import swal from 'sweetalert';
import './styles.scss';
function ModalHtmlComponent(title, message, type, action = () => {}, button) {
  var content = document.createElement('div');
  content.className = 'swal-text';
  content.innerHTML = message;
  content.style = 'margin: 0;';
  swal({
    title,
    content: content,
    icon: type,
    button,
  }).then(() => {
    action();
  });
}
export default ModalHtmlComponent;
