import React, { useContext } from 'react';

import Table from '../../../components/BasicTable';
import { BorderoContext } from '../../../contexts/BorderoContext';

import { columnsDetails } from './columns';

function TableDetails() {
  const {
    tabelaBordero,
    fetchTabelaBorderos,
    paginacaoTabelaBordero,
  } = useContext(BorderoContext);

  const { total, loading, pageCount, currentPage } = paginacaoTabelaBordero;

  return (
    <Table
      data={tabelaBordero}
      onLoadData={fetchTabelaBorderos}
      columns={columnsDetails}
      filter={false}
      total={total}
      loading={loading}
      pageCount={pageCount}
      currentPage={currentPage}
    />
  );
}

export default TableDetails;
