import React, { useContext } from 'react';

import Card from '../Card';
import Title from '../Title';
import Table from '../BasicTable';
import Overlay from '../Overlay';
import Button  from '../Button';

import { Switch, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import InputMask from 'react-text-mask';
import { cpfMask } from '../../utils/masks';

import './styles.scss';
import { BlocklistCPFContext } from '../../contexts/BlocklistCPFContext';
import { BlocklistUnidadeConsumidoraContext } from '../../contexts/BlocklistUCContext';
import { BlocklistTelefoneContext} from '../../contexts/BlocklistTelefoneContext';


function ListBlocklist({
  title,
  columns,
  data,
  tipoBlocklist,
  filter,
  setFilter,
  onLoadData,
  total,
  pageCount,
  loading,
}) {

  const {cpf, setCpf } = useContext(BlocklistCPFContext);
  const { uc, setUc } = useContext(BlocklistUnidadeConsumidoraContext);
  const { phone, setPhone } = useContext(BlocklistTelefoneContext);

  const formatPhone = (e) =>{
    let unformatted = [...e.target.value.replace(/\D/g, '')];
    if (unformatted.length > 0) {
      let formatted = '';
      for (var i = 0; i < unformatted.length; i++) {
        if (i === 0) {
          formatted += '(';
        }
        if (i === 2) {
          formatted += ') ';
        } else {
          if (i === (unformatted[2] === '9' ? 7 : 6)) {
            formatted += '-';
          } else if (i === (unformatted[2] === '9' ? 11 : 10)) {
            break;
          }
        }
        formatted += unformatted[i];
        setPhone(formatted)
      }
    }
  }


  const changePhone = (phone) => {
    let unformatted = [...phone.replace(/\D/g, '')];
    if (unformatted.length > 0) {

      if (phone !== filter?.filtroDinamico) {
        if (unformatted[2] === '9') {
          if (unformatted.length === 11) {
            setFilter((filter) => ({
              ...filter,
              pagina: 1,
              filtroDinamico: phone,
            }));
          } else if (unformatted.length === 0) {
            setFilter((filter) => ({
              ...filter,
              pagina: 1,
              filtroDinamico: '',
            }));
          }
        } else {
          if (unformatted.length === 10) {
            setFilter((filter) => ({
              ...filter,
              pagina: 1,
              filtroDinamico: phone,
            }));
          } else if (unformatted.length === 0) {
            setFilter((filter) => ({
              ...filter,
              pagina: 1,
              filtroDinamico: '',
            }));
          }
        }
      }
    } else {
      if (filter?.filtroDinamico !== '') {
        setFilter((filter) => ({
          ...filter,
          pagina: 1,
          filtroDinamico: '',
        }));
      }
    }
  };

  return (
    <>
      <Card
        title={<Title label={title} />}
        rightBar={
          <div className="filtro-pesquisa">
            <div className="filtro">
              <label>Apenas ativos</label>
              <Switch
                checked={filter?.filterStatus}
                onChange={(e) =>
                  setFilter((filter) => ({
                    ...filter,
                    pagina: 1,
                    filterStatus: e,
                  }))
                }
              />
            </div>
            <div className="pesquisa">
              <div className="pesquisa-input">
                {tipoBlocklist === 'cpf' && (
                  <InputMask
                    placeholder="Pesquise por CPF"
                    mask={cpfMask}
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  />
                )}
                {tipoBlocklist === 'telefone' && (
                  <Input
                    onKeyUp={(e) => formatPhone(e)}
                    placeholder="Pesquise por Telefone"
                    max={11}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
                {tipoBlocklist === 'unidade-consumidora' && (
                  <Input
                    placeholder="Pesquise por UC"
                    value={uc}
                    onChange={(e) => setUc(e.target.value)}
                  />
                )}

              </div>

              <Button
                type="primary"
                htmlType="submit"
                className="btn-blue"
                onClick={() =>{
                  if(tipoBlocklist === 'cpf'){
                    const cpf_filter = cpf.replace(/\D/g, '');
                    if(filter?.filtroDinamico !== cpf_filter){
                      if (cpf_filter.length === 11) {
                        setFilter((filter) => ({
                          ...filter,
                          pagina: 1,
                          filtroDinamico: cpf_filter,
                        }));
                      }
                      if (cpf_filter.length === 0) {
                        setFilter((filter) => ({
                          ...filter,
                          pagina: 1,
                          filtroDinamico: '',
                        }));
                      }
                    }
                  }
                  else if(tipoBlocklist === 'unidade-consumidora'){
                    if(filter?.filtroDinamico !== uc){
                      setFilter((filter) => ({
                        ...filter,
                        pagina: 1,
                        filtroDinamico: uc,
                      }))
                    }
                  }
                  else{
                    changePhone(phone)
                  }
                }}
              ><SearchOutlined className="icon-search" />
              </Button>
            </div>
          </div>
        }
      >
        <Overlay active={loading}>
          <Table
            columns={columns}
            data={data}
            onLoadData={onLoadData}
            pageCount={pageCount}
            total={total}
            pagination={true}
            filter={filter}
          />
        </Overlay>
      </Card>
    </>
  );
}

export default ListBlocklist;
