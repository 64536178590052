import { object, mixed, number } from 'yup';

const VALUES_NOT_ALLOWED = [
  'R$ 0,00',
  'R$ 00,00',
  'R$ 000,00',
  'R$ 0000,00',
  'R$ 00000,00',
  'R$ 000000,00',
];

export const validationSchema = object().shape({
  orgaoId: mixed().when('tipoCalculoVencimento',(tipoCalculoVencimento) => {
      if (tipoCalculoVencimento === 5) {
        return number().required('O orgão é obrigatório').nullable();
      } 
    },
  ),
  tabelaJurosId: mixed().required('A tabela de juros é obrigatória'),
  diaRecebimento: mixed().when(
    'produto.tipoCalculoVencimento',
    (tipoCalculoVencimento) => {
      if (tipoCalculoVencimento !== 2) {
        return number().required('O dia recebimento é obrigatório').nullable();
      }
    },
  ),
  convenioId: mixed().when(
    'produto.convenioTipo.permiteSelecao',
    (permiteSelecao) => {
      if (permiteSelecao) {
        return mixed().required('A empresa conveniada é obrigatória');
      }
    },
  ),
  dataVencimento: mixed().when('produtoId', (produtoId) => {
    if (produtoId !== 6) {
      // Produtos diferentes de Energia
      return mixed().required('A data de vencimento é obrigatória');
    } else {
      return mixed().notRequired();
    }
  }),
  valorSolicitado: mixed().when('produtoId', (produtoId) => {
    if (produtoId !== 10) {
      // Produtos diferentes de Refin
      return mixed().when('floatValorSolicitado', (floatValorSolicitado) => {
        return floatValorSolicitado
          ? mixed().notRequired()
          : mixed().test(
              'always-true',
              'O valor solicitado é obrigatório',
              (value) => false,
            );
      });
    } else {
      // Apenas para Refin
      return mixed().notRequired();
    }
  }),
  valorSacar: mixed().when('produtoId', (produtoId) => {
    if (produtoId === 10) {
      // Apenas para Refin
      return mixed().when('tipoCalculo', (tipoCalculo) => {
        return tipoCalculo === 0
          ? mixed()
              .required('O valor liberado é obrigatório')
              .notOneOf(VALUES_NOT_ALLOWED, ' O valor liberado é obrigatório')
          : mixed().notRequired();
      });
    } else {
      return mixed().notRequired();
    }
  }),
  valorParcela: mixed().when('produtoId', (produtoId) => {
    if (produtoId === 10) {
      // Apenas para Refin
      return mixed().when('tipoCalculo', (tipoCalculo) => {
        return tipoCalculo === 1
          ? mixed()
              .required('O valor da parcela é obrigatório')
              .notOneOf(VALUES_NOT_ALLOWED, ' O valor liberado é obrigatório')
          : mixed().notRequired();
      });
    } else {
      return mixed().notRequired();
    }
  }),

});
