import React, { createContext, useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import {
  listarHistoricoGravacoes,
  listarSituacoes,
  downloadGravacao,
} from '../services/historicoGravacoesService';
import ModalComponent from '../components/Modal';

export const HistoricoGravacoesContext = createContext({});

const initialValuesFiltros = {
  filtroSituacao: null,
  filtroTelefone: null,
  filtroConfirmacao: null,
  filtroData: [moment().add(-1, 'days'), moment()],
  filtroDataHoraInicial: moment().add(-1, 'days'),
  filtroDataHoraFinal: moment(),
  filtroPropostaId: null,
  filtroAnalista: null,
  filtroCliente: null,
  quantidadePorPagina: null,
  pagina: null,
  ordenacao: 'dataHora',
  ordenacaoAsc: false,
  paginado: null,
};

function HistoricoGravacoesProvider({ children }) {
  const [listagemHistoricoGravacoes, setListagemHistoricoGravacoes] = useState(
    [],
  );
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [listagemSituacoes, setListagemSituacoes] = useState([]);

  const [filters, setFilters] = useState(initialValuesFiltros);
  const [search, setSearch] = useState(null);

  const fetchListagemHistoricoGravacoes = useCallback(
    async (props) => {
      setLoading(true);

      const page = props?.pageIndex + 1;
      if (
        !(
          (props?.filter?.filtroCliente &&
            /^[0-9]*$/.test(
              props?.filter?.filtroCliente
                ?.replaceAll('-', '')
                ?.replaceAll('.', ''),
            )) ||
          props?.filter?.filtroPropostaId || 
          props?.filter?.filtroTelefone
        ) &&
        !(
          props?.filter?.filtroDataHoraFinal ||
          props?.filter?.filtroDataHoraInicial
        )
      ) {
        ModalComponent(
          '',
          'É necessário informar data final e inicial',
          'warning',
        );
        setLoading(false);
      } else {
        await listarHistoricoGravacoes({
          filtroSituacao: props?.filter?.filtroSituacao,
          filtroTelefone: props?.filter?.filtroTelefone,
          filtroConfirmacao: props?.filter?.filtroConfirmacao,
          filtroDataHoraInicial: props?.filter?.filtroDataHoraInicial,
          filtroDataHoraFinal: props?.filter?.filtroDataHoraFinal,
          filtroPropostaId: props?.filter?.filtroPropostaId,
          filtroAnalista: props?.filter?.filtroAnalista,
          filtroCliente: props?.filter?.filtroCliente,
          ordenacao: props?.sortBy[0]?.id || '',
          ordenacaoAsc: props?.sortBy[0]?.desc || false,
          pagina: page || 1,
          quantidadePorPagina: 10,
          paginado: props?.filter?.paginado,
        }).then((response) => {
          setTotal(response?.data?.total);
          setPageCount(response?.data?.totalPages);
          if (response?.data?.itens) {
            setListagemHistoricoGravacoes(response?.data?.itens);
          } else {
            setListagemHistoricoGravacoes([]);
          }
          setLoading(false);
        });
      }
    },

    [],
  );

  const fetchListagemSituacoes = async () => {
    const response = await listarSituacoes();
    setListagemSituacoes(response?.data);
  };

  useEffect(() => {
    fetchListagemSituacoes();
  }, []);

  return (
    <HistoricoGravacoesContext.Provider
      value={{
        listagemSituacoes,
        listagemHistoricoGravacoes,
        fetchListagemHistoricoGravacoes,
        total,
        pageCount,
        loading,
        setFilters,
        filters,
        initialValuesFiltros,
        downloadGravacao,
        search,
        setSearch
      }}
    >
      {children}
    </HistoricoGravacoesContext.Provider>
  );
}

export default HistoricoGravacoesProvider;
