import React, { useState, useEffect } from 'react';

import { Upload, Modal, Select } from 'antd';
import Message from '../../../components/Modal';
import UploadItem from './UploadItem';

import Overlay from '../../../components/Overlay';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/add2.svg';
import { ReactComponent as IconArrowDown } from '../../../assets/images/icons/icon-arrow-down.svg';

import { carregarImagem, getLinkImagem } from '../../../services/imagemService';
import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';
const { Option } = Select;

function DocumentosFormUpload({
  disabled,
  fileIds,
  setFileIds,
  fileList,
  setFileList,
  typeDocumetsContext,
  formik,
}) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingFile, setLoadingFile] = useState(false);
  const [blobPdf, setBlobPdf] = useState(null);

  const indiceArquivo = (imagemId) =>
    fileList.findIndex((elem) => elem.imagemId === imagemId);

  const handleType = (value, file) => {
    let index;
    if (file?.imagemId || file?.imagemId === 0) {
      index = indiceArquivo(file.imagemId);
    }
    const tempArquivos = JSON.parse(JSON.stringify(fileList));
    tempArquivos[index].tipoDocumentoCredenciado = value;
    file.tipoDocumentoCredenciado = value;
    setFileList(tempArquivos);
  };

  const customUploadRender = (item, file) => {
    return (
      <>
        <div class="icone-arquivo">
          {UploadItem(file, handlePreview, handleRemove, null, disabled)}
        </div>

        {!file.exclusao && (
          <div className="titulo-arquivo">
            <h5>
              {typeDocumetsContext?.find(
                (nome) => nome.id === file.tipoDocumentoCredenciado,
              )?.nome || 'OUTROS'}
            </h5>
          </div>
        )}
        {file.exclusao && (
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            id="tipoArquivo"
            name="tipoArquivo"
            suffixIcon={<IconArrowDown />}
            placeholder="Tipo de Arquivo"
            dropdownClassName="dropdown-select-upload-proposta-credito "
            onChange={(value) => {
              handleType(value, file);
            }}
            value={file.tipoDocumentoCredenciado}
          >
            {typeDocumetsContext?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.nome}
              </Option>
            ))}
          </Select>
        )}
        <p className="data-upload-item">{file.dataHora}</p>
      </>
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setBlobPdf(null);
    setPreviewImage(null);
    setPreviewTitle(null);
  };


  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);
    const { conteudo } = file;
    if (conteudo) {
      setLoadingFile(false);

      file['type'] = conteudo.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(conteudo);
      setPreviewVisible(true);
    } else {
      if (file.link) {
        const linkWithId = file?.link?.replace('{id}', file?.imagemId);
        var anexo;
        // eslint-disable-next-line
        if (linkWithId == file?.link) {
          anexo = await getLinkImagem(file?.link);
        } else {
          anexo = await getLinkImagem(linkWithId);
        }
        setLoadingFile(false);

        const { conteudo } = anexo;

        if (conteudo) {
          file.type = conteudo.match(
            /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
          )[1];

          setFileType(file.type);
          setPreviewImage(conteudo);
          setPreviewVisible(true);
        }
      } else if (file.url) {
        setLoadingFile(false);
        file.type = file.url.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(file.url);
        setPreviewVisible(true);
      }
    }

    if (file.documentoId) {
      setPreviewTitle('Preview Title');
    } else {
      setPreviewTitle('');
    }
  };

  const uploadButton = (
    <div className="action-anexar">
      <IconPlus />
      <div>Clique para anexar</div>
    </div>
  );

  const beforeUpload = (file) => {
    file.status = '';
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg'
    ) {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
        'warning',
      );
      file.status = 'removed';
    }
    return false;
  };

  const uploadImage = async (options) => {
    const { file } = options;

    if (file?.status !== 'removed') {
      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const fileURL = await getBase64(file);
          const { data } = await carregarImagem(temp);
          const { dataHora, id } = data;
          const newFile = {
            dataHora: dataHora,
            documentoId: null,
            imagemId: id,
            url: fileURL,
            exclusao: true,
            type: file.type,
            tipoDocumentoCredenciado: null,
          };
          setFileList((fileList) => [...fileList, newFile]);
        } catch (err) {
          console.log(`Falha no upload`, err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemove = (file) => {
    let newFileList = [];
    if (file.id) {
      file['imagemId'] = file.id;
    }
    newFileList = fileList.filter(
      (fileRemove) => fileRemove.imagemId !== file.imagemId,
    );

    setFileList(newFileList);
  };

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobPdf(blobURL);
    } else {
      setBlobPdf(null);
    }
  }, [previewImage, fileType]);

  return (
    <div>
      <Upload
        multiple={true}
        listType="picture-card"
        className="form-upload-documentos-loja proposta-credito-uploads"
        fileList={fileList}
        itemRender={customUploadRender}
        beforeUpload={beforeUpload}
        onChange={uploadImage}
        disabled={disabled}
      >
        {uploadButton}
      </Upload>

      <Modal
        visible={previewVisible}
        destroyOnClose={true}
        title={previewTitle}
        footer={null}
        className={'modal-preview-upload ' + fileType.replace('/', '')}
        onCancel={handleCancel}
      >
        <Overlay active={loadingFile}>
          {!loadingFile && (
            <div>
              {fileType === 'application/pdf' && (
                <object
                  data={(blobPdf ?? previewImage) + '#toolbar=0'}
                  type={fileType}
                  width="100%"
                  height="100%"
                  aria-label="Upload File"
                ></object>
              )}
              {fileType !== 'application/pdf' && (
                <img src={previewImage} alt="Documento"></img>
              )}
            </div>
          )}
        </Overlay>
      </Modal>
    </div>
  );
}

export default DocumentosFormUpload;
