import React from 'react';
import Tooltip from '../../../components/tooltip';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

function TooltipInfo({ text }) {
  return (
    <Tooltip
      placement="bottom"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

export default TooltipInfo;
