import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { cnpjFormatter } from '../../../utils/textFormatter';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';
import Tooltip from '../../../components/tooltip';

import { AcompanhamentoCredenciamentoContext } from '../../../contexts/AcompanhamentoCredenciamentoContext';

import Toolbar from './toolbar';
import Tag from '../../../components/Tag';
import './styles.scss';

const columns = [
  {
    Header: 'Tipo',
    accessor: 'tipoCredenciamento',
  },
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{cnpjFormatter(value)}</div>
    ),
  },
  {
    Header: 'Nome Fantasia',
    accessor: 'nomeFantasia',
  },
  {
    Header: 'Cidade',
    accessor: 'nomeCidade',
  },
  {
    Header: 'Cadastro',
    accessor: 'cadastro',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: 'Alteração',
    accessor: 'ultimaAtualizacao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: 'Situação',
    accessor: 'credenciamentoMotivoDescricao',
    Cell: (e) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Tag color={e.row.original.etapaCor}>{e.row.original.etapaNome}</Tag>
          <Tooltip
            placement="bottomRight"
            arrowPointAtCenter
            color="#435361"
            title={
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  lineHeight: '15px',
                }}
              >
                {e.row.original.credenciamentoMotivoDescricao}
              </div>
            }
          ></Tooltip>
        </div>
      );
    },
  },
  {
    Header: 'Gerente',
    accessor: 'nomeGerente',
  },

  {
    Header: 'Coordenador',
    accessor: 'nomeCoordenador',
  },

  {
    Header: 'Supervisor',
    accessor: 'nomeSupervisor',
  },
];

function AcompanhamentoList() {
  const {
    fetchCredenciamento,
    correspondenteList,
    pageCount,
    loading,
    total,
    filters,
    pageIndex,
    setPageIndex,
  } = useContext(AcompanhamentoCredenciamentoContext);

  const history = useHistory();

  return (
    <Card>
      <div className="tabelaAcompanhamentoList">
        <Table
          columns={columns}
          data={correspondenteList}
          pageCount={pageCount}
          loading={loading}
          onLoadData={fetchCredenciamento}
          total={total}
          filter={filters}
          header={<Toolbar />}
          onRowClick={(row) =>
            history.push('/correspondente-credenciamento', row.id)
          }
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </div>
    </Card>
  );
}

export default AcompanhamentoList;
