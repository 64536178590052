export const initialValues = {
  operacao: {
    produtoNome: '',
    diaRecebimento: null,
    vencimento: '',
    valorContratado: '',
    valorSolicitado: '',
    valorOperacao: '',
    tabelaJurosId: null,
    prazo: '',
    taxa: '',
    prestacao: '',
    valorSacar: '',
    valorParcela: '',
    valorSaldoDevedor: '',
    valorLiberado: '',
    valorSolicitadoRefin: '',
    valorDebitoConcorrente:''
  },

  cliente: {
    cpf: '',
    nome: '',
    nascimento: '',
    rg: '',
    rgUfId: null,
    rgEmissor: '',
    rgEmissao: '',
    sexo: '',
    estadoCivil: null,
    nacionalidadeId: null,
    naturalidadeUfId: null,
    naturalidadeCidadeId: null,
    grauInstrucaoId: null,
    pep: false,
    nomeMae: '',
    nomeConjuge: '',
  },

  contatos: {
    contato: {
      email: '',
      telefone: '',
      telefoneFixo: '',
      telefoneExtra: [],
    },
    referencia: {
      grau: '',
      nome: '',
      telefone: '',
    },
  },

  endereco: {
    cep: '',
    logradouro: '',
    numero: 0,
    complemento: '',
    bairro: '',
    cidadeId: null,
    ufId: null,
  },

  bancario: {
    bancoId: null,
    agencia: '',
    digito: '',
    numero: '',
    conta: '',
    tipoConta: null,
    tipoOperacao: '12',
    tempoConta: '',
  },

  profissional: {
    empresa: '',
    ocupacaoId: null,
    profissaoId: null,
    tempoEmpregoAtual: '',
    telefoneRH: '',
    pisPasep: '',
    renda: '',
    outrasRendas: '',
    tipoOutrasRendas: '',
    orgaoDados:[]
  },

  unidade: {
    nomeMatriz: '',
    loginAgente: '',
    supervisorComercial: '',
    telefoneExtraMatriz: '',
    celularSupervisor: '',
    nomeVendedor: '',
    cpfVendedor: '',
    celularVendedor: '',
  },
};
