import React, { useState, useEffect, useContext } from 'react';
import InputMask from 'react-text-mask';

import { PreAnaliseContext } from '../../contexts/PreAnaliseContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

import moment from 'moment';

import { Select, Modal } from 'antd';
import Input from '../../components/SimpleInput';

import SelectSearch from '../../components/SearchSelect';

import { FormikProvider } from 'formik';
import { Form, Field } from 'formik-antd';

import Card from '../../components/Card';
import Title from '../../components/Title';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Overlay from '../../components/Overlay';
import InputPhone from '../../components/InputPhone';
import Spinner from '../../components/Spinner';

import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as CpAutoIcon } from '../../assets/images/icons/cp-auto-icon.svg';
import { ReactComponent as ConsignadoPrivadoIcon } from '../../assets/images/icons/consignado-privado-icon.svg';
import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import validaCPF from '../../utils/validaCpf';
import { cpfFormatter, cepFormatter } from '../../utils/textFormatter';

import { cpfMask, cepMask } from '../../utils/masks';

import './styles.scss';
import { PreAnaliseProdutoPlugIn } from '../../components/PreAnaliseProdutoPlugIn';

const { Option } = Select;

function PreAnaliseForm() {
  const { formik, disableSubmit, setFlagNovaProposta, loadingSalvarProposta } =
    useContext(PreAnaliseContext);

  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Overlay active={showOverlay}>
      <Card title={<Title label="Pré-Análise" />}>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <FormLayout
              setFieldValue={formik.setFieldValue}
              values={formik.values}
              setValues={formik.setValues}
              setErrors={formik.setErrors}
              errors={formik.errors}
              touched={formik.touched}
              setFieldTouched={formik.setFieldTouched}
              setFieldError={formik.setFieldError}
              setShowOverlay={setShowOverlay}
              disableSubmit={disableSubmit}
              setFlagNovaProposta={setFlagNovaProposta}
            />
          </Form>
        </FormikProvider>
        <Modal
          visible={loadingSalvarProposta}
          centered
          footer={null}
          closable={false}
          width={475}
        >
          <div className="modal-pre-analise">
            <p>
              Já recebemos sua proposta e estamos realizando os procedimentos
              iniciais. <br />
              Aguarde alguns segundos.
            </p>
            <Spinner />
          </div>
        </Modal>
      </Card>
    </Overlay>
  );
}

export default PreAnaliseForm;

function FormLayout({
  setFieldValue,
  values,
  setValues,
  setErrors,
  errors,
  touched,
  setFieldTouched,
  setFieldError,
  setShowOverlay,
  disableSubmit,
  setFlagNovaProposta,
}) {
  const {
    ocupacoes,
    preAnaliseContexto,
    fetchPreAnalise,
    preAnaliseCpf,
    fetchCidades,
    fetchUfs,
    fetchEndereco,
    fetchOcupacoes,
    carregarProposta,
    propostaId,
    verificaPropostaAndamento,
    verificaPropostaSimultanea,
    mensagemPropostaAndamento,
    modalPropostaAndamentoVisible,
    setmodalPropostaAndamentoVisible,
    redirecionarParaAcompanhamento,
    cpfValido,
    setCpfValido,
  } = useContext(PreAnaliseContext);

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cepInvalido, setCepInvalido] = useState(false);

  const [statusCPF, setStatusCPF] = useState('');
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusCEP, setStatusCEP] = useState('');
  const [convenioId, setConvenioId] = useState();
  const [acesso, setAcesso] = useState(false);
  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao('tela', 'tela.credito.proposta.novaProposta', 'Criar'),
    );
  }, [checarPermissao]);

  const handleConsultaCPF = async (e) => {
    setFieldTouched('cpf');
    setFlagNovaProposta(false);

    let cpf = e.target.value.replace(/\D/g, '');

    if (!validaCPF(cpf)) {
      setCpfValido(false);
    } else {
      setShowOverlay(true);

      const data = await preAnaliseCpf(cpf);

      if (data?.propostaEmAndamento) {
        verificaPropostaAndamento(data?.propostaEmAndamento);
      } else if (data?.propostaSimultanea) {
        verificaPropostaSimultanea(data?.propostaSimultanea)
      } else if (data?.dadosPessoais) {
        setCpfValido(true);

        setValues({
          ...values,
          cpf,
          nome: data?.nome?.toUpperCase(),
          nascimento: data?.dadosPessoais?.nascimento ? moment(data?.dadosPessoais?.nascimento) : undefined,
        });
        document.getElementsByName('nome')[0].focus();
      } else {
        setCpfValido(true);

        setValues({ ...values, cpf, nome: '', nascimento: '' });
        document.getElementsByName('nome')[0].focus();
      }

      setShowOverlay(false);
    }
  };

  const handleConsultaCEP = async (cep) => {
    setCepInvalido(false);
    setFieldTouched('cep');

    if (cep) {
      cep = cep.replace(/\D/g, '');
      setCepInvalido(false);

      const { data } = await fetchEndereco(cep);

      if (data?.status) {
        const dataCidades = await fetchCidades(data.ufId);
        setCidades(dataCidades);
        setFieldValue('ufId', data.ufId);
        setFieldValue('cidadeId', data.cidadeId);
        setFieldValue('bairro', data.bairro);
        setFieldValue('logradouro', data.logradouro);

        setErrors({});
      } else {
        setFieldError(
          'cep',
          'CEP inválido ou não encontrado, favor informar o CEP correto ou prosseguir com o preenchimento manual',
        );
        setCepInvalido(true);
      }
    }
  };

  const handleSelecionarEstado = (estado) => {
    setFieldValue('ufId', estado);
    setFieldValue('cidadeId', '');
  };

  const handleNascimento = (nascimento) => {
    setErrors({});
    setFieldTouched('nascimento');
  };

  const disabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  const validaProdutosPlugins = (contexto) => {
    const permiteConsignadoPrivado = contexto?.produtosLiberados.find((plugin) => plugin.nome === "Consignado Privado")
    !permiteConsignadoPrivado && setFieldValue("funcionarioConveniada", false)
    const permiteCPAuto = contexto?.produtosLiberados.find((plugin) => plugin.nome === "CP Auto")
    !permiteCPAuto && setFieldValue("possuiVeiculoGarantia", false)
  }

  useEffect(() => {
    async function getProposta() {
      setShowOverlay(true);

      const data = await carregarProposta(propostaId);

      if (data) {
        const dataCidades = await fetchCidades(data.ufId);
        setCidades(dataCidades);

        setValues({
          ...data,
          cpf: cpfFormatter(data.cpf),
          cep: data?.cep ? cepFormatter(data.cep) : '',
        });
        setCpfValido(true);

        if (data?.convenioId) {
          setFieldValue('funcionarioConveniada', true);
          setFieldValue("convenioId", data.convenioId);
          setConvenioId(data.convenioId)
        }

      }
      setShowOverlay(false);
    }

    if (propostaId) {
      getProposta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propostaId, carregarProposta, setValues, setShowOverlay]);

  useEffect(() => {
    async function loadEstados() {
      const data = await fetchUfs();
      if (data) {
        setEstados(data);
      }
    }

    loadEstados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.ufId && (!values.cep || cepInvalido)) {
      async function loadCidades(ufId) {
        const data = await fetchCidades(ufId);
        setCidades(data);
      }
      loadCidades(values.ufId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.ufId]);

  useEffect(() => {
    if (values.cidadeId) {
      setFieldValue("possuiVeiculoGarantia", undefined)

      fetchPreAnalise(values.cidadeId, values?.id).then(
        (contexto) => {
          validaProdutosPlugins(contexto)
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cidadeId]);

  useEffect(() => {
    if (!preAnaliseContexto) {
      fetchPreAnalise(undefined, values?.id).then((contexto) => {
        validaProdutosPlugins(contexto)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ocupacoes.length === 0) {
      async function loadOcupacoes() {
        await fetchOcupacoes();
      }
      loadOcupacoes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocupacoes]);

  useEffect(() => {
    if (touched.cpf && errors.cpf === 'CPF inválido') {
      setStatusCPF('warning');
    } else if (touched.cpf && errors.cpf === 'O CPF é obrigatório') {
      setStatusCPF('error');
    } else {
      setStatusCPF('');
    }

    if (
      errors.telefone === 'Número de telefone inválido, favor tentar novamente.'
    ) {
      setStatusTelefone('warning');
    } else if (
      touched.telefone &&
      errors.telefone === 'O telefone é obrigatório'
    ) {
      setStatusTelefone('error');
    } else {
      setStatusTelefone('');
    }
    if (
      touched.cep &&
      errors.cep ===
      'CEP inválido ou não encontrado, favor informar o CEP correto ou prosseguir com o preenchimento manual'
    ) {
      setStatusCEP('warning');
    } else {
      setStatusCEP('');
    }
  }, [errors, touched]);

  /*
    Resolução do Bug 9888
    Atualiza o convenioId, mesmo quando o sistema espera retorno da
    API com relação ao CPF indicado.
  */
  useEffect(() => {
    if (convenioId) {
      setFieldValue('convenioId', convenioId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, convenioId])

  useEffect(() => {
    if (!values?.funcionarioConveniada) {
      setConvenioId(null);
      setFieldValue('convenioId', null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.funcionarioConveniada])

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="cpf"
            label="CPF"
            hasFeedback
            validateStatus={statusCPF}
            className="label-form"
          >
            <Field name="cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  mask={cpfMask}
                  onBlur={(e) => handleConsultaCPF(e)}
                  placeholder="Ex.: 000.000.000-00"
                  className="ant-input"
                  data-cy="cpf"
                  disabled={propostaId || acesso}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-9">
          <Form.Item
            name="nome"
            label="Nome"
            hasFeedback
            className="label-form"
          >
            <Input
              name="nome"
              value={values?.nome}
              placeholder="Ex.: Nome completo"
              disabled={!cpfValido || acesso}
              data-cy="nome"
              onBlur={(e) => setFieldValue('nome', String(e.target.value).toUpperCase().trim())}
              uppercase={true}
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="nascimento"
            label="Data de Nascimento"
            hasFeedback
            className="label-form"
          >
            <DatePicker
              name="nascimento"
              disabledDate={disabledDate}
              format="DD/MM/YYYY"
              disabled={!cpfValido || acesso}
              onBlur={(e) => handleNascimento(e)}
              data-cy="nascimento"
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="telefone"
            label="Telefone"
            hasFeedback
            validateStatus={statusTelefone}
            className="label-form"
          >
            <Field name="telefone">
              {({ field }) => (
                <InputPhone
                  {...field}
                  disabled={!cpfValido || acesso}
                  data-cy="telefone"
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="ocupacaoId"
            label="Classificação"
            hasFeedback
            className="label-form"
          >
            <Field name="ocupacaoId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('ocupacaoId', value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={!cpfValido || acesso}
                  optionFilterProp="children"
                  showSearch
                  data-cy={'ocupacaoId'}
                >
                  {ocupacoes.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="cep"
            label="CEP"
            hasFeedback
            validateStatus={statusCEP}
            className="label-form"
          >
            <Field name="cep">
              {({ field }) => (
                <InputMask
                  {...field}
                  mask={cepMask}
                  onBlur={(e) => handleConsultaCEP(e.target.value)}
                  placeholder="Ex.: 00.000-000"
                  disabled={!cpfValido || acesso}
                  className="ant-input"
                  autoComplete="cep"
                  data-cy="cep"
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="ufId"
            label="Estado"
            hasFeedback
            className="label-form"
          >
            <Field name="ufId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={handleSelecionarEstado}
                  showSearch
                  optionFilterProp="children"
                  disabled={
                    !cpfValido || (values.cep && !cepInvalido) || acesso
                  }
                >
                  {estados.length > 0 &&
                    estados.map((estado) => (
                      <Option key={estado.id} value={estado.id}>
                        {estado.uf}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="cidadeId"
            label="Cidade"
            hasFeedback
            className="label-form"
          >
            <Field name="cidadeId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('cidadeId', value)}
                  showSearch
                  optionFilterProp="children"
                  disabled={
                    !values.ufId || (values.cep && !cepInvalido) || acesso
                  }
                >
                  {cidades.length > 0 &&
                    cidades.map((cidade) => (
                      <Option key={cidade.id} value={cidade.id}>
                        {cidade.nome}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
      </div>

      <div className='plugins'>
        {
          // eslint-disable-next-line
          preAnaliseContexto?.produtosLiberados?.map((plugin) => {
            if (plugin.nome === "Consignado Privado") {
              return (
                <div className="form-row row-convenio">
                  <Form.Item name="funcionarioConveniada">
                    <PreAnaliseProdutoPlugIn
                      name="funcionarioConveniada"
                      imagem={<ConsignadoPrivadoIcon
                        width={24}
                        height={24}
                      />}
                      titulo={plugin.tituloPreAnalise}
                      isConsignadoPrivado={true}
                      setFieldValue={setFieldValue}
                      value={values?.funcionarioConveniada}
                      setConvenioId={setConvenioId}
                      convenioId={convenioId}
                      convenios={plugin.listaConveniada}
                    />
                  </Form.Item>
                </div>
              )
            } else if (plugin.nome === "CP Auto") {
              return (
                <div className="form-row row-convenio">
                  <Form.Item name="possuiVeiculoGarantia">
                    <PreAnaliseProdutoPlugIn
                      name="possuiVeiculoGarantia"
                      imagem={<CpAutoIcon
                        width={24}
                        height={24}
                      />}
                      titulo={plugin.tituloPreAnalise}
                      setFieldValue={setFieldValue}
                      value={values?.possuiVeiculoGarantia}
                    />
                  </Form.Item>
                </div>
              )
            }
          }
          )}

      </div>

      <div className="d-flex justify-content-end mt-2">
        <Button
          type="submit"
          variant="green"
          data-cy="submit"
          disabled={
            checarPermissao(
              'tela',
              'tela.credito.proposta.novaProposta',
              'Criar',
            )
              ? disableSubmit
              : true
          }
        >
          <span>AVANÇAR</span>
          <ArrowRightIcon
            height="15"
            fill="#17222D"
            style={{ marginLeft: 10 }}
          />
        </Button>
      </div>
      <Modal
        visible={modalPropostaAndamentoVisible}
        onCancel={() => setmodalPropostaAndamentoVisible(false)}
        centered
        footer={null}
        width={475}
      >
        <div className="modal-proposta-andamento">
          <div className="icon-proposta-andamento">
            <IconExclamationCircle width={80} />
          </div>
          <p>{mensagemPropostaAndamento}</p>

          <div className="modal-proposta-andamento-footer">
            <Button
              variant="orange"
              className="btn btn-prosseguir"
              onClick={() => {
                setCpfValido(true);
                setFlagNovaProposta(true);
                setmodalPropostaAndamentoVisible(false);
              }}
            >
              Prosseguir
            </Button>
            <Button
              variant="blue"
              className="btn btn-verificar"
              onClick={() => redirecionarParaAcompanhamento()}
            >
              Verificar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
