import React from 'react';

import './styles.scss';

function ProdutoDriver({ produto }) {
  return (
    <div className="ProdutoDrivers">
      <div className="NomeProduto">{produto.nomeProduto}</div>
      {produto.criteriosProduto.map((lista, i) => (
        <div className="line"
        // eslint-disable-next-line
        style={(i%2==0) ? {background: '#ddd' } : {background: '#fff' }}
        >
          <div className="crit col-sm-5">{lista.criterio}</div>
          <div className="return col-sm-7">{lista.resposta}</div>
          {i + 1 < produto.criteriosProduto.length && <hr />}
        </div>
      ))}
    </div>
  );
}

export default ProdutoDriver;
