import moment from 'moment';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  buscarHistoricoAcoesProposta,
  buscarHistoricoProposta,
} from '../services/propostaService';
import {
  listarPropostasEsteira,
  listarPropostasEsteiraContexto,
  exportarPropostasEsteiraCSV,
  imprimirPdfEsteiraCredito,
  carregaNovoFluxo,
  enviaNovoFluxo,
} from '../services/relatorioEsteiraService';
import Modal from '../components/Modal';
import { useFormik } from 'formik';

const initialFilters = {
  analistas: [],
  cpf: '',
  dataFinal: moment(),
  dataInicial: moment().add(-1, 'days'),
  rangeData: [moment().add(-1, 'days'), moment()],
  etapaId: null,
  matrizId: null,
  nomeAgente: null,
  ordenacao: '',
  ordenacaoAsc: false,
  origemId: null,
  pagina: 1,
  produtoId: null,
  propostaId: null,
  quantidadePorPagina: 15,
  supervisorId: null,
  tipoCliente: null,
  tipoData: 1,
};

const initialContext = {
  tipoData: [],
  tipoCliente: [],
  etapas: [],
  produtos: [],
  matrizes: [],
  supervisores: [],
  origens: [],
  analistas: [],
};

export const RelatorioEsteiraContext = createContext({
  propostasEsteira: [],
  loading: false,
  total: 0,
  pageCount: 0,
  filters: initialFilters,
  showFilter: true,
  contexto: initialContext,
  fetchPropostasEsteira: async (f) => f,
  setShowFilter: (f) => f,
  setFilters: (f) => f,
});

function RelatorioEsteiraProvider({ children }) {
  const [propostasEsteira, setPropostasEsteira] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [contexto, setContexto] = useState({});
  const [pageIndex, setPageIndex] = useState(false);
  const [modalNovoFluxoVisible, setModalNovoFluxoVisible] = useState(false);
  const [novoFluxo, setNovoFluxo] = useState({});
  const [loadingModalNovoFluxo, setLoadingModalNovoFluxo] = useState(false);

  const fetchPropostasEsteira = useCallback(
    async ({ pageIndex, filter, sortBy }) => {
      setLoading(true);
      const page = pageIndex + 1;
      if (filter?.cpf?.replace(/\D/g, '') === '' && !filter?.rangeData) {
        Modal('', 'É necessário informar a data inicial e final', 'warning');
        setLoading(false);
      } else {
        const response = await listarPropostasEsteira({
          pagina: page || 1,
          quantidadePorPagina: 15,
          ordenacao: sortBy[0],
          cpf: filter.cpf,
          nomeAgente: filter.nomeAgente,
          tipoData: filter.tipoData,
          dataInicial:
            filter.rangeData && filter.rangeData[0]
              ? filter.rangeData[0].format('YYYY-MM-DD')
              : null,
          dataFinal:
            filter.rangeData && filter.rangeData[1]
              ? filter.rangeData[1].format('YYYY-MM-DD')
              : null,
          etapaId: filter.etapaId,
          produtoId: filter.produtoId ? filter.produtoId : null,
          matrizId: filter.matrizId,
          supervisorId: filter.supervisorId,
          tipoCliente: filter.tipoCliente,
          propostaId: parseInt(filter.propostaId),
          origemId: filter.origemId,
          analistas: filter.analistas,
        });

        if (response.data.itens) {
          setPropostasEsteira(response.data?.itens);
        } else {
          setPropostasEsteira([]);
        }

        setTotal(response.data.total);
        setPageCount(response.data.totalPages);
        setLoading(false);
      }
    },
    [],
  );

  const formik = useFormik({
    initialValues: initialFilters,
  });

  const { values } = formik;

  const exportarPropostasCsv = async () => {
    setLoading(true);
    const csv = exportarPropostasEsteiraCSV({
      cpf: values?.cpf?.replace(/\D/g, ''),
      nomeAgente: values?.nomeAgente,
      tipoData: values?.tipoData,
      dataInicial:
        values?.rangeData && values?.rangeData[0]
          ? values?.rangeData[0].format('YYYY-MM-DD')
          : null,
      dataFinal:
        values?.rangeData && values?.rangeData[1]
          ? values?.rangeData[1].format('YYYY-MM-DD')
          : null,
      etapaId: values?.etapaId,
      produtoId: values?.produtoId,
      matrizId: values?.matrizId,
      supervisorId: values?.supervisorId,
      clienteTipo: values?.tipoCliente,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio-esteira.csv');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });

    return csv;
  };

  const exportarPropostasPdf = async () => {
    setLoading(true);
    const response = await imprimirPdfEsteiraCredito({
      cpf: values?.cpf?.replace(/\D/g, ''),
      nomeAgente: values?.nomeAgente,
      tipoData: values?.tipoData,
      dataInicial:
        values?.rangeData && values?.rangeData[0]
          ? values?.rangeData[0].format('YYYY-MM-DD')
          : null,
      dataFinal:
        values?.rangeData && values?.rangeData[1]
          ? values?.rangeData[1].format('YYYY-MM-DD')
          : null,
      etapaId: values?.etapaId,
      produtoId: values?.produtoId,
      matrizId: values?.matrizId,
      supervisorId: values?.supervisorId,
      clienteTipo: values?.tipoCliente,
    });
    setLoading(false);
    return response;
  };

  const fetchContexto = async () => {
    const response = await listarPropostasEsteiraContexto();
    if (response.data) {
      setContexto(response.data);
    }
  };

  useEffect(() => {
    fetchContexto();
  }, []);

  async function loadNovoFluxo(id, cpf) {
    setLoadingModalNovoFluxo(true);
    const response = await carregaNovoFluxo(id);
    if (response.data) {
      setNovoFluxo({ ...response.data, cpf });
    }
    setLoadingModalNovoFluxo(false);
  }

  async function sendNovoFluxo(id, cpf, anotacao) {
    const response = await enviaNovoFluxo(id, cpf, anotacao);
    return response;
  }

  return (
    <RelatorioEsteiraContext.Provider
      value={{
        formik,
        propostasEsteira,
        loading,
        total,
        pageCount,
        filters,
        showFilter,
        contexto,
        pageIndex,
        fetchPropostasEsteira,
        exportarPropostasCsv,
        exportarPropostasPdf,
        setShowFilter,
        setFilters,
        setPageIndex,
        initialFilters,
        buscarHistoricoAcoesProposta,
        buscarHistoricoProposta,
        setModalNovoFluxoVisible,
        modalNovoFluxoVisible,
        loadNovoFluxo,
        novoFluxo,
        setNovoFluxo,
        sendNovoFluxo,
        loadingModalNovoFluxo,
        setLoadingModalNovoFluxo,
        search, 
        setSearch
      }}
    >
      {children}
    </RelatorioEsteiraContext.Provider>
  );
}

export default RelatorioEsteiraProvider;
