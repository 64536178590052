import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'formik-antd';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormikProvider, setNestedObjectValues } from 'formik';
import moment from 'moment';

import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import Overlay from '../../../components/Overlay';
import { mask } from '../../../utils/masks';
import './styles.scss';

import DadosGeraisForm from '../Forms/DadosGeraisForm';
import ContatoForm from '../Forms/ContatoForm';
import EnderecoForm from '../Forms/EnderecoForm';
import ComercialForm from '../Forms/ComercialForm';
import DadosBancariosForm from '../Forms/DadosBancariosForm';
import SociosForm from '../Forms/SociosForm';
import ProdutosForm from '../Forms/ProdutosForm';
import UnidadesForm from '../Forms/UnidadesForm';
import VisitaForm from '../Forms/VisitaForm';
import RegiaoForm from '../Forms/RegiaoForm';
import DocumentosForm from '../Forms/DocumentosForm';
import ConsignadoPrivadoForm from '../Forms/ConsignadoPrivadoForm';
import {
  SubTipoConvenio,
  TipoCredenciado,
} from '../../../utils/identificadoresFrontBack';

function TabsComponent({ action = 'edicao', userId, infoUsuario }) {
  const {
    abasAcessiveis,
    formik,
    dadosCredenciamentoContext,
    setFileList,
    postProdutoRegiao,
    dadosBancoContext,
    setFileListDocumentos,
    produtosRegiao,
    setProdutosRegiao,
  } = useContext(CredenciadoContext);

  const [dadosGeraisContext, setDadosGeraisContext] = useState({});
  const [contatoContext, setContatoContext] = useState({});
  const [dadosBancariosContext, setDadosBancariosContext] = useState({});
  const [typeDocumetsContext, setTypeDocumetsContext] = useState([]);
  const [regioes, setRegiao] = useState([]);
  const isReadOnly = action === 'visualizacao' && userId;
  const { touched, errors, setTouched, validateForm, setErrors } = formik;

  useEffect(() => {
    setRegiao(formik?.values?.regioes);
  }, [formik?.values?.regioes]);

  useEffect(() => {
    let tipoCredenciado = formik.values.dadosGerais.tipoCredenciado;
    if (tipoCredenciado !== 0 && tipoCredenciado !== 1) {
      tipoCredenciado = null;
    }
    if (regioes.length > 0) {
      const body = {
        tipoCredenciado: tipoCredenciado,
        regioes: regioes.map((item) => ({
          ufId: item.ufId,
          cidadeId: item.cidadeId || null,
          opera: !!item.opera,
        })),
      };
      postProdutoRegiao(body).then((response) => {
        setProdutosRegiao(response.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regioes, formik.values.dadosGerais.tipoCredenciado]);

  useEffect(() => {
    if (dadosCredenciamentoContext && dadosBancoContext) {
      setDadosGeraisContext({
        classificacaoCredenciado:
          dadosCredenciamentoContext?.classificacaoCredenciado,
        tipoCredenciado: dadosCredenciamentoContext?.tipoCredenciado,
        subTipoCredenciado: dadosCredenciamentoContext?.subTipoCredenciado,
      });
      setContatoContext({
        tipoContato: dadosCredenciamentoContext?.contato?.tipoContato,
      });
      setDadosBancariosContext({
        conta: dadosCredenciamentoContext?.dadoBancario?.conta,
        tipoChavePIX: dadosCredenciamentoContext?.dadoBancario?.tipoChavePIX,
        tipoConta: dadosCredenciamentoContext?.dadoBancario?.tipoConta,
        tempoConta: dadosCredenciamentoContext?.dadoBancario?.tempoConta,
        banco: dadosBancoContext,
      });
      setTypeDocumetsContext(
        dadosCredenciamentoContext?.documento?.tipoDocumentoCredenciado?.map(
          (item) => ({
            id: item.id,
            nome: item.nome,
          }),
        ),
      );
    }
  }, [dadosCredenciamentoContext, dadosBancoContext]);

  useEffect(() => {
    if (userId && infoUsuario?.dadosGerais?.cpfCnpj) {
      // desvinculando os dados do value do formik do objeto de infoUsuario
      const infoUsuarioClone = JSON.parse(JSON.stringify(infoUsuario));

      formik.setFieldValue('id', userId);
      formik.setFieldValue('dadosGerais', infoUsuarioClone?.dadosGerais);
      formik.setFieldValue(
        'statusCredenciado',
        infoUsuarioClone?.statusCredenciado,
      );
      formik.setFieldValue(
        'dadosGerais.cpfCnpj',
        mask(infoUsuarioClone?.dadosGerais?.cpfCnpj, '##.###.###/####-##'),
      );
      formik.setFieldValue('endereco', infoUsuarioClone?.endereco);
      formik.setFieldValue(
        'endereco.cep',
        mask(infoUsuarioClone?.endereco?.cep, '#####-###'),
      );
      formik.setFieldValue(
        'contato',
        infoUsuarioClone?.contato ? [...infoUsuarioClone?.contato] : [],
      );
      formik.setFieldValue('visita', {
        visita: infoUsuarioClone?.visita?.visita,
        contato: infoUsuarioClone?.visita?.contato,
        anexos: infoUsuarioClone?.visita?.anexos,
      });
      if (infoUsuarioClone?.visita?.dataHora) {
        formik.setFieldValue(
          'visita.dataVisita',
          moment(infoUsuarioClone?.visita?.dataHora, 'YYYYMMDD HH:mm:ss'),
        );
        formik.setFieldValue(
          'visita.horaVisita',
          moment(infoUsuarioClone?.visita?.dataHora, 'YYYYMMDD HH:mm:ss'),
        );
      }
      formik.setFieldValue('socios', infoUsuarioClone?.socios);
      formik.setFieldValue('comercial', infoUsuarioClone?.comercial);
      formik.setFieldValue('dadosBancarios', infoUsuarioClone?.dadosBancarios);
      formik.setFieldValue('documentos', infoUsuarioClone?.documentos);
      setFileListDocumentos(
        infoUsuarioClone?.documentos?.map((item) => {
          return { ...item, imagemId: null };
        }) || [],
      );
      formik.setFieldValue('regioes', infoUsuarioClone?.regioes);
      formik.setFieldValue(
        'produtos.tipoModalidade',
        infoUsuarioClone?.produtos?.tipoModalidade,
      );
      formik.setFieldValue(
        'produtos.produtos',
        infoUsuarioClone?.produtos?.produtos,
      );
      formik.setFieldValue('dadosConsignado', infoUsuarioClone.dadosConsignado);
      checkFormikErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUsuario]);

  useEffect(() => {
    if (formik?.values?.visita?.anexos) {
      setFileList(formik.values?.visita?.anexos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.visita?.anexos]);

  function highlightTab(tabKey) {
    // eslint-disable-next-line
    if (tabKey == 'contato' || tabKey == 'regioes' || tabKey == 'socios') {
      // abas dinamicas
      if (touched[tabKey]) {
        for (let i = 0; i < touched[tabKey]?.lista?.length; i++) {
          for (const key in touched[tabKey]?.lista?.[i]) {
            if (touched[tabKey]?.lista?.[i].hasOwnProperty(key)) {
              if (errors?.[tabKey]?.lista?.[i]?.[key]) {
                return true;
              }
            }
          }
        }
      }
    } else {
      if (touched[tabKey]) {
        for (const key in touched[tabKey]) {
          if (touched[tabKey].hasOwnProperty(key)) {
            if (errors?.[tabKey]?.[key]) {
              return true;
            }
          }
        }
      }
    }
  }

  async function checkFormikErrors() {
    // função para limpar os erros ao inicializar com dados do usuário
    const errors = await validateForm();
    if (Object.keys(errors).length !== 0) {
      setTouched(setNestedObjectValues(errors, true));
      setErrors({});
    }
  }

  const tabPanels = () =>
    abasAcessiveis.map((aba) => {
      switch (aba.nome) {
        case 'Dados Gerais':
          return (
            <TabPanel>
              <DadosGeraisForm
                formik={formik}
                dadosGeraisContext={dadosGeraisContext}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
          );
        case 'Endereço':
          return (
            <TabPanel>
              <EnderecoForm formik={formik} isReadOnly={isReadOnly} />
            </TabPanel>
          );
        case 'Contato':
          return (
            <TabPanel>
              <ContatoForm
                formik={formik}
                action={action}
                userId={userId}
                contatoContext={contatoContext}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
          );
        case 'Visita':
          // eslint-disable-next-line
          if (formik?.values?.dadosGerais?.tipoCredenciado == 0) {
            return (
              <TabPanel>
                <VisitaForm
                  formik={formik}
                  isReadOnly={isReadOnly}
                  userId={userId}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Sócios':
          // eslint-disable-next-line
          if (formik?.values?.dadosGerais?.tipoCredenciado == 0) {
            return (
              <TabPanel>
                <SociosForm
                  formik={formik}
                  action={action}
                  userId={userId}
                  isReadOnly={isReadOnly}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Comercial':
          // eslint-disable-next-line
          if (formik?.values?.dadosGerais?.tipoCredenciado == 0) {
            return (
              <TabPanel>
                <ComercialForm
                  formik={formik}
                  isReadOnly={isReadOnly}
                  userId={userId}
                  unidadeId={infoUsuario.unidadeId}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Dados Bancários':
          return (
            <TabPanel>
              <DadosBancariosForm
                formik={formik}
                dadosBancariosContext={dadosBancariosContext}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
          );
        case 'Documentos':
          return (
            <TabPanel>
              <DocumentosForm
                formik={formik}
                typeDocumetsContext={typeDocumetsContext}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
          );
        case 'Regiões':
          if (
            // eslint-disable-next-line
            formik?.values?.dadosGerais?.tipoCredenciado == TipoCredenciado.CORRESPONDENTE_ID ||
            // eslint-disable-next-line
            (formik?.values?.dadosGerais?.tipoCredenciado ==
              TipoCredenciado.CONVENIO_ID &&
              formik?.values?.dadosGerais?.subTipoCredenciado ===
              SubTipoConvenio.CONSIGNADO_PRIVADO_ID &&
              !formik?.values?.dadosConsignado?.restrito) ||
            // eslint-disable-next-line
            (formik?.values?.dadosGerais?.tipoCredenciado ==
              TipoCredenciado.CONVENIO_ID &&
              formik?.values?.dadosGerais?.subTipoCredenciado ===
              SubTipoConvenio.CIA_ELETRICA_ID)
          ) {
            return (
              <TabPanel>
                <RegiaoForm
                  formik={formik}
                  userId={userId}
                  regioes={regioes}
                  setRegiao={setRegiao}
                  isReadOnly={isReadOnly}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Produtos':
          // eslint-disable-next-line
          if (regioes.filter((regiao) => regiao.opera).length != 0) {
            return (
              <TabPanel>
                <ProdutosForm
                  userId={userId}
                  formik={formik}
                  produtosRegiao={produtosRegiao}
                  isReadOnly={isReadOnly}
                  tiposModalidade={dadosCredenciamentoContext?.tipoModalidade}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Unidades':
          if (
            // eslint-disable-next-line
            formik?.values?.dadosGerais?.tipoCredenciado != 1 ||
            // eslint-disable-next-line
            formik?.values?.dadosGerais?.subTipoCredenciado != 1
          ) {
            return (
              <TabPanel>
                <UnidadesForm
                  formik={formik}
                  produtosRegiao={produtosRegiao}
                  tiposModalidade={dadosCredenciamentoContext?.tipoModalidade}
                  isReadOnly={isReadOnly}
                  setRegiao={setRegiao}
                />
              </TabPanel>
            );
          }
          return null;
        case 'Consignado Privado':
          if (
            // eslint-disable-next-line
            formik?.values?.dadosGerais?.tipoCredenciado == 1 &&
            // eslint-disable-next-line
            formik?.values?.dadosGerais?.subTipoCredenciado == 3
          ) {
            return (
              <TabPanel>
                <ConsignadoPrivadoForm
                  formik={formik}
                  dadosBancariosContext={dadosBancariosContext}
                  isReadOnly={isReadOnly}
                />
              </TabPanel>
            );
          }
          return null;
        default:
          return null;
      }
    });

  return (
    <>
      <Overlay>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <Tabs>
              <TabList>
                {abasAcessiveis.map((aba, i) => {
                  if (
                    (aba?.nome !== 'Regiões' ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.tipoCredenciado == TipoCredenciado.CORRESPONDENTE_ID ||
                      (formik?.values?.dadosGerais?.tipoCredenciado ===
                        TipoCredenciado.CONVENIO_ID &&
                        formik?.values?.dadosGerais?.subTipoCredenciado ===
                        SubTipoConvenio.CONSIGNADO_PRIVADO_ID &&
                        !formik?.values?.dadosConsignado?.restrito) ||
                        (formik?.values?.dadosGerais?.tipoCredenciado ==
                          TipoCredenciado.CONVENIO_ID &&
                          formik?.values?.dadosGerais?.subTipoCredenciado ===
                          SubTipoConvenio.CIA_ELETRICA_ID)
                        ) &&
                    (aba?.nome !== 'Produtos' ||
                      // eslint-disable-next-line
                      regioes.filter((regiao) => regiao.opera).length != 0) &&
                    (aba?.nome !== 'Comercial' ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.tipoCredenciado == 0) &&
                    (aba?.nome !== 'Unidades' ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.tipoCredenciado != 1 ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.subTipoCredenciado != 1) &&
                    (aba?.nome !== 'Visita' ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.tipoCredenciado == 0) &&
                    (aba?.nome !== 'Sócios' ||
                      // eslint-disable-next-line
                      formik?.values?.dadosGerais?.tipoCredenciado == 0) &&
                    (aba?.nome !== 'Consignado Privado' ||
                      (formik?.values?.dadosGerais?.tipoCredenciado === 1 &&
                        formik?.values?.dadosGerais?.subTipoCredenciado === 3))
                  ) {
                    return (
                      <Tab key={i}>
                        <span
                          className={
                            highlightTab(aba.key) || errors[aba.key]
                              ? 'tab-error'
                              : ''
                          }
                        >
                          {aba.nome}
                        </span>
                      </Tab>
                    );
                  } else {
                    return null;
                  }
                })}
              </TabList>

              <div className="react-tabs__content-holder">{tabPanels()}</div>
            </Tabs>
          </Form>
        </FormikProvider>
      </Overlay>
    </>
  );
}

export default TabsComponent;
