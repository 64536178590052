import React from 'react';
import { Link } from 'react-router-dom';

import useInspinia from '../../hooks/useInspinia';

import TarifaProvider from '../../contexts/TarifaContext';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import TarifasList from './TarifasList';
import TarifasForm from './TarifasForm';
import { Breadcrumb } from 'antd';

function Tarifas() {
  useInspinia();

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Parametrização</Breadcrumb.Item>
          <Breadcrumb.Item>Tarifa</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <TarifaProvider>
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <TarifasList />
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <TarifasForm />
            </div>
          </div>
        </TarifaProvider>
      </Content>
    </Main>
  );
}

export default Tarifas;
