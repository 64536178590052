import React from 'react';
import { useFormik, Formik } from 'formik';
import { Form } from 'formik-antd';
import { Select } from 'antd';

import Button from '../../../../components/Button';

import { ReactComponent as EraseIcon } from '../../../../assets/images/icons/erase.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/icons/search.svg';
import { ReactComponent as CaretDownFilled } from '../../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';

const { Option } = Select;

function FilterParametroProdutos() {
  const { setFieldValue, handleSubmit, resetForm, values } = useFormik({
    initialValues: {},
    onSubmit: async (data) => {
      console.log(data);
    },
  });

  return (
    <div className="row">
      <div className="col-lg-12">
        <Formik initialValues={{ filtroEstagio: '' }} onSubmit={handleSubmit}>
          <Form>
            <div className="filter-parametro-produtos">
              <div className="row">
                <div className="col-lg-9 col-campos">
                  <div className="row">
                    <div className="col">
                      <Form.Item
                        name="grupoProduto"
                        label="Filtrar por grupo produto"
                      >
                        <Select
                          mode="multiple"
                          name="grupoProduto"
                          showArrow={true}
                          suffixIcon={<CaretDownFilled />}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          value={values.grupoProduto}
                          onChange={(value) =>
                            setFieldValue('grupoProduto', value)
                          }
                        >
                          <Option key={1}>Energia</Option>
                          <Option key={2}>CP</Option>
                          <Option key={3}>CDC</Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col">
                      <Form.Item name="produto" label="Filtrar produto">
                        <Select
                          mode="multiple"
                          name="produto"
                          showArrow={true}
                          suffixIcon={<CaretDownFilled />}
                          value={values.produto}
                          onChange={(value) => setFieldValue('produto', value)}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          <Option key={1}>Energia Coelbra</Option>
                          <Option key={2}>CP Boleto</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 buttons text-right">
                  <Button type="primary" htmlType="submit" className="btn-blue">
                    <SearchIcon className="search-icon" />
                    Pesquisar
                  </Button>
                  <br />
                  <Button
                    text="LIMPAR"
                    className="btn-gray"
                    htmlType="reset"
                    onClick={resetForm}
                  >
                    <EraseIcon className="erase-icon" /> Limpar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default FilterParametroProdutos;
