import React, { createContext, useState, useEffect, useCallback } from 'react';
import moment from 'moment';

import {
  exportarPropostasAcompanhamentoCSV,
  imprimirPdfAcompanhamento,
  listarAcompanhamentoPropostas,
  getContextoProposta,
  putModalidade,
  avancarPropostaAssinatura,
} from '../services/acompanhamentopropostaService';

import {
  reanaliseProposta,
  getHistoricoReanalise,
  getLinkAnexoHistoricoReanalise
} from '../services/propostaService';
import { carregarImagem } from '../services/imagemService';
import { getImagem } from '../services/imagemService';
import Modal from '../components/Modal';
import { useFormik } from 'formik';

export const AcompanhamentoPropostaContext = createContext({});

const initialFilters = {
  filtroDinamico: null,
  filtroEtapa: null,
  filtroProduto: null,
  filtroTiposData: null,
  filtroDataFinal: moment(),
  filtroDataInicial: moment().add(-1, 'day'),
  filtroData: [moment().add(-1, 'day'), moment()],
};

function AcompanhamentoPropostaProvider({ children }) {
  const [propostas, setPropostas] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState(null)
  const [contexto, setContexto] = useState({});
  const [tiposData, setTiposData] = useState([]);
  const [tempoRefresh, setTempoRefresh] = useState();

  const [updateList, setUpdateList] = useState(false);

  const formik = useFormik({
    initialValues: initialFilters,
  });

  const { values } = formik;

  const fetchPropostas = useCallback(
    async (props) => {
      if (tempoRefresh) {
        setLoading(true);
        const page = props?.pageIndex + 1;
        if (
          !(
            props?.filter?.filtroDinamico !== '' &&
            /^[0-9]*$/.test(
              props?.filter?.filtroDinamico
                ?.replaceAll('.', '')
                ?.replaceAll('-', ''),
            )
          ) &&
          !(props?.filter?.filtroDataInicial || props?.filter?.filtroDataFinal)
        ) {
          Modal('', 'É necessário informar a data inicial e final', 'warning');
          setLoading(false);
        } else {
          const data = await listarAcompanhamentoPropostas({
            pagina: page || 1,
            ordenacao: props?.sortBy?.[0],
            filtroEtapa: props?.filter.filtroEtapa,
            filtroProduto: props?.filter.filtroProduto,
            filtroTipoData: props?.filter.filtroTipoData,
            filtroDataInicial: props?.filter.filtroDataInicial,
            filtroDataFinal: props?.filter.filtroDataFinal,
            filtroDinamico: props?.filter.filtroDinamico,
          });
          if (data?.itens) {
            setPropostas(data.itens);
          } else {
            setPropostas([]);
          }
          setTotal(data?.total);
          setPageCount(data?.totalPages);
          setLoading(false);
        }
      }
    },
    [tempoRefresh],
  );

  const exportarPropostasCsv = () => {
    setLoading(true);
    const csv = exportarPropostasAcompanhamentoCSV(values).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio-acompanhamento.csv');
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    });

    return csv;
  };

  const exportarPropostasPdf = async () => {
    setLoading(true);
    const response = await imprimirPdfAcompanhamento(values);
    setLoading(false);
    return response.data;
  };

  const fetchContexto = async () => {
    const data = await getContextoProposta();
    setTempoRefresh(data?.atualizarTela);
    setContexto(data);
  };

  useEffect(() => {
    fetchContexto();
  }, []);

  useEffect(() => {
    const tiposData = [
      { id: 0, nome: 'Cadastro' },
      { id: 1, nome: 'Alteração' },
    ];
    setTiposData(tiposData);
  }, []);

  return (
    <AcompanhamentoPropostaContext.Provider
      value={{
        formik,
        propostas,
        total,
        loading,
        setLoading,
        pageCount,
        contexto,
        tiposData,
        filters,
        exportarPropostasCsv,
        exportarPropostasPdf,
        setFilters,
        fetchPropostas,
        putModalidade,
        avancarPropostaAssinatura,
        getHistoricoReanalise,
        tempoRefresh,
        reanaliseProposta,
        carregarImagem,
        getImagem,
        pageIndex,
        setPageIndex,
        getLinkAnexoHistoricoReanalise, 
        search, 
        setSearch,
        setUpdateList,
        updateList
      }}
    >
      {children}
    </AcompanhamentoPropostaContext.Provider>
  );
}

export default AcompanhamentoPropostaProvider;
