import React, { Fragment, useContext, useEffect, useState } from 'react';

import Card from '../../../components/Card';
import Table from '../../../components/FullTable';
import Title from '../../../components/Title';
import data from '../../../data/listagemDeProdutosFormDocObrigatorios.json';
import dataImpressao from '../../../data/listagemDeProdutosFormDocImpressao.json';
import { ReactComponent as IncludeIcon } from '../../../assets/images/icons/include.svg';
import { ReactComponent as IconConfirm } from '../../../assets/images/icons/icon-confirma.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import { ReactComponent as CaretDownFilled } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

import InputCurrency from '../../../components/InputCurrency';
import IconEdit from '../../../components/EditIcon';

import listagemDeProdutosFormDocObrigatorios from '../../../data/listagemDeProdutosFormDocObrigatorios.json';

import Tooltip from '../../../components/tooltip';

import { columnsImpressao } from './columnsImpressao';

import Button from '../../../components/CustomButton';

import { ParametroProdutosContext } from '../../../contexts/ParametroProdutosContext';

import { Formik, Field } from 'formik';

import { Form, Input, Select, Switch } from 'formik-antd';
import './styles.scss';

import validationSchema from './validationSchema';

const { Option } = Select;

const Header = <Title label="Cadastro" />;

function TooltipInfo({ text }) {
  return (
    <Tooltip
      placement="bottom"
      className="tooltip-info"
      overlayClassName="tooltip-parametro-produtos"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

const initialValues = {
  ativo: true,
  codigoProduto: '',
  produto: '',
  grupoProduto: null,
  modalidade: null,
  taxaOperacional: true,
  tipoTaxa: null,
  tipoCobranca: null,
  taxas: null,
  diasAtraso: '',
  tipoTarifa: null,
  valorFixo: 0,
  conveniada: null,
  tabelaJuros: null,
  etapasCredito: null,
  regioes: null,
  formaRecebimento: null,
  valorLiberado: '',
  prazoMaximo: '',
  diaPrimeiroVencimento: null,
  mensagemContigencia: '',
  switchContingencia: true,
};

function ProdutosForm() {
  return (
    <Card title={Header}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={async () => {
          console.log('submit');
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form layout="vertical" className="form-parametros-produtos">
            <FormLayout
              setFieldValue={setFieldValue}
              values={values}
              handleSubmit={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </Card>
  );
}

function FormLayout({ setFieldValue, handleSubmit }) {
  const { parametroProduto, parametroDocumentosObrigatorios } = useContext(
    ParametroProdutosContext,
  );

  const valuesTaxas = {
    tipoTaxa: 1,
    tipoCobranca: 1,
    taxas: '',
    diasAtraso: '',
  };

  const valuesTarifas = {
    tipoTarifa: 1,
    tipoTarifaValor: '',
  };

  const [taxas, setTaxas] = useState([{ ...valuesTaxas }]);
  const [tarifas, setTarifas] = useState([{ ...valuesTarifas }]);

  function handleAddTaxas() {
    setTaxas((state) => [...state, { ...valuesTaxas }]);
  }

  function handleAddTarifas() {
    setTarifas((state) => [...state, { ...valuesTarifas }]);
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Lista documentos',
      accessor: 'listaDocumento',
      Cell: ({ value }) => {
        return <div className="td-align-left">{value}</div>;
      },
    },
    {
      Header: 'Obrigatoriedade',
      accessor: 'obrigatoriedade',
      Cell: ({ value }) => {
        return <Switch checked={value} size="small" onChange={() => {}} />;
      },
    },
    {
      Header: '',
      accessor: 'edit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <IconEdit className="btn-edit-table-documentos" />;
      },
    },
  ];

  useEffect(() => {
    console.log(parametroDocumentosObrigatorios);
    const fields = ['id', 'listaDocumento', 'obrigatoriedade'];
    fields.forEach((field) =>
      setFieldValue(field, parametroDocumentosObrigatorios[field], false),
    );
  }, [parametroDocumentosObrigatorios, setFieldValue]);

  useEffect(() => {
    if (parametroProduto.produto !== null) {
      const fields = [
        'ativo',
        'codigoProduto',
        'produto',
        'grupoProduto',
        'taxaOperacional',
        'tipoTaxa',
        'tipoCobranca',
        'taxas',
        'diasAtraso',
        'tipoTarifa',
        'valorFixo',
        'conveniada',
        'tabelaJuros',
        'etapasCredito',
        'regioes',
        'formaRecebimento',
        'valorLiberado',
        'prazoMaximo',
        'diaPrimeiroVencimento',
        'mensagemContigencia',
        'switchContingencia',
      ];
      fields.forEach((field) =>
        setFieldValue(field, parametroProduto[field], false),
      );
    }
  }, [parametroProduto, setFieldValue]);

  return (
    <Fragment>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="ativo"
            label="Ativo"
            className="form-item-input-switch"
          >
            <Switch name="ativo" size="small" />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3 col-sm-4">
          <div className="form-group">
            <Form.Item name="codigoProduto" label="Código produto">
              <Input name="codigoProduto" disabled placeholder="Cree12323Faz" />
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-2 col-sm-8">
          <div className="form-group">
            <Form.Item name="produto" label="Produto">
              <Input name="produto" placeholder="ex.: CP  energia" />
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-4 col-sm-4">
          <div className="form-group">
            <Form.Item name="grupoProduto" label="Grupo produto">
              <Select
                name="grupoProduto"
                placeholder="Selecione"
                suffixIcon={<CaretDownFilled />}
              >
                <Option value={0}>Grupo Energia</Option>
                <Option value={1}>Opção 2</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-3 col-sm-4">
          <div className="form-group">
            <Form.Item name="modalidade" label="Modalidade">
              <Select
                name="modalidade"
                placeholder="Selecione"
                suffixIcon={<CaretDownFilled />}
              >
                <Option value={'Fisica'}>Fisica</Option>
                <Option value={'Digital'}>Digital</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="taxaOperacional"
            label="Taxas operacionais"
            className="form-item-input-switch"
          >
            <Field name="taxaOperacional">
              {({ field }) => <Switch name="taxaOperacional" size="small" />}
            </Field>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        {taxas.map(() => (
          <>
            <div className="col-lg-8 col-sm-9">
              <div className="form-row">
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <Form.Item name="tipoTaxa" label="Tipo taxa">
                      <Select
                        name="tipoTaxa"
                        placeholder="Selecione"
                        suffixIcon={<CaretDownFilled />}
                      >
                        <Option value={0}>Valor fixo </Option>
                        <Option value={1}>Opção 2 </Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <Form.Item name="tipoCobranca" label="Tipo cobrança">
                      <Select
                        name="tipoCobranca"
                        placeholder="Selecione"
                        suffixIcon={<CaretDownFilled />}
                      >
                        <Option value={0}>Juros por atraso</Option>
                        <Option value={1}>Opção 2</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <Form.Item name="taxas" label="Taxas">
                      <Field name="taxas">
                        {({ field }) => <InputCurrency {...field} />}
                      </Field>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <Form.Item name="diasAtraso" label="Dias atraso">
                      <Field name="diasAtraso">
                        {({ field }) => (
                          <Input
                            name="diasAtraso"
                            className="text-right"
                            placeholder="20"
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}

        <div className="col-lg-3 col-sm-3">
          <Button className="btnAddInputs" onClick={handleAddTaxas}>
            <IconPlusBtn /> TAXA
          </Button>
        </div>
      </div>

      <div className="form-row inputs-add-tarifas">
        <div className="col-lg-4 col-sm-6">
          <label>Tipo de Tarifa</label>
          <div className="form-row">
            {tarifas.map(() => (
              <>
                <div className="col-lg-5 col-sm-5">
                  <div className="form-group">
                    <Form.Item name="tipoTarifa">
                      <Select
                        name="tipoTarifa"
                        suffixIcon={<CaretDownFilled />}
                      >
                        <Option value={0}>Tc</Option>
                        <Option value={1}>opcao 2</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-7 col-sm-7">
                  <div className="form-group">
                    <Form.Item name="tipoTarifaValor">
                      <Field name="valorFixo">
                        {({ field }) => <InputCurrency {...field} />}
                      </Field>
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="col-lg-3 col-sm-3">
          <Button className="btnAddInputs" onClick={handleAddTarifas}>
            <IconPlusBtn /> Tarifa
          </Button>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-4 col-sm-4">
          <div className="form-group">
            <Form.Item name="conveniada" label="Conveniada">
              <Select
                mode="multiple"
                allowClear
                showArrow={true}
                suffixIcon={<CaretDownFilled />}
                name="conveniada"
                placeholder="Selecione"
              >
                <Option value={0}>0c123</Option>
                <Option value={1}>Opção 2</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-2 col-sm-4">
          <div className="form-group">
            <Form.Item name="tabelaJuros" label="Tabela Juros">
              <Select
                name="tabelaJuros"
                placeholder="Selecione"
                suffixIcon={<CaretDownFilled />}
              >
                <Option value={0}>Valor fixo</Option>
                <Option value={1}>Opção 2</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-3 col-sm-4">
          <div className="form-group">
            <Form.Item name="etapasCredito" label="Etapas crédito">
              <Select
                mode="multiple"
                allowClear
                showArrow={true}
                suffixIcon={<CaretDownFilled />}
                name="etapasCredito"
                placeholder="Selecione"
              >
                <Option value={0}>Triagem</Option>
                <Option value={1}>Opção 2</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-3 col-sm-4">
          <div className="form-group">
            <Form.Item name="regioes" label="Regiões">
              <Select
                mode="multiple"
                allowClear
                showArrow={true}
                suffixIcon={<CaretDownFilled />}
                name="regioes"
                placeholder="Selecione"
              >
                <Option value={0}>ES</Option>
                <Option value={1}>SP</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-2 col-sm-4">
          <div className="form-group">
            <Form.Item name="formaRecebimento" label="Forma recebimento">
              <Select
                name="formaRecebimento"
                placeholder="Selecione"
                suffixIcon={<CaretDownFilled />}
              >
                <Option value={'boleto'}>Boleto</Option>
                <Option value={1}>Opção 2</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-2 col-sm-4">
          <div className="form-group">
            <Form.Item name="valorLiberado">
              <label>Valor liberado</label>
              <TooltipInfo text="Parâmetros para contigência do crivo"></TooltipInfo>
              <Field name="valorLiberado">
                {({ field }) => <InputCurrency {...field} />}
              </Field>
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-2 col-sm-3">
          <div className="form-group">
            <Form.Item name="prazoMaximo">
              <label>Prazo Máximo</label>
              <TooltipInfo text="Parâmetros para contigência do crivo"></TooltipInfo>
              <Input
                name="prazoMaximo"
                className="text-right"
                placeholder="25"
              />
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-3 col-sm-4">
          <div className="form-group">
            <Form.Item
              name="diaPrimeiroVencimento"
              className="input-tags-primeiro-vencimento"
              label="Dia 1° vencimento"
            >
              <Select
                className="diaPrimeiroVencimento"
                mode="tags"
                allowClear
                name="diaPrimeiroVencimento"
                placeholder="Selecione"
              >
                <Option value={0}>1</Option>
                <Option value={1}>2</Option>
                <Option value={2}>3</Option>
                <Option value={3}>4</Option>
                <Option value={4}>5</Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-3 col-sm-5">
          <div className="form-group">
            <Form.Item
              name="mensagemcontingenciaSwitch"
              className="form-item-input-com-switch"
              label={
                <>
                  Mensagem contigência
                  <Switch name="switchContingencia" size="small" />
                </>
              }
            >
              <Input
                name="mensagemContigencia"
                placeholder="*Sujeito a análise de crédito"
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-11">
          <div className="form-row">
            <div className="col-lg-6 col-md-10">
              <div className="title-tabela-docs">
                <div className="Container  ContainerColor mt-1"></div>
                <h4 className="titleMsg">Documentos obrigatórios </h4>
              </div>
              <div className="tabela-documentos tabelaObrigatoriedade">
                <Table
                  data={data}
                  columns={columns}
                  pagination={false}
                  filter={false}
                />
                <table className="table table-final-add">
                  <tbody>
                    <tr>
                      <td>
                        <p>0002</p>
                      </td>
                      <td>
                        <Form.Item name="listaDocumento" label="">
                          <Input
                            name="listaDocumento"
                            className="listaDocumento"
                            placeholder="Digite o nome do documento"
                          />
                        </Form.Item>
                      </td>
                      <td>
                        {/* <Form.Item name="obrigatoriedade" label="">
                          <Switch
                            name="obrigatoriedade"
                            size="small"
                            className="obrigatoriedade"
                          />
                        </Form.Item> */}
                      </td>
                      <td>
                        <button className="btn-confirma-table-documentos">
                          <IconConfirm className="confirm-icon" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-6 col-md-10">
              <div className="title-tabela-docs">
                <div className="Container  ContainerColor mt-1"></div>
                <h4 className="titleMsg">Documentos para impressão</h4>
              </div>
              <div className="tabela-documentos tabelaDocumentos">
                <Table
                  data={dataImpressao}
                  columns={columnsImpressao}
                  pagination={false}
                  filter={false}
                />
                <table className="table table-final-add">
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          name="tipoDocumentoImpressao"
                          placeholder="Selecione"
                          showArrow={true}
                          suffixIcon={<CaretDownFilled />}
                        >
                          {listagemDeProdutosFormDocObrigatorios.length > 0 &&
                            listagemDeProdutosFormDocObrigatorios.map(
                              (item) => (
                                <Option
                                  value={item.listaDocumento}
                                  key={item.id}
                                >
                                  {item.listaDocumento}
                                </Option>
                              ),
                            )}
                        </Select>
                      </td>
                      <td>
                        <Select
                          name="tipoDocumentoImpressaoInput"
                          placeholder="Selecione"
                          showArrow={true}
                          suffixIcon={<CaretDownFilled />}
                        >
                          <Option value={0} key={0}>
                            Cliente aceitou
                          </Option>
                          <Option value={1} key={1}>
                            Cliente não aceitou
                          </Option>
                        </Select>
                      </td>
                      <td>
                        <button className="btn-confirma-table-documentos">
                          <IconConfirm className="confirm-icon" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-end pr-3 buttons">
        <Button
          type="reset"
          variant="default"
          onClick={() => console.log('cancelar')}
          style={{ minWidth: '105px' }}
        >
          CANCELAR
        </Button>

        <div className="p-2"></div>

        <Button
          type="submit"
          variant="primary"
          onClick={() => handleSubmit()}
          className="btn-salvar"
        >
          <IncludeIcon className="insert-icon" />
          <span>SALVAR</span>
        </Button>
      </div>
    </Fragment>
  );
}

export default ProdutosForm;
