import moment from 'moment';

import Tooltip from '../../../components/tooltip';
import Table from '../../../components/FullTable';
import Modalidade from '../../../components/Modalidade';
import ButtonSituacao from '../../../components/ButtonSituacao';
import Overlay from '../../../components/Overlay';

import { currencyFormat } from '../../../utils/numberFormatter';
import { cpfFormatter } from '../../../utils/textFormatter';

import { ReactComponent as ReloadIcon } from '../../../assets/images/icons/reload-proposta.svg';
import { ReactComponent as QuestionMark } from '../../../assets/images/icons/question-mark.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as Arrow } from '../../../assets/images/icons/double-arrow.svg';

import { historicoColumns } from './historicoColumns';
import { useContext, useState } from 'react';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { RelatorioEsteiraContext } from '../../../contexts/RelatorioEsteiraContext';
import { Button } from 'antd';

function EsteiraCreditoColumns(setModalReanaliseVisible, setPropostaId) {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    buscarHistoricoProposta,
    buscarHistoricoAcoesProposta,
    setModalNovoFluxoVisible,
    loadNovoFluxo,
  } = useContext(RelatorioEsteiraContext);
  //eslint-disable-next-line
  const [loaded, setLoaded] = useState([]);

  const loadHistoricoAcoes = async (propostaId) => {
    const data = await buscarHistoricoAcoesProposta(propostaId);
    sessionStorage.setItem('historicoAcoesProposta', JSON.stringify(data));

    window.open(`/historico-acoes/${propostaId}`, '_blank');
  };
  const firstUpperCase = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const columns = [
    {
      Header: 'Status',
      accessor: 'situacaoDescricao',
      style: {
        minWidth: 170,
        textAlign: 'center',
      },
      Cell: ({ row }) => {
        const rowContent = () => {
          if (row.original?.situacaoCor) {
            return (
              <div style={{ textAlign: 'center' }}>
                <ButtonSituacao
                  link={`/analise-mesa-credito/${row.original.id}`}
                  bgColor={row?.original.situacaoCor}
                  telaAnterior="/relatorio-esteira-credito"
                >
                  {row?.original.situacaoDescricao}
                </ButtonSituacao>
              </div>
            );
          } else {
            return '';
          }
        };

        if (
          checarPermissao(
            'botao',
            'botao.esteiraCredito.tooltipMotivo',
            'Visualizar',
          ) &&
          row.original.propostaMotivoDescricao
        ) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Tooltip
                placement="bottomRight"
                arrowPointAtCenter
                color="#435361"
                title={
                  <div
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '13px',
                      lineHeight: '28px',
                    }}
                  >
                    {row.original.propostaMotivoDescricao}
                  </div>
                }
              >
                {rowContent()}
              </Tooltip>
            </div>
          );
        } else {
          return <div style={{ textAlign: 'center' }}>{rowContent()}</div>;
        }
      },
    },
    {
      Header: 'Cadastro',
      accessor: 'cadastro',
      style: {
        minWidth: 150,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      Header: 'Alteração',
      accessor: 'ultimaAlteracao',
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY HH:mm')}
        </div>
      ),
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      style: {
        minWidth: 150,
        width: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      style: {
        minWidth: 120,
        width: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{cpfFormatter(value)}</div>
      ),
    },
    {
      Header: 'Cliente',
      accessor: 'cliente',
      style: {
        minWidth: 200,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div
          style={{
            width: '200px',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: 'Tipo',
      accessor: 'tipoCliente',
      style: {
        minWidth: 60,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Modalidade',
      accessor: 'tipoModalidade',
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          <Modalidade value={value} />
        </div>
      ),
    },
    {
      Header: 'Matriz',
      accessor: 'matrizNome',
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          {value}
        </div>
      ),
    },
    {
      Header: 'Agente',
      accessor: 'agente',
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
      ),
    },
  ];

  columns.splice(1, 0, {
    Header: '',
    accessor: 'motivoGrupo',
    style: {
      width: 20,
      minWidth: 20,
      textAlign: 'center',
    },
    Cell: ({ value, row }) => (
      <div>
        {value && (
          <div style={{ textAlign: 'center', paddingRight: '5px' }}>
            <Tooltip
              placement="topRight"
              arrowPointAtCenter
              color="#435361"
              title={
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '20px',
                    textAlign: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    maxWidth: '300px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {value.map((motivo) => {
                      return (
                        <div className="tooltip-title" key={motivo.Motivoid}>
                          {`- ${firstUpperCase(motivo?.nome)}`}
                        </div>
                      );
                    })}
                    {row.original.mensagemMotivo && (
                      <ul className="tooltip-list">
                        <li>{row.original.mensagemMotivo}</li>
                      </ul>
                    )}
                  </div>
                </div>
              }
            >
              <QuestionMark width="20" className="question-icon" />
            </Tooltip>
          </div>
        )}
      </div>
    ),
    disableSortBy: true,
  });

  if (
    checarPermissao('botao', 'botao.esteiraCredito.reanalise', 'Visualizar')
  ) {
    columns.push({
      Header: '',
      accessor: 'permiteReanalise',
      id: 'reanalise',
      style: {
        minWidth: 30,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value, row }) =>
        value === 1 && (
          <div style={{ textAlign: 'center' }}>
            <Tooltip
              placement="bottomRight"
              arrowPointAtCenter
              color="#435361"
              title={
                <div
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '13px',
                    lineHeight: '15px',
                  }}
                >
                  Reanalisar Proposta
                </div>
              }
            >
              <ReloadIcon
                onClick={() => {
                  setPropostaId(row.original.id);
                  setModalReanaliseVisible(true);
                }}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        ),
    });
  }

  if (
    checarPermissao(
      'botao',
      'botao.esteiraCredito.historicoStatus',
      'Visualizar',
    )
  ) {
    columns.push({
      Header: 'Histórico',
      accessor: 'historico',
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ row, value }) => {
        const [historico, setHistorico] = useState([]);
        const [loading, setLoading] = useState(true);
        return (
          <div style={{ textAlign: 'center', textDecoration: 'underline' }}>
            <Tooltip
              placement="bottomRight"
              color="#445566"
              overlayClassName="tooltip-historico-esteira-credito"
              overlayStyle={{ minWidth: '600px' }}
              trigger="click"
              onClick={async () => {
                // eslint-disable-next-line
                const item = loaded.find((item) => item.id == row.original.id);
                if (!item) {
                  const data = await buscarHistoricoProposta(
                    row.original.id,
                    false,
                    'esteiraCredito',
                  );
                  loaded.push({ id: row.original.id, historico: data });
                  setHistorico(data);
                } else {
                  setHistorico(item.historico);
                }
                setLoading(false);
              }}
              title={
                <div className="outsideBorder">
                  <span className="form-row">Proposta: {row.original.id}</span>
                  <span className="form-row">
                    CPF: {cpfFormatter(row.original.cpf)}
                  </span>
                  <div className="tabelaHistorico">
                    <Overlay active={loading}>
                      <Table
                        data={historico}
                        columns={historicoColumns}
                        pagination={false}
                        filter={false}
                      />
                    </Overlay>
                  </div>
                </div>
              }
            >
              <p style={{ cursor: 'pointer' }}>Ver mais</p>
            </Tooltip>
          </div>
        );
      },
    });
  }

  const TooltipInfo = ({ text, id }) => (
    <Tooltip
      placement="top"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo
        className="bullet-info-icon"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          loadHistoricoAcoes(id);
        }}
        width={20}
        height={20}
      />
    </Tooltip>
  );

  const TooltipNovoFluxo = ({ text, id, cpf }) => (
    <Button
      className="btn-novo-fluxo"
      onClick={() => {
        setModalNovoFluxoVisible(true);
        loadNovoFluxo(id, cpf);
      }}
    >
      <Tooltip
        placement="top"
        className="tooltip-info"
        overlayClassName="tooltip-proposta-credito"
        color="#435361"
        title={text}
      >
        <Arrow className="bullet-info-icon" width={20} height={20} />
      </Tooltip>
    </Button>
  );

  columns.push({
    Header: '',
    accessor: 'situacaoId',
    style: {
      minWidth: 40,
      textAlign: 'center',
    },
    Cell: ({ row, value }) => {
      return (
        <div>
          <TooltipInfo text="Histórico de ações" id={row.original.id} />
        </div>
      );
    },
    disableSortBy: true,
  });

  columns.push({
    Header: '',
    accessor: 'novoFluxo',
    style: {
      minWidth: 40,
      textAlign: 'center',
    },
    Cell: ({ row }) => {
      if (row?.original?.novoFluxo) {
        return (
          <div>
            <TooltipNovoFluxo
              text="Acionar novo fluxo"
              id={row.original.id}
              cpf={row.original.cpf}
            />
          </div>
        );
      } else {
        return '';
      }
    },
    disableSortBy: true,
  });
  return columns;
}

export default EsteiraCreditoColumns;
