import React, { useCallback, useMemo } from 'react';
import currency from 'currency.js';

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

function InputDecimal(props) {
  const {
    className = 'ant-input',
    style = { textAlign: 'right' },
    currencyConfig = {
      locale: 'pt-BR',
      style: 'decimal',
      useGrouping: true,
      minimumFractionDigits: 2,
    },
    customInput,
    name,
    id,
    max = Number.MAX_SAFE_INTEGER,
    onChange,
    value,
    disabled = false,
  } = props;

  const fakeChangeEvent = useMemo(
    () => ({
      target: {
        type: 'number',
        name,
        id,
      },
    }),
    [name, id],
  );

  const valueInCents = currency(value).intValue;
  const valueAbsTrunc = Math.trunc(Math.abs(valueInCents));

  if (
    valueInCents !== valueAbsTrunc ||
    !Number.isFinite(valueInCents) ||
    Number.isNaN(valueInCents)
  ) {
    throw new Error(`invalid value property`);
  }

  const handleKeyDown = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (
        (valueInCents === 0 && !VALID_FIRST.test(key)) ||
        (valueInCents !== 0 &&
          !VALID_NEXT.test(key) &&
          keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = valueInCents.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString =
          valueInCents === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }

      fakeChangeEvent.target.value = currency(nextValue / 100).value;
      onChange(fakeChangeEvent);
    },
    [fakeChangeEvent, max, onChange, valueInCents],
  );

  const handleChange = useCallback(() => {}, []);

  const { locale, useGrouping, minimumFractionDigits } = currencyConfig;

  const valueDisplay = currency(valueInCents / 100).value.toLocaleString(
    locale,
    {
      style: 'decimal',
      useGrouping,
      minimumFractionDigits,
    },
  );

  const inputProps = {
    className: className,
    inputMode: 'numeric',
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    style: style,
    value: valueDisplay,
  };

  if (customInput) {
    const customProps = { ...props, ...inputProps };
    delete customProps.customInput;
    delete customProps.currencyConfig;
    const CustomInput = customInput;
    return <CustomInput {...customProps} />;
  }

  return <input {...inputProps} disabled={disabled} />;
}

export default InputDecimal;
