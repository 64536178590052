import React from 'react';

import { ReactComponent as ClipboardIcon } from '../../assets/images/icons/clipboard.svg';

import './styles.scss';

function CardOperatividade({ children, ultimaParcial }) {
  return (
    <div className="card-operatividade">
      <div className="card-operatividade-header">
        <div className="card-operatividade-title">
          <ClipboardIcon className="mr-1" />
          Status Operatividade
        </div>
      </div>
      <div className="card-operatividade-content">{children}</div>
    </div>
  );
}

export default CardOperatividade;
