import React, { useContext, useState } from 'react';
import RelatorioSintetico from './RelatorioSintetico';
import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import RelatorioFollowUpProvider from '../../contexts/RelatorioFollowUpContext';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import './styles.scss';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { isBoolean } from 'lodash';

function RelatoriosProposta() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  const [isFullScreen, setIsFullScreen] = useState(false);

  return (
    <Main style={{display: (isFullScreen && isBoolean(isFullScreen)) && "none"}}>
    <PageHeading 
      style={{display: (isFullScreen && isBoolean(isFullScreen)) && "none"}}
    >
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
          <Breadcrumb.Item>Follow UP</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <div className="row">
          <div className="col">
            <RelatorioFollowUpProvider>
              {checarPermissao(
                'tela',
                'tela.credito.relatorio.followUp',
                'Visualizar',
              ) && (
                <>
                  <RelatorioSintetico setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen}/>
                </>
              )}
            </RelatorioFollowUpProvider>
          </div>
        </div>
      </Content>
    </Main>
  );
}

export default RelatoriosProposta;
