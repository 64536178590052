import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
import Spinner from '../../components/Spinner';
import Modal from '../../components/Modal';
import brand from '../../assets/images/crefazon-logo.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/crefazon-logo-alt.svg';
import { enviarEmailRedefinicao, enviarSmsRedefinicao } from '../../services/usuariosService';
import { Radio } from 'antd';
import './styles.scss';


function EscolhaEnvioRedefinicao() {
  const [loading, setLoading] = useState(false);
  const [tipoEnvio, setTipoEnvio] = useState('');
  const history = useHistory();
  const dadosUsuarioRedefinicao = JSON.parse(localStorage.getItem('dadosRedefinicao'));


  const formik = useFormik({
    initialValues: { },
    validateOnBlur: false,
    onSubmit: async () => {
        setLoading(true);

        dadosUsuarioRedefinicao.tipo = tipoEnvio;
        localStorage.setItem('dadosRedefinicao', JSON.stringify(dadosUsuarioRedefinicao));

        if(tipoEnvio === "sms"){
            await enviarSmsRedefinicao(
                dadosUsuarioRedefinicao.login,
            ).then(()=>{
                history.push('/confirmacao-redefinicao');
                setLoading(false);
            })

        }else{ 
            await enviarEmailRedefinicao(
                dadosUsuarioRedefinicao.login,
            ).then(()=>{
                history.push('/confirmacao-redefinicao');
                setLoading(false);
            })
        }
        
    },
  });

  const openModal = () => {
    Modal('', 'Por gentileza entrar em contato com a Central de Atendimento no e-mail reset@crefaz.com.br para cadastro das informações.', 'warning')
  }

  return (
    <div className="login-container">
      <div className="row h-100">
        <div className="col-lg-6 login-brand">
          <div>
            <div className="brand">
              <img src={brand} alt="logo-crefazon" width="100%" />
            </div>
            <BrandWhite className="brand-white" />
          </div>
        </div>

        <div className="col-lg-6 login-form">
          <div style={{ width: '400px' }}>
            <div className="login-title">
              <h2>Redefinir a senha</h2>
              <div className="underline"></div>
              <p className="info-login" style={{ marginTop: '20px', marginBottom:'0' }}>
              Como você deseja receber o código para redefinir sua senha?
              </p>
            </div>
            <FormikProvider value={formik}>
              <Form layout="vertical" autoComplete="off">
                <div>
                    <Form.Item
                        name="tipoEnvio"
                        className='radio-tipo-envio'
                    >
                    <Radio.Group 
                        value={tipoEnvio}
                        name="tipoEnvio"
                        onChange={(e) =>{
                            setTipoEnvio(e.target.value);
                        }}
                    >
                        <Radio   value={'sms'} disabled={!dadosUsuarioRedefinicao.telefone}> <div className="item-tipo-redefinicao"> {dadosUsuarioRedefinicao.telefone ? `Receber um código de verificação no número ${dadosUsuarioRedefinicao.telefone}`: 'Nenhum número encontrado.'} </div> </Radio>
                        <Radio value={'e-mail'} disabled={!dadosUsuarioRedefinicao.email}> <div className="item-tipo-redefinicao" >{dadosUsuarioRedefinicao.email ? `Receber um código de verificação no e-mail ${dadosUsuarioRedefinicao.email}`: 'Nenhum e-mail encontrado.'} </div> </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
             
                <div className='footer-itens'>
                    <div className='link-reset-col'>
                        <span className="link-reset" onClick={openModal}>
                            NÃO RECONHEÇO AS INFORMAÇÕES ACIMA
                        </span>
                    </div>
                </div>

                <Button
                  htmlType="submit"
                  type="primary"
                  className={`btn-login redefinir ${!tipoEnvio ? 'disabled' : ''}`}
                  block
                  style={{
                    backgroundColor: '#ff7817',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    color: '#263659',
                    fontWeight: 600,
                    height: '50px',
                    marginTop: '35px',
                  }}
                  disabled={!tipoEnvio}
                >
                  {!loading ? (
                    'ENVIAR'
                  ) : (
                    <Spinner color="#26417B" width={4.54} margin={2.27} />
                  )}
                </Button>

                <Button
                  type="primary"
                  className="btn-login"
                  block
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    color: '#ff7817',
                    fontWeight: 600,
                    height: '50px',
                    marginTop: '15px',
                  }}
                  onClick={() => history.push('/login')}
                >
                  CANCELAR
                </Button>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EscolhaEnvioRedefinicao;
