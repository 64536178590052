import moment from 'moment';

import Etapa from '../../../components/Etapa';
import { cnpjFormatter } from '../../../utils/textFormatter';

export const columns = [
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    style: {
      minWidth: 150,
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{cnpjFormatter(value)}</div>
    ),
  },
  {
    Header: 'Nome Fantasia',
    accessor: 'nomeFantasia',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
  },
  {
    Header: 'Tipo Credenciamento',
    accessor: 'tipo',
    style: {
      minWidth: 180,
      textAlign: 'center',
    },
  },
  {
    Header: 'Cidade',
    accessor: 'cidade',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
  },
  {
    Header: 'Estado',
    accessor: 'estado',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
  },
  {
    Header: 'Data Proposta',
    accessor: 'dataProposta',
    style: {
      minWidth: 150,
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Data Alteração',
    accessor: 'dataAlteracao',
    style: {
      minWidth: 150,
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Etapa Credenciamento',
    accessor: 'etapa',
    style: {
      minWidth: 200,
      width: 200,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{<Etapa value={value} />}</div>
    ),
  },
];
