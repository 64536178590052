import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';
import { Button } from 'antd';

import SelectSearch from '../../../components/SearchSelect';

import InputPhone from '../../../components/InputPhone';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import {
  valuesReferencia,
  PropostaCreditoContext,
} from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import validaPhone from '../../../utils/validaPhone';

const { Option } = Select;

function DadosReferenciasForm({ formik, disabledFields }) {
  const { values, touched, setFieldTouched, setFieldValue } = formik;
  const [telefoneAdicional, setTelefoneAdicional] = useState([]);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const { referencia, setReferencia, tipoReferencia, editableField } =
    useContext(PropostaCreditoContext);

  const handleAddReferencia = () => {
    setReferencia((state) => [...state, { ...valuesReferencia }]);
  };

  const handleAddTelefoneAdicional = () => {
    setTelefoneAdicional((state) => [...state, { ...valuesReferencia }]);
  };

  const handleRemoveReferencia = (index) => {
    setReferencia((newArray) => newArray.filter((_, idx) => idx !== index));
    values.contatos.referencia.splice(index, 1);
  };

  const handleRemoveTelefoneAdicional = (index) => {
    setTelefoneAdicional((newArray) =>
      newArray.filter((_, idx) => idx !== index),
    );
    if (values?.contatos?.telefoneAdicionalReferencia) {
      values.contatos.telefoneAdicionalReferencia.splice(index, 1);
    }
  };

  useEffect(() => {
    if (touched?.contatos?.referencia) {
      for (let i = 0; i < 2; i++) {
        setFieldTouched(`contatos.referencia.${i}.nome`, true);
        setFieldTouched(`contatos.referencia.${i}.telefone`, true);
        setFieldTouched(`contatos.referencia.${i}.grau`, true);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [touched?.contatos?.referencia]);

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">
          Dados de Referências Pessoais do Cliente
        </div>
      </div>

      <div className="form-row input-dinamicos">
        {referencia &&
          referencia.map((item, i) => (
            <Fragment key={i}>
              <div className="col-md-4 col-lg-4 col-xl-4">
                <Form.Item
                  name={`contatos.referencia.${i}.nome`}
                  label="Nome"
                  validate={(value) => {
                    let error;
                    if (!value) {
                      error = 'O nome da referência deve ser preenchida';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Input
                    name={`contatos.referencia.${i}.nome`}
                    disabled={
                      (acesso ? !editableField(`contatos.referencia.nome`) : true)
                      || disabledFields
                    }
                    value={formik?.values?.contatos?.referencia[i]?.nome}
                    onBlur={(e) => {
                      setFieldValue(
                        `contatos.referencia.${i}.nome`,
                        e.target.value,
                      );
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-2">
                <Form.Item
                  name={`contatos.referencia.${i}.telefone`}
                  label="Telefone"
                  validate={(value) => {
                    let error;
                    if (!value) {
                      error = 'O telefone deve ser preenchido';
                    } else if (value && !validaPhone(value)) {
                      error = 'Telefone inválido';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Field name={`contatos.referencia.${i}.telefone`}>
                    {({ field }) => (
                      <InputPhone
                        {...field}
                        maxLength={15}
                        disabled={
                          (acesso
                            ? !editableField(`contatos.referencia.telefone`)
                            : true)
                          || disabledFields
                        }
                        onChange={() => {
                          formik.validateField(
                            `contatos.referencia.${i}.telefone`,
                          );
                        }}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-2">
                <Form.Item
                  name={`contatos.referencia.${i}.grau`}
                  label="Tipo de Referência"
                  validate={(value) => {
                    let error;
                    if (value === null || value < 0 || value === undefined) {
                      error = 'O tipo de referência deve ser preenchido';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Field name={`contatos.referencia.${i}.grau`}>
                    {({ field }) => (
                      <SelectSearch
                        {...field}
                        name={`contatos.referencia.${i}.grau`}
                        onChange={(value) =>
                          setFieldValue(`contatos.referencia.${i}.grau`, value)
                        }
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownAlign={{
                          overflow: { adjustY: 0 },
                        }}
                        optionFilterProp="children"
                        showSearch
                        disabled={
                          (acesso
                            ? !editableField(`contatos.referencia.grau`)
                            : true)
                          || disabledFields
                        }
                      >
                        {tipoReferencia.length > 0 &&
                          tipoReferencia.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.nome}
                            </Option>
                          ))}
                      </SelectSearch>
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-md-1 col-lg-1 col-xl-1">
                <Button
                  onClick={() => handleRemoveReferencia(i)}
                  className="btnRemoveInputs"
                  disabled={
                    (acesso
                      ? referencia.length <= 2 ||
                      !editableField(`contatos.referencia.telefone`)
                      : true)
                    || disabledFields
                  }
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
            </Fragment>
          ))}
        {telefoneAdicional &&
          telefoneAdicional.map((item, i) => (
            <Fragment key={i}>
              <div className="col-md-4 col-lg-4 col-xl-4">
                <Form.Item
                  name={`contatos.telefoneAdicionalReferencia.${i}.nome`}
                  label="Nome"
                  validate={(value) => {
                    let error;
                    if (!value) {
                      error = 'O nome da referência deve ser preenchida';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Input
                    name={`contatos.telefoneAdicionalReferencia.${i}.nome`}
                    disabled={
                      (acesso
                        ? !editableField(`contatos.referencia.adicionar`)
                        : true)
                      || disabledFields
                    }
                    onBlur={(e) => {
                      setFieldValue(
                        `contatos.telefoneAdicionalReferencia.${i}.nome`,
                        e.target.value,
                      );
                    }}
                  />
                </Form.Item>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-2">
                <Form.Item
                  name={`contatos.telefoneAdicionalReferencia.${i}.telefone`}
                  label="Telefone"
                  validate={(value) => {
                    let error;
                    if (!value) {
                      error = 'O telefone deve ser preenchido';
                    } else if (value && !validaPhone(value)) {
                      error = 'Telefone inválido';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Field
                    name={`contatos.telefoneAdicionalReferencia.${i}.telefone`}
                  >
                    {({ field }) => (
                      <InputPhone
                        {...field}
                        disabled={
                          (acesso
                            ? !editableField(`contatos.referencia.adicionar`)
                            : true)
                          || disabledFields
                        }
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-2">
                <Form.Item
                  name={`contatos.telefoneAdicionalReferencia.${i}.grau`}
                  label="Tipo de Referência"
                  validate={(value) => {
                    let error;
                    if (value === null || value < 0 || value === undefined) {
                      error = 'O tipo de referência deve ser preenchido';
                    }
                    return error;
                  }}
                  hasFeedback
                >
                  <Field
                    name={`contatos.telefoneAdicionalReferencia.${i}.grau`}
                  >
                    {({ field }) => (
                      <SelectSearch
                        {...field}
                        name={`contatos.telefoneAdicionalReferencia.${i}.grau`}
                        onChange={(value) =>
                          setFieldValue(
                            `contatos.telefoneAdicionalReferencia.${i}.grau`,
                            value,
                          )
                        }
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownAlign={{
                          overflow: { adjustY: 0 },
                        }}
                        optionFilterProp="children"
                        showSearch
                        disabled={
                          (acesso
                            ? !editableField(`contatos.referencia.adicionar`)
                            : true)
                          || disabledFields
                        }
                      >
                        {tipoReferencia.length > 0 &&
                          tipoReferencia.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.nome}
                            </Option>
                          ))}
                      </SelectSearch>
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-md-1 col-lg-1 col-xl-1">
                <Button
                  onClick={() => handleRemoveTelefoneAdicional(i)}
                  className="btnRemoveInputs"
                  disabled={
                    !editableField(`contatos.referencia.remover`)
                    || disabledFields
                  }
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
            </Fragment>
          ))}
        <div className="col-md-4 col-lg-3 col-xl-3">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={
              editableField('contatos.referencia.telefone')
                ? handleAddReferencia
                : handleAddTelefoneAdicional
            }
            disabled={
              (acesso ? !editableField(`contatos.referencia.adicionar`) : true)
              || disabledFields
            }
          >
            <i className="fa fa-plus mr-2" /> Referência
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosReferenciasForm };
