import React, { useContext, useState, useEffect } from 'react';

import { Select } from 'antd';

import { FormikProvider } from 'formik';

import { Form } from 'formik-antd';
import SelectSearch from '../../../components/SearchSelect';
import DatePicker from '../../../components/DatePicker';
import Modal from '../../../components/Modal';

import { cpfCnpjFormatter } from '../../../utils/textFormatter';
import { formatDate } from '../../../utils/dateFormatter';
import moment from 'moment';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';

import { ReactComponent as CurvedArrowIcon } from '../../../assets/images/icons/curved-arrow-right.svg';
import { ReactComponent as PencilIcon } from '../../../assets/images/icons/pencil.svg';
import { ReactComponent as IconClose } from '../../../assets/images/icons/close-modal-X.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconCalendar } from '../../../assets/images/icons/calendar.svg';

const { Option } = Select;

const DetalhesPosicoes = ({
  positionId,
  nivelIndex,
  perfis,
  hierarquiaId,
  editCurrentHierarquia,
  close,
  setShouldValidateForm,
}) => {
  const contexto = useContext(HierarquiasContext);
  const {
    checarPermissao,
    editingHierarquia,
    listUsuariosResponsavelPosition,
    activeEditHierarquia,
    formDetalhesPosicao,
    setDetalhesPosicaoInfo,
  } = contexto;

  const [positionDetails, setPositionDetails] = useState(null);
  const [positionDetailsUser, setPositionDetailsUser] = useState(null);
  const [allowEditPosition, setAllowEditPosition] = useState(false);

  const [editingHierarquiaPosition, setEditingHierarquiaPosition] =
    useState(false);

  const [
    searchTermListResponsavelPosition,
    setSearchTermListResponsavelPosition,
  ] = useState(null);

  const formik = formDetalhesPosicao;

  const {
    setFieldValue,
    values,
    setValues,
    errors,
    setFieldTouched,
    setFieldError,
    setErrors,
    setTouched,
  } = formik;

  const clearFields = async () => {
    await setValues({});
    await setErrors({});
    await setTouched({});
  };

  const editPosition = () => {
    activeEditHierarquia(hierarquiaId);
    setEditingHierarquiaPosition(true);
    setAllowEditPosition(true);
  };

  const moreDetailsUser = () => {
    if (positionDetailsUser?.id) {
      window.open(
        `/cadastro-usuarios/${positionDetailsUser?.usuarioId}?visualizacao=true`,
        '_blank',
      );
    }
  };

  const hierarquia = contexto.searchHierarquiaById(hierarquiaId);
  const nivel = hierarquia?.hierarquiaNivel?.[nivelIndex]?.id;

  const editPositionDetalhes = () => {
    let validateEditDetalhes = true;
    let bodyValidate = {};
    let manualValidation = false;
    if (positionDetailsUser?.vigenciaInicial && values?.fimVigenciaOld) {
      // Validações para edição de uma posição com responsável atual
      if (values?.inicioVigencia && values?.fimVigenciaOld) {
        // Alteração de responsável incluindo um novo
        bodyValidate = {
          usuarioHierarquiaNivelDetalheId: positionDetailsUser?.id,
          dataVigenciaInicial: null,
          dataVigenciaFinal: formatDate(values?.fimVigenciaOld),
        };
        manualValidation = true;
      } else {
        // Finalização do responsável anterior, sem inserir um novo
        bodyValidate = {
          usuarioHierarquiaNivelDetalheId: positionDetailsUser?.id,
          dataVigenciaInicial: formatDate(positionDetailsUser?.vigenciaInicial),
          dataVigenciaFinal: formatDate(values?.fimVigenciaOld),
        };
      }
    } else if (
      values?.inicioVigencia &&
      !positionDetails?.usuarioHierarquiaNivelDetalhe?.usuarioId
    ) {
      // Validações para edição de uma posição que não possui responsável atual
      bodyValidate = {
        usuarioHierarquiaNivelDetalheId: null,
        dataVigenciaInicial: formatDate(values?.inicioVigencia),
        dataVigenciaFinal: null,
      };
    } else if (
      values?.inicioVigencia &&
      positionDetails?.usuarioHierarquiaNivelDetalhe?.usuarioId
    ) {
      manualValidation = true;
    } else {
      // Impedimento de edição da posição, por falta de encaixar em um dos cenários acima
      validateEditDetalhes = false;
    }

    // Validação pelo back-end
    validateEditDetalhes &&
      positionId > 0 &&
      !manualValidation &&
      contexto
        .validateVigenciaResponsavelPosition(
          bodyValidate,
          hierarquiaId,
          nivel,
          positionId,
        )
        .then((response) => {
          if (response) {
            contexto.setVigenciaValida(true);
          } else {
            contexto.setVigenciaValida(false);
            Modal('', 'Data de vigência inválida.', 'warning');
          }
        });

    // Validação pelo front-end
    if (manualValidation) {
      let fimVigenciaAnterior = moment(values?.fimVigenciaOld);
      let inicioVigenciaAtual = moment(values?.inicioVigencia);
      if (
        moment(fimVigenciaAnterior).isValid() &&
        moment(inicioVigenciaAtual).isValid()
      ) {
        if (!moment(inicioVigenciaAtual).isAfter(fimVigenciaAnterior, 'day')) {
          contexto.setVigenciaValida(false);
          Modal('', 'Data de vigência inválida.', 'warning');
        } else {
          contexto.setVigenciaValida(true);
        }
      } else if (
        moment(inicioVigenciaAtual).isValid() &&
        moment(positionDetailsUser?.vigenciaInicial).isValid()
      ) {
        if (
          !moment(inicioVigenciaAtual).isAfter(
            positionDetailsUser?.vigenciaInicial,
            'day',
          )
        ) {
          contexto.setVigenciaValida(false);
          Modal('', 'Data de vigência inválida.', 'warning');
        } else {
          contexto.setVigenciaValida(true);
        }
      }
    }
  };

  const searchResponsavel = (value) => {
    setSearchTermListResponsavelPosition(value);
    contexto.carregarUsuariosResponsavelPosition(value, perfis);
  };

  const validateField = (field) => {
    const possuiResponsavelAnterior =
      !!positionDetails.usuarioHierarquiaNivelDetalhe?.usuarioId;
    const error = 'Campo Obrigatório';

    function removeError(field) {
      const errorsClone = JSON.parse(JSON.stringify(errors));
      delete errorsClone?.[field];
      setErrors(errorsClone);
    }

    if (possuiResponsavelAnterior) {
      if (field === 'fimVigenciaOld') {
        if (!values?.fimVigenciaOld && !errors?.fimVigenciaOld) {
          setFieldError('fimVigenciaOld', error);
        } else {
          removeError(field);
        }
      }
      if (field === 'inicioVigencia') {
        if (
          !values?.inicioVigencia &&
          values?.responsavel &&
          !errors?.inicioVigencia
        ) {
          setFieldError('inicioVigencia', error);
        } else {
          removeError(field);
        }
      }
      if (field === 'responsavel') {
        if (
          !values?.responsavel &&
          values?.inicioVigencia &&
          !errors?.responsavel
        ) {
          setFieldError('responsavel', error);
        } else {
          removeError(field);
        }
      }
    } else {
      if (field === 'inicioVigencia') {
        if (!values?.inicioVigencia && !errors?.inicioVigencia) {
          setFieldError('inicioVigencia', error);
        } else {
          removeError(field);
        }
      }
      if (field === 'responsavel') {
        if (!values?.responsavel && !errors?.responsavel) {
          setFieldError('responsavel', error);
        } else {
          removeError(field);
        }
      }
    }
  };

  useEffect(() => {
    contexto
      .carregaDetalhesPosicaoHierarquia(positionId, nivelIndex, hierarquiaId)
      .then((response) => {
        setPositionDetails(response);
        setPositionDetailsUser(response?.usuarioHierarquiaNivelDetalhe);
        if (!response?.status) {
          if (response?.usuarioHierarquiaNivelDetalhe?.usuarioId) {
            setFieldValue(
              'idResponsavelAnterior',
              response.usuarioHierarquiaNivelDetalhe.usuarioId,
            );
          }
          setFieldValue('responsavel', null);
          setFieldValue('inicioVigencia', null);
          setFieldValue('fimVigencia', null);
          setFieldValue('fimVigenciaOld', null);
        }
        if (
          (!response?.usuarioHierarquiaNivelDetalhe ||
            Object.keys(response?.usuarioHierarquiaNivelDetalhe).length ===
              0) &&
          editingHierarquia &&
          !editCurrentHierarquia
        ) {
          close();
        } else if (
          !response?.usuarioHierarquiaNivelDetalhe ||
          Object.keys(response?.usuarioHierarquiaNivelDetalhe).length === 0
        ) {
          setEditingHierarquiaPosition(true);
          activeEditHierarquia(hierarquiaId);
        }
      });
    setDetalhesPosicaoInfo({ hierarquiaId, positionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  useEffect(() => {
    if (!editCurrentHierarquia) {
      setEditingHierarquiaPosition(false);
    }
  }, [editCurrentHierarquia]);

  useEffect(() => {
    setShouldValidateForm(allowEditPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowEditPosition]);

  useEffect(() => {
    editPositionDetalhes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.inicioVigencia, values.fimVigenciaOld]);

  useEffect(() => {
    if (positionDetails?.usuarioHierarquiaNivelDetalhe?.usuarioId) {
      setAllowEditPosition(false);
    } else {
      setAllowEditPosition(true);
    }
  }, [positionDetails]);

  return (
    <>
      {positionId !== null && positionDetails && (
        <FormikProvider value={formik}>
          <Form>
            <div
              className={
                editingHierarquia ? 'users-info-editing' : 'users-info'
              }
            >
              <button
                className="close-btn"
                onClick={async () => {
                  setErrors({});
                  setValues({});
                  await clearFields();
                  setShouldValidateForm(false);
                  contexto.setVigenciaValida(true);
                  close();
                }}
              >
                <IconClose />
              </button>
              {positionDetails?.nome && (
                <div className="header">
                  <h2>{positionDetails.nome}</h2>
                </div>
              )}
              {positionId > 0 && positionDetailsUser?.usuarioId && (
                <div
                  className={
                    editingHierarquiaPosition
                      ? 'info-container-editing'
                      : 'info-container'
                  }
                >
                  <p>
                    <span>Responsável: </span>
                    {positionDetailsUser?.usuarioNome}
                  </p>
                  <p>
                    <span>CPF: </span>
                    {cpfCnpjFormatter(positionDetailsUser?.cpfCnpj)}
                  </p>
                  <p>
                    <span>Vigência: </span>
                    {moment(positionDetailsUser?.vigenciaInicial).format(
                      'DD/MM/YYYY',
                    )}
                  </p>

                  {editingHierarquiaPosition && allowEditPosition ? (
                    <div>
                      <div className="input-position-details-card">
                        <Form.Item
                          name="fimVigenciaOld"
                          label="Fim Vigência"
                          hasFeedback={false}
                        >
                          <DatePicker
                            suffixIcon={<IconCalendar />}
                            name="fimVigenciaOld"
                            clearIcon={false}
                            placeholder="00/00/0000"
                            id="fimVigenciaOld"
                            disabledDate={(date) => date > moment()}
                            format="DD/MM/YYYY"
                            onBlur={() => {
                              setFieldTouched('fimVigenciaOld', true, false);
                              validateField('fimVigenciaOld');
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ) : (
                    <div className="buttons-container">
                      <button
                        className="button-ver-mais"
                        onClick={moreDetailsUser}
                      >
                        <CurvedArrowIcon color="#FF7817" />
                        VER MAIS
                      </button>
                      {checarPermissao(
                        'botao',
                        'botao.cadastro.hierarquia.editar',
                        'Visualizar',
                      ) &&
                        (editCurrentHierarquia || !editingHierarquia) && (
                          <button
                            className="button-editar"
                            onClick={editPosition}
                            disabled={
                              !checarPermissao(
                                'botao',
                                'botao.cadastro.hierarquia.editar',
                                'Visualizar',
                              )
                            }
                          >
                            <PencilIcon color="#FFFFFF" />
                            EDITAR
                          </button>
                        )}
                    </div>
                  )}
                </div>
              )}
              {editingHierarquiaPosition && allowEditPosition && (
                <div className="input-position-container">
                  <div className="input-position-details-card">
                    <Form.Item name="responsavel" label="Responsável">
                      <SelectSearch
                        name="responsavel"
                        placeholder="Pesquise por CPF ou Nome"
                        optionFilterProp="children"
                        suffixIcon={<IconArrowSelect />}
                        onSearch={searchResponsavel}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        value={values?.responsavel}
                        onBlur={() => {
                          setFieldTouched('responsavel', true, false);
                          validateField('responsavel');
                        }}
                        onChange={(e) => {
                          setFieldValue('responsavel', e);
                        }}
                      >
                        {searchTermListResponsavelPosition?.length >= 3 &&
                          listUsuariosResponsavelPosition?.map(
                            (responsavel, index) => (
                              <Option value={responsavel?.id} key={index}>
                                {`${responsavel?.nome} - ${cpfCnpjFormatter(
                                  responsavel?.cpf,
                                )}`}
                              </Option>
                            ),
                          )}
                      </SelectSearch>
                    </Form.Item>
                  </div>
                  <div className="input-position-details-card">
                    <Form.Item
                      name="inicioVigencia"
                      label="Início Vigência"
                      hasFeedback={false}
                    >
                      <DatePicker
                        name="inicioVigencia"
                        suffixIcon={<IconCalendar />}
                        clearIcon={false}
                        id="inicioVigencia"
                        placeholder="00/00/0000"
                        format="DD/MM/YYYY"
                        disabledDate={(date) => date > moment()}
                        onBlur={() => {
                          setFieldTouched('inicioVigencia', true, false);
                          validateField('inicioVigencia');
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="input-position-details-card">
                    <Form.Item
                      name="fimVigencia"
                      label="Fim Vigência"
                      hasFeedback={false}
                    >
                      <DatePicker
                        name="fimVigencia"
                        suffixIcon={<IconCalendar />}
                        clearIcon={false}
                        placeholder="00/00/0000"
                        format="DD/MM/YYYY"
                        disabledDate={(date) => date > moment()}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </Form>
        </FormikProvider>
      )}
    </>
  );
};

export default DetalhesPosicoes;
