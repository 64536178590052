import React, { createContext, useState, useEffect, useContext } from 'react';

import { useFormik } from 'formik';

import moment from 'moment';

import { cloneDeepWith } from 'lodash';

import { formatDate } from '../utils/dateFormatter';

import {
  carregarHierarquias,
  validarNomeHierarquias,
  carregarContextoHierarquias,
  carregarArvoreHierarquia,
  carregarPosicaoDetalhes,
  carregarPosicaoHistorico,
  validarPerfil,
  validarVigencia,
  criarHierarquia,
  editarHierarquia,
  carregarDependentesPosicao,
} from '../services/hierarquiasService';
import { usuariosAtivos as carregarUsuarios } from '../services/usuariosService';

import Modal from '../components/Modal';

import { ControleAcessoContext } from './ControleAcessoContext';

export const HierarquiasContext = createContext({});

export const HierarquiasProvider = ({ children }) => {
  const { checarPermissao } = useContext(ControleAcessoContext);

  const [primeiroCarregamento, setPrimeiroCarregamento] = useState(true);

  const [dependentes, setDependentes] = useState([]);

  const [filterActive, setFilterActive] = useState(true);
  const [filterText, setFilterText] = useState(null);

  const [hierarquias, setHierarquias] = useState([]);
  const [hierarquiasFiltradas, setHierarquiasFiltradas] = useState([]);

  const [creatingHierarquia, setCreatingHierarquia] = useState(false);
  const [editingHierarquia, setEditingHierarquia] = useState(false);
  const [editHierarquiaId, setEditHierarquiaId] = useState(null);

  const [activeButtonsAction, setActiveButtonsAction] = useState(true);

  const [coresNiveisHierarquia, setCoresNiveisHierarquia] = useState([]);
  const [optionsPerfisHierarquia, setOptionsPerfisHierarquia] = useState([]);

  const [detalhesPosicaoInfo, setDetalhesPosicaoInfo] = useState({});

  const [infoModalNotification, setInfoModalNotification] = useState({});
  const [infoModalTransDependencias, setInfoModalTransDependencias] = useState(
    {},
  );
  const [infoModalInativacao, setInfoModalInativacao] = useState({
    visible: false,
    posicao: {},
  });

  const [vigenciaValida, setVigenciaValida] = useState(true);

  const [openPanelsCollapseHierarquia, setOpenPanelsCollapseHierarquia] =
    useState([]);

  const [
    lastSearchUsuarioResponsavelPosition,
    setLastSearchUsuarioResponsavelPosition,
  ] = useState(null);

  const [listUsuariosResponsavelPosition, setListUsuariosResponsavelPosition] =
    useState([]);

  const atualizaListagemHierarquiasFiltrada = (listagem = null) => {
    // Atualiza a listagem de hierarquias com ou sem filtro
    let listagemFiltrada = listagem || hierarquias;

    // Filtra a listagem de acordo com os filtros de ativo/inativos e pesquisa por texto
    listagemFiltrada = listagemFiltrada.filter(
      (hierarquia) =>
        (!filterText ||
          hierarquia.nome
            .toLowerCase()
            .includes(filterText?.trim().toLowerCase())) &&
        ((filterActive && hierarquia.ativo) || !filterActive),
    );

    // Limpa os dados da lista filtrada e mantém apenas o essencial
    listagemFiltrada = listagemFiltrada?.map((hierarquia) => {
      return {
        id: hierarquia.id,
        ativo: hierarquia.ativo,
        novo: hierarquia.novo || false,
        nome: hierarquia.nome,
        cadastro: hierarquia.cadastro,
        ultimaAlteracao: hierarquia.ultimaAlteracao,
      };
    });

    setHierarquiasFiltradas(listagemFiltrada);
  };

  const formDetalhesPosicao = useFormik({
    // Formulário do card de detalhes da posição
    initialValues: {
      idResponsavelAnterior: null,
      idPosicaoEditada: null,
      responsavel: null,
      fimVigenciaOld: null,
      inicioVigencia: null,
      fimVigencia: null,
    },
    validateOnChange: false,
  });

  const validateAllDetalhesPosicao = () => {
    const { setFieldTouched, setFieldError, values, errors } =
      formDetalhesPosicao;
    let error = false;

    if (values?.idResponsavelAnterior) {
      if (!values?.fimVigenciaOld && !errors?.fimVigenciaOld) {
        setFieldError('fimVigenciaOld', 'Campo Obrigatório');
        setFieldTouched('fimVigenciaOld', true);
        error = true;
      }
      if (
        !values?.inicioVigencia &&
        values?.responsavel &&
        !errors?.inicioVigencia
      ) {
        setFieldError('inicioVigencia', 'Campo Obrigatório');
        setFieldTouched('inicioVigencia', true);
        error = true;
      }
      if (
        values?.inicioVigencia &&
        !values?.responsavel &&
        !errors?.responsavel
      ) {
        setFieldError('responsavel', 'Campo Obrigatório');
        setFieldTouched('responsavel', true);
        error = true;
      }
    } else {
      if (!values?.inicioVigencia && !errors?.inicioVigencia) {
        setFieldError('inicioVigencia', 'Campo Obrigatório');
        setFieldTouched('inicioVigencia', true);
        error = true;
      }
      if (!values?.responsavel && !errors?.responsavel) {
        setFieldError('responsavel', 'Campo Obrigatório');
        setFieldTouched('responsavel', true);
        error = true;
      }
    }

    if (Object.keys(errors).length) {
      error = true;
    }

    return error;
  };

  const createNewHierarquia = () => {
    // Criação de uma nova hierarquia, adicionado ao topo dos collapses uma nova hierarquia vazia
    if (!creatingHierarquia) {
      setCreatingHierarquia(true);
      let hierarquiasTemp = [
        {
          id: -1,
          ativo: true,
          novo: true,
          nome: '',
          cadastro: moment().format('YYYY-MM-DD'),
          ultimaAlteracao: null,
          perfis: [],
          hierarquiaNivel: [],
          posicoes: [],
        },
        ...hierarquias,
      ];
      setHierarquias(hierarquiasTemp);
      atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
      setEditingHierarquia(true);
      setEditHierarquiaId(-1);
      setOpenPanelsCollapseHierarquia([...openPanelsCollapseHierarquia, -1]);
      setTimeout(() => {
        document.getElementById('new-hierarquia-input-name')?.focus();
      }, 400);
    }
  };

  const getPosicoesByNivel = (
    hierarquia,
    nivelIndex,
    currentLevel = 0,
    items = [],
  ) => {
    hierarquia.forEach((item) => {
      if (currentLevel === nivelIndex) {
        items = [...items, item];
      } else if (item.posicoes) {
        items = [
          ...getPosicoesByNivel(
            item.posicoes,
            nivelIndex,
            currentLevel + 1,
            items,
          ),
        ];
      }
    });
    return items;
  };

  const searchIndexHierarquia = (id) => {
    return id
      ? hierarquias.map((hierarquia) => hierarquia.id).indexOf(parseInt(id))
      : null;
  };

  const searchHierarquiaById = (id) => {
    // Retorna os dados da hierarquia pelo id.
    return id
      ? hierarquias?.filter((hierarquia) => hierarquia.id === id)[0] ?? null
      : null;
  };

  const getQuantidadeDeNiveis = ({ posicoes = [] }) =>
    // Retorna a quantidade de níveis vinculados a uma posição passando o objeto da hierarquia.
    posicoes.length === 0
      ? 0
      : 1 + Math.max(...posicoes.map(getQuantidadeDeNiveis));

  const updateAtivoPosicoes = (posicoes) => {
    posicoes?.forEach((item) => {
      if (item?.ativo === true || typeof item?.ativo === 'undefined') {
        item['ativo'] = true;
      }
      updateAtivoPosicoes(item?.posicoes);
    });
    return posicoes;
  };

  const updateDadosHierarquiaNiveis = (niveis) => {
    let niveisNew = niveis?.map((nivel, index) => {
      return {
        id: nivel?.id,
        nome: nivel?.nome,
        ordem: index + 1,
      };
    });
    return niveisNew;
  };

  const updatePositionIdNegative = (posicoes) => {
    posicoes?.forEach((posicao) => {
      if (Number(posicao?.id) < 0) {
        posicao.id = null;
      }
      updatePositionIdNegative(posicao?.posicoes);
    });
  };

  const carregaPanelHierarquia = (id, force = false) => {
    if (id && id !== -1) {
      // Hierarquia já criada, com ID diferente de -1
      let index = searchIndexHierarquia(id);

      if (!hierarquias[index]?.posicoes || force) {
        carregarArvoreHierarquia(id).then((response) => {
          let posicoesNew = updateAtivoPosicoes(response.posicoes);
          let niveisNew = updateDadosHierarquiaNiveis(response.hierarquiaNivel);

          hierarquias[index] = {
            ...hierarquias[index],
            ...response,
            hierarquiaNivel: niveisNew,
            posicoes: posicoesNew,
          };
          atualizaListagemHierarquiasFiltrada(hierarquias);
        });
      }
    }
  };

  const activeEditHierarquia = (id) => {
    if (id) {
      setEditingHierarquia(true);
      setEditHierarquiaId(id);
    }
  };

  const cancelEditHierarquia = async () => {
    if (editingHierarquia && editHierarquiaId) {
      setEditingHierarquia(false);

      let indexHierarquiaEdit = searchIndexHierarquia(editHierarquiaId);

      let hierarquiasTemp = hierarquias;

      if (editHierarquiaId > 0) {
        carregaPanelHierarquia(editHierarquiaId, true);
      } else {
        hierarquiasTemp.splice(indexHierarquiaEdit, 1);
        setHierarquias(hierarquiasTemp);
        atualizaListagemHierarquiasFiltrada(hierarquiasTemp);

        if (creatingHierarquia) {
          setCreatingHierarquia(false);
        }
      }
      setEditHierarquiaId(null);
    }
  };

  const editNameHierarquia = async (name, hierarquiaId) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    hierarquiasTemp[indexHierarquia].nome = name;

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const validateNameHierarquia = async (name) => {
    if (name && name.length > 0) {
      const response = await validarNomeHierarquias(name);
      return response.status || false;
    } else {
      return true;
    }
  };

  const addPerfilHierarquia = (id, hierarquiaId) => {
    setEditingHierarquia(true);
    setEditHierarquiaId(hierarquiaId);

    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    hierarquiasTemp[indexHierarquia]?.perfis?.push(id);

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const removePerfilHierarquia = (id, hierarquiaId) => {
    setEditingHierarquia(true);
    setEditHierarquiaId(hierarquiaId);

    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    hierarquiasTemp[indexHierarquia].perfis = hierarquiasTemp[
      indexHierarquia
    ]?.perfis?.filter((perfilId) => perfilId !== id);

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const validateRemovePerfilHierarquia = async (perfis, hierarquiaId) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let posicoesTemp = hierarquias[indexHierarquia]?.posicoes;

    const bodyValidate = {
      id: hierarquiaId,
      perfis,
      posicoes: posicoesTemp,
    };

    const response = await validarPerfil(bodyValidate);
    return response;
  };

  const addNivelHierarquia = async (hierarquiaId) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    hierarquiasTemp[indexHierarquia].hierarquiaNivel.push({
      id: null,
      nome: '',
      ativo: true,
      ordem: hierarquiasTemp[indexHierarquia]?.hierarquiaNivel?.length + 1,
    });

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);

    return true;
  };

  const removeNivelHierarquia = async (index, hierarquiaId) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    hierarquiasTemp[indexHierarquia]?.hierarquiaNivel?.splice(index, 1);

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);

    return true;
  };

  const editNivelHierarquia = async (name, index, hierarquiaId) => {
    if (name) {
      let indexHierarquia = searchIndexHierarquia(hierarquiaId);

      let hierarquiasTemp = hierarquias;
      hierarquiasTemp[indexHierarquia].hierarquiaNivel[index].nome = name;

      setHierarquias(hierarquiasTemp);
      atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
    }

    return true;
  };

  function searchPosition(id, posicoes) {
    let posicaoFound = id
      ? posicoes?.find((children) => children?.id === id) || ''
      : '';
    if (posicaoFound === '' && posicoes?.length > 0) {
      posicoes?.forEach((item) => {
        if (item?.posicoes) {
          let aux = searchPosition(id, item?.posicoes);
          if (aux !== '') {
            posicaoFound = aux;
          }
        }
      });
    }
    return posicaoFound;
  }

  function replacePosition(objectsToReplace, posicoes) {
    return cloneDeepWith(posicoes, (item) => {
      let foundPosition = objectsToReplace
        .map((item) => item.id)
        .indexOf(item?.id);
      if (foundPosition !== -1) {
        item = objectsToReplace[foundPosition];
        return objectsToReplace[foundPosition];
      }
    });
  }

  function inativaSubPosicoes(posicoes) {
    // Inativar as sub-posições da posição inativada.
    if (posicoes.length) {
      posicoes.forEach((posicao) => {
        posicao.ativo = false;
        posicao.dataInativacao = moment().format('YYYYMMDD').toString();
        if (posicao.posicoes) {
          inativaSubPosicoes(posicao.posicoes);
        }
      });
    }
    return posicoes;
  }

  const carregaDetalhesPosicaoHierarquia = async (
    positionId,
    nivelIndex,
    hierarquiaId,
  ) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);
    let nivelId = hierarquias[indexHierarquia]?.hierarquiaNivel[nivelIndex]?.id;
    let positionHierarquiaDetails;

    if (positionId > 0) {
      positionHierarquiaDetails = carregarPosicaoDetalhes(
        hierarquiaId,
        nivelId,
        positionId,
      );
      return positionHierarquiaDetails;
    }
    return { status: false, registered: false };
  };

  const addPositionHierarquia = async (
    parentPositionId = null,
    hierarquiaId,
  ) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;

    const generateRandomNegativeIdPosition = () => {
      return Math.round(Math.random() * -1000 - 1);
    };
    let randomNegativeIdPosition = generateRandomNegativeIdPosition();
    while (
      searchPosition(
        randomNegativeIdPosition,
        hierarquiasTemp[indexHierarquia]?.posicoes,
      )
    ) {
      randomNegativeIdPosition = generateRandomNegativeIdPosition();
    }

    let bodyDefaultPosition = {
      id: randomNegativeIdPosition,
      nome: '',
      ativo: true,
      posicoes: [],
      usuarioId: null,
      vigenciaInicial: moment(new Date()).format('YYYYMMDD'),
    };

    if (!parentPositionId) {
      hierarquiasTemp[indexHierarquia]['posicoes'].push(bodyDefaultPosition);
    } else {
      searchPosition(
        parentPositionId,
        hierarquiasTemp[indexHierarquia]?.posicoes,
      )['posicoes'].push(bodyDefaultPosition);
    }
    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const openModalInativePositionHierarquia = (posicao) => {
    const { positionId, hierarquiaId, nivelIndex, parentId } = posicao;
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);
    let posFound = searchPosition(
      positionId,
      hierarquias[indexHierarquia].posicoes,
    );
    if (posFound?.posicoes?.length === 0) {
      modalNotification(
        'warning',
        `Inativar ${posFound?.nome} e seus dependentes?`,
        [
          { variant: 'gray', type: 'cancel', text: 'CANCELAR' },
          { variant: 'red', type: 'confirm', text: 'INATIVAR' },
        ],
        () => inativePositionHierarquia(positionId, hierarquiaId),
        false,
      );
    } else {
      modalNotification(
        'warning',
        `Existem associações dependentes da ${posFound?.nome}, como deseja prosseguir?`,
        [
          { variant: 'red', type: 'cancel', text: 'INATIVAR' },
          { variant: 'blue', type: 'confirm', text: 'TRANSFERIR' },
        ],
        () => {
          setInfoModalTransDependencias({
            visible: true,
            hierarquiaId,
            hierarquiaIndex: indexHierarquia,
            hierarquiaPositionId: positionId,
            nivelIndex,
            parentId,
          });
          cancelModalNotification();
        },
        async () => {
          // Busca dados de dependentes e envia para a modal pelo context
          setInfoModalNotification((value) => {
            return { ...value, visible: false };
          });
          triggerModalConfirmaInativacao(
            {
              hierarquiaId,
              nivelIndex,
              positionId,
            },
            null,
            () => {
              inativePositionHierarquia(positionId, hierarquiaId);
            },
          );
        },
      );
    }
  };

  async function triggerModalConfirmaInativacao(
    { hierarquiaId, nivelIndex, positionId },
    posicoesFiltradas = null,
    confirmaInativacao,
  ) {
    const hierarquia = searchHierarquiaById(hierarquiaId);
    const nivelId = hierarquia.hierarquiaNivel[nivelIndex].id;
    const dependentesPosicao = await carregarDependentesPosicao(
      hierarquiaId,
      nivelId,
      positionId,
    );
    const dependentes =
      posicoesFiltradas !== null
        ? dependentesPosicao[0].posicoes.filter((item) => {
            return posicoesFiltradas.includes(item.id);
          })
        : dependentesPosicao[0].posicoes;
    const posicao = { ...dependentesPosicao[0], posicoes: dependentes };
    setInfoModalInativacao({
      visible: true,
      hierarquiaId,
      positionId,
      nivelId,
      posicao: posicao ?? {},
      confirmaInativacao,
    });
  }

  function inativePositionHierarquia(positionId, hierarquiaId) {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);
    let posFound = searchPosition(
      positionId,
      hierarquias[indexHierarquia].posicoes,
    );
    posFound.ativo = false;
    posFound.dataInativacao = moment().format('YYYYMMDD').toString();
    posFound.posicoes = inativaSubPosicoes(posFound.posicoes);
    cancelModalNotification();
  }

  const editPositionHierarquia = async (name, positionId, hierarquiaId) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;

    searchPosition(
      positionId,
      hierarquiasTemp[indexHierarquia]?.posicoes,
    ).nome = name;

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const validateVigenciaResponsavelPosition = async (
    body,
    hierarquiaId,
    nivelId,
    positionId,
  ) => {
    const response = await validarVigencia(
      body,
      hierarquiaId,
      nivelId,
      positionId,
    );
    return !(response?.status === false || false);
  };

  const editPositionDetalhes = async (
    hierarquiaId,
    positionId,
    usuarioAnterior = null,
    fimVigenciaOld = null,
    responsavel = null,
    inicioVigencia = null,
  ) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);

    let hierarquiasTemp = hierarquias;
    let positionHerarquiaTemp = searchPosition(
      positionId,
      hierarquiasTemp[indexHierarquia]?.posicoes,
    );

    if (usuarioAnterior && fimVigenciaOld) {
      positionHerarquiaTemp['usuarioAnterior'] = {
        id: usuarioAnterior,
        vigenciaFinal: fimVigenciaOld
          ? moment(fimVigenciaOld).format('YYYYMMDD')
          : null,
      };
    }

    positionHerarquiaTemp['usuarioId'] = responsavel;
    positionHerarquiaTemp['vigenciaInicial'] = inicioVigencia
      ? moment(inicioVigencia).format('YYYYMMDD')
      : null;

    setHierarquias(hierarquiasTemp);
    atualizaListagemHierarquiasFiltrada(hierarquiasTemp);
  };

  const carregaHistoricoPosicaoHierarquia = async (
    positionId,
    nivelIndex,
    hierarquiaId,
  ) => {
    let indexHierarquia = searchIndexHierarquia(hierarquiaId);
    let nivelId = hierarquias[indexHierarquia]?.hierarquiaNivel[nivelIndex]?.id;

    let positionHierarquiaHistory;

    if (positionId > 0) {
      positionHierarquiaHistory = carregarPosicaoHistorico(
        hierarquiaId,
        nivelId,
        positionId,
      );
    }

    return positionHierarquiaHistory;
  };

  function atualizaDetalhesPosicao(idPosicao, update, hierarquia) {
    // Atualiza os dados de detalhes da posição no payload
    if (hierarquia?.id === idPosicao) {
      hierarquia = { ...hierarquia, ...update };
      delete hierarquia.usuarioNome;
    } else {
      hierarquia.posicoes = hierarquia?.posicoes.map(function (item) {
        return atualizaDetalhesPosicao(idPosicao, update, item);
      });
    }
    return hierarquia;
  }

  function removePosition(idPosicao, hierarquia) {
    // Remove as posições do item desejado
    if (hierarquia.posicoes.filter((item) => item.id === idPosicao).length) {
      hierarquia.posicoes = hierarquia.posicoes.filter(
        (item) => item.id !== idPosicao,
      );
    } else {
      hierarquia.posicoes = hierarquia.posicoes.map(function (item) {
        return removePosition(idPosicao, item);
      });
    }
    return hierarquia;
  }

  const saveHierarquia = async () => {
    const { values } = formDetalhesPosicao;
    if (vigenciaValida) {
      if (Object.keys(detalhesPosicaoInfo).length) {
        editPositionDetalhes(
          detalhesPosicaoInfo?.hierarquiaId,
          detalhesPosicaoInfo?.positionId,
          values?.idResponsavelAnterior,
          values?.fimVigenciaOld,
          values?.responsavel,
          values?.inicioVigencia,
        );
      }
      setActiveButtonsAction(false);

      let indexHierarquia = searchIndexHierarquia(editHierarquiaId);

      let hierarquiaTemp = JSON.parse(
        JSON.stringify(hierarquias[indexHierarquia]),
      );


      delete hierarquiaTemp?.cadastro;
      delete hierarquiaTemp?.ultimaAlteracao;

      // Atualização do objeto de hierarquia com detalhes de posição alterados
      const { values: detalhesPosicao, setValues: limparDetalhesPosicao } =
        formDetalhesPosicao;

      if (detalhesPosicao.idPosicaoEditada) {
        let detalhesPosicaoPayload;

        if (!detalhesPosicao.fimVigenciaOld) {
          // Criando novo responsável (sem responsável anteriormente)
          detalhesPosicaoPayload = {
            usuarioId: detalhesPosicao.responsavel,
            vigenciaInicial: moment(detalhesPosicao.inicioVigencia).isValid()
              ? formatDate(detalhesPosicao.inicioVigencia)
              : null,
          };
        } else if (
          detalhesPosicao.idResponsavelAnterior &&
          detalhesPosicao.responsavel
        ) {
          // Editando responsável
          detalhesPosicaoPayload = {
            usuarioId: detalhesPosicao.responsavel,
            vigenciaInicial: moment(detalhesPosicao.inicioVigencia).isValid()
              ? formatDate(detalhesPosicao.inicioVigencia)
              : null,
            usuarioAnterior: {
              id: detalhesPosicao.idResponsavelAnterior,
              vigenciaFinal: formatDate(detalhesPosicao.fimVigenciaOld),
            },
          };
        } else {
          // Removendo responsável
          detalhesPosicaoPayload = {
            usuarioAnterior: {
              id: detalhesPosicao.idResponsavelAnterior,
              vigenciaFinal: formatDate(detalhesPosicao.fimVigenciaOld),
            },
            usuarioId: null,
            vigenciaInicial: null,
          };
        }
        hierarquiaTemp = atualizaDetalhesPosicao(
          detalhesPosicao.idPosicaoEditada,
          detalhesPosicaoPayload,
          hierarquiaTemp,
        );
      }

      updatePositionIdNegative(hierarquiaTemp?.posicoes);
      if (!hierarquiaTemp.nome) {
        Modal('', 'O campo nome da hierarquia é obrigatório', 'warning');
        setActiveButtonsAction(true);
        return null;
      }
      if (creatingHierarquia) {
        // Salvamento e criação de uma nova hierarquia
        delete hierarquiaTemp?.id;
        delete hierarquiaTemp?.novo;
        criarHierarquia(hierarquiaTemp)
          .then((response) => {
            if (response[0]?.status) {
              modalNotification('success', 'Registro incluído com sucesso!');

              // Recebe e define o ID da nova hierarquia criada pela rota POST
              let newHierarquiaId = response[0]?.hierarquiaId;

              // Substitui o ID -1 (de nova hierarquia) pelo novo ID recebido
              hierarquias[indexHierarquia].id = newHierarquiaId;
              carregaPanelHierarquia(newHierarquiaId, true);

              // Abre e mantém aberto o collapse/panel da hierarquia que acabou de ser criada
              setOpenPanelsCollapseHierarquia([
                ...openPanelsCollapseHierarquia,
                newHierarquiaId,
              ]);

              setCreatingHierarquia(false);
              setEditingHierarquia(false);
              setEditHierarquiaId(null);
            }
            setActiveButtonsAction(true);
          })
          .catch(() => {
            setActiveButtonsAction(true);
          });
      } else if (editingHierarquia && editHierarquiaId) {
        // Salvamento de edição de uma hierarquia existente
        editarHierarquia(editHierarquiaId, hierarquiaTemp)
          .then((response) => {
            if (response?.status) {
              modalNotification('success', 'Registro atualizado com sucesso!');
              limparDetalhesPosicao({});
              carregaPanelHierarquia(editHierarquiaId, true);
              setEditingHierarquia(false);
              setEditHierarquiaId(null);
            }
            setActiveButtonsAction(true);
          })
          .catch(() => {
            setActiveButtonsAction(true);
          });
      }
    } else {
      Modal('', 'Data de vigência inválida.', 'warning');
    }
  };

  const modalNotification = (
    type,
    message,
    buttons,
    actionConfirm,
    actionCancel,
  ) => {
    setInfoModalNotification({
      visible: true,
      type: type,
      message: message,
      buttons: buttons,
      actionConfirm: actionConfirm,
      actionCancel: actionCancel,
    });
  };

  const cancelModalNotification = async () => {
    await setInfoModalNotification({ visible: false });
    return true;
  };

  const carregarUsuariosResponsavelPosition = (search, perfis) => {
    // Recebe o termo de pesquisa, para buscar os usuários compatíveis
    if (search?.length >= 3) {
      if (
        lastSearchUsuarioResponsavelPosition === null ||
        search.substr(0, 3).toUpperCase() !==
          lastSearchUsuarioResponsavelPosition.substr(0, 3).toUpperCase()
      ) {
        carregarUsuarios({ filtro: search, perfis }).then((response) => {
          setListUsuariosResponsavelPosition(response);
        });
      }
      setLastSearchUsuarioResponsavelPosition(search);
    }
  };

  useEffect(() => {
    // Sempre que o filtro de ativos é acionado e não for o primeiro carregamento, ele atualiza a listagem de hierarquias
    !primeiroCarregamento && atualizaListagemHierarquiasFiltrada();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterActive]);

  useEffect(() => {
    // Ao iniciar o contexto aciona a rota para carregar hierarquias e popula os respectivos estados necessários
    carregarHierarquias().then((response) => {
      // Ordenação das hierarquias por ordem alfabética
      let hierarquiaOrdenada = response.sort((a, b) => {
        let x = a?.nome?.toUpperCase();
        let y = b?.nome?.toUpperCase();

        return x === y ? 0 : x > y ? 1 : -1;
      });

      setHierarquias(hierarquiaOrdenada);
      atualizaListagemHierarquiasFiltrada(hierarquiaOrdenada);
      setPrimeiroCarregamento(false);
    });
    carregarContextoHierarquias().then((response) => {
      setCoresNiveisHierarquia(response.corNivelHierarquia);
      setOptionsPerfisHierarquia(response.perfil);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HierarquiasContext.Provider
      value={{
        // Estados (state)
        filterActive,
        setFilterActive,
        setFilterText,
        creatingHierarquia,
        hierarquiasFiltradas,
        hierarquias,
        coresNiveisHierarquia,
        optionsPerfisHierarquia,
        editingHierarquia,
        editHierarquiaId,
        setEditingHierarquia,
        setEditHierarquiaId,
        infoModalNotification,
        openPanelsCollapseHierarquia,
        activeButtonsAction,
        listUsuariosResponsavelPosition,
        infoModalTransDependencias,
        setHierarquias,
        dependentes,
        setDependentes,
        infoModalInativacao,
        setInfoModalInativacao,
        vigenciaValida,
        setVigenciaValida,
        setDetalhesPosicaoInfo,
        // Funções
        triggerModalConfirmaInativacao,
        getPosicoesByNivel,
        getQuantidadeDeNiveis,
        searchHierarquiaById,
        checarPermissao,
        atualizaListagemHierarquiasFiltrada,
        searchIndexHierarquia,
        searchPosition,
        createNewHierarquia,
        carregaPanelHierarquia,
        addPerfilHierarquia,
        removePerfilHierarquia,
        cancelEditHierarquia,
        saveHierarquia,
        editNameHierarquia,
        validateNameHierarquia,
        addNivelHierarquia,
        removeNivelHierarquia,
        editNivelHierarquia,
        modalNotification,
        cancelModalNotification,
        setOpenPanelsCollapseHierarquia,
        activeEditHierarquia,
        addPositionHierarquia,
        inativePositionHierarquia,
        editPositionHierarquia,
        carregarUsuariosResponsavelPosition,
        carregaDetalhesPosicaoHierarquia,
        carregaHistoricoPosicaoHierarquia,
        openModalInativePositionHierarquia,
        setInfoModalTransDependencias,
        validateRemovePerfilHierarquia,
        editPositionDetalhes,
        validateVigenciaResponsavelPosition,
        formDetalhesPosicao,
        removePosition,
        replacePosition,
        validateAllDetalhesPosicao,
      }}
    >
      {children}
    </HierarquiasContext.Provider>
  );
};
export default HierarquiasProvider;
