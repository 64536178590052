import React, { createContext, useState } from 'react';
import { getPermissoes } from '../services/controleAcessoService';
import { validaAcesso } from '../services/authService';
export const ControleAcessoContext = createContext({});

function ControleAcessoProvider({ children }) {
  const [logoutUsuario, setLogoutUsuario] = useState(false);
  const [msgLogoutUsuario, setMsgLogoutUsuario] = useState('');
  const [permissoes, setPermissoes] = useState(null);
   const checarPermissao = (secao, name, acesso) => {
    if (permissoes && Object.keys(permissoes).length > 0) {
      if (permissoes[secao]) {
        return permissoes[secao].find(
          (item) =>
            item.tag === name &&
            item.acesso.find((subitem) => subitem === acesso),
        );
      }
    }
  };

  const iniciaPermissoes = async () => {
    let token = localStorage.getItem('authToken');
    let permissoesLocal = JSON.parse(localStorage.getItem('permissoes'));
    if (
      (!permissoesLocal || !permissoes || permissoesLocal?.token !== token) &&
      token
    ) {
      localStorage.removeItem('permissoes');
      setPermissoes(null);
      const userId = localStorage.getItem("userId");
      let data = await validaAcesso(userId);

      if (data?.data?.logoutUsuario) {
        setLogoutUsuario(data?.data?.logoutUsuario);
        setMsgLogoutUsuario(data?.data?.mensagem);

        const tempPermissoes = { ...data?.data, token };
          localStorage.setItem('permissoes', JSON.stringify(tempPermissoes));
          setPermissoes(tempPermissoes);
      } else {
        await getPermissoes().then((response) => {
          if (response?.logoutUsuario) {
            setLogoutUsuario(response?.logoutUsuario);
            setMsgLogoutUsuario(response?.mensagem);
          }
          const tempPermissoes = { ...response, token };
          localStorage.setItem('permissoes', JSON.stringify(tempPermissoes));
          setPermissoes(tempPermissoes);
        });
      }
    }
  };

  return (
    <ControleAcessoContext.Provider
      value={{
        checarPermissao,
        iniciaPermissoes,
        permissoes,
        setPermissoes,
        logoutUsuario,
        msgLogoutUsuario,
        setLogoutUsuario,
      }}
    >
      {children}
    </ControleAcessoContext.Provider>
  );
}

export default ControleAcessoProvider;
