import React, { useState, useContext } from 'react';
import Button from '../../../components/CustomButton';
import { Form} from 'formik-antd';

import { ReactComponent as CloseTooltip } from '../../../assets/images/icons/X-close-tooltip.svg';
import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Overlay from '../../../components/Overlay';
import InputMoney from '../../../components/InputMoney';
import Tooltip from '../../../components/tooltip';
import { ReactComponent as CalculadoraIcon } from '../../../assets/images/icons/calculadora.svg';
import { CalculadoraContext } from '../../../contexts/CalculadoraContext';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import './styles.scss';

function TooltipCalculadora() {

    const {
        editableField,
        formik,
    } = useContext(AnaliseMesaContext);

    const { values, setFieldValue,setFieldTouched } = formik;

    const {
        calcularLimitesParcelas,
        calcularValoresLimitesPrestacao,
    } = useContext(CalculadoraContext);


    const [applyCalc, setApplyCalc] = useState(false);
    const [loading, setLoading] = useState(false);
    const [prestacaoMax, setPrestacaoMax] = useState(0);
    const [valorDebitoConcorrente, setValorDebitoConcorrente] = useState(null);
    const [valorRenda, setValorRenda] = useState(null);
    const [visibleTooltip, setVisibleTooltip] = useState(false);


    const calculaPrestacaoMaxima = async () => {
        setFieldTouched('calc.valorRenda')

        if(valorRenda !== null && valorRenda !== undefined && valorRenda !== 0){

            setLoading(true)
            let objetoLimitesProduto;

            await calcularValoresLimitesPrestacao(

                values?.operacao?.produtoId,
                values?.id,
                values?.operacao?.convenioId,
                values?.operacao?.tabelaJurosId,
                values?.operacao?.vencimento,
                values?.operacao?.valorContingencia,
                valorRenda,
            ).then((value) => {
                objetoLimitesProduto = value;
                setLoading(false)
            }).catch(() => setLoading(true));



            await calcularLimitesParcelas(
                valorRenda,
                values?.operacao?.produtoId,
                values?.id,
                values?.operacao?.tabelaJurosId,
                values?.operacao?.vencimento,
                objetoLimitesProduto,
                null,
                null,
                values?.operacao?.valorDebitoInterno,
                valorDebitoConcorrente,
                true
            )
            .then((response) => {
                setLoading(false)

                // eslint-disable-next-line
                if(prestacaoMax == 0 && !applyCalc){
                setApplyCalc(!applyCalc)
                }

                if (response.maiorValorLimiteParcela >= 0) {
                setPrestacaoMax(response.maiorValorLimiteParcela)

                }
            })
        }
    };

  return (
    <>
            <Tooltip
              placement="right"
              overlayClassName="tooltip-calc-operacao-mesa-credito"
              overlayStyle={{ minWidth: '397px' }}
              trigger="click"
              visible={visibleTooltip}
              onVisibleChange={() => setVisibleTooltip(!visibleTooltip)}
              title={
                <div className="tooltip-content ant-form-vertical">
                    <Overlay active={loading}>
                    <Card
                        title={<Title label="Cálculo da parcela" />}
                        rightBar={
                            <CloseTooltip onClick={() => setVisibleTooltip(!visibleTooltip)} className='icon-close-tooltip'/>
                        }
                    >
                        <div className='form-row'>
                        <div className="col-lg-12 ">
                            <Form.Item
                            name="calc.valorRenda"
                            label="Renda"
                            validate={() =>
                                {
                                  // eslint-disable-next-line
                                if((valorRenda === null || valorRenda === undefined || valorRenda == 0) && visibleTooltip){
                                    return 'Obrigatório informar renda'
                                }
                                }
                            }
                            >
                            <InputMoney
                                name="calc.valorRenda"
                                className="ant-input"
                                prefix="R$ "
                                placeholder={"R$ 0,00"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={valorRenda}
                                style={{ textAlign: 'right' }}
                                onValueChange={(value) => setValorRenda(value.floatValue) }
                                onBlur={() => setFieldTouched('calc.valorRenda')}
                            />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 ">
                            <Form.Item
                            name="calc.valorDebitoConcorrentes"
                            label="Déb. concorrentes"
                            >
                            <InputMoney
                                name="calc.valorDebitoConcorrentes"
                                className="ant-input"
                                prefix="R$ "
                                decimalScale={2}
                                fixedDecimalScale={true}
                                placeholder={"R$ 0,00"}
                                style={{ textAlign: 'right' }}
                                value={valorDebitoConcorrente}
                                onValueChange={(value) => setValorDebitoConcorrente(value.floatValue) }
                            />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 ">
                            <Form.Item
                            name="calc.valorDebitosInternos"
                            label="Déb. internos"
                            >
                            <InputMoney
                                className="ant-input"
                                prefix="R$ "
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={values?.operacao?.valorDebitoInterno == null ? 0 : values?.operacao?.valorDebitoInterno}
                                style={{ textAlign: 'right' }}
                                disabled
                            />
                            </Form.Item>
                        </div>
                        <div className="col-lg-12 ">
                            <Form.Item
                            name="calc.prestacaoMaxima"
                            label="Prestação máxima"
                            >
                            <InputMoney
                                name="calc.prestacaoMaxima"
                                className="ant-input prestacao-max"
                                prefix="R$ "
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={prestacaoMax}
                                style={{ textAlign: 'left' }}
                                disabled
                            />
                            </Form.Item>
                        </div>

                        <div className='col-buttons-calc col-lg-12 d-flex justify-content-end'>

                            {applyCalc &&(
                            <Button
                                htmlType="button"
                                className="btn-confirm"
                                variant='gray'
                                onClick={() => {
                                setFieldValue('operacao.limitePrestacao', prestacaoMax)
                                setFieldValue('operacao.valorDebitoConcorrente', valorDebitoConcorrente)
                                setVisibleTooltip(!visibleTooltip)
                                setApplyCalc(!applyCalc)
                                setPrestacaoMax(0)
                                setValorRenda(0)
                                setValorDebitoConcorrente(0)
                                }}
                            >
                                Confirmar
                            </Button>
                            )}

                            <Button
                            htmlType="button"
                            className="btnCalc apply"
                            variant='blue'
                            onClick={() => calculaPrestacaoMaxima()}
                            >
                            {applyCalc ? 'Recalcular' :'Aplicar Cálculo'}
                            </Button>
                        </div>
                        </div>
                    </Card>
                    </Overlay>
                </div>
              }
            >
                <Button
                htmlType="button"
                className="btnCalc"
                variant='blue'
                disabled={!editableField('operacao.limitePrestacao')}
              >
                <CalculadoraIcon/> Calculadora
              </Button>

            </Tooltip>
        </>

  );
}

export default TooltipCalculadora;
