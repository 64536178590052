import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  codigoProduto: Yup.string().required('O código do produto é obrigatório'),
  produto: Yup.string().required('O produto é obrigatório'),
  grupoProduto: Yup.mixed().required('O grupo de produto é obrigatorio'),
  tipoTaxa: Yup.mixed().required('O tipo de taxa é obrigatorio'),
  tipoCobranca: Yup.mixed().required('O tipo de cobrança é obrigatorio'),
  taxas: Yup.mixed().required('A taxa é obrigatoria'),
  diasAtraso: Yup.string().required('Dias em atraso é obrigatorio'),
  // tipoTarifa: Yup.mixed().required('O tipo de tarifa é obrigatorio'),
  tipoTarifaValor: Yup.mixed().required('O tipo de tarifa é obrigatorio'),
  modalidade: Yup.mixed().required('A modalidade é obrigatoria'),
  conveniada: Yup.mixed().required('A conveniada é obrigatoria'),
  tabelaJuros: Yup.mixed().required('A tabela de juros é obrigatoria'),
  etapasCredito: Yup.mixed().required('A etapa de crédito é obrigatoria'),
  regioes: Yup.mixed().required('A região é obrigatoria'),
  formaRecebimento: Yup.mixed().required(
    'A forma de recebimento é obrigatoria',
  ),
  valorLiberado: Yup.string().required('O valor liberado é obrigatorio'),
  prazoMaximo: Yup.string().required('O prazo máximo é obrigatorio'),
  diaPrimeiroVencimento: Yup.mixed().required(
    'O dia do primeiro vencimento é obrigatorio',
  ),
});

export default validationSchema;
