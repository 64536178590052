import React, { createContext, useCallback, useState, useEffect } from 'react';

import {
  getContextoBordero,
  listarBorderos,
  getCardsDetalhes,
  listarTabelaPropostasBordero,
} from '../services/borderoService';

const initialFilters = {
  debito: null,
  cnpj: null,
  nomeFantasia: '',
  situacaoBordero: null,
  rangeData: [],
};

export const BorderoContext = createContext({
  data: [],
  paginacaoBorderos: {
    total: 0,
    pageCount: 0,
    loading: false,
  },
  paginacaoTabelaBordero: {
    total: 0,
    pageCount: 0,
    loading: false,
  },
  filters: initialFilters,
  contexto: [],
  cardsBordero: {},
  tabelaBordero: [],
  pageIndex: null,
  fetchBorderos: async (f) => f,
  fetchTabelaBorderos: async (f) => f,
  setFilters: (f) => f,
  setBorderoSelecionado: (f) => f,
  setPageIndex: (f) => f,
});

function BorderoProvider({ children }) {
  const [data, setData] = useState([]);
  const [cardsBordero, setCardsBordero] = useState({});
  const [paginacaoBorderos, setPaginacaoBorderos] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
    loading: false,
  });
  const [paginacaoTabelaBordero, setPaginacaoTabelaBordero] = useState({
    total: 0,
    pageCount: 0,
    currentPage: 0,
    loading: false,
  });
  const [tabelaBordero, setTabelaBordero] = useState([]);
  const [contexto, setContexto] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [borderoSelecionado, setBorderoSelecionado] = useState(null);

  const fetchBorderos = useCallback(async (props) => {
    setPaginacaoBorderos((paginacaoBorderos) => ({
      ...paginacaoBorderos,
      loading: true,
      currentPage: props?.pageIndex,
    }));

    const page = props?.pageIndex + 1;

    const response = await listarBorderos({
      pagina: page || 1,
      ordenacao: props?.sortBy?.[0],
      debito: props?.filter.debito,
      cnpj: props?.filter.cnpj,
      nomeFantasia: props?.filter.nomeFantasia,
      dataInicial:
        props?.filter.rangeData && props?.filter.rangeData[0]
          ? props?.filter.rangeData[0].format('YYYY-MM-DD')
          : '',
      dataFinal:
        props?.filter.rangeData && props?.filter.rangeData[1]
          ? props?.filter.rangeData[1].format('YYYY-MM-DD')
          : '',
      situacaoBordero: props?.filter.situacaoBordero,
    });

    if (response.data?.itens) {
      setData(response.data.itens);
      setPaginacaoBorderos((paginacaoBorderos) => ({
        ...paginacaoBorderos,
        pageCount: response.data.totalPages,
        total: response.data.total,
      }));
    } else {
      setData([]);
    }

    setPaginacaoBorderos((paginacaoBorderos) => ({
      ...paginacaoBorderos,
      loading: false,
    }));
  }, []);

  const fetchTabelaBorderos = useCallback(
    async ({ pageIndex, sortBy }) => {
      setPaginacaoTabelaBordero((paginacaoTabelaBordero) => ({
        ...paginacaoTabelaBordero,
        loading: true,
        currentPage: pageIndex,
      }));

      let page = pageIndex + 1;

      const response = await listarTabelaPropostasBordero({
        pagina: page,
        ordenacao: sortBy[0],
        filtroBorderoId: borderoSelecionado,
      });
      if (response.data.itens) {
        setTabelaBordero(response.data.itens);
        setPaginacaoTabelaBordero((paginacaoTabelaBordero) => ({
          ...paginacaoTabelaBordero,
          pageCount: response.data.totalPages,
          total: response.data.total,
        }));
      } else {
        setTabelaBordero([]);
      }

      setPaginacaoTabelaBordero((paginacaoTabelaBordero) => ({
        ...paginacaoTabelaBordero,
        loading: false,
      }));
    },
    [borderoSelecionado],
  );

  const fetchContexto = async () => {
    const response = await getContextoBordero();
    if (response.data) {
      setContexto(response.data);
    }
  };

  const fetchCardDetalhes = useCallback(async () => {
    const response = await getCardsDetalhes(borderoSelecionado);

    if (response.data) {
      setCardsBordero(response.data);
    }
  }, [borderoSelecionado]);

  useEffect(() => {
    if (borderoSelecionado) {
      fetchCardDetalhes();
    }
  }, [borderoSelecionado, fetchCardDetalhes]);

  useEffect(() => {
    fetchContexto();
  }, []);

  return (
    <BorderoContext.Provider
      value={{
        data,
        paginacaoBorderos,
        paginacaoTabelaBordero,
        filters,
        contexto,
        cardsBordero,
        tabelaBordero,
        fetchBorderos,
        fetchTabelaBorderos,
        setFilters,
        setBorderoSelecionado,
      }}
    >
      {children}
    </BorderoContext.Provider>
  );
}

export default BorderoProvider;
