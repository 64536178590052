import { useContext, useEffect, useState } from 'react';
import { RelatorioControlePropostaContext } from '../../../contexts/RelatorioControlePropostaContext';

import FilterControleProposta from './FiltroRelatorio/filter.js';
import './styles.scss';

import { Field, FormikProvider } from 'formik';
import { Form } from 'formik-antd';
import Title from '../../../components/Title';
import Card from '../../../components/Card';
import InputMultiEmail from '../../../components/InputMultiEmail';
function ControleProposta() {
  const [emails, setEmails] = useState([]);
  const [firstRun, setFirstRun] = useState(true);
  const [relatorioEnviado, setRelatorioEnviado] = useState(false);
  const [onBlurEmailsButton, setOnBlurEmailsButton] = useState(false);
  const [isEmailValido, setIsEmailValido] = useState(false);
  const { formik } = useContext(RelatorioControlePropostaContext);
  const [showInvalidEmailModal, setShowInvalidEmailModal] = useState(false);

  useEffect(() => {
    if (!firstRun && !relatorioEnviado) {
      formik.setFieldValue('emails', emails);
      formik.setFieldTouched('emails');
    }

    setRelatorioEnviado(false)
    setFirstRun(false);
    // eslint-disable-next-line
  }, [emails, onBlurEmailsButton]);

   return (
    <>
      <Card
        title={<Title label="Controle de Propostas" />}
        className="cardControleProposta"
      >
        <FormikProvider value={formik}>
          <div className="form-row">
            <div className="campo-email-controle-proposta">
              <Form.Item
                name="emails"
                label="E-mail de envio do relatório"
                className="form-item"
                hasFeedback
                validate={(value) => {
                  let msgError = '';
                  // eslint-disable-next-line
                  if (!value || value?.length == 0) {
                    msgError = 'Campo obrigatório';
                  }
                  return msgError;
                }}
              >
                <Field name="emails">
                  {({ field }) => (
                    <InputMultiEmail
                      {...field}
                      placeholder="Digite aqui"
                      onBlur={() => {
                        setOnBlurEmailsButton(!onBlurEmailsButton)
                      }}
                      formik={formik}
                      emails={emails}
                      setEmails={setEmails}
                      validateEmail={(email) => {
                        const validMultiEmail =
                          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
                            email,
                          );

                        if (validMultiEmail && email.length >= 1) {
                          setIsEmailValido(true);
                          return true;
                        }
                        setIsEmailValido(false);

                        setShowInvalidEmailModal(true);

                        return false;
                      }}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
          </div>

          <FilterControleProposta
            formik={formik}
            emails={emails}
            setEmails={setEmails}
            showInvalidEmailModal={showInvalidEmailModal}
            isEmailValido={isEmailValido}
            setShowInvalidEmailModal={setShowInvalidEmailModal}
            setRelatorioEnviado={setRelatorioEnviado}
          />
        </FormikProvider>
      </Card>
    </>
  );
}

export default ControleProposta;
