import React, {  useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import Card from '../../components/Card';
import Title from '../../components/Title';
import ModalDiscadorDigitro from '../../components/ModalDiscadorDigitro';

import AnaliseMesaProvider from '../../contexts/AnaliseMesaContext';
import useInspinia from '../../hooks/useInspinia';

import Buttons from './Buttons';
import CrivoComponent from './Crivo';
import TabsComponent from './Tabs';
import InformacoesCliente from './InformacoesCliente';
import EtapaAtual from './EtapaAtual';
import CollapseTabelas from './CollapseTabelas';
import StatusOperatividade from './StatusOperatividade';

import './style.scss';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import CalculadoraProvider from '../../contexts/CalculadoraContext';
import ModalDiscadorAgitel from '../../components/ModalDiscadorAgitel';

function AnaliseMesaCredito() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);

  onbeforeunload = () => {
    localStorage.setItem('paginaAberta', false);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onbeforeunload);
    const signal = setInterval(() => {
      localStorage.setItem('paginaAberta', true); // persistirá o sinal que há uma página aberta, mesmo que outra aba da tela 47 tenha sido fechada
    }, 1000);

    return () => clearInterval(signal);
  }, []);

  return (
    <Main>
      <CalculadoraProvider>
        <AnaliseMesaProvider>
          <PageHeading>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Início</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Crédito</Breadcrumb.Item>
              <Breadcrumb.Item>Proposta</Breadcrumb.Item>
              <Breadcrumb.Item>Análise</Breadcrumb.Item>
            </Breadcrumb>
          </PageHeading>

          <Content>
            <div className="card-mesa-credito">
              {checarPermissao(
                'tela',
                'tela.credito.proposta.mesacredito',
                'Visualizar',
              ) && (
                <>
                  <Card
                    title={<Title label="Análise da mesa de crédito" />}
                    rightBar={<Buttons />}
                  >
                    <div className="body-content">
                      <div className="row">
                        <div className="col-lg-9 col-esquerda">
                          <div className="row cols-topo">
                            <div className="col-status-operatividade">
                              <StatusOperatividade />
                            </div>
                            <div className="col-informacoes-cliente">
                              <InformacoesCliente />
                            </div>
                            <div className="col-etapa-atual">
                              <EtapaAtual />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-tabs">
                              <TabsComponent />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-direita analise-crivo">
                          <CrivoComponent />
                        </div>
                      </div>
                    </div>
                    <div className="row collapse-tabelas">
                      <div className="col-lg-12">
                        <CollapseTabelas />
                      </div>
                    </div>
                  </Card>
                </>
              )}
            </div>
          </Content>

          <ModalDiscadorDigitro />
          <ModalDiscadorAgitel />
        </AnaliseMesaProvider>
      </CalculadoraProvider>
    </Main>
  );
}

export default AnaliseMesaCredito;
