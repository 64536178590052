import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from '../../services/authService';
import api from '../../services/api';
import Main from '../../layouts/Main';
import Card from '../../components/Card';
import Content from '../../components/Content';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { Button, Modal } from 'antd';
import useInspinia from '../../hooks/useInspinia';

import './styles.scss';

import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

function DashBoard() {
  useInspinia();

  const { logoutUsuario, msgLogoutUsuario, setLogoutUsuario } = useContext(
    ControleAcessoContext,
  );
  const history = useHistory();

  const { setPermissoes } = useContext(ControleAcessoContext);
  const logout = () => {
    setVisibleErrorLogin(false);
    setLogoutUsuario(false);
    localStorage.removeItem('permissoes');
    setPermissoes(null);
    signOut();
    history.push('/login');
  };

  const [visibleErrorLogin, setVisibleErrorLogin] = useState(false);

  const validaToken = async () => {
    await api.get('/Usuario/valida-token');
  };

  useEffect(() => {
    setVisibleErrorLogin(logoutUsuario);
  }, [logoutUsuario]);

  useEffect(() => {
    validaToken();
  }, []);

  const [campanhas, setCampanhas] = useState([]);
  const getCampanhas = async () => {
    const { data } = await api.get('campanha');
    let campanhasAux = [];

    const unresolved = data.data?.map( async (element) => { 
      campanhasAux.push({
        id: element.id,
        nome: element.nome,
        link: element.link,
        imagem: '',
      });
      await api.get(element.imagem, { responseType: 'blob' }).then((res) => {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(res.data);
        campanhasAux.map((ele) => {
          if(ele.id === element.id){
            return ele.imagem = imageUrl
          }else{
            return null;
          }
        });

      });
    });
    await Promise.all(unresolved)
    setCampanhas(campanhasAux)
  };
  
  useEffect(() => {
   getCampanhas();
  }, []);
  
  const [visibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
    if(campanhas.length > 0 && visibleModal === false) {
      setVisibleModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campanhas]);
  
  const [modalindex, setModalIndex] = useState(0);
  const fechaModal = () => {
    if(campanhas.length - 1 !== modalindex){
      setModalIndex((prev) => prev + 1)
    } else {
      setVisibleModal(false)
    }
  }
  return (
    <Main>
      {campanhas.length > 0 && <Modal
        centered
        visible={visibleModal}
        onCancel={ fechaModal }
        footer={null}
        className="campanha-modal"
        width={1080}
        closeIcon='X'
        >
        <a 
          href={`https://${campanhas[modalindex].link}`}
          target="_blank" rel="noreferrer">
            <img 
              className='imagemCampanha'
              src={campanhas[modalindex].imagem}
              alt={campanhas[modalindex].nome} 
              />
        </a>
      </Modal>}

      <Modal
        visible={visibleErrorLogin}
        onCancel={() => {
          setVisibleErrorLogin(false);
          if (logoutUsuario) {
            logout();
          }
        }}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">{msgLogoutUsuario}</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {
              setVisibleErrorLogin(false);
              if (logoutUsuario) {
                logout();
              }
            }}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <Content>
        <Card title={<h2>Bem Vindo</h2>}>
          <p>Bem vindo ao sistema Crefaz para Operações de Crédito!</p>
        </Card>
      </Content>
    </Main>
  );
}

export default DashBoard;