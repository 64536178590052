function validaCNPJ(cnpj) {
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  if (cnpj.length !== 14) {
    return false;
  }

  if (/0{14}/.test(cnpj)) {
    return false;
  }

  for (var j = 0; j < 2; ++j) {
    for (var i = 0, n = 0; i < 12 + j; n += cnpj[i] * b[++i - j]);
    // eslint-disable-next-line eqeqeq
    if (cnpj[12 + j] != ((n %= 11) < 2 ? 0 : 11 - n)) {
      return false;
    }
  }

  return true;
}

export default validaCNPJ;
