import React from 'react';

import { object, string, ref } from 'yup';

import { Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';

import { ReactComponent as Brand } from '../../assets/images/login-brand.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/login-brand-white.svg';

import './styles.scss';

const validationForm = object({
  usuario: string().required('O Usuário é obrigatório'),
  senha: string().required('A Senha é obrigatória'),
  novaSenha: string().required('A Nova Senha é obrigatória'),
  confirmacaoSenha: string()
    .required('A Confirmação da Senha é obrigatória')
    .oneOf([ref('novaSenha'), null], 'As senhas não correspondem'),
});

function NovaSenha() {
  return (
    <div className="login-container">
      <div className="row h-100">
        <div className="col-lg-6 login-brand">
          <div>
            <Brand className="brand" />
            <BrandWhite className="brand-white" />
          </div>
        </div>

        <div className="col-lg-6 login-form">
          <div style={{ width: '360px' }}>
            <div className="login-title">
              <h2>Entrar</h2>
              <div className="underline"></div>
            </div>

            <Formik
              initialValues={{
                usuario: '',
                senha: '',
                novaSenha: '',
                confirmacaoSenha: '',
              }}
              validationSchema={validationForm}
              validateOnBlur={false}
              onSubmit={(data) => console.log(data)}
            >
              {(props) => (
                <Form layout="vertical" autoComplete="off">
                  <Form.Item
                    name="usuario"
                    label="Usuário"
                    className="login-item"
                  >
                    <Input
                      type="text"
                      name="usuario"
                      size="large"
                      className="login-input"
                    />
                  </Form.Item>

                  <Form.Item name="senha" label="Senha" className="login-item">
                    <Input
                      type="password"
                      name="senha"
                      size="large"
                      className="login-input"
                    />
                  </Form.Item>

                  <Form.Item
                    name="novaSenha"
                    label="Nova senha"
                    className="login-item"
                  >
                    <Input
                      type="password"
                      name="novaSenha"
                      size="large"
                      className="login-input"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmacaoSenha"
                    label="Confirmação de senha"
                    className="login-item"
                  >
                    <Input
                      type="password"
                      name="confirmacaoSenha"
                      size="large"
                      className="login-input"
                    />
                  </Form.Item>

                  <Button
                    htmlType="submit"
                    type="primary"
                    block
                    style={{
                      backgroundColor: '#ff7817',
                      color: '#263659',
                      fontWeight: 600,
                      border: '1px solid #ff7817',
                      borderRadius: 0,
                      height: '40px',
                      marginTop: '1rem',
                    }}
                  >
                    ENTRAR
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NovaSenha;
