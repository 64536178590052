import React, { useState, useEffect  } from 'react';
import { enviarEmailRedefinicao,enviarSmsRedefinicao } from '../../services/usuariosService';
import Spinner from '../Spinner';


function Timer({type, disabled}) {

    const [timeOver, setTimeOver] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [tentativas, setTentativas] = useState(0)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);


   const SolicitarNovoItem = () => {
    if(tentativas < 5){
      setLoading(true);
      setTentativas(tentativas + 1)

      const dadosUsuarioRedefinicao = JSON.parse(localStorage.getItem('dadosRedefinicao'));
      // eslint-disable-next-line
      if (type == 'e-mail'){
        enviarEmailRedefinicao(
         dadosUsuarioRedefinicao.login
        ).then(() => {
          setSeconds(60);
        }).catch(()=>  setLoading(false))
      }else{
        enviarSmsRedefinicao(
          dadosUsuarioRedefinicao.login
        ).then(() => {
          setSeconds(60);
        }).catch(()=>  setLoading(false))
      }
    } else{
      setError('Limite de 5 tentativas atingido. Por gentileza entrar em contato com a Central de Atendimento no e-mail reset@crefaz.com.br.')
    }
   }


    useEffect(() => {
        const timerInterval = setInterval(() => {
          if (seconds > 0) {
            setLoading(false);
            setTimeOver(false)
            setSeconds(seconds - 1);
          }
          else{
            setTimeOver(true)
          }
        }, 1000);
    
        return () => {
          clearInterval(timerInterval);
        };
      }, [seconds]);
    
      const formattedTime = `${Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
  return (
    <>
        {timeOver ? 
          (loading ? 
            <Spinner color="#ff7817" width={4.54} margin={2.27} /> 

          :(error ? 
            <div className="login-error redefinicao" style={{marginBottom: '25px' }}>{error}</div> 

            :<div
              className={`link-reset-col ${disabled ? 'disabled' : ''}`}
              style={{ marginTop: '15px', marginBottom: '15px' }}
            >
              <span onClick={SolicitarNovoItem} className="link-reset">
                {'Solicitar novo ' + type}
              </span>
            </div>
           )
        ) : (
            <div className='footer-form' style={{ marginTop: '25px' }}>
                <span className='info-login'>{'Solicite um novo ' + type + ' em'}</span>
                <span className='link-login' id='timer' >{formattedTime}</span>
            </div>
          )
        }
    </>
  );
}

export default Timer;
