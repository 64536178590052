import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const integerMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
});

export const decimalMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  allowNegative: false,
  decimalSymbol: ',',
  decimalLimit: 2,
  allowLeadingZeroes: false,
});

export const dateMask = [
  /[0-3]/,
  /[0-9]/,
  '/',
  /[0-1]/,
  /[0-2]/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
