import React, { useState, useEffect, useContext } from 'react';

import { Form, Input, Select, Field } from 'formik-antd';
import InputMask from 'react-text-mask';

import moment from 'moment';

import { CadastroPessoaContext } from '../../../../contexts/CadastroPessoaContext';

import DatePicker from '../../../../components/DatePicker';
import SelectSearch from '../../../../components/SearchSelect';

import { cpfMask } from '../../../../utils/masks';

import TooltipInfo from '../TooltipInfo';
import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

function DadosPessoaisForm({ formik }) {
  const { values, setFieldValue } = formik;
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [acesso, setAcesso] = useState(false);
  const { contexto, listarCidades, listarUfs } = useContext(
    CadastroPessoaContext,
  );

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao('aba', 'aba.cadastroPessoa.cliente.pessoais', 'Editar'),
    );
  }, [checarPermissao]);

  useEffect(() => {
    async function loadEstados() {
      const data = await listarUfs();
      if (data) {
        setEstados(data);
      }
    }

    loadEstados();
  }, [listarUfs]);

  useEffect(() => {
    async function loadCidades(ufId) {
      const data = await listarCidades(ufId);
      setCidades(data);
    }

    if (values?.pessoa?.naturalidadeUfId) {
      loadCidades(values?.pessoa?.naturalidadeUfId);
    }
  }, [values?.pessoa?.naturalidadeUfId, listarCidades]);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-2">
          <Form.Item name="pessoa.cpf" label="CPF" hasFeedback>
            <Field name="pessoa.cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  placeholder="000.000.000-00"
                  className="ant-input"
                  mask={cpfMask}
                  disabled
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-5">
          <Form.Item name="pessoa.nome" label="Nome do Cliente" hasFeedback>
            <Input
              name="pessoa.nome"
              placeholder="Ex.: Davi Martelo leite"
              data-cy="nome-cliente"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.nascimento"
            label="Data de Nascimento"
            hasFeedback
          >
            <DatePicker
              name="pessoa.nascimento"
              format={dateFormat}
              disabledDate={disabledDate}
              placeholder="00/00/0000"
              data-cy="data-nascimento"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item name="pessoa.sexo" label="Sexo" hasFeedback>
            <SelectSearch
              name="pessoa.sexo"
              placeholder="Sexo"
              showSearch
              data-cy="sexo-cliente"
              disabled={acesso}
              onChange={(value) => setFieldValue('pessoa.sexo', value)}
              value={values?.pessoa?.sexo}
            >
              {contexto?.sexo?.map((sexo) => (
                <Option key={sexo.id} value={sexo.id}>
                  {sexo.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-2">
          <Form.Item name="pessoa.rg" label="Número do RG/CNH" hasFeedback>
            <Input
              name="pessoa.rg"
              placeholder="00.000.00.0"
              data-cy="rg"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="pessoa.documentoEmissor"
            label="Orgão Emissor"
            hasFeedback
          >
            <Input
              name="pessoa.documentoEmissor"
              placeholder="SSP"
              data-cy="emissor"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.documentoUFId"
            label="UF de Emissão"
            hasFeedback
          >
            <SelectSearch
              name="pessoa.documentoUFId"
              placeholder="UF de Emissão"
              showSearch
              data-cy="uf-emissao"
              disabled={acesso}
              onChange={(value) => setFieldValue('pessoa.documentoUFId', value)}
              value={values?.pessoa?.documentoUFId}
            >
              {estados?.map((estado) => (
                <Option key={estado.id} value={estado.id}>
                  {estado.uf}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.documentoEmissao"
            label="Data de Emissão"
            hasFeedback
          >
            <DatePicker
              name="pessoa.documentoEmissao"
              format={dateFormat}
              disabledDate={disabledDate}
              placeholder="00/00/0000"
              data-cy="data-emissao"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item name="pessoa.pep" hasFeedback>
            <label>PEP</label>
            <TooltipInfo text="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares considerando assim parentes, na linha direta, até o primeiro grau, assim como o cônjuge, companheiro, enteado, bem como, outras pessoas de seu relacionamento próximo."></TooltipInfo>
            <SelectSearch
              name="pessoa.pep"
              data-cy="pep"
              disabled={acesso}
              onChange={(value) => setFieldValue('pessoa.pep', value)}
              value={values?.pessoa?.pep}
            >
              <Option key={1} value={true}>
                SIM
              </Option>
              <Option key={2} value={false}>
                NÃO
              </Option>
              ))
            </SelectSearch>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item name="pessoa.estadoCivil" label="Estado Civil" hasFeedback>
            <SelectSearch
              name="pessoa.estadoCivil"
              placeholder="Casado"
              showSearch
              data-cy="estado-civil"
              disabled={acesso}
              onChange={(value) => setFieldValue('pessoa.estadoCivil', value)}
              value={values?.pessoa?.estadoCivil}
            >
              {contexto?.estadoCivil?.map((estadoCivil) => (
                <Option key={estadoCivil.id} value={estadoCivil.id}>
                  {estadoCivil.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.nacionalidadeId"
            label="Nacionalidade"
            hasFeedback
          >
            <SelectSearch
              name="pessoa.nacionalidadeId"
              placeholder="Brasil"
              showSearch
              data-cy="pais"
              disabled={acesso}
              onChange={(value) =>
                setFieldValue('pessoa.nacionalidadeId', value)
              }
              value={values?.pessoa?.nacionalidadeId}
            >
              {contexto?.pais?.map((pais) => (
                <Option key={pais.id} value={pais.id}>
                  {pais.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.naturalidadeUfId"
            label="UF Naturalidade"
            hasFeedback
          >
            <SelectSearch
              name="pessoa.naturalidadeUfId"
              placeholder="ES"
              showSearch
              data-cy="uf-naturalidade"
              disabled={acesso}
              onChange={(value) =>
                setFieldValue('pessoa.naturalidadeUfId', value)
              }
              value={values?.pessoa?.naturalidadeUfId}
            >
              {estados?.map((estado) => (
                <Option key={estado.id} value={estado.id}>
                  {estado.uf}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="pessoa.naturalidadeCidadeId"
            label="Cidade Naturalidade"
          >
            <SelectSearch
              name="pessoa.naturalidadeCidadeId"
              placeholder="Vítoria"
              showSearch
              data-cy="cidade-naturalidade"
              disabled={acesso}
              onChange={(value) =>
                setFieldValue('pessoa.naturalidadeCidadeId', value)
              }
              value={values?.pessoa?.naturalidadeCidadeId}
            >
              {cidades?.map((cidade) => (
                <Option key={cidade.id} value={cidade.id}>
                  {cidade.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="pessoa.grauInstrucao"
            label="Grau de Instrução"
            hasFeedback
          >
            <SelectSearch
              name="pessoa.grauInstrucao"
              placeholder="Superior Completo"
              showSearch
              data-cy="grau-instrucao"
              disabled={acesso}
              onChange={(value) => setFieldValue('pessoa.grauInstrucao', value)}
              value={values?.pessoa?.grauInstrucao}
            >
              {contexto?.grauInstrucao?.map((grauInstrucao) => (
                <Option key={grauInstrucao.id} value={grauInstrucao.id}>
                  {grauInstrucao.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item name="pessoa.nomeMae" label="Nome da Mãe" hasFeedback>
            <Input
              name="pessoa.nomeMae"
              placeholder="Ex.: Nome completo"
              data-cy="nome-mae"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        {contexto?.estadoCivil &&
          contexto?.estadoCivil.length > 0 &&
          values.pessoa?.estadoCivil !== null &&
          contexto?.estadoCivil[values.pessoa?.estadoCivil]?.exigeConjuge && (
            <div className="col-lg-6">
              <Form.Item
                name="pessoa.nomeConjuge"
                label="Nome do Cônjuge"
                hasFeedback
              >
                <Input
                  name="pessoa.nomeConjuge"
                  placeholder="Ex.: Nome completo"
                  data-cy="nome-conjuge"
                  disabled={acesso}
                />
              </Form.Item>
            </div>
          )}
      </div>
    </>
  );
}

export default DadosPessoaisForm;
