import { Form } from 'formik-antd';
import { Select, Radio } from 'antd';
import { useContext } from 'react';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import { ReactComponent as IconTrashBtn } from '../../../assets/images/icons/icon-trash2.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import Button from '../../../components/Button';

const { Option } = Select;

function ProdutosForm({
  formik,
  produtosLiberados,
  type,
  setIsFormEdited,
  tiposModalidade,
}) {
  const { values, setFieldValue, getFieldProps } = formik;

  const { formik: formikCredenciado } = useContext(CredenciadoContext);
  const modalidadeProdutoMatriz =
    formikCredenciado?.values?.produtos?.tipoModalidade;

  const handleAddproduto = () => {
    const newProduct = {
      modalidadeOpcoes: null,
      id: null,
      propriedade: {
        nome: '',
        opcoes: [],
      },
    };
    setFieldValue(
      'produtos.produtos',
      values?.produtos?.produtos
        ? [...values?.produtos?.produtos, newProduct]
        : [newProduct],
    );
    setIsFormEdited(true);
  };

  const handleRemoveProduto = (index) => {
    const newList = getFieldProps('produtos.produtos').value;
    newList?.splice(index, 1);
    setFieldValue(`produtos.produtos`, [...newList]);
    setIsFormEdited(true);
  };

  function propriedadeProdutoRegiaoSelecionado(idProduto) {
    let indexProdutoSelecionado = produtosLiberados
      ?.map((produto) => produto.id)
      ?.indexOf(idProduto);

    return Number.isInteger(indexProdutoSelecionado)
      ? produtosLiberados[indexProdutoSelecionado]?.propriedade
      : null;
  }

  return (
    <div className="dados-produto">
      <div className="form-row header-sub-item pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Produto</div>
      </div>
      <div className=" row">
        <div className="col-lg-12 col-modalidade">
          <label className="label-modalidade">Modalidade: </label>
          <Radio.Group
            name="produtos.tipoModalidade"
            value={values?.produtos?.tipoModalidade}
            defaultValue={modalidadeProdutoMatriz}
            onChange={(e) => {
              setFieldValue('produtos.tipoModalidade', e.target.value);
              setIsFormEdited(true);
            }}
            disabled={type === 'read'}
          >
            {tiposModalidade?.map((tipo, index) => {
              return (
                <Radio key={index} value={tipo.id}>
                  {tipo.nome}
                </Radio>
              );
            })}
          </Radio.Group>
        </div>
        {values?.produtos?.produtos &&
          values?.produtos?.produtos.map((_, i) => {
            return (
              <div className="col-lg-12 col-new-info" key={i}>
                <div className="col-lg-5 item-dados">
                  <Form.Item
                    label="Produto"
                    name={`produtos.produtos[${i}].id`}
                  >
                    <Select
                      name={`produtos.produtos[${i}].id`}
                      value={values?.produtos?.produtos[i]?.id}
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      onChange={(value) => {
                        setFieldValue(`produtos.produtos[${i}].id`, value);
                        setFieldValue(
                          `produtos.produtos[${i}].propriedade.opcoes`,
                          null,
                        );
                        setFieldValue(
                          `produtos.produtos[${i}].modalidadeOpcoes`,
                          produtosLiberados.find((item) => item.id === value)
                            .modalidadeOpcoes,
                        );
                        setIsFormEdited(true);
                      }}
                      disabled={type === 'read'}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {produtosLiberados
                        ?.filter((item) =>
                          formikCredenciado?.values?.produtos?.produtos?.find(
                            (produto) => produto.id === item.id,
                          ),
                        )
                        ?.map((produto, index) => {
                          if (
                            values?.produtos?.tipoModalidade === 0
                              ? true
                              : produto.modalidadeOpcoes ===
                                  values?.produtos?.tipoModalidade ||
                                produto.modalidadeOpcoes === 0
                          ) {
                            if (
                              values?.produtos?.produtos
                                ?.map((selecteds) => selecteds.id)
                                ?.indexOf(produto.id) < 0 ||
                              produto.id === values?.produtos?.produtos[i]?.id
                            ) {
                              return (
                                <Option key={index} value={produto.id}>
                                  {produto.nome}
                                </Option>
                              );
                            }else{
                              return false;
                            }
                          }else{
                            return false;
                          }
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-7 col-new-info">
                  {propriedadeProdutoRegiaoSelecionado(
                    values?.produtos?.produtos[i]?.id,
                  ) &&
                    propriedadeProdutoRegiaoSelecionado(
                      values?.produtos?.produtos[i]?.id,
                    )?.nome && (
                      <div className="item-dados col-lg-10">
                        <Form.Item
                          label={
                            propriedadeProdutoRegiaoSelecionado(
                              values?.produtos?.produtos[i]?.id,
                            )?.nome
                          }
                          name={`produtos.produtos[${i}].propriedade.opcoes`}
                        >
                          <Select
                            name={`produtos.produtos[${i}].propriedade.opcoes`}
                            value={
                              values?.produtos?.produtos[i]?.propriedade
                                ?.opcoes || []
                            }
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            allowClear
                            mode="multiple"
                            onChange={(value) => {
                              setFieldValue(
                                `produtos.produtos[${i}].propriedade.opcoes`,
                                value,
                              );
                              setIsFormEdited(true);
                            }}
                            disabled={type === 'read'}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {propriedadeProdutoRegiaoSelecionado(
                              values?.produtos?.produtos[i]?.id,
                            )
                              ?.opcoes?.filter((item) =>
                                formikCredenciado?.values?.produtos?.produtos?.find(
                                  (produto) =>
                                    produto.id ===
                                      values?.produtos?.produtos[i]?.id &&
                                    produto?.propriedade?.opcoes?.includes(
                                      item.id,
                                    ),
                                ),
                              )
                              ?.map((opcao, index) => (
                                <option key={index} value={opcao.id}>
                                  {opcao.nome}
                                </option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}
                  <div className="col-lg-2 col-button-remove">
                    <Button
                      onClick={() => handleRemoveProduto(i)}
                      className="btn-trash"
                      disabled={type === 'read'}
                    >
                      <IconTrashBtn />
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        {values.produtos ? (
          <div className="col-lg-12 col-button">
            <Button
              type="button"
              className="btn-produtos"
              variant="blue"
              onClick={handleAddproduto}
              disabled={type === 'read'}
            >
              <IconPlusBtn />
              Produto
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default ProdutosForm;
