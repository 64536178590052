import { Switch } from 'antd';
import moment from 'moment';

import { currencyFormat } from '../../../../utils/numberFormatter';
import { ReactComponent as IconAnexo } from '../../../../assets/images/icons/icon-anexo.svg';

import { ReactComponent as IconCheck } from '../../../../assets/images/icons/confirm.svg';
import { ReactComponent as IconNegative } from '../../../../assets/images/icons/negative.svg';

import './styles.scss';
export const columnsHistoricoCia = (contexto, validaCompanhiaCampoLimiteParcela) => {
  const columnsHistoricoCiaArray = [
    {
      title: '',
      dataIndex: 'participa',
      key: 'participa',
      render: (value) => (
        <div className="info-status">
          {value ? (
            <IconCheck className="icon-status" />
          ) : (
            <IconNegative className="icon-status" />
          )}
        </div>
      ),
    },
    {
      title: 'Mês',
      dataIndex: 'dataReferencia',
      key: 'referencia',
    },
    {
      title: 'Vencimento',
      dataIndex: 'dataVencimento',
      key: 'vencimento',
      className: 'coluna-vencimento-historico-cia',
      render: (value) => value && moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      render: (value) => currencyFormat(value),
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (value) =>
        contexto?.tipoDebito.find((tipo) => tipo.id === value).nome,
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      key: 'situacao',
      render: (value) =>
        contexto?.situacaoDebito.find((situacao) => situacao.id === value).nome,
    },
    {
      title: 'Descontar',
      dataIndex: 'descontar',
      key: 'descontar',
      render: (value) => {
        return (
          <Switch
            checked={value}
            size="small"
            name="descontar"
            className="switch-red-if-off"
            disabled={contexto?.bloqueiaAcessoSimultaneo}
          />
        );
      },
    },
    {
      title: 'Anexo',
      dataIndex: 'existeComprovante',
      key: 'anexo',
      className: 'cell-icon-anexo',
      disableSortBy: true,
      render: (value) => {
        return value && <IconAnexo />;
      },
    },
  ];

  if (validaCompanhiaCampoLimiteParcela) {
    columnsHistoricoCiaArray.splice(4, 0, 
      {
        title: 'V. Cálculo',
        dataIndex: 'valorCalculo',
        key: 'valorCalculo',
        render: (value) => currencyFormat(value),
      },  
    )
  }

  return columnsHistoricoCiaArray;
};
