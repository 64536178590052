import React, { useContext, useEffect, useState } from 'react';
import { Modal, Checkbox, Input } from 'antd';
import Button from '../Button';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/x-icon.svg';

import { AnaliseMesaContext } from '../../contexts/AnaliseMesaContext';
import Message from '../../components/Modal';

import './styles.scss';

const { TextArea } = Input;

function ModalMotivosDecisao({ isModalVisible, setIsModalVisible }) {
  const [motivos, setMotivos] = useState([]);
  const [todosMotivos, setTodosMotivos] = useState([]);

  const {
    formik,
    listagemMotivosDecisao,
    fetchListagemMotivosEtapas,
    decisaoSelecionada,
    setDecisaoSelecionada,
    motivosSelecionados,
    setMotivosSelecionados,
    situacaoPendencia,
    setObservacao,
    arquivos,
    setIsDecisao,
    handleEnvioFormulario,
    permiteSalvar,
  } = useContext(AnaliseMesaContext);

  useEffect(() => {
    async function loadMotivosDecisao() {
      const data = await listagemMotivosDecisao(decisaoSelecionada);
      setMotivos(data);
      setTodosMotivos(data);
    }

    async function loadMotivosEtapa() {
      const data = await fetchListagemMotivosEtapas(situacaoPendencia);
      setMotivos(data);
      setTodosMotivos(data);
    }

    if (decisaoSelecionada) {
      loadMotivosDecisao();
    }

    if (situacaoPendencia) {
      loadMotivosEtapa();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisaoSelecionada, situacaoPendencia]);

  const handleChangeMotivos = (id) => {
    const findMotivo = motivosSelecionados.find((motivo) => motivo === id);

    if (!!findMotivo) {
      setMotivosSelecionados(
        motivosSelecionados.filter((motivo) => motivo !== id),
      );
    } else {
      setMotivosSelecionados((oldMotivos) => [...oldMotivos, id]);
    }
  };

  const labelButton = () => {
    if (decisaoSelecionada === 2) {
      return 'Negar';
    } else if (decisaoSelecionada === 3) {
      return 'Cancelar';
    } else {
      return 'Pendenciar';
    }
  };

  const submitFormik = () => {
    if (!motivosSelecionados.length) {
      Message('', 'Nenhum motivo foi selecionado', 'warning');
      setIsDecisao(false);
    } else if (arquivos.find((item) => item.documentoId === null)) {
      Message('', 'Selecione o tipo de cada documento inserido', 'warning');
      setIsDecisao(false);
    } else {
      formik.values.anexo = arquivos;
      if (!permiteSalvar() && decisaoSelecionada !== 2) return;
      handleEnvioFormulario();
    }
  };

  return (
    <>
      <Modal
        onCancel={() => {
          setIsModalVisible(false);
          setDecisaoSelecionada(null);
          setMotivosSelecionados([]);
        }
      
      }
        className="modal-motivos"
        visible={isModalVisible}
        footer={[
          <Button
            variant="red"
            className="button-negar mr-2"
            onClick={() => {
              setIsModalVisible(false);
              submitFormik();
            }}
          >
            {' '}
            <CloseIcon /> {labelButton()}
          </Button>,
        ]}
      >
        <h3 className="motivo-title">Motivos</h3>
        <span>Pesquisa</span>
        <div className="pesquisa pb-3">
          <Input
            className="filter-input"
            placeholder="Pesquise pelo motivo"
            suffix={<SearchOutlined />}
            onChange={(e) => {
              const { value } = e.target;
              const searchMotivos = motivos
              .filter((motivo) =>
                motivo.nome
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .includes(
                    value
                      .toLowerCase()
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  )
              );
              setMotivos(value.length>0 ? searchMotivos : todosMotivos);
            }}
          />
        </div>
        <div className="motivos">
          <div class="row">
            {motivos.filter((motivo, index) => {
                const currentIndex = motivos.findIndex(
                  (m) => m.id === motivo.id && m.nome === motivo.nome
                );
                return currentIndex === index;
              }).map(({ id, nome }) => (
              <div className="checkbox-container" key={id}>
                <div className="checkbox">
                  <Checkbox
                    checked={
                      !!motivosSelecionados.find((motivo) => motivo === id)
                    }
                    onChange={() => handleChangeMotivos(id)}
                  />
                </div>
                <div className="checkbox-text">{nome}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="observacao-motivos mt-2">
          <div className="observação-label mb-1">
            <span>Observação</span>
          </div>
          <TextArea
            rows={4}
            onBlur={(e) => setObservacao(e.target.value)}
            placeholder="Digite aqui"
            className="motivo-textarea"
          />
        </div>
      </Modal>
    </>
  );
}

export default ModalMotivosDecisao;
