export function convertBase64ToBlob(
  base64File,
  contentType = 'application/pdf',
  sliceSize = 512,
) {
  // Remover indicadores e manter somente conteúdo do arquivo
  if (base64File.includes('base64,')) {
    base64File = base64File.split('base64,')[1];
  }

  // Converter para blob
  const byteArrays = [];
  const byteChars = window.atob(base64File);
  for (let offset = 0; offset < byteChars.length; offset += sliceSize) {
    const slice = byteChars.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });

  // Gerar URL para o blob
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
}
