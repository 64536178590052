import api from './api';

export const getContextoProposta = async () => {
  let acompanhamentoContexto = sessionStorage.getItem("acompanhamento-contexto");

  if (!acompanhamentoContexto) {
    try {
      const { data } = await api.get('/proposta/acompanhamento-contexto');
      sessionStorage.setItem("acompanhamento-contexto", JSON.stringify(data.data))
      return data.data;
    } catch (error){
      return error;
    }

    }
    acompanhamentoContexto = JSON.parse(acompanhamentoContexto)
    return acompanhamentoContexto;
  };  

export const listarAcompanhamentoPropostas = async ({
  pagina = 1,
  filtroDinamico = null,
  filtroProduto = null,
  filtroEtapa = null,
  filtroTipoData = null,
  filtroDataInicial = null,
  filtroDataFinal = null,
  ordenacao = { id: '', desc: true },
}) => {
  const { data } = await api.post('/proposta/acompanhamento', {
    pagina,
    filtroDinamico,
    filtroProduto,
    filtroEtapa,
    filtroTipoData,
    filtroDataInicial,
    filtroDataFinal,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data.data;
};

export const exportarPropostasAcompanhamentoCSV = async ({
  filtroDinamico = null,
  filtroProduto = null,
  filtroEtapa = null,
  filtroTipoData = null,
  filtroDataInicial = null,
  filtroDataFinal = null,
  ordenacao = { id: '', desc: true },
}) => {
  const { data } = await api.post(
    '/proposta/acompanhamento/exportacao-csv',
    {
      filtroDinamico,
      filtroProduto,
      filtroEtapa,
      filtroTipoData,
      filtroDataInicial,
      filtroDataFinal,
      ordenacao: ordenacao.id,
      ordenacaoAsc: !ordenacao.desc,
    },
    { responseType: 'arraybuffer' },
  );
  return data;
};

export const exportarPropostasAcompanhamentoPDF = async ({
  filtroDinamico = null,
  filtroProduto = null,
  filtroEtapa = null,
  filtroTipoData = null,
  filtroDataInicial = null,
  filtroDataFinal = null,
  ordenacao = { id: '', desc: true },
}) => {
  const data = await api.post('/proposta/acompanhamento/exportacao-pdf', {
    filtroDinamico,
    filtroProduto,
    filtroEtapa,
    filtroTipoData,
    filtroDataInicial,
    filtroDataFinal,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data;
};

export const imprimirPdfAcompanhamento = async ({
  filtroDinamico = null,
  filtroProduto = null,
  filtroEtapa = null,
  filtroTipoData = null,
  filtroDataInicial = null,
  filtroDataFinal = null,
  ordenacao = { id: '', desc: true },
}) => {
  const { data } = await api.post('/proposta/acompanhamento/exportacao-pdf', {
    filtroDinamico,
    filtroProduto,
    filtroEtapa,
    filtroTipoData,
    filtroDataInicial: filtroDataInicial
      ? filtroDataInicial?.format('YYYY-MM-DD')
      : null,
    filtroDataFinal: filtroDataFinal
      ? filtroDataFinal?.format('YYYY-MM-DD')
      : null,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data;
};

export const putModalidade = async (proposta) => {
  const { data } = await api.put(
    `/proposta/modalidade/${proposta.id}`,
    proposta,
  );
  return data.data;
};

export const preAbertura = async (rota) => {
  const { data } = await api.get(rota);
  return data;
};


export const avancarPropostaAssinatura = async (id) => {
  const { data } = await api.put(
    `/proposta/avancar-assinatura/${id}`
  );
  return data;
}