import React, { useContext } from 'react';

import { Formik, Field } from 'formik';
import { Form, Input, DatePicker, Switch, Select } from 'formik-antd';

import InputMask from 'react-text-mask';

import Button from '../../../components/Button';

import { BorderoContext } from '../../../contexts/BorderoContext';

import { cnpjMask } from '../../../utils/masks';

import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as EraseIcon } from '../../../assets/images/icons/erase.svg';

import '../styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];

function Filter() {
  const { contexto, setFilters } = useContext(BorderoContext);
  return (
    <div className="filter-container">
      <Formik
        initialValues={{}}
        onSubmit={(data) => {
          setFilters(data);
        }}
      >
        <Form layout="vertical">
          <div className="row d-flex align-items-center">
            <div className="col-lg-10 col-md-10">
              <div className="form-row">
                <div className="col-lg-10">
                  <Form.Item name="debito" className="form-item-input-switch">
                    <div className="switch-margin">
                      Lojistas em Débito
                      <Switch name="debito" size="small" />
                    </div>
                  </Form.Item>
                </div>
              </div>

              <div className="form-row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <Form.Item name="cnpj" label="CNPJ" className="filter-item">
                      <Field name="cnpj">
                        {({ field }) => (
                          <InputMask
                            {...field}
                            mask={cnpjMask}
                            placeholder="11.111.111/0001-11"
                            className="ant-input"
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <Form.Item
                      name="nomeFantasia"
                      label="Nome Fantasia"
                      className="filter-item"
                    >
                      <Input name="nomeFantasia" placeholder="Digite o nome" />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <Form.Item
                      name="situacaoBordero"
                      label="Situação do Borderô"
                      className="filter-item"
                    >
                      <Select
                        name="situacaoBordero"
                        placeholder="Selecione"
                        mode="multiple"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.map((situacao) => (
                          <Option key={situacao.id} value={situacao.id}>
                            {situacao.situacao}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <Form.Item
                      name="rangeData"
                      label="Data Geração"
                      className="filter-item"
                    >
                      <RangePicker name="rangeData" format={dateFormatList} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2">
              <div className="d-flex flex-column justify-content-end">
                <Button type="submit" variant="blue">
                  <SearchIcon className="search-icon" />
                  <span className="d-md-none d-lg-inline ml-2">Pesquisar</span>
                </Button>

                <span className="p-1"></span>

                <Button type="button" variant="gray" onClick={() => {}}>
                  <EraseIcon className="erase-icon" />
                  <span className="d-md-none d-lg-inline ml-2">Limpar</span>
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Filter;
