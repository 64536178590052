import { useState } from 'react';
import { ReactComponent as Icon } from '../../../assets/images/icons/icon-upload-item2.svg';
import { ReactComponent as Eye } from '../../../assets/images/icons/icon-upload-eye2.svg';
import { ReactComponent as Trashcan } from '../../../assets/images/icons/icon-upload-trashcan2.svg';
import { ReactComponent as Download } from '../../../assets/images/icons/icon-upload-download.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { Modal, Button } from 'antd';

function UploadItem(
  file,
  handlePreview,
  handleRemove,
  handleDownload,
  disabled = false,
) {
  const [deleteFileModalVisible, setDeleteFileModalVisible] = useState(false);
  return (
    <>
      <Modal
        visible={deleteFileModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {
          setDeleteFileModalVisible(false);
        }}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Deseja realmente excluir o arquivo?</div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => setDeleteFileModalVisible(false)}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => {
              setDeleteFileModalVisible(false);
              handleRemove(file);
            }}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>
      <div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture-card">
        <div class="ant-upload-list-item-info">
          <span class="ant-upload-span">
            <a
              class="ant-upload-list-item-thumbnail ant-upload-list-item-file"
              href=" "
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.url &&
              file.url !== ' ' &&
              file.type &&
              file.type !== 'application/pdf' ? (
                <img
                  src={file.url}
                  class="ant-upload-list-item-image"
                  alt="Documento"
                />
              ) : (
                <span role="img" aria-label="file" class="anticon anticon-file">
                  <Icon />
                </span>
              )}
            </a>
          </span>
        </div>

        <span class="ant-upload-list-item-actions">
          {handlePreview && (
            <button
              title="Visualizar arquivo"
              type="button"
              class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
              onClick={() => handlePreview(file)}
            >
              <span role="img" aria-label="eye" class="anticon anticon-eye">
                <Eye />
              </span>
            </button>
          )}

          {handleRemove &&
            // file.imagemId == null &&
            !disabled && (
              <button
                title="Remover arquivo"
                type="button"
                class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
                onClick={() => setDeleteFileModalVisible(true)}
              >
                <span
                  role="img"
                  aria-label="delete"
                  tabindex="-1"
                  class="anticon anticon-delete"
                >
                  <Trashcan />
                </span>
              </button>
            )}

          {handleDownload && (
            <button
              title="Baixar arquivo"
              type="button"
              class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
              onClick={() => handleDownload(file)}
            >
              <span role="img" aria-label="eye" class="anticon anticon-eye">
                <Download fill="currentColor" />
              </span>
            </button>
          )}
        </span>
      </div>
    </>
  );
}

export default UploadItem;
