import React, { createContext, useCallback, useEffect, useState } from 'react';

import { listarPropostas } from '../services/FilaService';

export const FilaContext = createContext({});

function FilaProvider({ children }) {
  const [propostas, setPropostas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filtroDinamico, setFiltroDinamico] = useState('');
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchPropostas = useCallback(
    async (props) => {
      setLoading(true);

      const page = props?.pageIndex + 1;

      const response = await listarPropostas({
        pagina: page,
        quantidadePorPagina: 5,
        filtroDinamico,
        ordenacao: props?.sortBy[0],
      });

      if (response?.data?.itens) {
        setPropostas(response.data.itens);
      } else {
        setPropostas([]);
      }

      setTotal(response?.data?.total);
      setPageCount(response?.data?.totalPages);
      setLoading(false);
    },
    [filtroDinamico],
  );

  useEffect(() => {
    setDisabled(selectedRows.length <= 0);
  }, [selectedRows]);

  return (
    <FilaContext.Provider
      value={{
        propostas,
        selectedRows,
        disabled,
        loading,
        total,
        pageCount,
        filtroDinamico,
        setPropostas,
        fetchPropostas,
        setSelectedRows,
        setFiltroDinamico,
      }}
    >
      {children}
    </FilaContext.Provider>
  );
}

export default FilaProvider;
