import { useFormik } from 'formik';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  listarRelatorioAnalitico,
  getCardsEsteira,
  imprimirPdfRelatorioAnalitico,
  exportarPropostasVisaoAnaliticaCSV,
  getContextoProposta,
} from '../services/relatorioAnaliticoService';

import { isNumber } from 'lodash';
import ModalComponent from '../components/Modal';

const initialFilters = {
  produtoGrupoId: null,
  filtroCpf: null,
  filtroSituacaoId: null,
  filtroAnalista: null,
  filtroSupervisorId: null,
  searchButton: false,
};

const initialContext = {
  etapas: null,
  grupoProdutos: null,
  supervisores: null,
};

export const RelatorioAnaliticoContext = createContext({
  relatorioAnalitico: [],
  loading: false,
  loadingCards: false,
  total: 0,
  pageCount: 0,
  filters: initialFilters,
  showFilter: true,
  contexto: initialContext,
  cards: {},
  tempoRolagem: 0,
  fetchPropostasEsteira: async (f) => f,
  fetchPropostasRelatorioAnalitico: async (f) => f,
  setShowFilter: (f) => f,
  setFilters: (f) => f,
});

function RelatorioAnaliticoProvider({ children }) {
  const [relatorioAnalitico, setRelatorioAnalitico] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [search, setSearch] = useState(null);
  const [showFilter, setShowFilter] = useState(true);
  const [contexto, setContexto] = useState({});
  const [cards, setCards] = useState([]);
  const [tempoRolagem, setTempoRolagem] = useState(40);
  const [oldFilter, setOldFilter] = useState(initialFilters);

  const formik = useFormik({ initialValues: initialFilters });

  const { values } = formik;

  const fetchRelatorioAnalitico = useCallback(async (props) => {
    setLoading(true);
    const page = props?.pageIndex + 1;
    const response = await listarRelatorioAnalitico({
      pagina: page || 1,
      quantidadePorPagina: 15,
      ordenacao: props?.sortBy?.[0],
      produtoGrupoId: props?.filter?.produtoGrupoId,
      filtroCpf: props?.filter?.filtroCpf,
      filtroSituacaoId: props?.filter?.filtroSituacaoId,
      filtroAnalista: props?.filter?.filtroAnalista,
      filtroSupervisorId: props?.filter?.filtroSupervisorId,
    });
    if (response.data?.itens) {
      setRelatorioAnalitico(response.data?.itens);
      setTempoRolagem(response.data?.tempoRolagem || 40);
    } else {
      setRelatorioAnalitico([]);
      if (props?.filter?.searchButton) {
        ModalComponent(
          '',
          'Não existe nenhuma proposta com os filtros selecionados.',
          'warning',
        );
      }
    }

    setTotal(response.data.total);
    setPageCount(response.data.totalPages);
    setLoading(false);
  }, []);

  const fetchCards = useCallback(async (produtoGrupoId) => {
    if (isNumber(produtoGrupoId)) {
      setLoadingCards(true);
      const data = await getCardsEsteira({ produtoGrupoId });

      setCards(data || []);
      setLoadingCards(false);
    }
  }, []);

  const fetchContexto = async () => {
    const response = await getContextoProposta();
    if (response) {
      setContexto(response);
    }
  };

  const getCard = (name) => cards.find((c) => c.card === name);

  useEffect(() => {
    fetchContexto();
  }, []);
  useEffect(() => {
    if (JSON.stringify(oldFilter) !== JSON.stringify(search)) {
      fetchCards(search?.produtoGrupoId);
    }
    setOldFilter(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.produtoGrupoId]);

  useEffect(() => {
    fetchCards(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportarPropostasCsv = () => {
    setLoading(true);
    const csv = exportarPropostasVisaoAnaliticaCSV(values).then((response) => {
      if (!response || response?.byteLength < 120) {
        ModalComponent(
          '',
          'Não existe nenhuma proposta com os filtros selecionados.',
          'warning',
        );
      } else {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio-visao-analitica.csv');
        document.body.appendChild(link);
        link.click();
      }

      setLoading(false);
    });

    return csv;
  };

  const exportarPropostasPdf = async () => {
    setLoading(true);
    const response = await imprimirPdfRelatorioAnalitico(values);
    setLoading(false);
    return response.data;
  };

  return (
    <RelatorioAnaliticoContext.Provider
      value={{
        formik,
        relatorioAnalitico,
        loading,
        loadingCards,
        total,
        pageCount,
        filters,
        showFilter,
        contexto,
        tempoRolagem,
        fetchRelatorioAnalitico,
        setShowFilter,
        setFilters,
        getCard,
        initialFilters,
        exportarPropostasCsv,
        exportarPropostasPdf,
        getContextoProposta,
        search,
        setSearch,
      }}
    >
      {children}
    </RelatorioAnaliticoContext.Provider>
  );
}

export default RelatorioAnaliticoProvider;
