import React, { useState, useContext, useEffect } from 'react';

import { Formik, Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import moment from 'moment';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';
import InputPhone from '../../../components/InputPhone';

import validaPhone from '../../../utils/validaPhone';

import { BlocklistTelefoneContext } from '../../../contexts/BlocklistTelefoneContext';

import { ReactComponent as IconSalvar } from '../../../assets/images/icons/icon-salvar.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const { Option } = Select;
const { TextArea } = Input;

function FormBlocklist() {
  const { salvarBlocklistTelefone, telefoneBlocklist, setPhone, setFilter } = useContext(
    BlocklistTelefoneContext,
  );
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Overlay active={showOverlay}>
      <Card
        title={
          <Title
            label={`${
              telefoneBlocklist.id > 0 ? 'Edição' : 'Inclusão'
            } de Blocklist Telefone`}
          />
        }
      >
        <Formik
          initialValues={telefoneBlocklist}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={async (data, { resetForm }) => {
            const tiposTelefone = [null, 'Celular', 'Fixo'];
            setShowOverlay(true);
            data.tipo = tiposTelefone.findIndex((item) => item === data.tipo);

            const response = await salvarBlocklistTelefone(data).catch(() => {
              setShowOverlay(false);
              resetForm();
            });

            if (response.success) {
              setPhone("")
              setFilter((filter) => ({
                ...filter,
                pagina: 1,
                filtroDinamico: '',
              }));
              Modal(
                'Sucesso',
                `Telefone ${
                  data.id > 0 ? `excluído da` : `inserido na`
                } Blocklist`,
                'success',
              );
            }

            setShowOverlay(false);
            resetForm();
          }}
        >
          {({ setFieldValue, touched, setErrors, setTouched }) => (
            <Form layout="vertical">
              <FormLayout
                setFieldValue={setFieldValue}
                touched={touched}
                setErrors={setErrors}
                setTouched={setTouched}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </Overlay>
  );
}

function FormLayout({ setFieldValue, touched, setErrors, setTouched, formik }) {
  const { telefoneBlocklist, motivos, resetTelefoneBlocklistForm } = useContext(
    BlocklistTelefoneContext,
  );

  const { checarPermissao } = useContext(ControleAcessoContext);

  const [acessoEdicao, setAcessoEdicao] = useState(false);
  const [acessoInclusao, setAcessoInclusao] = useState(false);

  useEffect(() => {
    setAcessoEdicao(
      !!checarPermissao('frame', 'frame.blocklistTelefone.edicao', 'Editar'),
    );
    setAcessoInclusao(
      !checarPermissao('botao', 'botao.blocklistTelefone.inclusao', 'Criar'),
    );
  }, [checarPermissao]);


  const tiposTelefone = [null, 'Celular', 'Fixo'];

  const handleTelefoneType = (value) => {
    const type = validaPhone(value);
    if (type !== 0) {
      // setTipoTelefone(type);
      setFieldValue('tipo', tiposTelefone[type]);
    } 
  };

  const [statusErrorTelefone, setStatusErrorTelefone] = useState('');
  const [statusErrorMotivo, setStatusErrorMotivo] = useState('');
  const [statusErrorObs, setStatusErrorObs] = useState('');

  const handleReset = () => {
    resetTelefoneBlocklistForm();
    setFieldValue('telefone', '');
    setFieldValue('tipo', '');
    setFieldValue('motivoId', '');
    setFieldValue('descricaoInclusao', '');
    setStatusErrorTelefone('');
    setStatusErrorMotivo('');
    setStatusErrorObs('');
    setErrors({});
    setTouched({});
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="telefone"
            validate={(value) => {
              let msgError = null;

              if (!value) {
                msgError = 'Campo obrigatório';
                setStatusErrorTelefone('warning');
              } else if (!validaPhone(value)) {
                msgError = 'Telefone incorreto';
                setStatusErrorTelefone('error');
              } else {
                setStatusErrorTelefone('');
              }
              telefoneBlocklist.id > 0 && (msgError = '');

              return msgError;
            }}
            validateStatus={touched.telefone && statusErrorTelefone}
            hasFeedback
            label="Telefone"
          >
            <Field name="telefone">
              {({ field }) => (
                <InputPhone
                  {...field}
                  onChange={(e) => handleTelefoneType(e.target.value)}
                  disabled={telefoneBlocklist.id > 0 ? true : acessoInclusao}
                  className="ant-input"
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item name="tipo" label="Tipo">
            {telefoneBlocklist.id === 0 ? (
              <Select
                placeholder="Digite um telefone correto"
                name="tipo"
                suffixIcon=""
                disabled={true}
                className="selectTipoTelefone"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {tiposTelefone.map((nome, i) => (
                  <Option key={i} value={i}>
                    {nome}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input
                name="tipo"
                style={{ textTransform: 'capitalize' }}
                disabled
              />
            )}
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            name="motivoId"
            validate={(value) => {
              let msgError = null;

              if (value === '' || value === null) {
                msgError = 'Campo obrigatório';
                setStatusErrorMotivo('warning');
              } else {
                setStatusErrorMotivo('');
              }
              telefoneBlocklist.id > 0 && (msgError = '');

              return msgError;
            }}
            validateStatus={touched.motivoId && statusErrorMotivo}
            hasFeedback
            label="Motivo"
          >
            {telefoneBlocklist.id === 0 ? (
              <Select
                name="motivoId"
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                disabled={acessoInclusao}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {motivos.length > 0 &&
                  motivos.map((motivo) => (
                    <Option key={motivo.id} value={motivo.id}>
                      {motivo.nome}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input name="motivo" disabled />
            )}
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            name="descricaoInclusao"
            label={`Observação ${
              telefoneBlocklist.id > 0 ? 'da Inclusão' : ''
            }`}
            validate={(value) => {
              let msgError = null;
              if (!value && telefoneBlocklist.id === 0) {
                msgError = 'Campo obrigatório';
                setStatusErrorObs('warning');
              } else {
                setStatusErrorObs('');
              }
              return msgError;
            }}
            validateStatus={
              telefoneBlocklist.id === 0 &&
              touched.descricaoInclusao &&
              statusErrorObs
            }
            hasFeedback
          >
            <Field name="descricaoInclusao">
              {({ field }) => (
                <TextArea
                  name="descricaoInclusao"
                  placeholder={
                    telefoneBlocklist.id > 0 ? '' : 'Informe uma observação'
                  }
                  disabled={telefoneBlocklist.id > 0 ? true : acessoInclusao}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="dataInclusao"
            label={`Data ${telefoneBlocklist.id > 0 ? 'da Inclusão' : ''}`}
          >
            <Input
              name="dataInclusao"
              value={
                telefoneBlocklist.inclusao === ''
                  ? moment().format('DD/MM/YYYY')
                  : moment(telefoneBlocklist.inclusao).format('DD/MM/YYYY')
              }
              disabled
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="usuarioInclusao"
            label={`Responsável ${
              telefoneBlocklist.id > 0 ? 'da Inclusão' : ''
            }`}
          >
            <Input
              name="usuarioInclusao"
              value={
                telefoneBlocklist.id > 0
                  ? telefoneBlocklist.usuarioInclusao
                  : localStorage.getItem('login')
              }
              disabled
            />
          </Form.Item>
        </div>
        {telefoneBlocklist.id > 0 && (
          <>
            <div className="col-lg-12">
              <Form.Item
                name="descricaoExclusao"
                label={`Observação ${
                  telefoneBlocklist.id > 0 ? 'da Exclusão' : ''
                }`}
                validate={(value) => {
                  let msgError = null;
                  if (!value) {
                    msgError = 'Campo obrigatório';
                    setStatusErrorObs('warning');
                  } else {
                    setStatusErrorObs('');
                  }
                  return msgError;
                }}
                validateStatus={touched.descricaoExclusao && statusErrorObs}
                hasFeedback
              >
                <Field name="descricaoExclusao">
                  {({ field }) => (
                    <TextArea
                      name="descricaoExclusao"
                      placeholder={
                        telefoneBlocklist.id > 0 &&
                        telefoneBlocklist.exclusao !== null
                          ? ''
                          : 'Informe uma observação'
                      }
                      disabled={
                        acessoEdicao
                          ? telefoneBlocklist.id > 0 &&
                            telefoneBlocklist.exclusao !== null
                          : true
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="exclusao"
                label={`Data ${telefoneBlocklist.id > 0 ? 'da Exclusão' : ''}`}
              >
                <Input
                  name="exclusao"
                  value={
                    telefoneBlocklist.exclusao === null
                      ? moment().format('DD/MM/YYYY')
                      : moment(telefoneBlocklist.exclusao).format('DD/MM/YYYY')
                  }
                  disabled
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="usuarioExclusao"
                label={`Responsável ${
                  telefoneBlocklist.id > 0 ? 'da Exclusão' : ''
                }`}
              >
                <Input
                  name="usuarioExclusao"
                  value={
                    telefoneBlocklist.id > 0
                      ? telefoneBlocklist.usuarioInclusao
                      : localStorage.getItem('login')
                  }
                  disabled
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
      <div className="d-flex justify-content-end botoes-acao">
        <Button
          className="btn-cancelar"
          type="button"
          variant="gray"
          onClick={handleReset}
        >
          <span>Cancelar</span>
        </Button>
        {!acessoInclusao && telefoneBlocklist.id === 0 && (
          <Button className="btn-salvar" type="submit" variant="green">
            <IconSalvar />
            <span>Salvar</span>
          </Button>
        )}
        {acessoEdicao &&
          telefoneBlocklist.id > 0 &&
          telefoneBlocklist.exclusao === null && (
            <Button className="btn-salvar" type="submit" variant="green">
              <IconSalvar />
              <span>Excluir</span>
            </Button>
          )}
      </div>
    </>
  );
}

export default FormBlocklist;
