import api from './api';

export const getDocumento = async ({
  propostaId,
  produtoId,
  tipoDocumento,
  viaPreenchida
}) => {
  try {
    const data = await api
      .get(
        `Impressao/impressao-documento?produtoId=${produtoId}&propostaId=${propostaId}&tipoDocumento=${tipoDocumento}&viaPreenchida=${viaPreenchida}`,
        { responseType: 'arraybuffer' },
      )
      .then((response) => {
        if (response.headers['content-length']) {
          return response;
        } else {
          return { success: false };
        }
      });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getBoleto = async (linkBoleto) => {
  try {
    const data = await api.get(linkBoleto);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const getPropostaData = async (propostaId) => {
  try {
    const data = await api.get(`proposta/${propostaId}`);
    return data.data;
  } catch (error) {
    return error;
  }
};
