import React, { useContext, useState } from 'react';

import Card from '../../../components/Card';
import Table from '../../../components/FullTable';
import Title from '../../../components/Title';

import data from '../../../data/listagemDeProdutos.json';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import painelProdutos from '../../../data/PanelProdutos.json';

import Field from '../../../components/Field';

import { Collapse, Modal } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ListButton from '../../../components/ListButton';
import IconEdit from '../../../components/EditIcon';
import { Switch } from 'antd';
import { currencyFormat } from '../../../utils/numberFormatter';
import { ReactComponent as ModalidadeFisica } from '../../../assets/images/icons/modalidadeFisica.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';

import FilterParametroProdutos from './FilterParametroProdutos';

import { ParametroProdutosContext } from '../../../contexts/ParametroProdutosContext';
import './styles.scss';
import Tooltip from '../../../components/tooltip';

const { Panel } = Collapse;

function ProdutosList() {
  const [visible, setVisible] = useState(false);
  const [filterShow, setFilterShow] = useState([]);

  const handleFilterClick = () => {
    setFilterShow(!filterShow);
  };

  const Header = (
    <div className="table-header row pl-2">
      <div className="col-lg-6">
        <Title label="Listagem de produtos" />
      </div>
      <div className="col-lg-6 pr-0 text-right">
        <div className="table-filter">
          <Input
            className="filter-input"
            placeholder="Pesquisar..."
            suffix={<SearchOutlined />}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <Button
            className="botao-filtrar"
            type="default"
            onClick={handleFilterClick}
            style={{
              backgroundColor: '#DEE2E6',
              color: '#263659',
              border: '1px solid #DEE2E6',
              fontWeight: 600,
            }}
          >
            <FilterIcon className="insert-icon" />
            FILTRAR
          </Button>
        </div>
      </div>
      <div
        className={
          (filterShow ? 'd-none' : 'Container') +
          ' col-lg-12 col-filter-parametro-produtos'
        }
      >
        <FilterParametroProdutos />
      </div>
    </div>
  );

  const handleCancel = () => {
    // resetForm();
    setVisible(false);
  };

  const setGlobalFilter = (value) => {
    var x = document.getElementsByClassName('ant-input')[0].value;

    document.querySelector('.ant-input').value = x;

    console.log(x);
  };
  const { setParametroProduto, activeInput } = useContext(
    ParametroProdutosContext,
  );

  const [novoGrupo, setNovoGrupo] = useState('');

  const showModal = () => {
    setVisible(true);
  };

  const AddNewGroup = () => {
    setNovoGrupo('');
    setVisible(true);
  };

  const handleEdit = (data) => {
    setParametroProduto(data);
  };

  const columns = [
    {
      Header: 'Código',
      accessor: 'codigoProduto',
    },
    {
      Header: 'Produto',
      accessor: 'produto',
    },
    {
      Header: 'Modalidade',
      accessor: 'modalidade',
      Cell: ({ value }) => (
        <div>
          <ModalidadeFisica /> {value}
        </div>
      ),
    },
    {
      Header: 'Valor Máx',
      accessor: 'valorLiberado',
      Cell: ({ value }) => <div style={{}}>{currencyFormat(value)}</div>,
    },
    {
      Header: 'Prazo Máx',
      accessor: 'prazoMaximo',
    },
    {
      Header: 'Dia 1 vencimento',
      accessor: 'diaPrimeiroVencimento',
      Cell: ({ value }) => {
        return (
          <Tooltip placement="bottom" color="#435361" title={value}>
            <div>{value}</div>
          </Tooltip>
        );
      },
    },
    {
      Header: 'Flag Tx.Juros',
      accessor: 'flagTxJuros',
    },
    {
      Header: 'Ativo',
      accessor: 'ativo',
      Cell: ({ value }) => {
        return <Switch checked={value} size="small" onChange={() => {}} />;
      },
    },
    {
      Header: '',
      accessor: 'edit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <IconEdit onClick={() => handleEdit(row.original)} />;
      },
    },
  ];

  const genExtra = (id) => {
    return (
      <IconEdit
        onClick={(e) => {
          e.stopPropagation();
          setNovoGrupo(id);
          showModal();
        }}
      />
    );
  };

  return (
    <Card className="listagem-produtos-parametros" title={Header}>
      <div>
        <Collapse
          expandIconPosition="left"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              style={{ color: '#ff7817' }}
            />
          )}
        >
          {painelProdutos.length > 0 &&
            painelProdutos.map((item) => {
              return (
                <Panel
                  header={item.nome}
                  key={item.id}
                  extra={genExtra(item.nome)}
                >
                  <div className="tabelaListagem">
                    <Table
                      data={data}
                      columns={columns}
                      pagination={false}
                      filter={false}
                    />
                  </div>
                </Panel>
              );
            })}
        </Collapse>

        <div className={activeInput ? 'd-none' : ''}>
          <ListButton onClick={AddNewGroup} />
        </div>

        <Modal
          title="Grupo de Parâmetros"
          visible={visible}
          zIndex={9999}
          onCancel={handleCancel}
          okButtonProps={{ form: 'formModal', htmlType: 'submit' }}
        >
          <form id="formModal" onSubmit={() => console.log('submit')}>
            <Field label="Nome">
              <input type="hidden" name="id" />
              <input type="hidden" name="ordem" />
              <Input
                name="nome"
                value={novoGrupo}
                onChange={() => console.log(novoGrupo)}
              />
            </Field>
          </form>
        </Modal>
      </div>
    </Card>
  );
}

export default ProdutosList;
