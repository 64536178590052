import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

function CustomButton({
  type = 'button',
  variant,
  className,
  onClick,
  children,
  ...props
}) {
  const btnClass = ClassNames('btn', {
    'btn-primary': variant === 'primary',
    'btn-default': variant === 'default',
    'btn-orange': variant === 'orange',
  });

  return (
    <button
      type={type}
      className={`${btnClass} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

CustomButton.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf(['primary', 'orange', 'default']).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default CustomButton;
