import { object, string, number, mixed, boolean, array, lazy } from 'yup';
import '../../../utils/phoneSchema';

export const validationSchema = object().shape({
  pessoa: object().shape({
    cpf: mixed().required('CPF obrigatório'),
    nome: mixed().required('Nome obrigatório').nullable(),
    nascimento: mixed().required('Data de nascimento obrigatório').nullable(),
    rg: mixed().required('RG obrigatório').nullable(),
    documentoEmissor: mixed().required('Orgão emissor obrigatório').nullable(),
    documentoUFId: number().required('UF de Emissão obrigatório').nullable(),
    documentoEmissao: mixed()
      .required('Data de emissão obrigatório')
      .nullable(),
    pep: boolean().required('PEP é obrigatório').nullable(),
    sexo: number().required('Sexo obrigatório').nullable(),
    estadoCivil: number().required('Estado civil obrigatório').nullable(),
    nacionalidadeId: number().required('Nacionalidade obrigatório').nullable(),
    naturalidadeUfId: number()
      .required('UF naturalidade obrigatório')
      .nullable(),
    naturalidadeCidadeId: number()
      .required('Cidade naturalidade obrigatório')
      .nullable(),
    grauInstrucao: number()
      .required('Grau de instrução obrigatório')
      .nullable(),
    nomeMae: mixed().required('Nome da mãe obrigatório').nullable(),
    nomeConjuge: string().when('estadoCivil', (estadoCivil) => {
      if (estadoCivil === 1 || estadoCivil === 4) {
        return string().required('Nome do cônjuge obrigatório').nullable();
      } else {
        return string().nullable();
      }
    }),
  }),

  endereco: object().shape({
    cep: mixed().required('CEP obrigatório').nullable(),
    logradouro: mixed().required('Logradouro obrigatório').nullable(),
    bairro: mixed().required('Bairro obrigatório').nullable(),
    numero: mixed().required('Número obrigatório').nullable(),
    cidadeId: number().required('Cidade obrigatória'),
    ufid: number().required('Estado obrigatório').nullable(),
  }),

  contatos: object().shape({
    email: string().email('Deve ser informado um e-mail válido').nullable(),
    telefone: string()
      .required('O telefone deve ser preenchido')
      .validateCellphone('Número de celular inválido, favor tentar novamente.')
      .nullable(),
    contatosExtras: lazy(() => {
      return array()
        .of(
          object().shape({
            telefone: string()
              .validatePhone(
                'Número de telefone inválido, favor tentar novamente.',
              )
              .required('O telefone deve ser preenchido')
              .nullable(),
          }),
        )
        .nullable();
    }),
  }),

  profissao: object().shape({
    empresa: mixed().required('Empresa é obrigatória').nullable(),
    ocupacaoId: mixed()
      .required('Classificação profissional é obrigatório')
      .nullable(),
    profissaoId: mixed().required('Profissão é obrigatório').nullable(),
    tempoempregoId: mixed()
      .required('Tempo de emprego é obrigatório')
      .nullable(),
    telefoneRH: string()
      .validatePhone('Número de telefone inválido, favor tentar novamente.')
      .nullable(),
    renda: mixed().required('Renda é obrigatória').nullable(),
  }),

  referencias: lazy(() => {
    return array()
      .of(
        object().shape({
          nome: mixed()
            .required('O nome da referência deve ser preenchida')
            .nullable(),
          telefone: string()
            .validatePhone(
              'Número de telefone inválido, favor tentar novamente.',
            )
            .required('O telefone deve ser preenchido')
            .nullable(),
          grau: mixed()
            .required('O tipo de referência deve ser preenchido')
            .nullable(),
        }),
      )
      .nullable();
  }),
});
