import React, { useContext } from 'react';

import InputInteger from '../../../components/InputInteger';
import InputMoney from '../../../components/InputMoney';

import { Button } from 'antd';
import { useFormik, FormikProvider, Field } from 'formik';
import { Form, Input } from 'formik-antd';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

import { validationForm } from './validationForm';

function JurosPercentuaisForm() {
  const { tableFormData, salvarJurosPercentuais, acesso } =
    useContext(TabelaJurosContext);

  const formikProps = useFormik({
    initialValues: tableFormData,
    validationSchema: validationForm,
    validateOnBlur: false,
    onSubmit: (data, { resetForm, setFieldError }) => {
      const hasJuros = Math.ceil(
        parseFloat(data.juros.toString().replace(',', '.')),
      );
      if (!hasJuros) {
        setFieldError('juros', '');
        return;
      }
      salvarJurosPercentuais(data);
      resetForm();
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formikProps}>
      <Form layout="vertical" autoComplete="off">
        <Input type="hidden" name="id" />
        <Input type="hidden" name="tabelaJurosId" />
        <Input type="hidden" name="plano" />

        <div className="form-row">
          <div className="col-md-3">
            <Form.Item name="prazoInicial" label="Prazo Inicial">
              <Field name="prazoInicial">
                {({ field }) => (
                  <InputInteger
                    className="ant-input"
                    disabled={!acesso}
                    {...field}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <Form.Item name="prazoFinal" label="Prazo Final">
              <Field name="prazoFinal">
                {({ field }) => (
                  <InputInteger
                    className="ant-input"
                    disabled={!acesso}
                    {...field}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <Form.Item name="juros" label="Taxa">
              <Field name="juros">
                {({ field }) => (
                  <InputMoney
                    name="juros"
                    className="ant-input"
                    prefix=""
                    allowNegative={false}
                    decimalScale={4}
                    fixedDecimalScale={true}
                    disabled={!acesso}
                    {...field}
                    onValueChange={(values) => {
                      formikProps.setFieldValue(
                        'jurosFloat',
                        values.floatValue,
                      );
                    }}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <label>{'\u00A0'}</label>
            <Button
              htmlType="submit"
              type="primary"
              block
              style={{
                backgroundColor: '#263659',
                borderColor: '#263659',
                borderRadius: 0,
              }}
              disabled={!acesso}
            >
              <span className="text-uppercase">Adicionar</span>
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default JurosPercentuaisForm;
