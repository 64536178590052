import React, { useContext, useState, useEffect } from 'react';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';

import Toolbar from './toolbar';
import Filter from './filter';
import { Modal, Input } from 'antd';

import { RelatorioEsteiraContext } from '../../../contexts/RelatorioEsteiraContext';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import Button from '../../../components/Button';
import ModalComponent from '../../../components/Modal';

import EsteiraCreditoColumns from './columns';

import './styles.scss';
import { FormikProvider } from 'formik';
import Overlay from '../../../components/Overlay';

function RelatorioEsteira() {
  const {
    propostasEsteira,
    loading,
    total,
    pageCount,
    showFilter,
    fetchPropostasEsteira,
    pageIndex,
    setPageIndex,
    filters,
    formik,
    modalNovoFluxoVisible,
    setModalNovoFluxoVisible,
    novoFluxo,
    sendNovoFluxo,
    loadingModalNovoFluxo,
    setNovoFluxo,
    setLoadingModalNovoFluxo,
    search,
  } = useContext(RelatorioEsteiraContext);

  const [motivo, setMotivo] = useState(null);
  const [msgError, setMsgError] = useState(false);
  const [clickedModalMotivo, setClickedModalMotivo] = useState(false);
  const [dadosNovoFluxoLocal, setDadosNovoFluxoLocal] = useState('');

  useEffect(() => {
    if (novoFluxo) {
      const { erroRegistroGravame, inclusaoCia } = novoFluxo;
      if (erroRegistroGravame) setDadosNovoFluxoLocal(erroRegistroGravame);
      if (inclusaoCia) setDadosNovoFluxoLocal({...inclusaoCia, motivo: inclusaoCia.motivoRBM});
    }
  }, [novoFluxo]);


  async function handleNovoFluxo() {
    if (motivo !== '' && motivo !== null) {
      setLoadingModalNovoFluxo(true);
      const data = await sendNovoFluxo(dadosNovoFluxoLocal.id, novoFluxo.cpf, motivo);
      if (data.success) {
        ModalComponent('', 'Proposta avançada com sucesso', 'success');
        setMsgError(false);
        setModalNovoFluxoVisible(false);
      } else {
        ModalComponent('', 'Falha ao tentar avançar com a proposta', 'error');
      }
      setLoadingModalNovoFluxo(false);
    } else {
      setMsgError(true);
    }
  }

  useEffect(() => {
    if (motivo === '' && clickedModalMotivo) {
      setMsgError(true);
    } else {
      setMsgError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motivo]);

  function handleCancel() {
    setModalNovoFluxoVisible(false);
    setMotivo(null);
    setMsgError(false);
    setNovoFluxo({});
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Card className="relatorio-esteira-credito">
          <Toolbar />

          {showFilter && <Filter formik={formik} />}

          <Table
            data={propostasEsteira}
            columns={EsteiraCreditoColumns()}
            onLoadData={fetchPropostasEsteira}
            loading={loading}
            total={total}
            pageCount={pageCount}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            filter={filters}
            search={search}
            onSearch={true}
          />
        </Card>
      </FormikProvider>

      <Modal
        visible={modalNovoFluxoVisible}
        onCancel={() => handleCancel()}
        centered
        footer={null}
        width={475}
      >
        <Overlay active={loadingModalNovoFluxo}>
          <div className="modal-novo-fluxo">
            <div className="icon">
              <IconExclamationCircle width={80} />
            </div>
            <h1>Avançar para Fila Pagamento?</h1>
            <div className="modal-input">
              <label>Motivo</label>
              <Input.TextArea
                placeholder="Motivo RBM"
                className="modal-input-text"
                disabled
                value={dadosNovoFluxoLocal?.motivo}
                style={{ resize: 'none' }}
              />
            </div>
            <div className="modal-input">
              <label>Dados Adicionais</label>
              <Input.TextArea
                placeholder="Dados Adicionais"
                className="modal-input-text"
                disabled
                value={dadosNovoFluxoLocal?.dadosAdicionais}
                style={{ resize: 'none' }}
              />
            </div>
            <div className="modal-input">
              <label>Motivo Usuário</label>
              <Input.TextArea
                placeholder="Digite aqui"
                value={motivo}
                style={{ resize: 'none' }}
                onChange={(e) => setMotivo(e.target.value)}
                className={`modal-input-text txta-motivo ${
                  msgError && 'error-txta'
                }`}
                onClick={() => setClickedModalMotivo(true)}
              />
              {msgError && (
                <div className="ant-form-item-explain-error modal-error">
                  Campo obrigatório
                </div>
              )}
            </div>
            <Button
              variant="blue"
              onClick={() => handleNovoFluxo()}
              className="btn"
            >
              Confirmar
            </Button>
          </div>
        </Overlay>
      </Modal>
    </>
  );
}

export default RelatorioEsteira;
