import React, { useEffect, useState } from 'react';

import { Modal, Upload, DatePicker } from 'antd';
import Button from '../Button';

import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg';
import { ReactComponent as AnexarIcon } from '../../assets/images/icons/icon-plus.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';

import Message from '../../components/Modal';

import { carregarImagem } from '../../services/imagemService';

import { convertBase64ToBlob } from '../../utils/convertBase64toBlob';

import './styles.scss';

function ModalUpload({
  isModalVisible,
  setIsModalVisible,
  arquivos,
  setArquivos,
  action,
  disabled = false,
}) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [msgError, setMsgError] = useState(false);
  const [blobFile, setBlobFile] = useState(null);

  const handleCancel = () => {
    setIsModalVisible(false);
    setMsgError(false);
    setPreviewImage(null);
    setPreviewTitle(null);
    setBlobFile(null);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemove = (file) => {
    let newFileList = [];

    if (file.id) {
      newFileList = arquivos.filter((fileRemove) => fileRemove.id !== file.id);
    }

    setArquivos(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.type && file.url) {
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];
    }

    setFileType(file.type);
    setPreviewImage(file.url);
    setPreviewTitle(file.nome);
    setPreviewVisible(true);
  };

  const uploadButton = (
    <div className="action-anexar">
      <AnexarIcon />
      <div>Clique para anexar</div>
    </div>
  );

  const beforeUpload = (file) => {
    file.status = '';
    if (file.type !== 'application/pdf') {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PDF',
        'warning',
      );
      file.status = 'removed';
    }
    return false;
  };

  const indiceArquivo = (imagemId) =>
    arquivos.findIndex((elem) => elem.id === imagemId);

  const customUploadRender = (item, file) => {
    return (
      <>
        {item}
        <p className="data-upload-item">
          {arquivos[indiceArquivo(file.id)]
            ? arquivos[indiceArquivo(file.id)].dataHora
            : 'Carregando...'}
        </p>
        <p className="data-upload-item">
          {arquivos[indiceArquivo(file.id)] &&
            arquivos[indiceArquivo(file.id)]?.nome}
        </p>
        <DatePicker
          name="mesAnoReferencia"
          format="MM/YYYY"
          placeholder="00/0000"
          picker="month"
          suffixIcon={<CalendarIcon />}
          clearIcon={false}
          defaultValue={file?.dataReferencia}
          onChange={(value) => {
            arquivos[indiceArquivo(file.id)].dataReferencia = value;
          }}
          key={`select-type-id-${file.id}`}
        />
      </>
    );
  };

  const uploadImage = async (options) => {
    const { file } = options;

    if (file?.status !== 'removed') {
      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const fileURL = await getBase64(file);
          const { data } = await carregarImagem(temp);
          const { id, dataHora } = data;

          let newFile = Object.assign(file);

          newFile = {
            nome: file.name,
            id,
            dataHora,
            url: fileURL,
          };
          setArquivos((arquivos) => [...arquivos, newFile]);
        } catch (err) {
          console.log(`Falha no upload`, err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = () => {
    if (arquivos?.length === 0) {
      setMsgError(true);
    } else {
      action();
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (arquivos?.length > 0) {
      setMsgError(false);
    }
  }, [arquivos]);

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobFile(blobURL);
    } else {
      setBlobFile(null);
    }
  }, [previewImage, fileType]);

  return (
    <>
      <Modal
        className="modal-upload-notas-fiscais"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <h3>Selecione os arquivos</h3>

        <h4>Anexo</h4>
        <Upload
          multiple={true}
          listType="picture-card"
          fileList={arquivos}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          onChange={uploadImage}
          onRemove={handleRemove}
          itemRender={customUploadRender}
          disabled={disabled}
          className={`txta-motivo ${msgError && 'error-upload'}`}
        >
          {uploadButton}
        </Upload>
        {msgError && (
          <div className="ant-form-item-explain-error">
            É obrigatório anexar um arquivo para fazer o upload
          </div>
        )}
        <p className="text-observacao">
          Observação: Parâmetros ajustados pelo sistema.
        </p>

        <div className="text-right botoes-acao">
          <Button
            onClick={() => {
              handleCancel();
            }}
            variant="gray"
            className="btn-xs-block btn-cancelar"
          >
            <span>Cancelar</span>
          </Button>
          <span className="p-1"></span>
          <Button
            type="submit"
            variant="blue"
            className="btn-xs-block btn-upload"
            onClick={() => {
              handleUpload();
            }}
            disabled={disabled}
          >
            <UploadIcon />
            <span>Upload</span>
          </Button>
        </div>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          className={'modal-preview-upload ' + fileType.replace('/', '')}
          onCancel={() => setPreviewVisible(false)}
        >
          <div className="file-preview-container" style={{ height: '90%' }}>
            <object
              data={(blobFile ?? previewImage) + '#toolbar=0'}
              type={fileType}
              width="100%"
              aria-label="Upload File"
            />
          </div>
        </Modal>
      </Modal>
    </>
  );
}

export default ModalUpload;
