import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import useInspinia from '../../hooks/useInspinia';

import ParametroProvider from '../../contexts/ParametroContext';

import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';

import ParametroList from './ParametroList';
import ParametroForm from './ParametroForm';
import { Breadcrumb } from 'antd';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function Parametro() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Configurações</Breadcrumb.Item>
          <Breadcrumb.Item>Parâmetro</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <ParametroProvider>
        {checarPermissao('tela','tela.configuracoes.parametro','Visualizar') && <> 
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <ParametroList />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <ParametroForm />
            </div>
          </div>
        </>}
        </ParametroProvider>
      </Content>
    </Main>
  );
}

export default Parametro;
