const dddList = [
  { ddd: '11', uf: 'SP' },
  { ddd: '12', uf: 'SP' },
  { ddd: '13', uf: 'SP' },
  { ddd: '14', uf: 'SP' },
  { ddd: '15', uf: 'SP' },
  { ddd: '16', uf: 'SP' },
  { ddd: '17', uf: 'SP' },
  { ddd: '18', uf: 'SP' },
  { ddd: '19', uf: 'SP' },
  { ddd: '21', uf: 'RJ' },
  { ddd: '22', uf: 'RJ' },
  { ddd: '24', uf: 'RJ' },
  { ddd: '27', uf: 'ES' },
  { ddd: '28', uf: 'ES' },
  { ddd: '31', uf: 'MG' },
  { ddd: '32', uf: 'MG' },
  { ddd: '33', uf: 'MG' },
  { ddd: '34', uf: 'MG' },
  { ddd: '35', uf: 'MG' },
  { ddd: '37', uf: 'MG' },
  { ddd: '38', uf: 'MG' },
  { ddd: '41', uf: 'PR' },
  { ddd: '42', uf: 'PR' },
  { ddd: '43', uf: 'PR' },
  { ddd: '44', uf: 'PR' },
  { ddd: '45', uf: 'PR' },
  { ddd: '46', uf: 'PR' },
  { ddd: '47', uf: 'SC' },
  { ddd: '48', uf: 'SC' },
  { ddd: '49', uf: 'SC' },
  { ddd: '51', uf: 'RS' },
  { ddd: '53', uf: 'RS' },
  { ddd: '54', uf: 'RS' },
  { ddd: '55', uf: 'RS' },
  { ddd: '61', uf: 'DF' },
  { ddd: '62', uf: 'GO' },
  { ddd: '63', uf: 'TO' },
  { ddd: '64', uf: 'GO' },
  { ddd: '65', uf: 'MT' },
  { ddd: '66', uf: 'MT' },
  { ddd: '67', uf: 'MS' },
  { ddd: '68', uf: 'AC' },
  { ddd: '69', uf: 'RO' },
  { ddd: '71', uf: 'BA' },
  { ddd: '73', uf: 'BA' },
  { ddd: '74', uf: 'BA' },
  { ddd: '75', uf: 'BA' },
  { ddd: '77', uf: 'BA' },
  { ddd: '79', uf: 'SE' },
  { ddd: '81', uf: 'PE' },
  { ddd: '82', uf: 'AL' },
  { ddd: '83', uf: 'PB' },
  { ddd: '84', uf: 'RN' },
  { ddd: '85', uf: 'CE' },
  { ddd: '86', uf: 'PI' },
  { ddd: '87', uf: 'PE' },
  { ddd: '88', uf: 'CE' },
  { ddd: '89', uf: 'PI' },
  { ddd: '91', uf: 'PA' },
  { ddd: '92', uf: 'AM' },
  { ddd: '93', uf: 'PA' },
  { ddd: '94', uf: 'PA' },
  { ddd: '95', uf: 'RR' },
  { ddd: '96', uf: 'AP' },
  { ddd: '97', uf: 'AM' },
  { ddd: '98', uf: 'MA' },
  { ddd: '99', uf: 'MA' },
];

export default dddList;
