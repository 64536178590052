import React, { useContext } from 'react';

import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';

import { RelatorioEsteiraContext } from '../../../contexts/RelatorioEsteiraContext';

function Toolbar() {
  const {
    showFilter,
    setShowFilter,
    exportarPropostasCsv,
    exportarPropostasPdf,
  } = useContext(RelatorioEsteiraContext);

  const imprimirPdf = async () => {
    const obj = await exportarPropostasPdf();
    sessionStorage.setItem('relatorioEsteiraImpressao', JSON.stringify(obj));

    window.open('/relatorio-esteira-pdf', '_blank');
  };

  return (
    <div className=" mb-3 topo-relatorio-esteira-credito">
      <div className="title-esteira">
        <Title label="Relatório Visão Esteira de Crédito" />
      </div>

      <div className="toolbar">
        <Button
          className="mb-1"
          type="button"
          variant="white"
          onClick={() => imprimirPdf()}
          async={true}
        >
          <Icon variant="download" />
          <span className="ml-2">PDF</span>
        </Button>

        <span className="p-1"></span>

        <Button
          className="mb-1"
          type="button"
          variant="white"
          onClick={() => exportarPropostasCsv()}
          async={true}
        >
          <Icon variant="download" />
          <span className="ml-2">CSV</span>
        </Button>

        <span className="p-1"></span>

        <Button
          className="mb-1"
          type="button"
          variant="gray"
          onClick={() => setShowFilter(!showFilter)}
        >
          <Icon variant="filter" />
          <span className="ml-2">Filtrar</span>
        </Button>
      </div>
    </div>
  );
}

export default Toolbar;
