import React from 'react';

import { Breadcrumb } from 'antd';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Card from '../../components/Card';
import Content from '../../components/Content';

import CorrespondenteProvider from '../../contexts/CorrespondenteContext';

import useInspinia from '../../hooks/useInspinia';

import AprovacaoList from './AprovacaoList';
import AprovacaoForm from './AprovacaoForm';

function Aprovacao() {
  useInspinia();

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Correspondente</Breadcrumb.Item>
          <Breadcrumb.Item>Aprovação</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <CorrespondenteProvider>
          <Card>
            <AprovacaoList />

            <AprovacaoForm />
          </Card>
        </CorrespondenteProvider>
      </Content>
    </Main>
  );
}

export default Aprovacao;
