import moment from 'moment';
import Tag from '../../../components/Tag';

import { ReactComponent as ClientIcon } from '../../../assets/images/icons/client-circle.svg';

export const historicoColumns = [
  {
    Header: 'Status',
    accessor: 'Nome',
    style: {
      minWidth: 80,
      width: 80,
      textAlign: 'center',
    },
    Cell: ({ value, row }) =>
      row.original.Cor && (
        <div style={{ textAlign: 'center' }}>
          <Tag color={row.original.Cor}>{value}</Tag>
        </div>
      ),
  },
  {
    Header: 'Alteração',
    accessor: 'Data',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {moment(value).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: 'Login',
    accessor: 'usuario',
    style: {
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {value}
      </div>
    ),
  },
  {
    Header: 'Perfil',
    accessor: 'tipoUsuario',
    style: {
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {value === 'Analista Crédito' && <ClientIcon color="#445566" width="15" />}
      </div>
    ),
  },
];
