import api from './api';

export const getImagem = async (propostaImagemId) => {
  const { data } = await api.get(`/imagem/proposta/${propostaImagemId}`);
  return data.data;
};

export const getLinkImagem = async (link) => {
  const { data } = await api.get(link);
  return data.data;
};

export const getImagemAnexoReanalise = async (
  propostaHistoricoRenaliseImagemId,
) => {
  const { data } = await api.get(
    `/imagem/proposta-reanalise/${propostaHistoricoRenaliseImagemId}`,
  );
  return data;
};

export const carregarImagem = async (imagem) => {
  try {
    const { data } = await api.post('/Imagem', imagem);
    return data;
  } catch (error) {
    return error;
  }
};
