import api from './api';
import integrationApi from './integrationApi';
import axios from 'axios';
import xml2js from 'xml2js';



export const criaChamada = async (chamada) => {
  try {
    const { data } = await integrationApi.post(
      `Discador/chamada-telefonica`,
      chamada,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const atualizaChamada = async (chamada, id) => {
  try {
    const { data } = await api.put(`/chamada/${id}`, chamada);
    return data;
  } catch (error) {
    return error.response;
  }
};

const baseURL = 'http://localhost:15070';
const usuarioAgifone = 'AGIFONECliente';
const senhaAgifone = '219d1dcb238747ceab6b73d7db2fc542';

const agifoneApi = axios.create({
  baseURL,
});


export const autenticaAgifone = async () => {
  let tokenAgifone = '';

  try {
    const { data } = await agifoneApi.get(
      `/Command?action=authenticate&user=${usuarioAgifone}&password=${senhaAgifone}`,
    );

    let parserAutentica = new xml2js.Parser();
    parserAutentica.parseString(data, function (err, result) {
      tokenAgifone = result.ReturnData.Data[0].Token[0];
    });

    return tokenAgifone;
  } catch (error) {
    return error;
  }
};

export const encerraChamada = async () => {
  const tokenAgifone = await autenticaAgifone();

  try {
    const { data } = await agifoneApi.get(
      `/Command?action=finalizeallcalls&token=${tokenAgifone}`,
    );

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchHistoricoChamada = async (propostaId) => {
  try {
    const { data } = await api.post(`/chamada/lista`, {
      filtroPropostaId: propostaId,
      paginado: false,
      ordenacao: 'dataHora',
      ordenacaoAsc: false,
    });
    return data.data.itens;
  } catch (error) {
    return error;
  }
};

export const getHistoricoReanalise = async (id) => {
  const { data } = await api.get(`/proposta/reanalise/historico/${id}`);
  return data;
};



export const postHistoricoProposta = async (cpf, propostaId, filtroDinamico) => {
  try {
    const { data } = await api.post(`/proposta/historico-proposta`, {

        pagina: 1,
        quantidadePorPagina: 5,
        filtroDinamico,
        ordenacao: "ultimaAlteracao",
        ordenacaoAsc: false,
        cpf,
        propostaId,

    });
    return data.data.itens;
  } catch (error) {
    return error.response
  }}

  export const postCodigoBarrasNeoEnergia = async (body) => {
    const { data } = await integrationApi.post(`/NeoEnergia/codigo-barra-fatura`, body);
    return data;
  }

  export const getAbaContato = async (propostaId) => {
    const { data } = await api.get(`/proposta/aba-contato/${propostaId}`);
    return data.data;
  }
