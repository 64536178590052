import { Radio, Form, Field } from 'formik-antd'
import { Select } from 'antd';
import './styles.scss'
import { useEffect, useState } from 'react'
import SelectSearch from '../SearchSelect';
const { Option } = Select;

export const PreAnaliseProdutoPlugIn = ({ imagem, titulo, isConsignadoPrivado, setFieldValue, name,value, setConvenioId, convenioId, convenios }) => {

    const [radiusValue, setRadiusValue] = useState(value);

    const onChangeRadius = (e) => {
        setRadiusValue(e.target.value);
        setFieldValue(name, e.target.value)
    }

    useEffect(() => {
      setRadiusValue(value)
    }, [value])

    return (
        <div className='pre-analise-produto-plugin'>
            <div className='bloco-informacao'>
                <div className='imagem'>
                    {imagem}
                </div>
                <div className='titulo'>
                    {titulo}
                </div>
            </div>
            <div className='bloco-radius-buttons'>
                <Radio.Group onChange={onChangeRadius} value={radiusValue}>
                    <Radio value={true} id='sim-plugin-pre-analise'>
                        Sim
                    </Radio>
                    <Radio value={false} id='nao-plugin-pre-analise'>
                        Não
                    </Radio>
                </Radio.Group>
                <span className='form-item-error'>
                    *Obrigatório
                </span>
            </div>

            {isConsignadoPrivado && radiusValue && (
                <div className="classificao">
                    <Form.Item
                        name="convenioId"
                        label="Conveniada"
                        hasFeedback
                        validate={(value) => {
                            let msgError = null;

                            if (value === '' || value === null) {
                                msgError = 'Campo obrigatório';
                            } else {
                            }

                            return msgError;
                        }}
                        className="label-form"
                    >
                        <Field name="convenioId">
                            {({ field }) => (
                                <SelectSearch
                                    {...field}
                                    onChange={(value) => {
                                        setConvenioId(value)
                                        setFieldValue("convenioId", value)
                                    }}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    dropdownAlign={{
                                        overflow: { adjustY: 0 },
                                    }}
                                    value={convenioId}
                                    optionFilterProp="children"
                                    data-cy={'convenioId'}
                                >
                                    {convenios?.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.nome}
                                        </Option>
                                    ))}
                                </SelectSearch>
                            )}
                        </Field>
                    </Form.Item>
                </div>
            )}
        </div>
    )
}
