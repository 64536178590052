import api from './api';

export const listarGruposParametro = async () => {
  const { data } = await api.get('/parametro-grupo');
  return data.data;
};

export const inserirGrupoParametro = async (values) => {
  const { data } = await api.post('/parametro-grupo', values);
  return data;
};

export const buscarGrupoParametro = async (id) => {
  const { data } = await api.get(`parametro-grupo/${id}`);
  return data.data;
};

export const atualizarGrupoParametro = async (values) => {
  const { data } = await api.put(`/parametro-grupo/${values.id}`, values);
  return data;
};
