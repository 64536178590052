import React, { useEffect } from 'react';
import moment from 'moment';
import { cpfFormatter } from '../../../../utils/textFormatter';
import logoQuadrado from '../../../../assets/images/crefazon-logo-quadrado.svg';

import './styles.scss';

export default function PdfRelatorioAnalitico() {
  const cabecObj = [
    'Status',
    'CPF',
    'Produto',
    'Analista',
    'Supervisor',
    'Espera',
    'Distribuição',
    'Análise',
    'Redistribuição',
    'Operatividade',
    'Prioridade',
  ];

  const relatorioPropostaImpressao = JSON.parse(
    sessionStorage.getItem('relatorioVisaoAnaliticaImpressao'),
  );

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <div className="bg-white">
      <table className="tabela">
        <thead className="tHead">
          <tr>
            <td colSpan={cabecObj?.length}>
              <div className="headerRelatorioAnalitico">
                <div>
                  <div className="title">
                  Relatório Painel Analítico
                  </div>
                  <div className="subtitle">
                    Data de geração:{' '}
                    {moment(relatorioPropostaImpressao?.dataGeracao).format(
                      'DD/MM/YYYY',
                    )}
                  </div>
                </div>
                <img
                  className="logoRelatorioAnalitico"
                  src={logoQuadrado}
                  alt="Crefaz - Crédito Consciente"                  
                />
              </div>
            </td>
          </tr>
          <tr>
            {cabecObj.map((dado) => (
              <td className="tHeadItemRelatorioAnalitico">{dado}</td>
            ))}
          </tr>
        </thead>
        <tbody className="tBody">
          {relatorioPropostaImpressao?.itens?.map((proposta) => (
            <tr className="trBody">
              <td className="tBodyItemRelatorioAnalitico">{proposta?.situacao}</td>
              <td className="tBodyItemRelatorioAnalitico">{cpfFormatter(proposta?.cpf)}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.produto}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.analista}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.supervisor}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.espera}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.distribuicao}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.analise}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.redistribuida}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.operatividade}</td>
              <td className="tBodyItemRelatorioAnalitico">{proposta?.prioridade}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
