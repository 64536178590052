import Tag from '../../../../components/Tag';
import { cpfFormatter } from '../../../../utils/textFormatter';

export const columnsContato = [
  {
    Header: 'Situação',
    accessor: 'situacao',
    style: {
      minWidth: 80,
      width: 80,
      textAlign: 'center',
    },
    Cell: ({ value, row }) =>
      row.original.situacaoCor && (
        <div style={{ textAlign: 'center' }}>
          <Tag color={row.original.situacaoCor}>{value}</Tag>
        </div>
      ),
  },
  {
    Header: 'Id',
    accessor: 'id',
    style: {
      minWidth: 75,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </div>
    ),
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    style: {
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {cpfFormatter(value)}
      </div>
    ),
  },
  {
    Header: 'Nome do Cliente',
    accessor: 'nome',
    style: {
      width: 180,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          width: 180,
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </div>
    ),
  },
  {
    Header: 'Tipo do Telefone',
    accessor: 'tipoTelefone',
    style: {
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {value}
      </div>
    ),
  },
  {
    Header: 'Nome do Agente',
    accessor: 'usuario',
    style: {
      width: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </div>
    ),
  },

  {
    Header: 'Matriz',
    accessor: 'promotora',
    style: {
      width: 250,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {value}
      </div>
    ),
  },
];
