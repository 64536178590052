import api from './api';

export const getDDDs = async () => {
  let ddd = sessionStorage.getItem("contexto-ddd");
  if (!ddd) {
    try {
      const { data } = await api.get('/contextos/ddd');
      sessionStorage.setItem("contexto-ddd", JSON.stringify(data.data));
      return data.data;
    } catch (error) {
      return error;
    }
  }

  ddd = JSON.parse(ddd);
  return ddd;
};
