import React, { useState, useEffect } from 'react';

import { Form, Select } from 'formik-antd';
import { Modal } from 'antd';

import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import Option from '../../../components/Option';
import Button from '../../../components/Button';
import Table from '../../../components/FullTable';
import SelectSearch from '../../../components/SearchSelect';
import { listarCidades, listarUfs } from '../../../services/enderecoService';

function RegiaoForm({ formik, userId, setRegiao, isReadOnly }) {
  const { setFieldValue, values } = formik;
  const formRegiao = formik?.values?.regiao;

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [regioes, setRegioes] = useState([]);

  const [modalVisibleAlertDuplicated, setModalVisibleAlertDuplicated] =
    useState(false);
  const [modalAlertDuplicatedText, setModalAlertDuplicatedText] =
    useState(null);

  useEffect(() => {
    setFieldValue('regioes', regioes);
    setRegiao(regioes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regioes]);

  useEffect(() => {
    if (userId && formik?.values?.regioes) {
      setRegioes(formik.values?.regioes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik.values?.regioes) {
      setRegioes(formik.values?.regioes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
  };
  const fetchCidades = async (ufId) => {
    const response = await listarCidades(formik.values?.regiao?.ufId);
    setCidades(response);
  };
  const removeRegiao = (index) => {
    setRegioes(regioes.filter((val, ind) => ind !== index));
  };
  const adicionaRegiao = async () => {
    let filteredRegioes = regioes.filter((regiao) => {
      if (
        regiao.ufId === values?.regiao?.ufId &&
        regiao.cidadeId === values?.regiao?.cidadeId
      ) {
        return regiao;
      }else{
        return null;
      }
    });
    if (filteredRegioes?.length === 0) {
      let valuesRegiao = {
        id: null,
        opera: values?.regiao?.opera,
        ufId: values?.regiao?.ufId,
        ufNome: estados.find((item) => item.id === values?.regiao?.ufId)?.uf,
        cidadeId: values?.regiao?.cidadeId,
        cidadeNome: cidades.find((item) => item.id === values?.regiao?.cidadeId)
          ?.nome,
      };
      setRegioes((state) => [...state, valuesRegiao]);
      setFieldValue('regiao.ufId', null);
      setFieldValue('regiao.cidadeId', null);
    } else {
      if (filteredRegioes[0]?.opera === values?.regiao?.opera) {
        setModalVisibleAlertDuplicated(true);
        setModalAlertDuplicatedText(
          'Liberação/Bloqueio já existe. Verifique e tente novamente!',
        );
      } else if (filteredRegioes[0]?.opera) {
        setModalVisibleAlertDuplicated(true);
        setModalAlertDuplicatedText(
          'Existe um outro registro liberando a região que está tentando bloquear. Verifique e tente novamente!',
        );
      } else if (values?.regiao?.opera) {
        setModalVisibleAlertDuplicated(true);
        setModalAlertDuplicatedText(
          'Existe um outro registro bloqueando a região que está tentando liberar. Verifique e tente novamente!',
        );
      }
    }
  };
  const columns = [
    {
      Header: 'Liberar/Bloquear',
      accessor: 'opera',
      disableSortBy: false,
      Cell: ({ value }) => <div> {value ? '+' : '-'}</div>,
    },
    {
      Header: 'Estado',
      accessor: 'ufNome',
      disableSortBy: false,
    },
    {
      Header: 'Cidade',
      accessor: 'cidadeNome',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'excluir',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div
          className="form-button"
          style={{ textAlign: 'right', display: isReadOnly && 'none' }}
        >
          <Button
            className="btn-trash"
            onClick={() => {
              removeRegiao(row.index);
            }}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      ),
    },
  ];

  const ordernedRegioes = (listRegioes) => {
    // ordenação dos itens de regiões de acordo com tipo de "opera" +/-, os nomes dos estados e posteriormente os nomes das cidades
    let listOrdernedRegioes = listRegioes;
    listOrdernedRegioes.sort((regiaoA, regiaoB) => {
      // ordena inicialmente verificando o valor de "opera", listando primeiros os liberados e depois os bloqueados
      if (regiaoA?.opera === regiaoB?.opera) {
        // ordena por ordem alfabética os nomes das UF, depois de ordenar separando os liberados e bloqueados
        if (regiaoA?.ufNome?.toUpperCase() === regiaoB?.ufNome?.toUpperCase()) {
          // ordena por ordem alfabética os nomes das cidades de dentro de cada UF
          if (
            regiaoA?.cidadeNome?.toUpperCase() ===
            regiaoB?.cidadeNome?.toUpperCase()
          ) {
            return 0;
          } else if (
            regiaoA?.cidadeNome?.toUpperCase() >
            regiaoB?.cidadeNome?.toUpperCase()
          ) {
            return 1;
          } else if (
            regiaoA?.cidadeNome?.toUpperCase() <
            regiaoB?.cidadeNome?.toUpperCase()
          ) {
            return -1;
          } else {
            return 0;
          }
        } else if (
          regiaoA?.ufNome?.toUpperCase() > regiaoB?.ufNome?.toUpperCase()
        ) {
          return 1;
        } else if (
          regiaoA?.ufNome?.toUpperCase() < regiaoB?.ufNome?.toUpperCase()
        ) {
          return -1;
        } else {
          return 0;
        }
      } else if (regiaoA?.opera && !regiaoB?.opera) {
        return -1;
      } else if (!regiaoA?.opera && regiaoB?.opera) {
        return 1;
      } else {
        return 0;
      }
    });

    return listOrdernedRegioes;
  };

  useEffect(() => {
    setFieldValue('regiao.cidadeId', null);
    if (formik.values?.regiao?.ufId) {
      fetchCidades(formik.values?.regiao?.ufId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.regiao?.ufId]);

  useEffect(() => {
    fetchEstados();
    formik.setFieldValue('regiao.opera', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-12 col-tab-regiao">
          <div className="row">
            {!isReadOnly && (
              <>
                <div className="col-lg-12 sub-title"> Configurar Região </div>
                <div className="col-lg-3 item-dados">
                  <Form.Item label="Liberar/Bloquear" name="regiao.opera">
                    <Select
                      name="regiao.opera"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      defaultValue={true}
                      value={values?.regiao?.opera}
                      onSelect={(value) => setFieldValue('regiao.opera', value)}
                      disabled={isReadOnly}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <option key={true} value={true}>
                        +
                      </option>
                      <option key={false} value={false}>
                        -
                      </option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-3 item-dados">
                  <Form.Item label="Estado" name="regiao.ufId">
                    <SelectSearch
                      name="regiao.ufId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      showSearch
                      value={values?.regiao?.ufId}
                      onChange={(value) => {
                        setFieldValue('regiao.ufId', value);
                        setFieldValue('regiao.cidadeId', null);
                      }}
                      disabled={isReadOnly}
                    >
                      {estados &&
                        estados.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.uf}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Form.Item>
                </div>
                <div className="col-lg-5 item-dados">
                  <Form.Item label="Cidade" name="regiao.cidadeId">
                    <SelectSearch
                      name="regiao.cidadeId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      showSearch
                      value={values?.regiao?.cidadeId || 'Selecione'}
                      onChange={(value) =>
                        setFieldValue('regiao.cidadeId', value)
                      }
                      disabled={!formik?.values?.regiao?.ufId || isReadOnly}
                    >
                      {cidades.length > 0 &&
                        cidades.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Form.Item>
                </div>

                <div
                  className="col-lg-1 col-button-plus"
                  style={{ display: isReadOnly && 'none' }}
                >
                  <Button
                    onClick={() => {
                      if (formRegiao?.ufId) {
                        adicionaRegiao();
                        setFieldValue('regiao', {
                          id: null,
                          opera: values.regiao.opera,
                        });
                      }
                    }}
                    className="btnPlusRegiao"
                  >
                    <IconPlusBtn />
                  </Button>
                </div>
              </>
            )}

            <div className="col-lg-12 sub-title regioes">
              {' '}
              Regiões Configuradas{' '}
            </div>

            <div className="col-lg-12 tabela-listagem-regioes">
              <Table
                columns={columns}
                data={ordernedRegioes(regioes)}
                filter={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={modalVisibleAlertDuplicated}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">{modalAlertDuplicatedText}</div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {
              setModalVisibleAlertDuplicated(false);
            }}
          >
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default RegiaoForm;
