import api from './api';

export const listarPaises = async () => {
  let paises = sessionStorage.getItem("endereco-paises");
  if (!paises) {
    try {
      const { data } = await api.get(`/Endereco/Pais`);
      sessionStorage.setItem("endereco-paises", JSON.stringify(data.data));
      return data.data;
    } catch (error) {
      return error;
    }
  }

  paises = JSON.parse(paises);
  return paises;
};

export const listarUfs = async () => {
  let ufs = sessionStorage.getItem("endereco-ufs");
  if (!ufs) {
    try {
      const { data } = await api.get(`/endereco/ufs`);
      sessionStorage.setItem("endereco-ufs", JSON.stringify(data.data));
      return data.data;
    } catch (error) {
      return error;
    }
  }

  ufs = JSON.parse(ufs);
  return ufs;
};

export const listarCidades = async (ufId) => {
  try {
    const { data } = await api.get(`endereco/Cidade/${ufId}`);
    if (data?.data) {
      data.data.sort((a, b) => {
        if (
          a.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '') <
          b.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        ) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    return data.data;
  } catch (error) {
    return error;
  }
};

export const buscarEndereco = async (cep) => {
  try {
    const { data } = await api.get(`endereco/Cep/${cep}`);
    return data;
  } catch (error) {
    return error;
  }
};
