import React, { useContext } from 'react';
import { Modal } from 'antd';
import Button from '../../components/Button';
import { ReactComponent as IconCheckCircle } from '../../assets/images/icons/icon-check-modal.svg';
import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { HierarquiasContext } from '../../contexts/HierarquiasContext';

const ModalNotification = ({
  visible = false,
  type = 'success',
  width = 452,
  message = '',
  buttons,
  closeOutClick = false,
  closeButton = false,
  actionConfirm,
  actionCancel,
}) => {
  const { cancelModalNotification } = useContext(HierarquiasContext);
  const actionButtonConfirm = () => {
    if (actionConfirm) {
      actionConfirm();
    } else {
      cancelModalNotification();
    }
  };
  const actionButtonCancel = () => {
    if (actionCancel) {
      actionCancel();
    } else {
      cancelModalNotification();
    }
  };

  return (
    <Modal
      visible={visible}
      centered
      closable={closeButton}
      footer={null}
      width={width}
      maskClosable={closeOutClick}
      className="modal-warning modal-notification-hierarquia"
      onCancel={cancelModalNotification}
    >
      <div className="icon">
        {type === 'success' ? (
          <IconCheckCircle width={75} />
        ) : (
          type === 'warning' && <IconExclamationCircle width={75} />
        )}
      </div>
      <div className="text-modal">{message}</div>
      <div className="btn-area">
        {(buttons || [{ text: 'ok', type: 'confirm', variant: 'blue' }])?.map(
          (button, index) => (
            <Button
              key={index}
              variant={button?.variant || 'blue'}
              onClick={() =>
                button?.type === 'confirm'
                  ? actionButtonConfirm()
                  : actionButtonCancel()
              }
            >
              {button?.text}
            </Button>
          ),
        )}
      </div>
    </Modal>
  );
};

export default ModalNotification;
