import React, { useState, useContext, useEffect } from 'react';

import InputMask from 'react-text-mask';
import { Formik, Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import moment from 'moment';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';

import { cpfMask } from '../../../utils/masks';
import validaCPF from '../../../utils/validaCpf';
import { cpfFormatter } from '../../../utils/textFormatter';

import { BlocklistCPFContext } from '../../../contexts/BlocklistCPFContext';

import { ReactComponent as IconSalvar } from '../../../assets/images/icons/icon-salvar.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const { Option } = Select;
const { TextArea } = Input;

function FormBlocklist() {
  const { salvarBlocklistCPF, cpfBlocklist, setCpf, setFilter } = useContext(BlocklistCPFContext);
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Overlay active={showOverlay}>
      <Card
        title={
          <Title
            label={
              cpfBlocklist.id > 0
                ? 'Edição de Blocklist CPF'
                : 'Inclusão de Blocklist CPF'
            }
          />
        }
      >
        <Formik
          initialValues={cpfBlocklist}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={async (data, { resetForm }) => {
            setShowOverlay(true);
            const response = await salvarBlocklistCPF(data).catch(() => {
              setShowOverlay(false);
              resetForm();
            });

            if (response.success) {
              setCpf("")
              setFilter((filter) => ({
                ...filter,
                pagina: 1,
                filtroDinamico: '',
              }));
              Modal(
                'Sucesso',
                `CPF ${data.id > 0 ? `excluído da` : `inserido na`}  Blocklist`,
                'success',
              );
            }

            setShowOverlay(false);
            resetForm();
          }}
        >
          {({ setFieldValue, setErrors, setTouched, touched }) => (
            <Form layout="vertical">
              <FormLayout
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                setTouched={setTouched}
                touched={touched}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </Overlay>
  );
}

function FormLayout({ setFieldValue, setErrors, setTouched, touched }) {
  const { motivos, cpfBlocklist, resetCpfBlocklistForm } =
    useContext(BlocklistCPFContext);

  const [statusErrorCPF, setStatusErrorCPF] = useState('');
  const [statusErrorMotivo, setStatusErrorMotivo] = useState('');
  const [statusErrorObs, setStatusErrorObs] = useState('');
  const [acessoEdicao, setAcessoEdicao] = useState(false);
  const [acessoInclusao, setAcessoInclusao] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcessoEdicao(
      !!checarPermissao('frame', 'frame.blocklistCpf.edicao', 'Editar'),
    );
    setAcessoInclusao(
      !checarPermissao('botao', 'botao.blocklistCpf.inclusao', 'Criar'),
    );
  }, [checarPermissao]);

  const handleReset = () => {
    resetCpfBlocklistForm();
    setErrors({});
    setTouched({});
    setFieldValue('cpf', '');
    setFieldValue('motivoId', '');
    setFieldValue('descricaoInclusao', '');
    setStatusErrorCPF('');
    setStatusErrorMotivo('');
    setStatusErrorObs('');
  };

  useEffect(() => {
    if (cpfBlocklist.id > 0) {
      const fields = [
        'id',
        'cpf',
        'motivoId',
        'descricaoInclusao',
        'descricaoExclusao',
        'inclusao',
        'exclusao',
        'responsavelInclusao',
        'responsavelExclusao',
        'edit',
      ];
      fields.forEach((field) =>
        setFieldValue(field, cpfBlocklist[field], false),
      );
    }
  }, [cpfBlocklist, setFieldValue]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="cpf"
            label="CPF"
            validate={(value) => {
              let msgError = null;
              if (!value) {
                msgError = 'Campo obrigatório';
                setStatusErrorCPF('warning');
              } else if (!validaCPF(value?.replace(/\D/g, ''))) {
                setStatusErrorCPF('error');
                msgError = 'CPF incorreto';
              } else {
                setStatusErrorCPF('');
              }
              cpfBlocklist.id > 0 && (msgError = '');
              return msgError;
            }}
            validateStatus={touched.cpf && statusErrorCPF}
            hasFeedback
          >
            <Field name="cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  mask={cpfMask}
                  value={cpfFormatter(field.value)}
                  disabled={cpfBlocklist.id > 0 ? true : acessoInclusao}
                  placeholder="000.000.000-00"
                  className="ant-input"
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="motivoId"
            label="Motivo"
            validate={(value) => {
              let msgError = null;

              if (value === '' || value === null) {
                msgError = 'Campo obrigatório';
                setStatusErrorMotivo('warning');
              } else {
                setStatusErrorMotivo('');
              }
              cpfBlocklist.id > 0 && (msgError = '');

              return msgError;
            }}
            validateStatus={touched.motivoId && statusErrorMotivo}
            hasFeedback
          >
            {cpfBlocklist.id === 0 ? (
              <Select
                name="motivoId"
                placeholder="Selecione"
                disabled={cpfBlocklist.id > 0 ? true : acessoInclusao}
                suffixIcon={<IconArrowSelect />}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {motivos.length > 0 &&
                  motivos.map((motivo) => (
                    <Option key={motivo.id} value={motivo.id}>
                      {motivo.nome}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input name="motivo" disabled />
            )}
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            name="descricaoInclusao"
            label={'Observação' + (cpfBlocklist.id > 0 ? ' da Inclusão' : '')}
            validate={(value) => {
              let msgError = null;
              if (!value && cpfBlocklist.id === 0) {
                msgError = 'Campo obrigatório';
                setStatusErrorObs('warning');
              } else {
                setStatusErrorObs('');
                msgError = '';
              }
              return msgError;
            }}
            validateStatus={
              cpfBlocklist.id === 0 &&
              touched.descricaoInclusao &&
              statusErrorObs
            }
            hasFeedback
          >
            <Field name="descricaoInclusao">
              {({ field }) => (
                <TextArea
                  {...field}
                  name="descricaoInclusao"
                  placeholder={
                    cpfBlocklist.id > 0 ? '' : 'Informe uma observação'
                  }
                  disabled={cpfBlocklist.id > 0 ? true : acessoInclusao}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="inclusao"
            label={'Data' + (cpfBlocklist.id > 0 ? ' da Inclusão' : '')}
          >
            <Input
              name="inclusao"
              value={
                cpfBlocklist.inclusao === ''
                  ? moment().format('DD/MM/YYYY')
                  : moment(cpfBlocklist.inclusao).format('DD/MM/YYYY')
              }
              disabled
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="usuarioInclusao"
            label={'Responsável' + (cpfBlocklist.id > 0 ? ' da Inclusão' : '')}
          >
            <Field name="usuarioInclusao">
              {({ field }) => (
                <Input
                  {...field}
                  name="usuarioInclusao"
                  value={
                    cpfBlocklist.id > 0
                      ? cpfBlocklist.usuarioInclusao
                      : localStorage.getItem('login')
                  }
                  disabled
                />
              )}
            </Field>
          </Form.Item>
        </div>
        {cpfBlocklist.id > 0 && (
          <>
            <div className="col-lg-12">
              <Form.Item
                name="descricaoExclusao"
                label={
                  'Observação' + (cpfBlocklist.id > 0 ? ' da Exclusão' : '')
                }
                validate={(value) => {
                  let msgError = null;
                  if (!value) {
                    msgError = 'Campo obrigatório';
                    setStatusErrorObs('warning');
                  } else {
                    setStatusErrorObs('');
                  }
                  return msgError;
                }}
                validateStatus={touched.descricaoExclusao && statusErrorObs}
                hasFeedback
              >
                <Field name="descricaoExclusao">
                  {({ field }) => (
                    <TextArea
                      {...field}
                      name="descricaoExclusao"
                      disabled={
                        acessoEdicao
                          ? cpfBlocklist.id > 0 &&
                            cpfBlocklist.exclusao !== null
                          : true
                      }
                      placeholder={
                        cpfBlocklist.id > 0 ? '' : 'Informe uma observação'
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="exclusao"
                label={'Data' + (cpfBlocklist.id > 0 ? ' da Exclusão' : '')}
              >
                <Input
                  name="exclusao"
                  value={
                    cpfBlocklist.exclusao === null
                      ? moment().format('DD/MM/YYYY')
                      : moment(cpfBlocklist.exclusao).format('DD/MM/YYYY')
                  }
                  disabled
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="usuarioExclusao"
                label={
                  'Responsável' + (cpfBlocklist.id > 0 ? ' da Exclusão' : '')
                }
              >
                <Field name="usuarioExclusao">
                  {({ field }) => (
                    <Input
                      {...field}
                      name="usuarioExclusao"
                      value={
                        cpfBlocklist.id > 0 && cpfBlocklist.usuarioExclusao
                          ? cpfBlocklist.usuarioExclusao
                          : localStorage.getItem('login')
                      }
                      disabled
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
          </>
        )}
      </div>
      <div className="d-flex justify-content-end botoes-acao">
        <Button
          className="btn-cancelar"
          variant="gray"
          type="button"
          onClick={() => handleReset()}
        >
          <span>Cancelar</span>
        </Button>
        {cpfBlocklist.id === 0 && !acessoInclusao && (
          <Button className="btn-salvar" type="submit" variant="green">
            <IconSalvar />
            <span>Salvar</span>
          </Button>
        )}
        {cpfBlocklist.id > 0 && cpfBlocklist.exclusao === null && acessoEdicao && (
          <Button className="btn-salvar" type="submit" variant="green">
            <IconSalvar />
            <span>Excluir</span>
          </Button>
        )}
      </div>
    </>
  );
}

export default FormBlocklist;
