import React, { useContext } from 'react';

import Option from '../../components/Option';
import { Form, Input, Select } from 'formik-antd';
import InputMask from 'react-text-mask';
import { cpfMask } from '../../utils/masks';

import Button from '../../components/Button';

import { ReactComponent as IconSearch } from '../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../assets/images/icons/erase.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';

import { UsuariosContext } from '../../contexts/UsuariosContext';

function Filter({ formik }) {
  const { contexto, setFilters, blankValues } = useContext(UsuariosContext);
  const { values, setFieldValue } = formik;

  const handleEnvioFormulario = () => {
    let cpfFormatado = values?.filtroCPF
      ?.replaceAll('.', '')
      ?.replaceAll('-', '');
    setFilters({
      pagina: 1,
      filtroCPF: cpfFormatado,
      filtroNome: values.filtroNome,
      filtroLogin: values.filtroLogin,
      filtroMatriz: values.filtroMatriz,
      filtroUnidade: values.filtroUnidade,
      filtroAtivo: values?.filtroAtivo ? values.filtroAtivo : null,
      filtroBloqueado: values?.filtroBloqueado ? values.filtroBloqueado : null,
      filtroTipo: values?.filtroTipo ? values.filtroTipo : [],
    });
  };

  const listagemAtivo = [
    { id: 1, value: 1, label: 'Ativo' },
    { id: 2, value: 2, label: 'Inativo' },
  ];

  const listagemBloqueado = [
    { id: 1, value: 1, label: 'Sim' },
    { id: 2, value: 2, label: 'Não' },
  ];

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="box-filtros">
          <Form layout="vertical">
            <div className="filter-grid">
              <div className="row">
                <div className="col-lg-10">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="item-filter">
                        <Form.Item label="Tipo" name="filtroTipo">
                          <Select
                            mode="multiple"
                            name="filtroTipo"
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            allowClear
                            value={values?.filtroTipo}
                            onChange={(val) => setFieldValue('filtroTipo', val)}
                          >
                            {contexto?.tipoUsuario?.map((item) => (
                              <Option key={item?.id} value={item?.id}>
                                {item?.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="item-filter">
                        <Form.Item label="CPF" name="filtroCPF">
                          <InputMask
                            mask={cpfMask}
                            className="ant-input"
                            placeholder="000.000.000-00"
                            value={values?.filtroCPF || ''}
                            onChange={(val) => {
                              setFieldValue('filtroCPF', val.target.value);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="item-filter">
                        <Form.Item label="Nome" name="filtroNome">
                          <Input
                            type="text"
                            name="filtroNome"
                            placeholder="Nome completo"
                            onChange={(val) =>
                              setFieldValue('filtroNome', val.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="item-filter">
                        <Form.Item label="Login" name="filtroLogin">
                          <Input
                            type="text"
                            name="filtroLogin"
                            placeholder="Login completo"
                            onChange={(val) =>
                              setFieldValue('filtroLogin', val.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="item-filter">
                        <Form.Item label="Matriz" name="filtroMatriz">
                          <Input
                            type="text"
                            name="filtroMatriz"
                            placeholder="Digite aqui"
                            onChange={(val) =>
                              setFieldValue('filtroMatriz', val.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="item-filter">
                        <Form.Item label="Unidade" name="filtroUnidade">
                          <Input
                            type="text"
                            name="filtroUnidade"
                            placeholder="Digite aqui"
                            onChange={(val) =>
                              setFieldValue('filtroUnidade', val.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item-filter">
                        <Form.Item label="Ativo" name="filtroAtivo">
                          <Select
                            name="filtroAtivo"
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            allowClear
                            value={values?.filtroAtivo}
                            onChange={(val) =>
                              setFieldValue('filtroAtivo', val)
                            }
                          >
                            {listagemAtivo.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item-filter">
                        <Form.Item label="Bloqueado" name="filtroBloqueado">
                          <Select
                            name="filtroBloqueado"
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            allowClear
                            value={values?.filtroBloqueado}
                            onChange={(val) =>
                              setFieldValue('filtroBloqueado', val)
                            }
                          >
                            {listagemBloqueado.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="col-buttons">
                    <Button
                      type="button"
                      className="btn-pesquisar"
                      variant="blue"
                      async={true}
                      onClick={() => {
                        handleEnvioFormulario();
                      }}
                    >
                      <IconSearch />
                      Pesquisar
                    </Button>
                    <Button
                      type="button"
                      className="btn-limpar"
                      async={true}
                      variant="gray"
                      onClick={() => {
                        setFilters(blankValues);
                        setFieldValue('filtroTipo', []);
                        setFieldValue('filtroCPF', null);
                        setFieldValue('filtroNome', null);
                        setFieldValue('filtroLogin', null);
                        setFieldValue('filtroMatriz', null);
                        setFieldValue('filtroUnidade', null);
                        setFieldValue('filtroAtivo', 1);
                        setFieldValue('filtroBloqueado', 2);
                      }}
                    >
                      <IconErase />
                      Limpar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Filter;
