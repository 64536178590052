import { object, string, number, array } from 'yup';
import * as Yup from 'yup';
import validaCep from '../../../utils/validaCep';
import validaCNPJ from '../../../utils/validaCnpj';
import validaCPF from '../../../utils/validaCpf';
import { SubTipoConvenio, TipoCredenciado } from '../../../utils/identificadoresFrontBack';

Yup.addMethod(string, 'validateCNPJ', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCNPJ(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCPF(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateCEP', function (errorMessage) {
  return this.test(`test-cep`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCep(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateEmail', function (errorMessage) {
  return this.test(`test-email`, errorMessage, function (value) {
    const { path, createError } = this;
    // eslint-disable-next-line
    if (value?.indexOf('.') == -1 || value?.indexOf('@') == -1) {
      return false;
    } else {
      return true || createError({ path, message: errorMessage });
    }
  });
});
Yup.addMethod(string, 'validateTelefone', function (errorMessage) {
  return this.test(`test-telefone`, errorMessage, function (value) {
    if (value?.replace(/\D/g, '').length < 10) {
      return false;
    } else {
      return true;
    }
  });
});

const validateProdutos = (produtos) => {
  return produtos?.filter(produto => produto.id && produto.id !== null && produto.id !== undefined).length > 0;
};

export const validationSchema = object().shape({
  dadosGerais: object().shape({
    ativo: Yup.boolean().nullable(),
    unicard: Yup.boolean().nullable(),
    analisePromotora: Yup.boolean().nullable(),
    tipoCredenciado: number().required('Campo Obrigatório').nullable(),
    subTipoCredenciado: number().required('Campo Obrigatório').nullable(),
    classificacaoCredenciado: Yup.array()
      .test('teste-classificao', 'Campo Obrigatório', function (classificao) {
        const tipoCredenciado = this.parent?.tipoCredenciado;

        if (tipoCredenciado !== 0) {
          return true;
        }
        if (tipoCredenciado === 0 && classificao.length === 0) return false;
        return true;
      }),
    cpfCnpj: string()
      .validateCNPJ('CNPJ inválido')
      .required('Campo Obrigatório')
      .nullable(),
    nome: string().required('Campo Obrigatório').nullable(),
    analiseOCRFatura: Yup.boolean()
    .when(['tipoCredenciado', 'subTipoCredenciado'], {
      is: (tipoCredenciado, subTipoCredenciado) =>
        tipoCredenciado === TipoCredenciado.CONVENIO_ID && subTipoCredenciado === SubTipoConvenio.CIA_ELETRICA_ID,
      then: Yup.boolean().required('Campo Obrigatório').nullable(),
    })
    .nullable(),
  }),
  endereco: object().shape({
    cep: string().nullable(),
    logradouro: string().nullable(),
    bairro: string().nullable(),
    numero: string().nullable(),
    cidadeId: Yup.number()
    .nullable()
    .when('ufId', {
      is: (val) => val !== null && val !== undefined,
      then: Yup.number().required('Campo Obrigatório'),
      otherwise: Yup.number().nullable(),
    }),
    ufId: number().required('Campo Obrigatório').nullable(),
    complemento: string().nullable(),
  }),
  contato: array().of(
    Yup.object().shape({
      id: number().nullable(),
      nome: string().nullable(),
      email: string().validateEmail('E-mail inválido').nullable(),
      telefone: string().validateTelefone('Telefone inválido').nullable(),
      tipoContato: number().nullable(),
      observacao: string().nullable(),
    }),
  ),
  visita: object().shape({
    visita: Yup.boolean().nullable(),
    dataVisita: string()
      .when('visita', {
        is: true,
        then: string().required('Campo Obrigatório').nullable(),
      })
      .nullable(),
    horaVisita: string().nullable(),
    contatoVisita: string().nullable(),
  }),
  socios: array().of(
    Yup.object().shape({
      cpf: string().validateCPF('CPF inválido').nullable(),
      nome: string().nullable(),
      pep: Yup.boolean(),
    }),
  ),
  dadosBancarios: object().shape({
    banco: string().nullable(),
    agencia: string().nullable(),
    agenciaDigito: string().nullable(),
    contaNumero: string().nullable(),
    conta: string().nullable(),
    tipoConta: string().nullable(),
    tempoConta: string().nullable(),
    tipoChavePix: number().nullable(),
    chavePix: Yup.string()
      .transform((value) => value ? value.replace(/\s+/g, '') : value)
      .when('tipoChavePix', {
        is: (tipoChavePix) => tipoChavePix !== null && tipoChavePix !== undefined,
        then: Yup.string().required('Campo Obrigatório').nullable(),
        otherwise: Yup.string().nullable(),
      })
      .nullable(),
  }),
  produtos: object().shape({
    tipoModalidade: Yup.number()
      .nullable()
      .when('produtos', {
        is: (produtos) => validateProdutos(produtos),
        then: Yup.number().required('Obrigatório informar a modalidade').nullable(),
        otherwise: Yup.number().nullable(),
      }),
    produtos: array().of(
      Yup.object().shape({
        id: string().nullable(),
        modalidadeOpcoes: array().nullable(),
        propriedade: Yup.object({
          nome: string().nullable(),
          opcoes: array().of(number()).nullable(),
        }).nullable(),
      }),
    ),
  }),
  comercial: object().shape({
    hierarquia: object().shape({
      dataVigenciaInicial: string().nullable(),
      id: number().nullable(),
      niveis: array()
        .of(
          Yup.object().shape({
            id: number().nullable(),
            posicaoId: number().nullable(),
          }),
        )
        .nullable(),
    }),
  }),
  documentos: array()
    .of(
      Yup.object({
        id: string().nullable(),
        imagemId: number().nullable(),
        tipoDocumentoCredenciado: number().when('imagemId', {
          is: number(),
          then: Yup.number().required().integer(),
        }),
      }),
    )
    .nullable(),
  regioes: array().of(
    Yup.object({
      id: string().nullable(),
      ufId: number().nullable(),
      ufNome: string().nullable(),
      cidadeId: number().nullable(),
      cidadeNome: string().nullable(),
      opera: Yup.boolean().nullable(),
    }),
  ),
  dadosConsignado: object().shape({
    restrito: Yup.boolean().required('Campo Obrigatório'),
    diaCorte: number().nullable(),
    diaVencimento: number().required('Campo Obrigatório').nullable(),
    quantidadeVidas: number().nullable(),
    turnOver: number().nullable(),
    percentualCalculoMargem: number().required("Campo Obrigatório").nullable(),
    bancoConcorrente: array().of(string()).nullable(),
    convenioRiscoId: string().required('Campo Obrigatório').nullable(),
    averbadora: number().required('Campo Obrigatório').nullable(),
    codigoConvenio: string()
    .when('averbadora', {
      is: 2,
      then: Yup.string().required('Campo Obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    codigoConvenioReserva: string()
    .when('averbadora', {
      is: 2,
      then: Yup.string().required('Campo Obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  }),
});
