import React, { useContext, useEffect, useState } from 'react';

import { Modal, Checkbox, Button } from 'antd';
import DatePicker from '../../../components/DatePicker';

import Tooltip from '../../../components/tooltip';

import { ReactComponent as IconArrowBack } from '../../../assets/images/icons/arrow-back-left.svg';
import { ReactComponent as IconArrowsRightLeft } from '../../../assets/images/icons/circle-arrows-right-left.svg';
import { ReactComponent as IconClose } from '../../../assets/images/icons/close-modal-X.svg';
import { ReactComponent as IconWarning } from '../../../assets/images/icons/exclamacao-icon.svg';
import { ReactComponent as IconSixDots } from '../../../assets/images/icons/seis-circulos.svg';
import { ReactComponent as IconCalendar } from '../../../assets/images/icons/calendar.svg';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';
import moment from 'moment';

import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
let origemList = [];
let origemListCompare = [];

const ModalTransfDependentes = ({ visible, hierarquiaId, hierarquiaIndex }) => {
  const contexto = useContext(HierarquiasContext);
  const {
    setInfoModalTransDependencias,
    infoModalTransDependencias,
    hierarquias,
    setHierarquias,
    modalNotification,
    searchPosition,
    dependentes,
    removePosition,
    replacePosition,
    triggerModalConfirmaInativacao,
  } = contexto;

  const { parentId, nivelIndex, hierarquiaPositionId } =
    infoModalTransDependencias;

  const hierarquia = JSON.parse(JSON.stringify(hierarquias[hierarquiaIndex]));
  const hierarquiaModal = searchPosition(
    infoModalTransDependencias.hierarquiaPositionId,
    hierarquia.posicoes,
  );

  const [discarting, setDiscarting] = useState(false);

  const [selectedsList, setSelectedsList] = useState([]);
  const [destinosList, setDestinosList] = useState([]);
  const [destinoSelected, setDestinoSelected] = useState(null);
  const [destinoTransfList, setDestinoTransfList] = useState([]);
  const [lastAlteracao, setLastAlteracao] = useState(null);
  const [visibleModalDateInativacao, setVisibleModalDateInativacao] =
    useState(false);
  const [inativacaoDate, setInativacaoDate] = useState(new Date());

  const desfazerAlteracao = () => {
    setLastAlteracao(null);
    if (destinoTransfList.length > 0) {
      const newDestinoList = [
        ...destinoTransfList[destinoTransfList.length - 1].destino,
      ];
      ordenaDestino(newDestinoList);
      setDestinosList([...newDestinoList]);
      ordenaOrigem(destinoTransfList[destinoTransfList.length - 1].origem);
      origemList = [...destinoTransfList[destinoTransfList.length - 1].origem];
      const newDestinoTransfList = [...destinoTransfList];
      newDestinoTransfList.pop();
      setDestinoTransfList(newDestinoTransfList);
    }
  };

  const initialValues = {
    listaHierarquias: [
      {
        id: null,
        nome: null,
        ativo: true,
        cadastro: null,
        ultimaAlteracao: null,
        hierarquiaNivel: [
          {
            id: null,
            nome: null,
            ativo: null,
          },
        ],
      },
    ],
  };

  const formik = useFormik({
    initialValues,
  });

  const selectItem = (value, id) => {
    const newList = [...selectedsList];
    if (value) {
      newList.push(id);
    } else {
      const index = selectedsList.indexOf(id);
      newList.splice(index, 1);
    }
    setSelectedsList([...newList]);
  };

  function selectAll() {
    // eslint-disable-next-line
    if (!(selectedsList.length == origemList.length)) {
      const newList = [...origemList];
      newList.forEach((item) => {
        if (selectedsList.indexOf(item.id) !== -1) {
          selectItem(true, item.id);
        }
      });
      const newSelectedsList = newList?.map((item) => item.id);
      setSelectedsList([...newSelectedsList]);
    } else {
      setSelectedsList([]);
    }
  }

  function saveHistory() {
    const newDestinoList = JSON.parse(JSON.stringify(destinosList));
    setDestinoTransfList([
      ...destinoTransfList,
      { origem: [...origemList], destino: [...newDestinoList] },
    ]);
  }

  const returnItem = (parentId, itemId) => {
    saveHistory();
    const newList = [...destinosList];
    for (let i = 0; i < newList.length; i++) {
      if (newList[i].id === parentId) {
        for (let j = 0; j < newList[i].posicoes.length; j++) {
          if (newList[i].posicoes[j].id === itemId) {
            const itemRemoved = newList[i].posicoes.splice(j, 1);
            const orderedOrigemList = ordenaOrigem([
              ...origemList,
              itemRemoved[0],
            ]);
            origemList = orderedOrigemList;
            return;
          }
        }
      }
    }
  };

  const transferirDependentes = (id) => {
    saveHistory();
    if (destinoSelected) {
      setLastAlteracao({
        origemIds: origemList,
        destinoId: destinoSelected,
      });
    }
    if (id) {
      const newDestinoList = JSON.parse(JSON.stringify(destinosList));
      const itemToAddOrigem = origemList.filter(
        (item) => selectedsList.indexOf(item.id) !== -1,
      );
      let newOrigemList = JSON.parse(JSON.stringify(origemList));
      if (selectedsList.length > 0) {
        newOrigemList = newOrigemList.filter((item) => {
          // eslint-disable-next-line
          return selectedsList.indexOf(item.id) == -1;
        });
      }
      let selectedTree = JSON.parse(JSON.stringify(hierarquias)).find(
        (arvore, index) => arvore.id === hierarquiaId,
      );
      if (itemToAddOrigem.length > 0) {
        // eslint-disable-next-line
        selectedTree = removePosition(itemToAddOrigem[0].id, selectedTree);
      }
      for (let i = 0; i < destinosList.length; i++) {
        // eslint-disable-next-line
        if (destinosList?.[i]?.id == id) {
          newDestinoList?.[i]?.posicoes.push(...itemToAddOrigem);
          newDestinoList?.[i]?.posicoes.sort((a, b) =>
            a.nome.localeCompare(b.nome),
          );
        }
      }
      ordenaOrigem(newOrigemList);
      ordenaDestino(newDestinoList);
      origemList = newOrigemList;
      setDestinosList(newDestinoList);
      setSelectedsList([]);
    }
  };

  const saveTransferencia = () => {
    const newDependentes = [...dependentes];
    newDependentes.filter((item) => {
      return origemList.find((origem) => origem.id === item.id);
    });
    setVisibleModalDateInativacao(true);
  };

  const cancelTransferencia = () => {
    if (destinoTransfList.length > 0) {
      setDiscarting(true);
      modalNotification(
        'warning',
        'Deseja descartar as alterações?',
        [
          { text: 'não', type: 'cancel', variant: 'red' },
          { text: 'sim', type: 'confirm', variant: 'blue' },
        ],
        () => {
          setInfoModalTransDependencias(() => ({
            visible: false,
          }));
          contexto.cancelModalNotification();
        },
        () => {
          setDiscarting(false);
          contexto.cancelModalNotification();
        },
      );
    } else {
      setInfoModalTransDependencias({ visible: false });
      contexto.setEditingHierarquia(false);
      contexto.setEditHierarquiaId(null);
    }
  };

  useEffect(() => {
    let orderOrigemList = ordenaOrigem(hierarquiaModal.posicoes);
    origemList = orderOrigemList;
    origemListCompare = orderOrigemList?.map((item) => item.id);
    let posDestino = parentId
      ? searchPosition(parentId, hierarquia?.posicoes)?.posicoes
      : hierarquia?.posicoes;
    let posDestinoFilter = posDestino?.filter(
      (item) => item?.id !== hierarquiaModal?.id && item?.ativo === true,
    );
    origemList = origemList.filter((item) => item.ativo);
    posDestinoFilter = ordenaDestino(posDestinoFilter);
    setDestinosList(posDestinoFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ordenaOrigem(list) {
    return list.sort((a, b) => a.nome.localeCompare(b.nome));
  }

  function transferirDependentesSubmit() {
    let indexSelectedTree = 0;

    const newHierarquiasInfos = JSON.parse(JSON.stringify(hierarquias));
    let selectedTree = newHierarquiasInfos.find((arvore, index) => {
      if (arvore.id === hierarquiaId) {
        indexSelectedTree = index;
        return arvore;
      }
      return false;
    });
    let itemSelectedModalTransferenciaDependentes = {
      parentNodeId: selectedTree.id,
      nodeId: hierarquiaModal.id,
    };
    const { parentNodeId, nodeId } = itemSelectedModalTransferenciaDependentes;
    var parentNode = [];
    if (parentNodeId) {
      parentNode = searchPosition(nodeId, selectedTree?.posicoes);
    } else {
      // eslint-disable-next-line
      parentNode = selectedTree;
    }

    selectedTree.posicoes = replacePosition(
      destinosList,
      selectedTree.posicoes,
    );

    deleteNode(selectedTree.posicoes, nodeId);
    newHierarquiasInfos[indexSelectedTree] = selectedTree;
    setHierarquias([...newHierarquiasInfos]);
    setVisibleModalDateInativacao(false);
    setInfoModalTransDependencias({ visible: false });
  }

  function deleteNode(nodeList, id) {
    const nodeFound = nodeList?.find((item) => item?.id === id);
    if (nodeFound) {
      nodeFound['ativo'] = false;
      nodeFound['dataInativacao'] = moment(inativacaoDate).format('YYYYMMDD');
    } else {
      nodeList.forEach((item) => {
        if (item.posicoes) {
          deleteNode(item?.posicoes, id);
        }
      });
    }
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  function ordenaDestino(list) {
    list.sort((a, b) => a.nome.localeCompare(b.nome));
    list.forEach((item) => {
      item.posicoes.sort((a, b) => a.nome.localeCompare(b.nome));
    });
    return list;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <Modal
            width={747}
            visible={visible && !discarting}
            footer={null}
            className="modal-transferencia-dependencia"
            closeIcon={<IconClose />}
            onCancel={cancelTransferencia}
          >
            <>
              <div className="modal-title">
                <IconWarning className="icon-warning" />
                Transferir dependências {hierarquiaModal.nome}
              </div>
              <div className="modal-grid">
                <div className="options-camp">
                  <div className="label-option-camp">
                    Selecione os supervisores que deseja transferir:
                  </div>
                  <label className="select-all-camp">
                    <Checkbox
                      checked={
                        selectedsList.length !== 0 &&
                        selectedsList.length === origemList.length
                      }
                      className="styled-checkbox"
                      onClick={() => {
                        selectAll();
                      }}
                    />
                    Selecionar todos
                  </label>
                  {origemList?.map((item, index) => (
                    <PosicaoItem
                      key={'supervisor-' + index}
                      item={item}
                      origin={'origem'}
                      checked={selectedsList?.indexOf(item?.id) !== -1}
                      returnItem={returnItem}
                      selectItem={selectItem}
                      transferirDependentes={transferirDependentes}
                      isDestino={false}
                    />
                  ))}
                </div>
                <div className="icon-camp">
                  <IconArrowsRightLeft />
                </div>
                <div className="options-camp">
                  <div className="label-option-camp">
                    Selecionar para onde serão transferidos:
                  </div>
                  {destinosList?.map((item, index) => (
                    <PosicaoItem
                      key={'gerente-' + index}
                      item={item}
                      origin={'destino'}
                      selectItem={selectItem}
                      destinoSelected={destinoSelected}
                      setDestinoSelected={setDestinoSelected}
                      returnItem={returnItem}
                      transferirDependentes={transferirDependentes}
                      isDestino={true}
                    />
                  ))}
                </div>
              </div>
              <div className="text-right group-btns">
                <Tooltip
                  placement="topLeft"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  overlayClassName="tooltip-styled-crefaz"
                  title="Desfazer a última alteração"
                >
                  <Button
                    type="button"
                    variant="blue"
                    className="btn-acao icon-btn"
                    onClick={!lastAlteracao && desfazerAlteracao}
                  >
                    <IconArrowBack className="arrow-back" />
                  </Button>
                </Tooltip>
                <Button
                  onClick={() => {
                    cancelTransferencia();
                  }}
                  type="button"
                  variant="grey"
                  className="btn-acao"
                >
                  Cancelar
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    saveTransferencia();
                  }}
                  className="btn-acao"
                >
                  Transferir
                </Button>
              </div>
            </>
          </Modal>
          <Modal
            visible={visibleModalDateInativacao}
            centered
            onCancel={() => setVisibleModalDateInativacao(false)}
            footer={null}
            width={452}
            closeIcon={<IconClose />}
            maskClosable={false}
            className="modal-data-inativacao"
          >
            <div className="title">Data de Inativação</div>
            <div className="line"></div>
            <label>
              Data de inativação
              <DatePicker
                suffixIcon={<IconCalendar />}
                clearIcon={false}
                value={moment(inativacaoDate)}
                placeholder="00/00/0000"
                disabledDate={disabledDate}
                onChange={(value) => {
                  setInativacaoDate(value);
                }}
                format="DD/MM/YYYY"
              />
            </label>

            <div className="btn-area">
              <Button
                onClick={() => {
                  setVisibleModalDateInativacao(false);
                }}
                variant="gray"
                className="modal-btn btn-cancel"
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if (origemList?.length === 0) {
                    transferirDependentesSubmit();
                  } else {
                    setVisibleModalDateInativacao(false);
                    setInfoModalTransDependencias({ visible: false });
                    triggerModalConfirmaInativacao(
                      {
                        hierarquiaId: infoModalTransDependencias.hierarquiaId,
                        nivelIndex,
                        positionId: hierarquiaPositionId,
                      },
                      origemList.map((item) => item.id),
                      () => {
                        transferirDependentesSubmit();
                      },
                    );
                  }
                }}
                variant="red"
                className="modal-btn btn-inativar"
              >
                Inativar
              </Button>
            </div>
            <div></div>
          </Modal>
        </Form>
      </FormikProvider>
    </>
  );
};

function PosicaoItem({
  item,
  subitem,
  selectItem,
  origin,
  checked,
  returnItem,
  parentId,
  transferirDependentes,
  isDestino,
  level = 1,
}) {
  return (
    <>
      <div
        onClick={() =>
          // eslint-disable-next-line
          item?.posicoes && origin == 'destino'
            ? transferirDependentes(item?.id)
            : ''
        }
        className={`posicao-component ${
          !item?.posicoes ? 'clickable' : 'parent-elem'
        } ${subitem ? 'sub-item' : ''}`}
      >
        <IconSixDots className="posicao-dots" />
        <div className="posicao-label">{item?.nome}</div>
        {subitem ? (
          <Tooltip
            placement="topRight"
            arrowPointAtCenter
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            overlayClassName="tooltip-styled-crefaz"
            title="Desfazer"
          >
            <IconArrowBack
              className="arrow-back"
              onClick={() => returnItem(parentId, item?.id)}
            />
          </Tooltip>
        ) : (
          origin === 'origem' && (
            <Checkbox
              checked={checked}
              onChange={(e) => selectItem(e.target.checked, item?.id)}
              className="styled-checkbox"
            />
          )
        )}
      </div>
      {item?.posicoes &&
        level === 1 &&
        isDestino && // Listagem de destino com no máximo 2 níveis
        item?.posicoes?.map(
          (subordinado, index) =>
            origemListCompare?.includes(subordinado?.id) && (
              <PosicaoItem
                key={item.id + 'subordinado-' + index}
                item={subordinado}
                subitem
                parentId={item.id}
                returnItem={returnItem}
                transferirDependentes={transferirDependentes}
                isDestino={isDestino}
                level={level + 1}
              />
            ),
        )}
    </>
  );
}

export default ModalTransfDependentes;
