import React from 'react';

import Tag from '../../components/Tag';

import './styles.scss';

function CardBordero({
  icon,
  title,
  targetText = null,
  targetColor = null,
  children,
}) {
  return (
    <div className="card-bordero">
      <div className="row card-bordero-header d-flex justify-content-between align-items-center">
        <div className="widget-title">
          {icon}&ensp;{title}
        </div>

        {targetText && (
          <Tag color={targetColor} width={75}>
            {targetText}
          </Tag>
        )}
      </div>
      <div className="card-bordero-content">{children}</div>
    </div>
  );
}

export default CardBordero;
