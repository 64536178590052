import { useEffect, useState } from 'react';

import { Form } from 'formik-antd';
import { Select } from 'antd';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';

import { ReactComponent as IconTrashBtn } from '../../../assets/images/icons/icon-trash2.svg';

import Button from '../../../components/Button';

import { listarCidades } from '../../../services/enderecoService';
import SelectSearch from '../../../components/SearchSelect';

const { Option } = Select;

function RegioesForm({
  formik,
  regioesCredenciado,
  estados,
  type,
  setIsFormEdited,
}) {
  const { setFieldValue, values, getFieldProps } = formik;

  const [listaEstadosCidades, setListaEstadosCidades] = useState();

  useEffect(() => {
    setListaEstadosCidades(
      estados.map((item) => {
        return { ...item, cidades: [] };
      }),
    );
  }, [estados]);

  useEffect(() => {
    if (regioesCredenciado?.length && listaEstadosCidades?.length) {
      regioesCredenciado?.forEach((item) => {
        if (item?.ufId) {
          atualizaCidades(item.ufId);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regioesCredenciado, listaEstadosCidades]);

  const initialValuesRegiao = {
    id: null,
    ufId: null,
    ufNome: '',
    cidadeId: null,
    cidadeNome: '',
    opera: false,
  };

  const fetchCidades = async (ufId) => {
    return await listarCidades(ufId);
  };

  const handleAddRegiao = () => {
    setFieldValue(
      `regioes`,
      values?.regioes?.length > 0
        ? [...values?.regioes, initialValuesRegiao]
        : [initialValuesRegiao],
    );
    setIsFormEdited(true);
  };
  const handleRemoveRegiao = (index) => {
    if (values?.regioes?.length > 1) {
      const newListRegioes = getFieldProps('regioes').value;
      newListRegioes?.splice(index, 1);
      setFieldValue(`regioes`, [...newListRegioes]);
    } else {
      setFieldValue(`regioes`, []);
    }
    setIsFormEdited(true);
  };

  const atualizaCidades = async (ufId) => {
    let ufIndex = listaEstadosCidades?.map((estado) => estado.id).indexOf(ufId);
    if (ufIndex >= 0 && !listaEstadosCidades[ufIndex]?.cidades?.length) {
      let newListCidadesEstados = [...listaEstadosCidades];
      if (newListCidadesEstados !== undefined) {
        newListCidadesEstados[ufIndex]['cidades'] = await fetchCidades(ufId);
        setListaEstadosCidades(newListCidadesEstados);
      }
    }
  };

  function listaCidadesFormatada(i) {
    // armazena todas as cidades do estado
    let listaCidades =
      listaEstadosCidades[
        listaEstadosCidades
          ?.map((estado) => estado.id)
          ?.indexOf(values?.regioes[i]?.ufId)
      ]?.cidades;

    // lista de todas as cidades sendo individualmente adicionadas
    let listaCidadesInclude = listaCidades?.filter((item) =>
      regioesCredenciado?.find(
        (regiao) => regiao.cidadeId === item.id && regiao.opera,
      ),
    );

    // lista de todas as cidades sendo individualmente bloqueadas
    let listaCidadesExclude = listaCidades?.filter((item) =>
      regioesCredenciado?.find(
        (regiao) => regiao.cidadeId === item.id && !regiao.opera,
      ),
    );

    // flag para identificar se há bloqueio da uf inteira
    let excludeUf = !!regioesCredenciado?.find(
      (regiao) =>
        regiao.ufId === values?.regioes[i].ufId &&
        regiao.cidadeId == null &&
        !regiao.opera,
    );

    // flag para identificar se há adição da uf inteira
    let includeUF = !!regioesCredenciado?.find(
      (regiao) =>
        regiao.ufId === values?.regioes[i].ufId &&
        regiao.cidadeId == null &&
        regiao.opera,
    );

    if (includeUF) {
      // se houver adição da UF inteira, retorna a lista completa de cidades EXCETO as que estão dentro da lista de cidades bloqueadas individualmente
      return listaCidades
        ?.filter(
          (item) =>
            !listaCidadesExclude?.find((cidade) => cidade.id === item.id),
        )
        ?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.nome}
            </Option>
          );
        });
    }

    if (excludeUf || listaCidadesInclude?.length) {
      // se houver bloqueio da UF inteira OU cidades individualmente adicionadas, retorna lista filtrada
      return listaCidadesInclude.map((item) => {
        return (
          <Option key={item.id} value={item.id}>
            {item.nome}
          </Option>
        );
      });
    }
  }

  return (
    <div className="dados-bloquear-regiao">
      <div className="form-row header-sub-item pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Bloquear Região</div>
      </div>
      {values?.regioes &&
        values?.regioes?.map((_, i) => {
          return (
            <div className="form-row row" key={i}>
              <div className="col-lg-2">
                <Form.Item name={`regioes[${i}].opera`} label="Bloquear">
                  <Select
                    name={`regioes[${i}].opera`}
                    suffixIcon={<IconArrowSelect />}
                    allowClear
                    disabled
                    value={false}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option key={false} value={false}>
                      -
                    </Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item label="UF" name="regioes.ufId">
                  <SelectSearch
                    name={`regioes[${i}].ufId`}
                    placeholder="Selecione"
                    suffixIcon={<IconArrowSelect />}
                    showSearch
                    value={values?.regioes[i]?.ufId}
                    onChange={(value) => {
                      setFieldValue(`regioes[${i}].ufId`, value);
                      setFieldValue(`regioes[${i}].cidadeId`, null);
                      atualizaCidades(value);
                      setIsFormEdited(true);
                    }}
                    disabled={type === 'read'}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {listaEstadosCidades?.map((item) => {
                      if (
                        regioesCredenciado
                          ?.map((regiao) => regiao?.opera && regiao?.ufId)
                          .indexOf(item.id) !== -1
                      ) {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.uf}
                          </Option>
                        );
                      }else{
                        return null;
                      }
                    })}
                  </SelectSearch>
                </Form.Item>
              </div>
              <div className="col-lg-5">
                <Form.Item label="Cidade" name="regioes.cidadeId">
                  <SelectSearch
                    name={`regioes[${i}].cidadeId`}
                    placeholder="Selecione"
                    suffixIcon={<IconArrowSelect />}
                    disabled={
                      type === 'read' ? true : !values?.regioes[i]?.ufId
                    }
                    showSearch
                    allowClear
                    value={values?.regioes[i]?.cidadeId || 'Selecione'}
                    onChange={(value) => {
                      setFieldValue(`regioes[${i}].cidadeId`, value);
                      setIsFormEdited(true);
                    }}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {values?.regioes[i]?.ufId && listaCidadesFormatada(i)}
                  </SelectSearch>
                </Form.Item>
              </div>
              <div className="col-lg-1 col-button-remove">
                <Button
                  onClick={() => handleRemoveRegiao(i)}
                  className="btn-trash"
                  disabled={type === 'read'}
                >
                  <IconTrashBtn />
                </Button>
              </div>
            </div>
          );
        })}
      <div className="col-lg-12 col-button-regiao">
        <Button
          type="button"
          className="btn-regiao"
          variant="blue"
          onClick={handleAddRegiao}
          disabled={type === 'read'}
        >
          <IconPlusBtn />
          Região
        </Button>
      </div>
    </div>
  );
}

export default RegioesForm;
