import React, { useContext, useEffect } from 'react';

import { Form, Input, TimePicker } from 'formik-antd';
import './styles.scss';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { Switch } from 'antd';
import DatePickerComponent from '../../../components/DatePicker';
import FormUpload from './VisitaFormUpload';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import moment from 'moment';

function VisitaForm({ formik, isReadOnly, userId }) {
  const {
    fileList,
    setFileList,
    statusContatoVisita,
    messageContatoVisita,
    messageHoraVisita,
    statusHoraVisita,
    validaDuasPalavras,
  } = useContext(CredenciadoContext);

  const {
    values,
    setFieldValue,
    setFieldError,
  } = formik;

  function validationDataVisita(value) {
    let error;
    if (values.visita.visita && (!value || !moment(value).isValid())) {
      error = 'Campo Obrigatório';
      setFieldError('values.visita.dataVisita', error);
    }
    return error;
  }

  useEffect(() => {
    formik.setFieldValue('visita.anexos', fileList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    formik.setFieldValue('visita.dataVisita', values.visita.dataVisita)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.visita?.visita]);

  return (
    <>
      <div className="row tab-visita-credenciamento">
        <div className="col-lg-12">
          <Form layout="vertical">
            <div className="row">
              <div className="col-lg-12">
                <Form.Item name="visita.visita">
                  <label className="switch-label">
                    Visita
                    <Switch
                      checked={values?.visita?.visita}
                      name="visita.visita"
                      style={{ marginLeft: 10 }}
                      onChange={(value) => {
                        setFieldValue('visita.visita', value);
                        // eslint-disable-next-line
                        if (value == false) {
                          setFieldValue('visita.dataVisita', null);
                          setFieldValue('visita.horaVisita', null);
                          setFieldValue('visita.contato', null);
                        }
                      }}
                      size="small"
                      className="switch-red-if-off"
                      disabled={isReadOnly}
                    />
                  </label>
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 visita-form-grid">
                <Form.Item
                  name="visita.dataVisita"
                  validate={validationDataVisita}
                  label="Data da visita"
                >
                  <DatePickerComponent
                    disabled={!values?.visita?.visita || isReadOnly}
                    name="visita.dataVisita"
                    format="DD/MM/YYYY"
                    placeholder="00/00/0000"
                    disabledDate={(date) => date > Date.now()}
                    onChange={(value) => {
                      const hora = formik.values.visita.horaVisita;
                      if (
                        hora &&
                        moment(value).isSame(moment(), 'd') &&
                        moment(hora).isAfter(moment())
                      ) {
                        setFieldValue('visita.horaVisita', moment());
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="visita.horaVisita"
                  label="Hora da visita"
                  help={messageHoraVisita}
                  validateStatus={statusHoraVisita}
                >
                  <TimePicker
                    disabled={!values?.visita?.visita || isReadOnly}
                    name="visita.horaVisita"
                    placeholder="00:00"
                    format={['HH:mm', 'HHmm']}
                    locale={locale}
                    className="time-picker"
                    disabledDate={(value) =>
                      moment(value)
                        .day(moment(formik.values.visita.dataVisita).day())
                        .month(moment(formik.values.visita.dataVisita).month())
                        .year(moment(formik.values.visita.dataVisita).year())
                        .isAfter(moment())
                    }
                  />
                </Form.Item>
                <Form.Item
                  name="visita.contato"
                  help={statusContatoVisita}
                  validateStatus={messageContatoVisita}
                  label="Contato da visita"
                >
                  <Input
                    disabled={!values?.visita?.visita || isReadOnly}
                    name="visita.contato"
                    value={values?.visita?.contato}
                    onChange={(e) =>
                      setFieldValue('visita.contato', e.target.value)
                    }
                    onBlur={(e) => validaDuasPalavras(e, 'Contato na visita')}
                    placeholder="Digite aqui"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 form-upload-visita-credenciamento">
                <label className="label-upload">Anexos</label>
                <FormUpload
                  disabled={!values?.visita?.visita || isReadOnly}
                  fileList={fileList}
                  setFileList={setFileList}
                  isReadOnly={isReadOnly}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default VisitaForm;
