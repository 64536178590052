import React from 'react';
import { Tooltip } from 'antd';

import { ReactComponent as IconEditar } from '../../assets/images/icons/icon-editar.svg';

const TooltipAlterados = () => (
  <Tooltip
    placement="top"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title="Este registro foi alterado"
  >
    <IconEditar width={10} className="icone-campo-alterado" />
  </Tooltip>
);

export default TooltipAlterados;
