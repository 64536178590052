import React, { createContext, useCallback, useState, useEffect } from 'react';
import moment from 'moment';

import {
  listarComissoes,
  listarNfs,
  getContextoComissao,
  imprimirCSVComissao,
  imprimirPDFComissao,
  uploadNotas,
} from '../services/relatorioComissaoService';
import ModalComponent from '../components/Modal';

const initialFiltersComissoes = {
  cpf: null,
  codigoOperacao: null,
  matriz: [],
  rangeDataPagamento: [],
  rangeDataComissao: [moment().add(-1, 'days'), moment()],
  ordenacao: null,
  ordenacaoAsc: null,
  quantidadePorPagina: 15,
  filtroProduto: null,
};

const initialFiltersNfs = {
  filtroNomeArquivo: '',
  filtroNomeMatriz: '',
  rangeData: [moment().add(-1, 'days'), moment()],
  ordenacao: null,
  ordenacaoAsc: null,
  quantidadePorPagina: 15,
};

export const RelatorioComissaoContext = createContext({
  comissoes: [],
  contexto: {},
  loading: false,
  total: 0,
  pageCount: 0,
  filtersComissoes: initialFiltersComissoes,
  showFilter: false,
  fetchComissoes: async (f) => f,
  fetchNfs: async (f) => f,
  setShowFilter: (f) => f,
  setFiltersComissoes: (f) => f,
});

function RelatorioComissaoProvider({ children }) {
  const [comissoes, setComissoes] = useState([]);
  const [contexto, setContexto] = useState([]);

  const [notasFiscais, setNotasFiscais] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNfs, setLoadingNfs] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [pageIndex, setPageIndex] = useState(false);
  const [tipoRelatorio, setTipoRelatorio] = useState('pago');
  const [totalComissao, setTotalComissao] = useState(0);

  const [totalComissoes, setTotalComissoes] = useState(0);
  const [pageCountComissoes, setPageCountComissoes] = useState(0);
  const [filtersComissoes, setFiltersComissoes] = useState(
    initialFiltersComissoes,
  );

  const [totalNfs, setTotalNfs] = useState(0);
  const [pageCountNfs, setPageCountNfs] = useState(0);
  const [filtersNfs, setFiltersNfs] = useState(initialFiltersNfs);

  const [arquivosNotas, setArquivosNotas] = useState([]);

  const fetchComissoes = useCallback(
    async ({ pageIndex, filter, sortBy }) => {
      setLoading(true);

      const page = pageIndex + 1;
      if (
        (!filter?.cpf || filter?.cpf?.replace(/\D/g, '') === '') &&
        !filter?.rangeDataComissao
      ) {
        ModalComponent(
          '',
          'É necessário informar a data inicial e final',
          'warning',
        );
        setLoading(false);
      } else {
        const response = await listarComissoes({
          pagina: page || 1,
          ordenacao: sortBy[0]?.id || null,
          ordenacaoAsc: sortBy[0]?.desc || null,
          quantidadePorPagina: filter.quantidadePorPagina,
          cpf: filter.cpf,
          matriz: filter.matriz,
          filtroComissaoPaga: tipoRelatorio,
          filtroProduto: filter.filtroProduto,
          codigoOperacao: filter.codigoOperacao,
          filtroDataPagamentoInicial:
            filter.rangeDataPagamento && filter.rangeDataPagamento[0]
              ? filter.rangeDataPagamento[0].format('YYYY-MM-DD')
              : null,
          filtroDataPagamentoFinal:
            filter.rangeDataPagamento && filter.rangeDataPagamento[1]
              ? filter.rangeDataPagamento[1].format('YYYY-MM-DD')
              : null,
          filtroDataComissaoInicial:
            filter.rangeDataComissao && filter.rangeDataComissao[0]
              ? filter.rangeDataComissao[0].format('YYYY-MM-DD')
              : null,
          filtroDataComissaoFinal:
            filter.rangeDataComissao && filter.rangeDataComissao[1]
              ? filter.rangeDataComissao[1].format('YYYY-MM-DD')
              : null,
        }).catch(() => setLoading(false));

        if (response?.data?.itens) {
          setComissoes(response.data.itens);
        } else {
          setComissoes([]);
        }

        setTotalComissoes(response?.data.total);
        setTotalComissao(response?.data.total_comissao);
        setPageCountComissoes(response?.data.totalPages);
        setLoading(false);
      }
    },
    [tipoRelatorio],
  );

  const fetchNfs = useCallback(
    async ({ pageIndex, filter, sortBy }) => {
      if (tipoRelatorio === 'nf') {
        setLoadingNfs(true);

        const page = pageIndex + 1;
        if (!filter?.rangeData) {
          ModalComponent(
            '',
            'É necessário informar a data inicial e final',
            'warning',
          );
          setLoading(false);
        } else {
          const response = await listarNfs({
            pagina: page || 1,
            ordenacao: sortBy[0]?.id || null,
            ordenacaoAsc: sortBy[0]?.desc || null,
            quantidadePorPagina: filter.quantidadePorPagina,
            filtroNomeArquivo: filter.filtroNomeArquivo,
            filtroNomeMatriz: filter.filtroNomeMatriz,
            filtroDataInicial:
              filter.rangeData && filter.rangeData[0]
                ? filter.rangeData[0].format('YYYY-MM-DD')
                : null,
            filtroDataFinal:
              filter.rangeData && filter.rangeData[1]
                ? filter.rangeData[1].format('YYYY-MM-DD')
                : null,
          }).catch(() => setLoadingNfs(false));

          if (response?.data?.itens) {
            setNotasFiscais(response.data.itens);
          } else {
            setNotasFiscais([]);
          }

          setTotalNfs(response?.data?.total || 0);
          setPageCountNfs(response?.data?.totalPages || 0);
          setLoadingNfs(false);
        }
      }
    },
    [tipoRelatorio],
  );

  const fetchContexto = useCallback(async () => {
    const { data } = await getContextoComissao();
    setContexto(data);
  }, []);

  useEffect(() => {
    fetchContexto();
  }, [fetchContexto]);

  const exportarCsv = async () => {
    setLoading(true);
    await imprimirCSVComissao({
      ...filtersComissoes,
      filtroDataComissaoInicial:
        filtersComissoes.rangeDataComissao &&
        filtersComissoes.rangeDataComissao[0]
          ? filtersComissoes.rangeDataComissao[0].format('YYYY-MM-DD')
          : null,
      filtroDataComissaoFinal:
        filtersComissoes.rangeDataComissao &&
        filtersComissoes.rangeDataComissao[1]
          ? filtersComissoes.rangeDataComissao[1].format('YYYY-MM-DD')
          : null,
      filtroDataPagamentoInicial:
        filtersComissoes.rangeDataPagamento &&
        filtersComissoes.rangeDataPagamento[0]
          ? filtersComissoes.rangeDataPagamento[0].format('YYYY-MM-DD')
          : null,
      filtroDataPagamentoFinal:
        filtersComissoes.rangeDataPagamento &&
        filtersComissoes.rangeDataPagamento[1]
          ? filtersComissoes.rangeDataPagamento[1].format('YYYY-MM-DD')
          : null,
      filtroComissaoPaga: tipoRelatorio,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio-comissao.csv');
      document.body.appendChild(link);
      link.click();
    });

    setLoading(false);
  };

  const exportarPdf = async () => {
    setLoading(true);
    const response = await imprimirPDFComissao({
      ...filtersComissoes,
      filtroDataComissaoInicial:
        filtersComissoes.rangeDataComissao &&
        filtersComissoes.rangeDataComissao[0]
          ? filtersComissoes.rangeDataComissao[0].format('YYYY-MM-DD')
          : null,
      filtroDataComissaoFinal:
        filtersComissoes.rangeDataComissao &&
        filtersComissoes.rangeDataComissao[1]
          ? filtersComissoes.rangeDataComissao[1].format('YYYY-MM-DD')
          : null,
      filtroDataPagamentoInicial:
        filtersComissoes.rangeDataPagamento &&
        filtersComissoes.rangeDataPagamento[0]
          ? filtersComissoes.rangeDataPagamento[0].format('YYYY-MM-DD')
          : null,
      filtroDataPagamentoFinal:
        filtersComissoes.rangeDataPagamento &&
        filtersComissoes.rangeDataPagamento[1]
          ? filtersComissoes.rangeDataPagamento[1].format('YYYY-MM-DD')
          : null,
      filtroComissaoPaga: tipoRelatorio,
    });
    setLoading(false);
    return response.data;
  };

  const uploadArquivosNotas = async () => {
    const notas = arquivosNotas.map((nota) => ({
      id: nota?.id,
      nomeArquivo: nota?.nome,
      mesAnoReferencia: nota?.dataReferencia || '',
    }));

    const { data } = await uploadNotas(notas);

    if (data) {
      setArquivosNotas([]);
    }
  };

  return (
    <RelatorioComissaoContext.Provider
      value={{
        comissoes,
        contexto,
        loading,
        loadingNfs,
        totalComissoes,
        pageCountComissoes,
        filtersComissoes,
        totalNfs,
        pageCountNfs,
        filtersNfs,
        showFilter,
        pageIndex,
        tipoRelatorio,
        notasFiscais,
        totalComissao,
        initialFiltersComissoes,
        initialFiltersNfs,
        arquivosNotas,
        fetchComissoes,
        fetchNfs,
        setShowFilter,
        setFiltersComissoes,
        setPageIndex,
        setTipoRelatorio,
        setNotasFiscais,
        exportarCsv,
        exportarPdf,
        setFiltersNfs,
        setArquivosNotas,
        uploadNotas,
        uploadArquivosNotas,
      }}
    >
      {children}
    </RelatorioComissaoContext.Provider>
  );
}

export default RelatorioComissaoProvider;
