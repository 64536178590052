import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';
import ImpressaoDocumentos from './ImpressaoDocumentos';

import ImpressaoDocumentosProvider from '../../contexts/ImpressaoDocumentosContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function ImpressaoProposta() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Impressão de Documentos</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <div className="row">
          <div className="col-lg-6">
            <ImpressaoDocumentosProvider>
              {checarPermissao(
                'tela',
                'tela.credito.proposta.impressaoContrato',
                'Visualizar',
              ) && (
                <>
                  <ImpressaoDocumentos />
                </>
              )}
            </ImpressaoDocumentosProvider>
          </div>
        </div>
      </Content>
    </Main>
  );
}

export default ImpressaoProposta;
