import React, { useContext } from 'react';
import moment from 'moment';

import { Form, Field, Select, Checkbox } from 'formik-antd';
import InputMask from 'react-text-mask';

import { cpfMask } from '../../../utils/masks';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import Tooltip from '../../../components/tooltip';
import DatePicker from '../../../components/DatePicker';
import TooltipAlterados from '../../../components/TooltipAlterados';
import SelectSearch from '../../../components/SearchSelect';
import Input from '../../../components/SimpleInput';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import './styles.scss';

const { Option } = Select;

function TooltipInfo({ text }) {
  return (
    <Tooltip
      placement="bottom"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

function ClienteForm({ values }) {
  const {
    contexto,
    editableField,
    estados,
    formik,
    nacionalidades,
    cidadesNaturalidade,
    validationField,
    nacionalidadeId,
    setNacionalidadeId,
    setNaturalidadeUfId,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
  } = useContext(AnaliseMesaContext);

  const { setFieldTouched, setFieldValue } = formik;

  const handleSelecionarNacionalidade = (value) => {
    setFieldValue('cliente.nacionalidadeId', value);
    setFieldValue('cliente.naturalidadeUfId', value === 1 ? null : 0);
    setFieldValue('cliente.naturalidadeCidadeId', value === 1 ? null : 0);
    setNacionalidadeId(value);
  };

  const handleSelecionarUfNaturalidade = (value) => {
    setFieldValue('cliente.naturalidadeUfId', value);
    setFieldValue('cliente.naturalidadeCidadeId', null);
    setNaturalidadeUfId(value);
    setFieldTouched('cliente.naturalidadeUfId');
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const disabledEmissao = (current) => {
    return (
      current &&
      (current > moment().endOf('day') ||
        current < moment(values.cliente.nascimento))
    );
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="cliente.cpf"
            label={
              <>
                <label>CPF</label>
                {campoChecar('cliente.cpf') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('cliente.cpf')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('cliente.cpf')?.campoId)
                    }
                  />
                )}
                {highlightField('cliente.cpf') && <TooltipAlterados />}
              </>
            }
            validate={(value) => validationField(value, 'cliente.cpf', 'CPF')}
            className={highlightField('cliente.cpf') && `campo-alterado`}
            hasFeedback
          >
            <Field name="cliente.cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  mask={cpfMask}
                  className="ant-input"
                  disabled={!editableField('cliente.cpf')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="cliente.nome"
            validate={(value) =>
              validationField(value, 'cliente.nome', 'Nome', 'nome')
            }
            label={
              <>
                <label>Nome do Cliente</label>
                {campoChecar('cliente.nome') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('cliente.nome')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('cliente.nome')?.campoId)
                    }
                  />
                )}
                {highlightField('cliente.nome') && <TooltipAlterados />}
              </>
            }
            className={highlightField('cliente.nome') && `campo-alterado`}
            hasFeedback
          >
            <Input
              name="cliente.nome"
              value={values?.cliente?.nome}
              onBlur={(e) => setFieldValue('cliente.nome', String(e.target.value).toUpperCase().trim())}
              disabled={!editableField('cliente.nome')}
              uppercase={true}
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.nascimento"
            label={
              <>
                <label>Data de Nascimento</label>
                {campoChecar('cliente.nascimento') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.nascimento')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.nascimento')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.nascimento') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'cliente.nascimento', 'Data de Nascimento')
            }
            className={highlightField('cliente.nascimento') && `campo-alterado`}
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.nascimento"
              placeholder="00/00/0000"
              disabledDate={disabledDate}
              onBlur={() => setFieldTouched('cliente.nascimento')}
              disabled={!editableField('cliente.nascimento')}
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="cliente.rg"
            label={
              <>
                <label>Número do RG/CNH</label>
                {campoChecar('cliente.rg') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('cliente.rg')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('cliente.rg')?.campoId)
                    }
                  />
                )}
                {highlightField('cliente.rg') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'cliente.rg', 'número do RG')
            }
            className={highlightField('cliente.rg') && `campo-alterado`}
            hasFeedback
          >
            <Input
              name="cliente.rg"
              disabled={!editableField('cliente.rg')}
              value={values?.cliente?.rg}
              onBlur={(e) => setFieldValue('cliente.rg', e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.rgEmissor"
            label={
              <>
                <label>Orgão Emissor</label>
                {campoChecar('cliente.rgEmissor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.rgEmissor')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.rgEmissor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.rgEmissor') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'cliente.rgEmissor', 'emissor do RG')
            }
            className={highlightField('cliente.rgEmissor') && `campo-alterado`}
            hasFeedback
          >
            <Input
              name="cliente.rgEmissor"
              disabled={!editableField('cliente.rgEmissor')}
              value={values?.cliente?.rgEmissor}
              onBlur={(e) => setFieldValue('cliente.rgEmissor', e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.rgUfId"
            label={
              <>
                <label>UF de emissão</label>
                {campoChecar('cliente.rgUfId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.rgUfId')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.rgUfId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.rgUfId') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'cliente.rgUfId', 'UF do RG')
            }
            className={highlightField('cliente.rgUfId') && `campo-alterado`}
            hasFeedback
          >
            <SelectSearch
              name="cliente.rgUfId"
              disabled={!editableField('cliente.rgUfId')}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="UF de Emissão"
              showSearch
              onChange={(value) => setFieldValue('cliente.rgUfId', value)}
              value={values?.cliente?.rgUfId}
              suffixIcon={<IconArrowSelect />}
              optionFilterProp="children"
              fillDefault={editableField('cliente.rgUfId')}
            >
              {estados.map((uf) => (
                <Option key={uf.id} value={uf.id}>
                  {uf.uf}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.rgEmissao"
            label={
              <>
                <label>Data de Emissão</label>

                {campoChecar('cliente.rgEmissao') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.rgEmissao')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.rgEmissao')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.rgEmissao') && <TooltipAlterados />}
              </>
            }
            className={highlightField('cliente.rgEmissao') && `campo-alterado`}
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.rgEmissao"
              disabled={!editableField('cliente.rgEmissao')}
              placeholder="00/00/0000"
              disabledDate={disabledEmissao}
              onBlur={() => setFieldTouched('cliente.rgEmissao')}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="cliente.sexo"
            label={
              <>
                <label>Sexo</label>
                {campoChecar('cliente.sexo') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('cliente.sexo')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('cliente.sexo')?.campoId)
                    }
                  />
                )}
                {highlightField('cliente.sexo') && <TooltipAlterados />}
              </>
            }
            className={highlightField('cliente.sexo') && `campo-alterado`}
          >
            <SelectSearch
              name="cliente.sexo"
              onChange={(value) => setFieldValue('cliente.sexo', value)}
              disabled={!editableField('cliente.sexo')}
              value={values?.cliente?.sexo}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              fillDefault={editableField('cliente.sexo')}
            >
              {contexto?.sexo &&
                contexto?.sexo.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.estadoCivil"
            validate={(value) =>
              validationField(value, 'cliente.estadoCivil', 'Estado Civil')
            }
            label={
              <>
                <label>Estado Civil</label>
                {campoChecar('cliente.estadoCivil') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.estadoCivil')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.estadoCivil')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.estadoCivil') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('cliente.estadoCivil') && `campo-alterado`
            }
            hasFeedback
          >
            <SelectSearch
              name="cliente.estadoCivil"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('cliente.estadoCivil')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              showSearch
              onChange={(value) => setFieldValue('cliente.estadoCivil', value)}
              value={values?.cliente?.estadoCivil}
              fillDefault={editableField('cliente.estadoCivil')}
            >
              {contexto?.estadoCivil &&
                contexto.estadoCivil.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="cliente.nacionalidadeId"
            label={
              <>
                <label>Nacionalidade</label>
                {campoChecar('cliente.nacionalidadeId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('cliente.nacionalidadeId')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.nacionalidadeId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.nacionalidadeId') && (
                  <TooltipAlterados />
                )}
              </>
            }
            validate={(value) =>
              validationField(value, 'cliente.nacionalidadeId', 'Nacionalidade')
            }
            hasFeedback
            className={
              highlightField('cliente.nacionalidadeId') && `campo-alterado`
            }
          >
            <SelectSearch
              name="cliente.nacionalidadeId"
              onChange={(value) => handleSelecionarNacionalidade(value)}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('cliente.nacionalidadeId')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              showSearch
              value={values?.cliente?.nacionalidadeId}
              fillDefault={editableField('cliente.nacionalidadeId')}
            >
              {nacionalidades?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        {nacionalidadeId === 1 && (
          <div className="col-lg-3">
            <Form.Item
              name="cliente.naturalidadeUfId"
              label={
                <>
                  <label>UF Naturalidade</label>
                  {campoChecar('cliente.naturalidadeUfId') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('cliente.naturalidadeUfId')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('cliente.naturalidadeUfId')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('cliente.naturalidadeUfId') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('cliente.naturalidadeUfId') && `campo-alterado`
              }
              validate={(value) =>
                validationField(
                  value,
                  'cliente.naturalidadeUfId',
                  'UF Naturalidade',
                )
              }
              hasFeedback
            >
              <SelectSearch
                name="cliente.naturalidadeUfId"
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => handleSelecionarUfNaturalidade(value)}
                disabled={!editableField('cliente.naturalidadeUfId')}
                optionFilterProp="children"
                suffixIcon={<IconArrowSelect />}
                showSearch
                value={values?.cliente?.naturalidadeUfId}
                fillDefault={editableField('cliente.naturalidadeUfId')}
              >
                {estados &&
                  estados.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.uf}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
        )}
      </div>
      <div className="form-row">
        {nacionalidadeId === 1 && (
          <div className="col-lg-3">
            <Form.Item
              name="cliente.naturalidadeCidadeId"
              label={
                <>
                  <label>Cidade Naturalidade</label>
                  {campoChecar('cliente.naturalidadeCidadeId') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('cliente.naturalidadeCidadeId')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('cliente.naturalidadeCidadeId')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('cliente.naturalidadeCidadeId') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(
                  value,
                  'cliente.naturalidadeCidadeId',
                  'Cidade Naturalidade',
                )
              }
              className={
                highlightField('cliente.naturalidadeCidadeId') &&
                `campo-alterado`
              }
              hasFeedback
            >
              <SelectSearch
                name="cliente.naturalidadeCidadeId"
                disabled={!editableField('cliente.naturalidadeCidadeId')}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                suffixIcon={<IconArrowSelect />}
                showSearch
                onChange={(value) =>
                  setFieldValue('cliente.naturalidadeCidadeId', value)
                }
                value={values?.cliente?.naturalidadeCidadeId}
                fillDefault={editableField('cliente.naturalidadeCidadeId')}
              >
                {cidadesNaturalidade.length > 0 &&
                  cidadesNaturalidade.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
        )}
        <div className="col-lg-3">
          <Form.Item
            name="cliente.grauInstrucaoId"
            label={
              <>
                <label>Grau de Instrução</label>
                {campoChecar('cliente.grauInstrucaoId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('cliente.grauInstrucaoId')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.grauInstrucaoId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.grauInstrucaoId') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('cliente.grauInstrucaoId') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'cliente.grauInstrucaoId',
                'Grau de Instrução',
              )
            }
            hasFeedback
          >
            <SelectSearch
              name="cliente.grauInstrucaoId"
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('cliente.grauInstrucaoId')}
              suffixIcon={<IconArrowSelect />}
              onChange={(value) =>
                setFieldValue('cliente.grauInstrucaoId', value)
              }
              value={values?.cliente?.grauInstrucaoId}
              fillDefault={editableField('cliente.grauInstrucaoId')}
            >
              {contexto.grauInstrucao &&
                contexto.grauInstrucao.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="cliente.nomeMae"
            label={
              <>
                <label>Nome da Mãe</label>
                {campoChecar('cliente.nomeMae') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('cliente.nomeMae')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('cliente.nomeMae')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('cliente.nomeMae') && <TooltipAlterados />}
              </>
            }
            className={highlightField('cliente.nomeMae') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'cliente.nomeMae', 'Nome da Mãe')
            }
            hasFeedback
          >
            <Input
              name="cliente.nomeMae"
              disabled={!editableField('cliente.nomeMae')}
              value={values?.cliente?.nomeMae}
              onBlur={(e) => setFieldValue('cliente.nomeMae', e.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        {contexto.estadoCivil &&
          contexto.estadoCivil.length > 0 &&
          values?.cliente?.estadoCivil !== null &&
          contexto.estadoCivil[values?.cliente?.estadoCivil]?.exigeConjuge && (
            <div className="col-lg-6">
              <Form.Item
                name="cliente.nomeConjuge"
                label={
                  <>
                    <label>Nome do Cônjuge</label>
                    {campoChecar('cliente.nomeConjuge') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('cliente.nomeConjuge')?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('cliente.nomeConjuge')?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('cliente.nomeConjuge') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
                className={
                  highlightField('cliente.nomeConjuge') && `campo-alterado`
                }
                validate={(value) => {
                  let msgError;
                  if (
                    !value &&
                    contexto.estadoCivil[values?.cliente?.estadoCivil]
                      ?.exigeConjuge
                  ) {
                    msgError = 'Obrigatório informar o Nome do Cônjuge';
                  }
                  return msgError;
                }}
                hasFeedback
              >
                <Input
                  name="cliente.nomeConjuge"
                  disabled={!editableField('cliente.nomeConjuge')}
                  placeholder="Nome completo do cônjuge"
                  value={values?.cliente?.nomeConjuge}
                  onBlur={(e) =>
                    setFieldValue('cliente.nomeConjuge', e.target.value)
                  }
                />
              </Form.Item>
            </div>
          )}
        <div className="col-lg-6">
          <Form.Item
            name="cliente.pep"
            validate={(value) => validationField(value, 'cliente.pep', 'PEP')}
            hasFeedback
            className={highlightField('cliente.pep') && `campo-alterado`}
            label={
              <>
                <label>PEP</label>
                <TooltipInfo text="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares considerando assim parentes, na linha direta, até o primeiro grau, assim como o cônjuge, companheiro, enteado, bem como, outras pessoas de seu relacionamento próximo." />
                {campoChecar('cliente.pep') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('cliente.pep')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('cliente.pep')?.campoId)
                    }
                  />
                )}
                {highlightField('cliente.pep') && <TooltipAlterados />}
              </>
            }
          >
            <SelectSearch
              getPopupContainer={(trigger) => trigger.parentNode}
              name="cliente.pep"
              disabled={!editableField('cliente.pep')}
              suffixIcon={<IconArrowSelect />}
              onChange={(value) => setFieldValue('cliente.pep', value)}
              value={values?.cliente?.pep}
              fillDefault={editableField('cliente.pep')}
            >
              <Option value={true}>Sim</Option>
              <Option value={false}>Não</Option>
            </SelectSearch>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default ClienteForm;
