function validaCep(cep) {
  if (
    cep === '00000000' ||
    cep === '11111111' ||
    cep === '22222222' ||
    cep === '33333333' ||
    cep === '44444444' ||
    cep === '55555555' ||
    cep === '66666666' ||
    cep === '77777777' ||
    cep === '88888888' ||
    cep === '99999999'
  ) {
    return false;
  }

  let validacep = /\d{8}/;

  if (validacep.test(cep)) {
    return true;
  } else {
    return false;
  }
}

export default validaCep;
