import api from './api';

export const listarPropostasEsteira = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  ordenacao = { id: '', desc: false },
  cpf = null,
  nomeAgente = null,
  tipoData = null,
  dataInicial = null,
  dataFinal = null,
  etapaId = null,
  produtoId = null,
  matrizId = null,
  supervisorId = null,
  tipoCliente = null,
  propostaId = null,
  origemId = null,
  analistas = [],
}) => {
  const { data } = await api.post('/proposta/esteira-credito', {
    pagina,
    quantidadePorPagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    cpf,
    nomeAgente,
    tipoData,
    dataInicial,
    dataFinal,
    etapaId,
    produtoId,
    matrizId,
    supervisorId,
    tipoCliente,
    propostaId,
    origemId,
    analistas,
  });
  return data;
};

export const exportarPropostasEsteiraCSV = async ({
  cpf = null,
  nomeAgente = null,
  tipoData = null,
  dataInicial = null,
  dataFinal = null,
  etapaId = null,
  produtoId = null,
  matrizId = null,
  supervisorId = null,
  clienteTipo = null,
}) => {
  const { data } = await api.post(
    '/proposta/esteira-credito/exportacao-csv',
    {
      cpf,
      nomeAgente,
      tipoData,
      dataInicial,
      dataFinal,
      etapaId,
      produtoId,
      matrizId,
      supervisorId,
      clienteTipo,
    },
    { responseType: 'arraybuffer' },
  );
  return data;
};

export const exportarPropostasEsteiraPDF = async ({
  cpf = null,
  nomeAgente = null,
  tipoData = null,
  dataInicial = null,
  dataFinal = null,
  etapaId = null,
  produtoId = null,
  matrizId = null,
  supervisorId = null,
  clienteTipo = null,
}) => {
  const data = await api.post('/Proposta/acompanhamento-proposta-pdf', {
    cpf,
    nomeAgente,
    tipoData,
    dataInicial,
    dataFinal,
    etapaId,
    produtoId,
    matrizId,
    supervisorId,
    clienteTipo,
  });
  return data;
};

export const listarPropostasEsteiraContexto = async () => {
  let esteiraCreditoContexto = sessionStorage.getItem("esteira-credito-contexto");

  if (!esteiraCreditoContexto) {
    try{      
      const { data } = await api.get('/proposta/esteira-credito-contexto');
      sessionStorage.setItem('esteira-credito-contexto', JSON.stringify(data));
      return data;
  } catch (error) {
    return error;
  }
}
esteiraCreditoContexto  = JSON.parse(esteiraCreditoContexto)
return esteiraCreditoContexto;

};

export const carregaNovoFluxo = async (id) => {
  const { data } = await api.get(`/proposta/novo-fluxo/${id}`);
  return data;
};

export const enviaNovoFluxo = async (id, cpf, anotacao) => {
  try {
    const { data } = await api.put(`proposta/novo-fluxo/${id}`, {
      cpf,
      anotacao,
    });
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const imprimirPdfEsteiraCredito = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  ordenacao = { id: '', desc: false },
  cpf = null,
  nomeAgente = null,
  tipoData = null,
  dataInicial = null,
  dataFinal = null,
  etapaId = null,
  produtoId = null,
  matrizId = null,
  supervisorId = null,
  tipoCliente = null,
  propostaId = null,
  origemId = null,
  analistas = [],
}) => {
  const { data } = await api.post('/proposta/esteira-credito/exportacao-pdf', {
    pagina,
    quantidadePorPagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    cpf,
    nomeAgente,
    tipoData,
    dataInicial,
    dataFinal,
    etapaId,
    produtoId,
    matrizId,
    supervisorId,
    tipoCliente,
    propostaId,
    origemId,
    analistas,
  });
  return data;
};
