import './styles.scss';
import React, { useContext } from 'react';
import PageHeading from '../../components/PageHeading';
import { Breadcrumb } from 'antd';
import Content from '../../components/Content';
import Main from '../../layouts/Main';
import useInspinia from '../../hooks/useInspinia';
import IntegracoesPage from './IntegracoesPage';
import IntegracoesProvider from '../../contexts/IntegracoesContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

export default function Integracoes() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Integrações</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <IntegracoesProvider>
          {checarPermissao(
            'tela',
            'tela.integracoes.geracaoDeToken',
            'Visualizar',
          ) && (
            <>
              <div className="card-integracoes">
                <IntegracoesPage />
              </div>
            </>
          )}
        </IntegracoesProvider>
      </Content>
    </Main>
  );
}
