function validaPhone(value, type) {
  if (value) {
    value = value.replace(/\D/g, '');

    const ddds = JSON.parse(sessionStorage.getItem('ddds')) || [];
    const ddd = value.substr(0, 2);
    const phone = value.substr(2);

    const validaDDD = () => ddds?.find((item) => item.ddd === ddd);

    const validacaoFixo =
      phone.substr(0, 1) !== '9' &&
      !phone.match(/^(\d)\1{7,8}$/) &&
      phone.length === 8;

    const validacaoCelular =
      phone.substr(0, 1) === '9' &&
      !phone.match(/^(\d)\1{7,8}$/) &&
      phone.length === 9;

    const validacaoDinamica = () => {
      if (!!validacaoFixo) {
        return 2;
      } else if (!!validacaoCelular) {
        return 1;
      } else {
        return 0;
      }
    };

    if (validaDDD()) {
      if (!type) {
        return validacaoDinamica();
      } else {
        if (type === 'fixo' && !!validacaoFixo) {
          return 2;
        } else if (type === 'celular' && !!validacaoCelular) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  }
}

export default validaPhone;
