import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Breadcrumb, Button } from 'antd';

import useInspinia from '../../hooks/useInspinia';

import Layout from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import { ReactComponent as IconBallonComments } from '../../assets/images/icons/icon-ballon-comments.svg';

import PropostaCreditoProvider from '../../contexts/PropostaCreditoContext';

import PropostaCreditoForm from './PropostaCreditoForm';
import CentralDeComunicacao from './CentralDeComunicacao';

import './styles.scss';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import CalculadoraProvider from '../../contexts/CalculadoraContext';

function PropostaCredito() {
  const params = useParams();
  const idProposta = params?.propostaId;
  const { checarPermissao } = useContext(ControleAcessoContext);
  useInspinia();
  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta de Crédito</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

    <CalculadoraProvider>    
      <PropostaCreditoProvider>
        {checarPermissao('tela', 'tela.propostacredito', 'Visualizar') && (
          <>
            <div
              className="content-proposta-credito"
              style={{ height: 'calc(100vh - 107px)' }}
            >
              <Content>
                <div className="row proposta-credito">
                  <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 col-form">
                    <PropostaCreditoForm propostaId={idProposta} />
                  </div>
                  {checarPermissao(
                    'botao',
                    'botao.propostaCredito.centralComunicacao',
                    'Visualizar',
                  ) && (
                    <>
                      <div className="col-lg-3 col-md-12 col-sm-12 hidden-md-down col-central-comunicacao">
                        <CentralDeComunicacao
                          mode="screen"
                          propostaId={idProposta}
                          disabled={
                            !checarPermissao(
                              'botao',
                              'botao.propostaCredito.centralComunicacao',
                              'Criar',
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </Content>
            </div>
            {checarPermissao(
              'botao',
              'botao.propostaCredito.centralComunicacao',
              'Visualizar',
            ) && (
              <>
                <CentralDeComunicacao
                  mode="tooltip"
                  propostaId={idProposta}
                  placementTooltip="topRight"
                  fixedTooltip={true}
                  disabled={
                    !checarPermissao(
                      'botao',
                      'botao.propostaCredito.centralComunicacao',
                      'Criar',
                    )
                  }
                >
                  <Button className="ballon-tooltip-comments">
                    <IconBallonComments />
                  </Button>
                </CentralDeComunicacao>
              </>
            )}
          </>
        )}
      </PropostaCreditoProvider>
      </CalculadoraProvider> 
    </Layout>
  );
}

export default PropostaCredito;
