import api from './api';

export const isAuthenticated = () => localStorage.getItem('authToken') !== null;

export const getToken = () => localStorage.getItem('authToken');

export const signIn = async (login) => {
  const { data } = await api.post('/Usuario/login', login);
  return data;
};

export const validaAcesso = async (id) => {
  const { data } = await api.put(`/usuario/valida-acesso/${id}`);
  return data;
};

export const signOut = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('login');
  localStorage.removeItem('userId');
  localStorage.removeItem('nome');
  localStorage.removeItem('telefonia');
  localStorage.removeItem('paginaAberta');
  localStorage.removeItem('telaAnterior');
  sessionStorage.clear();
};
