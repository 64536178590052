import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';
import Tooltip from '../../../components/tooltip';
import Etapa from '../../../components/Etapa';

export const columnsPropostas = [
  {
    Header: 'Proposta',
    accessor: 'Id',
  },
  {
    Header: 'Produto',
    accessor: 'nome',
  },
  {
    Header: 'Cadastro',
    accessor: 'Cadastro',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Última alteração',
    accessor: 'UltimaAlteracao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'Valor',

    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Plano',
    accessor: 'Plano',
  },
  {
    Header: 'Prestação',
    accessor: 'Prestacao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Status',
    accessor: 'situacao',
    style: {
      minWidth: 170,
      width: 190,
      textAlign: 'center',
    },
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: 'center', 'text-decoration': 'underline' }}>
          <Tooltip
            placement="bottomRight"
            arrowPointAtCenter
            color="#435361"
            title={
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  lineHeight: '15px',
                }}
              >
                Simplesmente um texto fictício da indústria de impressão e
                composição. Lorem Ipsum tem sido o texto fictício padrão da
                indústria desde os anos 1500.
              </div>
            }
          >
            <div style={{ textAlign: 'center' }}>{<Etapa value={value} />}</div>
          </Tooltip>
        </div>
      );
    },
  },
];
