import React from 'react';
import { Breadcrumb } from 'antd';
import Layout from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';
import DadosCredenciadoPage from './DadosCredenciadoPage';
import './styles.scss';
import CredenciadoProvider from '../../contexts/CadastroCredenciamentoContext';
import useInspinia from '../../hooks/useInspinia';
import { useParams } from 'react-router-dom';

function DadosCredenciado() {
  useInspinia();
  const params = useParams();
  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Credenciamento</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <CredenciadoProvider>
          <DadosCredenciadoPage
            userId={params.userId ? Number.parseInt(params.userId) : null}
            action={params.action}
          />
        </CredenciadoProvider>
      </Content>
    </Layout>
  );
}
export default DadosCredenciado;
