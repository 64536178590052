import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import moment from 'moment';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { Steps, Select } from 'antd';
import { useFormik, Field, FormikProvider } from 'formik';
import { Form, Input, InputNumber, Switch, TimePicker } from 'formik-antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import Modal from '../../components/Modal';
import InputPhone from '../../components/InputPhone';

import { phoneFormatter, cellPhoneFormatter } from '../../utils/textFormatter';

import { CorrespondenteContext } from '../../contexts/CorrespondenteContext';

import InputMask from 'react-text-mask';

import { initialValues } from './initialValues';
import { cnpjMask, cepMask } from '../../utils/masks';

import { ReactComponent as IconAddress } from '../../assets/images/icons/step-address.svg';
import { ReactComponent as IconCalendar } from '../../assets/images/icons/step-calendar.svg';
import { ReactComponent as IconPhone } from '../../assets/images/icons/step-phone.svg';
import { ReactComponent as IconUser } from '../../assets/images/icons/step-user.svg';
import { ReactComponent as IconCadastrar } from '../../assets/images/icons/cadastrar-correspondente.svg';

import FormUploads from './CorrespondenteFormUploads';
import validationsSchemas from './validationsSchemas';

import './styles.scss';

const { Option } = Select;

const { TextArea } = Input;
const { Step } = Steps;

const formatHora = ['HH:mm', 'HHmm'];
const pageReload = () => {
  window.location.reload();
};

function CorrespondenteForm() {
  const {
    fetchCidades,
    fetchUfs,
    fetchEndereco,
    fetchStatusCNPJ,
    cadastrarCorrespondente,
    tipo,
    carregarImagem,
    fileIds,
    setFileIds,
    parametroPrazo,
    fetchCredenciamento,
    putCredenciamento,
  } = useContext(CorrespondenteContext);

  const [disabled, setDisabled] = useState(false);
  const [statusCNPJ, setStatusCNPJ] = useState('');
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusCelular, setStatusCelular] = useState('');
  const [statusEmail, setStatusEmail] = useState('');
  const [statusCEP, setStatusCEP] = useState('');
  const [cepInvalido, setCepInvalido] = useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoId, setEstadoId] = useState(null);
  const [fileList, setFileList] = useState([]);

  const location = useLocation();
  const idSolicitacao = location.state;

  const disableDate = (current) => {
    return (
      current &&
      (current < moment().subtract(parametroPrazo, 'days') ||
        current < moment().endOf('day'))
    );
  };

  const handleConsultaCNPJ = async (cnpj) => {
    if (cnpj) {
      const data = await fetchStatusCNPJ(cnpj.replace(/\D/g, ''));

      if (data && !data.status) {
        Modal(
          'Atenção',
          data.message,
          'warning',
          data.canResume ? () => {} : pageReload,
        );
      }
    }
  };

  const handleConsultaCEP = async (cep, setFieldTouched, setFieldValue) => {
    setCepInvalido(false);
    setFieldTouched('cep');

    if (cep) {
      cep = cep.replace(/\D/g, '');
      setCepInvalido(false);

      const data = await fetchEndereco(cep);

      if (data.data) {
        if (data.data.ufId > 0) {
          setFieldValue('ufId', data.data.ufId);
          setEstadoId(data.data.ufId);
          setFieldValue('cidadeId', data.data.cidadeId);
          setFieldValue('logradouro', data.data.logradouro);
          setFieldValue('bairro', data.data.bairro);
        }
      } else {
        setFieldValue('ufId', '');
        setEstadoId(null);
        setFieldValue('cidadeId', '');
        setFieldValue('logradouro', '');
        setFieldValue('bairro', '');
        setCepInvalido(true);
      }
    }
  };

  const handleSelecionarEstado = (estado, setFieldValue) => {
    setFieldValue('ufId', estado);
    setFieldValue('cidadeId', '');

    setEstadoId(estado);
  };

  const handleNumero = (e, setFieldValue) => {
    if (e.target.value === '' || e.target.value === '0') {
      setFieldValue('numero', 'S/N');
    }
  };

  useEffect(() => {
    if (estados.length === 0) {
      async function loadEstados() {
        const data = await fetchUfs();
        if (data) {
          setEstados(data);
        }
      }

      loadEstados();
    }
  }, [estados, fetchUfs]);

  useEffect(() => {
    if (estadoId) {
      async function loadCidades(ufId) {
        const data = await fetchCidades(ufId);
        setCidades(data);
      }

      loadCidades(estadoId);
    }
  }, [estadoId, fetchCidades]);

  return (
    <FormStepper
      setStatusCNPJ={setStatusCNPJ}
      setStatusTelefone={setStatusTelefone}
      setStatusCelular={setStatusCelular}
      setStatusEmail={setStatusEmail}
      setStatusCEP={setStatusCEP}
      tipo={tipo}
      cadastrarCorrespondente={cadastrarCorrespondente}
      fileIds={fileIds}
      setFileIds={setFileIds}
      idSolicitacao={idSolicitacao}
      fetchCredenciamento={fetchCredenciamento}
      putCredenciamento={putCredenciamento}
      fileList={fileList}
      setFileList={setFileList}
    >
      <FormStep
        label={tipo === 1 ? 'Correspondente' : 'Conveniada'}
        icon={
          <div className="step-icon-container">
            <IconUser className="step-icon" />
          </div>
        }
      >
        <div className="form-row">
          <div className="col-lg-4">
            <Form.Item
              name="cnpj"
              label="CNPJ"
              placeholder="2233"
              hasFeedback
              validateStatus={statusCNPJ}
              onBlur={(e) => handleConsultaCNPJ(e.target.value)}
            >
              <Field name="cnpj">
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask={cnpjMask}
                    className="ant-input"
                    placeholder="00.000.000/0000-00"
                    disabled={idSolicitacao}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-8">
            <Form.Item name="nomeFantasia" label="Nome Fantasia" hasFeedback>
              <Input
                name="nomeFantasia"
                placeholder="Ex: Carlos Henrique"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>
        </div>
        {tipo === 2 && (
          <div className="form-row">
            <div className="col-lg-4">
              <Form.Item
                name="funcionarios"
                label="Quantidade de Funcionários"
                hasFeedback
              >
                <InputNumber
                  name="funcionarios"
                  style={{ width: '100%' }}
                  placeholder="Ex: 100"
                  min={0}
                />
              </Form.Item>
            </div>
          </div>
        )}
      </FormStep>

      <FormStep
        label="Endereço"
        icon={
          <div className="step-icon-container">
            <IconAddress className="step-icon" />
          </div>
        }
      >
        <div className="form-row">
          <div className="col-lg-2">
            <Form.Item
              name="cep"
              label="CEP"
              hasFeedback
              validateStatus={statusCEP}
            >
              <Field name="cep">
                {({ field, form }) => (
                  <InputMask
                    {...field}
                    mask={cepMask}
                    onBlur={(e) =>
                      handleConsultaCEP(
                        e.target.value,
                        form.setFieldTouched,
                        form.setFieldValue,
                      )
                    }
                    disabled={idSolicitacao}
                    placeholder="Ex.: 00.000-000"
                    className="ant-input"
                    autoComplete="cep"
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-8">
            <Form.Item name="logradouro" label="Logradouro" hasFeedback>
              <Input
                name="logradouro"
                placeholder="Ex: Avenida, rua, etc."
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>

          <div className="col-lg-2">
            <Form.Item name="numero" label="Número" hasFeedback>
              <Field name="numero">
                {({ form }) => (
                  <Input
                    name="numero"
                    placeholder="Ex: 1800"
                    onBlur={(numero) =>
                      handleNumero(numero, form.setFieldValue)
                    }
                    disabled={idSolicitacao}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-2">
            <Form.Item name="ufId" label="Estado" hasFeedback>
              <Field name="ufId">
                {({ field, form }) => (
                  <Select
                    {...field}
                    onChange={(estado) =>
                      handleSelecionarEstado(estado, form.setFieldValue)
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    optionFilterProp="children"
                    autoComplete="ufId"
                    disabled={form.values.cep && !cepInvalido}
                  >
                    {estados.length > 0 &&
                      estados.map((estado) => (
                        <Option key={estado.id} value={estado.id}>
                          {estado.nome}
                        </Option>
                      ))}
                  </Select>
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-5">
            <Form.Item name="cidadeId" label="Cidade" hasFeedback>
              <Field name="cidadeId">
                {({ field, form }) => (
                  <Select
                    {...field}
                    name="cidadeId"
                    onChange={(value) => form.setFieldValue('cidadeId', value)}
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionFilterProp="children"
                    autoComplete="cidadeId"
                    disabled={form.values.cep && !cepInvalido}
                  >
                    {cidades.length > 0 &&
                      cidades.map((cidade) => (
                        <Option key={cidade.id} value={cidade.id}>
                          {cidade.nome}
                        </Option>
                      ))}
                  </Select>
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-5">
            <Form.Item name="bairro" label="Bairro" hasFeedback>
              <Input
                name="bairro"
                placeholder="Ex: Campo Grande"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-7">
            <Form.Item name="complemento" label="Complemento" hasFeedback>
              <Input
                name="complemento"
                placeholder="Ex: Apartamento - 502"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>
        </div>
      </FormStep>

      <FormStep
        label="Contato"
        icon={
          <div className="step-icon-container">
            <IconPhone className="step-icon" />
          </div>
        }
      >
        <div className="form-row">
          <div className="col-lg-6">
            <Form.Item name="contato" label="Nome do Responsável">
              <Input
                name="contato"
                placeholder="Ex: Felipe Santos Oliveira"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>

          <div className="col-lg-3 col-md-3">
            <Form.Item
              name="telefone"
              label="Telefone"
              hasFeedback
              validateStatus={statusTelefone}
            >
              <Field name="telefone">
                {({ field }) => (
                  <InputPhone
                    placeholder="Ex: (00) 0000-0000"
                    {...field}
                    type="fixo"
                    className="ant-input"
                    disabled={idSolicitacao}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-3 col-md-3">
            <Form.Item
              name="celular"
              label="Celular"
              hasFeedback
              validateStatus={statusCelular}
            >
              <Field name="celular">
                {({ field }) => (
                  <InputPhone
                    placeholder="Ex: (00) 90000-0000"
                    {...field}
                    type="celular"
                    className="ant-input"
                    disabled={idSolicitacao}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-12 col-md-12">
            <Form.Item
              name="email"
              label="E-mail"
              hasFeedback
              validateStatus={statusEmail}
            >
              <Input
                type="email"
                name="email"
                placeholder="Ex: usuario@email.com"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-12">
            <Form.Item name="observacao" label="Observação">
              <TextArea
                name="observacao"
                rows={5}
                placeholder="Escreva os detalhes"
                disabled={idSolicitacao}
              />
            </Form.Item>
          </div>
        </div>
      </FormStep>

      {tipo === 1 && (
        <FormStep
          label="Visita"
          icon={
            <div className="step-icon-container">
              <IconCalendar className="step-icon" />
            </div>
          }
        >
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="visita" hasFeedback>
                <span className="mr-2">Visita</span>
                <Field name="visita">
                  {({ field }) => (
                    <Switch
                      {...field}
                      id="visita"
                      size="small"
                      onChange={(e) => {
                        setDisabled(!disabled);
                      }}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-4 col-md-6">
              <Form.Item name="dataVisita" label="Data da visita" hasFeedback>
                <DatePicker
                  format="DD/MM/YYYY"
                  name="dataVisita"
                  locale={locale}
                  disabled={disabled}
                  disabledDate={disableDate}
                />
              </Form.Item>
            </div>

            <div className="col-lg-3 col-md-6">
              <Form.Item name="horaVisita" label="Hora da visita" hasFeedback>
                <TimePicker
                  name="horaVisita"
                  format={formatHora}
                  locale={locale}
                  disabled={disabled}
                />
              </Form.Item>
            </div>

            <div className="col-lg-5 col-md-12">
              <Form.Item
                name="contatoVisita"
                label="Contato na visita"
                hasFeedback
              >
                <Input
                  name="contatoVisita"
                  disabled={disabled}
                  placeholder="Ex: Nome do contato"
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="anexos" label="Anexos">
                <FormUploads
                  disabled={disabled}
                  carregarImagem={carregarImagem}
                  fileIds={fileIds}
                  setFileIds={setFileIds}
                  fileList={fileList}
                  setFileList={setFileList}
                />
              </Form.Item>
            </div>
          </div>
        </FormStep>
      )}
    </FormStepper>
  );
}

export default CorrespondenteForm;

function FormStepper({ children, ...props }) {
  const {
    idSolicitacao,
    fileIds,
    fetchCredenciamento,
    putCredenciamento,
    cadastrarCorrespondente,
    tipo,
    setFileList,
    setFileIds,
    setStatusCNPJ,
    setStatusTelefone,
    setStatusCelular,
    setStatusEmail,
    setStatusCEP,
  } = props;
  const [step, setStep] = useState(0);
  const childrenArray = React.Children.toArray(children);
  const currentChild = childrenArray[step];

  const [validationState, setValidationState] = useState(0);

  const history = useHistory();

  const navigateToAcompanhamentoCredenciamento = () => {
    history.push('/acompanhamento-credenciamento');
  };

  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  let buttonLabel = isLastStep()
    ? idSolicitacao
      ? 'ATUALIZAR'
      : 'CADASTRAR'
    : 'PRÓXIMO';
  let buttonVariant = isLastStep() ? 'green' : 'orange';

  const formik = useFormik({
    initialValues,
    validationSchema: validationsSchemas[validationState],
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (data) => {
      if (data.cnpj) {
        data.cnpj = data.cnpj.replace(/\D/g, '');
      }

      if (data.cep) {
        data.cep = data.cep.replace(/\D/g, '');
      }

      if (isLastStep()) {
        data.anexos = fileIds;

        if (data.anexos.length === 0 && data.visita) {
          return Modal(
            '',
            'É obrigatório adicionar no mínimo 1 anexo.',
            'warning',
          );
        }

        if (data.telefone) {
          data.telefone = data.telefone.replace(/\D/g, '');
        }

        if (data.celular) {
          data.celular = data.celular.replace(/\D/g, '');
        }

        if (idSolicitacao) {
          const response = await putCredenciamento(idSolicitacao, data);

          if (response.success) {
            Modal(
              '',
              'Solicitação atualizada com sucesso!',
              'success',
              navigateToAcompanhamentoCredenciamento,
            );
          } else {
            Modal('', response.errors.join(' \n \n '), 'warning');
          }
        } else {
          const response = await cadastrarCorrespondente(data);

          if (response.success) {
            Modal(
              '',
              'Solicitação cadastrada com sucesso!',
              'success',
              navigateToAcompanhamentoCredenciamento,
            );
          } else {
            Modal('', response.errors.join(' \n \n '), 'warning');
          }
        }
      } else {
        setTouched({});
        setErrors({});
        setStep(step + 1);
        setValidationState(step + 1);
      }
    },
  });

  const { errors, touched, setFieldValue, setTouched, setErrors, setValues } =
    formik;

  useEffect(() => {
    async function loadCorrespondente() {
      const data = await fetchCredenciamento(idSolicitacao);
      if (data) {
        data.telefone = phoneFormatter(data.telefone);
        data.celular = cellPhoneFormatter(data.celular);
        data.horaVisita = moment(data.horaVisita, 'HH:mm');

        setValues(data);

        if (data.anexos) {
          let anexosId = [];
          const anexos = data.anexos.map((anexo) => {
            const newFile = new File([anexo], `anexo${anexo.id}`);
            anexosId.push(anexo);

            return {
              uid: anexo.id,
              name: newFile.name,
              type: newFile.type,
              url: anexo.conteudo,
            };
          });

          setFileList(anexos);
          setFileIds(anexosId);
          setFieldValue('anexos', anexosId);
        }
      }
    }

    if (idSolicitacao) {
      loadCorrespondente();
    } else {
      setValues(initialValues);
      setStep(0);
    }
  }, [
    idSolicitacao,
    setFieldValue,
    setValues,
    setFileIds,
    setFileList,
    fetchCredenciamento,
  ]);

  useEffect(() => {
    if (touched.cnpj && errors.cnpj === 'CNPJ inválido') {
      setStatusCNPJ('warning');
    } else if (touched.cnpj && errors.cnpj === 'O CNPJ é obrigatório') {
      setStatusCNPJ('error');
    } else {
      setStatusCNPJ('');
    }

    if (
      touched.telefone &&
      errors.telefone === 'Número de telefone inválido, favor tentar novamente.'
    ) {
      setStatusTelefone('warning');
    } else if (
      touched.telefone &&
      touched.celular &&
      errors.telefone === 'Um dos telefones deve ser preenchido'
    ) {
      setStatusTelefone('error');
    } else {
      setStatusTelefone('');
    }

    if (
      touched.celular &&
      errors.celular === 'Número de celular inválido, favor tentar novamente.'
    ) {
      setStatusCelular('warning');
    } else if (
      touched.telefone &&
      touched.celular &&
      errors.celular === 'Um dos telefones deve ser preenchido'
    ) {
      setStatusCelular('error');
    } else {
      setStatusCelular('');
    }

    if (
      touched.email &&
      errors.email === 'Deve ser informado um E-mail válido'
    ) {
      setStatusEmail('warning');
    } else if (touched.email && errors.email === 'O E-mail é obrigatório') {
      setStatusEmail('error');
    } else {
      setStatusEmail('');
    }

    if (
      touched.cep &&
      errors.cep === 'CEP inválido, favor preencher novamente.'
    ) {
      setStatusCEP('warning');
    } else if (touched.cep && errors.cep === 'O CEP é obrigatório') {
      setStatusCEP('error');
    } else {
      setStatusCEP('');
    }
  }, [
    errors,
    touched,
    setStatusCNPJ,
    setStatusTelefone,
    setStatusCelular,
    setStatusEmail,
    setStatusCEP,
  ]);

  useEffect(() => {
    if (tipo === 2 && step === 3) {
      setStep(step - 1);
      setValidationState(step - 1);
    }
  }, [tipo, step, childrenArray]);

  useEffect(() => {
    setFieldValue('tipo', tipo);
  }, [tipo, setFieldValue]);

  return (
    <FormikProvider value={formik}>
      <Form layout="vertical" autoComplete="off">
        <Steps
          current={step}
          className={
            'mb-5 steps-form-correspondente ' +
            (tipo === 2 && 'steps-conveniada')
          }
        >
          {childrenArray.map((child) => (
            <Step
              key={child.props.label}
              title={child.props.label}
              icon={child.props.icon}
            />
          ))}
        </Steps>

        {currentChild}

        <div className="wizard-buttons wizard-buttons-correspondente">
          {step > 0 ? (
            <Button
              type="button"
              variant="orange"
              onClick={() => {
                setTouched({});
                setErrors({});
                setStep(step - 1);
                setValidationState(step - 1);
              }}
            >
              <LeftOutlined style={{ marginRight: '10px' }} />
              <span>Voltar</span>
            </Button>
          ) : null}
          <Button type="submit" variant={buttonVariant}>
            {isLastStep() ? (
              <IconCadastrar style={{ marginRight: '10px' }} />
            ) : (
              ''
            )}
            <span>{buttonLabel}</span>
            {isLastStep() ? (
              ''
            ) : (
              <RightOutlined style={{ marginLeft: '10px' }} />
            )}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}

function FormStep({ children }) {
  return <Fragment>{children}</Fragment>;
}
