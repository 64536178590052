import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import CadastroPessoaProvider from '../../contexts/CadastroPessoaContext';

import CadastroPessoaList from './CadastroPessoaList';
import CadastroPessoaEdicao from './CadastroPessoaEdicao';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function CadastroPessoa() {
  useInspinia();
  const params = useParams();
  const { idPessoa } = params;
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Início</Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Pessoa</Breadcrumb.Item>
          <Breadcrumb.Item>Cadastro de Pessoa</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <div className="cadastroPessoaContainer">
        <Content>
          {checarPermissao('tela','tela.credito.pessoa.cadastro','Visualizar') && <> 
            <CadastroPessoaProvider>
              {idPessoa ? (
                <CadastroPessoaEdicao idPessoa={idPessoa} />
              ) : (
                <CadastroPessoaList />
              )}
            </CadastroPessoaProvider>
          
          </>}
        </Content>
      </div>
    </Main>
  );
}

export default CadastroPessoa;
