import React from "react";
import "./styles.scss";

function PaginationFollowUp({ totalCount, pageSize, currentPage, onPageChange, isFullScreen }) {
  const totalPages = Math.ceil(totalCount / pageSize);
  const initialPage = 1;

  function changePage(page) {
    if (page < 1 || page > totalPages) {
      return;
    }

    onPageChange(page);
  }

  function renderPageButton(pageNumber) {
    return <button className="button-pagination" onClick={() => changePage(pageNumber)}>{pageNumber}</button>;
  }

  function renderEllipsisButton(position) {
    return (
      <button onClick={() => changePage(position)} className="ellipsis button-pagination">
        ...
      </button>
    );
  }

  function renderButton(initialPage, currentPage, onPageChange) {
    if (totalPages < 6 && totalPages > 1) {
      const buttons = [];
      for (let firstPage = initialPage; firstPage <= totalPages; firstPage++) {
        buttons.push(
          <button
            className={currentPage === firstPage ? "button-current-page" : "button-pagination"}
            key={firstPage}
            onClick={() => onPageChange(firstPage)}
          >
            {firstPage}
          </button>
        );
      }
      return buttons;
    }
  }

  return (
    <>
      {totalPages > 5 && totalPages > 1 ?
        (<div className="wrapper-pagination">

          <button className="preview-button button-pagination"
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}>
            <span>Anterior</span>
          </button>

          {currentPage > 1 && renderPageButton(initialPage)}

          {currentPage > 2 &&
            currentPage < 4 &&
            renderPageButton(initialPage + 1)}

          {currentPage > 3 && renderEllipsisButton(currentPage - 1)}

          {currentPage === totalPages && renderPageButton(totalPages - 2)}

          {currentPage > 3 && renderPageButton(currentPage - 1)}

          <button className="button-current-page">{currentPage}</button>

          {currentPage + 1 < totalPages && renderPageButton(currentPage + 1)}

          {currentPage + 2 < totalPages &&
            (!(currentPage < totalPages - 2) || currentPage === 1) &&
            renderPageButton(currentPage + 2)}

          {currentPage < totalPages - 2 &&
            renderEllipsisButton(currentPage + 1)}

          {currentPage < totalPages && renderPageButton(totalPages)}

          <button className="next-button button-pagination"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <span>Próximo</span>
          </button>
        </div>) :
        totalPages > 1 &&
        <div className="wrapper-pagination">
          <button className="preview-button"
            onClick={() => changePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Anterior
          </button>

          {renderButton(initialPage, currentPage, onPageChange)}

          <button className="next-button"
            onClick={() => changePage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      }
    </>
  );
}

export default PaginationFollowUp;