import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import NotasFiscaisProvider from '../../contexts/NotasFiscaisContext';
import NotasFiscaisList from './List';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function RelatorioComissao() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Comissão</Breadcrumb.Item>
          <Breadcrumb.Item>Notas Fiscais</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <NotasFiscaisProvider>
          {checarPermissao('tela','tela.comercial.comissao.notasFiscais','Visualizar') && <> 
            <NotasFiscaisList />
          </>}
        </NotasFiscaisProvider>
      </Content>
    </Main>
  );
}

export default RelatorioComissao;
