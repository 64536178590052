import api from './api';

export const listarMotivos = async () => {
  const { data } = await api.get('/bloqueado/telefone');
  return data;
};

export const listarTelefones = async ({
  filtroTelefone = '',
  filtroAtivos = false,
  ordenacao,
  ordenacaoAsc = false,
  pagina = 1,
}) => {
  const { data } = await api.post('/bloqueado/telefone/lista', {
    filtroTelefone,
    filtroAtivos,
    ordenacao,
    ordenacaoAsc,
    pagina,
  });
  return data;
};

export const inserirTelefone = async (telefoneBlocklist) => {
  const dados = {
    telefone: telefoneBlocklist.telefone,
    motivoId: telefoneBlocklist.motivoId,
    tipo: telefoneBlocklist.tipo,
    descricao: telefoneBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('bloqueado/telefone', dados);
  return data;
};

export const editarTelefone = async (telefoneBlocklist) => {
  const dados = {
    id: telefoneBlocklist.id,
    descricao: telefoneBlocklist.descricaoExclusao,
  };

  const { data } = await api.put(`bloqueado/telefone/${dados.id}`, dados);
  return data;
};
