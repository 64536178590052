import React from 'react';

import { ReactComponent as Save } from '../../assets/images/icons/save.svg';
import { ReactComponent as Include } from '../../assets/images/icons/include.svg';
import { ReactComponent as Add } from '../../assets/images/icons/add.svg';
import { ReactComponent as Search } from '../../assets/images/icons/search.svg';
import { ReactComponent as Download } from '../../assets/images/icons/download.svg';
import { ReactComponent as Clean } from '../../assets/images/icons/clean.svg';
import { ReactComponent as Clock } from '../../assets/images/icons/clock.svg';
import { ReactComponent as ArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as Writing } from '../../assets/images/icons/writing.svg';
import { ReactComponent as Cellphone } from '../../assets/images/icons/cellphone.svg';
import { ReactComponent as Filter } from '../../assets/images/icons/filter.svg';
import { ReactComponent as Attachment } from '../../assets/images/icons/icon-anexo.svg';
import { ReactComponent as Anexo } from '../../assets/images/icons/icon-anexo.svg';
import { ReactComponent as Send } from '../../assets/images/icons/send-csv.svg';

function Icon({ variant, ...props }) {
  var icon;
  switch (variant) {
    case 'save': {
      icon = <Save {...props} />;
      break;
    }
    case 'include': {
      icon = <Include {...props} />;
      break;
    }
    case 'add': {
      icon = <Add {...props} />;
      break;
    }
    case 'search': {
      icon = <Search {...props} />;
      break;
    }
    case 'send': {
      icon = <Send {...props} />;
      break;
    }
    case 'download': {
      icon = <Download {...props} />;
      break;
    }
    case 'clean': {
      icon = <Clean {...props} />;
      break;
    }
    case 'clock': {
      icon = <Clock {...props} />;
      break;
    }
    case 'arrowSelect': {
      icon = <ArrowSelect {...props} />;
      break;
    }
    case 'writing': {
      icon = <Writing {...props} />;
      break;
    }
    case 'cellPhone': {
      icon = <Cellphone {...props} />;
      break;
    }
    case 'filter': {
      icon = <Filter {...props} />;
      break;
    }
    case 'attachment': {
      icon = <Attachment {...props} />;
      break;
    }
    case 'anexo': {
      icon = <Anexo {...props} />;
      break;
    }
    default:
      icon = null;
  }
  return icon;
}

export default Icon;
