import React, { useContext } from 'react';
import moment from 'moment';

import { Field, Formik } from 'formik';
import { Form, DatePicker, Select } from 'formik-antd';

import Button from '../../../components/Button';
import { cnpjMask } from '../../../utils/masks';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconCalendar } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../../assets/images/icons/erase.svg';

import { NotasFiscaisContext } from '../../../contexts/NotasFiscaisContext';
import InputMask from 'react-text-mask';

import './styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];
const monthFormatList = ['MM/YYYY', 'MMYYYY'];

const disabledDate = (current) => {
  return current < moment('0001-01-01');
};

function Toolbar() {
  const { initialFilters, matrizes, showFilter, setFilters } =
    useContext(NotasFiscaisContext);

  return (
    showFilter && (
      <div className="box-filtros">
        <Formik
          initialValues={initialFilters}
          onSubmit={async (data) => {
            const dataInicial = data.filtroData
              ? moment(data.filtroData[0]).format('YYYY-MM-DD')
              : null;
            const dataFinal = data.filtroData
              ? moment(data.filtroData[1]).format('YYYY-MM-DD')
              : null;
            const mesAnoReferencia = data.filtroMesAnoReferencia
              ? moment(data.filtroMesAnoReferencia).format('YYYY-MM')
              : null;

            setFilters({
              pagina: 1,
              filtroDataInicial: dataInicial,
              filtroDataFinal: dataFinal,
              filtroMesAnoReferencia: mesAnoReferencia,
              filtroCNPJMatriz: data.filtroCNPJMatriz,
              filtroMatriz: data.filtroMatriz,
              filtroStatusVerificacao: data.filtroStatusVerificacao,
            });
          }}
        >
          {({ resetForm, values, setFieldValue }) => (
            <Form layout="vertical">
              <div className="row">
                <div className="col-lg-10 col-md-12 col-inputs">
                  <div className="form-row">
                    <div className="col-sm-5">
                      <Form.Item label="CNPJ Matriz" name="filtroCNPJMatriz">
                        <Field name="filtroCNPJMatriz">
                          {({ field }) => (
                            <InputMask
                              {...field}
                              mask={cnpjMask}
                              className="ant-input"
                              placeholder="00.000.000/0000-00"
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </div>
                    <div className="col-sm-4">
                      <Form.Item label="Data de Inserção" name="filtroData">
                        <RangePicker
                          name="filtroData"
                          allowClear={false}
                          disabledDate={disabledDate}
                          format={dateFormatList}
                          suffixIcon={<IconCalendar />}
                          defaultPickerValue={[
                            moment().add(-1, 'month'),
                            moment(),
                          ]}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-3">
                      <Form.Item
                        label="Data de Referência"
                        name="filtroMesAnoReferencia"
                      >
                        <DatePicker
                          name="filtroMesAnoReferencia"
                          allowClear={false}
                          picker="month"
                          format={monthFormatList}
                          suffixIcon={<IconCalendar />}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-lg-5">
                      <Form.Item label="Matriz" name="filtroMatriz">
                        <Select
                          mode="multiple"
                          name="filtroMatriz"
                          placeholder="Selecione"
                          suffixIcon={<IconArrowSelect />}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {matrizes.length > 0 &&
                            matrizes.map((matriz) => (
                              <Option key={matriz.id} value={matriz.id}>
                                {matriz.nome}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-lg-4">
                      <Form.Item
                        label="Verificado"
                        name="filtroStatusVerificacao"
                      >
                        <Select
                          name="filtroStatusVerificacao"
                          placeholder="Selecione"
                          suffixIcon={<IconArrowSelect />}
                          allowClear
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          <Option key={1} value={true}>
                            Verificado
                          </Option>
                          <Option key={2} value={false}>
                            Não Verificado
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-12 col-botoes">
                  <Button
                    type="submit"
                    className="btn-pesquisar"
                    variant="blue"
                  >
                    <IconSearch />
                    Pesquisar
                  </Button>
                  <Button
                    type="button"
                    className="btn-limpar"
                    htmlType="reset"
                    variant="gray"
                    onClick={() => {
                      setFilters(initialFilters);
                      setFieldValue('filtroCNPJMatriz', '');
                      setFieldValue('filtroData', null);
                      setFieldValue('filtroMesAnoReferencia', '');
                      setFieldValue('filtroCNPJMatriz', '');
                      setFieldValue('filtroStatusVerificacao', '');
                      setFieldValue('filtroMatriz', null);
                    }}
                  >
                    <IconErase />
                    Limpar
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
}

export default Toolbar;
