import React, { useContext } from 'react';

import { ProcessamentoCrivoContext } from '../../../contexts/ProcessamentoCrivoContext';

import './styles.scss';

function RelatorioResponse() {
  const { processamentoCrivo } = useContext(ProcessamentoCrivoContext);

  return (
    <div className="RelatorioResponse">
      <div className="title">Respostas</div>

      {processamentoCrivo.map((item, index) => (
        <div className="line" 
          style={(index%2===0) ? {background: '#ddd' } : {background: '#fff' }}
        >
          <div
            className="circle"
            style={{ background: item.processamentoCor }}
          ></div>
          <div className="col-sm-5 crit">
            <div className="strong"></div>
            <p className="response strong">{item.processamentoCriterio}</p>
          </div>
          <div className="col-sm-6 return">
            <div className="strong"></div>
            <p className="response">{item.processamentoRetorno}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default RelatorioResponse;
