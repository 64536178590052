import React, { useContext } from 'react';
import Button from '../Button';
import Overlay from '../Overlay';

import { ReactComponent as ArrowRightIcon } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as CalculadoraIcon } from '../../assets/images/icons/calculadora.svg';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { OfertasProdutosContext } from '../../contexts/OfertasProdutosContext';
import './styles.scss';

function CardFormProduto({
  children,
  action,
  submitButton,
  loading = false,
  produto,
  setProduto,
  consultaPlaca,
}) {

  const { checarPermissao } = useContext(ControleAcessoContext);
  const { simulacaoProposta } = useContext(OfertasProdutosContext);

  const isConsultaPlacaOuNaoCPAuto =
    (produto?.nome === 'CP Auto' && consultaPlaca) ||
    produto?.nome !== 'CP Auto';

  return (
    <Overlay active={loading}>
      <div className="product-card-form">
        <div
          className={`product-card-header ${produto.id === 10 && 'cp-refin'}`}
        >
          <div className="product-name">{produto.nome}</div>

          <div className="title-buttons">
            {checarPermissao (
              'botao',
              'botao.credito.ofertaProdutos.cadastrar',
              'Visualizar',
            ) && isConsultaPlacaOuNaoCPAuto && submitButton ? (
              <Button onClick={action} variant="orange">
                CADASTRAR
                <ArrowRightIcon
                  height="15"
                  fill="#17222D"
                  style={{ marginLeft: 10 }}
                />
              </Button>
            ) : (
              isConsultaPlacaOuNaoCPAuto && !simulacaoProposta && (
                <Button
                  onClick={action}
                  variant="blue"
                  className="d-flex align-items-center"
                >
                  <CalculadoraIcon
                    height="24"
                    fill="#17222D"
                    style={{ marginRight: 10 }}
                  />
                  CALCULAR
                </Button>
              )
            )}
            <button
              type="button"
              className="x-button"
              onClick={() => setProduto(null)}
            >
              X
            </button>
          </div>
        </div>
        <div className="product-card-content">{children}</div>
      </div>
    </Overlay>
  );
}

export default CardFormProduto;
