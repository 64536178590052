import React, { useContext, useEffect, useState } from 'react';

import { Form, Select, Input } from 'formik-antd';
import { Modal } from 'antd';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/add3.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import InputMask from 'react-text-mask';
import { cpfMask } from '../../../utils/masks';
import Button from '../../../components/Button';
import Tooltip from '../../../components/tooltip';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';

function TooltipInfo({ text }) {
  return (
    <Tooltip
      placement="topLeft"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

function SociosForm({ formik, action = 'visualizacao', userId, isReadOnly }) {
  const {
    initialValuesSocios,
    sociosInputs,
    validaDuasPalavras,    
  } = useContext(CredenciadoContext);
  const [socios, setSocios] = useState(sociosInputs);
  const [visibleModalExcludedSocio, setVisibleModalExcludedSocio] =
    useState(false);
  const [modalExcludedSocioIndex, setModalExcludedSocioIndex] = useState(null);

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    getFieldHelpers,
    getFieldMeta,
    getFieldProps,
  } = formik;

  useEffect(() => {
    if (values.socios == null) {
      values.socios = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addSocio() {
    setFieldValue('socios', [...values.socios, ...initialValuesSocios]);
    const newList = [...socios, ...initialValuesSocios];
    setSocios(newList);
  }
  function removeSocio(index) {
    const newList = getFieldProps('socios').value;
    const newTouched = { ...touched };
    newList.splice(index, 1);
    newTouched?.socios?.splice(index, 1);
    setSocios([...newList]);
    setTouched({ ...newTouched });
    setFieldValue(`socios`, [...newList]);
  }
  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);

    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }

  function handleCancel() {
    setVisibleModalExcludedSocio(false);
    setModalExcludedSocioIndex(null);
  }

  useEffect(() => {
    setSocios(formik?.values?.socios);
  }, [formik?.values?.socios]);

  return (
    <>
      <Form layout="vertical">
        <div className="col-tab-socios">
          {values?.socios?.length
            ? values?.socios?.map((item, i) => (
                <div className="item-socios">
                  <div className="grid-socios">
                    <div className="item-dados">
                      <Form.Item
                        label="CPF"
                        name={`socios[${i}].cpf`}
                        help={genericValidate(`socios[${i}].cpf`, 'message')}
                        validateStatus={genericValidate(
                          `socios[${i}].cpf`,
                          'status',
                        )}
                      >
                        <InputMask
                          mask={cpfMask}
                          name={`socios[${i}].cpf`}
                          className="ant-input"
                          placeholder="000.000.000-00"
                          value={values?.socios[i]?.cpf}
                          onChange={(e) =>
                            setFieldValue(`socios[${i}].cpf`, e.target.value)
                          }
                          onBlur={() => genericTouched(`socios[${i}].cpf`)}
                          disabled={isReadOnly}
                        />
                      </Form.Item>
                    </div>
                    <div className="item-dados">
                      <Form.Item label="Nome" name={`socios[${i}].nome`}>
                        <Input
                          type="text"
                          value={values?.socios[i]?.nome}
                          name={`socios[${i}].nome`}
                          placeholder="Nome Completo"
                          onBlur={(e) => {
                            validaDuasPalavras(e, 'Nome');
                          }}
                          disabled={isReadOnly}
                        />
                      </Form.Item>
                    </div>
                    <div className="item-dados pep-select">
                      <Form.Item name={`socios[${i}].pep`}>
                        <label>PEP</label>
                        <TooltipInfo text="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares considerando assim parentes, na linha direta, até o primeiro grau, assim como o cônjuge, companheiro, enteado, bem como, outras pessoas de seu relacionamento próximo." />
                        <Select
                          name={`socios[${i}].pep`}
                          placeholder="Selecione"
                          value={values?.socios[i]?.pep}
                          suffixIcon={<IconArrowSelect />}
                          allowClear
                          disabled={isReadOnly}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          <option key={true} value={true}>
                            Sim
                          </option>
                          <option key={false} value={false}>
                            Não
                          </option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  {socios.length >= 1 ? (
                    <div
                      className="col-button-remove"
                      style={{
                        display: isReadOnly && 'none',
                      }}
                    >
                      <Button
                        onClick={() => {
                          if (
                            formik?.values?.socios[i]?.nome &&
                            formik?.values?.socios[i]?.cpf
                          ) {
                            setVisibleModalExcludedSocio(true);
                            setModalExcludedSocioIndex(i);
                          } else {
                            removeSocio(i);
                          }
                        }}
                        className="btnRemoveInputs"
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))
            : ''}
          <div
            className="form-row row"
            style={{
              display: isReadOnly && 'none',
            }}
          >
            <div className="col-lg-3 col-button-socios">
              <Button
                type="button"
                className="btn-socios"
                variant="blue"
                async={true}
                onClick={addSocio}
              >
                <IconPlusBtn />
                SÓCIO
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <Modal
        visible={visibleModalExcludedSocio}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={handleCancel}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          Deseja realmente excluir o sócio{' '}
          {visibleModalExcludedSocio &&
            formik?.values?.socios[modalExcludedSocioIndex]?.nome}{' '}
          {visibleModalExcludedSocio &&
            formik?.values?.socios[modalExcludedSocioIndex]?.cpf}
          ?
        </div>
        <div className="btn-area" style={{ gap: 10 }}>
          <Button
            variant="red"
            onClick={() => {
              handleCancel();
            }}
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => {
              removeSocio(modalExcludedSocioIndex);
              setVisibleModalExcludedSocio(false);
              setModalExcludedSocioIndex(null);
            }}
          >
            SIM
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default SociosForm;
