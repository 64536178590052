import { Field } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';
import InputMoney from '../InputMoney';
import { TipoCalculoVencimento } from '../../utils/identificadoresFrontBack';

function RendaInput({
  produtoEnergia = false,
  acesso,
  onBlur,
  labelSelectRenda,
  values,
  validationField,
  renda,
  setRenda,
  setInitialInputValue,
  initialInputValue,
  tabelaJuros
}) {
  let isEnergia = ['Energia', 'Luz em Dia'].includes(
    values?.operacao?.produtoNome,
  );
  return (
    ((produtoEnergia === true && isEnergia) ||
      (!isEnergia && produtoEnergia === false)) && (
      <div className={
        values?.operacao?.tipoCalculoVencimento === TipoCalculoVencimento.DEBITO_EM_CONTA && tabelaJuros?.length > 1
          ? `col-md-3 col-lg-3 col-xl-2`
          : `col-md-4 col-lg-4 col-xl-3`
      }>
        <Form.Item
          name="operacao.renda"
          label={labelSelectRenda()}
          validate={(value) =>
            validationField(value, 'operacao.renda', 'Renda')
          }
        >
          <Field name="operacao.renda">
            {({ field }) => (
              <InputMoney
                {...field}
                className="ant-input"
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale={true}
                maxLength={12}
                value={renda}
                style={{ textAlign: 'right' }}
                onFocus={async (e) => {
                  setInitialInputValue(e.target.value);
                }}

                onBlur={
                  async (e) => {
                    if (initialInputValue !== e.target.value) {
                      onBlur();
                    }
                  }
                }
                disabled={acesso}
                onValueChange={(values) => {
                  setRenda(values.floatValue);
                }}
              />
            )}
          </Field>
        </Form.Item>
      </div>
    )
  );
}

export default RendaInput;
