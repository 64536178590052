import React from 'react';

import ProcessamentoCrivoProvider from '../../contexts/ProcessamentoCrivoContext';

import RelatorioDrivers from './RelatorioDrivers';
import HeaderRelatorio from './HeaderRelatorios';
import RelatorioResponse from './RelatorioResponse';

import './styles.scss';

function ProcessamentoCrivo() {
  return (
    <div className="processamentoCrivo">
      <div className="relatorio">
        <ProcessamentoCrivoProvider>
          <HeaderRelatorio />
          <RelatorioResponse />
          <RelatorioDrivers />
        </ProcessamentoCrivoProvider>
      </div>
    </div>
  );
}

export default ProcessamentoCrivo;
