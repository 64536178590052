import api from './api';

export const getStatusCNPJ = async (cnpj) => {
  const { data } = await api.get(`/credenciamento/statusCNPJ/${cnpj}`);
  return data.data;
};

export const cadastrarCorrespondente = async (correspondente) => {
  try {
    const { data } = await api.post('/credenciamento', correspondente);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCredenciamento = async (id) => {
  const { data } = await api.get(`/credenciamento/${id}`);
  return data;
};

export const putCredenciamento = async (id, credenciamento) => {
  const { data } = await api.put(`/credenciamento/${id}`, credenciamento);
  return data;
};

export const getContextoAcompanhamentoCredenciamento = async () => {
  const { data } = await api.get(
    `/credenciamento/acompanhamento-estagio-contexto`,
  );
  return data.data;
};

export const filtroListaAcompanhamentoCredenciamento = async (filtro) => {
  const { data } = await api.post(
    '/credenciamento/acompanhamento-estagio',
    filtro,
  );
  return data.data;
};
