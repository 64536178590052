import {
  React,
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Field, FormikProvider, useFormik } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import { Button, Switch, Tooltip, Modal, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import NavigationPrompt from 'react-router-navigation-prompt';
import InputMask from 'react-text-mask';
import InputPhone from '../../components/InputPhone';
import moment from 'moment';
import validaPhone from '../../utils/validaPhone';

import { ReactComponent as IconErrorContained } from '../../assets/images/icons/icon-error-contained.svg';
import SelectComp from '../../components/Select';
import { SelectUnidades } from '../../components/SelectUnidades';
import Card from '../../components/Card';
import Title from '../../components/Title';
import SelectSearch from '../../components/SearchSelect';
import SimpleInput from '../../components/SimpleInput';
import DatePicker from '../../components/DatePicker';
import TableLogs from '../../components/TableLogs';
import ConversationMessages from './ConversationMessages';
import { validationSchema } from './validationSchemaGeneric';
import { useInterval } from '../../utils/customHooks';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { CadastroUsuariosContext } from '../../contexts/CadastroUsuariosContext';
import './styles.scss';

import { ColumnsContatoUsuario } from './columnsContatoUsuario';
import Table from '../../components/BasicTable';
import {
  getMatriz,
  getUnidades,
  loadContexto,
  gerarLogin,
  validaExclusaoPerfil,
  postObservacao,
  getUnidadeSubunidade,
} from '../../services/usuariosService';

import { cpfMask, cepMask, cnpjMask, mask } from '../../utils/masks';

import {
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../../services/enderecoService';

import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { ReactComponent as BulletInfoDark } from '../../assets/images/icons/bullet-info-dark.svg';
import { ReactComponent as IconSaveButton } from '../../assets/images/icons/save-floppy-disk.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconPlusBtn } from '../../assets/images/icons/add.svg';
import { ReactComponent as IconArchivePen } from '../../assets/images/icons/archive-pen.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/icons/trash2.svg';
import { ReactComponent as SuccessOutlinedIcon } from '../../assets/images/icons/confirm-outline.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close-icon.svg';
import { isNumber } from 'lodash';

const { Option } = Select;
const { Panel } = Collapse;

const PosicaoHistoricoHierarquia = ({ posicao }) => {
  return (
    <>
      <div className="evento-hierarquia-item">
        <div className="dot">•</div>
        {posicao?.nome}
        {posicao?.vigenciaInicial
          ? `: ${moment(posicao.vigenciaInicial).format(
            'DD/MM/YYYY',
          )} a ${moment(posicao.vigenciaFinal).format('DD/MM/YYYY')}`
          : ''}
      </div>
      {posicao?.posicoes?.map((childPosicao) => (
        <PosicaoHistoricoHierarquia posicao={childPosicao} />
      ))}
    </>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CadastroUsuariosPage({ userId }) {
  const [typeSwitch, setTypeSwitch] = useState('');
  const [visibleModalPossuiPosicao, setVisibleModalPossuiPosicao] =
    useState(false);
  const [visibleModalDesbloquearUsuario, setVisibleModalDesbloquearUsuario] =
    useState(false);
  const [visibleModalBloquearInativar, setVisibleModalBloquearInativar] =
    useState(false);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [statusErrorCEP, setStatusErrorCEP] = useState('');
  const [messageErrorCEP, setMessageErrorCEP] = useState('');
  const [listaMatriz, setListaMatriz] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);
  const [listaUnidadeSubunidade, setListaUnidadeSubunidade] = useState([]);
  const [oldPerfisList, setOldPerfisList] = useState([]);
  const [listaUnidadesFiltradas, setUnidadesFiltradas] = useState([]);
  const [visibleModalDescartarAlteracao, setVisibleModalDescartarAlteracao] =
    useState(false);
  const [visibleModalErrorNome, setVisibleModalErrorNome] = useState(false);
  const [motivoBloquearInativar, setMotivoBloquearInativar] = useState(null);
  const [observacaoBloquearInativar, setObservacaoBloquearInativar] =
    useState('');
  const [firstRun, setFirstRun] = useState(true);
  const [
    isDeleteCertificationModalVisible,
    setDeleteCertificationModalVisible,
  ] = useState(false);
  const [confirmDeleteCertification, setDeleteCertification] = useState(null);
  const [contextosUsuarios, setContextosUsuarios] = useState({
    tipoUsuario: [],
    tipoCertificacao: [],
    perfilUsuario: [],
    departamentoUsuario: [],
    classificacao: [],
    certificacao: [],
    motivoBloqueioUsuario: [],
    motivoInativacaoUsuario: [],
  });
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [isNewUserErrorModalVisible, setNewUserErrorModalVisible] =
    useState(false);
  // eslint-disable-next-line
  const [listaTodasUnidades, setTodasUnidades] = useState([]);
  const [oldMatrizValue, setOldMatrizValue] = useState('');
  const [cepValido, setCepValido] = useState(false);
  const [
    modalErrorProfileValidationVisible,
    setModalErrorProfileValidationVisible,
  ] = useState(false);
  const [statusTelefoneCadastroUsuario, setStatusTelefoneCadastroUsuario] =
    useState('');
  const btnFlutuanteRef = useRef();
  const windowHeight = window.innerHeight;
  const topBarOffset = 160;

  let readOnly = !!useQuery().get('visualizacao');

  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    userInfos,
    fetchUsuarioEdit,
    fetchObservacoes,
    observacoes,
    sendObservacao,
    keepObservacao,
    refreshObservacoes,
    criarUsuario,
    fetchPossuiPosicao,
    bloquearUsuario,
    desbloquearUsuario,
    inativarUsuario,
    historicoHierarquia,
    fetchHistoricoHierarquia,
    atualizarUsuario,
    telefoneCadastroUsuario,
    setTelefoneCadastroUsuario,
    unidadeSubunidade,
    setUnidadeSubunidade,
    showModalUsuarioAtivo,
    fetchUsuarioAtivo,
    setShowModalUsuarioAtivo,
    existeUsuarioAtivo,
  } = useContext(CadastroUsuariosContext);

  const initialValues = {
    ativo: true,
    bloqueado: false,
    dadosGerais: {
      tipoUsuarioId: '',
      nome: '',
      perfilUsuarioId: [],
      cpf: '',
      rg: '',
      dataNascimento: '',
      pep: false,
      login: '',
      departamentoId: '',
    },
    contato: {
      email: null,
      telefoneLista: [],
    },
    endereco: {
      cep: '',
      logradouro: '',
      numero: '',
      ufId: '',
      cidadeId: '',
      bairro: '',
      complemento: '',
    },
    dadosComerciais: {
      termoMidia: false,
      atuacaoPromotora: false,
      codigoAgente: '',
      classificacaoId: '',
      matrizId: null,
      unidadesBackoffice: [],
      unidadeVenda: [],
      redeSocial: '',
    },
    certificacoes: [
      {
        certificacaoId: '',
        tipoCertificacaoId: '',
        validade: '',
      },
    ],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
  });
  const {
    values,
    setFieldValue,
    getFieldHelpers,
    setTouched,
    getFieldMeta,
    setValues,
    setFieldTouched,
  } = formik;

  const history = useHistory();

  const columns = [
    {
      Header: 'Tipo de operação',
      accessor: 'tipoOperacao',
    },
    {
      Header: 'Usuário',
      accessor: 'usuario',
    },
    {
      Header: 'Data',
      accessor: 'dataHora',
    },
  ];

  const formatarTextoTooltip = () => {
    if (userInfos?.hierarquia) {
      return (
        <div>
          {userInfos?.hierarquia.map((hierarquia) => {
            if (hierarquia.detalhes) {
              return (
                <ul>
                  <li className="hierarquia-tooltip-title">
                    {hierarquia.nome}
                  </li>
                  {hierarquia?.detalhes?.map((detalhe) => {
                    return (
                      <li className="hierarquia-tooltip-body">
                        {detalhe?.posicao}:{' '}
                        {detalhe?.responsavelUsuarioAtual
                          ? `desde ${moment(detalhe?.vigenciaInicial).format(
                            'DD/MM/YYYY',
                          ) || ''
                          }`
                          : detalhe?.responsavel ?? ''}
                      </li>
                    );
                  })}
                </ul>
              );
            } else {
              return '';
            }
          })}
        </div>
      );
    }
    return '';
  };
  function checkIfTypeUserHasPermission(permission) {
    if (
      contextosUsuarios.tipoUsuario.length > 0 &&
      values?.dadosGerais?.tipoUsuarioId
    ) {
      const currentUserType = contextosUsuarios.tipoUsuario.find(
        (item) => item.id === values?.dadosGerais?.tipoUsuarioId,
      );
      if (permission === 'permiteAtuacaoPromotora') {
        return (
          currentUserType[permission] &&
          listaMatriz?.find(
            (item) => item.id === values?.dadosComerciais?.matrizId,
          )?.analisePromotora
        );
      } else {
        return currentUserType[permission];
      }
    }
    return false;
  }
  const alertUser = (e) => {
    e.preventDefault();
    alert('Sair sem salvar?');
  };
  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }
  async function handleUpdateUserInfo() {
    const errors = await formik
      .validateForm()
      .then((errors) => setTouched({ ...errors }, true));
    formik.setTouched(errors);
    if (!checkIfTypeUserHasPermission('permiteAtuacaoPromotora')) {
      delete errors?.dadosComerciais?.atuacaoPromotora;
    }
    if (!checkIfTypeUserHasPermission('permiteTermoMidia')) {
      delete errors?.dadosComerciais?.termoMidia;
    }
    if (!checkIfTypeUserHasPermission('possuiDadosComerciais')) {
      delete errors?.dadosComerciais;
    }
    if (!checkIfTypeUserHasPermission('possuiCertificacoes')) {
      delete errors?.certificacoes;
    }
    Object.entries(errors).forEach(([key, value]) => {
      if (isEmpty(errors[key])) {
        delete errors[key];
      }
    });
    if (!Object.keys(errors).length) {
      let valuesClone = JSON.parse(JSON.stringify(values));
      delete valuesClone.hierarquia;
      delete valuesClone.ativo;
      delete valuesClone.bloqueado;
      const removerCertificacoesVazias = valuesClone.certificacoes.filter(
        (certificacao) =>
          certificacao.certificacaoId !== null &&
          certificacao.certificacaoId !== '',
      );
      valuesClone.certificacoes = removerCertificacoesVazias;
      if (valuesClone?.dadosGerais?.pep === null) {
        valuesClone.dadosGerais.pep = false;
      }
      if (valuesClone?.dadosComerciais?.codigoAgente) {
        valuesClone.dadosComerciais.codigoAgente += '';
      }

      const tipoUsuarioContexto = contextosUsuarios?.tipoUsuario?.find(tipo => tipo.id === valuesClone?.dadosGerais?.tipoUsuarioId);

      if (tipoUsuarioContexto &&
        (tipoUsuarioContexto?.nome !== "Backoffice" &&
          tipoUsuarioContexto?.nome !== "Vendedor" &&
          tipoUsuarioContexto?.nome !== "Supervisor de Unidade")) {
        valuesClone.certificacoes = [];
        delete valuesClone?.dadosComerciais;
      }
      if (
        !valuesClone.contato.email ||
        valuesClone.contato.email.trim() === ''
      ) {
        valuesClone.contato.email = null;
      }

      if (valuesClone?.contato?.telefone === '') {
        delete valuesClone?.contato?.telefone;
      }
      if (valuesClone?.contato?.tipoTelefone) {
        delete valuesClone?.contato?.tipoTelefone;
      }
      if (valuesClone?.dadosComerciais?.unidadeVenda) {
        delete valuesClone?.dadosComerciais?.unidadeVenda;
      }

      if (tipoUsuarioContexto?.nome === "Vendedor" && valuesClone?.dadosComerciais) {
        valuesClone.dadosComerciais.unidadesBackoffice = [];
      }

      if (valuesClone?.dadosComerciais) {
        valuesClone.dadosComerciais.unidadesBackoffice = valuesClone.dadosComerciais.unidadesBackoffice.filter((id) => id !== null)
      }

      const certificacoesFiltradas = valuesClone.certificacoes.filter(cert => {
        return cert.certificacaoId !== undefined &&
          cert.tipoCertificacaoId !== undefined &&
          cert.validade !== undefined &&
          cert.certificacaoId !== "" &&
          cert.tipoCertificacaoId !== "" &&
          cert.validade !== "";
      });

      valuesClone.certificacoes = certificacoesFiltradas;

      const response = await atualizarUsuario(userId, valuesClone);
      if (response.data.success) {
        setSuccessModalVisible(true);
        setIsFormFilled(true);
      } else {
        setNewUserErrorModalVisible(true);
      }
    }
  }

  async function handleEnvio() {
    const errors = await formik
      .validateForm()
      .then((errors) => setTouched({ ...errors }, true));
    formik.setTouched(errors, true);
    if (!Object.keys(errors).length) {
      const valuesClone = JSON.parse(JSON.stringify(values));
      const newCertificacoes = valuesClone.certificacoes.filter(
        (item) => item.certificacaoId !== '',
      );
      valuesClone.certificacoes = newCertificacoes;
      valuesClone.dadosComerciais.codigoAgente =
        '' + valuesClone.dadosComerciais.codigoAgente;

      const tipoUsuarioContexto = contextosUsuarios?.tipoUsuario?.find(tipo => tipo.id === valuesClone?.dadosGerais?.tipoUsuarioId);

      if (tipoUsuarioContexto &&
        (tipoUsuarioContexto?.nome !== "Backoffice" &&
          tipoUsuarioContexto?.nome !== "Vendedor" &&
          tipoUsuarioContexto?.nome !== "Supervisor de Unidade")) {
        valuesClone.certificacoes = [];
        delete valuesClone?.dadosComerciais;
      }
      if (
        !valuesClone.contato.email ||
        valuesClone.contato.email.trim() === ''
      ) {
        valuesClone.contato.email = null;
      }
      if (valuesClone.bloqueado === true) {
        valuesClone.bloqueado = false;
      }

      if (valuesClone?.contato?.tipoTelefone) {
        delete valuesClone?.contato?.tipoTelefone;
      }

      if (valuesClone?.dadosComerciais?.unidadeVenda) {
        delete valuesClone?.dadosComerciais?.unidadeVenda;
      }

      if (tipoUsuarioContexto?.nome === "Vendedor" && valuesClone?.dadosComerciais) {
        valuesClone.dadosComerciais.unidadesBackoffice = [];
      }

      if (valuesClone?.dadosComerciais) {
        valuesClone.dadosComerciais.unidadesBackoffice = valuesClone.dadosComerciais.unidadesBackoffice.filter((id) => id !== null)
      }

      const certificacoesFiltradas = valuesClone.certificacoes.filter(cert => {
        return cert.certificacaoId !== undefined &&
          cert.tipoCertificacaoId !== undefined &&
          cert.validade !== undefined &&
          cert.certificacaoId !== "" &&
          cert.tipoCertificacaoId !== "" &&
          cert.validade !== "";
      });

      valuesClone.certificacoes = certificacoesFiltradas;

      if (valuesClone.dadosGerais.tipoUsuarioId)
        criarUsuario(valuesClone)
          .then((response) => {
            return response.data.data;
          })
          .then(async (data) => {
            if (data?.id) {
              for (let i = 0; i < observacoes.length; i++) {
                postObservacao(data?.id, observacoes[i].mensagem);
              }
            }
            return data;
          })
          .then(async (data) => {
            if (data.status) {
              if (values.bloqueado) {
                let body = {
                  id: data.id,
                  motivoId: motivoBloquearInativar,
                  observacao: observacaoBloquearInativar,
                  login: localStorage.getItem('login')
                };
                await bloquearUsuario(data.id, body);
              }
              setSuccessModalVisible(true);
              setIsFormFilled(true);
            } else {
              setNewUserErrorModalVisible(true);
            }
            return data;
          });
    }
  }

  const showLogHierarquico = () => {
    if (userId && [1, 3].includes(parseInt(values?.dadosGerais?.tipoUsuarioId)))
      return true;
  };

  const handleNome = async (value) => {
    if (!userId) {
      const nome = value.split(' ');
      let nomeLogin = nome.filter((item) => item !== '');
      if (nomeLogin.length >= 2) {
        const login = nomeLogin[0] + ' ' + nomeLogin[nomeLogin.length - 1];
        const data = await gerarLogin({ nome: login });
        setFieldValue('dadosGerais.login', data.login);
      } else {
        setVisibleModalErrorNome(true);
      }
    }
  };
  const handleConsultarCEP = async (value) => {
    setFieldValue('endereco.ufId', null);
    setFieldValue('endereco.cidadeId', null);
    setFieldValue('endereco.logradouro', null);
    setFieldValue('endereco.bairro', null);
    value = value.replace(/\D/g, '');
    if (value && value.length < 8) {
      setFieldValue('endereco.cep', null);
      setMessageErrorCEP('CEP inválido');
      setStatusErrorCEP('warning');
      setCepValido(false);
    } else if (value && value.length === 8) {
      const data = await fetchEndereco(value);
      if (data?.status) {
        setMessageErrorCEP(null);
        setStatusErrorCEP(null);
        await fetchCidades(data.ufId);
        setFieldValue('endereco.ufId', data.ufId);
        setFieldValue('endereco.cidadeId', data.cidadeId);
        setFieldValue('endereco.logradouro', data.logradouro);
        setFieldValue('endereco.bairro', data.bairro);
        setFieldValue('endereco.cep', data.cep);
        setCepValido(true);
      } else {
        setStatusErrorCEP('error');
        setMessageErrorCEP('CEP não encontrado');
        setCepValido(false);
      }
    }
  };
  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
    if (values?.endereco?.ufId) {
      fetchCidades(values?.endereco?.ufId);
    }
  };
  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    setCidades(response);
  };

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    if (response?.success) {
      return response.data;
    }
  };

  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);
    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }
  async function handleBloquearInativarUsuario() {
    const body = {
      id: userId,
      motivoId: motivoBloquearInativar,
      observacao: observacaoBloquearInativar,
      login: localStorage.getItem('login')
    };
    if (typeSwitch === 'bloquear') {
      setFieldValue('bloqueado', await bloquearUsuario(userId, body));
    } else {
      setFieldValue('ativo', !(await inativarUsuario(userId, body)));
    }
    setVisibleModalBloquearInativar(false);
    setObservacaoBloquearInativar('');
    setMotivoBloquearInativar(null);
  }
  function handleSwitchs(value, type) {
    fetchPossuiPosicao(userId).then((data) => {
      if (type === 'bloquear') {
        if (value) {
          data
            ? setVisibleModalPossuiPosicao(true)
            : setVisibleModalBloquearInativar(true);
        } else {
          if (data) {
            setVisibleModalDesbloquearUsuario(true);
          } else {
            const body = {
              login: localStorage.getItem("login")
            }
            setFieldValue('bloqueado', !desbloquearUsuario(userId, body));
          }
        }
      } else {
        if (!value) {
          data
            ? setVisibleModalPossuiPosicao(true)
            : setVisibleModalBloquearInativar(true);
        }
      }
    });
  }

  useEffect(() => {
    if (userInfos.ativo && values?.endereco?.cep === '') {
      setCepValido(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.endereco?.cep]);

  useEffect(() => {
    if (values?.endereco?.ufId) {
      fetchCidades(values?.endereco?.ufId);
    }
  }, [values?.endereco?.ufId]);

  useEffect(() => {
    if (userId) {
      fetchObservacoes(userId);
      fetchUsuarioEdit(userId);
      fetchHistoricoHierarquia(userId);
      if (readOnly) {
        setIsFormFilled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (userId && refreshObservacoes) {
      const lastMessageId = observacoes[observacoes.length - 1]?.id;
      await fetchObservacoes(userId, lastMessageId);
    }
  }, refreshObservacoes * 1000);

  useEffect(() => {
    if (btnFlutuanteRef?.current?.style !== null) {
      btnFlutuanteRef.current.style.top = `${parseInt(
        windowHeight - topBarOffset,
      )}px`;
    }

    function handleScroll() {
      let scroll = document.body.scrollTop;
      if (btnFlutuanteRef.current.style) {
        btnFlutuanteRef.current.style.top = `${parseInt(
          windowHeight - topBarOffset + scroll,
        )}px`;
      }
    }
    document.body.addEventListener('scroll', handleScroll);
    return () => document.body.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visibleModalDescartarAlteracao || visibleModalErrorNome) {
      document.querySelector('body').classList.add('overflow');
    }
  }, [visibleModalDescartarAlteracao, visibleModalErrorNome]);

  useEffect(() => {
    if (
      visibleModalDescartarAlteracao ||
      visibleModalErrorNome ||
      visibleModalBloquearInativar ||
      visibleModalPossuiPosicao ||
      visibleModalDesbloquearUsuario
    ) {
      document.querySelector('body').classList.add('overflow');
    }
  }, [
    visibleModalDescartarAlteracao,
    visibleModalErrorNome,
    visibleModalBloquearInativar,
    visibleModalPossuiPosicao,
    visibleModalDesbloquearUsuario,
  ]);

  useEffect(() => {
    loadContexto().then((data) => setContextosUsuarios(data));
    fetchEstados();
    setOldPerfisList(values?.dadosGerais?.perfilUsuarioId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.addEventListener('beforeunload', alertUser);
    };
  }, []);

  function verificaMatrizLista(id) {
    return !!listaMatriz?.find((item) => item.id === id);
  }

  useEffect(() => {
    if (userInfos?.endereco?.cep) {
      setCepValido(true);
    }

    if (verificaMatrizLista(userInfos?.dadosComerciais?.matrizId)) {
      setOldMatrizValue(userInfos?.dadosComerciais?.matrizId);
    } else {
      setOldMatrizValue(userInfos?.dadosComerciais?.nomeMatriz);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfos, listaMatriz]);

  useEffect(() => {
    if (isNumber(oldMatrizValue)) {
      getUnidadesUsuario(oldMatrizValue);
    }

    if (!values?.dadosComerciais?.unidadeVenda) {
      setFieldValue(
        'dadosComerciais.unidadeVenda',
        userInfos?.dadosComerciais?.unidades ?? [],
      );
      setListaUnidades(userInfos?.dadosComerciais?.unidades);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldMatrizValue]);

  useEffect(() => {
    if (userId) {
      setValues(userInfos);
      setFieldValue('contato.telefone', userInfos?.contato?.telefone || '');
    }
    setFieldValue('tipoTelefone', contextosUsuarios?.tipoTelefone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userInfos, setValues]);

  useEffect(() => {
    if (values?.dadosGerais?.tipoUsuarioId) {
      getMatrizUsuario();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dadosGerais?.tipoUsuarioId]);

  useEffect(() => {
    if (
      userId &&
      oldPerfisList?.length > values?.dadosGerais?.perfilUsuarioId?.length
    ) {
      validaExclusaoPerfil(userId, values?.dadosGerais?.perfilUsuarioId).then(
        (response) => {
          if (response.perfis_validos) {
            setOldPerfisList(values?.dadosGerais?.perfilUsuarioId);
          } else {
            setFieldValue('dadosGerais.perfilUsuarioId', oldPerfisList);
            setModalErrorProfileValidationVisible(true);
          }
        },
      );
    } else {
      setOldPerfisList(values?.dadosGerais?.perfilUsuarioId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dadosGerais?.perfilUsuarioId]);

  function getUnidadesUsuario(id) {
    if (id && listaMatriz?.find((item) => item.id === id)) {
      getUnidades(id).then((data) => {
        if (data) {
          setListaUnidades(data);
          setListaUnidadeSubunidade(data);
          if (
            !data.find((elem) =>
              values?.dadosComerciais?.unidadeVenda?.includes(elem.id),
            ) &&
            listaUnidadesFiltradas?.length
          ) {
            setFieldValue('dadosComerciais.unidadeVenda', []);
          }
        } else {
          setFieldValue(
            'dadosComerciais.unidadeVenda',
            userInfos?.dadosComerciais?.unidadeVenda,
          );
          setListaUnidades([]);
        }
      });
    }
  }

  useEffect(() => {
    if (listaUnidadeSubunidade?.length > 0) {
      let idUnidadeFiltered = listaUnidadeSubunidade?.find(
        (objeto) => objeto.matriz,
      )?.id;
      getUnidadeSubunidade(idUnidadeFiltered).then((data) => {
        setUnidadeSubunidade(data?.data);
      });
    }
    // eslint-disable-next-line
  }, [listaUnidadeSubunidade]);


  function getMatrizUsuario() {
    if (listaMatriz.length === 0) {
      if (checkIfTypeUserHasPermission('possuiClassificacao') || true) {
        getMatriz()
          .then((data) => {
            setListaMatriz(data);
          })
          .then(() => {
            if (values?.dadosComerciais?.matrizId) {
              getUnidadesUsuario(values?.dadosComerciais?.matrizId);
            }
          });
      }
    } else {
      setFieldValue('dadosComerciais.unidadeVenda', []);

      const { matrizId } = values.dadosComerciais;
      if (matrizId && verificaMatrizLista(matrizId)) {
        setOldMatrizValue(matrizId);
      } else {
        setOldMatrizValue(userInfos?.dadosComerciais?.nomeMatriz);
      }
    }
  }

  const handleTelefoneAdicionalType = (value, indice) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(`contato.tipoTelefone.${indice}`, type);
    } else {
      setFieldValue(`contato.tipoTelefone.${indice}`, '');
    }
  };

  const removeMask = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  };

  const handleAddTelefone = () => {
    const telefone = values?.contato?.telefone;
    if (!telefone || !validaPhone(telefone)) {
      return;
    }

    const telefoneSemMascara = removeMask(telefone);

    setTelefoneCadastroUsuario([
      ...telefoneCadastroUsuario,
      { telefone: telefoneSemMascara, principal: false },
    ]);

    setFieldValue('contato.telefoneLista', [
      ...telefoneCadastroUsuario,
      { telefone: telefoneSemMascara, principal: false },
    ]);

    setFieldValue('contato.telefone', '');
    setStatusTelefoneCadastroUsuario([...statusTelefoneCadastroUsuario, '']);
  };

  useEffect(() => {
    setFieldValue('contato.telefoneLista', telefoneCadastroUsuario);
    // eslint-disable-next-line
  }, [telefoneCadastroUsuario]);

  useEffect(() => {
    if (
      listaUnidadeSubunidade.length !== 0 &&
      formik?.values?.dadosComerciais?.unidadeVenda !== null
    ) {
      if (!firstRun) {
        setFieldValue('dadosComerciais.unidadeVendaId', null);
        setFieldValue('dadosComerciais.unidadesBackoffice', []);
      } else {
        setFirstRun(false);
      }
    }
    // eslint-disable-next-line
  }, [unidadeSubunidade]);

  const handleChangeCpf = (e) => {
  const cpf = e.target.value.replaceAll('-', '').replaceAll('.', '');
    if (cpf.length === 11) {
      fetchUsuarioAtivo(cpf, () => setFieldValue('dadosGerais.cpf', ''));
    }
    setFieldTouched('dadosGerais.cpf', true);
  };

  const ComponentTermoMidia = () => {
    return (
      checkIfTypeUserHasPermission('permiteTermoMidia') && (
        <Form.Item
          name="ativo"
          className="switch-midia"
          style={{ width: '200px' }}
        >
          <div className="separation-label-switch">
            <label className="label-switch label-switch-termo-midia">
              Termo de Mídia
            </label>
            <Switch
              size="small"
              className="switch-red-if-off switch-margin termo-midia-button"
              checked={values?.dadosComerciais?.termoMidia}
              onClick={() => {
                setFieldValue(
                  'dadosComerciais.termoMidia',
                  !values?.dadosComerciais?.termoMidia,
                );
              }}
              disabled={(userId && !values?.ativo) || readOnly}
            />
          </div>
        </Form.Item>
      )
    );
  };

  const ComponentAtuacaoPromotora = () => {
    return (
      checkIfTypeUserHasPermission('permiteAtuacaoPromotora') && (
        <Form.Item name="atuacaoPromotora" className="atuacao-promotora-switch">
          <div className="separation-label-switch">
            <label className="label-switch">Atuação Promotora</label>
            <Switch
              size="small"
              className="switch-red-if-off switch-margin"
              checked={values?.dadosComerciais?.atuacaoPromotora}
              onClick={() => {
                setFieldValue(
                  'dadosComerciais.atuacaoPromotora',
                  !values?.dadosComerciais?.atuacaoPromotora,
                );
              }}
              disabled={(userId && !values?.ativo) || readOnly}
            />
          </div>
        </Form.Item>
      )
    );
  };

  const ComponentPossuiCertificacoes = () => {
    return (
      <div className="col-lg-12 d-flex flex-wrap p-0 wrapper-certificacoes">
        {values.certificacoes.map((item, i) => (
          <>
            <div className="col-lg-3 pl-0 item-certificacoes">
              <Form.Item
                name={`certificacoes[${i}].certificacaoId`}
                label="Certificação"
              >
                <Select
                  name={`certificacoes[${i}].certificacaoId`}
                  placeholder="Selecione"
                  allowClear
                  suffixIcon={<IconArrowSelect />}
                  disabled={(userId && !values?.ativo) || readOnly}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue(`certificacoes[${i}].certificacaoId`, value)
                    }
                  }}
                >
                  {contextosUsuarios?.certificacao.length > 0
                    ? contextosUsuarios?.certificacao.map((certificacao) => (
                      <Option value={certificacao?.id}>
                        {certificacao?.nome}
                      </Option>
                    ))
                    : ''}
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-3 px-0 item-certificacoes">
              <Form.Item
                name={`certificacoes[${i}].tipoCertificacaoId`}
                label="Tipo de Certificação"
              >
                <Select
                  name={`certificacoes[${i}].tipoCertificacaoId`}
                  placeholder="Selecione"
                  allowClear
                  suffixIcon={<IconArrowSelect />}
                  disabled={!values.certificacoes[i].certificacaoId || (userId && !values?.ativo) || readOnly}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue(`certificacoes[${i}].tipoCertificacaoId`, value)
                      setFieldValue(`certificacoes[${i}].validade`, '');
                      formik.validateField(`certificacoes[${i}].validade`)
                    }
                  }}
                >
                  {contextosUsuarios?.tipoCertificacao.length > 0
                    ? contextosUsuarios?.tipoCertificacao.map(
                      (tipoCertificacao) => (
                        <Option value={tipoCertificacao?.id}>
                          {tipoCertificacao?.nome}
                        </Option>
                      ),
                    )
                    : ''}
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-3 px-0 item-certificacoes">
              <Form.Item
                name={`certificacoes[${i}].validade`}
                className='validade-certificacao'
                label="Validade da Certificação"
                validate={(value) => {
                  const isDisabled = !values.certificacoes[i].certificacaoId || !values.certificacoes[i].tipoCertificacaoId || (userId && !values?.ativo) || readOnly

                  if (!value && !isDisabled) {
                    return "Campo Obrigatório"
                  }

                }}
              >
                <DatePicker
                  className="data-picker-validade-certificacao"
                  format="DD/MM/YYYY"
                  onChange={({ _d: date }) =>
                    setFieldValue(
                      `certificacoes[${i}].validade`,
                      moment(date).format('YYYY-MM-DD'),
                    )
                  }
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (!value) {
                      setFieldValue(`certificacoes[${i}].validade`, '');
                    }
                    formik.validateField(`certificacoes[${i}].validade`);
                    if (
                      formik?.touched?.certificacoes && formik.touched?.certificacoes?.length > 0) {
                      formik.touched.certificacoes[i].validade = true
                    }
                  }}
                  name={`certificacoes[${i}].validade`}
                  placeholder="Selecione"
                  disabled={!values.certificacoes[i].tipoCertificacaoId || (userId && !values?.ativo) || readOnly}

                />
              </Form.Item>
            </div>
            {values.certificacoes.length > 1 && (
              <Button
                className="deletar-certificacao-contato-button"
                onClick={() => {
                  setDeleteCertification(i);
                  setDeleteCertificationModalVisible(true);
                }}
              >
                <TrashIcon />
              </Button>
            )}
          </>
        ))}

        <div className="col-lg-2 col-btn btn-certificacao">
          <Button
            type="button"
            className="btn-acao btn-enviar"
            onClick={() => {
              setFieldValue('certificacoes', [
                ...values.certificacoes,
                {
                  certificacaoId: '',
                  tipoCertificacaoId: '',
                  validade: '',
                },
              ]);
            }}
            disabled={(userId && !values?.ativo) || readOnly}
          >
            <IconPlusBtn />
            Certificação
          </Button>
        </div>
      </div>
    );
  };


  // O useEffect abaixo é acionado sempre que a lista de certificações (values.certificacoes) é alterada.
  // Ele itera sobre cada certificação na lista e verifica se o campo tipoCertificacaoId está ausente,
  // mas certificacaoId está presente. Se essa condição for verdadeira, define a validade da certificação
  // para uma string vazia e força a validação desse campo usando o método validateField do Formik.
  // Isso garante que a validade seja limpa e o campo seja validado corretamente quando as certificações são modificadas.
  useEffect(() => {
    values.certificacoes?.forEach((c, i) => {
      if (
        c.certificacaoId &&
        !c?.tipoCertificacaoId) {
        setFieldValue(`certificacoes[${i}].validade`, '');
        formik.validateField(`certificacoes[${i}].validade`)

      } else if (
        // se clicar no "X" do certificacaoId ele limpa e valida os demais campos
        c.certificacaoId === undefined
      ) {
        formik.validateField(`certificacoes[${i}].validade`)
        setFieldValue(`certificacoes[${i}].tipoCertificacaoId`, '');
        setFieldValue(`certificacoes[${i}].validade`, '');
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.certificacoes]);

  return (
    <div className="viewport-cadastro-usuarios">
      {!isFormFilled && (
        <NavigationPrompt when={readOnly ? false : true} disableNative>
          {({ isActive, onCancel, onConfirm }) => (
            <Modal
              visible={isActive}
              centered
              footer={null}
              width={452}
              maskClosable={false}
              className="modal-warning"
              onCancel={onCancel}
            >
              <div className="icon">
                <IconExclamationCircle width={75} />
              </div>
              <div className="text-modal">Deseja descartar as alterações?</div>
              <div className="btn-area">
                <Button
                  variant="gray"
                  onClick={onCancel}
                  className="btn-cancel"
                >
                  NÃO
                </Button>
                <Button
                  variant="blue"
                  onClick={onConfirm}
                  className="btn-confirm"
                >
                  SIM
                </Button>
              </div>
            </Modal>
          )}
        </NavigationPrompt>
      )}
      <div className="cadastro-usuario-page">
        <Card
          className="titulo-pagina-cadastro-usuario"
          title={
            <Title
              label={userId ? 'Edição de Usuário' : 'Cadastro de Usuário'}
            />
          }
        >
          <FormikProvider value={formik}>
            <Form>
              <div className="row form-cadastro-usuario">
                <div className="d-flex">
                {
                  checarPermissao(
                    'botao',
                    'botao.cadastro.usuarios.inativar',
                    'Visualizar'
                  ) && 
                  <Form.Item name="ativo" className="remove-click-area">
                    <div className="separation-label-switch">
                      <label className="label-switch">Ativo</label>
                      <Switch
                        size="small"
                        className="switch-red-if-off switch-margin"
                        checked={values?.ativo}
                        onClick={() => {
                          setFieldValue('ativo', !values?.ativo);
                        }}
                        onChange={(checked) => {
                          if (userId) {
                            setTypeSwitch('ativar');
                            handleSwitchs(checked, 'ativar');
                          }
                        }}
                        disabled={(userId && !values?.ativo) || readOnly || !checarPermissao(
                          'botao',
                          'botao.cadastro.usuarios.inativar',
                          'Editar'
                        )}
                      />
                    </div>
                  </Form.Item>
                }
                {
                  checarPermissao(
                    'botao',
                    'botao.cadastro.usuarios.bloquearDesbloquear',
                    'Visualizar'
                  ) && 
                  <Form.Item name="bloqueado">
                    <div className="separation-label-switch">
                      <label className="label-switch">Bloqueado</label>
                      <Switch
                        size="small"
                        className="switch-red-if-off switch-margin"
                        onChange={(checked) => {
                          if (userId) {
                            setTypeSwitch('bloquear');
                            handleSwitchs(checked, 'bloquear');
                          } else if (values.bloqueado) {
                            setFieldValue('bloqueado', false);
                            setMotivoBloquearInativar(null);
                            setObservacaoBloquearInativar('');
                          } else {
                            setTypeSwitch('bloquear');
                            setVisibleModalBloquearInativar(true);
                            setFieldValue('bloqueado', !values.bloqueado);
                          }
                        }}
                        disabled={readOnly || !checarPermissao(
                          'botao',
                          'botao.cadastro.usuarios.bloquearDesbloquear',
                          'Editar'
                        )}
                        checked={values?.bloqueado}
                      />
                    </div>
                  </Form.Item>
                }
                  <Modal
                    title={
                      typeSwitch === 'ativar' ? (
                        <Title label="Inativação do usuário" />
                      ) : (
                        <Title label="Bloqueio do usuário" />
                      )
                    }
                    visible={visibleModalBloquearInativar}
                    onCancel={() => {
                      if (values.bloqueado) {
                        setFieldValue('bloqueado', false);
                      }
                      setMotivoBloquearInativar(null);
                      setObservacaoBloquearInativar('');
                      setVisibleModalBloquearInativar(false);
                    }}
                    centered
                    footer={null}
                    width={452}
                    maskClosable={false}
                    className="modal-bloquear-inativar-usuario"
                  >
                    <div>
                      <label>Motivo</label>
                      <SelectComp
                        onChange={(val) => setMotivoBloquearInativar(val)}
                        placeholder="Selecione"
                        name="selectMotivo"
                        allowClear
                        value={motivoBloquearInativar}
                      >
                        {typeSwitch === 'ativar'
                          ? contextosUsuarios.motivoInativacaoUsuario.map(
                            (item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ),
                          )
                          : contextosUsuarios.motivoBloqueioUsuario.map(
                            (item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ),
                          )}
                      </SelectComp>
                      <label>Observação</label>
                      <Input.TextArea
                        rows="2"
                        placeholder="Digite aqui"
                        value={observacaoBloquearInativar}
                        onChange={(e) =>
                          setObservacaoBloquearInativar(e.target.value)
                        }
                      />
                      <div className="buttons">
                        <Button
                          variant="gray"
                          onClick={() => {
                            setMotivoBloquearInativar(null);
                            setObservacaoBloquearInativar('');
                            setVisibleModalBloquearInativar(false);
                            if (typeSwitch === 'ativar') {
                              setFieldValue('ativo', !values.ativo);
                            } else {
                              setFieldValue('bloqueado', false);
                            }
                          }}
                          className="btn-gray btn-cancel"
                        >
                          CANCELAR
                        </Button>
                        <Button
                          variant="blue"
                          onClick={() => {
                            if (userId) {
                              handleBloquearInativarUsuario();
                            } else {
                              setVisibleModalBloquearInativar(false);
                            }
                          }}
                          className="btn-blue btn-save"
                        >
                          {typeSwitch === 'ativar' ? 'INATIVAR' : 'BLOQUEAR'}
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </div>
                <Modal
                  visible={visibleModalPossuiPosicao}
                  centered
                  footer={null}
                  width={452}
                  maskClosable={false}
                  className="modal-warning"
                  onCancel={() => {
                    setVisibleModalPossuiPosicao(false);
                  }}
                >
                  <div className="icon">
                    <IconExclamationCircle width={75} />
                  </div>
                  <div className="text-modal">
                    {typeSwitch === 'ativar'
                      ? 'Usuário associado a uma posição hierárquica. Deseja realmente inativá-lo?'
                      : 'Usuário associado a uma posição hierárquica. Deseja realmente bloqueá-lo?'}
                  </div>
                  <div className="btn-area">
                    <Button
                      variant="gray"
                      onClick={() => {
                        setVisibleModalPossuiPosicao(false);
                      }}
                      className="btn-cancel"
                    >
                      NÃO
                    </Button>
                    <Button
                      variant="blue"
                      onClick={() => {
                        setVisibleModalPossuiPosicao(false);
                        setVisibleModalBloquearInativar(true);
                      }}
                      className="btn-confirm"
                    >
                      SIM
                    </Button>
                  </div>
                </Modal>
                <Modal
                  visible={visibleModalDesbloquearUsuario}
                  centered
                  footer={null}
                  width={452}
                  maskClosable={false}
                  className="modal-warning"
                  onCancel={() => {
                    setVisibleModalDesbloquearUsuario(false);
                  }}
                >
                  <div className="icon">
                    <IconExclamationCircle width={75} />
                  </div>
                  <div className="text-modal">
                    Usuário associado a uma unidade inativada. Deseja realmente
                    desbloqueá-lo?
                  </div>
                  <div className="btn-area">
                    <Button
                      variant="gray"
                      onClick={() => {
                        setVisibleModalDesbloquearUsuario(false);
                      }}
                      className="btn-cancel"
                    >
                      NÃO
                    </Button>
                    <Button
                      variant="blue"
                      onClick={() => {
                        setVisibleModalDesbloquearUsuario(false);
                        const body = {
                          login: localStorage.getItem("login")
                        }
                        setFieldValue('bloqueado', !desbloquearUsuario(userId, body));
                      }}
                      className="btn-confirm"
                    >
                      SIM
                    </Button>
                  </div>
                </Modal>
                <div className="col-lg-12 form-title">
                  <div className="cube"></div>Dados Gerais
                  {userInfos?.hierarquia && formatarTextoTooltip() ? (
                    <Tooltip
                      placement="topLeft"
                      className="tooltip-info"
                      open
                      overlayClassName="tooltip-dados-gerais"
                      color="#435361"
                      title={() => formatarTextoTooltip()}
                    >
                      <BulletInfoDark className="bullet-info-icon" />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-lg-3">
                  <Form.Item
                    name="dadosGerais.tipoUsuarioId"
                    label="Tipo de usuário"
                  >
                    <Select
                      name="dadosGerais.tipoUsuarioId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      disabled={(userId && !values?.ativo) || readOnly}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {contextosUsuarios.tipoUsuario.length > 0
                        ? contextosUsuarios.tipoUsuario
                          .filter((userType) => userType.ativo)
                          .map((userType, idx) => (
                            <Option value={userType?.id} key={idx}>
                              {userType.nome}
                            </Option>
                          ))
                        : ''}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-9">
                  <Form.Item
                    name="dadosGerais.nome"
                    label="Nome"
                    validateStatus={genericValidate(
                      'dadosGerais.nome',
                      'status',
                    )}
                  >
                    <Input
                      name="dadosGerais.nome"
                      placeholder="Nome Completo"
                      onBlur={(e) => handleNome(e.target.value)}
                      disabled={(userId && !values?.ativo) || readOnly}
                    />
                  </Form.Item>
                </div>
                <Modal
                  visible={visibleModalErrorNome}
                  onCancel={() => {
                    setVisibleModalErrorNome(false);
                  }}
                  centered
                  footer={null}
                  width={452}
                  maskClosable={false}
                  className="modal-warning"
                >
                  <div className="icon">
                    <IconExclamationCircle width={75} />
                  </div>
                  <div className="text-modal">
                    Informe ao menos duas palavras para o campo Nome
                  </div>
                  <div className="btn-area">
                    <Button
                      variant="blue"
                      onClick={() => setVisibleModalErrorNome(false)}
                      className="btn-confirm"
                    >
                      OK
                    </Button>
                  </div>
                </Modal>
                <div className="col-lg-3">
                  <Form.Item label="Perfil" name="dadosGerais.perfilUsuarioId">
                    <Select
                      name="dadosGerais.perfilUsuarioId"
                      placeholder={'Selecione'}
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      value={oldPerfisList}
                      mode="multiple"
                      disabled={(userId && !values?.ativo) || readOnly}
                      removeIcon={<CloseIcon />}
                      className="perfil-usuario-select"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {contextosUsuarios.perfilUsuario.length > 0
                        ? contextosUsuarios.perfilUsuario.filter((perfil) => perfil.ativo)
                        .map((perfil, idx) => (
                          <Option value={perfil.id} key={idx}>
                            {perfil.nome}
                          </Option>
                        ))
                        : ''}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-3">
                  <Form.Item
                    name="dadosGerais.cpf"
                    label="CPF"
                    validateStatus={genericValidate(
                      'dadosGerais.cpf',
                      'status',
                    )}
                  >
                    <Field name="dadosGerais.cpf">
                      {({ field }) => (
                        <InputMask
                          {...field}
                          mask={
                            userId
                              ? values?.dadosGerais?.cpf?.length === 14
                                ? cnpjMask
                                : cpfMask
                              : cpfMask
                          }
                          showMask={userId ? true : false}
                          className="ant-input"
                          placeholder="000.000.000-00"
                          onChange={(e) =>
                            setFieldValue(
                              'dadosGerais.cpf',
                              e.target.value
                                .replaceAll('-', '')
                                .replaceAll('.', ''),
                            )
                          }
                          onBlur={handleChangeCpf}
                          value={values?.dadosGerais?.cpf}
                          disabled={userId ? true : false}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                    <Modal
                      visible={showModalUsuarioAtivo}
                      centered
                      footer={null}
                      width={452}
                      maskClosable={false}
                      className="modal-warning"
                    >
                      <div className="icon">
                        <IconExclamationCircle width={75} />
                      </div>
                      <div className="text-modal">
                        {existeUsuarioAtivo?.mensagem}
                      </div>
                      <div className="btn-area">
                        <Button
                          variant="blue"
                          onClick={() => setShowModalUsuarioAtivo(false)}
                          className="btn-confirm"
                        >
                          OK
                        </Button>
                      </div>
                    </Modal>
                <div className="col-lg-3">
                  <Form.Item name="dadosGerais.rg" label="RG">
                    <Input
                      name="dadosGerais.rg"
                      placeholder="000000"
                      onBlur={() => genericTouched('dadosGerais.rg')}
                      disabled={userId ? true : false}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-3">
                  <Form.Item
                    name="dadosGerais.dataNascimento"
                    label="Data Nascimento"
                  >
                    <DatePicker
                      disabledDate={(d) => !moment(d).isBefore(Date.now())}
                      disabled={(userId && !values?.ativo) || readOnly}
                      name="dadosGerais.dataNascimento"
                      format="DD/MM/YYYY"
                      placeholder="00/00/0000"
                      onChange={({ _d: date }) =>
                        setFieldValue(
                          `dadosGerais.dataNascimento`,
                          moment(date).format('YYYY-MM-DD'),
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-3">
                  <Form.Item name="dadosGerais.pep">
                    <label>PEP</label>
                    <Tooltip
                      arrowPointAtCenter
                      autoAdjustOverflow
                      placement="topLeft"
                      className="tooltip-info-pep"
                      overlayClassName="tooltip-pep"
                      color="#435361"
                      title={
                        'Pessoa politicamente exposta é aquela que desempenha ou tenha \
                            desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou \
                            funções públicas relevantes, no Brasil ou em outros países, \
                            territórios e dependências estrangeiros, assim como seus \
                            representantes, familiares considerando assim parentes, \
                            na linha direta, até o primeiro grau, assim como o cônjuge, \
                            companheiro, enteado, bem como, outras pessoas de seu \
                            relacionamento próximo.'
                      }
                    >
                      <BulletInfoDark className="bullet-info-icon pep" />
                    </Tooltip>
                    <Select
                      name="dadosGerais.pep"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      disabled={(userId && !values?.ativo) || readOnly}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value={false}>Não</Option>
                      <Option value={true}>Sim</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item name="dadosGerais.login" label="Login">
                    <Input
                      name="dadosGerais.login"
                      placeholder="Login do usuário"
                      disabled={values?.dadosGerais?.login}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-3">
                  <Form.Item
                    name="dadosGerais.departamentoId"
                    label="Departamento"
                  >
                    <Select
                      name="dadosGerais.departamentoId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      disabled={(userId && !values?.ativo) || readOnly}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      {contextosUsuarios.departamentoUsuario.length > 0
                        ? contextosUsuarios.departamentoUsuario.map(
                          (item, idx) => (
                            <Option value={item.id} key={idx}>
                              {item.nome}
                            </Option>
                          ),
                        )
                        : ''}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 form-title">
                  <div className="cube"></div>Contato
                </div>
                <div className="col-xl-3 col-lg-3">
                  <Form.Item name="contato.email" label="E-mail">
                    <Input
                      name="contato.email"
                      placeholder="Digite aqui"
                      disabled={(userId && !values?.ativo) || readOnly}
                    />
                  </Form.Item>
                </div>

                <div className="col-lg-3 col-xl-3 pr-lg-0 contato-container">
                  <Form.Item
                    name={`contato.telefone`}
                    label="Telefone"
                    validateStatus={statusTelefoneCadastroUsuario}
                    validate={(value) => {
                      let msgError = '';
                      if (value && !validaPhone(value)) {
                        msgError = 'Telefone inválido';
                        setStatusTelefoneCadastroUsuario('warning');
                      } else {
                        msgError = '';
                        setStatusTelefoneCadastroUsuario('');
                      }
                      return msgError;
                    }}
                  >
                    <Field name={`contato.telefone`}>
                      {({ field }) => (
                        <InputPhone
                          placeholderValue="(__) ____-____"
                          {...field}
                          onBlur={(e) =>
                            handleTelefoneAdicionalType(e.target.value, 0)
                          }
                          disabled={(userId && !values?.ativo) || readOnly}
                        />
                      )}
                    </Field>
                    <div class="d-flex align-items-end md-mr-2">
                      <Button
                        htmlType="button"
                        className="phone-button"
                        onClick={handleAddTelefone}
                        disabled={(userId && !values?.ativo) || readOnly}
                      >
                        <IconPlusBtn />
                        <span className='text-phone-button'>Adicionar</span>
                      </Button>
                    </div>
                  </Form.Item>
                </div>
                <div className="col-xd-12 col-lg-12">
                  <div className="contact-columns col-xl-6 col-lg-6">
                    <Table
                      columns={ColumnsContatoUsuario(
                        contextosUsuarios?.tipoTelefone,
                        values?.ativo
                      )}
                      data={telefoneCadastroUsuario || []}
                      onLoadData={() => {}}
                      loading={false}
                      mensagemArrayVazio={
                        <div className="no-phone-container">
                          <IconErrorContained className="icon-error-contained" />
                          <span className="no-phone-message">
                            Nenhum Telefone Adicionado
                          </span>
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12 form-title">
                  <div className="cube"></div>Endereço
                </div>
                <div className="col-lg-4">
                  <Form.Item
                    name="endereco.cep"
                    label="CEP"
                    help={
                      messageErrorCEP
                        ? messageErrorCEP
                        : genericValidate('endereco.cep', 'message')
                    }
                    validateStatus={
                      statusErrorCEP
                        ? statusErrorCEP
                        : genericValidate('endereco.cep', 'status')
                    }
                  >
                    <InputMask
                      mask={cepMask}
                      className="ant-input"
                      name="endereco.cep"
                      placeholder="00.000-000"
                      onChange={(e) =>
                        setFieldValue(
                          'endereco.cep',
                          e.target.value.replaceAll('-', ''),
                        )
                      }
                      onBlur={(e) => {
                        handleConsultarCEP(e.target.value);
                        genericTouched('endereco.cep');
                      }}
                      value={values?.endereco?.cep}
                      disabled={(userId && !values?.ativo) || readOnly}
                      defaultValue={
                        values?.endereco?.cep &&
                        mask(values?.endereco?.cep, '#####-###')
                      }
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="endereco.logradouro" label="Logradouro">
                    <Field name="endereco.logradouro">
                      {({ field }) => (
                        <SimpleInput
                          {...field}
                          name="endereco.logradouro"
                          value={values?.endereco?.logradouro}
                          disabled={(userId && !values?.ativo) || readOnly}
                          placeholder="Rua, Avenida..."
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="endereco.numero" label="Número">
                    <Input
                      name="endereco.numero"
                      placeholder="Digite aqui"
                      disabled={(userId && !values?.ativo) || readOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="endereco.ufId" label="UF">
                    <SelectSearch
                      name="endereco.ufId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      value={values?.endereco?.ufId}
                      onChange={(value) => {
                        setFieldValue('endereco.ufId', value);
                        setFieldValue('endereco.cidadeId', '');
                      }}
                      disabled={
                        (userId && !values?.ativo) || readOnly || cepValido
                      }
                      showSearch
                    >
                      {estados &&
                        estados.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.uf}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Form.Item>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="endereco.cidadeId" label="Cidade">
                    <SelectSearch
                      showSearch
                      name="endereco.cidadeId"
                      value={values?.endereco?.cidadeId}
                      disabled={
                        (userId && !values?.ativo) ||
                        cepValido ||
                        !values?.endereco?.ufId ||
                        readOnly
                      }
                      onChange={(value) =>
                        setFieldValue('endereco.cidadeId', value)
                      }
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                    >
                      {cidades.length > 0 &&
                        cidades.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                    </SelectSearch>
                  </Form.Item>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="endereco.bairro" label="Bairro">
                    <Field name="endereco.bairro">
                      {({ field }) => (
                        <SimpleInput
                          {...field}
                          name="endereco.bairro"
                          placeholder="Digite aqui"
                          value={values?.endereco?.bairro}
                          disabled={(userId && !values?.ativo) || readOnly}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                <div className="col-lg-12">
                  <Form.Item name="endereco.complemento" label="Complemento">
                    <Field name="endereco.complemento">
                      {({ field }) => (
                        <SimpleInput
                          {...field}
                          placeholder="Casa, Apto, Bloco..."
                          disabled={(userId && !values?.ativo) || readOnly}
                          value={values?.endereco?.complemento}
                          onBlur={(e) =>
                            setFieldValue(
                              'endereco.complemento',
                              e.target.value,
                            )
                          }
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>

                {checkIfTypeUserHasPermission('possuiDadosComerciais') && (
                  <>
                    <div className="col-lg-12 form-title">
                      <div className="cube"></div>Dados Comerciais
                    </div>
                    <div className={'col-lg-12 switch-separator'}>
                      {/* Foi necessário quebrar em componentes retornados através
                      de arrow function devido ao problema de encadeamento de 
                      useEffects e renderizações condicionais :) */}
                      {ComponentTermoMidia()}
                      {ComponentAtuacaoPromotora()}
                    </div>

                    <CamposDadosComerciais
                      listaTodasUnidades={listaTodasUnidades}
                      getUnidades={getUnidades}
                      userId={userId}
                      readOnly={readOnly}
                      checkIfTypeUserHasPermission={
                        checkIfTypeUserHasPermission
                      }
                      oldMatrizValue={oldMatrizValue}
                      listaMatriz={listaMatriz}
                      listaUnidades={listaUnidades}
                      listaUnidadesFiltradas={listaUnidadesFiltradas}
                      setUnidadesFiltradas={setUnidadesFiltradas}
                      contextosUsuarios={contextosUsuarios}
                      values={values}
                      setFieldValue={setFieldValue}
                      formik={formik}
                      setOldMatrizValue={setOldMatrizValue}
                      userInfos={userInfos}
                      verificaMatrizLista={verificaMatrizLista}
                      unidadeSubunidade={unidadeSubunidade}
                    />
                  </>
                )}

                {checkIfTypeUserHasPermission('possuiCertificacoes') && (
                  <div className="col-lg-12">
                    <div className="col-lg-12 form-title p-0">
                      <div className="cube"></div>Certificações
                    </div>
                    {/* Foi necessário quebrar em componentes retornados através
                      de arrow function devido ao problema de encadeamento de 
                      useEffects e renderizações condicionais :) */}
                    {ComponentPossuiCertificacoes()}
                  </div>
                )}

                <Modal
                  visible={isDeleteCertificationModalVisible}
                  onCancel={() => {
                    setDeleteCertificationModalVisible(false);
                  }}
                  centered
                  footer={null}
                  width={452}
                  maskClosable={false}
                  className="modal-warning"
                >
                  <div className="icon">
                    <IconExclamationCircle width={75} />
                  </div>
                  <div className="text-modal">
                    Confirma a exclusão da certificação?
                  </div>
                  <div className="btn-area">
                    <Button
                      variant="gray"
                      onClick={() => {
                        setDeleteCertification(null);
                        setDeleteCertificationModalVisible(false);
                      }}
                      className="btn-cancel"
                    >
                      NÃO
                    </Button>
                    <Button
                      variant="blue"
                      onClick={() => {
                        setFieldValue(
                          'certificacoes',
                          values.certificacoes.filter((item, index) => {
                            return index !== confirmDeleteCertification;
                          }),
                        );
                        setDeleteCertificationModalVisible(false);
                      }}
                      className="btn-confirm"
                    >
                      SIM
                    </Button>
                  </div>
                </Modal>

                <div className="col-lg-12 text-right">
                  <Button
                    type="button"
                    variant="gray"
                    className="btn-cancelar"
                    onClick={() => history.push('/usuarios')}
                    disabled={(userId && !values?.ativo) || readOnly}
                  >
                    Cancelar
                  </Button>
                  <Modal
                    visible={visibleModalDescartarAlteracao}
                    centered
                    footer={null}
                    width={452}
                    maskClosable={false}
                    className="modal-warning"
                    onCancel={() => {
                      setVisibleModalDescartarAlteracao(false);
                    }}
                  >
                    <div className="icon">
                      <IconExclamationCircle width={75} />
                    </div>
                    <div className="text-modal">
                      Deseja descartar as alterações?
                    </div>
                    <div className="btn-area">
                      <Button
                        variant="gray"
                        onClick={() => setVisibleModalDescartarAlteracao(false)}
                        className="btn-cancel"
                      >
                        NÃO
                      </Button>
                      <Button
                        variant="blue"
                        onClick={() => {
                          history.push('/usuarios');
                        }}
                        className="btn-confirm"
                      >
                        SIM
                      </Button>
                    </div>
                  </Modal>
                  <Button
                    type="button"
                    onClick={() => {
                      if (userId) {
                        handleUpdateUserInfo();
                      } else {
                        handleEnvio();
                      }
                    }}
                    disabled={
                      readOnly ||
                      ((!values?.ativo  &&
                        userId) ||
                        !checarPermissao(
                          'botao',
                          'botao.cadastro.usuarios.salvar',
                          'Editar',
                        ))
                    }
                    className="btn-acao btn-salvar save"
                  >
                    <IconSaveButton className="svg-save" />
                    <span className="span-text-salvar">Salvar</span>
                  </Button>
                  <Modal
                    visible={isSuccessModalVisible}
                    centered
                    footer={null}
                    width={452}
                    maskClosable={false}
                    className="modal-warning"
                    onCancel={() => {
                      setSuccessModalVisible(false);
                      history.push('/usuarios');
                    }}
                  >
                    <div className="icon">
                      <SuccessOutlinedIcon width={75} />
                    </div>
                    <div className="text-modal">
                      {userId
                        ? 'Registro atualizado com sucesso!'
                        : 'Cadastro realizado com sucesso'}
                    </div>
                    <div className="btn-area">
                      <Link to="/usuarios">
                        <Button
                          variant="blue"
                          onClick={() => {
                            setSuccessModalVisible(false);
                          }}
                          className="btn-confirm"
                        >
                          OK
                        </Button>
                      </Link>
                    </div>
                  </Modal>
                  <Modal
                    visible={modalErrorProfileValidationVisible}
                    centered
                    footer={null}
                    width={452}
                    maskClosable={false}
                    className="modal-warning"
                    onCancel={() => {
                      setModalErrorProfileValidationVisible(false);
                    }}
                  >
                    <div className="icon">
                      <IconExclamationCircle width={75} />
                    </div>
                    <div className="text-modal">
                      Esse perfil não pode ser excluído, pois é condição para a
                      posição que o usuário assume dentro da hierarquia
                      atualmente. É necessário encerrar a vigência do usuário
                      como responsável pela posição antes de excluir esse perfil
                      do cadastro dele.
                    </div>
                    <div className="btn-area">
                      <Button
                        variant="blue"
                        onClick={() => {
                          setModalErrorProfileValidationVisible(false);
                        }}
                        className="btn-confirm"
                      >
                        OK
                      </Button>
                    </div>
                  </Modal>
                  <Modal
                    visible={isNewUserErrorModalVisible}
                    centered
                    footer={null}
                    width={452}
                    maskClosable={false}
                    className="modal-warning"
                    onCancel={() => {
                      setNewUserErrorModalVisible(false);
                    }}
                  >
                    <div className="icon">
                      <IconExclamationCircle width={75} />
                    </div>
                    <div className="text-modal">
                      Erro ao salvar alteração. <br /> Por favor, tente mais
                      tarde!
                    </div>
                    <div className="btn-area">
                      <Button
                        variant="blue"
                        onClick={() => setNewUserErrorModalVisible(false)}
                        className="btn-confirm"
                      >
                        OK
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </Card>
        {userId ? (
          <Card title={''} className="card-collapse-logs">
            {showLogHierarquico() ? (
              <Collapse
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{ color: '#ff7817' }}
                  />
                )}
                className="mt-4"
              >
                <Panel
                  header={'Histórico de Hierarquia '}
                  className="cadastro-usuarios-historico-hierarquia"
                >
                  {historicoHierarquia?.map((posicao) => (
                    <div className="evento-hierarquia">
                      <h3>{posicao?.nome}</h3>
                      {posicao?.posicoes?.map((childPosicao) => (
                        <>
                          <PosicaoHistoricoHierarquia posicao={childPosicao} />
                        </>
                      ))}
                    </div>
                  ))}
                </Panel>
              </Collapse>
            ) : (
              ''
            )}
            <Collapse
              expandIconPosition="left"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  style={{ color: '#ff7817' }}
                />
              )}
              className="mt-4"
            >
              <Panel
                header={'Histórico de Segurança'}
                className="tabela-listagem-usuarios"
              >
                <div className="tabelaListagem">
                  <TableLogs
                    idUsuario={userId}
                    tipoLog="Seguranca.Usuario"
                    columnsHeader={columns}
                  ></TableLogs>
                </div>
              </Panel>
            </Collapse>
          </Card>
        ) : (
          ''
        )}
      </div>
      <ConversationMessages
        title="Observação"
        mode="tooltip"
        listMessages={observacoes}
        placementTooltip="bottomRight"
        type="chat"
        sendMessage={userId ? sendObservacao : keepObservacao}
        proposta={1}
        origem={2}
        customClass="cadastro-usuarios-conversation-messages"
        userId={userId}
        readOnly={readOnly}
      >
        <Button
          type="submit"
          className={['btn-acao', 'btn-salvar', 'btn-flutuante-archive-pen']}
          style={{ pointerEvents: !values?.ativo ? 'none' : 'auto' }}
          ref={btnFlutuanteRef}
        >
          <IconArchivePen />
        </Button>
      </ConversationMessages>
    </div>
  );
}

function CamposDadosComerciais({
  userId,
  contextosUsuarios,
  checkIfTypeUserHasPermission,
  listaMatriz,
  values,
  setFieldValue,
  formik,
  setOldMatrizValue,
  oldMatrizValue,
  setUnidadesFiltradas,
  readOnly,
  unidadeSubunidade,
}) {
  const [currentMatrizValue, setCurrentMatrizValue] = useState(
    values?.dadosComerciais?.matrizId || 0,
  );
  const [visibleModalAlterarMatriz, setVisibleModalAlterarMatriz] =
    useState(false);

  useEffect(() => {
    if (visibleModalAlterarMatriz) {
      document.querySelector('body').classList.add('overflow');
    }
  }, [visibleModalAlterarMatriz]);

  return (
    <div className="container-dados-comerciais col-lg-12 d-flex flex-wrap p-0">
      {checkIfTypeUserHasPermission('possuiCodigoAgente') && (
        <div className="col-lg-3 px-2">
          <Form.Item
            name="dadosComerciais.codigoAgente"
            label="Código do agente"
          >
            <Input
              name="dadosComerciais.codigoAgente"
              placeholder="Digite aqui"
              disabled={(userId && !values?.ativo) || readOnly}
            />
          </Form.Item>
        </div>
      )}
      {checkIfTypeUserHasPermission('possuiClassificacao') && (
        <div className="col-lg-3 px-2">
          <Form.Item
            name="dadosComerciais.classificacaoId"
            label="Classificação"
            validate={(value) => {
              if (
                !value &&
                checkIfTypeUserHasPermission('possuiClassificacao')
              ) {
                return 'Campo Obrigatório';
              }
            }}
          >
            <Select
              name="dadosComerciais.classificacaoId"
              placeholder="Selecione"
              suffixIcon={<IconArrowSelect />}
              disabled={(userId && !values?.ativo) || readOnly}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {contextosUsuarios.classificacao.length > 0
                ? contextosUsuarios.classificacao.map((item) => (
                  <Option value={item.id}>{item.nome}</Option>
                ))
                : ''}
            </Select>
          </Form.Item>
        </div>
      )}
      <div className="col-lg-6 px-2">
        <Form.Item name="dadosComerciais.redeSocial" label="Rede social">
          <Input
            name="dadosComerciais.redeSocial"
            placeholder="Digite aqui"
            className="campo-rede-social"
            disabled={(userId && !values?.ativo) || readOnly}
          />
        </Form.Item>
      </div>
      <div className="d-flex flex-wrap col-lg-12 p-0 my-0">
        <div className="col-lg-6 d-flex p-0">
          <div className="col-lg-12 p-0">
            <div className="col-lg-12 px-2">
              <Form.Item
                name="dadosComerciais.matrizId"
                label="Matriz"
                validate={(value) => {
                  if (
                    !value &&
                    checkIfTypeUserHasPermission('possuiClassificacao')
                  ) {
                    return 'Campo Obrigatório';
                  }
                }}
              >
                <SelectSearch
                  showSearch
                  placeholder="Selecione"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  suffixIcon={<IconArrowSelect />}
                  onChange={(value) => {
                    setCurrentMatrizValue(value);
                    setFieldValue('dadosComerciais.atuacaoPromotora', false);
                    if (userId && oldMatrizValue) {
                      setTimeout(() => setVisibleModalAlterarMatriz(true), 300);
                      return;
                    } else {
                      setOldMatrizValue(value);
                      setFieldValue('dadosComerciais.matrizId', value);
                    }
                    setFieldValue('dadosComerciais.unidadeVenda', []);
                    if (!userId) {
                      setUnidadesFiltradas([]);
                    }
                  }}
                  value={oldMatrizValue}
                  disabled={(userId && !values?.ativo) || readOnly}
                >
                  {listaMatriz.map((item, index) => (
                    <Option key={'matriz-' + index} value={parseInt(item.id)}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
              <Modal
                visible={visibleModalAlterarMatriz}
                centered
                footer={null}
                width={452}
                maskClosable={false}
                className="modal-warning"
                onCancel={() => {
                  setVisibleModalAlterarMatriz(false);
                }}
              >
                <div className="icon">
                  <IconExclamationCircle width={75} />
                </div>
                <div className="text-modal" style={{ minWidth: 410 }}>
                  Deseja realmente alterar a matriz do usuário? Um novo registro
                  de usuário será gerado automaticamente ao salvar as
                  alterações.
                </div>
                <div className="btn-area">
                  <Button
                    variant="gray"
                    onClick={() => {
                      setVisibleModalAlterarMatriz(false);
                    }}
                    className="btn-cancel"
                  >
                    NÃO
                  </Button>
                  <Button
                    variant="blue"
                    onClick={() => {
                      setVisibleModalAlterarMatriz(false);
                      setFieldValue(
                        'dadosComerciais.matrizId',
                        currentMatrizValue,
                      );
                      setOldMatrizValue(currentMatrizValue);
                      setFieldValue('dadosComerciais.unidadeVenda', []);
                      setFieldValue('dadosComerciais.unidadesBackoffice', []);
                      setUnidadesFiltradas([]);
                    }}
                    className="btn-confirm"
                  >
                    SIM
                  </Button>
                </div>
              </Modal>
            </div>
            {checkIfTypeUserHasPermission('possuiUnidadesBackOffice') && (
              <div className="col-lg-12 mb-md-3 py-md-1 p-2">
                <SelectUnidades
                  single={true}
                  label={'Unidade de Venda'}
                  disabled={(currentMatrizValue === 0 ? true : false) || (userId && !values?.ativo) || readOnly}
                  data={unidadeSubunidade}
                  formik={formik}
                  fieldValue="dadosComerciais.unidadeVendaId"
                  initialValue={formik?.values?.dadosComerciais?.unidadeVenda}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-6 d-flex px-2">
          {checkIfTypeUserHasPermission('possuiUnidadesBackOffice') && (
            <SelectUnidades
          disabled={(currentMatrizValue === 0 ? true : false) || (userId && !values?.ativo) || readOnly}
              label={'Unidades BackOffice'}
              collapse={true}
              data={unidadeSubunidade}
              formik={formik}
              fieldValue="dadosComerciais.unidadesBackoffice"
              initialValue={formik.values?.dadosComerciais?.unidadesBackoffice}
            />
          )}
          {!checkIfTypeUserHasPermission('possuiUnidadesBackOffice') && (
            <div className="col-lg-12 p-0">
              <SelectUnidades
                disabled={(currentMatrizValue === 0 ? true : false) || (userId && !values?.ativo) || readOnly}
                single={true}
                label={'Unidade de Venda'}
                data={unidadeSubunidade}
                formik={formik}
                fieldValue="dadosComerciais.unidadeVendaId"
                initialValue={formik?.values?.dadosComerciais?.unidadeVenda}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default CadastroUsuariosPage;
