import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';

import { CaretDownFilled } from '@ant-design/icons';

import './styles.scss';

function SelectComponent({ filled, modalidade, children, value, onChange, ...rest }) {
  
  const filledClass = classNames('form-group', {
    filled: filled === true,
    'select-modalidade': modalidade === true,
  });
  
  const [selected, setSelected] = useState(null);
  
  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Select
      {...rest}
      className={filledClass}
      getPopupContainer={(trigger) => trigger.parentNode}
      suffixIcon={
        <CaretDownFilled
          className="arrow-color"
        />
      }
      showArrow={true}
      onChange={value => {setSelected(value); onChange(value)}}      
      value={selected}        
      onInputKeyDown={(e) => {
        if ((e.which === 9) && document?.getElementById(e.target.getAttribute('aria-owns'))) {
          let value = children.find((item) => {
            const elems = document?.getElementById(e.target.getAttribute('aria-owns'))?.parentElement?.getElementsByClassName("ant-select-item-option-active");
            return (elems?.length === 1) && (item?.props?.children === elems[0]?.innerText);
          }); 
          
          setSelected(value?.props?.value); 
          onChange(value?.props?.value);
        }
      }} 
    >
      {children}
    </Select>
  );
}

export default SelectComponent;
