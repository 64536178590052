import React, { createContext, useEffect, useState, useCallback } from 'react';

import {
  listarCPFs,
  inserirCPF,
  editarCPF,
  listarMotivos,
} from '../services/blocklistCPFService';

export const BlocklistCPFContext = createContext({});

const initialCpfBlocklistState = {
  id: 0,
  cpf: '',
  motivoId: null,
  descricaoInclusao: '',
  descricaoExclusao: '',
  inclusao: '',
  exclusao: '',
  usuarioInclusao: '',
  usuarioExclusao: '',
  edit: '',
};

function BlocklistCPFProvider({ children }) {
  const [listagemCPFs, setListagemCPFs] = useState([]);
  const [cpfBlocklist, setCpfBlocklist] = useState(initialCpfBlocklistState);
  const [motivos, setMotivos] = useState([]);

  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({ filterStatus: true });
  const [cpf, setCpf] = useState('');

  const resetCpfBlocklistForm = () => {
    setCpfBlocklist(initialCpfBlocklistState);
  };

  const fetchListagemCPFs = useCallback(async (props) => {
    setLoading(true);

    const page = props?.pageIndex + 1;
    let response = []

    if(props?.filter?.filtroDinamico || props?.filter?.filtroDinamico === ''){
      response = await listarCPFs({
        filtroCPF: props?.filter.filtroDinamico,
        filtroAtivos: !!props?.filter.filterStatus,
        ordenacao: props?.sortBy[0]?.id,
        ordenacaoAsc: props?.sortBy[0]?.desc,
        pagina: page || 1,
      });
    }

    if (!props) {
      response = await listarCPFs({})
    }

    if (response?.itens) {
      setListagemCPFs(response?.itens);
    } else {
      setListagemCPFs([]);
    }

    setTotal(response?.total);
    setPageCount(response?.totalPages);
    setLoading(false);
  }, []);

  const fetchMotivos = async () => {
    const response = await listarMotivos();
    setMotivos(response.motivo);
  };

  const salvarBlocklistCPF = async (cpfBlocklist) => {
    let response;

    if (cpfBlocklist.id > 0) {
      response = await editarCPF(cpfBlocklist);
    } else {
      response = await inserirCPF(cpfBlocklist);
    }

    setCpfBlocklist(initialCpfBlocklistState);
    await fetchListagemCPFs();

    return response;
  };

  const setValueForCpfBlocklist = async (data) => {
    setCpfBlocklist(data);
  };

  useEffect(() => {
    fetchMotivos();
  }, []);

  return (
    <BlocklistCPFContext.Provider
      value={{
        filter,
        motivos,
        listagemCPFs,
        setListagemCPFs,
        cpfBlocklist,
        setCpfBlocklist,
        resetCpfBlocklistForm,
        setValueForCpfBlocklist,
        salvarBlocklistCPF,
        setFilter,
        fetchListagemCPFs,
        total,
        pageCount,
        loading,
        cpf,
        setCpf,
      }}
    >
      {children}
    </BlocklistCPFContext.Provider>
  );
}

export default BlocklistCPFProvider;
