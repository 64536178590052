import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import RelatorioEsteiraProvider from '../../contexts/RelatorioEsteiraContext';
import RelatorioEsteira from './RelatorioEsteira';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function RelatorioEsteiraCredito() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Relatório</Breadcrumb.Item>
          <Breadcrumb.Item>Esteira de Crédito</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <RelatorioEsteiraProvider>
          {checarPermissao(
            'tela',
            'tela.credito.relatorio.esteiraCredito',
            'Visualizar',
          ) && (
            <>
              <RelatorioEsteira />
            </>
          )}
        </RelatorioEsteiraProvider>
      </Content>
    </Main>
  );
}

export default RelatorioEsteiraCredito;
