import React, { useContext } from 'react';
import { AnaliseMesaContext } from '../../contexts/AnaliseMesaContext';
import { ReactComponent as IconAtendeu } from '../../assets/images/icons/icon-discador-atendeu.svg';
import { ReactComponent as IconCancelarChamada } from '../../assets/images/icons/icon-discador-cancelar-chamada.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/icon-discador-check.svg';
import { ReactComponent as IconOcupado } from '../../assets/images/icons/icon-discador-ocupado.svg';
import { ReactComponent as IconError } from '../../assets/images/icons/cancel.svg';
import { genericPhoneFormatter } from '../../utils/textFormatter';
import Spinner from '../Spinner';

import './styles.scss';

import {
  atualizaChamada,
  encerraChamada,
} from '../../services/analiseMesaService';
import { notification } from 'antd';

function ModalDiscadorAgitel() {
  const {
    setChamada,
    setShowModalAgitel,
    showModalAgitel,
    telefone,
    tipoTelefone,
    chamadaId,

    setIsActive,

    setIsPaused,
    setTimer,
    increment,
    status,
    setStatus,
    timer,
  } = useContext(AnaliseMesaContext);

  const notificacaoConfirmada = (type) => {
    notification[type]({
      message: 'Chamada Confirmada',
      description: 'A chamada foi confirmada',
    });
  };

  const notificacaoAtendidaFechada = (type) => {
    notification[type]({
      message: 'Chamada Fechada',
      description: 'A chamada foi atendida porém fechada',
    });
  };

  const notificacaoCancelada = (type) => {
    notification[type]({
      message: 'Chamada Cancelada',
      description: 'A chamada foi cancelada',
    });
  };

  const notificacaoOcupada = (type) => {
    notification[type]({
      message: 'Chamada Ocupada',
      description: 'O telefone estava ocupado',
    });
  };

  function atendeuChamada() {
    setStatus('atendeu');
  }

  async function chamadaConfirmada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 0,
      confirmacao: true,
    };
    encerraChamada();
    await atualizaChamada(chamada, chamadaId).then((response) => {
      if (response.status === 400) {
        notificacaoCancelada('error');
        setChamada({
          historicoChamadaId: chamadaId,
          status: 1,
          confirmacao: false,
        });
      } else {
        notificacaoConfirmada('success');
        setChamada({
          historicoChamadaId: chamadaId,
          status: 0,
          confirmacao: true,
        });
      }
    });
    setShowModalAgitel(false);
    handleReset();
    setStatus('conexao');
  }

  async function chamadaOcupada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 2,
      confirmacao: false,
    };
    encerraChamada();
    await atualizaChamada(chamada, chamadaId).then(() => {
      notificacaoOcupada('warning');
      setChamada({
        historicoChamadaId: chamadaId,
        status: 2,
        confirmacao: false,
      });
    });
    setShowModalAgitel(false);
    handleReset();
    setStatus('conexao');
  }

  async function chamadaCancelada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 1,
      confirmacao: false,
    };
    encerraChamada();
    await atualizaChamada(chamada, chamadaId).then(() => {
      notificacaoCancelada('error');
      setChamada({
        historicoChamadaId: chamadaId,
        status: 1,
        confirmacao: false,
      });
    });

    setShowModalAgitel(false);
    handleReset();
    setStatus('conexao');
  }

  async function chamadaAtendidaFechada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 0,
      confirmacao: false,
    };
    encerraChamada();
    await atualizaChamada(chamada, chamadaId).then(() => {
      notificacaoAtendidaFechada('warning');
      setChamada({
        historicoChamadaId: chamadaId,
        status: 0,
        confirmacao: false,
      });
    });
    setShowModalAgitel(false);
    setStatus('conexao');
    handleReset();
  }

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(true);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <>
      {showModalAgitel && (
        <>
          {status !== '' && (
            <div
              className={
                'modal-discador ' + (status === 'erro-comunicacao' && 'bg-erro')
              }
            >
              {status === 'conexao' && (
                <div className="conexao">
                  <Spinner />
                </div>
              )}
              {(status === 'em-chamada' || status === 'atendeu') && (
                <div className="em-chamada">
                  <h4 className="tipo-telefone">{tipoTelefone}</h4>
                  <h5 className="numero-telefone">
                    {telefone && genericPhoneFormatter(telefone)}
                  </h5>
                  <p className="tempo-chamada">{formatTime()}</p>
                  {status === 'em-chamada' && (
                    <div className="btns-acoes">
                      <button
                        className="btn-atendeu"
                        onClick={() => atendeuChamada()}
                      >
                        <IconAtendeu />
                        <span>Atendeu</span>
                      </button>
                      <button
                        className="btn-ocupado"
                        onClick={() => chamadaOcupada(chamadaId)}
                      >
                        <IconOcupado />
                        <span>Ocupado</span>
                      </button>
                      <button
                        className="btn-cancelar"
                        onClick={() => chamadaCancelada(chamadaId)}
                      >
                        <IconCancelarChamada />
                        <span>Cancelar</span>
                      </button>
                    </div>
                  )}
                  {status === 'atendeu' && (
                    <div className="atendeu">
                      <h4 className="titulo">
                        <IconAtendeu />
                        Atendeu
                      </h4>
                      <button
                        className="btn-confirmacao"
                        onClick={() => chamadaConfirmada(chamadaId)}
                      >
                        <IconCheck /> Confirmação
                      </button>
                      <button
                        className="btn-fechar"
                        onClick={() => chamadaAtendidaFechada(chamadaId)}
                      >
                        Fechar
                      </button>
                    </div>
                  )}
                </div>
              )}
              {status === 'erro-comunicacao' && (
                <div className="erro-comunicacao">
                  <IconError />
                  <h4>Erro na comunicação com o sistema</h4>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ModalDiscadorAgitel;
