import moment from 'moment';
import { cpfFormatter, cnpjFormatter } from '../../../utils/textFormatter';
import { currencyFormat } from '../../../utils/numberFormatter';

const columns = [
  {
    Header: 'Cod. Operação',
    accessor: 'codigoOperacao',
    style: {
      minWidth: 130,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Pagamento',
    accessor: 'dataPagamento',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) =>
      value && (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY')}
        </div>
      ),
  },
  {
    Header: 'CNPJ / CPF',
    accessor: 'cnpjCpf',
    style: {
      minWidth: 130,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value.length > 11 ? cnpjFormatter(value) : cpfFormatter(value)}
      </div>
    ),
  },
  {
    Header: 'Cliente',
    accessor: 'cliente',
    style: {
      minWidth: 250,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{value}</div>
    ),
  },
  {
    Header: 'Valor da Operação',
    accessor: 'valorOperacao',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Valor Líquido',
    accessor: 'valorLiquido',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
    ),
  },

  {
    Header: 'Prazo',
    accessor: 'prazo',
    style: {
      minWidth: 80,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },

  {
    Header: 'Matriz',
    accessor: 'matriz',
    style: {
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Produto',
    accessor: 'produto',
    style: {
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'UF',
    accessor: 'uf',
    style: {
      minWidth: 50,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Comissão (%)',
    accessor: 'percentualComissao',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{`${value}%`}</div>
    ),
  },
  {
    Header: 'Comissão (R$)',
    accessor: 'valorComisssao',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Empresa',
    accessor: 'empresa',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Data Comissão',
    accessor: 'dataComissao',
    style: {
      minWidth: 130,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
];

export default columns;
