import React from 'react';
import NumberField from 'react-number-format';

function InputMoney({ ...props }) {
  return (
    <NumberField
      thousandSeparator="."
      decimalSeparator=","
      displayType="input"
      style={{ textAlign: 'right' }}
      {...props}
    />
  );
}

export default InputMoney;
