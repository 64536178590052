import React from 'react';

import { ReactComponent as IconInsert } from '../../assets/images/icons/insert.svg';
import './styles.scss';

function ListButton(props) {
  return (
    <button className="btn-list" {...props}>
      <IconInsert />
    </button>
  );
}

export default ListButton;
