import React, { useContext, useState, useEffect } from 'react';

import ArvorePosicoesHierarquia from './ArvorePosicoesHierarquia';
import HistoricoPosicao from './HistoricoPosicao';
import DetalhesPosicao from './DetalhesPosicao';

import HeaderPanelHierarquia from './HeaderPanelHierarquia';

import { ReactComponent as IconClose } from '../../../assets/images/icons/close-modal-X.svg';
import { ReactComponent as IconSave } from '../../../assets/images/icons/save-floppy-disk.svg';
import { ReactComponent as IconEditPen } from '../../../assets/images/icons/icon-editar.svg';
import { ReactComponent as IconVisualization } from '../../../assets/images/icons/error_contained.svg';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';
import Tooltip from '../../../components/tooltip';

const PanelHierarquia = ({
  hierarquia,
  indexHierarquia,
  editCurrentHierarquia,
}) => {
  const contexto = useContext(HierarquiasContext);
  const {
    checarPermissao,
    modalNotification,
    cancelModalNotification,
    activeButtonsAction,
    formDetalhesPosicao,
    validateAllDetalhesPosicao,
    editingHierarquia,
  } = contexto;

  const [visibleDetailsPosition, setVisibleDetailsPosition] = useState(false);
  const [detailsPositionId, setDetailsPositionId] = useState(null);
  const [detailsPositionNivelIndex, setDetailsPositionNivelIndex] =
    useState(null);

  const [visibleHistoryPosition, setVisibleHistoryPosition] = useState(false);
  const [historyPositionId, setHistoryPositionId] = useState(null);
  const [historyPositionNivelIndex, setHistoryPositionNivelIndex] =
    useState(null);
  const [shouldValidateForm, setShouldValidateForm] = useState();

  const [currentPositionActiveEvent, setCurrentPositionActiveEvent] =
    useState();

  function focusOnPosition(e, action = 'add') {
    if (action === 'add') {
      e?.target?.parentElement?.parentElement?.parentElement?.parentElement?.classList?.add(
        'ant-tree-treenode-selected',
      );
      e?.target?.closest('.ant-collapse-header')?.classList?.add('selected');
    } else {
      currentPositionActiveEvent?.target
        ?.closest('.ant-collapse-header')
        ?.classList?.remove('selected');
    }
  }

  const openDetailsPosition = (id, nivelIndex, e) => {
    setVisibleDetailsPosition(true);
    setDetailsPositionId(id);
    setDetailsPositionNivelIndex(nivelIndex);
    if (currentPositionActiveEvent) {
      focusOnPosition(currentPositionActiveEvent, 'remove'); // se já existir um evento anterior, remove o foco dele
    }
    setCurrentPositionActiveEvent(e);
    focusOnPosition(e);
  };

  const closeDetailsPosition = () => {
    setVisibleDetailsPosition(false);
    setDetailsPositionId(null);
    if (!historyPositionId)
      focusOnPosition(currentPositionActiveEvent, 'remove');
  };

  const openHistoryPosition = (id, nivelIndex, e) => {
    setVisibleHistoryPosition(true);
    setHistoryPositionId(id);
    setHistoryPositionNivelIndex(nivelIndex);
    if (currentPositionActiveEvent) {
      focusOnPosition(currentPositionActiveEvent, 'remove'); // se já existir um evento anterior, remove o foco dele
    }
    setCurrentPositionActiveEvent(e);
    focusOnPosition(e);
  };

  const closeHistoryPosition = async () => {
    setVisibleHistoryPosition(false);
    setHistoryPositionId();
    if (!detailsPositionId)
      focusOnPosition(currentPositionActiveEvent, 'remove');
  };

  const cancelEditHierarquia = () => {
    modalNotification(
      'warning',
      'Deseja descartar as alterações?',
      [
        { text: 'não', type: 'cancel', variant: 'red' },
        { text: 'sim', type: 'confirm', variant: 'blue' },
      ],
      () =>
        contexto.cancelEditHierarquia().then(() => {
          cancelModalNotification();
        }),
    );
  };

  useEffect(() => {
    if (!editCurrentHierarquia) {
      closeDetailsPosition();
      closeHistoryPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCurrentHierarquia]);

  return (
    <div className="container-panel-body">
      <HeaderPanelHierarquia
        hierarquia={hierarquia}
        editCurrentHierarquia={editCurrentHierarquia}
      />
      <div className="hierarquias-accordion-content">
        <ArvorePosicoesHierarquia
          hierarquia={hierarquia}
          editCurrentHierarquia={editCurrentHierarquia}
          openDetailsPosition={openDetailsPosition}
          openHistoryPosition={openHistoryPosition}
        />
        {visibleHistoryPosition && (
          <HistoricoPosicao
            positionId={historyPositionId}
            nivelIndex={historyPositionNivelIndex}
            hierarquiaId={hierarquia?.id}
            close={closeHistoryPosition}
          />
        )}
        {visibleDetailsPosition && (
          <DetalhesPosicao
            setShouldValidateForm={setShouldValidateForm}
            positionId={detailsPositionId}
            nivelIndex={detailsPositionNivelIndex}
            hierarquiaId={hierarquia?.id}
            perfis={hierarquia?.perfis}
            editCurrentHierarquia={editCurrentHierarquia}
            close={closeDetailsPosition}
          />
        )}

        {editCurrentHierarquia ? (
          <>
            <div className="action-buttons-container">
              <button
                type="button"
                onClick={() => cancelEditHierarquia()}
                className="close"
                disabled={!activeButtonsAction}
              >
                <IconClose />
              </button>
              <button
                type="button"
                className="save"
                disabled={
                  !checarPermissao(
                    'botao',
                    'botao.cadastro.hierarquia.salvar',
                    'Visualizar',
                  ) || !activeButtonsAction
                }
                onClick={async () => {
                  if (shouldValidateForm) {
                    const error =
                      validateAllDetalhesPosicao(formDetalhesPosicao);
                    if (!error) contexto.saveHierarquia();
                  } else {
                    contexto.saveHierarquia();
                  }
                }}
              >
                <IconSave />
              </button>
            </div>
            <div className="editing-label show-label">
              <IconEditPen />
              Editando
            </div>
          </>
        ) : (
          editingHierarquia && (
            <Tooltip
              placement="topLeft"
              trigger="hover"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              overlayClassName="tooltip-styled-crefaz"
              title="Salve a hierarquia anterior antes de realizar alterações nesta hierarquia"
              overlayStyle={{ minWidth: '489px' }}
            >
              <div className="visualizing-label show-label">
                <IconVisualization />
                Visualizando
              </div>
            </Tooltip>
          )
        )}
      </div>
    </div>
  );
};

export default PanelHierarquia;
