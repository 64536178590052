import { getToken } from "./authService";

let baseURL;

const prodUrl =
  window.location.origin.includes('prod') ||
  (window.location.origin.includes('crefazon') &&
    !window.location.origin.includes('dev') &&
    !window.location.origin.includes('stag'));

const stagUrl = window.location.origin.includes('stag');

if (prodUrl) {
  baseURL = ' wss://api-integracao.crefazon.com.br/ws';
} else if (stagUrl) {
  baseURL = ' wss://api-integracao-stag.crefazon.com.br/ws';
} else {
  baseURL = ' wss://api-integracao-dev.crefazon.com.br/ws';
}

const webSocketApi = ({propostaId, telefone}) => {
    const token = getToken();
    return new WebSocket(`${baseURL}/${propostaId}?tel=${telefone}&token=${token}`);
}

export default webSocketApi;
