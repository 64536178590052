import React, { useState, useEffect, useCallback } from 'react';
import { Tag } from 'antd';

import './styles.scss';

function TagComponent({ color, children, ...props }) {
  const [hue, setHue] = useState(0);
  const [sat, setSat] = useState(0);
  const [light, setLight] = useState(0);

  const hexToHSL = useCallback((color) => {
    const result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/.exec(
      color.trim(),
    );
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r /= 255;
    g /= 255;
    b /= 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);

    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
      }
      h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    setHue(h);
    setSat(s);
    setLight(l);

    return {
      h,
      s,
      l,
    };
  }, []);

  useEffect(() => {
    if (color) {
      hexToHSL(color);
    }
  }, [hexToHSL, color]);

  return (
    <Tag
      color={color}
      className="tag-color"
      style={{ '--hue': hue, '--sat': sat, '--light': light }}
      {...props}
    >
      {children}
    </Tag>
  );
}

export default TagComponent;
