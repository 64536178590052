import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';
import Atraso from '../../../components/Atraso';

export const columnsContratosRealizado = [
  {
    Header: 'Nº de contrato',
    accessor: 'nContrato',
  },
  {
    Header: 'Tipo produto',
    accessor: 'tipoProduto',
  },
  {
    Header: 'Data emissão',
    accessor: 'dataEmissao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Valor contrato',
    accessor: 'valorContrato',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Plano',
    accessor: 'plano',
  },
  {
    Header: 'Prestação',
    accessor: 'prestacao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Atraso',
    accessor: 'atraso',
    style: {
      minWidth: 170,
      width: 190,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{<Atraso value={value} />}</div>
    ),
  },
];
