import './style.scss';
import iconModalidadeDigital from '../../../assets/images/icone-modalidade-digital.svg';
import iconModalidadeFisica from '../../../assets/images/icone-modalidade-fisica.svg';

export const columnsPropostas = [
  {
    Header: 'ID Proposta',
    accessor: 'propostaId',
    Cell: ({ value }) => (
      <div style={{ fontSize: '13px'}}>{value}</div>
    ),
  },
  {
    Header: 'Produto',
    accessor: 'produto',
  },
  {
    Header: 'Modalidade',
    accessor: 'modalidade',
    Cell: ({ value }) => {
      return (
        <div style={{ textAlign: 'center', fontSize: '13px' }}>
          {/* eslint-disable-next-line */}
          {value == 'Físico' && (
            <img
              src={iconModalidadeFisica}
              alt="Ícone modalidade física"
              style={{ marginRight: '5px', fontSize: '15px', width: '15px', height: '15px' }}
            />
          )}
          {/* eslint-disable-next-line */}
          {value == 'Digital' && (
            <img
              src={iconModalidadeDigital}
              alt="Ícone modalidade digital"
              style={{ marginRight: '5px', fontSize: '13px'}}
            />
          )} {value}
        </div>
      );
    },
  },
  {
    Header: 'Última alteração',
    accessor: 'ultimaAlteracao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value}
      </div>
    ),
  },
  {
    Header: 'Valor contrato',
    accessor: 'valorContratado',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{value}</div>
    ),
  },
  {
    Header: 'Matriz',
    accessor: 'matriz',
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Agente',
    accessor: 'agente',
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Situação',
    accessor: 'situacao',
    style: {
      minWidth: 170,
      width: 190,
      textAlign: 'center',
    },
    Cell: ({ value }) => {
      return (
        <div
          style={{
            textAlign: 'center',
            textTransform: 'uppercase',
            borderRadius: '3px',
            backgroundColor: `${value.cor}`,
            cursor: 'pointer',
          }}
          onClick={() => {
            const originlUrl = window.document.location.origin;
            window.open(
              `${originlUrl}/analise-mesa-credito/${value.propostaId}`,
            );
          }}
        >
          <div className="title-situacao">{value.descricao}</div>
        </div>
      );
    },
  },
];
