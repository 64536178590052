import api from './api';

export const listarMotivosCompanhiaEletrica = async () => {
  const { data } = await api.get('/bloqueado/unidade-consumidora');
  return data;
};

export const listarUnidadeConsumidora = async ({
  filtroUnidadeConsumidora = null,
  filtroAtivos = false,
  ordenacao,
  ordenacaoAsc = false,
  pagina = 1,
}) => {
  const { data } = await api.post('/bloqueado/unidade-consumidora/lista', {
    filtroUnidadeConsumidora,
    filtroAtivos,
    ordenacao,
    ordenacaoAsc,
    pagina,
  });
  return data;
};

export const inserirUnidadeConsumidora = async (
  unidadeConsumidoraBlocklist,
) => {
  const dados = {
    unidadeConsumidora: unidadeConsumidoraBlocklist.unidadeConsumidora,
    ciaEletrica: unidadeConsumidoraBlocklist.ciaEletrica,
    motivoId: unidadeConsumidoraBlocklist.motivoId,
    descricao: unidadeConsumidoraBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('bloqueado/unidade-consumidora', dados);
  return data;
};

export const editarUnidadeConsumidora = async (unidadeConsumidoraBlocklist) => {
  const dados = {
    id: unidadeConsumidoraBlocklist.id,
    descricao: unidadeConsumidoraBlocklist.descricaoExclusao,
  };

  const { data } = await api.put(
    `bloqueado/unidade-consumidora/${dados.id}`,
    dados,
  );
  return data;
};
