import * as Yup from 'yup';

Yup.addMethod(Yup.mixed, 'percentualOuFixo', function (errorMessage) {
  return this.test(`percentualOuFixo`, errorMessage, function (value) {
    var percentualEmpty = true;
    var valorFixoEmpty = true;
    percentualEmpty =
      !this.parent.floatPercentual ||
      typeof this.parent.floatPercentual === 'undefined';
    valorFixoEmpty =
      !this.parent.floatValorFixo ||
      typeof this.parent.floatValorFixo === 'undefined';
    return !(percentualEmpty && valorFixoEmpty);
  });
});

const validationSchema = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  valorFixo: Yup.mixed().percentualOuFixo(
    'O valor fixo ou percentual devem ser preenchidos',
  ),
  percentual: Yup.mixed().percentualOuFixo(
    'O valor fixo ou percentual devem ser preenchidos',
  ),
  eventoId: Yup.mixed().required('o evento vinculado é obrigatório'),
  percentualBase: Yup.mixed().required('A base percentual é obrigatoria'),
});

export default validationSchema;
