import api from './api';

export const postConsultaGeraTokenPromobank = async ({
  gerarToken,
  usuarioId,
}) => {
  const data = await api.post('/usuario/promobank-consultar-gerar-token', {
    gerarToken,
    usuarioId,
  });

  return data.data;
};
