import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import { Tooltip, Input } from 'antd';

import SimpleInput from '../../../components/SimpleInput';
import SelectBancos from '../../../components/SelectBancos';
import SelectSearch from '../../../components/SearchSelect';
import CustomFormItem from '../../../components/CustomFormItem';

import InputMask from 'react-text-mask';
import { agenciaMask } from '../../../utils/masks';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const { Option } = Select;

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

function DadosBancariosForm({ formik, disabledFields }) {
  const { values, setFieldValue } = formik;

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const {
    bancos,
    contas,
    tempoConta,
    tipoConta,
    validationField,
    editableField,
  } = useContext(PropostaCreditoContext);


  const filteredTipos = tipoConta.filter((tipoConta) => {
    const bancoCodigo =
      values.bancario && bancos ? parseInt(values?.bancario?.bancoId) : -1;
    return (
      !tipoConta.bancos ||
      bancoCodigo === null ||
      bancoCodigo < 0 ||
      tipoConta.bancos.includes(bancoCodigo)
    );
  });

  const mascaraNumeroConta = (e) => {
    let unformatted = e.target.value.replace(/[^0-9Xx]/g, '').toUpperCase();
    let suffix = '';

    if (unformatted.length >= 2) {
      if (unformatted.lastIndexOf('X') >= unformatted.length - 2) {
        suffix = 'X';
        unformatted = unformatted.substr(0, unformatted.lastIndexOf('X'));
      }

      unformatted = unformatted.replace('X', '').concat(suffix);

      unformatted = unformatted
        .substr(0, unformatted.length - 1)
        .concat('-')
        .concat(unformatted.substr(unformatted.length - 1, 1));
    }
    e.target.value = unformatted;
    return unformatted;
  };

  function VerificaOperacao() {
    var retorno = '';
    if (bancos && values?.bancario) {
      var bancoId = parseInt(values?.bancario?.bancoId);
      if (bancoId === 1 && values?.bancario?.tipoConta === 1) {
        retorno = '051';
      } else if (bancoId === 104 && values?.bancario?.tipoConta === 0) {
        retorno = '001';
      } else if (
        bancoId === 104 &&
        values?.bancario?.tipoConta === 1 &&
        values?.bancario?.numero?.length >= 11
      ) {
        retorno = '1288';
      } else if (bancoId === 104 && values?.bancario?.tipoConta === 1) {
        retorno = '013';
      } else if (bancoId === 104 && values?.bancario?.tipoConta === 2) {
        retorno = '023';
      }
    }
    setFieldValue('bancario.tipoOperacao', retorno);
  }

  useEffect(() => {
    setFieldValue('bancario.bancoId', values?.bancario?.bancoId);
    VerificaOperacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.bancario?.bancoId,
    values?.bancario?.tipoConta,
    values?.bancario?.numero,
  ]);

  const maskDigitoAgenciaX = (e) => {
    let valueDigito = e.target.value.replace(/([^\d|X|x])+/gim, '');
    e.target.value = valueDigito;
    values.bancario.digito = valueDigito;
  };

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados Bancários do Cliente</div>
      </div>

      <div className="form-row dados-bancarios">
        <div
          className="col-md-4 col-lg-4 col-xl-3"
          style={{ height: formik?.errors?.bancario ? 'fit-content' : '70px' }}
        >
          <Form.Item
            name="bancario.bancoId"
            label="Banco"
            validate={(value) =>
              validationField(value, 'bancario.bancoId', 'Banco')
            }
            hasFeedback
          >
            <Field name="bancario.bancoId">
              {({ field }) => (
                <SelectBancos
                  getPopupContainer={(trigger) => trigger.parentNode}
                  {...field}
                  name="bancario.bancoId"
                  optionsBancos={{
                    bancosPrincipais: bancos?.listaBancosPrincipais,
                    bancos: bancos?.listaBancosSecundarios,
                  }}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  onChange={(value) => {
                    setFieldValue('bancario.bancoId', value);
                    values.bancario.bancoId = value;
                    VerificaOperacao();
                  }}
                  disabled={
                    (acesso ? !editableField('bancario.bancoId') : true)
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div
          className="col-md-4 col-lg-5 col-xl-3 col-input-agencia"
          style={{
            lineHeight: 1,
            height: formik?.errors?.bancario ? 'fit-content' : '70px',
          }}
        >
          <label>Agência</label>
          <TooltipInfo text="Cadastrar os dados bancários conforme o cartão do cliente, incluindo letra X quando houver."></TooltipInfo>
          <div className="form-row">
            <div className="col-9 col-md-8 col-lg-8 col-xl-8 pr-0">
              <CustomFormItem
                name="bancario.agencia"
                hasFeedback
                validate={(value) =>
                  validationField(value, 'bancario.agencia', 'Agência')
                }
                formik={formik}
                errorMessage="Obrigatório informar Agência"
                hasError={formik?.errors?.bancario?.agencia}
                isTouched={formik?.touched?.bancario?.agencia}
                maxLength={4}
                key="bancario.agencia"
                startingValue={formik?.values?.bancario?.agencia}
              >
                <InputMask
                  className="ant-input"
                  placeholder="0001"
                  disabled={
                    (acesso ? !editableField('bancario.agencia') : true)
                    || disabledFields
                  }
                  mask={agenciaMask}
                  showMask={false}
                />
              </CustomFormItem>
            </div>

            <div className="col-1 col-md-1 col-lg-1 col-xl-1 text-center">
              <p className="traco-agencia-digito">-</p>
            </div>

            <div
              className="col-2 col-md-3 col-lg-3 col-xl-3"
              style={{
                height: formik?.errors?.bancario ? 'fit-content' : '70px',
              }}
            >
              <Form.Item
                name="bancario.digito"
                validate={(value) =>
                  validationField(value, 'bancario.digito', 'Dígito')
                }
                hasFeedback
              >
                <Field name="bancario.digito">
                  {({ field }) => (
                    <SimpleInput
                      {...field}
                      placeholder="1"
                      disabled={
                        (acesso ? !editableField('bancario.digito') : true)
                        || disabledFields
                      }
                      onKeyUp={maskDigitoAgenciaX}
                      maxLength={1}
                      value={formik?.values?.bancario?.digito}
                      onBlur={(e) => {
                        setFieldValue('bancario.digito', e.target.value);
                      }}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
          </div>
        </div>

        <div
          className="col-md-4 col-lg-3 col-xl-2"
          style={{ height: formik?.errors?.bancario ? 'fit-content' : '70px' }}
        >
          <CustomFormItem
            name="bancario.numero"
            formik={formik}
            validate={(value) =>
              validationField(value, 'bancario.numero', 'Número da Conta')
            }
            hasError={formik?.errors?.bancario?.numero}
            isTouched={formik?.touched?.bancario?.numero}
            maskFunction={mascaraNumeroConta}
            errorMessage="Obrigatório informar Número da Conta"
            label="Nº da Conta + dígito"
            key="bancario.numero"
            startingValue={formik?.values?.bancario?.numero}
          >
            <Input
              placeholder="000001"
              disabled={
                (acesso ? !editableField('bancario.numero') : true)
                || disabledFields
              }
              maxLength={14}
            />
          </CustomFormItem>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="bancario.conta"
            label="Conta"
            validate={(value) =>
              validationField(value, 'bancario.conta', 'Conta')
            }
            hasFeedback
          >
            <Field name="bancario.conta">
              {({ field }) => (
                <SelectSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  {...field}
                  onChange={(value) => setFieldValue('bancario.conta', value)}
                  disabled={
                    (acesso ? !editableField('bancario.conta') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                >
                  {contas.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="bancario.tipoConta"
            label="Tipo de Conta"
            validate={(value) =>
              validationField(value, 'bancario.tipoConta', 'Tipo de Conta')
            }
            hasFeedback
          >
            <Field name="bancario.tipoConta">
              {({ field }) => (
                <SelectSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  {...field}
                  onChange={(value) =>
                    setFieldValue('bancario.tipoConta', value)
                  }
                  optionFilterProp="children"
                  showSearch
                  suffixIcon={<IconArrowSelect />}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso ? !editableField('bancario.tipoConta') : true)
                    || disabledFields
                  }
                >
                  {filteredTipos.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        {formik?.values?.bancario?.tipoOperacao && (
          <div className="col-md-3 col-lg-3 col-xl-2">
            <Form.Item
              name="bancario.tipoOperacao"
              label="Tipo de Operação"
              validate={(value) =>
                validationField(
                  value,
                  'bancario.tipoOperacao',
                  'Tipo de Operação',
                )
              }
              hasFeedback
            >
              <input
                disabled
                className="ant-input"
                name="bancario.tipoOperacao"
                value={formik?.values?.bancario?.tipoOperacao}
              />
            </Form.Item>
          </div>
        )}
        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="bancario.tempoConta"
            label="Tempo de Conta"
            validate={(value) =>
              validationField(value, 'bancario.tempoConta', 'Tempo de Conta')
            }
            hasFeedback
          >
            <Field name="bancario.tempoConta">
              {({ field }) => (
                <SelectSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  {...field}
                  onChange={(value) =>
                    setFieldValue('bancario.tempoConta', value)
                  }
                  disabled={
                    (acesso ? !editableField('bancario.tempoConta') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                >
                  {tempoConta.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosBancariosForm };
