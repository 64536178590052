import api from './api';

export const inserirCPF = async (cpfBlocklist) => {
  const dados = {
    cpf: cpfBlocklist.cpf,
    motivoId: parseInt(cpfBlocklist.motivoId),
    descricao: cpfBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('/bloqueado/cpf', dados);
  return data;
};

export const inserirUnidadeConsumidora = async (
  unidadeConsumidoraBlocklist,
) => {
  const dados = {
    unidadeConsumidora: unidadeConsumidoraBlocklist.unidadeConsumidora,
    ciaEletrica: unidadeConsumidoraBlocklist.ciaEletrica,
    motivoId: unidadeConsumidoraBlocklist.motivoId,
    descricao: unidadeConsumidoraBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('bloqueado/unidade-consumidora', dados);
  return data;
};

export const inserirTelefone = async (telefoneBlocklist) => {
  const dados = {
    telefone: telefoneBlocklist.telefone,
    motivoId: telefoneBlocklist.motivoId,
    tipo: telefoneBlocklist.tipo,
    descricao: telefoneBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('bloqueado/telefone', dados);
  return data;
};
