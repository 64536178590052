export const initialValues = {
  anexos: [],
  cnpj: '',
  nomeFantasia: '',
  funcionarios: 0,
  tipo: '',
  cep: '',
  logradouro: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidadeId: '',
  ufId: '',
  contato: '',
  telefone: '',
  celular: '',
  email: '',
  observacao: '',
  visita: true,
  dataVisita: '',
  horaVisita: '',
  contatoVisita: '',
};
