import * as Yup from 'yup';
import validaPhone from './validaPhone';

export default Yup.addMethod(
  Yup.string,
  'validatePhone',
  function (errorMessage) {
    return this.test(`test-phone`, errorMessage, function (value) {
      const { path, createError } = this;

      if (value) {
        return (
          validaPhone(value) || createError({ path, message: errorMessage })
        );
      } else {
        return true;
      }
    });
  },
);
Yup.addMethod(Yup.string, 'validateLandline', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      return (
        validaPhone(value, 'fixo') ||
        createError({ path, message: errorMessage })
      );
    } else {
      return true;
    }
  });
});
Yup.addMethod(Yup.string, 'validateCellphone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      return (
        validaPhone(value, 'celular') ||
        createError({ path, message: errorMessage })
      );
    } else {
      return true;
    }
  });
});
