import api from './api';

export const carregarHierarquias = async () => {
  const { data } = await api.get('/hierarquia');
  return data.data;
};

export const validarNomeHierarquias = async (name) => {
  const { data } = await api.get(`/hierarquia/valida-nome?nome=${name}`);
  return data.data;
};

export const carregarContextoHierarquias = async () => {
  const { data } = await api.get('/contextos/hierarquia');
  return data.data;
};

export const carregarArvoreHierarquia = async (id) => {
  const { data } = await api.get(`/hierarquia/${id}/arvore`);
  return data.data;
};

export const carregarPosicaoDetalhes = async (
  hierarquiaId,
  nivelId,
  posicaoId,
) => {
  const { data } = await api.get(
    `/hierarquia/${hierarquiaId}/nivel/${nivelId}/posicao/${posicaoId}/detalhes`,
  );
  return data.data;
};
export const carregarPosicaoHistorico = async (
  hierarquiaId,
  nivelId,
  posicaoId,
) => {
  const { data } = await api.get(
    `/hierarquia/${hierarquiaId}/nivel/${nivelId}/posicao/${posicaoId}/historico`,
  );
  return data.data;
};
export const carregarDependentesPosicao = async (
  hierarquiaId,
  nivelId,
  posicaoId,
) => {
  const { data } = await api.get(
    `/hierarquia/${hierarquiaId}/nivel/${nivelId}/posicao/${posicaoId}/dependentes`,
  );
  return data.data;
};

export const validarPerfil = async (body) => {
  const { data } = await api.post(`/hierarquia/valida-perfis`, body);
  return data.data;
};

export const validarVigencia = async (
  body,
  hierarquiaId,
  nivelId,
  posicaoId,
) => {
  const { data } = await api.post(
    `/hierarquia/${hierarquiaId}/nivel/${nivelId}/posicao/${posicaoId}/valida-vigencia`,
    body,
  );
  return data.data;
};

export const criarHierarquia = async (body) => {
  const { data } = await api.post('/hierarquia', body);
  return data.data;
};

export const editarHierarquia = async (id, body) => {
  const { data } = await api.put(`/hierarquia/${id}`, body);
  return data.data;
};
