import * as Yup from 'yup';
import { object, string, number } from 'yup';

export const validationForm = object({
  parametroGrupoId: number().moreThan(0).typeError('O grupo é obrigatório'),
  tipo: number().moreThan(0).typeError('O tipo é obrigatório'),
  codigo: string().required('O código é obrigatório'),
  nome: string().required('A descrição é obrigatória'),
  notaTecnica: string().notRequired(),
  validadeInicial: Yup.date().required('A validade inicial é obrigatória'),
  valor: string().required('O valor é obrigatório'),
});

export const validationGrupo = object({
  nome: string().required('O nome é obrigatório'),
});
