import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import BorderoProvider from '../../contexts/BorderoContext';

import Layout from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import Card from '../../components/Card';

import BorderoList from './BorderoList';

import useInspinia from '../../hooks/useInspinia';

function GerenciamentoBordero() {
  useInspinia();

  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Borderô</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <Card>
          <BorderoProvider>
            <BorderoList />
          </BorderoProvider>
        </Card>
      </Content>
    </Layout>
  );
}

export default GerenciamentoBordero;
