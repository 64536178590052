import axios from 'axios';
import Modal from '../components/Modal';
import { getToken } from './authService';
import { crivo } from './crivo';
import { rbm } from './rbm';

let baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use((config) => {
  const token = getToken();

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  async (response) => {
    const { data } = response.data;

    if (data?.integracaoCrivo) {
      const payloadCrivo = {
        ...data.integracaoCrivo,
        propostaId: data.id,
      };

      const conexaoCrivo = await crivo(payloadCrivo);

      if (conexaoCrivo) {
        if (conexaoCrivo.statusCode === 400) {
          Modal(
            '',
            conexaoCrivo.messages.join(' \n \n '),
            'warning',
            () => (window.location = '/acompanhamento-proposta'),
          );
          response.data.success = false;
          response.data.errors = [conexaoCrivo.messages.join(' \n \n ')];
        } else if (!conexaoCrivo?.data?.aprovado) {
          Modal(
            '',
            conexaoCrivo.data.mensagem,
            'warning',
            () => (window.location = '/acompanhamento-proposta'),
          );
          return {
            data: {
              ...conexaoCrivo.data,
            },
            success: false,
            reprovadoCrivo: true,
          };
        } else {
          response.data.data.respostaCrivo = conexaoCrivo.data;
        }
      }
    }

    if (data?.integracaoRBM) {
      const conexaoRBM = await rbm(data?.integracaoRBM);

      if (conexaoRBM) {
        response.data.data.conexaoRBM = conexaoRBM;
      }
    }

    if (data?.integracao?.RBM) {
      response.data.data.RBM = data.integracao.RBM.response;
      delete response.data.data.integracao.RBM;
    }

    return response;
  },
  (error) => {
    if (!!error) {
      Modal(
        '',
        'Ocorreu um erro ao processar os dados, tente novamente mais tarde.',
        'error',
        () => (window.location = '/'),
      );
    }

    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('authToken');
          localStorage.removeItem('login');
          localStorage.removeItem('userId');
          localStorage.removeItem('nome');
          localStorage.removeItem('telefonia');
          localStorage.removeItem('paginaAberta');
          localStorage.removeItem('telaAnterior');
          sessionStorage.clear();

          window.location = '/login';
          break;
        case 422:
          Modal(
            'Campos obrigatórios',
            error.response.data.errors.join(' \n \n '),
            'warning',
          );
          break;
        case 400:
          if (error.response.data.errors?.join(' \n \n ')) {
            Modal('', error.response.data.errors?.join(' \n \n '), 'warning');
          } else {
            Modal(
              '',
              'Ocorreu um erro ao processar os dados, tente novamente mais tarde.',
              'error',
            );
          }
          break;
        default:
          Modal(
            '',
            'Ocorreu um erro ao processar os dados, tente novamente mais tarde.',
            'error',
          );
      }
    }

    return Promise.reject(error);
  },
);

export default api;
