import moment from "moment";

export const columnsReconsulta = [
  {
    Header: 'Acionamento',
    accessor: 'acionamento',
    style: {
      minWidth: 80,
      width: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <>{moment(value).format('DD/MM/YYYY HH:mm')}</>
    ),
  },
  {
    Header: 'Login',
    accessor: 'login',
    style: {
      minWidth: 75,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    Cell: ({ value }) => (
      <div
        style={{
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </div>
    ),
  },
]
