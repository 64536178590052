import React, { useState, useEffect, useContext } from 'react';

import { Form, Input, Select, Field } from 'formik-antd';
import InputMask from 'react-text-mask';

import { cepMask } from '../../../../utils/masks';
import { CadastroPessoaContext } from '../../../../contexts/CadastroPessoaContext';
import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';
import SelectSearch from '../../../../components/SearchSelect';

import TooltipInfo from '../TooltipInfo';

const { Option } = Select;

function EnderecoForm({ formik }) {
  const { values, setFieldValue } = formik;
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cepInvalido, setCepInvalido] = useState(false);
  const [acesso, setAcesso] = useState(false);
  const { listarCidades, listarUfs, buscarEndereco } = useContext(
    CadastroPessoaContext,
  );

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao('aba', 'aba.cadastroPessoa.cliente.endereco', 'Editar'),
    );
  }, [checarPermissao]);

  const handleConsultaCEP = async (cep, setFieldTouched, setFieldValue) => {
    setCepInvalido(false);
    setFieldTouched('cep');

    if (cep) {
      cep = cep.replace(/\D/g, '');
      setCepInvalido(false);

      const data = await buscarEndereco(cep);

      if (data.data) {
        if (data.data.ufId > 0) {
          setFieldValue('endereco.ufid', data.data.ufId);
          setFieldValue('endereco.cidadeId', data.data.cidadeId);
          setFieldValue('endereco.logradouro', data.data.logradouro);
          setFieldValue('endereco.bairro', data.data.bairro);
        }
      } else {
        setFieldValue('endereco.ufid', '');
        setFieldValue('endereco.cidadeId', '');
        setFieldValue('endereco.logradouro', '');
        setFieldValue('endereco.bairro', '');
        setCepInvalido(true);
      }
    }
  };

  useEffect(() => {
    async function loadEstados() {
      const data = await listarUfs();
      if (data) {
        setEstados(data);
      }
    }

    loadEstados();
  }, [listarUfs]);

  useEffect(() => {
    async function loadCidades(ufId) {
      const data = await listarCidades(ufId);
      setCidades(data);
    }

    if (values?.endereco?.ufid) {
      loadCidades(values?.endereco?.ufid);
    }
  }, [values?.endereco?.ufid, listarCidades]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-2">
          <Form.Item name="endereco.cep" label="CEP" hasFeedback>
            <Field name="endereco.cep">
              {({ field, form }) => (
                <InputMask
                  {...field}
                  mask={cepMask}
                  placeholder="Ex.: 00.000-000"
                  className="ant-input"
                  autoComplete="cep"
                  data-cy="cep"
                  onBlur={(e) =>
                    handleConsultaCEP(
                      e.target.value,
                      form.setFieldTouched,
                      form.setFieldValue,
                    )
                  }
                  disabled={acesso}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="endereco.logradouro"
            hasFeedback
            label={
              <>
                <label>Logradouro</label>
                <TooltipInfo text="Rua / Avenida /..."></TooltipInfo>
              </>
            }
          >
            <Input
              name="endereco.logradouro"
              placeholder="Ex.: Rua, Avenida"
              data-cy="logradouro"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item name="endereco.numero" label="Número" hasFeedback>
            <Input
              name="endereco.numero"
              placeholder="Ex.: 603"
              data-cy="numero"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item name="endereco.ufid" label="UF" hasFeedback>
            <SelectSearch
              name="endereco.ufid"
              placeholder="ES"
              showSearch
              disabled={acesso ? true : values.endereco.CEP && !cepInvalido}
              onChange={(value) => setFieldValue('endereco.ufid', value)}
              value={values?.endereco?.ufid}
            >
              {estados?.map((estado) => (
                <Option key={estado.id} value={estado.id}>
                  {estado.uf}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item name="endereco.cidadeId" label="Cidade" hasFeedback>
            <SelectSearch
              id="cidade"
              name="endereco.cidadeId"
              placeholder="Vítoria"
              showSearch
              disabled={acesso ? true : values.endereco.CEP && !cepInvalido}
              onChange={(value) => setFieldValue('endereco.cidadeId', value)}
              value={values?.endereco?.cidadeId}
            >
              {cidades?.map((cidade) => (
                <Option key={cidade.id} value={cidade.id}>
                  {cidade.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-4">
          <Form.Item name="endereco.bairro" label="Bairro" hasFeedback>
            <Input
              name="endereco.bairro"
              placeholder="Ex.: Santa Lucia"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-8">
          <Form.Item name="endereco.complemento" label="Complemento">
            <Input
              name="endereco.complemento"
              placeholder="Ex.: Casa, AP, Bloco"
              data-cy="complemento"
              disabled={acesso}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default EnderecoForm;
