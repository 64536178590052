function validateCellPhone(cellNumber) {
  const phoneNumber = cellNumber.replace(/\D/g, '');

  const defaultCellNumber = phoneNumber.substring(0, 11);

  const ddds = JSON.parse(sessionStorage.getItem('ddds')) || [];
  const ddd = defaultCellNumber.substr(0, 2);

  const validateDDD = () => ddds?.find((item) => item.ddd === ddd);

  const re = /^(\d{2}9\d{8}|\d{2} 9\d{4} \d{4})$/;

  return validateDDD() && re.test(defaultCellNumber);
}

export default validateCellPhone;
