import React, { useEffect, useState } from 'react';
import { Modal, Checkbox } from 'antd';
import Button from '../Button';

import { useFormik, FormikProvider, Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import ModalAlerta from '../Modal';

import Overlay from '../Overlay';

import { genericPhoneFormatter, cpfFormatter } from '../../utils/textFormatter';

import { ReactComponent as SaveIcon } from '../../assets/images/icons/save.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';

import {
  inserirUnidadeConsumidora,
  inserirTelefone,
  inserirCPF,
} from '../../services/modalBlocklistService';

import './styles.scss';

const { Option } = Select;
const { TextArea } = Input;

const tipoTelefone = [null, 'Celular', 'Fixo'];

function ModalBlockList({
  isModalVisible,
  setIsModalVisible,
  dados,
  disabled = false,
}) {
  const [dadosBlocklist, setDadosBlocklist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkboxBlocklistCPF, setCheckboxBlocklistCPF] = useState(null);
  const [checkboxBlocklistUC, setCheckboxBlocklistUC] = useState([]);
  const [checkboxBlocklistTelefone, setCheckboxBlocklistTelefone] = useState(
    [],
  );
  const [statusErrorMotivo, setStatusErrorMotivo] = useState('');
  const [statusErrorCheckbox, setStatusErrorCheckbox] = useState(false);

  async function verificacaoBlocklist(data) {
    let retornosInsercaoBlocklist = [];

    if (checkboxBlocklistCPF !== null) {
      await inserirCPF({
        cpf: checkboxBlocklistCPF,
        motivoId: data.motivoId,
        descricaoInclusao: data.descricaoInclusao,
      }).then((result) => {
        retornosInsercaoBlocklist.push(result);
      });
    }

    if (checkboxBlocklistUC?.length > 0) {
      const unresolvedUC = checkboxBlocklistUC.map(async (item) => {
        await inserirUnidadeConsumidora({
          unidadeConsumidora: item.unidadeConsumidora,
          ciaEletrica: item.ciaEletrica,
          motivoId: data.motivoId,
          descricaoInclusao: data.descricaoInclusao,
        }).then((result) => {
          retornosInsercaoBlocklist.push(result);
        });
      });
      await Promise.all(unresolvedUC);
    }

    if (checkboxBlocklistTelefone?.length > 0) {
      const unresolvedTelefone = checkboxBlocklistTelefone.map(async (item) => {
        await inserirTelefone({
          telefone: item.telefone,
          tipo: item.tipo,
          motivoId: data.motivoId,
          descricaoInclusao: data.descricaoInclusao,
        }).then((result) => {
          retornosInsercaoBlocklist.push(result);
        });
      });
      await Promise.all(unresolvedTelefone);
    }

    return retornosInsercaoBlocklist;
  }

  const formik = useFormik({
    initialValues: {
      motivoId: null,
      descricaoInclusao: '',
      blocklist: null,
    },
    onSubmit: async (data, { resetForm }) => {
      if (
        checkboxBlocklistCPF !== null ||
        checkboxBlocklistUC?.length > 0 ||
        checkboxBlocklistTelefone?.length > 0
      ) {
        setLoading(true);

        await verificacaoBlocklist(data).then((response) => {
          if (response.find((retorno) => retorno.success)) {
            ModalAlerta('', 'Dado(s) bloqueado(s) com sucesso!', 'success');
          }
        });

        setCheckboxBlocklistCPF(null);
        setCheckboxBlocklistUC([]);
        setCheckboxBlocklistTelefone([]);
        setDadosBlocklist([]);
        setLoading(false);
        setIsModalVisible(false);

        resetForm();
      } else {
        setStatusErrorCheckbox(true);
      }
    },
  });
  const { touched, resetForm, setFieldValue } = formik;

  const handleCancel = () => {
    setIsModalVisible(false);
    setStatusErrorCheckbox(false);
    setCheckboxBlocklistCPF(null);
    setCheckboxBlocklistUC([]);
    setCheckboxBlocklistTelefone([]);
    setDadosBlocklist([]);
    resetForm();
    setFieldValue('motivoId', '');
    setFieldValue('descricao', '');
    setLoading(true);
  };

  useEffect(() => {
    setCheckboxBlocklistCPF(dados.cpfBloqueado ? null : dados.cpf);
    let checkboxCheckedUC = dados?.unidadesConsumidoras?.map((item) => {
      return {
        unidadeConsumidora: item.unidadeConsumidora,
        ciaEletrica: item.ciaEletrica,
      };
    });

    setCheckboxBlocklistUC(checkboxCheckedUC);

    let checkboxCheckedTelefone = dados?.telefones?.map((item) => {
      if (!item.telefoneBloqueado) {
        return { telefone: item.telefone, tipo: item.tipo };
      }else{
        return null;
      }
    }).filter((item) => !!item);

    setCheckboxBlocklistTelefone(checkboxCheckedTelefone);

    setStatusErrorCheckbox(false);
    setDadosBlocklist(dados);
    resetForm();
    setFieldValue('motivoId', '');
    setFieldValue('descricao', '');
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados]);

  const validaCheckbox = () => {
    if (
      checkboxBlocklistCPF === null &&
      checkboxBlocklistUC?.length === 0 &&
      checkboxBlocklistTelefone?.length === 0
    ) {
      setStatusErrorCheckbox(true);
    } else {
      setStatusErrorCheckbox(false);
    }
  };

  useEffect(() => {
    validaCheckbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxBlocklistCPF]);

  return (
    <>
      <Modal
        className="modal-blocklist"
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <h3>
          Blocklist
          <div className="line-title-blocklist"></div>
        </h3>

        <Overlay active={loading}>
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="inputs-checkbox">
                {dadosBlocklist.cpf && (
                  <div
                    className={'data-line '}
                    title={
                      dadosBlocklist?.cpfBloqueado
                        ? 'CPF já se encontra na blocklist'
                        : ''
                    }
                  >
                    <Checkbox
                      onChange={(e) => {
                        if (dadosBlocklist?.cpfBloqueado !== true) {
                          if (e.target.checked === true) {
                            setCheckboxBlocklistCPF(dadosBlocklist?.cpf);
                          } else {
                            setCheckboxBlocklistCPF(null);
                          }
                        }
                      }}
                      className="checkbox-input"
                      defaultChecked={
                        dadosBlocklist?.cpfBloqueado ? false : true
                      }
                      disabled={dadosBlocklist?.cpfBloqueado || disabled}
                    >
                      <p>CPF</p>
                      <span className="gray-line"></span>
                      <p className="data-info">
                        {cpfFormatter(dadosBlocklist?.cpf)}
                      </p>
                    </Checkbox>
                  </div>
                )}

                {dadosBlocklist?.telefones?.map((telefone, i) => (
                  <div
                    className={
                      'data-line ' + (telefone?.telefoneBloqueado && 'blocked')
                    }
                    title={
                      telefone?.telefoneBloqueado
                        ? 'Telefone já se encontra na blocklist'
                        : ''
                    }
                  >
                    {telefone.telefone && (
                      <Checkbox
                        onChange={(e) => {
                          if (telefone?.telefoneBloqueado !== true) {
                            let checkboxCheckedTelefone =
                              checkboxBlocklistTelefone;

                            if (e.target.checked === true) {
                              if (
                                checkboxCheckedTelefone
                                  .map((item) => item.telefone)
                                  .indexOf(telefone.telefone) === -1
                              ) {
                                checkboxBlocklistTelefone.push({
                                  telefone: telefone.telefone,
                                  tipo: telefone.tipo,
                                });
                                setCheckboxBlocklistTelefone(
                                  checkboxCheckedTelefone,
                                );
                              }
                            } else {
                              let indiceRemoveTelefone = checkboxCheckedTelefone
                                .map((item) => item.telefone)
                                .indexOf(telefone.telefone);
                              if (indiceRemoveTelefone >= 0) {
                                checkboxCheckedTelefone.splice(
                                  indiceRemoveTelefone,
                                  1,
                                );
                              }
                            }
                            validaCheckbox();
                          }
                        }}
                        className="checkbox-input"
                        defaultChecked={
                          telefone.telefoneBloqueado ? false : true
                        }
                        disabled={telefone?.telefoneBloqueado || disabled}
                      >
                        <p>Telefone: {tipoTelefone[telefone.tipo]}</p>
                        <span className="gray-line"></span>
                        <p className="data-info">
                          {genericPhoneFormatter(telefone.telefone)}
                        </p>
                      </Checkbox>
                    )}
                  </div>
                ))}

                {dadosBlocklist?.unidadesConsumidoras?.map((unidade, i) => (
                  <div
                    className={
                      'data-line ' +
                      (unidade?.UnidadeConsumidoraBloqueado && 'blocked')
                    }
                    title={
                      unidade?.UnidadeConsumidoraBloqueado
                        ? 'Unidade Consumidora já se encontra na blocklist'
                        : ''
                    }
                  >
                    <Checkbox
                      onChange={(e) => {
                        if (unidade?.UnidadeConsumidoraBloqueado !== true) {
                          let checkboxCheckedUC = checkboxBlocklistUC;

                          if (e.target.checked === true) {
                            if (
                              checkboxCheckedUC
                                .map((item) => item.unidadeConsumidora)
                                .indexOf(unidade.unidadeConsumidora) === -1
                            ) {
                              checkboxBlocklistUC.push({
                                unidadeConsumidora: unidade.unidadeConsumidora,
                                ciaEletrica: unidade.ciaEletrica,
                              });
                              setCheckboxBlocklistUC(checkboxCheckedUC);
                            }
                          } else {
                            let indiceRemoveUC = checkboxCheckedUC
                              .map(
                                (item) =>
                                  item.ciaEletrica === unidade.ciaEletrica &&
                                  item.unidadeConsumidora,
                              )
                              .indexOf(unidade.unidadeConsumidora);
                            if (indiceRemoveUC >= 0) {
                              checkboxCheckedUC.splice(indiceRemoveUC, 1);
                            }
                          }
                          validaCheckbox();
                        }
                      }}
                      className="checkbox-input"
                      defaultChecked={
                        unidade?.UnidadeConsumidoraBloqueado ? false : true
                      }
                      disabled={
                        unidade?.UnidadeConsumidoraBloqueado || disabled
                      }
                    >
                      <p>UC</p>
                      <span className="gray-line"></span>
                      <p className="data-info">{unidade.unidadeConsumidora}</p>
                    </Checkbox>
                  </div>
                ))}
                {statusErrorCheckbox && (
                  <p className="error-checkbox">
                    Selecione pelo menos um dado para ser bloqueado.
                  </p>
                )}
              </div>
              <div className="data-text">
                <Form.Item
                  name="motivoId"
                  validate={(value) => {
                    let msgError = null;

                    if (value === '' || value === null) {
                      msgError = 'Campo obrigatório';
                      setStatusErrorMotivo('error');
                    } else {
                      setStatusErrorMotivo('');
                    }

                    return msgError;
                  }}
                  validateStatus={touched.motivoId && statusErrorMotivo}
                  hasFeedback
                  label="Motivo"
                >
                  <Select
                    name="motivoId"
                    placeholder="Selecione"
                    suffixIcon={<IconArrowSelect />}
                    disabled={disabled}
                  >
                    {dadosBlocklist?.motivo?.map((motivo) => (
                      <Option value={motivo.id} key={motivo.id}>
                        {motivo.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="data-text">
                <Form.Item
                  name="descricaoInclusao"
                  label="Observação"
                  validate={(value) => {
                    let msgError = null;

                    if (value === '' || value === null) {
                      msgError = 'Campo obrigatório';
                    } 

                    return msgError;
                  }}
                >
                  <Field name="descricaoInclusao">
                    {({ field }) => (
                      <TextArea name="descricaoInclusao" disabled={disabled} />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="align-right botoes-acao">
                <Button
                  onClick={() => {
                    handleCancel();
                    resetForm();
                  }}
                  variant="gray"
                  className="btn-xs-block"
                >
                  <span>CANCELAR</span>
                </Button>
                <span className="p-1"></span>
                <Button
                  type="submit"
                  onClick={() => {
                    validaCheckbox();
                  }}
                  variant="green"
                  className="btn-xs-block"
                  disabled={disabled}
                >
                  <SaveIcon style={{ marginRight: 10 }} />
                  <span>SALVAR</span>
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </Overlay>
      </Modal>
    </>
  );
}

export default ModalBlockList;
