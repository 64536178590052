import React, { useContext } from 'react';

import { ProcessamentoCrivoContext } from '../../../contexts/ProcessamentoCrivoContext';

import ProdutoDriver from './ProdutoDriver';

import './styles.scss';

function RelatorioDrivers() {
  const { drivers } = useContext(ProcessamentoCrivoContext);

  return (
    <div className="RelatorioDrivers">
      {drivers.map((driver) => (
        <div className="driver">
          <div className="title">Driver - {driver.nomeDriver}</div>

          {driver.produtosDriver.map((produto) => (
            <ProdutoDriver produto={produto} />
          ))}
        </div>
      ))}
    </div>
  );
}

export default RelatorioDrivers;
