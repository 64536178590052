import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';
import Tag from '../../../components/Tag';

export const columnsContratos = [
  {
    Header: 'Contrato',
    accessor: 'contrato',
  },
  {
    Header: 'Produto',
    accessor: 'produtoNome',
  },
  {
    Header: 'Emissão',
    accessor: 'emissao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'valorContrato',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Plano',
    accessor: 'plano',
  },
  {
    Header: 'Prestação',
    accessor: 'prestacao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Atraso',
    accessor: 'statusNome',
    style: {
      minWidth: 170,
      width: 190,
      textAlign: 'center',
    },
    Cell: ({ value, row }) => (
      <div style={{ textAlign: 'center' }}>
        {<Tag color={row.original.statusCor}>{value}</Tag>}
      </div>
    ),
  },
];
