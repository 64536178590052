import moment from 'moment';
import { ReactComponent as PrintIcon } from '../../../assets/images/icons/print.svg';

const columns = [
  {
    Header: 'Data/Hora de inserção',
    accessor: 'dataInsercao',
    style: {
      minWidth: 130,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: 'Nome do Arquivo',
    accessor: 'nomeArquivo',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Matriz',
    accessor: 'matriz',
    style: {
      minWidth: 130,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: '',
    accessor: 'download',
    style: {
      textAlign: 'center',
    },
    disableSortBy: true,
    Cell: ({ value }) => <PrintIcon width="15" height="15" />,
  },
];

export default columns;
