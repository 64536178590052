import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Input } from 'formik-antd';
import Option from '../../../components/Option';
import { Field, Formik } from 'formik';
import Button from '../../../components/Button';
import Tooltip from '../../../components/tooltip';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen2.svg';
import { ReactComponent as IconEye } from '../../../assets/images/icons/icon-upload-eye.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconTree } from '../../../assets/images/icons/icon-tree.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../../assets/images/icons/erase.svg';
import ModalUnidades from '../ModalUnidadesSubunidades/index';

import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';

import 'antd/dist/antd.css';
import { Tree } from 'antd';
import { SubTipoConvenio, TipoCredenciado } from '../../../utils/identificadoresFrontBack';
const { TreeNode } = Tree;

function TooltipInfo({ data }) {
  return (
    <Tooltip
      placement="topLeft"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={
        <div>
          {(data?.dadosGerais?.cpfCnpj || data?.cpfCnpj) && (
            <p className="cpf-cnpj-tooltip-unidade">
              <strong>CPF/CNPJ:</strong>{' '}
              {data.dadosGerais?.cpfCnpj ?? data?.cpfCnpj}
            </p>
          )}
          <p className="ativo-tooltip-unidade">
            <strong>Ativo:</strong> {data?.ativo ? 'Sim' : 'Não'}
          </p>
        </div>
      }
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

function SubTitleUnidades() {
  return (
    <div className="col-lg-10 col-sub-title">
      <div>
        <IconTree />
      </div>
      <div className="sub-title-form">Unidades e Subunidade</div>
    </div>
  );
}

function UnidadesForm({
  formik,
  produtosRegiao,
  isReadOnly,
  setRegiao,
  tiposModalidade,
}) {
  const initialValues = {
    filtroCPFCNPJ: null,
    filtroNome: null,
    filtroAtivo: null,
  };
  const {
    fetchUnidadesInfo,
    unidades,
    setUnidadeId,
    typeModal,
    setTypeModal,
    unidadeInfos,
    setUnidadeInfos,
    visibleModalUnidades,
    setVisibleModalUnidades,
    findUnidade,
    unidadesFiltradas,
    setUnidadesFiltradas,
    handlePesquisar,
    credenciadoArvore,
    actionGetHierarquia,
    setEnviaDadosUnidades,
  } = useContext(CredenciadoContext);

  const { values } = formik;

  const [showFilter, setShowFilter] = useState(true);
  const [titleModal, setTitleModal] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState();

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    actionGetHierarquia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = (id = null, type, level = 1) => {
    setVisibleModalUnidades(true);
    // eslint-disable-next-line
    setTitleModal(level == 1 ? 'Unidade' : 'Subunidade');
    setTypeModal(type);
    setUnidadeId(id);
    if (id) {
      // eslint-disable-next-line
      if (type == 'add') {
        setUnidadeInfos({});
      } else {
        var unidade = findUnidade(unidades, id);
        if (unidade && !unidade?.visited) {
          fetchUnidadesInfo(id).then((response) => {
            response.dadosGerais.ativo = unidade.ativo; // atualiza o valor de ativo conforme ações em tela
            let credenciadoArvoreAuxIndex = credenciadoArvore.findIndex(
              (item) => item.id === id,
            );
            setUnidadesFiltradas(
              unidadesFiltradas.map((unidade) => {
                // eslint-disable-next-line
                if (unidade.id == id) {
                  let newObject = {
                    ...unidade,
                    ...response,
                    visited: true,
                  };
                  credenciadoArvore[credenciadoArvoreAuxIndex] = newObject;
                  return newObject;
                } else {
                  return unidade;
                }
              }),
            );
            setUnidadeInfos({
              ...response,
              subUnidade: unidade.subUnidade,
            });
          });
        } else if (unidade && unidade?.visited) {
          if (unidade?.dadosGerais) unidade.dadosGerais.ativo = unidade.ativo;
          setUnidadeInfos(unidade);
        } else {
          setUnidadeInfos({});
        }
      }
    } else {
      setUnidadeInfos({});
    }
  };

  function mountChildren(lista, level = 1) {
    return lista?.map((item) => (
      <TreeNode
        title={
          <span
            className="info-tree"
            id={`info-tree-unidades-${item?.id ? item?.id : '-1'}`}
          >
            {item?.nome ? item?.nome : <>&nbsp;</>}
            <div className="col-icons">
              <TooltipInfo data={item} />
              <IconEye
                onClick={() => {
                  showModal(item.id, 'read', level);
                }}
              />
              {!isReadOnly && (
                  <IconEdit
                    onClick={() => {
                      showModal(item.id, 'edit', level);
                    }}
                  />
              )}
              {(!isReadOnly &&
                !(values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
                  values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID)
              )
              && (
                 <IconPlusBtn
                 onClick={() => {
                  setEnviaDadosUnidades(true)
                   showModal(item.id, 'add', level + 1);
                 }}
               />
              )}

            </div>
          </span>
        }
        key={`${level}-sublevel-${item?.id}`}
      >
        {item?.subUnidade?.length > 0
          ? mountChildren(item?.subUnidade, level + 1)
          : ''}
      </TreeNode>
    ));
  }

  const handleCpfCnpj = (e) => {
    let data = e.target.value.replace(/\D/g, ''); // apenas dígitos
    if (data.length > 11) {
      // CNPJ
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3,
      )}/`; // ainda exibe máscara de CPF
      if (data.length > 12)
        // exibe máscara CNPJ
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      else cnpj += data.substr(8);
      data = cnpj;
    } else {
      // CPF
      let cpf = '';
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
      }
      data = cpf;
    }
    setCpfCnpj(data);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-tab-unidades">
          <div className="col-lg-12 col-header">
            {!showFilter && <SubTitleUnidades />}

            <div
              className={
                'col-button ' + (showFilter ? 'col-lg-12' : 'col-lg-2')
              }
            >
              <Button
                onClick={handleShowFilter}
                type="button"
                variant="gray"
                className="btn-filtrar"
              >
                <FilterIcon />
                FILTRAR
              </Button>
            </div>
          </div>
          {showFilter && (
            <div className="row">
              <div className="col-lg-12">
                <div className="box-filtros">
                  <Formik initialValues={initialValues}>
                    {({ values, resetForm }) => (
                      <Form layout="vertical">
                        <div className="filter-wrapper">
                          <div className="col-itens-filter">
                            <div className="form-row">
                              <div className="col-sm-3 item-filter">
                                <Form.Item
                                  label="CPF/CNPJ"
                                  name="filtroCPFCNPJ"
                                >
                                  <Field name="filtroCPFCNPJ">
                                    {({ field }) => (
                                      <Input
                                        {...field}
                                        placeholder="000.000.000-00"
                                        className="ant-input"
                                        value={cpfCnpj}
                                        onChange={(e) => {
                                          handleCpfCnpj(e);
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Form.Item>
                              </div>

                              <div className="item-filter">
                                <Form.Item label="Nome" name="filtroNome">
                                  <Input
                                    type="text"
                                    name="filtroNome"
                                    placeholder="Digite"
                                  />
                                </Form.Item>
                              </div>

                              <div className="item-filter">
                                <Form.Item label="Ativo" name="filtroAtivo">
                                  <Select
                                    name="filtroAtivo"
                                    placeholder="Selecione"
                                    suffixIcon={<IconArrowSelect />}
                                    allowClear
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                  >
                                    <Option key={1} value={true}>
                                      Ativo
                                    </Option>
                                    <Option key={2} value={false}>
                                      Inativo
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                          <div className="col-buttons">
                            <Button
                              type="button"
                              className="btn-pesquisar"
                              variant="blue"
                              onClick={() => {
                                handlePesquisar(values, 'btn');
                              }}
                            >
                              <IconSearch />
                              Pesquisar
                            </Button>
                            <Button
                              type="button"
                              className="btn-limpar"
                              variant="gray"
                              async={true}
                              onClick={() => {
                                resetForm();
                                setCpfCnpj('');
                                setUnidadesFiltradas([]);
                              }}
                            >
                              <IconErase />
                              Limpar
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-12 col-header">
            {showFilter && <SubTitleUnidades />}
          </div>
          <div className="col-tree">
            <Tree showLine={false} className="hide-file-icon">
              {mountChildren(unidadesFiltradas)}
            </Tree>
          </div>

          <div className="row">
            {!isReadOnly && (
              <div className="col-lg-3 col-button">
                <Button
                  type="button"
                  className="btn-unidade"
                  variant="blue"
                  onClick={() => {
                    showModal(null, 'add');
                  }}
                >
                  <IconPlusBtn />
                  UNIDADE
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {visibleModalUnidades && (
        <ModalUnidades
          title={titleModal}
          type={typeModal}
          tipo={formik.values.dadosGerais.tipo}
          subtipo={formik.values.dadosGerais.subTipo}
          produtosRegiao={produtosRegiao}
          unidadeInfos={unidadeInfos}
          regioesCredenciado={formik.values.regioes}
          tiposModalidade={tiposModalidade}
          nomeMatriz={formik?.values?.dadosGerais?.nome}
        />
      )}
    </>
  );
}

export default UnidadesForm;
