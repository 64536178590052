import React, { useEffect, useContext, useState } from 'react';

import { cpfFormatter } from '../../../utils/textFormatter';
import moment from 'moment';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';

import { ReactComponent as IconClose } from '../../../assets/images/icons/close-modal-X.svg';

const HistoricoPosicoes = ({ positionId, nivelIndex, hierarquiaId, close }) => {
  const contexto = useContext(HierarquiasContext);

  const [positionHistory, setPositionHistory] = useState(null);

  useEffect(() => {
    contexto
      .carregaHistoricoPosicaoHierarquia(positionId, nivelIndex, hierarquiaId)
      .then((response) => {
        if (response?.historico) {
          setPositionHistory(response);
        }
      })
      .catch(() => {
        close();
        setPositionHistory(null);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionId]);

  return (
    <>
      {positionId !== null &&
        positionHistory &&
        Object.keys(positionHistory)?.length > 0 && (
          <div
            className={
              contexto.editingHierarquia
                ? 'card-historico-editing'
                : 'card-historico'
            }
          >
            <div className="card-historico-head">
              <div className="card-historico-title">
                <h3>Histórico {positionHistory?.nome}</h3>
                <button onClick={close}>
                  <IconClose />
                </button>
              </div>
              {positionHistory?.historico
                ?.filter((responsavel) => !responsavel?.vigenciaFinal)
                ?.map((item, index) => (
                  <div
                    className="card-historico-details"
                    key={`card-historico-details-${index}`}
                  >
                    <p>
                      <span>Responsável: </span>
                      {item?.nome}
                    </p>
                    <p>
                      <span>Início: </span>
                      {moment(item?.vigenciaInicial).format('DD/MM/YYYY')}
                    </p>
                  </div>
                ))}
            </div>
            {positionHistory?.historico
              ?.filter((responsavel) => !!responsavel?.vigenciaFinal)
              ?.map((item, index) => (
                <div
                  className="card-historico-info"
                  key={`card-historico-info-${index}`}
                >
                  <p>
                    <span>Responsável: </span>
                    {item?.nome}
                  </p>
                  {item?.cpf && (
                    <p>
                      <span>CPF: </span>
                      {cpfFormatter(item?.cpf)}
                    </p>
                  )}
                  <p>
                    <span>Início: </span>
                    {moment(item?.vigenciaInicial).format('DD/MM/YYYY')}
                  </p>
                  <p>
                    <span>Fim: </span>
                    {moment(item?.vigenciaFinal).format('DD/MM/YYYY')}
                  </p>
                </div>
              ))}
          </div>
        )}
    </>
  );
};

export default HistoricoPosicoes;
