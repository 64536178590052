import React, { useContext, useEffect, useState } from 'react';

import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import swal from 'sweetalert';

import { FilaContext } from '../../../contexts/FilaContext';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/SelectTable';
import Button from '../../../components/Button';

import { columns } from './columns';

import './styles.scss';
import { redistribuirPropostas } from '../../../services/FilaService';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const Header = ({ tempFilter, handleChange, onClick, disabled }) => {

  const { checarPermissao } = useContext(ControleAcessoContext);
  const acessoBotaoRedistribuir = checarPermissao('botao','botao.credito.proposta.redistribuicao','Visualizar');

  return (
  
    <div className="row">
      <div className="col header-title">
        <Title label="Listagem de propostas" />
      </div>
  
      <div className="col-lg-6 header-actions">
        <div className="d-flex justify-content-end">
          <Input
            value={tempFilter}
            placeholder="Pesquisar por CPF, Nome do Analista ou Nº da Proposta..."
            style={{ width: '100%' }}
            suffix={<SearchOutlined />}
            onChange={handleChange}
          />
  
          <span className="p-1"></span>
  
          <Button
            type="button"
            variant="blue"
            onClick={() => onClick()}
            disabled={disabled || !acessoBotaoRedistribuir}
          >
            <span>Redistribuir</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

function FilaList() {
  const {
    propostas,
    selectedRows,
    setSelectedRows,
    disabled,
    fetchPropostas,
    total,
    pageCount,
    loading,
    setFiltroDinamico,
  } = useContext(FilaContext);

  const [tempFilter, setTempFilter] = useState('');

  const handleClick = () => {
    var arrayPropostas = [];

    selectedRows.forEach((item) => {
      arrayPropostas.push(item.cells[1].value);
    });

    var objPropostas = {
      propostas: arrayPropostas,
    };

    swal({
      text: 'Deseja redistribuir as propostas selecionadas',
      icon: 'warning',
      buttons: ['Cancelar', true],
    }).then(async (willDelete) => {
      if (willDelete) {
        await redistribuirPropostas(objPropostas);

        await fetchPropostas();
        swal({
          text: 'Tudo certo! Propostas redistribuídas!',
          icon: 'success',
        });
      } else {
        return false;
      }
    });
  };

  const handleChange = (e) => {
    setTempFilter(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFiltroDinamico(tempFilter);
    }, 1000);

    return () => clearTimeout(timer);
  }, [tempFilter, setFiltroDinamico]);

  return (
    <Card>
      <div className="tabela-fila-credito">
        <Table
          data={propostas}
          onLoadData={fetchPropostas}
          columns={columns}
          total={total}
          pageCount={pageCount}
          loading={loading}
          pagination
          header={
            <Header
              tempFilter={tempFilter}
              handleChange={handleChange}
              onClick={handleClick}
              disabled={disabled}
            />
          }
          setSelectedRows={setSelectedRows}
        />
      </div>
    </Card>
  );
}

export default FilaList;
