import React, { useContext, useState } from 'react';

import { Button, Input, Select } from 'antd';

import { ReactComponent as IconArrowSelectRounded } from '../../../assets/images/icons/arrow-down-select-rounded.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconTrash } from '../../../assets/images/icons/icon-trash3.svg';
import { ReactComponent as IconEditPen } from '../../../assets/images/icons/icon-editar.svg';
import { ReactComponent as IconPlusPerfilBtn } from '../../../assets/images/icons/add4.svg';
import { ReactComponent as IconX } from '../../../assets/images/x-icon2.svg';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';

const { Option } = Select;

const HeaderPanelHierarquia = ({ hierarquia, editCurrentHierarquia }) => {
  const contexto = useContext(HierarquiasContext);
  const {
    checarPermissao,
    optionsPerfisHierarquia,
    coresNiveisHierarquia,
    addPerfilHierarquia,
    activeEditHierarquia,
    modalNotification,
    searchHierarquiaById,
    getQuantidadeDeNiveis,
    editHierarquiaId,
  } = contexto;

  const perfis = hierarquia?.perfis;
  const hierarquiaId = hierarquia?.id;
  const niveis = hierarquia?.hierarquiaNivel;

  const [selectPerfilNivel, setSelectPerfilNivel] = useState('Níveis');

  const [addingNivel, setAddingNivel] = useState(false);

  const [editingNivel, setEditingNivel] = useState(null);
  const [newNameNivel, setNewNameNivel] = useState(null);

  const addNivelHierarquia = () => {
    activeEditHierarquia(hierarquiaId);
    setAddingNivel(true);

    contexto.addNivelHierarquia(hierarquiaId).then(() => {
      let indexNivelNew = niveis?.length - 1;
      setNewNameNivel('');
      setEditingNivel(indexNivelNew);
      document
        .getElementById(
          `input-edit-nivel-hierarquico-${hierarquiaId}-index-${indexNivelNew}`,
        )
        ?.focus();
    });
    setAddingNivel(false);
  };

  const removeNivelHierarquia = (index) => {
    const hierarquia = searchHierarquiaById(hierarquiaId);
    const quantidadeDeNiveisVinculados = getQuantidadeDeNiveis(hierarquia);
    const posicaoUltimoNivel = niveis.length - 1;
    if (
      index === posicaoUltimoNivel &&
      niveis.length > quantidadeDeNiveisVinculados
    ) {
      // Remove o nível somente se não tiver vinculado a nenhuma posição e se for o último nível.
      contexto.removeNivelHierarquia(index, hierarquiaId).then(() => {
        setNewNameNivel(null);
        setEditingNivel(null);
      });
    }
  };

  const editNivelHierarquia = (name, index) => {
    contexto.editNivelHierarquia(name, index, hierarquiaId);
    setEditingNivel(null);
    setNewNameNivel(null);
    setAddingNivel(false);
  };

  const removePerfilHierarquia = (perfilIdRemoved) => {
    let perfisValidate = JSON.parse(JSON.stringify(perfis));

    perfisValidate = perfisValidate?.filter(
      (perfilId) => perfilId !== perfilIdRemoved,
    );

    contexto
      .validateRemovePerfilHierarquia(perfisValidate, hierarquiaId)
      .then((response) => {
        if (!response?.length) {
          contexto.removePerfilHierarquia(perfilIdRemoved, hierarquiaId);
        } else {
          modalNotification('warning', warningRemovePerfilHierarquia(response));
        }
      });
  };

  function warningRemovePerfilHierarquia(userList) {
    return (
      <p>
        O perfil não pode ser excluído, pois existem usuários com esse perfil
        associados à hierarquia. Para excluir o perfil é necessário encerrar a
        vigência dos usuários associados as seguintes posições: <br />
        <br />
        {userList?.map((item) => (
          <div style={{ textAlign: 'left' }}>
            {item.nome} - {item.posicao}
          </div>
        ))}
      </p>
    );
  }

  return (
    <div
      id="cabecalho-dinamico-hierarquia"
      className="cabecalho-dinamico-hierarquia"
    >
      <Select
        value={selectPerfilNivel}
        className="cabecalho-dinamico-hierarquia-select"
        onSelect={(value) => {
          setSelectPerfilNivel(value);
        }}
        suffixIcon={<IconArrowSelect />}
      >
        <Option value={selectPerfilNivel === 'Níveis' ? 'Perfis' : 'Níveis'}>
          {selectPerfilNivel === 'Níveis' ? 'Perfis' : 'Níveis'}
        </Option>
      </Select>
      {selectPerfilNivel === 'Níveis' ? (
        <>
          {niveis?.map((item, index) => {
            return (
              <div
                key={'nivel-hierarquivo-index-' + index}
                className={`nivel-hierarquico ${
                  // eslint-disable-next-line
                  index == editingNivel && 'editing-nivel'
                }`}
              >
                <div
                  className="line-nivel"
                  style={{
                    backgroundColor:
                      coresNiveisHierarquia?.niveis?.[index]?.cor ||
                      coresNiveisHierarquia.default,
                  }}
                ></div>
                <div
                  className="number"
                  style={{
                    backgroundColor:
                      coresNiveisHierarquia?.niveis?.[index]?.cor ||
                      coresNiveisHierarquia.default,
                  }}
                >
                  {index + 1}º
                </div>

                <Input
                  className="text-input"
                  id={`input-edit-nivel-hierarquico-${hierarquiaId}-index-${index}`}
                  readOnly={editingNivel !== index}
                  onBlur={() => editNivelHierarquia(newNameNivel, index)}
                  onChange={(e) => setNewNameNivel(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === 'Escape') {
                      editNivelHierarquia(newNameNivel, index);
                    }
                  }}
                  value={
                    editingNivel === index && newNameNivel !== null
                      ? newNameNivel
                      : item?.nome
                  }
                  placeholder="Digite o nome do nível"
                />
                {checarPermissao(
                  'botao',
                  'botao.cadastro.hierarquia.editar',
                  'Visualizar') &&
                  editingNivel !== index && (
                  <Button
                    className="edit-btn"
                    disabled={
                      !!editHierarquiaId && editHierarquiaId !== hierarquiaId
                    }
                    onClick={() => {
                      activeEditHierarquia(hierarquiaId);
                      setNewNameNivel(item?.nome);
                      setEditingNivel(index);
                      document
                        .getElementById(
                          `input-edit-nivel-hierarquico-${hierarquiaId}-index-${index}`,
                        )
                        ?.focus();
                    }}
                  >
                    <IconEditPen />
                  </Button>
                )}
                {!!editingNivel && index === niveis?.length - 1 && (
                  // Apenas os últimos níveis hierárquicos podem ser removidos/apagados
                  <Button
                    className="cancel-btn"
                    onMouseDown={() => removeNivelHierarquia(index)}
                  >
                    <IconTrash />
                  </Button>
                )}
              </div>
            );
          })}
          {checarPermissao(
            'botao',
            'botao.cadastro.hierarquia.editar',
            'Visualizar',
          ) && (
            <div className="add-btn">
              <span
                className="add-text"
                onClick={() => {
                  if (!(editHierarquiaId && editHierarquiaId !== hierarquiaId))
                    !addingNivel && addNivelHierarquia();
                }}
                style={{
                  cursor:
                    editHierarquiaId && editHierarquiaId !== hierarquiaId
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                Adicionar nível
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="perfil-tag-area">
            {perfis?.map((perfilId, index) => {
              let perfilIndex = optionsPerfisHierarquia
                .map((perfil) => perfil?.id)
                ?.indexOf(perfilId);
              return (
                <div className="perfil-tag" key={'perfil-selected-' + index}>
                  <div className="perfil-text">
                    {optionsPerfisHierarquia[perfilIndex]?.nome}
                  </div>
                  <div
                    className="x-btn"
                    style={{
                      cursor:
                        editHierarquiaId && editHierarquiaId !== hierarquiaId
                          ? 'not-allowed'
                          : 'pointer',
                    }}
                    onClick={() => {
                      if (
                        !(editHierarquiaId && editHierarquiaId !== hierarquiaId)
                      )
                        removePerfilHierarquia(perfilId);
                    }}
                  >
                    <IconX />
                  </div>
                </div>
              );
            })}
          </div>
          {checarPermissao(
            'botao',
            'botao.cadastro.hierarquia.editar',
            'Visualizar',
          ) && (
            <div className="add-btn add-perfil-btn">
              <div className="add-text">
                <IconPlusPerfilBtn className="plus-perfil" />
              </div>
              <Select
                value="PERFIL"
                className="visible-select"
                suffixIcon={<IconArrowSelectRounded />}
                disabled={editHierarquiaId && editHierarquiaId !== hierarquiaId}
              ></Select>
              <Select
                className="hidden-select"
                suffixIcon={<IconArrowSelectRounded />}
                onSelect={(perfilId) => {
                  addPerfilHierarquia(perfilId, hierarquiaId);
                }}
                disabled={editHierarquiaId && editHierarquiaId !== hierarquiaId}
              >
                {optionsPerfisHierarquia?.filter((perfil) => perfil.ativo)
                .map((perfil, index) => {
                  if (!perfis?.includes(perfil?.id)) {
                    return (
                      <Option value={perfil?.id} key={'perfil-' + index}>
                        {perfil?.nome}
                      </Option>
                    );
                  }else{
                    return null;
                  }
                })}
              </Select>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderPanelHierarquia;
