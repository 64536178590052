import React, { useContext, useState } from 'react';
import './styles.scss';
import moment from 'moment';
import { Field } from 'formik';
import { ReactComponent as IconArrowSelect } from '../../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconCalendar } from '../../../../assets/images/icons/calendar.svg';
import { ReactComponent as BulletInfo } from '../../../../assets/images/icons/bullet-info-tooltip.svg';

import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';

import InputMask from 'react-text-mask';
import Tooltip from '../../../../components/tooltip';
import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';

import { Form, Select, Input, DatePicker } from 'formik-antd';

import { cpfMask } from '../../../../utils/masks';

import { RelatorioControlePropostaContext } from '../../../../contexts/RelatorioControlePropostaContext';
import { isNumber } from 'lodash';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];
function FilterControleProposta({
  formik,
  emails,
  setEmails,
  isEmailValido,
  showInvalidEmailModal,
  setShowInvalidEmailModal,
  setRelatorioEnviado,
}) {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const { contexto, enviarCsvRelatorioControlePropostas } = useContext(
    RelatorioControlePropostaContext,
  );

  const { values, setFieldValue } = formik;
  const [dates, setDates] = useState([]);

  const [selectedRangeData, setSelectedRangeData] = useState([
    moment().add(-1, 'days'),
    moment(),
  ]);
  const [hackValue, setHackValue] = useState();

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
    return tooLate;
  };

  const handleLimpaCampos = () => {
    setFieldValue('tipoData', 1);
    setSelectedRangeData([moment().add(-1, 'days'), moment()]);
    setDates([moment().add(-1, 'days'), moment()]);
    setFieldValue('dataInicial', moment().add(-1, 'days'));
    setFieldValue('dataFinal', moment());
    setFieldValue('rangeData', [moment().add(-1, 'days'), moment()]);
    setFieldValue('proposta', null);
    setFieldValue('cpf', '');
    setFieldValue('situacao', null);
    setFieldValue('agente', null);
    setFieldValue('matriz', null);
    formik.setFieldTouched("tipoErroIntegracao", false)
    setFieldValue('tipoErroIntegracao', null);
    setFieldValue('produto', null);
    setFieldValue('tipoCliente', null);
  };

  const onOpenChange = (open) => {
    if (!open) {
      setHackValue(undefined);
    }
  };

  function TooltipInfo({ text }) {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-info"
        overlayClassName="tooltip-proposta-credito"
        color="#435361"
        title={text}
      >
        <BulletInfo className="bullet-info-icon" />
      </Tooltip>
    );
  }

  const handleEnvioFormulario = async () => {
    let erro = false;

    if (!isEmailValido && showInvalidEmailModal) {
      Modal('', 'O e-mail digitado não é válido.', 'warning');
      setShowInvalidEmailModal(true);
      erro = true;
    }  

    // eslint-disable-next-line
    if (!emails || emails?.length == 0) {
      formik.setFieldTouched('emails');
      erro = true;
    } 

    if (
      (values.rangeData &&
        values?.rangeData[1]?.diff(values?.rangeData[0], 'days') > 31) ||
      !(
        (values?.dataInicial && values?.dataFinal) ||
        // eslint-disable-next-line
        values?.cpf.replace(/\D/g, '').length == 11 ||
        values?.proposta
      )
    ) {
      Modal('', 'Prazo máximo para consulta: 31 dias', 'warning');
      erro = true;
    } 

    if (!isNumber(values?.tipoErroIntegracao)) {
      formik.setFieldTouched('tipoErroIntegracao');
      erro = true;
    }

    if (!erro) {
      const cpfWithoutMask = values?.cpf.replace(/\D/g, '');

      const dataInicial = values.dataInicial
        ? moment(values?.dataInicial).format('YYYY-MM-DD')
        : null;
      const dataFinal = values.dataFinal
        ? moment(values?.dataFinal).format('YYYY-MM-DD')
        : null;

      const response = await enviarCsvRelatorioControlePropostas({
        tipoData: values?.tipoData,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        propostaId: values?.proposta,
        cpf: cpfWithoutMask,
        situacaoId: values?.situacao,
        agente: values?.agente,
        matrizId: values?.matriz,
        tipoErro: values?.tipoErroIntegracao,
        produtoId: values?.produto,
        tipoCliente: values?.tipoCliente,
        emails: emails.join(';'),
      });

      if (response.success) {
        Modal('', response.data?.mensagem, 'warning', () => {
          handleLimpaCampos();
          setRelatorioEnviado(true);
          setEmails([]);
        });
      } else {
        Modal('', response?.messages.join('\n'), 'warning');
      }
    }

  };

  return (
    <>
      <div className="container-filtro-relatorio-controle-propostas">
        <h3 className="title-filtro-relatorio-controle-propostas">
          Filtro do relatório
        </h3>
        <div className="filter-controle-propostas">
          <Form layout="vertical">
            <div>
              <div className="col-lg-12 col-md-12" style={{ padding: '0px' }}>
                <div className="form-row">
                  <div className="col-lg-4 col-md-6">
                    <Form.Item name="tipoData" label="Tipo de Data">
                      <Select
                        name="tipoData"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.tipoData?.map((item) => (
                          <Option
                            key={item.id}
                            selectedRangeData={item.id}
                            value={item.id}
                          >
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <Form.Item name="rangeData" label="Data">
                      <RangePicker
                        name="rangeData"
                        value={hackValue || selectedRangeData}
                        disabledDate={
                          values?.cpf?.replace(/\D/g, '') !== ''
                            ? null
                            : disabledDate
                        }
                        onCalendarChange={(val) => setDates(val)}
                        onChange={(val) => {
                          setSelectedRangeData(val);

                          if (val) {
                            setFieldValue('dataInicial', val[0]);
                            setFieldValue('dataFinal', val[1]);
                          } else {
                            setFieldValue('dataInicial', null);
                            setFieldValue('dataFinal', null);
                          }
                        }}
                        onOpenChange={onOpenChange}
                        format={dateFormatList}
                        defaultPickerValue={[
                          moment().add(-1, 'month'),
                          moment(),
                        ]}
                        suffixIcon={<IconCalendar />}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-2 col-md-4 input-numero-proposta-tela-controle-propostas">
                    <Form.Item
                      name="proposta"
                      className="filter-item"
                      label="Proposta"
                    >
                      <TooltipInfo text="Se um código da proposta for informado não haverá restrição no filtro de data." />
                      <Input
                        name="proposta"
                        placeholder="Nº da Proposta"
                        type="number"
                        className="ant-input"
                        onChange={(val) => {
                          if (
                            val.target.value !== '' &&
                            /^[0-9]*$/.test(
                              val.target.value
                                ?.replaceAll('.', '')
                                ?.replaceAll('-', ''),
                            )
                          ) {
                            setSelectedRangeData(undefined);
                            setDates(undefined);
                            setFieldValue('rangeData', undefined);
                            setFieldValue('dataInicial', null);
                            setFieldValue('dataFinal', null);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-2 col-md-4">
                    <Form.Item
                      name="cpf"
                      className="field-control filtro-cpf-controle-proposta"
                      label="CPF"
                    >
                      <TooltipInfo text="Se um CPF for informado não haverá restrição no filtro de data." />
                      <Field name="cpf">
                        {({ field }) => (
                          <InputMask
                            name="cpf"
                            className="ant-input"
                            placeholder="000.000.000-00"
                            mask={cpfMask}
                            {...field}
                            onChange={(val) => {
                              setFieldValue('cpf', val.target.value);
                              if (
                                val.target.value.replace(/\D/g, '').length ===
                                11
                              ) {
                                setSelectedRangeData(undefined);
                                setDates(undefined);
                                setFieldValue('rangeData', undefined);
                                setFieldValue('dataInicial', null);
                                setFieldValue('dataFinal', null);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <Form.Item name="situacao" label="Status">
                      <Select
                        name="situacao"
                        allowClear
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.situacao?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <Form.Item name="agente" label="Agente">
                      <Input name="agente" placeholder="Digite aqui" />
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <Form.Item name="matriz" label="Matriz">
                      <Select
                        name="matriz"
                        allowClear
                        showSearch
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        filterOption={(input, option) => {
                          return option.children
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(
                              input
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, ''),
                            );
                        }}
                      >
                        {contexto?.matrizes?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <Form.Item 
                    name="tipoErroIntegracao" 
                    label="Tipo de Erro" 
                    hasFeedback
                    >
                      <Select
                        name="tipoErroIntegracao"
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.tipoErroIntegracao?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <Form.Item name="produto" label="Produto">
                      <Select
                        name="produto"
                        allowClear
                        showSearch
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        filterOption={(input, option) => {
                          return option.children
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(
                              input
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, ''),
                            );
                        }}
                      >
                        {contexto?.produtos?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <Form.Item name="tipoCliente" label="Tipo de Cliente">
                      <Select
                        name="tipoCliente"
                        allowClear
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.tipoCliente?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="filter-actions container-buttons-controle-proposta">
              <Button
                className="button-limpar-controle-proposta"
                type="button"
                variant="gray"
                block
                onClick={handleLimpaCampos}
                async={true}                
              >
                <Icon variant="clean" />
                <span className="ml-2">Limpar filtro</span>
              </Button>
              {checarPermissao(
                'botao',
                'botao.credito.controlePropostas.enviarCsv',
                'Editar',
              ) && (
                <Button
                  className="button-enviar-csv-controle-proposta"
                  type="button"
                  variant="blue"
                  block
                  async={true}
                  onClick={() => {
                    setEmails([...emails]);
                    handleEnvioFormulario();
                  }}
                >
                  <Icon variant="send" />
                  <span className="ml-2">Enviar CSV</span>
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default FilterControleProposta;
