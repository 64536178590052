import React, { useState } from 'react';

import { Upload, Modal } from 'antd';
import Message from '../../components/Modal';
import { PlusOutlined } from '@ant-design/icons';

function FormUploads({
  disabled,
  carregarImagem,
  fileIds,
  setFileIds,
  fileList,
  setFileList,
}) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter(
      (fileRemove) => fileRemove.uid !== file.uid,
    );

    const newFileIdList = fileIds.filter(
      (fileRemove) => fileRemove.uid !== file.uid,
    );

    setFileIds(newFileIdList);
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (!file.url) {
      file.preview = await uploadImage(file.originFileObj);
    }

    setFileType(file.type);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
    setPreviewImage('');
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };

  const uploadImage = async (options) => {
    const { file } = options;

    let fileRemoved;

    if (fileList.length > 0) {
      fileRemoved = fileList.filter((item) => item.uid === file.uid)[0];
    }

    if (!fileRemoved) {
      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const data = await carregarImagem(temp);
          const newFilesId = fileIds;
          newFilesId.push({
            id: null,
            imagemId: data.data.id,
            uid: file.uid,
          });

          setFileIds(newFilesId);
        } catch (err) {
          console.log(`Falha no upload`, err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadButton = (
    <div className="action-anexar">
      <PlusOutlined />
      <div>Clique para anexar</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        className="form-uploads-visitas"
        fileList={fileList}
        onPreview={handlePreview}
        beforeUpload={(file) => {
          if (
            file.type !== 'application/pdf' &&
            file.type !== 'image/png' &&
            file.type !== 'image/jpeg'
          ) {
            Message(
              'Arquivo inválido',
              'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
              'warning',
            );
          } else {
            const fileURL = URL.createObjectURL(file);
            const newFile = {
              uid: file.uid,
              name: file.name,
              type: file.type,
              url: fileURL,
            };

            setFileList([...fileList, newFile]);
          }
          return false;
        }}
        disabled={disabled}
        onChange={uploadImage}
        onRemove={handleRemove}
      >
        {uploadButton}
      </Upload>

      <Modal
        visible={previewVisible}
        destroyOnClose={true}
        title={previewTitle}
        footer={null}
        className={'modal-preview-upload ' + fileType.replace('/', '')}
        onCancel={handleCancel}
      >
        <div>
          {fileType === 'application/pdf' && (
            <object
              data={previewImage + '#toolbar=0'}
              type={fileType}
              width="100%"
              height="100%"
              aria-label="Upload File"
            ></object>
          )}
          {fileType !== 'application/pdf' && (
            <img src={previewImage} alt="Arquivo"></img>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default FormUploads;
