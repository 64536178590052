import * as Yup from 'yup';
import validaCPF from '../../utils/validaCpf';
import '../../utils/phoneSchema';

Yup.addMethod(Yup.string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cpf`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }

    return validaCPF(value) || createError({ path, message: errorMessage });
  });
});

const validationSchema = Yup.object().shape({
  cpf: Yup.string().validateCPF('CPF inválido').required('O CPF é obrigatório'),
  nome: Yup.string()
    .matches(/(\w.+\s).+/, 'Nome incompleto')
    .required('O nome é obrigatório'),
  nascimento: Yup.date().required('A data de nascimento é obrigatória'),
  telefone: Yup.string()
    .validatePhone('Número de telefone inválido, favor tentar novamente.')
    .required('O telefone é obrigatório'),
  ocupacaoId: Yup.mixed().required('A classificação é obrigatória'),
  ufId: Yup.mixed().required('O estado é obrigatório'),
  cidadeId: Yup.mixed().required('A cidade é obrigatória'),
  cep: Yup.string().matches(
    /\d{5}-\d{3}/,
    'CEP inválido ou não encontrado, favor informar o CEP correto ou prosseguir com o preenchimento manual',
  ),
  funcionarioConveniada: Yup.boolean().required(" "),
  possuiVeiculoGarantia: Yup.boolean().required(" "),
  convenioId: Yup.number().nullable(),
});

export default validationSchema;
