import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import Spinner from '../Spinner';

function Overlay(props) {
  return (
    <LoadingOverlay
      {...props}
      spinner={<Spinner />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.25)',
        }),
        content: (base) => ({
          ...base,
          marginTop: props.marginTop,
        }),
      }}
    >
      {props.children}
    </LoadingOverlay>
  );
}

export default Overlay;
