import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import TelemetryProvider from './TelemetryProvider';
import { getAppInsights } from './TelemetryService';
import Routes from './routes';

function App() {
  return (
    <BrowserRouter>
      <TelemetryProvider
        instrumentationKey="34f5997c-1aaa-4be5-b1aa-fde693441513"
        after={() => {
          getAppInsights();
        }}
      >
        <div id="wrapper">
          <Routes />
        </div>
      </TelemetryProvider>
    </BrowserRouter>
  );
}

export default App;
