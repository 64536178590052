import React, { Fragment, useState, useEffect, useContext } from 'react';

import { Field } from 'formik';
import { Form } from 'formik-antd';
import Input from '../../../components/SimpleInput';

import InputMask from 'react-text-mask';

import { cpfMask } from '../../../utils/masks';
import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import InputPhone from '../../../components/InputPhone';
import validaPhone from '../../../utils/validaPhone';

function DadosVendedorForm({ formik, disabledFields }) {
  const { setFieldTouched, setFieldValue } = formik;
  const { validationField, editableField } = useContext(PropostaCreditoContext);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const [statusCPF, setStatusCPF] = useState('');

  const handleCPF = () => {
    setFieldTouched('unidade.cpfVendedor');
  };

  useEffect(() => {
    if (
      formik.touched.unidade &&
      formik.errors.unidade &&
      formik.touched.unidade.cpfVendedor &&
      formik.errors.unidade.cpfVendedor === 'CPF inválido'
    ) {
      setStatusCPF('warning');
    } else if (
      formik.touched.unidade &&
      formik.errors.unidade &&
      formik.touched.unidade.cpfVendedor &&
      formik.errors.unidade.cpfVendedor ===
        'Obrigatório informar CPF do vendedor'
    ) {
      setStatusCPF('error');
    } else {
      setStatusCPF('');
    }
  }, [formik.errors.unidade, formik.touched.unidade]);

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Informações do Vendedor</div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-lg-6 col-xl-4">
          <Form.Item
            name="unidade.nomeVendedor"
            label="Nome do vendedor"
            validate={(value) =>
              validationField(value, 'unidade.nomeVendedor', 'Nome do vendedor')
            }
            hasFeedback
          >
            <Input
              name="unidade.nomeVendedor"
              disabled={
                (acesso ? !editableField('unidade.nomeVendedor') : true)
                || disabledFields
              }
              value={formik?.values?.unidade?.nomeVendedor}
              onBlur={(e) => {
                setFieldValue('unidade.nomeVendedor', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="unidade.cpfVendedor"
            label="CPF"
            validateStatus={statusCPF}
            validate={(value) =>
              validationField(
                value,
                'unidade.cpfVendedor',
                'CPF do vendedor',
                'cpf',
              )
            }
            hasFeedback
          >
            <Field name="unidade.cpfVendedor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="000.000.000-00"
                  onBlur={handleCPF}
                  mask={cpfMask}
                  showMask={false}
                  disabled={
                    (acesso ? !editableField('unidade.cpfVendedor') : true)
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="unidade.celularVendedor"
            label="Celular vendedor"
            validate={(value) => {
              let error;
              if (!value) {
                error = 'O telefone deve ser preenchido';
              } else if (value && !validaPhone(value)) {
                error = 'Telefone inválido';
              }
              return error;
            }}
            hasFeedback
          >
            <Field name="unidade.celularVendedor">
              {({ field }) => (
                <InputPhone
                  {...field}
                  className="ant-input"
                  placeholder="Ex.: (00) 0000-0000"
                  disabled={
                    (acesso ? !editableField('unidade.celularVendedor') : true)
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosVendedorForm };
