import React, { useContext } from 'react';

import { Radio } from 'antd';

import { CorrespondenteContext } from '../../contexts/CorrespondenteContext';

const CorrespondenteSelecao = () => {
  const onChange = (e) => {
    setTipo(e.target.value);
  };

  const { tipo, setTipo } = useContext(CorrespondenteContext);

  return (
    <div className="form-row checkbox-correspondente">
      <div className="col-lg-12">
        <label>Tipo Credenciamento: </label>
        <Radio.Group onChange={onChange} value={tipo}>
          <Radio value={1}>Correspondente</Radio>
          <Radio value={2}>Conveniada</Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default CorrespondenteSelecao;
