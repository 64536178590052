import api from './api';

export const getContextoProposta = async () => {
  let visaoAnaliticaContexto = sessionStorage.getItem('visao-analitica-contexto');

    if(!visaoAnaliticaContexto) {
      try {
        const { data } = await api.get('/proposta/visao-analitica-contexto');
        sessionStorage.setItem('visao-analitica-contexto', JSON.stringify(data.data[0]));
        return data.data[0];
      } catch (error) {
        return error;
      }
    }
    visaoAnaliticaContexto = JSON.parse(visaoAnaliticaContexto)
    return visaoAnaliticaContexto;

};

export const listarRelatorioAnalitico = async ({
  pagina = 1,
  filtroCpf = null,
  filtroSituacaoId = null,
  filtroAnalista = null,
  filtroSupervisorId = null,
  quantidadePorPagina = 15,
  produtoGrupoId = 0,
  ordenacao = { id: 'situacao', desc: false },
}) => {
  const { data } = await api.post('/proposta/visao-analitica', {
    pagina,
    filtroCpf,
    filtroSituacaoId,
    filtroAnalista,
    filtroSupervisorId,
    quantidadePorPagina,
    produtoGrupoId,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data;
};

export const exportarPropostasVisaoAnaliticaCSV = async ({
  filtroCpf = null,
  filtroSituacaoId = null,
  filtroAnalista = null,
  filtroSupervisorId = null,
  produtoGrupoId = 0,
  ordenacao = null,
  ordenacaoAsc,
}) => {
  const { data } = await api.post(
    '/proposta/visao-analitica/exportacao-csv',
    {
      filtroCpf,
      filtroSituacaoId,
      filtroAnalista,
      filtroSupervisorId,
      produtoGrupoId: produtoGrupoId || 0,
      ordenacao,
      ordenacaoAsc,
    },
    { responseType: 'arraybuffer' },
  );
  return data;
};

export const imprimirPdfRelatorioAnalitico = async ({
  filtroCpf = null,
  filtroSituacaoId = null,
  filtroAnalista = null,
  filtroSupervisorId = null,
  produtoGrupoId = 0,
  ordenacao = null,
  ordenacaoAsc,
}) => {
  const { data } = await api.post('/proposta/visao-analitica-pdf', {
    filtroCpf,
    filtroSituacaoId,
    filtroAnalista,
    filtroSupervisorId,
    produtoGrupoId: produtoGrupoId || 0,
    ordenacao,
    ordenacaoAsc,
  });
  return data;
};

export const getCardsEsteira = async ({ produtoGrupoId }) => {
  const { data } = await api.get(
    `/proposta/visao-analitica-cards/${produtoGrupoId}`,
  );
  return data.data;
};
