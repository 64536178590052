import React, { useContext } from 'react';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';
import { Toolbar } from './toolbar';
import { Switch } from 'antd';

import IconEdit from '../../../components/EditIcon';

import { percentualFormat } from '../../../utils/numberFormatter';

import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const pad = (str, max) => {
  str = str.toString();
  return str.length < max ? pad('0' + str, max) : str;
};

function TabelaPrincipal() {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    data,
    total,
    pageCount,
    loading,
    editarTabelaJuros,
    acesso,
    changePage,
    fetchTabelaJuros,
    resetTableForm,
  } = useContext(TabelaJurosContext);

  const columns = [
    {
      Header: 'Código',
      accessor: 'id',
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{pad(value, 4)}</div>
      ),
    },
    {
      Header: 'Código Crivo',
      accessor: 'codigoCrivo',
      style: {
        minWidth: 160,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{value}</div>
      ),
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      style: {
        minWidth: 160,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      style: {
        minWidth: 160,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
      style: {
        minWidth: 160,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {value === 0 ? 'Juros Percentuais' : 'Valores Fixos'}
        </div>
      ),
    },
    {
      Header: 'Planos',
      accessor: 'minPlano',
      style: {
        textAlign: 'center',
        minWidth: 160,
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center' }}>
          <span>{row.original.minPlano}</span>
          <span>{' - '}</span>
          <span>{row.original.maxPlano}</span>
        </div>
      ),
    },
    {
      Header: 'Taxas',
      accessor: 'minJuros',
      style: {
        textAlign: 'center',
        minWidth: 160,
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center' }}>
          <span>{percentualFormat(row.original.minJuros, 4)}</span>
          <span>{' - '}</span>
          <span>{percentualFormat(row.original.maxJuros, 4)}</span>
        </div>
      ),
    },
    {
      Header: 'Ativo',
      accessor: 'ativo',
      disableSortBy: true,
      style: {
        minWidth: 60,
        width: 60,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          <Switch checked={value} size="small" onChange={() => {}} />
        </div>
      ),
    },
    {
      Header: '',
      id: 'action',
      style: {
        minWidth: 40,
        width: 40,
        textAlign: 'center',
      },
      Cell: ({ row }) =>
        checarPermissao(
          'botao',
          'botao.cadastro.tabelajuros.editar',
          'Visualizar',
        ) ? (
          <div
            style={{
              textAlign: 'center',
              cursor: acesso ? 'pointer' : 'not-allowed',
            }}
          >
            <a
              href="/"
              disabled={
                !acesso ||
                !checarPermissao(
                  'botao',
                  'botao.cadastro.tabelajuros.editar',
                  'Visualizar',
                )
              }
              onClick={(e) => {
                if (
                  checarPermissao(
                    'botao',
                    'botao.cadastro.tabelajuros.editar',
                    'Visualizar',
                  )
                ) {
                  resetTableForm();
                  e.preventDefault();
                  editarTabelaJuros(row.original.id);
                }
              }}
            >
              <IconEdit width="15" />
            </a>
          </div>
        ) : (
          <></>
        ),
    },
  ];

  return (
    <Card>
      <div className="table-tabela-juros">
        <Table
          columns={columns}
          data={data}
          onLoadData={fetchTabelaJuros}
          total={total}
          pageCount={pageCount}
          loading={loading}
          header={<Toolbar />}
          filter={changePage} // forçar componente a ir para a primeira página
        />
      </div>
    </Card>
  );
}

export default TabelaPrincipal;
