import { Modal } from 'antd';
import { useContext } from 'react';
import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { PropostaCreditoContext } from '../../contexts/PropostaCreditoContext';
import './styles.scss';

function ModalTabelaInativa({
  values,
  setFormValue,
  visible,
  setVisible,
  setRecalculoTabelaJuros,
}) {
  const { valorLiberado, prazo, parcela, valorSolicitado, saldoDevedor } =
    values;

  const { tabelaJuros, setTabelaSelecionada } = useContext(PropostaCreditoContext);

  function formatCurrency(number) {
    return number?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  function handleClick() {
    setFormValue('operacao.valorContratado', null);
    setFormValue('operacao.valorLiberado', 0);
    setFormValue('operacao.prestacao', null);
    setFormValue('operacao.prazo', null);
    if (tabelaJuros?.length === 1) {
      const { id, nome } = tabelaJuros[0];
      setFormValue('operacao.tabelaJurosNome', nome);
      setFormValue('operacao.tabelaJurosId', id);
      setTabelaSelecionada(tabelaJuros[0])

    } else {
      setFormValue('operacao.tabelaJurosNome', null);
      setFormValue('operacao.tabelaJurosId', null);
    }
    setVisible(false);
    setRecalculoTabelaJuros(true);
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      closeIcon={'X'}
      className="modal-tabela-inativa"
      width={691}
      onCancel={handleClick}
      maskClosable={false}
    >
      <header>
        <IconExclamationCircle width={75} />
      </header>
      <main>
        <ul>
          <li>Necessário efetuar recálculo da proposta;</li>
          <li>
            Valores anteriores à atualização:
            <ul>
              {valorLiberado && (
                <li>Valor Liberado: {formatCurrency(valorLiberado)}; </li>
              )}
              {prazo && parcela && (
                <li>
                  {' '}
                  Prazo/Parcela: {`${prazo}x de ${formatCurrency(parcela)}`};
                </li>
              )}
              {valorSolicitado && (
                <li>
                  {' '}
                  Valor solicitado:{' '}
                  {formatCurrency(
                    saldoDevedor
                      ? valorSolicitado + saldoDevedor
                      : valorSolicitado,
                  )}
                </li>
              )}
              {saldoDevedor && (
                <li> Valor saldo devedor: {formatCurrency(saldoDevedor)}.</li>
              )}
            </ul>
          </li>
        </ul>
      </main>
      <footer>
        <button onClick={handleClick}>OK</button>
      </footer>
    </Modal>
  );
}

export default ModalTabelaInativa;
