import React, { useContext, useState } from 'react';
import { CadastroUsuariosContext } from '../../../src/contexts/CadastroUsuariosContext';
import { ReactComponent as RadioButtonNoSelected } from '../../assets/images/icons/radio-no-selected.svg';
import { ReactComponent as RadioButtonSelected } from '../../assets/images/icons/radio-selected.svg';
import { ReactComponent as BulletInfo } from '../../assets/images/icons/bullet-info-tooltip.svg';
import Tooltip from '../../components/tooltip';
import { Button } from 'antd';
import { genericPhoneFormatter } from '../../utils/textFormatter';
import validaPhone from '../../utils/validaPhone';
 
const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="topLeft"
    className="tooltip-info"
    color="#435361"
    title={text}
  >
    <BulletInfo
      className="bullet-info-icon"
      style={{ cursor: 'pointer' }}
      width={12}
      height={12}
    />
  </Tooltip>
);
 
export const ColumnsContatoUsuario = ( tiposTelefone = [], ativo) => {
  const {
    telefoneCadastroUsuario,
    setTelefoneCadastroUsuario,
  } = useContext(CadastroUsuariosContext);
 
 
  const tipoTelefoneString = (telefone) => {
    const tipoTelefoneNumber = validaPhone(telefone);
 
    const resultado = tiposTelefone.find(
      (tipo) => tipo.id === tipoTelefoneNumber,
    );
 
    if (resultado) {
      return resultado.nome;
    } else {
      return '';
    }
  };
 
  // eslint-disable-next-line no-unused-vars
  const [selecionaPrincipal, setSelecionaPrincipal] = useState(false);
  const [selectedTelefoneIndex, setSelectedTelefoneIndex] = useState(null);
 
  const handleSelectedPhone = (index) => {
    if (index === selectedTelefoneIndex) {
      setSelectedTelefoneIndex(null);
      setSelecionaPrincipal(false);
      setTelefoneCadastroUsuario((prevState) => {
        const newState = [...prevState];
        newState[index].principal = false;
        return newState;
      });
    } else {
      setSelectedTelefoneIndex(index);
      const newPhone = telefoneCadastroUsuario[index];
      if (!newPhone.principal) {
        const indiceTelefonePrincipal = telefoneCadastroUsuario.findIndex(
          (telefone) => telefone.principal,
        );
        // o IF abaixo garante que apenas um telefone seja marcado como principal
        if (indiceTelefonePrincipal !== -1) {
          setTelefoneCadastroUsuario((prevState) => {
            const newState = [...prevState];
            newState[indiceTelefonePrincipal].principal = false;
            return newState;
          });
        }
        setTelefoneCadastroUsuario((prevState) => {
          const newState = [...prevState];
          newState[index].principal = true;
          return newState;
        });
        setSelecionaPrincipal(true);
      }
    }
  };
 
  const handleRemovePhone = (index) => {
   
    setTelefoneCadastroUsuario((phones) => {
      const newPhoneList = [...phones];
      newPhoneList.splice(index, 1);
      return newPhoneList;
    });
 
    if (index === selectedTelefoneIndex) {
      setSelectedTelefoneIndex(null);
      setSelecionaPrincipal(false);
    }
  };
 
 
  return [
    {
      Header: 'Telefone',
      accessor: 'telefone',
      style: {
        textAlign: 'center',
        maxWidth: 189,
        maxHeight: 19,
      },
      disableSortBy: true,
      Cell: ({ value }) =>
        <span> {value}</span> ? genericPhoneFormatter(value) : '',
    },
    {
      Header: 'Tipo',
      accessor: '',
      style: {
        textAlign: 'center',
        maxWidth: 189,
        maxHeight: 19,
      },
      disableSortBy: true,
      Cell: ({ row }) => <>{tipoTelefoneString(row?.original?.telefone)}</>,
    },
    {
      Header: (
        <>
          <span>SMS</span>
          <TooltipInfo text="Número de redefinição de senha por SMS" />
        </>
      ),
 
      accessor: 'principal',
      style: {
        textAlign: 'center',
        maxWidth: 108,
        maxHeight: 19,
      },
      disableSortBy: true,
      Cell: ({ row }) => {
        const tipoTelefone = tipoTelefoneString(row?.original?.telefone);
        const isPrincipal = row?.original.principal;
        if (tipoTelefone === 'Celular') {
          if (telefoneCadastroUsuario) {
            return (
              <span onClick={() => ativo && handleSelectedPhone(row.index)}>
                {selectedTelefoneIndex === row.index || isPrincipal === true ? (
                  <RadioButtonSelected className={ativo ? '' : 'disabled-pointer'}/>
                ) : (
                  <RadioButtonNoSelected className={ativo ? '' : 'disabled-pointer'} />
                )}
              </span>
            );
          } else {
            return (
              <span onClick={() => handleSelectedPhone(row.index)}>
                <RadioButtonNoSelected className={ativo ? '' : 'disabled-pointer'} />
              </span>
            );
          }
        } else {
          return '-';
        }
      },
    },
    {
      Header: '',
      accessor: 'contato',
      style: {
        textAlign: 'center',
        maxWidth: 47,
        maxHeight: 19,
      },
      disableSortBy: true,
      Cell: ({ row }) => (
        <Button
          style={{
            padding: '3px 5px',
            backgroundColor: '#ED5565',
            border: '1px solid #D44C5A',
            borderRadius: '0px',
            maxHeight: '23px',
          }}
          onClick={() => handleRemovePhone(row.index)}
          disabled={!ativo}
        >
          <i
            className="fa fa-trash"
            style={{
              color: '#fff',
              width: '15px',
              height: '15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </Button>
      ),
    },
  ];
};