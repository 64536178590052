import { useEffect, useRef } from 'react';

export function useInterval(callback, delay) {
  // 'setInterval' que possibilita o uso com hooks do React.
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function run() {
      savedCallback.current();
    }
    if (delay !== null && delay !== 0) {
      let interval = setInterval(run, delay);
      return () => clearInterval(interval);
    }
  }, [delay]);
}
