import React, { useEffect, useState } from 'react';

import { Field } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import Option from '../../../components/Option';
import Tooltip from '../../../components/tooltip';
import SelectSearch from '../../../components/SearchSelect';
import InputInteger from '../../../components/InputInteger';
import {
  cpfFormatter,
  cnpjFormatter,
} from '../../../utils/textFormatter';
import validaCPF from '../../../utils/validaCpf';
import validaCNPJ from '../../../utils/validaCnpj';
import validaEmail from '../../../utils/validaEmail';
import validateCellPhone from '../../../utils/validateCellPhone';
import { isNumber } from 'lodash';

function TooltipInfo({ text }) {
  return (
    <Tooltip
      placement="topLeft"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
}

function DadosBancariosForm({ formik, dadosBancariosContext, isReadOnly }) {
  const { setFieldValue, values, setFieldTouched } = formik;
  const [chavePix, setChavePix] = useState(values?.dadosBancarios?.chavePix);
  const [statusChavePix, setStatusChavePix] = useState();

  useEffect(() => {
    setFieldTouched('dadosBancarios.chavePix', true);
    if (
      values?.dadosBancarios?.tipoChavePix !== undefined &&
      // eslint-disable-next-line
      values?.dadosBancarios?.chavePix == ''
    ) {
      setStatusChavePix('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // o useEffect abaixo garante que a chavePix seja corretamente
  // formatada e atualizada quando o tipoChavePix mudar
  useEffect(() => {
    if (values?.dadosBancarios?.chavePix) {
      handleChavePix(values.dadosBancarios.chavePix);
    }
  }, [values?.dadosBancarios?.tipoChavePix]);

  const cellPhoneFormatterAlt = (data) => {
    data = data.replace(/\D/g, '');
    // Limita o número de dígitos a 11
    data = data.slice(0, 11);
    // Formata o número no padrão 00 00000 0000
    const formattedPhoneNumber = data.replace(/(\d{2})(\d{5})(\d{4})/, '$1 $2 $3');
    return formattedPhoneNumber;
  };

  const handleChavePix = (value) => {
    let data = value.replace(/\D/g, ''); // apenas dígitos

    switch (values?.dadosBancarios?.tipoChavePix) {
      default:
        return setChavePix(value);
      case 0: // CPF
        return setChavePix(cpfFormatter(data));
      case 1: // CNPJ
        return setChavePix(cnpjFormatter(data));
      case 2: // Celular
        return setChavePix(cellPhoneFormatterAlt(data));
    }
  };

  useEffect(() => {
    // responsável por limpar de fato o campo "chave pix" 
    // quando o valor do campo "tipo de chave pix" for removido  
    if (!isNumber(values?.dadosBancarios?.tipoChavePix)) {
      setChavePix('');
      formik.setFieldValue('dadosBancarios.chavePix', '');
      formik.setFieldTouched('dadosBancarios.chavePix', false);
      setStatusChavePix();
    }
  }, [values?.dadosBancarios?.tipoChavePix]);


  const maskDigitoAgenciaX = (e) => {
    let valueDigito = e.target.value.replace(/([^\d|X|x])+/gim, '');
    e.target.value = valueDigito;
    formik.setFieldValue('dadosBancarios.agenciaDigito', valueDigito);
  };

  const mascaraNumeroConta = (e) => {
    let unformatted = e.target.value.replace(/[^0-9Xx]/g, '').toUpperCase();
    let suffix = '';

    if (unformatted.length >= 2) {
      if (unformatted.lastIndexOf('X') >= unformatted.length - 2) {
        suffix = 'X';
        unformatted = unformatted.substr(0, unformatted.lastIndexOf('X'));
      }

      unformatted = unformatted.replace('X', '').concat(suffix);

      unformatted = unformatted
        .substr(0, unformatted.length - 1)
        .concat('-')
        .concat(unformatted.substr(unformatted.length - 1, 1));
    }
    e.target.value = unformatted;
    formik.setFieldValue('dadosBancarios.contaNumero', unformatted);
  };

  function verificaOperacao() {
    let retorno = '';
    if (values?.dadosBancarios) {
      var bancoId = parseInt(values?.dadosBancarios?.banco);
      if (bancoId === 1 && values?.dadosBancarios?.tipoConta === 1) {
        retorno = '051';
      } else if (bancoId === 104 && values?.dadosBancarios?.tipoConta === 0) {
        retorno = '001';
      } else if (
        bancoId === 104 &&
        values?.dadosBancarios?.tipoConta === 1 &&
        values?.dadosBancarios?.contaNumero?.length >= 11
      ) {
        retorno = '1288';
      } else if (bancoId === 104 && values?.dadosBancarios?.tipoConta === 1) {
        retorno = '013';
      } else if (bancoId === 104 && values?.dadosBancarios?.tipoConta === 2) {
        retorno = '023';
      }
    }
    setFieldValue('dadosBancarios.tipoOperacao', retorno);
  }

  useEffect(() => {
    verificaOperacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dadosBancarios?.banco,
    values?.dadosBancarios?.tipoConta,
    values?.dadosBancarios?.contaNumero,
  ]);

  return (
    <>
      <Form layout="vertical">
        <div className="col-tab-dados-bancarios">
          <div className="grid-dados-bancarios">
            <div className="item-dados">
              <Form.Item label="Banco" name="dadosBancarios.banco">
                <SelectSearch
                  name="dadosBancarios.banco"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  allowClear
                  value={values?.dadosBancarios?.banco}
                  onChange={(value) => {
                    setFieldValue('dadosBancarios.banco', value);
                    setFieldValue('dadosBancarios.tipoConta', '');
                    verificaOperacao();
                  }}
                  disabled={isReadOnly}
                >
                  {dadosBancariosContext?.banco?.map((item) => (
                    <Option key={item.codigo} value={item.codigo}>
                      {`${item.codigo} - ${item.nome}`}
                    </Option>
                  ))}
                </SelectSearch>
              </Form.Item>
            </div>
            <div className="col-input-agencia">
              <label>Agência</label>
              <TooltipInfo text="Cadastrar os dados de agência conforme o cartão do cliente, incluindo letra X quando houver" />
              <div className="form-row">
                <div className="col-lg-7 pr-0">
                  <Form.Item name="dadosBancarios.agencia">
                    <Field name="dadosBancarios.agencia">
                      {({ field }) => (
                        <InputInteger
                          className="ant-input"
                          name="dadosBancarios.agencia"
                          disabled={isReadOnly}
                          {...field}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                <div className="col-lg-1 text-center">
                  <p className="traco-agencia-digito">-</p>
                </div>
                <div className="col-lg-4">
                  <Form.Item name="dadosBancarios.agenciaDigito">
                    <Input
                      name="dadosBancarios.agenciaDigito"
                      onKeyUp={maskDigitoAgenciaX}
                      maxLength={1}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="item-dados num-conta">
              <Form.Item
                label="Número da Conta + Dígito"
                name="dadosBancarios.contaNumero"
              >
                <Input
                  type="text"
                  name="dadosBancarios.contaNumero"
                  placeholder=""
                  disabled={isReadOnly}
                  onKeyUp={(e) => mascaraNumeroConta(e)}
                />
              </Form.Item>
            </div>
            <div className="item-dados conta-dados-bancarios">
              <Form.Item name="dadosBancarios.conta">
                <label>Conta</label>
                <Select
                  name="dadosBancarios.conta"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  disabled={isReadOnly}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {dadosBancariosContext?.conta?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="item-dados">
              <Form.Item label="Tipo de Conta" name="dadosBancarios.tipoConta">
                <Select
                  name="dadosBancarios.tipoConta"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  disabled={isReadOnly}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {dadosBancariosContext?.tipoConta?.map((item) => {
                    if (item?.bancos?.length) {
                      if (
                        item.bancos.includes(
                          parseInt(values?.dadosBancarios?.banco),
                        )
                      ) {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        );
                      } else {
                        return false;
                      }
                    } else {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="item-dados">
              <Form.Item
                label="Tipo de Operação"
                name="dadosBancarios.tipoOperacao"
              >
                <Input
                  name="dadosBancarios.tipoOperacao"
                  type="text"
                  placeholder="000"
                  disabled
                  style={{ textAlign: 'right' }}
                />
              </Form.Item>
            </div>
            <div className="item-dados tempo-conta-item-grid">
              <Form.Item
                name="dadosBancarios.tempoConta"
                label="Tempo de Conta"
              >
                <Select
                  name="dadosBancarios.tempoConta"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  disabled={isReadOnly}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {dadosBancariosContext?.tempoConta?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="item-dados">
              <Form.Item
                label="Tipo de Chave Pix"
                name="dadosBancarios.tipoChavePix"
              >
                <Select
                  name="dadosBancarios.tipoChavePix"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  disabled={isReadOnly}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={() => {
                    setFieldTouched('dadosBancarios.chavePix', false);
                    setFieldValue('dadosBancarios.chavePix', '');
                    setChavePix('');
                    setStatusChavePix();
                  }}

                  onBlur={() => {
                    setFieldTouched('dadosBancarios.chavePix', true);
                  }}
                >
                  {dadosBancariosContext?.tipoChavePIX?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="item-dados chave-pix-item-grid">
              <Form.Item
                label="Chave Pix"
                name="dadosBancarios.chavePix"
                validateStatus={statusChavePix}
                validate={(value) => {
                  let msgError = '';
                  const tipoChavePix = values?.dadosBancarios?.tipoChavePix;

                  if (tipoChavePix === undefined || tipoChavePix === null) {
                    setStatusChavePix();
                    return msgError;
                  }

                  if (value === '') {
                    setStatusChavePix('error');
                    return msgError;
                  } else {
                    switch (tipoChavePix) {
                      default:
                        if (
                          tipoChavePix !== undefined &&
                          // eslint-disable-next-line
                          value == ''
                        ) {
                          setStatusChavePix('error');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                      case 0: // CPF
                        if (value && !validaCPF(value?.replace(/\D/g, ''))) {
                          msgError = 'CPF inválido';
                          setStatusChavePix('warning');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                      case 1: // CNPJ
                        if (value && !validaCNPJ(value?.replace(/\D/g, ''))) {
                          msgError = 'CNPJ inválido';
                          setStatusChavePix('warning');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                      case 2: // Celular
                        if (value && !validateCellPhone(value)) {
                          msgError = 'Telefone inválido';
                          setStatusChavePix('warning');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                      case 3: // E-mail
                        if (value && !validaEmail(value)) {
                          msgError = 'E-mail inválido';
                          setStatusChavePix('warning');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                        case 4: // Outros
                        if (value && value.trim() === '') {
                          msgError = 'Valor inválido';
                          setStatusChavePix('warning');
                        } else {
                          setStatusChavePix();
                        }
                        return msgError;
                    }
                  }
                }}
              >
                <Input
                  type="text"
                  name="dadosBancarios.chavePix"
                  disabled={isReadOnly}
                  placeholder=""
                  value={chavePix}
                  onChange={(e) => {
                    handleChavePix(e.target.value)
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default DadosBancariosForm;
