export const cpfFormatter = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const cnpjFormatter = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const phoneFormatter = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/([1-9]{2})/, '($1) ')
    .replace(/(\d{4})/, '$1-')
    .replace(/(\d{4})/, '$1');
};

export const cellPhoneFormatterAlt = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})/, '$1 ')
    .replace(/(\d{5})/, '$1 ')
    .replace(/(\d{4}\d+?$)/, '$1');
};

export const cellPhoneFormatter = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})/, '($1) ')
    .replace(/(\d{5})/, '$1-')
    .replace(/(\d{4})/, '$1');
};

export const genericPhoneFormatter = (value = '') => {
  if (value) {
    let unformatted = value.replace(/\D/g, '');
    if (unformatted.length === 10) {
      return value
        .replace(/\D/g, '')
        .replace(/([1-9]{2})/, '($1) ')
        .replace(/(\d{4})/, '$1-')
        .replace(/(\d{4})/, '$1');
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})/, '($1) ')
        .replace(/(\d{5})/, '$1-')
        .replace(/(\d{4})/, '$1');
    }
  }
};

export const cepFormatter = (value = '') => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})/, '$1-')
    .replace(/(\d{3})/, '$1');
};

export const cpfCnpjFormatter = (value = '') => {
  let data = String(value)?.replace(/\D/g, '');
  if (data.length > 11) {
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
      5,
      3,
    )}/`;
    if (data.length > 12) cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
    else cnpj += data.substr(8);
    data = cnpj;
  } else {
    let cpf = '';
    let parts = Math.ceil(data.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
    }
    data = cpf;
  }
  return data;
};
