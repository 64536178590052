
import { cpfFormatter } from '../../../utils/textFormatter';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock.svg';
import iconRedistribuir from '../../../assets/images/icons/icon-redistribuir.svg';

import BulletInfo from '../../../assets/images/icons/bullet-info-tooltip.svg';

import { Tooltip } from 'antd';

import './styles.scss';
const globalStyle = {
  height: 35,
  textAlign: 'center',
  fontSize: 13,
  color: '#3D3D3E',
  fontWeight: '400',
  lineHeight: '15px',
  padding: 0,
};

const timeStyle = {
  fontSize: '10px',
  textAlign: 'center',
  color: '#686B6D',
  fontWeight: 600,
  lineHeight: '10px',
};

function RelatorioAnaliticoColumns({ redistribuirProposta }) {
  const columns = [
    {
      Header: 'Status',
      accessor: 'situacao',
      style: {
        width: 150,
        minWidth: 150,
        ...globalStyle,
        paddingInline: 16,
        paddingBlock: 2,
      },
      Cell: ({ value, row }) => {
        return (
          <a
            style={{
              width: 116,
              minWidth: 116,
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
            }}
            href={`/analise-mesa-credito/${row?.original?.propostaId}`}
            target="_blank"
            rel={'noreferrer'}
          >
            <div
              style={{
                padding: '3px 0',
                fontSize: '12px',
                backgroundColor: value.cor,
                color: '#FFF',
                border: 'none',
                cursor: 'pointer',
                width: 116,
                maxWidth: 116,
                lineHeight: '16px',
                fontWeight: 600,
              }}
              bgColor={value.cor}
            >
              {value.nome}
            </div>
          </a>
        );
      },
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      style: {
        ...globalStyle,
        width: 151,
        minWidth: 151,
      },
      Cell: ({ value }) => {
        return <span style={{ color: '#686B6D' }}>{cpfFormatter(value)}</span>;
      },
    },
    {
      Header: 'Produto',
      accessor: 'produto',
      style: {
        ...globalStyle,
        width: 86,
        minWidth: 86,
        paddingInline: 10,
      },
      Cell: ({ value }) => {
        return <span style={{ color: '#686B6D' }}>{value}</span>;
      },
    },
    {
      Header: 'Analista',
      accessor: 'analista',
      style: {
        ...globalStyle,
        width: 150,
        minWidth: 150,
      },
      Cell: ({ value }) => {
        return value.nome !== null ? (
          <span style={{ color: '#686B6D' }}>{value.nome}</span>
        ) : (
          <span>{''}</span>
        );
      },
    },
    {
      Header: 'Supervisor',
      accessor: 'supervisor',
      style: {
        ...globalStyle,
        width: 150,
        minWidth: 150,
      },
      Cell: ({ value }) => {
        return <span style={{ color: '#686B6D' }}>{value.nome}</span>;
      },
    },

    {
      Header: 'Espera',
      accessor: 'espera',
      style: {
        ...globalStyle,
        width: 80,
        minWidth: 80,
      },

      Cell: ({ value }) => {
        const tempoFormatado = value?.length === 5 ? '00:' + value : value;
        return value !== 'Não iniciado' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClockIcon style={{ color: '#686B6D' }} />
            <span style={{ ...timeStyle, marginLeft: 4 }}>
              {tempoFormatado}
            </span>
          </div>
        ) : (
          <span style={{ ...timeStyle }}>{value}</span>
        );
      },
    },
    {
      Header: 'Distribuição',
      accessor: 'distribuicao',
      style: {
        ...globalStyle,
        width: 109,
        minWidth: 109,
      },
      Cell: ({ value }) => {
        const tempoFormatado = value?.length === 5 ? '00:' + value : value;

        return value !== 'Não Iniciado' && value !== '-' ? (
          <div>
            <ClockIcon style={{ color: '#686B6D' }} />
            <span style={{ ...timeStyle, marginLeft: 4 }}>
              {tempoFormatado}
            </span>
          </div>
        ) : (
          <span style={{ ...timeStyle }}>{value}</span>
        );
      },
    },
    {
      Header: 'Análise',
      accessor: 'analise',
      style: {
        ...globalStyle,
        width: 119,
        minWidth: 119,
        paddingInline: 29,
      },
      Cell: ({ value, row }) => {
        const tempoFormatado =
          value.tempo?.length === 5 ? '00:' + value.tempo : value.tempo;

        return value.tempo !== '-' ? (
          <div
            style={{
              borderRadius: '4px',
              color: '#FFF',
              backgroundColor: row?.original?.analise?.cor,
              width: 68,
              height: 20,
              padding: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginInline: 'auto',
            }}
          >
            <ClockIcon style={{ color: '#FFFFFF' }} />
            <span style={{ fontSize: 10, marginLeft: 4, lineHeight: '10px' }}>
              {tempoFormatado}
            </span>
          </div>
        ) : (
          <span>{value.tempo}</span>
        );
      },
    },
    {
      Header: ' ',
      accessor: 'redistribuir',
      style: {
        width: 35,
        minWidth: 35,
      },
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const propostaId = row?.original?.propostaId;

        return (
          <div
            style={{ textAlign: 'center', cursor: 'pointer' }}
            onClick={() => redistribuirProposta(propostaId)}
          >
            {value === true ? (
              <img
                style={{ width: '15px', height: '15px' }}
                src={iconRedistribuir}
                alt='icone redistribuir'
              />
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      Header: 'Operatividade',
      accessor: 'operatividade',

      style: {
        ...globalStyle,
        width: 151,
        minWidth: 151,
        paddingInline: 10,
      },

      Cell: ({ value, row }) => {
        const { motivo } = row?.original?.operatividade;
        return value.situacao === 'Inativo' && value.motivo !== null ? (
          <div
            style={{
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              color: '#686B6D',
            }}
          >
            <span>{value.situacao}</span>
            <div
              style={{
                position: 'absolute',
                right: '34px',
                display: 'flex',
                color: '#686B6D',
              }}
            >
              <Tooltip
                placement="top"
                className="tooltip-info"
                overlayClassName="tooltip-proposta-credito"
                color="#435361"
                title={<div>{motivo}</div>}
              >
                <img
                  src={BulletInfo}
                  style={{
                    marginLeft: 7.5,
                    width: 12,
                    height: 11,
                  }}
                  alt='bullets info'
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          <div style={{ fontSize: 13, color: '#686B6D' }}>{value.situacao}</div>
        );
      },
    },
    {
      Header: 'Prioridade',
      accessor: 'prioridade',
      style: {
        ...globalStyle,
        width: 100,
        minWidth: 100,
      },
      Cell: ({ value, row }) => {
        return value.descricao !== '-' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: 4,
                backgroundColor: row?.original?.prioridade?.cor,
                width: 61,
                height: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 'normal',
              }}
            >
              <p
                style={{
                  fontSize: 10,
                  color: '#fff',
                  marginBottom: 0,
                  fontWeight: 600,
                }}
              >
                {value.descricao}
              </p>
            </div>
          </div>
        ) : (
          <span>{value.descricao}</span>
        );
      },
    },
  ];

  return columns;
}

export default RelatorioAnaliticoColumns;
