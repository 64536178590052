import React, { useState, useEffect, useContext, useRef } from 'react';
import InputMask from 'react-text-mask';
import { Form, Input } from 'formik-antd';
import { Select } from 'antd';
import Title from '../../../components/Title';
import { Field, useFormik, FormikProvider, ErrorMessage } from 'formik';
import { Modal, Switch, Collapse } from 'antd';
import { cepMaskAlt } from '../../../utils/masks';
import Table from '../../../components/BasicTable';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { CaretRightOutlined } from '@ant-design/icons';
import Button from '../../../components/Button';
import DatePickerComponent from '../../../components/DatePicker';
import SimpleInput from '../../../components/SimpleInput';
import ModalAlerta from '../../../components/Modal';
import {
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../../../services/enderecoService';

import RegioesForm from './RegioesForm';
import ProdutosForm from './ProdutosForm';

import {
  validationSchemaUnidadesConvenio,
  validationSchemaUnidades,
} from './validationSchema';
import validaCNPJ from '../../../utils/validaCnpj';
import validaCPF from '../../../utils/validaCpf';
import { cnpjMask } from '../../../utils/masks';

import initialValues from './initialValues';

import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';

import './styles.scss';
import {
  getHierarquiaCargos,
  validarVigencia,
} from '../../../services/credenciadoService';
import moment from 'moment';
import SelectSearch from '../../../components/SearchSelect';
import removeChars from '../../../utils/removeChars';
import TableLogs from '../../../components/TableLogs';
import { SubTipoConvenio, TipoCredenciado } from '../../../utils/identificadoresFrontBack';

const { Panel } = Collapse;
const { Option } = Select;

function ModalUnidades({
  title,
  type,
  produtosRegiao,
  unidadeInfos,
  regioesCredenciado,
  tiposModalidade,
  nomeMatriz,
}) {
  const inputEl = useRef(null);
  const {
    lstRandomIdsUnidadesTree,
    unidades,
    setUnidades,
    typeModal,
    unidadeId,
    visibleModalUnidades,
    setVisibleModalUnidades,
    findUnidade,
    setUnidadesFiltradas,
    filtroAtual,
    setFiltroAtual,
    handlePesquisar,
    findNovo,
    filtraListagem,
    visibleModalDiscard,
    setVisibleModalDiscard,
    infoUsuario,
    isCpfCnpjUnidadeValid,
    cpfCnpjUnidadesCadastradas,
    setCpfCnpjUnidadesCadastradas,
    formik: formikCredenciado,
    swapElement,
    credenciamentoHierarquias,
    inativaSubunidades,
  } = useContext(CredenciadoContext);

  const modalidadeProdutoMatriz =
    formikCredenciado?.values?.produtos?.tipoModalidade;

  const [visibleModalErrorCpfCnpj, setVisibleModalErrorCpfCnpj] =
    useState(false);

  const [visibleInativarUnidade, setVisibleInativarUnidade] = useState(false);
  const [
    visibleInativarUnidadeSubunidade,
    setVisibleInativarUnidadeSubunidade,
  ] = useState(false);
  const [cpfCnpjError, setCpfCnpjError] = useState(false);

  const isConveniadaConsigPrivado =
    formikCredenciado?.values?.dadosGerais.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
    formikCredenciado?.values?.dadosGerais.subTipoCredenciado ===
      SubTipoConvenio.CONSIGNADO_PRIVADO_ID;

  const schemaCorreto = () => {
    if (isConveniadaConsigPrivado) {
      return validationSchemaUnidadesConvenio;
    } else {
      return validationSchemaUnidades;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schemaCorreto(),
    // Desliga validação automatica para consignado privado
    validateOnChange: !isConveniadaConsigPrivado
  });

  const { setFieldValue, values, setValues, getFieldHelpers } = formik;
  const [valueCpfCnpj, setValuecpfCnpj] = useState();

  const [isFormEdited, setIsFormEdited] = useState(false);

  const [lstColumns, setLstColumns] = useState([]);
  const [lstData, setLstData] = useState([]);
  const [showDataVigencia, setShowDataVigencia] = useState(false);
  const [flagInativarSubunidades, setFlagInativarSubunidades] = useState(false);

  useEffect(() => {
    if (
      (unidadeInfos?.unidadeId || unidadeInfos?.dadosGerais) &&
      type !== 'add'
    ) {
      // desvincular o values do formik dos dados recebidos pela API
      const unidadeInfosClone = JSON.parse(JSON.stringify(unidadeInfos));

      setValues(unidadeInfosClone);

      if (
        unidadeInfosClone?.produtos?.produtos == null &&
        !isConveniadaConsigPrivado
      ) {
        unidadeInfosClone.produtos.produtos = [];
        setFieldValue('produtos.produtos', []);
      }

      // eslint-disable-next-line
      unidadeInfosClone?.dadosGerais?.cpfCnpj != undefined &&
        setFieldValue(
          'dadosGerais.cpfCnpj',
          handleCpfCnpj(unidadeInfosClone?.dadosGerais?.cpfCnpj),
        );
      // eslint-disable-next-line
      unidadeInfosClone?.endereco?.cep != undefined &&
        setFieldValue(
          'endereco.cep',
          handleCep(unidadeInfosClone?.endereco?.cep),
        );

      setFieldValue('comercial', unidadeInfosClone.comercial);
      let qtdColumns = 0;
      unidadeInfosClone?.comercial?.historico?.forEach((item) => {
        if (item?.niveis?.length > qtdColumns) {
          qtdColumns = item.niveis.length;
        }
      });
      let dataAux = [];
      unidadeInfosClone?.comercial?.historico?.forEach((item) => {
        // eslint-disable-next-line
        const { niveis, ...itens } = item;
        const dataTable = {
          hierarquia: item.hierarquiaNome,
          inicioVigencia: item.dataInicio
            ? moment(item.dataInicio).format('DD/MM/YYYY')
            : null,
          fimVigencia: item.dataFim
            ? moment(item.dataFim).format('DD/MM/YYYY')
            : null,
        };
        const newColumns = [
          {
            Header: 'Hierarquia',
            accessor: 'hierarquia',
          },
          {
            Header: 'Início de vigência',
            accessor: 'inicioVigencia',
          },
          {
            Header: 'Fim de vigência',
            accessor: 'fimVigencia',
          },
        ];
        for (let i = 0; i < qtdColumns; i++) {
          if (i < niveis.length) {
            dataTable[`hierarquia${i + 1}`] = niveis[i];
            newColumns.push({
              Header: `${i + 1}º NÍVEL`,
              accessor: `hierarquia${i + 1}`,
            });
          }
        }
        setLstColumns([...newColumns]);
        dataAux.push(dataTable);
      });
      setLstData(dataAux);

      getUsuarioHierarquiaNiveis(unidadeInfosClone);
    }
    if (
      modalidadeProdutoMatriz !== null &&
      typeof modalidadeProdutoMatriz !== 'undefined' &&
      typeof unidadeInfos?.produtos?.tipoModalidade !== 'number'
    ) {
      setFieldValue('produtos.tipoModalidade', modalidadeProdutoMatriz);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unidadeInfos]);

  async function atualizarHierarquia(hierarquiaId) {
    // atualiza somente a hierarquia
    if (hierarquiaId) {
      getHierarquiaCargos(hierarquiaId).then(({ proximoHierarquiaNivel }) => {
        if (proximoHierarquiaNivel)
          setFieldValue('comercial.lstProximoNivel', [proximoHierarquiaNivel]);
        setFieldValue('comercial.hierarquia.niveis', []);
      });
      setShowDataVigencia(false);
    }
  }

  async function atualizarNiveis(nivelId, posicaoId) {
    // atualiza o nível atual para pegar o próximo select
    const hierarquiaId = formik?.values?.comercial?.hierarquia?.id;
    const lstProximoNivel = formik?.values?.comercial?.lstProximoNivel; // => array com listagem de selects na tela
    const niveis = formik?.values?.comercial?.hierarquia?.niveis;
    const indexItemAtual = lstProximoNivel
      ?.map((item) => item.id)
      .indexOf(nivelId);
    const isUltimoItemSelect =
      nivelId === lstProximoNivel[lstProximoNivel.length - 1].id;
    if (nivelId && posicaoId && hierarquiaId) {
      getHierarquiaCargos(hierarquiaId, nivelId, posicaoId).then(
        ({ proximoHierarquiaNivel }) => {
          if (proximoHierarquiaNivel !== null) {
            if (isUltimoItemSelect) {
              setFieldValue('comercial.lstProximoNivel', [
                ...lstProximoNivel,
                proximoHierarquiaNivel,
              ]);
            } else {
              const selectsFiltrados = [...lstProximoNivel];
              const niveisFiltrados = [...niveis];
              selectsFiltrados.length = indexItemAtual + 1; // 'corta' o array até o select modificado
              niveisFiltrados.length = indexItemAtual + 1; //  'corta' o array de níveis também
              setFieldValue('comercial.lstProximoNivel', [
                ...selectsFiltrados,
                proximoHierarquiaNivel,
              ]);
              setFieldValue('comercial.hierarquia.niveis', [
                ...niveisFiltrados,
              ]);
            }
            setShowDataVigencia(false);
          } else {
            setShowDataVigencia(true);
          }
        },
      );
    }
  }

  async function getUsuarioHierarquiaNiveis(unidadeInfos) {
    // atualizar ambos hierarquia e níveis - caso seja em edição de usuário
    const hierarquiaId = unidadeInfos?.comercial?.hierarquia?.id;
    const niveis = unidadeInfos?.comercial?.hierarquia?.niveis;
    let lstProximoNivel;
    setShowDataVigencia(true); // usuários cadastrados possuem hierarquia completa
    if (niveis) setFieldValue('comercial.hierarquia.niveis', [...niveis]);

    if (hierarquiaId) {
      await getHierarquiaCargos(hierarquiaId).then(
        ({ proximoHierarquiaNivel }) => {
          if (proximoHierarquiaNivel) {
            lstProximoNivel = proximoHierarquiaNivel;
            setFieldValue('comercial.lstProximoNivel', [
              proximoHierarquiaNivel,
            ]);
          }
        },
      );

      if (lstProximoNivel) {
        const outrasHierarquiasPromises = niveis?.map(async (nivel) => {
          const res = await getHierarquiaCargos(
            hierarquiaId,
            nivel.id,
            nivel.posicaoId,
          );
          return res.proximoHierarquiaNivel;
        });
        const outrasHierarquiasResponse = await Promise.all(
          outrasHierarquiasPromises,
        );

        await Promise.all(outrasHierarquiasResponse);
        await setFieldValue('comercial.lstProximoNivel', [
          lstProximoNivel,
          ...outrasHierarquiasResponse.filter((item) => item !== null),
        ]);
      }
    }
  }

  const handleCep = (value) => {
    let data = value.replace(/\D/g, '');
    setIsFormEdited(true);
    return `${data.substr(0, 5)}-${data.substr(5, 7)}`;
  };

  async function validateCpfCnpjUnidade() {
    let cpfCnpj = formik.values?.dadosGerais?.cpfCnpj;
    const cpfCnpjOriginalEdicao = !!unidadeInfos?.dadosGerais?.cpfCnpj
      ? removeChars(['.', '-', '/'], unidadeInfos?.dadosGerais?.cpfCnpj)
      : null;
    cpfCnpj = removeChars(['.', '-', '/'], cpfCnpj);
    if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
      if (cpfCnpjOriginalEdicao && cpfCnpj === cpfCnpjOriginalEdicao) {
        setCpfCnpjError(false);
        return;
      }

      const tipo = formikCredenciado?.values?.dadosGerais?.tipoCredenciado;
      const subtipo = formikCredenciado?.values?.dadosGerais?.subTipoCredenciado;

      const { status } = await isCpfCnpjUnidadeValid(cpfCnpj, tipo, subtipo);
      if (!status) {
        setVisibleModalErrorCpfCnpj(true);
        setCpfCnpjError(true);
      } else if (cpfCnpjUnidadesCadastradas.includes(cpfCnpj)) {
        setVisibleModalErrorCpfCnpj(true);
        setCpfCnpjError(true);
      } else {
        setCpfCnpjError(false);
      }
    }
  }

  const handleCpfCnpj = (value) => {
    let data = value.replace(/\D/g, ''); // apenas dígitos
    if (data.length > 11) {
      // CNPJ
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3,
      )}/`; // ainda exibe máscara de CPF
      if (data.length > 12)
        // exibe máscara CNPJ
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      else cnpj += data.substr(8);
      data = cnpj;
    } else {
      // CPF
      let cpf = '';
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
      }
      data = cpf;
    }
    setValuecpfCnpj(data);
    setIsFormEdited(true);
    return data;
  };
  const handleCancel = () => {
    if (type === 'read') {
      setVisibleModalUnidades(false);
    } else {
      if (isFormEdited) {
        setVisibleModalDiscard(true);
      } else {
        setVisibleModalUnidades(false);
      }
    }
  };

  function handleCancelAlter() {
    setVisibleModalDiscard(false);
  }

  function handleDiscard() {
    setVisibleModalDiscard(false);
    setVisibleModalUnidades(false);
    setFlagInativarSubunidades(false);
  }

  // Regiões

  const [estados, setEstados] = useState([]);


  const fetchEstados = async () => {
    const response = await listarUfs();
    setEstados(response);
  };



  //  Endereço
  const [cepInvalido, setCepInvalido] = useState(false);
  const [cidadesEndereco, setCidadesEndereco] = useState([]);
  const [cepInvalidoStatus, setCepInvalidoStatus] = useState('');
  const [initialFormValues, setInitialFormValues] = useState();

  const fetchCidadesEndereco = async (ufId) => {
    const response = await listarCidades(ufId);
    setCidadesEndereco(response);
  };

  useEffect(() => {
    if (values?.endereco?.ufId) {
      fetchCidadesEndereco(values?.endereco?.ufId);
    }
  }, [values?.endereco?.ufId]);

  useEffect(() => {
    // rodar somente na primeira vez (edição de usuário) para popular os campos já preenchidos anteriormente
    let firstRun;
    if (values?.comercial?.hierarquia?.id && firstRun !== false) {
      firstRun = false;
      setInicioVigencia(
        moment(values?.comercial?.hierarquia?.dataVigenciaInicial),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.comercial?.hierarquia?.id]);

  useEffect(() => {
    if (formik.errors?.dadosGerais?.cpfCnpj && !formik.values.dadosGerais.cpfCnpj) {
      setMessageCnpj('Campo obrigatório');
      setMessageStatusCnpj('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.errors])

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    if (response?.success) {
      return response.data;
    }
  };
  const handleConsultarCEP = async (value) => {
    setCepInvalido(false);
    setFieldValue('endereco.ufId', '');
    setFieldValue('endereco.cidadeId', '');
    setFieldValue('endereco.logradouro', '');
    setFieldValue('endereco.bairro', '');
    if (value && value.replace(/\D/g, '').length === 8) {
      setCepInvalido(false);
      value = value.replace(/\D/g, '');
      const data = await fetchEndereco(value);
      if (data?.status) {
        await fetchCidadesEndereco(data.ufId);
        setFieldValue('endereco.ufId', data.ufId);
        setFieldValue('endereco.cidadeId', data.cidadeId);
        setFieldValue('endereco.logradouro', data.logradouro);
        setFieldValue('endereco.bairro', data.bairro);
        setFieldValue('endereco.cep', data.cep);
        setCepInvalidoStatus(null);
      } else {
        setFieldValue('endereco.cep', value);
        setCepInvalidoStatus('error');
        setCepInvalido(true);
      }
    } else if (value.replace(/\D/g, '').length === 0) {
      setCepInvalidoStatus('');
      setCepInvalido(false);
      setFieldValue('endereco.cep', value);
    } else {
      setFieldValue('endereco.cep', value);
      setCepInvalidoStatus('warning');
    }
    setIsFormEdited(true);
  };

  const [messageCnpj, setMessageCnpj] = useState(null);
  const [messageStatusCnpj, setMessageStatusCnpj] = useState(null);

  const handleBlurCpfCnpj = async (value, name) => {
    setMessageCnpj(null);
    setMessageStatusCnpj(null);
    setIsFormEdited(true);
    // eslint-disable-next-line
    if (value == '' && isConveniadaConsigPrivado) {
      setMessageCnpj('Campo obrigatório');
      setMessageStatusCnpj('error');
      return;
    }

    if (value !== '') {
      let noMask = value.replace(/[^0-9]/g, '');
      genericTouched(name);
      let valida = false;
      // eslint-disable-next-line
      if (noMask.length == 14) {
        valida = validaCNPJ(noMask);
        // eslint-disable-next-line
      } else if (noMask.length == 11) {
        valida = validaCPF(noMask);
      } else {
        setMessageCnpj('CPF/CNPJ inválido');
        setMessageStatusCnpj('warning');
      }
      // eslint-disable-next-line
      if (valida == false) {
        setMessageCnpj('CPF/CNPJ inválido');
        setMessageStatusCnpj('warning');
        return;
      }
    }
    validateCpfCnpjUnidade();
  };

  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }

  function handleClickErrorCpfCnpj() {
    setVisibleModalErrorCpfCnpj(false);
    setTimeout(() => {
      inputEl?.current?.focus();
    }, 600);
  }

  function validateOtherFields() {
    if (formik?.values?.regioes?.length) {
      let regioesErrors = false;
      formik?.values?.regioes?.forEach((item) => {
        if (item.ufId == null) {
          ModalAlerta('', 'É necessário preencher a UF da região.', 'warning');
          regioesErrors = true;
          return;
        }
      });
      if (regioesErrors) return false;
    }

    if (formik?.values?.produtos?.produtos?.length) {
      let produtoErrors = [];
      formik?.values?.produtos?.produtos?.forEach((item, i) => {
        const produtoRegiao = produtosRegiao?.find(
          // eslint-disable-next-line
          (produto) => produto.id == item.id,
        );
        const produtoRegiaoPropriedade = produtoRegiao?.propriedade
        if (
          produtoRegiao.nome !== "Consignado Privado" &&
          produtoRegiaoPropriedade && !item?.propriedade?.opcoes?.length
          ) {
          produtoErrors.push({ propriedade: { opcoes: 'Campo obrigatório' } });
        } else if (item.id == null) {
          produtoErrors.push({ id: 'Campo obrigatório' });
        } else {
          produtoErrors.push({});
        }
      });
      if (!!produtoErrors.find((item) => Object.keys(item).length)) {
        ModalAlerta(
          '',
          'É necessário preencher todos os campos do produto.',
          'warning',
        );
        formik.setErrors({
          produtos: { produtos: [...produtoErrors] },
          ...formik.errors,
        });
        return false;
      }
    }
    return true;
  }

  async function submitForm() {
    const errors = await formik.validateForm().then((errors) => {
      formik.setTouched({ ...errors }, true);
      return errors;
    });
    if (!Object.keys(errors).length) {
      const errors = await formik
        .validateForm()
        .then((errors) => formik.setTouched({ ...errors }, true));

      formik.setTouched(errors, true);

      if (!Object.keys(errors).length) {
        if (
          formik?.values?.produtos?.produtos?.length &&
          formik?.values?.produtos?.tipoModalidade == null
        ) {
          ModalAlerta(
            '',
            'É necessário escolher o tipo de modalidade dos produtos selecionados para prosseguir.',
            'warning',
          );
        } else if (validateOtherFields()) {
          let randomId = Math.round(Math.random() * -1000 - 1);
          while (lstRandomIdsUnidadesTree.includes(randomId)) {
            randomId = Math.round(Math.random() * -1000 - 1);
          }
          const valuesToSend = {
            ...formik.values,
          };

          if (!isConveniadaConsigPrivado) {
            valuesToSend.produtos = {
              produtos: [...formik.values.produtos.produtos],
              tipoModalidade: values?.produtos?.tipoModalidade,
            };
          }

          const body = {
            ...valuesToSend,
            dadosGerais:
              {
                ...formik?.values?.dadosGerais,
                cpfCnpj: removeChars(
                  ['-', '/', '.'],
                  formik?.values?.dadosGerais?.cpfCnpj,
                ),
              } || null,
            id: randomId,
            nome: formik.values?.dadosGerais?.nome,
            ativo: formik.values?.dadosGerais?.ativo,
            subUnidade: [],
            novo: true,
            visited: true,
            editado: true,
          };

          const newUnidades = unidades;
          const novo = findNovo(newUnidades);
          if (novo !== '' && novo?.novo) {
            novo.novo = null;
          }
          const unidade = findUnidade(newUnidades, unidadeId);

          // eslint-disable-next-line
          if (typeModal == 'add') {
            if (unidade) {
              if (!unidade.subUnidade) {
                unidade['subUnidade'] = [];
              }
              unidade['subUnidade'] = [...unidade?.subUnidade, body];
              setUnidades([...newUnidades]);
              handlePesquisar(filtroAtual);
            } else {
              setUnidades([...newUnidades, body]);
              setFiltroAtual({ unicaUnidade: body.id });
              setUnidadesFiltradas(
                filtraListagem({ unicaUnidade: body.id }, [
                  ...newUnidades,
                  body,
                ]),
              );
            }
          } else {
            let parent = findUnidade(newUnidades, unidadeId, 'parent');
            let parentIndex = newUnidades.indexOf(parent);
            if (unidade) {
              // eslint-disable-next-line
              if (parent && parent.id != unidadeId) {
                const elemToSwap = findUnidade(newUnidades, unidadeId);

                body.id = elemToSwap?.id;
                body.subUnidade = elemToSwap?.subUnidade;

                parent = swapElement(parent, elemToSwap, body);

                newUnidades.splice(parentIndex, 1, parent);
              } else {
                // eslint-disable-next-line
                const child = newUnidades.find((item) => item.id == unidadeId);
                body.id = unidadeId;
                body['subUnidade'] = child.subUnidade;
                newUnidades.splice(newUnidades.indexOf(child), 1, body);
              }
            } else {
              // eslint-disable-next-line
              const unidade = newUnidades.find((item) => item.id == unidadeId);
              body['id'] = unidadeId;
              body['subUnidade'] = unidade.subUnidade;
              newUnidades.splice(unidades.indexOf(unidade), 1, body);
            }

            if (flagInativarSubunidades) {
              let elemToInactivate = findUnidade(newUnidades, unidadeId);
              elemToInactivate.subUnidade = inativaSubunidades(
                elemToInactivate.subUnidade,
              );
              parent = swapElement(parent, elemToInactivate, elemToInactivate);
              newUnidades.splice(parentIndex, 1, parent);
            }

            setUnidades(newUnidades);
            setFiltroAtual({ unicaUnidade: body.id });
            setUnidadesFiltradas(
              filtraListagem({ unicaUnidade: body.id }, newUnidades),
            );
          }
          if (
            !cpfCnpjUnidadesCadastradas.includes(body?.dadosGerais?.cpfCnpj)
          ) {
            setCpfCnpjUnidadesCadastradas((prev) => [
              ...prev,
              body?.dadosGerais?.cpfCnpj,
            ]);
          }
          setVisibleModalUnidades(false);
          setTimeout(() => {
            document
              ?.getElementById('info-tree-unidades-' + unidadeId)
              ?.parentElement?.parentElement?.parentElement.querySelector(
                '.ant-tree-switcher_close',
              )
              ?.click();
          }, 500);
        }
      }
    }
  }

  useEffect(() => {
    if (!initialFormValues) {
      setInitialFormValues(JSON.parse(JSON.stringify(unidadeInfos)));
    }
    fetchEstados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeAtivo(value) {
    if (!value) {
      // eslint-disable-next-line
      if (unidadeInfos.subUnidade != null) {
        let hasActive = unidadeInfos.subUnidade.filter((item) => {
          return item.ativo;
        });
        hasActive.length > 0
          ? setVisibleInativarUnidadeSubunidade(true)
          : setVisibleInativarUnidade(true);
      } else {
        setVisibleInativarUnidade(true);
      }
    }
    setIsFormEdited(true);
  }

  function validacaoVigencia(tipoVigencia) {
    const { touched, errors } = formik;
    if (vigenciaInvalida) {
      return { message: '', status: '' };
    }

    if (!moment(inicioVigencia).isBefore(fimVigencia) && fimVigencia) {
      if (tipoVigencia === 'fim') {
        return {
          message: 'Data inválida',
          status: 'warning',
        };
      }
    }
    if (tipoVigencia === 'inicio') {
      if (
        touched?.comercial?.hierarquia?.dataVigenciaInicial &&
        errors?.comercial?.hierarquia?.dataVigenciaInicial
      ) {
        return { message: 'Campo Obrigatório', status: 'error' };
      }
    }

    return { message: '', status: '' };
  }

  function formatarData(data) {
    return moment(data).format('YYYYMMDD');
  }

  const [vigenciaInvalida, setVigenciaInvalida] = useState(false);

  const [inicioVigencia, setInicioVigencia] = useState(moment());
  const [fimVigencia, setFimVigencia] = useState(null);

  function validarVigenciaConcomitante(type, date) {
    if (unidadeInfos?.unidadeId) {
      if (type === 'fim' && moment(date).isValid()) {
        validarVigencia(
          unidadeId,
          values?.comercial?.hierarquia?.id,
          null, // vigencia inicial, alinhado com o back
          formatarData(date),
        ).then((response) => {
          if (!response) {
            setVigenciaInvalida(true);
            setFimVigencia(null);
            setFieldValue('comercial.hierarquia.dataVigenciaFinal', null);
          }
        });
      } else if (type === 'inicio' && moment(date).isValid()) {
        validarVigencia(
          unidadeId,
          values?.comercial?.hierarquia?.id,
          formatarData(date),
          null, // vigencia final, alinhado com o back
        ).then((response) => {
          if (!response) {
            setVigenciaInvalida(true);
            setInicioVigencia(null);
            setFieldValue('comercial.hierarquia.dataVigenciaInicial', null);
          }
        });
      }
    }
  }
  function validationHierarquia(value, index) {
    let error = { message: '', error: '' };
    if (!value) {
      error = { message: 'Campo Obrigatório', status: 'error' };
      formik.setFieldError(
        `comercial.hierarquia.niveis[${index}]`,
        'Campo Obrigatório',
      );
    }
    return error;
  }

  useEffect(() => {
    if (visibleModalUnidades) {
      document
        .getElementsByClassName('ant-modal-wrap')[0]
        .addEventListener('scroll', function () {
          if (
            document.activeElement.parentElement.classList.value ===
            'ant-form-item-control-input-content'
          ) {
            document.activeElement.blur();
          }
        });
    }
  }, [visibleModalUnidades]);

  return (
    <>
      <Modal
        visible={visibleModalDiscard}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={handleCancelAlter}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Deseja descartar as alterações?</div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={handleCancelAlter}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={handleDiscard}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>
      <Modal
        visible={vigenciaInvalida}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {
          setVigenciaInvalida(false);
        }}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          Credenciado não pode pertencer a duas hierarquias ao mesmo tempo.
          Verifique as datas e tente novamente!
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {
              setVigenciaInvalida(false);
            }}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        visible={visibleModalErrorCpfCnpj}
        onCancel={handleClickErrorCpfCnpj}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          CPF/CNPJ já cadastrado para correspondente ativo
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={handleClickErrorCpfCnpj}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>

      <Modal
        visible={visibleInativarUnidadeSubunidade}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setVisibleInativarUnidadeSubunidade(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          Existe(m) subunidade(s) associadas à unidade. Se confirmar a
          inativação dela, a(s) subunidade(s) também serão inativadas. Confirma
          a inativação da unidade?
        </div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => setVisibleInativarUnidadeSubunidade(false)}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => {
              setFieldValue('dadosGerais.ativo', false);
              setVisibleInativarUnidadeSubunidade(false);
              setFlagInativarSubunidades(true);
            }}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>
      <Modal
        visible={visibleInativarUnidade}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setVisibleInativarUnidade(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Confirma a inativação da unidade?</div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => setVisibleInativarUnidade(false)}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => {
              setFieldValue('dadosGerais.ativo', false);
              setIsFormEdited(true);
              setVisibleInativarUnidade(false);
            }}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>
      <Modal
        title={<Title label={title} />}
        visible={visibleModalUnidades}
        zIndex={10}
        width={745}
        footer={null}
        onCancel={handleCancel}
        id="ant-modal-id-unidade"
      >
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="col-modal-cadastro-unidade">
              <div className="dados-gerais">
                <div className="form-row header-sub-item pt-4 pb-3">
                  <div className="Container ContainerColor mt-1"></div>
                  <div className="row pl-4 titleMsg">Dados Gerais</div>
                  <div className="switch-dado">
                    <Form.Item name="dadosGerais.ativo">
                      <Switch
                        size="small"
                        className="switch-red-if-off"
                        name="dadosGerais.ativo"
                        checked={values?.dadosGerais?.ativo}
                        onChange={(value) => handleChangeAtivo(value)}
                        disabled={typeModal === 'read'}
                      />
                      <label>Ativo</label>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-row row">
                  {/* eslint-disable-next-line  */}
                  <div className={type == 'Unidade' ? 'col-lg-12' : 'col-lg-6'}>
                    <Form.Item name="dadosGerais.nomeMatriz" label="Matriz">
                      <Input
                        type="text"
                        name="dadosGerais.nomeMatriz"
                        placeholder="Nome da Matriz"
                        value={nomeMatriz || infoUsuario?.dadosGerais?.nome}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  {/* eslint-disable-next-line  */}
                  {type == 'Subunidade' && (
                    <div className="col-lg-6">
                      <Form.Item
                        name="dadosGerais.nomeUnidadePai"
                        label="Unidade"
                      >
                        <Input
                          type="text"
                          name="dadosGerais.nomeUnidadePai"
                          placeholder="Nome da Unidade"
                          disabled
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div className="col-lg-6">
                    <Form.Item
                      name="dadosGerais.cpfCnpj"
                      label="CPF/CNPJ"
                      validateStatus={messageStatusCnpj}
                      help={messageCnpj}
                      hasFeedback={false}
                    >
                      <Field name="dadosGerais.cpfCnpj">
                        {({ field }) => (
                          <Input
                            {...field}
                            ref={inputEl}
                            name="dadosGerais.cpfCnpj"
                            placeholder="00.000.000/0000-00"
                            className="ant-input"
                            mask={
                              unidadeInfos?.dadosGerais?.cpfCnpj && cnpjMask
                            }
                            onChange={(e) => {
                              handleCpfCnpj(e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlurCpfCnpj(e.target.value, 'cpfCnpj');
                            }}
                            value={valueCpfCnpj}
                            disabled={typeModal === 'read'}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item name="dadosGerais.nome" label="Nome">
                      <Input
                        type="text"
                        name="dadosGerais.nome"
                        placeholder="Digite aqui"
                        disabled={typeModal === 'read'}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="dados-endereco">
                <div className="form-row header-sub-item pt-4 pb-3">
                  <div className="Container ContainerColor mt-1"></div>
                  <div className="row pl-4 titleMsg">Endereco</div>
                </div>
                <div className="form-row row">
                  <div className="col-lg-4 ">
                    <Form.Item
                      label="CEP"
                      name="endereco.cep"
                      validateStatus={
                        cepInvalidoStatus === 'error'
                          ? 'error'
                          : cepInvalidoStatus === 'warning'
                          ? 'warning'
                          : 'validating'
                      }
                      help={
                        cepInvalidoStatus === 'error'
                          ? 'CEP não encontrado'
                          : cepInvalidoStatus === 'warning'
                          ? 'CEP inválido'
                          : ''
                      }
                    >
                      <InputMask
                        mask={cepMaskAlt}
                        name="endereco.cep"
                        className="ant-input"
                        placeholder="00.000-000"
                        value={values?.endereco?.cep}
                        onBlur={(e) => handleConsultarCEP(e.target.value)}
                        disabled={typeModal === 'read'}
                      />
                      <ErrorMessage name="roberto" />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item label="Logradouro" name="endereco.logradouro">
                      <Field name="endereco.logradouro">
                        {({ field }) => (
                          <SimpleInput
                            {...field}
                            type="text"
                            name="endereco.logradouro"
                            placeholder="Rua, Avenida..."
                            disabled={typeModal === 'read'}
                            onChange={() => {
                              setIsFormEdited(true);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                  <div className="col-lg-4 ">
                    <Form.Item label="Número" name="endereco.numero">
                      <Input
                        type="text"
                        name="endereco.numero"
                        placeholder="Digite aqui"
                        disabled={typeModal === 'read'}
                        onChange={() => {
                          setIsFormEdited(true);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item label="UF" name="endereco.ufId">
                      <SelectSearch
                        name="endereco.ufId"
                        placeholder="Selecione"
                        suffixIcon={<IconArrowSelect />}
                        allowClear
                        showSearch
                        value={values?.endereco?.ufId}
                        onChange={(value) => {
                          setFieldValue('endereco.ufId', value);
                          setFieldValue('endereco.cidadeId', null);
                          setIsFormEdited(true);
                        }}
                        disabled={
                          (formik?.values?.endereco?.cep && !cepInvalido) ||
                          typeModal === 'read'
                        }
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {estados?.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.uf}
                          </Option>
                        ))}
                      </SelectSearch>
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item label="Cidade" name="endereco.cidadeId">
                      <SelectSearch
                        name="endereco.cidadeId"
                        placeholder="Selecione"
                        showSearch
                        onChange={(value) => {
                          setFieldValue('endereco.cidadeId', value);
                          setIsFormEdited(true);
                        }}
                        suffixIcon={<IconArrowSelect />}
                        disabled={
                          typeModal === 'read'
                            ? true
                            : !values?.endereco?.ufId ||
                              (formik.values.endereco?.cep && !cepInvalido)
                        }
                        value={values?.endereco?.cidadeId || 'Selecione'}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {cidadesEndereco.length > 0 &&
                          cidadesEndereco?.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.nome}
                            </Option>
                          ))}
                      </SelectSearch>
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item label="Bairro" name="endereco.bairro">
                      <Field name="endereco.bairro">
                        {({ field }) => (
                          <SimpleInput
                            {...field}
                            type="text"
                            name="endereco.bairro"
                            placeholder="Digite aqui"
                            disabled={
                              (formik.values.cep && !cepInvalido) ||
                              typeModal === 'read'
                            }
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                  <div className="col-lg-12 ">
                    <Form.Item label="Complemento" name="endereco.complemento">
                      <Field name="endereco.complemento">
                        {({ field }) => (
                          <SimpleInput
                            {...field}
                            type="text"
                            name="endereco.complemento"
                            placeholder="Casa, Ap, Bloco"
                            value={values?.endereco?.complemento}
                            disabled={typeModal === 'read'}
                            onChange={() => {
                              setIsFormEdited(true);
                            }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                </div>
              </div>
              {!isConveniadaConsigPrivado && (
                <>
                  <RegioesForm
                    formik={formik}
                    regioesCredenciado={regioesCredenciado}
                    estados={estados}
                    type={typeModal}
                    setIsFormEdited={setIsFormEdited}
                  />
                  <ProdutosForm
                    formik={formik}
                    produtosLiberados={produtosRegiao}
                    tiposModalidade={tiposModalidade}
                    type={typeModal}
                    setIsFormEdited={setIsFormEdited}
                    unidadeInfos={unidadeInfos}
                  />
                  <div className="dados-hierarquia">
                    <div className="form-row header-sub-item pt-4 pb-3">
                      <div className="Container ContainerColor mt-1"></div>
                      <div className="row pl-4 titleMsg">Hierarquia</div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <Form.Item
                          name="comercial.hierarquia.id"
                          label="Hierarquia"
                        >
                          <Select
                            name="comercial.hierarquia.id"
                            onSelect={(value) => {
                              setFieldValue('comercial.hierarquia.id', value);
                              atualizarHierarquia(value);
                              setIsFormEdited(true);
                            }}
                            value={values?.comercial?.hierarquia?.id}
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            disabled={
                              typeModal === 'read' ||
                              (unidadeInfos?.unidadeId &&
                                unidadeInfos?.comercial?.hierarquia?.id)
                            }
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {credenciamentoHierarquias?.map((item) => (
                              <Option value={item.id} key={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      {
                      // eslint-disable-next-line
                      values?.comercial?.hierarquia?.id != null ? (
                        <>
                          {values?.comercial?.lstProximoNivel?.map(
                            (nivelHierarquia, index) => (
                              <div
                                className="col-lg-4"
                                key={nivelHierarquia?.id + index}
                              >
                                <Form.Item
                                  name={`comercial.hierarquia.niveis[${index}].posicaoId`}
                                  label={nivelHierarquia?.nome}
                                  validate={(value) =>
                                    validationHierarquia(value, index).message
                                  }
                                >
                                  <Select
                                    name={`comercial.hierarquia.niveis[${index}].posicaoId`}
                                    value={
                                      values?.comercial?.hierarquia?.niveis?.[
                                        index
                                      ]?.posicaoId
                                    }
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    suffixIcon={<IconArrowSelect />}
                                    placeholder="Selecione"
                                    onSelect={(selectedId) => {
                                      const niveis =
                                        values?.comercial?.hierarquia?.niveis;
                                      if (
                                        niveis.find(
                                          (item) =>
                                            item?.id === nivelHierarquia?.id,
                                        )
                                      ) {
                                        const itemSelecionadoIndex =
                                          niveis.findIndex(
                                            (item) =>
                                              item?.id === nivelHierarquia?.id,
                                          );
                                        let niveisAtualizados = niveis;
                                        niveisAtualizados[
                                          itemSelecionadoIndex
                                        ] = {
                                          id: nivelHierarquia.id,
                                          posicaoId: selectedId,
                                        };
                                        if (
                                          niveisAtualizados[
                                            itemSelecionadoIndex + 1
                                          ]
                                        ) {
                                          niveisAtualizados[
                                            itemSelecionadoIndex + 1
                                          ] = {
                                            ...niveisAtualizados[
                                              itemSelecionadoIndex + 1
                                            ],
                                            posicaoId: null,
                                          };
                                          //removendo itens do níveis após o próximo select vazio
                                          let isShowingOnScreen = true;
                                          niveisAtualizados =
                                            niveisAtualizados.filter((item) => {
                                              if (item.posicaoId === null) {
                                                isShowingOnScreen = false;
                                              }
                                              return isShowingOnScreen;
                                            });
                                        }
                                        setFieldValue(
                                          'comercial.hierarquia.niveis',
                                          niveisAtualizados,
                                        );
                                      } else {
                                        setFieldValue(
                                          'comercial.hierarquia.niveis',
                                          [
                                            ...niveis,
                                            {
                                              id: nivelHierarquia.id,
                                              posicaoId: selectedId,
                                            },
                                          ],
                                        );
                                      }

                                      atualizarNiveis(
                                        nivelHierarquia.id,
                                        selectedId,
                                      );
                                      setIsFormEdited(true);
                                    }}
                                    disabled={
                                      typeModal === 'read' ||
                                      (unidadeInfos?.unidadeId &&
                                        unidadeInfos?.comercial?.hierarquia
                                          ?.niveis)
                                    }
                                  >
                                    {nivelHierarquia?.detalhes?.map(
                                      (item, index) => {
                                        return (
                                          <Option
                                            value={item?.id}
                                            key={`proximo-nivel-${index}`}
                                          >
                                            {item?.nome}
                                          </Option>
                                        );
                                      },
                                    )}
                                  </Select>
                                </Form.Item>
                              </div>
                            ),
                          )}
                          {showDataVigencia && (
                            <>
                              <div className="col-lg-4">
                                <Form.Item
                                  name="comercial.hierarquia.dataVigenciaInicial"
                                  label="Início de Vigência"
                                  validateStatus={
                                    validacaoVigencia('inicio').status
                                  }
                                  help={validacaoVigencia('inicio').message}
                                >
                                  <DatePickerComponent
                                    format="DD/MM/YYYY"
                                    name="comercial.hierarquia.dataVigenciaInicial"
                                    placeholder="00/00/0000"
                                    onChange={(value) => {
                                      setIsFormEdited(true);
                                      setFieldValue(
                                        'comercial.hierarquia.dataVigenciaInicial',
                                        value,
                                      );
                                      setInicioVigencia(value);
                                      if (unidadeInfos.unidadeId) {
                                        validarVigenciaConcomitante(
                                          'inicio',
                                          value,
                                        );
                                      }
                                    }}
                                    disabledDate={(date) => date > moment()}
                                    disabled={
                                      typeModal === 'read' ||
                                      (unidadeInfos?.unidadeId &&
                                        unidadeInfos?.comercial?.hierarquia
                                          ?.dataVigenciaInicial)
                                    }
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-lg-4">
                                <Form.Item
                                  name="comercial.hierarquia.dataVigenciaFinal"
                                  label="Fim de Vigência"
                                  validateStatus={
                                    validacaoVigencia('fim').status
                                  }
                                  help={validacaoVigencia('fim').message}
                                >
                                  <DatePickerComponent
                                    format="DD/MM/YYYY"
                                    name="comercial.hierarquia.dataVigenciaFinal"
                                    placeholder="00/00/0000"
                                    onChange={(value) => {
                                      setIsFormEdited(true);
                                      setFieldValue(
                                        'comercial.hierarquia.dataVigenciaFinal',
                                        value,
                                      );
                                      setFimVigencia(value);
                                      if (unidadeInfos.unidadeId) {
                                        validarVigenciaConcomitante(
                                          'fim',
                                          value,
                                        );
                                      }
                                    }}
                                    disabledDate={(date) => date > moment()}
                                    disabled={typeModal === 'read'}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                      {values?.comercial?.historico?.length > 0 ? (
                        <div className="col-lg-12">
                          <div className="comercial-title">
                            Histórico de hierarquias utilizadas
                          </div>
                          <Table
                            columns={lstColumns}
                            data={lstData}
                            onLoadData={() => {}}
                            className="table table-striped"
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="dados-historico">
                {!isConveniadaConsigPrivado && (
                  <div className="form-row header-sub-item pt-4 pb-3">
                    <div className="Container ContainerColor mt-1"></div>
                    <div className="row pl-4 titleMsg">
                      Histórico da Unidade
                    </div>
                  </div>
                )}
                <div className="form-row row">
                  {!isConveniadaConsigPrivado && (
                    <div className="col-lg-12">
                      <Collapse
                        expandIconPosition="left"
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined
                            rotate={isActive ? 90 : 0}
                            style={{ color: '#ff7817' }}
                          />
                        )}
                        className="mt-4"
                      >
                        <Panel
                          header="Histórico de Alteração"
                          className="historico-dados-credenciado-panel"
                        >
                          <TableLogs idUsuario={unidadeId} tipoLog="Unidade" />
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                  {typeModal !== 'read' && (
                    <div className="col-lg-12 col-btn-cadastro">
                      <Button
                        type="button"
                        className="btn-cadastro"
                        variant="blue"
                        onClick={() => {
                          if (!isConveniadaConsigPrivado) {
                            const cepSemMascara = values?.endereco?.cep.replace(/\D/g, '');
                            if(cepSemMascara.length < 8){
                              setCepInvalidoStatus("warning")
                              return;
                            }
                          }

                          if (!cpfCnpjError) {
                            submitForm();
                          }
                        }}
                      >
                        {/* eslint-disable-next-line */}
                        {typeModal == 'add' ? 'CADASTRAR' : 'SALVAR'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
}

export default ModalUnidades;
