import { Button } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './styles.scss';

import { preAbertura } from '../../services/acompanhamentopropostaService';

function ButtonSituacao({
  link,
  linkState,
  children,
  bgColor,
  telaAnterior,
  disabled,
  acaoPreAbertura,
}) {
  const content = () => {
    return (
      <>
        <div className="border" style={{ background: bgColor }}></div>
        <div className="botao-cor" style={{ backgroundColor: bgColor }}>
          {children}
        </div>
      </>
    );
  };

  const history = useHistory();

  if (link) {
    if (acaoPreAbertura) {
      return (
        <div className="link-situacao-proposta">
          <div className="border" style={{ background: bgColor }}></div>
          <Button
            className="botao-cor"
            style={{
              backgroundColor: bgColor,
              border: 'none',
            }}
            onClick={async () => {
              await preAbertura(acaoPreAbertura).then(() => {
                history.push({
                  pathname: link,
                  state: { id: linkState },
                  search: telaAnterior ? '?origem=650' : '?origem=172',
                });
              });
            }}
          >
            {children}
          </Button>
        </div>
      );
    } else {
      return (
        <Link
          className="link-situacao-proposta"
          disabled={disabled}
          to={{
            pathname: link,
            state: { id: linkState },
            search: telaAnterior ? '?origem=650' : '?origem=172',
          }}
        >
          {content()}
        </Link>
      );
    }
  } else {
    return <div className="label-situacao-proposta">{content()}</div>;
  }
}

export default ButtonSituacao;
