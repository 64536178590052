import React, { useContext } from 'react';
import swal from 'sweetalert';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/CustomTable';

import { ReactComponent as IconTrash } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen.svg';

import {
  percentualFormat,
  currencyFormat,
} from '../../../utils/numberFormatter';

import ValoresFixosForm from './form';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';
import Overlay from '../../../components/Overlay';

function TabelaValoresFixos() {
  const {
    tabelaJurosValores,
    setTabelaJurosValores,
    setTableFormData,
    showOverlayTabelaJuros,
  } = useContext(TabelaJurosContext);

  const removeItem = (pItem) => {
    swal({
      title: '',
      text: 'Tem certeza que deseja excluir o item?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then((willDelete) => {
      if (willDelete) {
        setTabelaJurosValores(
          tabelaJurosValores.filter((item) => item !== pItem),
        );
        swal('Item excluído com sucesso', { icon: 'success' });
      }
    });
  };

  const columns = [
    {
      Header: 'Plano',
      accessor: 'plano',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Taxa',
      accessor: 'juros',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{percentualFormat(value, 4)}</div>
      ),
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      disableSortBy: true,
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: 'Valor Parcela',
      accessor: 'prestacao',
      disableSortBy: true,
      style: {
        minWidth: 80,
        cursor: 'default',
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      disableSortBy: true,
      style: {
        minWidth: 60,
        width: 60,
        textAlign: 'center',
      },
      Cell: ({ row }) => (
        <div style={{ textAlign: 'center' }}>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              removeItem(row.original);
            }}
          >
            <IconTrash width="15" />
          </a>

          <span className="m-1"></span>

          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setTableFormData({
                ...row?.original,
                oldPlano: row?.original?.plano,
                oldValor: row?.original?.valor,
                jurosFloat: row?.original?.juros,
                edit: true,
              });
            }}
          >
            <IconEdit width="15" />
          </a>
        </div>
      ),
    },
  ];

  return (
    <Overlay active={showOverlayTabelaJuros}>
      <Card title={<Title label="Tabela de Valores Fixos" />} className="flex">
        <div>
          <ValoresFixosForm />
        </div>

        <Table columns={columns} data={tabelaJurosValores} pagination />
      </Card>
    </Overlay>
  );
}

export default TabelaValoresFixos;
