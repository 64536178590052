import { ReactComponent as Icon } from '../../assets/images/icons/icon-upload-item.svg';
import { ReactComponent as Eye } from '../../assets/images/icons/icon-upload-eye.svg';
import { ReactComponent as Trashcan } from '../../assets/images/icons/icon-upload-trashcan.svg';
import { ReactComponent as Download } from '../../assets/images/icons/icon-upload-download.svg';

function UploadItem(
  file, 
  handlePreview, 
  handleRemove, 
  handleDownload,
  hideDocsPreview,
) {
  return (
    <div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture-card">
      <div class="ant-upload-list-item-info">
        <span class="ant-upload-span">
          <a
            class="ant-upload-list-item-thumbnail ant-upload-list-item-file"
            href=" "
            target="_blank"
            rel="noopener noreferrer"
          >
            {file.url &&
            file.url !== ' ' &&
            file.type &&
            file.type !== 'application/pdf' ? (
              <img
                src={file.url}
                class="ant-upload-list-item-image"
                alt="Documento"
              />
            ) : (
              <span role="img" aria-label="file" class="anticon anticon-file">
                <Icon />
              </span>
            )}
          </a>
        </span>
      </div>

      <span class="ant-upload-list-item-actions">
        {handlePreview && !hideDocsPreview && (
          <button
            title="Visualizar arquivo"
            type="button"
            class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
            onClick={() => handlePreview(file)}
          >
            <span role="img" aria-label="eye" class="anticon anticon-eye">
              <Eye />
            </span>
          </button>
        )}

        {handleRemove && file.id == null && !file.enviado && (
          <button
            title="Remover arquivo"
            type="button"
            class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
            onClick={() => handleRemove(file)}
          >
            <span
              role="img"
              aria-label="delete"
              tabindex="-1"
              class="anticon anticon-delete"
            >
              <Trashcan />
            </span>
          </button>
        )}

        {handleDownload && (
          <button
            title="Baixar arquivo"
            type="button"
            class="ant-btn ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-card-actions-btn"
            onClick={() => handleDownload(file)}
          >
            <span role="img" aria-label="eye" class="anticon anticon-eye">
              <Download fill="currentColor" />
            </span>
          </button>
        )}
      </span>
    </div>
  );
}

export default UploadItem;
