import React, { useContext } from 'react';

import { ProcessamentoCrivoContext } from '../../../contexts/ProcessamentoCrivoContext';
import logoQuadrado from '../../../assets/images/crefazon-logo-quadrado.svg';

import './styles.scss';

function HeaderRelatorio() {
  const { dataGeracao } = useContext(ProcessamentoCrivoContext);

  return (
    <div className="header">
      <div>
        <h1 className="title">Processamento do Crivo</h1>
        <p className="data">Data de geração: {dataGeracao}</p>
      </div>
      <img
        className="logo"
        src={logoQuadrado}
        alt="Crefaz - Crédito Consciente"
        width="56"
      />
    </div>
  );
}

export default HeaderRelatorio;
