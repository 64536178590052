import React from 'react';

import { percentualFormat } from '../../utils/numberFormatter';
import { ReactComponent as PieChartIcon } from '../../assets/images/icons/piechart.svg';

import './styles.scss';

function WidgetAcompanhamentoCredenciamento({
  title,
  qtdePropostas,
  total,
  color,
}) {
  return (
    <div className="widget-acompanhamento-credenciamento">
      <div className="widget-header" style={{ backgroundColor: color }}>
        <div className="widget-title">{title}</div>
      </div>
      <div className="widget-content">
        <div className="content-data-group text-right">
          <h2 className="total-value" style={{ color: '#676A6C' }}>
            {qtdePropostas}
          </h2>
        </div>

        <div className="form-row d-flex justify-content-begin">
          <p
            className="porcentagem label p-2"
            style={{ backgroundColor: '#676A6C', color: '#fff' }}
          >
            <PieChartIcon width="12" height="12" style={{ marginRight: 5 }} />
            {percentualFormat((qtdePropostas * 100.0) / total)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default WidgetAcompanhamentoCredenciamento;
