import { object, mixed, string } from 'yup';

export const validationSchema = object().shape({
  produtoId: mixed().required('O produto é obrigatório'),
  tabelaJurosId: mixed().required('A tabela de juros é obrigatória'),
  convenioId: mixed().when('permiteSelecao', (permiteSelecao) => {
    if (permiteSelecao) {
      return mixed().required('A empresa conveniada é obrigatória');
    }
  }),
  dataVencimento: string().required('A data de vencimento é obrigatória'),
  valorSolicitado: mixed().when(
    'floatValorSolicitado',
    (floatValorSolicitado) => {
      return floatValorSolicitado
        ? mixed().notRequired()
        : mixed().test(
            'always-true',
            'O valor solicitado é obrigatório',
            () => false,
          );
    },
  ),
});
