import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';
import CardsRow from './Cards';
import AcompanhamentoList from './AcompanhamentoList';
import AcompanhamentoCredenciamentoProvider from '../../contexts/AcompanhamentoCredenciamentoContext';

function AcompahamentoCredenciamento() {
  useInspinia();
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Correspondente</Breadcrumb.Item>
          <Breadcrumb.Item>Acompanhamento de Credenciamento</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <AcompanhamentoCredenciamentoProvider>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <CardsRow />
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <AcompanhamentoList />
            </div>
          </div>
        </AcompanhamentoCredenciamentoProvider>
      </Content>
    </Main>
  );
}

export default AcompahamentoCredenciamento;
