import React from 'react';
import { DatePicker } from 'formik-antd';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';

moment.updateLocale('pt', {
  weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
});

function DatePickerComponent({ ...props }) {
  return (
    <DatePicker
      {...props}
      suffixIcon={<CalendarIcon />}
      clearIcon={false}
      format={[props.format, props.format.replace(/\//g, '')]}
    ></DatePicker>
  );
}

export default DatePickerComponent;
