import React from 'react';
import ReactDOM from 'react-dom';
import ptBR from 'antd/lib/locale/pt_BR';
import { ConfigProvider } from 'antd';
import ControleAcessoProvider from './contexts/ControleAcessoContext';

import 'antd/dist/antd.css';
import './styles/main.scss';

import App from './App';

// Resolve erro devido a versão do NODE
// ResizeObserver loop completed with undelivered notifications.
window.addEventListener('error', function (e) {
  if (
    e.message ===
    'ResizeObserver loop completed with undelivered notifications.' ||
    e.message ===
    'ResizeObserver loop limit exceeded'
  ) {
    document.body.classList.add('error-body');
  } else {
    document.body.classList.contains('error-body') &&
      document.body.classList.remove('error-body');
    console.error(e.message);
  }
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});

ReactDOM.render(
  <ControleAcessoProvider>
    <ConfigProvider locale={ptBR}>
      <App />
    </ConfigProvider>
  </ControleAcessoProvider>,

  document.getElementById('root'),
);
