import React, { Fragment } from 'react';

import Sidebar from '../../containers/Sidebar';
import PageWrapper from '../../containers/Wrapper';
import Navbar from '../../containers/Navbar';

function Main({ children, style }) {
  return (
    <Fragment>
      <Sidebar style={style}/>

      <PageWrapper>
        <div className="row border-bottom" style={style}>
          <Navbar />
        </div>

        {children}
      </PageWrapper>
    </Fragment>
  );
}

export default Main;
