import React from 'react';
import moment from 'moment';
import { cpfFormatter } from '../../utils/textFormatter';
import logoQuadrado from '../../assets/images/crefazon-logo-quadrado.svg';
import ButtonSituacao from '../../components/ButtonSituacao';

import './styles.scss';

export default function HistoricoAcoesProposta() {
  const cabecObj = ['Status', 'Usuário', 'Alteração', 'Departamento'];

  const historicoAcoesProposta = JSON.parse(
    sessionStorage.getItem('historicoAcoesProposta'),
  );

  return (
    <div className="relatorio-esteira-pdf bg-white">
      <table className="tabela">
        <thead className="tHead">
          <tr>
            <td colSpan={cabecObj?.length}>
              <div className="header">
                <div>
                  <div className="title">Histórico de ações da proposta</div>
                  <div className="subtitle">
                    Proposta: {historicoAcoesProposta?.propostaId} CPF:{' '}
                    {cpfFormatter(historicoAcoesProposta?.cpf)} Nome:{' '}
                    {historicoAcoesProposta?.cliente}
                  </div>
                </div>
                <img
                  className="logo"
                  src={logoQuadrado}
                  alt="Crefaz - Crédito Consciente"
                  width="56"
                />
              </div>
            </td>
          </tr>
          <tr>
            {cabecObj.map((dado) => (
              <td className="tHeadItem">{dado}</td>
            ))}
          </tr>
        </thead>
        <tbody className="tBody">
          {historicoAcoesProposta?.logSalvamentoProposta?.map((proposta) => (
            <tr className="trBody">
              <td className="tBodyItem" style={{ width: 250 }}>
                <ButtonSituacao bgColor={proposta?.cor}>
                  <div className="btn-situacao">
                    <div>{proposta?.situacao}</div>
                  </div>
                </ButtonSituacao>
              </td>
              <td className="tBodyItem">{proposta?.login}</td>

              <td className="tBodyItem">
                {moment(proposta?.dataHora).format('DD/MM/YYYY HH:mm:ss')}
              </td>
              <td className="tBodyItem">{proposta?.departamento}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
