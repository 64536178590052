import api from './api';
 
export const listarOcupacoes = async () => {
  let ocupacao = sessionStorage.getItem('ocupacao');
 
    if(!ocupacao) {
      try {
        const { data } = await api.get('/ocupacao');
        sessionStorage.setItem('ocupacao', JSON.stringify(data.data));
        return data.data;
  } catch (error) {
    return error;
  }
}
ocupacao = JSON.parse(ocupacao)
return ocupacao;
};