import api from './api';

export const listarParametros = async () => {
  try {
    const { data } = await api.get('/Parametro');
    return data.data;
  } catch (error) {
    return error;
  }
};

export const inserirParametro = async (parametro) => {
  const { data } = await api.post('/Parametro', parametro);
  return data;
};

export const buscarParametro = async (codigo) => {
  try {
    const { data } = await api.get(`/Parametro?codigo=${codigo}`);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const buscarParametroAtualFuturo = async (id) => {
  try {
    const { data } = await api.get(`/parametro-atual-futuro/${id}`);
    return data.data;
  } catch (error) {
    return error;
  }
};

export const atualizarParametro = async (parametro) => {
  try {
    const { data } = await api.put(`/Parametro/${parametro.id}`, parametro);
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteParametroHistorico = async (id) => {
  try {
    const { data } = await api.delete(`/parametro-historico/${id}`);
    return data;
  } catch (error) {
    return error;
  }
};
