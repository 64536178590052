import React, { useContext } from 'react';

import useInspinia from '../../hooks/useInspinia';

import RelatorioAnaliticoProvider from '../../contexts/RelatorioAnaliticoContext';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';

import Content from '../../components/Content';

import RelatorioAnalitico from './RelatorioAnalitico';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function RelatorioAnaliticoProposta() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
          <Breadcrumb.Item>Visão Analítica</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <RelatorioAnaliticoProvider>
          {checarPermissao(
            'tela',
            'tela.credito.relatorio.visaoAnalitica',
            'Visualizar',
          ) && (
            <>
              <RelatorioAnalitico />
            </>
          )}
        </RelatorioAnaliticoProvider>
      </Content>
    </Main>
  );
}

export default RelatorioAnaliticoProposta;
