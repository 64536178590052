import api from './api';

export const listarSituacoes = async () => {
  const { data } = await api.get('/chamada/lista-situacao-chamada');
  return data;
};

export const listarHistoricoGravacoes = async ({
  filtroCpf = null,
  filtroSituacao = null,
  filtroTelefone = null,
  filtroConfirmacao = null,
  filtroDataHoraInicial = null,
  filtroDataHoraFinal = null,
  filtroPropostaId = null,
  filtroCliente = null,
  filtroAnalista = null,
  pagina = 1,
  quantidadePorPagina = 10,
  ordenacao = '',
  ordenacaoAsc = false,
  paginado = true,
}) => {
  try {
    const { data } = await api.post(`/chamada/lista`, {
      filtroCpf,
      filtroSituacao,
      filtroTelefone,
      filtroConfirmacao,
      filtroDataHoraInicial,
      filtroDataHoraFinal,
      filtroPropostaId,
      filtroCliente,
      filtroAnalista,
      quantidadePorPagina,
      pagina,
      ordenacao,
      ordenacaoAsc,
      paginado,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const downloadGravacao = async (url) => {
  const data = await api.get(url, { responseType: 'arraybuffer' });
  return data;
};
