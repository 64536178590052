import React, { useEffect, useState } from 'react';
import Table from '../BasicTable';
import moment from 'moment';
import './styles.scss';
import { getHistoricoSeguranca } from '../../services/usuariosService';

export default function TableLogs({ idUsuario, tipoLog, columnsHeader }) {
  const [whenChangePage, setWhenChangePage] = useState();
  const [listagemPaginaAtual, setListagemPaginaAtual] = useState([]);
  const [propsListagem, setPropsListagem] = useState({
    quantityPerPage: 0,
    total: 0,
    totalPages: 0,
  });

  function formatarListagem(listagem) {
    if (listagem?.length) {
      return listagem?.map((item) => {
        return {
          ...item,
          dataHora: moment(item.dataHora).format('DD/MM/YYYY - HH:mm'),
        };
      });
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (whenChangePage) {
      getHistoricoSeguranca({
        pagina: whenChangePage.pageIndex + 1,
        tipo: tipoLog,
        usuarioId: idUsuario,
        ordenacao: whenChangePage?.sortBy[0]?.id,
        ordenacaoAsc: whenChangePage?.sortBy[0]?.desc && !whenChangePage?.sortBy[0]?.desc,
      }).then((response) => {
        setPropsListagem({
          total: response.total,
          totalPages: response.totalPages,
          quantityPerPage: response.quantityPerPage,
        });
        setListagemPaginaAtual(formatarListagem(response.itens));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whenChangePage]);

  const columns = [
    {
      Header: 'Tipo de Operação',
      accessor: 'tipoOperacao',
    },
    {
      Header: 'Usuário',
      accessor: 'usuario',
    },
    {
      Header: 'Data Hora',
      accessor: 'dataHora',
    },
  ];

  return (
    <div className="table-logs-module">
      <Table
        columns={columnsHeader ?? columns}
        data={listagemPaginaAtual}
        onLoadData={setWhenChangePage}
        total={propsListagem.total}
        loading={false}
        pageCount={propsListagem.totalPages}
      />
    </div>
  );
}
