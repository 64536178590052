import api from './api';

export const listarComissoes = async (payload) => {
  const { data } = await api.post('/comissao/comissao-lista', payload);
  return data;
};

export const listarNfs = async (payload) => {
  const { data } = await api.post('/comissao/lista/notas', payload);
  return data;
};

export const getContextoComissao = async () => {
  const { data } = await api.get('/comissao/comissao-contexto');
  return data;
};

export const imprimirCSVComissao = async (payload) => {
  const { data } = await api.post(
    '/comissao/comissao-lista/exportacao-csv',
    payload,
    { responseType: 'arraybuffer' },
  );
  return data;
};

export const imprimirPDFComissao = async (payload) => {
  const { data } = await api.post(
    '/comissao/comissao-lista/exportacao-pdf',
    payload,
  );
  return data;
};

export const uploadNotas = async (payload) => {
  const { data } = await api.post('/comissao/upload-nota', payload);
  return data;
};
