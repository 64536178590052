import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from 'react-table';

import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Pagination from '../Pagination';
import Title from '../Title';

import './styles.scss';

function FullTable({
  data,
  columns,
  header,
  pagination,
  filter,
  action,
  boxFilterExtra,
  onRowClick,
  onCellClick,
  pageSize,
}) {
  const DATA = useMemo(() => data, [data]);
  const COLUMNS = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    setGlobalFilter,
    rows,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    state: { globalFilter, pageIndex },
  } = useTable(
    {
      data: DATA,
      columns: COLUMNS,
      initialState: { pageSize: !pageSize ? 10 : pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const items = pagination ? page : rows;

  const sortRender = (isDesc) => {
    if (isDesc) {
      return <i className="fa fa-sort-down" />;
    } else {
      return <i className="fa fa-sort-up" />;
    }
  };

  return (
    <div>
      <div className="table-header row">
        {header && (
          <div
            className={`table-header-container col-lg-${
              filter ? '5' : '12'
            } col-md-12`}
          >
            <Title label={header} />
          </div>
        )}

        {filter && (
          <div className="table-filter-container col-lg-7 col-md-12">
            {action && <div className="table-action">{action}</div>}

            <div className="table-filter">
              <Input
                className="filter-input"
                placeholder="Pesquisar..."
                value={globalFilter || ''}
                suffix={<SearchOutlined />}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      {boxFilterExtra && boxFilterExtra}

      <div className="table-responsive">
        <table className="table table-striped full-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ ...column.style }}
                  >
                    {column.render('Header')}
                    <span className="ml-1">
                      {column.canSort ? (
                        column.isSorted ? (
                          sortRender(column.isSortedDesc)
                        ) : (
                          <i className="fa fa-sort" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {items.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => (onRowClick ? onRowClick(row.original) : {})}
                  style={{
                    cursor: onRowClick ? 'pointer' : 'default',
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => (onCellClick ? onCellClick(cell) : {})}
                        style={{
                          cursor: onCellClick ? 'pointer' : 'default',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagination && items.length > 0 && (
        <div className="table-pagination">
          <Pagination
            totalRecords={data.length}
            pageIndex={pageIndex + 1}
            pageCount={pageCount}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageNeighbours={1}
          />
        </div>
      )}
    </div>
  );
}

FullTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  header: PropTypes.string,
  pagination: PropTypes.bool,
  filter: PropTypes.bool,
  action: PropTypes.element,
  onRowClick: PropTypes.func,
};

FullTable.defaultProps = {
  pagination: true,
  header: null,
  filter: true,
  action: null,
  onRowClick: () => {},
};

export default FullTable;
