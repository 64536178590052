import React, { useContext, useEffect } from 'react';

import { Input, Form, Field, Select, Checkbox } from 'formik-antd';

import SelectBancos from '../../../components/SelectBancos';
import InputInteger from '../../../components/InputInteger';
import TooltipAlterados from '../../../components/TooltipAlterados';
import SelectSearch from '../../../components/SearchSelect';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import './styles.scss';

const { Option } = Select;

function BancariosForm({ values }) {
  const {
    formik,
    contexto,
    validationField,
    editableField,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
  } = useContext(AnaliseMesaContext);

  const { setFieldValue } = formik;

  const mascaraNumeroConta = (e) => {
    let unformatted = e.target.value.replace(/[^0-9Xx]/g, '').toUpperCase();
    let suffix = '';

    if (unformatted.length >= 2) {
      if (unformatted.lastIndexOf('X') >= unformatted.length - 2) {
        suffix = 'X';
        unformatted = unformatted.substr(0, unformatted.lastIndexOf('X'));
      }

      unformatted = unformatted.replace('X', '').concat(suffix);

      unformatted = unformatted
        .substr(0, unformatted.length - 1)
        .concat('-')
        .concat(unformatted.substr(unformatted.length - 1, 1));
    }
    e.target.value = unformatted;
    setFieldValue('bancario.numero', unformatted);
  };

  const maskDigitoAgenciaX = (e) => {
    let valueDigito = e.target.value.replace(/([^\d|X|x])+/gim, '');
    e.target.value = valueDigito;
    values.bancario.digito = valueDigito;
  };

  const filteredTipos =
    contexto?.tipoConta &&
    contexto?.tipoConta?.filter((tipoConta) => {
      const bancoCodigo =
        values?.bancario && contexto?.bancos
          ? parseInt(values?.bancario?.bancoId)
          : -1;
      return (
        !tipoConta?.bancos ||
        bancoCodigo === null ||
        bancoCodigo < 0 ||
        tipoConta?.bancos.includes(bancoCodigo)
      );
    });

  function VerificaOperacao() {
    var retorno = '';
    if (contexto?.bancos && values.bancario) {
      var bancoId = parseInt(values.bancario.bancoId);
      if (bancoId === 1 && values.bancario.tipoConta === 1) {
        retorno = '051';
      } else if (bancoId === 104 && values.bancario.tipoConta === 0) {
        retorno = '001';
      } else if (
        bancoId === 104 &&
        values.bancario.tipoConta === 1 &&
        values.bancario.numero.length >= 11
      ) {
        retorno = '1288';
      } else if (bancoId === 104 && values.bancario.tipoConta === 1) {
        retorno = '013';
      } else if (bancoId === 104 && values.bancario.tipoConta === 2) {
        retorno = '023';
      }
    }
    setFieldValue('bancario.tipoOperacao', retorno);
  }

  useEffect(() => {
    VerificaOperacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.bancario?.bancoId,
    values?.bancario?.tipoConta,
    values?.bancario?.numero,
  ]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="bancario.bancoId"
            label={
              <>
                <label>Banco</label>
                {campoChecar('bancario.bancoId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('bancario.bancoId')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('bancario.bancoId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('bancario.bancoId') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'bancario.bancoId', 'Banco')
            }
            className={highlightField('bancario.bancoId') && `campo-alterado`}
            hasFeedback
          >
            <Field name="bancario.bancoId">
              {({ field }) => (
                <SelectBancos
                  {...field}
                  optionsBancos={{
                    bancosPrincipais:
                      contexto?.bancos &&
                      contexto?.bancos?.listaBancosPrincipais,
                    bancos:
                      contexto?.bancos &&
                      contexto?.bancos?.listaBancosSecundarios,
                  }}
                  onChange={(value) => {
                    setFieldValue('bancario.bancoId', value);
                    values.bancario.bancoId = value;
                    VerificaOperacao();
                  }}
                  disabled={!editableField('bancario.bancoId')}
                  fillDefault={editableField('bancario.bancoId')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3 col-input-agencia">
          <label>Agência</label>
          {campoChecar('bancario.agencia') && (
            <Checkbox
              className="check-campo"
              checked={!!getChecado(campoChecar('bancario.agencia')?.campoId)}
              onChange={() =>
                handleChangeChecados(campoChecar('bancario.agencia')?.campoId)
              }
            />
          )}
          {highlightField('bancario.agencia') && <TooltipAlterados />}
          <div className="form-row">
            <div className="col-lg-7 pr-0">
              <Form.Item
                name="bancario.agencia"
                validate={(value) =>
                  validationField(value, 'bancario.agencia', 'Agência')
                }
                className={
                  highlightField('bancario.agencia') && `campo-alterado`
                }
                hasFeedback
              >
                <Field name="bancario.agencia">
                  {({ field }) => (
                    <InputInteger
                      {...field}
                      className="ant-input"
                      placeholder="0001"
                      disabled={!editableField('bancario.agencia')}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-1 text-center">
              <p className="traco-agencia-digito">-</p>
            </div>
            <div className="col-lg-4">
              <Form.Item
                name="bancario.digito"
                validate={(value) =>
                  validationField(value, 'bancario.digito', 'Dígito')
                }
                className={
                  highlightField('bancario.digito') && `campo-alterado`
                }
                hasFeedback
              >
                <Input
                  name="bancario.digito"
                  placeholder="1"
                  disabled={!editableField('bancario.digito')}
                  onKeyUp={maskDigitoAgenciaX}
                  maxLength={1}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="bancario.numero"
            label={
              <>
                <label>Número da Conta</label>
                {campoChecar('bancario.numero') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('bancario.numero')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('bancario.numero')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('bancario.numero') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'bancario.numero', 'Número da Conta')
            }
            className={highlightField('bancario.numero') && `campo-alterado`}
            hasFeedback
          >
            <Input
              name="bancario.numero"
              placeholder="000001"
              disabled={!editableField('bancario.numero')}
              onKeyUp={(e) => mascaraNumeroConta(e)}
              maxLength={14}
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="bancario.conta"
            label={
              <>
                <label>Conta</label>
                {campoChecar('bancario.conta') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('bancario.conta')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('bancario.conta')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('bancario.conta') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'bancario.conta', 'Conta')
            }
            className={highlightField('bancario.conta') && `campo-alterado`}
            hasFeedback
          >
            <SelectSearch
              name="bancario.conta"
              placeholder="Conjunta"
              disabled={!editableField('bancario.conta')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              showSearch
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setFieldValue('bancario.conta', value)}
              value={values?.bancario?.conta}
              fillDefault={editableField('bancario.conta')}
            >
              {contexto?.conta &&
                contexto?.conta.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="bancario.tipoConta"
            label={
              <>
                <label>Tipo de Conta</label>
                {campoChecar('bancario.tipoConta') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('bancario.tipoConta')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('bancario.tipoConta')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('bancario.tipoConta') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'bancario.tipoConta', 'Tipo de Conta')
            }
            className={highlightField('bancario.tipoConta') && `campo-alterado`}
            hasFeedback
          >
            <SelectSearch
              name="bancario.tipoConta"
              placeholder="Poupança"
              optionFilterProp="children"
              showSearch
              suffixIcon={<IconArrowSelect />}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={!editableField('bancario.tipoConta')}
              onChange={(value) => setFieldValue('bancario.tipoConta', value)}
              value={values?.bancario?.tipoConta}
              fillDefault={editableField('bancario.tipoConta')}
            >
              {filteredTipos &&
                filteredTipos.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
        {formik?.values?.bancario?.tipoOperacao && (
          <div className="col-lg-3">
            <Form.Item
              name="bancario.tipoOperacao"
              label={
                <>
                  <label>Tipo Operação</label>
                  {campoChecar('bancario.tipoOperacao') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('bancario.tipoOperacao')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('bancario.tipoOperacao')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('bancario.tipoOperacao') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(
                  value,
                  'bancario.tipoOperacao',
                  'Tipo de Operação',
                )
              }
              className={
                highlightField('bancario.tipoOperacao') && `campo-alterado`
              }
              hasFeedback
            >
              <Input
                value={formik?.values?.bancario?.tipoOperacao}
                disabled={!editableField('bancario.tipoOperacao')}
              />
            </Form.Item>
          </div>
        )}
        <div className="col-lg-3">
          <Form.Item
            name="bancario.tempoConta"
            label={
              <>
                <label>Tempo de Conta</label>
                {campoChecar('bancario.tempoConta') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('bancario.tempoConta')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('bancario.tempoConta')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('bancario.tempoConta') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'bancario.tempoConta', 'Tempo de Conta')
            }
            className={
              highlightField('bancario.tempoConta') && `campo-alterado`
            }
            hasFeedback
          >
            <SelectSearch
              name="bancario.tempoConta"
              placeholder="Menos que 1 ano"
              disabled={!editableField('bancario.tempoConta')}
              optionFilterProp="children"
              suffixIcon={<IconArrowSelect />}
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              onChange={(value) => setFieldValue('bancario.tempoConta', value)}
              value={values?.bancario?.tempoConta}
              fillDefault={editableField('bancario.tempoConta')}
            >
              {contexto?.tempoConta &&
                contexto?.tempoConta.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                ))}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default BancariosForm;
