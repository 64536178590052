import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-pagination.svg';
import Pagination from '../Pagination';
import Overlay from '../Overlay';
import Tooltip from '../tooltip';
import './styles.scss';
import { Select } from 'antd';

const { Option } = Select;
function BasicTable({
  data,
  columns,
  pageCount: controlledPageCount,
  onLoadData,
  total,
  loading,
  header,
  onRowClick,
  tooltipContent,
  filter,
  timeRefresh,
  perPage = 0,
  setPerPage,
  search,
  onSearch = false,
  updateList = false,
  mensagemArrayVazio
}) {
  const [intervalRefresh, setIntervalRefresh] = useState(null);
  const [updateListLocal, setUpdateListLocal] = useState(updateList);
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    previousPage,
    nextPage,
    gotoPage,
    state: { sortBy, pageIndex },
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: 0 },
      manualSortBy: true,
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );


  function fetchData(data) {
    // pageIndexLocal está validando se há o valor
    // página = 1, se houver ele irá para primeira página, pois indica
    // que está sendo feito o acionamento via o botão "Pesquisar".
    // Caso contrário, utiliza o valor do pageIndex. =D
    let pageIndexLocal = data?.pagina === 1 ? 0 : pageIndex;
    gotoPage(pageIndexLocal);


    if (updateListLocal !== updateList) {
      pageIndexLocal = 0;
      gotoPage(0);
      setUpdateListLocal(updateList);
    }

    if (timeRefresh) {
      let interval;
      clearTimeout(intervalRefresh);
      function refresh() {
        new Promise((resolve) => {
          resolve(
            onLoadData({
              pageIndex: pageIndexLocal,
              perPage,
              sortBy,
              filter: data,
            }),
          );
        }).then(() => {
          interval = setTimeout(() => refresh(), timeRefresh * 1000);
          setIntervalRefresh(interval);
        });
      }
      refresh();
    } else {
      onLoadData({ pageIndex: pageIndexLocal, perPage, sortBy, filter: data });
    }
  }

  useEffect(() => {
    if (!loading && !onSearch) {
      fetchData(filter);
      // O valor página é utilizado apenas
      // quando é feito o filtro via "Pesquisar"
      // caso contrário será desconsiderado =)
      if (filter?.pagina) {
        filter.pagina = -1;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoadData, pageIndex, sortBy, timeRefresh, filter, perPage, updateList]);

  useEffect(() => {
    gotoPage(0);
  }, [filter, gotoPage]);

  useEffect(() => {
    if (onSearch && search) {
      fetchData(search);
      // O valor página é utilizado apenas
      // quando é feito o filtro via "Pesquisar"
      // caso contrário será desconsiderado =)
      if (search?.pagina) {
        search.pagina = -1;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pageIndex, sortBy, timeRefresh, perPage]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRefresh);
    };
  }, [intervalRefresh]);

  const renderSorter = (isDesc) => {
    if (isDesc) {
      return <i className="fa fa-sort-down" />;
    } else {
      return <i className="fa fa-sort-up" />;
    }
  };

  return (
    <div>
      {header && <div className="table-custom-header">{header}</div>}
      <Overlay active={loading}>
        <div className="table-responsive">
          <table className="table table-striped" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ ...column.style }}
                    >
                      {column.render('Header')}
                      <span className="ml-1">
                        {column.canSort ? (
                          column.isSorted ? (
                            renderSorter(column.isSortedDesc)
                          ) : (
                            <i className="fa fa-sort" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Tooltip
                    {...row.getRowProps()}
                    placement="bottomLeft"
                    color="#445566"
                    overlayClassName="tooltip-historico"
                    overlayStyle={{ minWidth: '320px' }}
                    trigger={tooltipContent ? 'click' : ''}
                    title={tooltipContent}
                  >
                    <tr
                      onClick={() =>
                        onRowClick ? onRowClick(row.original) : {}
                      }
                      style={{
                        cursor: onRowClick ? 'pointer' : 'auto',
                      }}
                    >
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  </Tooltip>
                );
              })}
              {data.length === 0 && mensagemArrayVazio &&

                <>
                  <tr colSpan={4}>
                    <td colSpan={4} className='mensagem-container'>
                      {mensagemArrayVazio}
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </div>
      </Overlay>

      <div className={perPage > 0 ? 'pagination-footer' : ''}>
        {perPage > 0 && data.length > 0 ? (
          <div className="per-page">
            <p>Mostra</p>
            <Select
              suffixIcon={<IconArrowSelect />}
              onChange={(value) => {
                setPerPage(Number(value));
              }}
              defaultValue="10"
            >
              <Option value="5">5</Option>
              <Option value="10">10</Option>
              <Option value="20">20</Option>
              <Option value="50">50</Option>
            </Select>
            <p>Por página</p>
          </div>
        ) : (
          ''
        )}

        <div className="pagination-container">
          <Pagination
            totalRecords={total}
            pageIndex={pageIndex + 1}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            pageNeighbours={1}
          />
        </div>
      </div>
    </div>
  );
}

BasicTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pageCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onLoadData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  header: PropTypes.node,
  onRowClick: PropTypes.func,
  tooltipContent: PropTypes.node,
};

BasicTable.defaultProps = {
  loading: false,
  header: null,
};

export default BasicTable;
