import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './styles.scss';
function Button({
  type,
  variant = 'btn-block',
  className,
  block,
  onClick,
  children,
  async,
  disabled,
  ...props
}) {
  const [disabledAsync, setDisabledAsync] = useState(false);

  const btnClass = ClassNames('btn', {
    'btn-green': variant === 'green',
    'btn-gray': variant === 'gray',
    'btn-blue': variant === 'blue',
    'btn-orange': variant === 'orange',
    'btn-white': variant === 'white',
    'btn-brown': variant === 'brown',
    'btn-red': variant === 'red',
    'btn-orange-outline': variant === 'orange-outline',
    'btn-block': block,
  });

  async function executeOnClick() {
    setDisabledAsync(true);
    await onClick();
    setDisabledAsync(false);
  }

  return (
    <button
      type={type}
      className={`${btnClass} ${className}`}
      onClick={() => {
        if (async) {
          executeOnClick();
        } else {
          onClick();
        }
      }}
      disabled={async ? disabledAsync : disabled}
      {...props}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf([
    'green',
    'gray',
    'blue',
    'orange',
    'white',
    'brown',
    'orange-outline',
    'red',
  ]).isRequired,
  className: PropTypes.string,
  block: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  className: '',
  block: false,
  onClick: () => {},
};

export default Button;
