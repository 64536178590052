import moment from 'moment';

import Tag from '../../../components/Tag';
import Tooltip from '../../../components/tooltip';

import { ReactComponent as ChecklListIcon } from '../../../assets/images/icons/checklist.svg';
// import { ReactComponent as MoneyTwoIcon } from '../../../assets/images/icons/moneytwo.svg';

import { currencyFormat } from '../../../utils/numberFormatter';
import { cnpjFormatter } from '../../../utils/textFormatter';

export const columns = (setVisible, setBorderoSelecionado) => [
  {
    Header: 'ID',
    accessor: 'id',
    style: {
      width: 100,
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'CNPJ',
    accessor: 'cnpj',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{cnpjFormatter(value)}</div>
    ),
  },
  {
    Header: 'Nome',
    accessor: 'nomeFantasia',
    style: {
      width: 150,
      minWidth: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Saldo',
    accessor: 'saldo',
    style: {
      width: 150,
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Situação',
    accessor: 'situacao',
    style: {
      textAlign: 'center',
      width: 150,
      minWidth: 150,
    },
    Cell: (e) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Tooltip
            placement="bottomRight"
            arrowPointAtCenter
            color="#435361"
            title={
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  lineHeight: '15px',
                }}
              >
                {e.row.original.situacao}
              </div>
            }
          >
            <Tag color={e.row.original.situacaoCor} width={75}>
              {e.row.original.situacao}
            </Tag>
          </Tooltip>
        </div>
      );
    },
  },
  {
    Header: 'Geração',
    accessor: 'geracao',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : '--'}
      </div>
    ),
  },
  {
    Header: 'Integração',
    accessor: 'integracao',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : '--'}
      </div>
    ),
  },
  {
    Header: 'Confirmação',
    accessor: 'confirmacao',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : '--'}
      </div>
    ),
  },
  {
    Header: '',
    accessor: 'detalhes',
    style: {
      width: 20,
      minWidth: 20,
      textAlign: 'center',
    },
    Cell: ({ row }) => (
      <div style={{ textAlign: 'center', paddingRight: '5px' }}>
        <Tooltip
          placement="bottomRight"
          arrowPointAtCenter
          color="#435361"
          title={
            <div
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '13px',
                lineHeight: '15px',
              }}
            >
              Detalhamento Borderô
            </div>
          }
        >
          <ChecklListIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setBorderoSelecionado(row.values.id);
              setVisible(true);
            }}
            width="15"
          />
        </Tooltip>
      </div>
    ),
    disableSortBy: true,
  },
  // {
  //   Header: '',
  //   accessor: 'liberacao',
  //   style: {
  //     width: 20,
  //     minWidth: 20,
  //     textAlign: 'center',
  //   },
  //   Cell: ({ row }) => (
  //     <div style={{ textAlign: 'center', paddingRight: '5px' }}>
  //       <Tooltip
  //         placement="bottomRight"
  //         arrowPointAtCenter
  //         color="#435361"
  //         title={
  //           <div
  //             style={{
  //               fontStyle: 'normal',
  //               fontWeight: 'normal',
  //               fontSize: '13px',
  //               lineHeight: '15px',
  //             }}
  //           >
  //             Liberar Pagamento
  //           </div>
  //         }
  //       >
  //         <Link
  //           to={{
  //             pathname: '#',
  //           }}
  //         >
  //           <MoneyTwoIcon width="30" />
  //         </Link>
  //       </Tooltip>
  //     </div>
  //   ),
  //   disableSortBy: true,
  // },
];
