import React, { useContext } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Toolbar from './toolbar';
import Button from '../../../components/Button';

import { CorrespondenteContext } from '../../../contexts/CorrespondenteContext';

import './styles.scss';

function AprovacaoForm() {
  const { showUpload, setShowUpload } = useContext(CorrespondenteContext);

  return (
    <div className="form-container">
      <Toolbar />

      <div className="row mt-3">
        <div className="col">
          <Tabs>
            <TabList>
              <Tab>Credenciamento</Tab>
              <Tab>Endereço</Tab>
              <Tab>Contato</Tab>
              <Tab>Visitas</Tab>
              <Tab>Sócios</Tab>
              <Tab>Dados Gerais</Tab>
              <Tab>Dados Bancários</Tab>
              <Tab>Arquivo</Tab>
              <Tab>Unidade</Tab>
            </TabList>

            <div className="react-tabs__content-holder">
              <TabPanel>form credenciamento</TabPanel>
              <TabPanel>form endereço</TabPanel>
              <TabPanel>form contato</TabPanel>
              <TabPanel>form visitas</TabPanel>
              <TabPanel>form sócios</TabPanel>
              <TabPanel>form dados gerais</TabPanel>
              <TabPanel>form dados bancários</TabPanel>
              <TabPanel>form arquivo</TabPanel>
              <TabPanel>form unidade</TabPanel>
            </div>
          </Tabs>
        </div>

        {showUpload && (
          <div className="col-lg-5">
            <div className="border p-3" style={{ backgroundColor: 'white' }}>
              <div className="d-flex justify-content-end">
                <Button
                  type="button"
                  variant="gray"
                  onClick={() => setShowUpload(false)}
                >
                  <span>Cancelar</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AprovacaoForm;
