import React, { Fragment, useContext, useState, useEffect } from 'react';
import moment from 'moment';

import { Formik, Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import { Popconfirm } from 'antd';

import locale from 'antd/es/date-picker/locale/pt_BR';

import InputMask from 'react-text-mask';
import InputDecimal from '../../components/InputDecimal';
import Modal from '../../components/Modal';
import Overlay from '../../components/Overlay';

import Card from '../../components/Card';
import Title from '../../components/Title';
import DatePicker from '../../components/DatePicker';
import Button from '../../components/Button';
import Icon from '../../components/Icon';

import { ParametroContext } from '../../contexts/ParametroContext';
import { validationForm } from './validationSchema';

import { integerMask } from './masks';

import { ReactComponent as HourglassIcon } from '../../assets/images/icons/hourglass.svg';

import integer from '../../assets/images/icons/integer.svg';
import date from '../../assets/images/icons/date.svg';
import money from '../../assets/images/icons/money.svg';
import text from '../../assets/images/icons/text.svg';

import './styles.scss';

const { TextArea } = Input;
const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const Header = ({ text }) => <Title label={`${text} de Parâmetros`} />;

function ParametroForm() {
  const {
    parametro,
    parametroAtualFuturo,
    gruposParametro,
    salvarParametro,
    resetParametroForm,
    removerParametroHistorico,
    setParametroAtualFuturo,
  } = useContext(ParametroContext);

  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Overlay active={showOverlay}>
      <Card title={<Header text={parametro.id > 0 ? 'Edição' : 'Inclusão'} />}>
        <Formik
          initialValues={parametro}
          validationSchema={validationForm}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={async (data, { resetForm }) => {
            setShowOverlay(true);
            const valor = String(data.valor);
            data.valor = valor;

            const response = await salvarParametro(data).catch(() =>
              setShowOverlay(false),
            );

            setShowOverlay(false);
            if (response?.success) {
              resetForm();
              Modal('', 'Parâmetro adicionado com sucesso.', 'success');
            } else {
              Modal(
                'Ocorreu 1 ou mais erros:',
                response.toString().replace(/ *, */g, '\n\n'),
                'error',
              );
            }
          }}
        >
          {({ setFieldValue, setFieldTouched, touched, values }) => (
            <Form layout="vertical">
              <FormLayout
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                values={values}
                resetForm={resetParametroForm}
                formData={parametro}
                initialValues={parametro}
                groups={gruposParametro}
                parametroAtualFuturo={parametroAtualFuturo}
                setParametroAtualFuturo={setParametroAtualFuturo}
                removerParametroHistorico={removerParametroHistorico}
                setShowOverlay={setShowOverlay}
              />
            </Form>
          )}
        </Formik>
      </Card>
    </Overlay>
  );
}

export default ParametroForm;

function FormLayout({
  setFieldValue,
  setFieldTouched,
  values,
  resetForm,
  formData,
  groups,
  parametroAtualFuturo,
  setParametroAtualFuturo,
  removerParametroHistorico,
  setShowOverlay,
}) {
  const disableDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const handleCancel = async (idProxParametro) => {
    setShowOverlay(true);
    const response = await removerParametroHistorico(idProxParametro);
    if (response.messages) {
      Modal('Ocorreu 1 ou mais erros:', response.messages.join(','), 'error');
    } else {
      setParametroAtualFuturo({});
    }
    setShowOverlay(false);
  };
  const handleValidadeInicial = () => {
    setFieldTouched('validadeInicial');
  };

  const handleTipoDado = () => {
    setFieldValue('valor', '');
    setFieldTouched('valor', false);
  };

  useEffect(() => {
    if (formData.id > 0) {
      const fields = [
        'id',
        'ordem',
        'parametroGrupoId',
        'nome',
        'codigo',
        'tipo',
        'valor',
        'validadeInicial',
        'notaTecnica',
      ];
      fields.forEach((field) => setFieldValue(field, formData[field], false));
    }
  }, [formData, setFieldValue]);

  useEffect(() => {
    if (parametroAtualFuturo.validadeInicial) {
      setFieldValue('validadeInicial', parametroAtualFuturo.validadeInicial);
    }
  }, [parametroAtualFuturo, setFieldValue]);

  return (
    <Fragment>
      <Input type="hidden" name="id" />
      <Input type="hidden" name="ordem" />

      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item name="parametroGrupoId" label="Grupo">
            <Select 
              name="parametroGrupoId" 
              placeholder="Selecione"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {groups.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item name="nome" label="Descrição" placeholder="Descrição">
            <Input name="nome" maxLength={50} />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6 col-md-6">
          <Form.Item
            name="codigo"
            label="Código"
            placeholder="Código interno do sistema"
          >
            <Input
              name="codigo"
              value={values.codigo}
              className="ant-input"
              disabled={formData.id > 0}
              maxLength={40}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setFieldValue('codigo', e.target.value.replace(/\W/, ''));
              }}
              guide={false}
            />
          </Form.Item>
        </div>

        <div className="col-lg-6 col-md-6">
          <Form.Item name="tipo" label="Tipo de dados">
            <Select
              name="tipo"
              placeholder="Selecione"
              onChange={handleTipoDado}
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              <Option value={1}>
                <img src={text} alt="value-icon" className="pb-1 pr-2" />
                Texto
              </Option>
              <Option value={2}>
                {' '}
                <img src={integer} alt="value-icon" className="pb-1 pr-2" />
                Inteiro
              </Option>
              <Option value={3}>
                <img src={money} alt="value-icon" className="pb-1 pr-2" />
                Decimal
              </Option>
              <Option value={4}>
                <img src={date} alt="value-icon" className="pb-1 pr-2" />
                Data
              </Option>
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6 col-md-6">
          <Form.Item name="valor" label="Valor" placeholder="Valor">
            <Field name="valor">
              {({ field }) => (
                <InputType
                  {...field}
                  data={values.tipo}
                  disabled={values.tipo === 0}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="ant-col ant-form-item-label">
            {formData.id > 0 &&
              parametroAtualFuturo.validadeInicialProximoParametroHistorico &&
              parametroAtualFuturo.valorProximoParametroHistorico && (
                <Popconfirm
                  placement="bottomRight"
                  color="#435361"
                  trigger="hover"
                  cancelText="Excluir"
                  okText="Manter"
                  title={`Valor a ser considerado ${'\n'} a partir do dia
                    ${moment(
                      parametroAtualFuturo.validadeInicialProximoParametroHistorico,
                    ).format(dateFormat)}:
                    ${
                      values.tipo === 4
                        ? moment(
                            parametroAtualFuturo.valorProximoParametroHistorico,
                          ).format(dateFormat)
                        : parametroAtualFuturo.valorProximoParametroHistorico
                    }`}
                  onCancel={() =>
                    handleCancel(
                      parametroAtualFuturo.idProximoParametroHistorico,
                    )
                  }
                >
                  <HourglassIcon
                    className="clock-icon"
                    style={{ marginRight: '5px' }}
                  />
                </Popconfirm>
              )}
            <span>Validade Inicial</span>
          </div>
          <Form.Item name="validadeInicial">
            <DatePicker
              name="validadeInicial"
              placeholder="Validade inicial"
              format={dateFormat}
              locale={locale}
              onBlur={handleValidadeInicial}
              disabledDate={disableDate}
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item name="notaTecnica" label="Nota Técnica">
            <TextArea
              name="notaTecnica"
              placeholder="Informações técnicas sobre o uso do parâmetro no sistema"
              rows={5}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row justify-content-end pr-3 pl-3">
        <Button
          type="reset"
          variant="gray"
          className="btn-xs-block"
          onClick={() => resetForm()}
          style={{ minWidth: '115px' }}
        >
          <span>Cancelar</span>
        </Button>

        <div className="p-2" />

        <Button
          type="submit"
          variant="green"
          className={formData.id > 0 ? 'd-none' : 'btn-xs-block'}
          style={{ minWidth: '115px' }}
        >
          <Icon variant="include" className="mr-2" />
          <span>INCLUIR</span>
        </Button>

        <Button
          type="submit"
          variant="orange"
          className={formData.id > 0 ? 'btn-xs-block' : 'd-none'}
          style={{ minWidth: '115px' }}
        >
          <Icon variant="save" className="mr-2" />
          <span>SALVAR</span>
        </Button>
      </div>
    </Fragment>
  );
}

function InputType({ data, ...props }) {
  switch (data) {
    case 1:
      return <Input {...props} />;
    case 2:
      return <InputMask mask={integerMask} className="ant-input" {...props} />;
    case 3:
      return <InputDecimal max={99999999999999} {...props} />;
    case 4:
      return <DatePicker format="DD/MM/YYYY" name="valor" />;
    default:
      return <Input {...props} />;
  }
}
