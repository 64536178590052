import React, { useContext, useState } from 'react';
import moment from 'moment';

import { Field } from 'formik';
import { Form, Input, Select, DatePicker } from 'formik-antd';

import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import InputMask from 'react-text-mask';
import Tooltip from '../../../components/tooltip';

import { cpfMask } from '../../../utils/masks';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

import { RelatorioEsteiraContext } from '../../../contexts/RelatorioEsteiraContext';

const { Option } = Select;
const { RangePicker } = DatePicker;

function Filter({ formik }) {
  const { contexto, initialFilters, setSearch } = useContext(
    RelatorioEsteiraContext,
  );

  const { values, setFieldValue } = formik;

  const [dates, setDates] = useState([]);
  const [value, setValue] = useState([moment().add(-1, 'days'), moment()]);
  const [hackValue, setHackValue] = useState();

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
    return tooLate;
  };

  const onOpenChange = (open) => {
    if (!open) {
      setHackValue(undefined);
    }
  };

  function TooltipInfo({ text }) {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-info"
        overlayClassName="tooltip-proposta-credito"
        color="#435361"
        title={text}
      >
        <BulletInfo className="bullet-info-icon" />
      </Tooltip>
    );
  }

  const handleEnvioFormulario = () => {
    if (
      values?.cpf?.replace(/\D/g, '') === '' &&
      values.rangeData &&
      values?.rangeData[1]?.diff(values?.rangeData[0], 'days') > 31
    ) {
      Modal('', 'Prazo máximo para consulta: 31 dias', 'warning');
    } else {
      const cpf = values.cpf?.replace(/[,.-]/g, '');
      setSearch({ ...values, cpf, pagina: 1 });
    }
  };

  return (
    <div className="filter-container">
      <Form layout="vertical" autoComplete="off">
        <div className="filter-wrapper">
          <div className="filter-form">
            <div className="form-row">
              <div className="col-lg-3">
                <Form.Item name="tipoData" className="filter-item">
                  <label style={{ margin: 0 }}>Tipo de data</label>
                  <Select 
                    name="tipoData"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {contexto?.tipoData?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="rangeData" className="filter-item">
                  <label style={{ margin: 0 }}>Data</label>
                  <RangePicker
                    name="rangeData"
                    format="DD/MM/YYYY"
                    value={hackValue || value}
                    disabledDate={
                      values?.cpf?.replace(/\D/g, '') !== ''
                        ? null
                        : disabledDate
                    }
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(val) => setValue(val)}
                    onOpenChange={onOpenChange}
                  />
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="cpf" className="filter-item">
                  <label style={{ margin: 0, marginRight: 10 }}>CPF</label>
                  <TooltipInfo text="Se um CPF for informado não haverá restrição no filtro de data." />
                  <Field name="cpf">
                    {({ field }) => (
                      <InputMask
                        name="cpf"
                        className="ant-input"
                        mask={cpfMask}
                        {...field}
                        onChange={(val) => {
                          setFieldValue('cpf', val.target.value);
                          if (
                            val.target.value.replace(/\D/g, '').length === 11
                          ) {
                            setValue(undefined);
                            setDates(undefined);
                            setFieldValue('rangeData', undefined);
                          }
                        }}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="etapaId" className="filter-item">
                  <label style={{ margin: 0 }}>Status</label>
                  <Select
                    mode="multiple"
                    name="etapaId"
                    showSearch
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contexto?.situacao?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="form-row">
              <div className="col-lg-3">
                <Form.Item name="nomeAgente" className="filter-item">
                  <label style={{ margin: 0 }}>Agente</label>
                  <Input name="nomeAgente" />
                </Form.Item>
              </div>

              <div className="col-lg-2">
                <Form.Item name="matrizId" className="filter-item">
                  <label style={{ margin: 0 }}>Matriz</label>
                  <Select
                    name="matrizId"
                    showSearch
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contexto?.matrizes?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="supervisorId" className="filter-item">
                  <label style={{ margin: 0 }}>Supervisores ativos</label>
                  <Select
                    name="supervisorId"
                    showSearch
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contexto?.supervisores?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.login}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-2">
                <Form.Item name="produtoId" className="filter-item">
                  <label style={{ margin: 0 }}>Produtos ativos</label>
                  <Select
                    mode="multiple"
                    name="produtoId"
                    showSearch
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contexto?.produtos?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-2">
                <Form.Item name="tipoCliente" className="filter-item">
                  <label style={{ margin: 0 }}>Cliente</label>
                  <Select
                    mode="multiple"
                    name="tipoCliente"
                    showSearch
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contexto?.tipoCliente?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="filter-actions">
            <Button
              type="button"
              variant="blue"
              block
              async={true}
              onClick={() => {
                handleEnvioFormulario();
              }}
            >
              <Icon variant="search" />
              <span className="ml-2">Pesquisar</span>
            </Button>

            <Button
              type="button"
              variant="gray"
              block
              onClick={() => {
                setSearch(initialFilters);
                setValue([moment().add(-1, 'days'), moment()]);
                setDates([moment().add(-1, 'days'), moment()]);
                setFieldValue('analistas', []);
                setFieldValue('cpf', '');
                setFieldValue('etapaId', null);
                setFieldValue('matrizId', null);
                setFieldValue('nomeAgente', null);
                setFieldValue('produtoId', null);
                setFieldValue('propostaId', null);
                setFieldValue('supervisorId', null);
                setFieldValue('tipoCliente', null);
                setFieldValue('tipoData', 0);
                setFieldValue('rangeData', [
                  moment().add(-1, 'days'),
                  moment(),
                ]);
              }}
              async={true}
            >
              <Icon variant="clean" />
              <span className="ml-2">Limpar</span>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Filter;
