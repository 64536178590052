import React from 'react';
import classNames from 'classnames';

function Field({ label, status, message, children }) {
  const fieldClass = classNames('form-group', {
    'has-error': status === 'error',
  });

  const messageClass = classNames('form-text', {
    'has-error': status === 'error',
  });

  return (
    <div className={fieldClass}>
      {label && <label>{label}</label>}
      {children}
      {message && <span className={messageClass}>{message}</span>}
    </div>
  );
}

export default Field;
