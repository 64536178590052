import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import BlocklistUnidadeConsumidoraProvider from '../../contexts/BlocklistUCContext';

import ListBlocklist from './ListBlocklist';
import FormBlocklist from './FormBlocklist';

import './styles.scss';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function BlocklistUnidadeConsumidora() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>BackOffice</Breadcrumb.Item>
          <Breadcrumb.Item>Blocklist</Breadcrumb.Item>
          <Breadcrumb.Item>Blocklist Unidade Consumidora</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <BlocklistUnidadeConsumidoraProvider>
          {checarPermissao(
            'tela',
            'tela.backoffice.blocklist.unidadeConsumidora',
            'Visualizar',
          ) && (
            <div className="row blocklist">
              {checarPermissao(
                'frame',
                'frame.blocklistUnidadeConsumidora',
                'Visualizar',
              ) && (
                <div className="col-sm-6 col-list">
                  <ListBlocklist />
                </div>
              )}
              {checarPermissao(
                'frame',
                'frame.blocklistUnidadeConsumidora.edicao',
                'Visualizar',
              ) && (
                <div className="col-sm-6 col-inclusao-edicao">
                  <FormBlocklist />
                </div>
              )}
            </div>
          )}
        </BlocklistUnidadeConsumidoraProvider>
      </Content>
    </Main>
  );
}

export default BlocklistUnidadeConsumidora;
