import React, { createContext, useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import {
  getUsuarioEdit,
  getObservacoes,
  postObservacao,
  postUsuario,
  getPossuiPosicao,
  bloquearUsuario,
  desbloquearUsuario,
  inativarUsuario,
  getHistoricoHierarquia,
  putUsuario,
  getUsuarioAtivo,
} from '../services/usuariosService';

export const CadastroUsuariosContext = createContext({
  fetchUsuarioEdit: async (f) => f,
  userInfos: [],
});

function CadastroUsuariosProvider({ children }) {
  const [userInfos, setUserInfos] = useState([]);
  const [observacoes, setObservacoes] = useState([]);
  const [refreshObservacoes, setRefreshObservacoes] = useState(0);
  const [historicoHierarquia, setHistoricoHierarquia] = useState([]);
  const [telefoneCadastroUsuario, setTelefoneCadastroUsuario] = useState([]);
  // eslint-disable-next-line
  const [tipoTelefone, setTipoTelefone] = useState([]);
  const [unidadeSubunidade, setUnidadeSubunidade] = useState([]);
  const [showModalUsuarioAtivo, setShowModalUsuarioAtivo] = useState(false);
  const [existeUsuarioAtivo, setExisteUsuarioAtivo] = useState([])

  const criarUsuario = async (user) => {
    const isSuccess = await postUsuario(user);
    return isSuccess;
  };

  const atualizarUsuario = async (userId, user) => {
    const isSuccess = await putUsuario(userId, user);
    return isSuccess;
  };

  const fetchObservacoes = async (userId, ultimaObservacaoId = null) => {
    const response = await getObservacoes(userId, ultimaObservacaoId);
    const { fetchedObservacoes, fetchedRefreshObservacoes } = response;

    if (fetchedObservacoes.length)
      setObservacoes((prev) => [...prev, ...fetchedObservacoes]);

    if (fetchedRefreshObservacoes !== refreshObservacoes)
      setRefreshObservacoes(fetchedRefreshObservacoes);
  };

  const sendObservacao = async (userId, mensagem) => {
    // Buscar mensagens enviadas entre a última atualização e atual
    let fetchedObservacoes = [];
    const ultimaObservacaoId = observacoes?.[observacoes.length - 1]?.id;
    const { sentMessage } = await postObservacao(userId, mensagem);
    if (!ultimaObservacaoId || ultimaObservacaoId + 1 !== sentMessage.id) {
      // Evita fazer chamadas caso nenhuma mensagem tenha sido enviada.
      const response = await getObservacoes(userId, ultimaObservacaoId);
      fetchedObservacoes = response.fetchedObservacoes.filter(
        (item) => item.id !== sentMessage.id,
      );
    }
    setObservacoes([...observacoes, ...fetchedObservacoes, sentMessage]);
  };

  function keepObservacao(id = null, mensagem) {
    const date = new Date();
    const body = {
      data: moment(date).format('YYYY-MM-DD'),
      horario: moment(date).format('LTS'),
      mensagem: mensagem,
      remetente: localStorage.getItem('login'),
    };
    setObservacoes([...observacoes, body]);
  }

  const fetchHistoricoHierarquia = async (id) => {
    const data = await getHistoricoHierarquia(id);
    setHistoricoHierarquia(data);
  };
  const fetchUsuarioEdit = async (id) => {
    const data = await getUsuarioEdit(id);
    let telefonesFiltrados = data?.contato?.telefoneLista?.filter(tel => tel.telefone.trim() !== "") || [];

  
    setUserInfos(data);
    setTelefoneCadastroUsuario(telefonesFiltrados);
  };

  const fetchPossuiPosicao = async (id) => {
    const data = await getPossuiPosicao(id);
    return data.status;
  };

  const fetchUsuarioAtivo = async (cpf, clearCpfField) => {
    const response = await getUsuarioAtivo(cpf);
    setExisteUsuarioAtivo(response.data);
    if (response.data.existeUsuarioAtivo === true) {
      setShowModalUsuarioAtivo(true);
      if (clearCpfField) clearCpfField();  // limpa o campo CPF
    } else {
      setShowModalUsuarioAtivo(false);
    }
  };

  return (
    <CadastroUsuariosContext.Provider
      value={{
        fetchUsuarioEdit,
        userInfos,
        fetchObservacoes,
        observacoes,
        refreshObservacoes,
        sendObservacao,
        criarUsuario,
        fetchPossuiPosicao,
        bloquearUsuario,
        desbloquearUsuario,
        inativarUsuario,
        historicoHierarquia,
        fetchHistoricoHierarquia,
        atualizarUsuario,
        keepObservacao,
        telefoneCadastroUsuario,
        setTelefoneCadastroUsuario,
        tipoTelefone,
        unidadeSubunidade,
        showModalUsuarioAtivo,
        fetchUsuarioAtivo,
        existeUsuarioAtivo, 
        setUnidadeSubunidade,
        setShowModalUsuarioAtivo,
        setExisteUsuarioAtivo
      }}
    >
      {children}
    </CadastroUsuariosContext.Provider>
  );
}

export default CadastroUsuariosProvider;