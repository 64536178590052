import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { FormikProvider } from 'formik';

import { Form, Field, Input } from 'formik-antd';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';
import Title from '../../../components/Title';
import IconEdit from '../../../components/EditIcon';
import IconConfig from '../../../components/ConfigIcon';
import ModalBlocklist from '../../../components/ModalBlockList';
import Button from '../../../components/Button';
import InputPhone from '../../../components/InputPhone';

import InputMask from 'react-text-mask';

import { cpfMask } from '../../../utils/masks';

import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../../assets/images/icons/erase.svg';

import { CadastroPessoaContext } from '../../../contexts/CadastroPessoaContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { cellPhoneFormatter, cpfFormatter } from '../../../utils/textFormatter';

import './styles.scss';

function CadastroPessoaList() {
  function filtraNome({ nome }) {
    const nomeArray = nome.split(' ');
    if (nomeArray[1]?.length > 3) {
      return nomeArray[0] + ' ' + nomeArray[1];
    } else {
      return nomeArray[0] + ' ' + nomeArray[1] + ' ' + nomeArray[2];
    }
  }

  const history = useHistory();

  const { checarPermissao } = useContext(ControleAcessoContext);

  const {
    formik,
    data,
    fetchPessoas,
    paginacaoPessoas,
    pageIndex,
    setPageIndex,
    filters,
    setFilters,
    setPaginacaoPessoas,
    initialValuesFiltros,
    isModalVisible,
    setIsModalVisible,
    setIdPessoaModalBlocklist,
    dadosModalBlocklist,
  } = useContext(CadastroPessoaContext);

  const { values, setFieldValue, resetForm } = formik;

  const columns = [
    {
      Header: 'CPF',
      accessor: 'documento',
      Cell: ({ value }) => (value ? cpfFormatter(value) : ''),
    },
    {
      Header: 'Nome',
      accessor: 'nome',
    },
    {
      Header: 'Nome',
      accessor: 'NomeMobile',
      Cell: ({ row }) => filtraNome(row.values),
    },
    {
      Header: 'Nascimento',
      accessor: 'nascimento',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {value ? moment(value).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
    {
      Header: 'Cadastro',
      accessor: 'cadastro',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {value ? moment(value).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
    {
      Header: 'Telefone',
      accessor: 'telefone',
      Cell: ({ value }) => (value ? cellPhoneFormatter(value) : ''),
    },
    {
      Header: 'E-mail',
      accessor: 'email',
    },
  ];

  if (checarPermissao('botao', 'botao.cadastroPessoa.edicao', 'Visualizar'))
    columns.push({
      Header: '',
      accessor: 'edit',
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original.pessoaJuridica) {
          return (
            <IconEdit
              onClick={() =>
                history.push(`/cadastro-pessoa/${row.original.pessoaId}`)
              }
            />
          );
        }
      },
    });

  if (checarPermissao('botao', 'botao.cadastroPessoa.blocklist', 'Visualizar'))
    columns.push({
      Header: '',
      accessor: 'config',
      disableSortBy: true,
      Cell: ({ row }) => {
        if (!row.original.pessoaJuridica) {
          return (
            <div style={{ textAlign: 'center' }}>
              <IconConfig
                onClick={() => {
                  setIdPessoaModalBlocklist(row.original.pessoaId);
                  setIsModalVisible(true);
                }}
                tooltip="Blocklist"
              />
            </div>
          );
        } else {
          return '';
        }
      },
    });

  const [showFilter, setShowFilter] = useState(false);

  const handleEnvioFormulario = () => {
    setFilters({
      filtroNome: values?.filtroNome,
      filtroCPF: values?.filtroCPF?.replace(/\D/g, ''),
      filtroTelefone: values?.filtroTelefone?.replace(/\D/g, ''),
      filtroUC: values?.filtroUC,
    });
  };

  return (
    <>
      {checarPermissao(
        'tela',
        'tela.credito.pessoa.cadastro',
        'Visualizar',
      ) && (
        <>
          <Card
            title={<Title label="Relação de Clientes" />}
            rightBar={
              <Button
                type="button"
                variant="gray"
                className="btn-filtrar"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterIcon />
                FILTRAR
              </Button>
            }
          >
            {showFilter && (
              <div className="box-filtros">
                <FormikProvider value={formik}>
                  <Form layout="vertical">
                    <div className="row">
                      <div className="col-lg-10 col-md-12 col-inputs">
                        <div className="form-row">
                          <div className="col-sm-4">
                            <Form.Item label="Nome" name="filtroNome">
                              <Input name="filtroNome" />
                            </Form.Item>
                          </div>
                          <div className="col-sm-2">
                            <Form.Item label="CPF" name="filtroCPF">
                              <InputMask
                                placeholder="000.000.000-00"
                                className="ant-input"
                                mask={cpfMask}
                                name="filtroCPF"
                                onChange={(e) =>
                                  setFieldValue('filtroCPF', e.target.value)
                                }
                                value={values.filtroCPF || ''}
                              ></InputMask>
                            </Form.Item>
                          </div>
                          <div className="col-sm-2">
                            <Form.Item label="Telefone" name="filtroTelefone">
                              <Field name="filtroTelefone">
                                {({ field }) => <InputPhone {...field} />}
                              </Field>
                            </Form.Item>
                          </div>
                          <div className="col-sm-4">
                            <Form.Item
                              label="Unidade Consumidora"
                              name="filtroUC"
                            >
                              <Input name="filtroUC" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-12 col-botoes">
                        <Button
                          type="button"
                          className="btn-pesquisar"
                          variant="blue"
                          async={true}
                          onClick={() => {
                            handleEnvioFormulario();
                          }}
                        >
                          <IconSearch />
                          Pesquisar
                        </Button>
                        <Button
                          type="button"
                          className="btn-limpar"
                          variant="gray"
                          async={true}
                          onClick={() => {
                            setFilters(initialValuesFiltros);
                            resetForm();
                            setPaginacaoPessoas({
                              total: 0,
                              pageCount: 0,
                              filtroDinamico: '',
                              currentPage: 0,
                              loading: false,
                            });
                          }}
                        >
                          <IconErase />
                          Limpar
                        </Button>
                      </div>
                    </div>
                  </Form>
                </FormikProvider>
              </div>
            )}
            <div className="tabelaListagemCadastro">
              <Table
                columns={columns}
                data={data}
                pageCount={paginacaoPessoas.pageCount}
                loading={paginacaoPessoas.loading}
                onLoadData={fetchPessoas}
                total={paginacaoPessoas.total}
                filter={filters}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            </div>
          </Card>
          <ModalBlocklist
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            dados={dadosModalBlocklist}
            disabled={
              !checarPermissao(
                'botao',
                'botao.cadastroPessoa.blocklist',
                'Criar',
              )
            }
          />
        </>
      )}
    </>
  );
}

export default CadastroPessoaList;
