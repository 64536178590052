import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function Atraso({ value }) {
  switch (value) {
    case 1:
      return <span className="badge badge-primary badge-status">Pago</span>;

    case 2:
      return (
        <span className="badge badge-warning badge-status">Em atraso</span>
      );

    case 3:
      return <span className="badge badge-gray badge-status">Em dia</span>;

    default:
      return null;
  }
}

Atraso.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Atraso;
