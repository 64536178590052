import React from 'react';

import { ReactComponent as IconCalculadora } from '../../assets/images/icons/icon-calculadora-header-luz-dia.svg';
import { ReactComponent as IconMenos } from '../../assets/images/icons/icon-menos-header-luz-dia.svg';
import { ReactComponent as IconResultado } from '../../assets/images/icons/icon-resultado-header-luz-dia.svg';
import { ReactComponent as IconCircleExclamation } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import { currencyFormat } from '../../utils/numberFormatter';

import './styles.scss';

function HeaderCalculoLuzDia({
  debitosAtuaisAcimaLimite = false,
  debitosEncontrados = false,
  valorDisponivel,
  debitosPagar,
  valorLimite,
}) {
  return (
    <div className="header-calculo-luz-dia">
      <div className="row">
        {!debitosAtuaisAcimaLimite && !debitosEncontrados ? (
          <>
            <div className="col-sm-4 col-disponivel">
              <IconCalculadora className="icon" />
              <div className="info-texto">
                <h5>Valor Solicitado</h5>
                <h3>
                  {valorDisponivel ? currencyFormat(valorDisponivel) : '--'}
                </h3>
              </div>
            </div>
            <div className="col-sm-1 col-2 col-operador">
              <IconMenos className="icon" />
            </div>
            <div className="col-sm-3 col-10 col-debitos-pagar">
              <h5>Débitos a pagar</h5>
              <h3>{currencyFormat(debitosPagar)}</h3>
            </div>
            <div className="col-sm-1 col-2 col-operador">
              <IconResultado className="icon" />
            </div>
            <div className="col-sm-3 col-10 col-receber">
              <h5>Valor Liberado</h5>
              <h3>
                {valorDisponivel
                  ? currencyFormat(valorDisponivel - debitosPagar)
                  : '--'}
              </h3>
            </div>
          </>
        ) : (
          <>
            <div className="col-sm-1 col-icon-exclamation">
              <IconCircleExclamation />
            </div>
            <div className="col-sm-9 col-texto">
              <p>
                {debitosAtuaisAcimaLimite
                  ? `Os débitos atuais do cliente estão acima do limite de ${currencyFormat(
                      valorLimite,
                    )} para
                  prosseguimento da proposta`
                  : debitosEncontrados && (
                      <>
                        Foram encontrados débitos junto a concessionária de
                        energia que não se enquadram no Luz em Dia.
                        <br />
                        Favor enviar comprovantes de pagamento para as faturas
                        abaixo para prosseguir com a proposta.
                      </>
                    )}
              </p>
            </div>
            <div className="col-sm-2 text-right col-total-debitos">
              <div className="align">
                <h5>Total de Débitos</h5>
                <h3>{currencyFormat(debitosPagar)}</h3>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default HeaderCalculoLuzDia;
