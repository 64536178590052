import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import InputMoney from 'react-number-format';
import Input from '../../../components/SimpleInput';
import { isNumber } from 'lodash';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { CalculadoraContext } from '../../../contexts/CalculadoraContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import DatePicker from '../../../components/DatePicker';
import Modal from '../../../components/Modal';
import { Modal as ModalAntd, Button as ButtonAntd } from 'antd';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import {
  ControleRenda,
  TipoCalculoVencimento,
} from '../../../utils/identificadoresFrontBack';
import { currencyFormat } from '../../../utils/numberFormatter';
import RendaInput from '../../../components/InputRenda';
import { Tooltip } from 'antd';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip2.svg';

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const dayToAdd = 'day';
let objetoLimitesParcela;
let objetoLimitesProduto;

function DadosOperacaoForm({ formik, recalculoTabelaJuros, disabledFields }) {
  const RENDA_MANUAL = 1;
  const REFIN_ID = 10;

  const { values, setFieldValue, setFieldTouched } = formik;
  const [calculoVencimento, setCalculoVencimento] = useState(null);
  const [valoresOuParcelas, setValoresOuParcelas] = useState([]);
  const [ocultarRendaEstimada, setOcultarRendaEstimada] = useState(0);
  const [renda, setRenda] = useState(0);
  const [vencimentoInvalido, setVencimentoInvalido] = useState(false);
  const [valorSaldoDevedorRefin, setValorSaldoDevedorRefin] = useState(0);
  const [acesso, setAcesso] = useState(false);
  const [isLuzEmDia, setIsLuzEmDia] = useState(false);
  const [permiteRecalculo, setPermiteRecalculo] = useState(false);
  const [visibleModalAlerta, setVisibleModalAlerta] = useState(false);
  const [initialInputValue, setInitialInputValue] = useState(null);
  const { checarPermissao } = useContext(ControleAcessoContext);


  const prevDiaRecebimentoRef = useRef(null);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const {
    tabelaJuros,
    planos,
    setPlanos,
    validationField,
    editableField,
    maiorValorLimiteParcela,
    setMaiorValorLimiteParcela,
    setMenorValorLimite,
    setMenorValorLimiteParcela,
    menorValorLimite,
    menorValorLimiteParcela,
    tabelaSelecionada,
    setTabelaSelecionada,
    setShowOverlay,
    proposta,
    calculado,
    setCalculado,
    maiorValorLimite,
    setMaiorValorLimite,
    dataRecalculo,
    setDataRecalculo,
    setListaModal,
    diaRecebimento,
    totalDebitosNaoComprovados,
    faturasDescontar,
    camposProposta,
    setValores,
    valores,
    setParcelas,
    parcelas
  } = useContext(PropostaCreditoContext);

  const {
    calcularPlanos,
    calcularValores,
    calcularParcelas,
    calcularVencimentos,
    calcularLimitesParcelas,
    calcularValorLimite,
    calcularValoresLimitesPrestacao,
  } = useContext(CalculadoraContext);

  const abateSomenteLimiteParcela = values?.operacao?.tipoCalculoVencimento === TipoCalculoVencimento.DEBITO_EM_CONTA;

  const TooltipInfo = ({ text }) => (
    <Tooltip
      placement="top"
      className="tooltip-info"
      overlayClassName="tooltip-selecao-oferta-form"
      color="#435361"
      title={text}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );

  const SelectParcela = ({ disabled }) => (
    <Select
      getPopupContainer={(trigger) => trigger.parentNode}
      name="operacao.tipoCalculo"
      size="small"
      onChange={() => {
        setCalculado(true);
      }}
      className="select-valor-parcela"
      suffixIcon={<IconArrowSelect />}
      disabled={disabled || disabledFields}
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Option value={0}>
        Valor{' '}
        {proposta?.operacao?.produtoId === REFIN_ID ? 'liberado' : 'solicitado'}
      </Option>
      <Option value={1}>Valor da parcela</Option>
    </Select>
  );

  const SelectRenda = ({ onChange }) => {
    if (
      values?.operacao?.controleRenda === ControleRenda.APENAS_MANUAL &&
      values?.operacao.tipoRenda !== RENDA_MANUAL
    ) {
      setFieldValue('operacao.tipoRenda', RENDA_MANUAL);
    }

    return proposta?.operacao?.controleRenda === ControleRenda.TODAS ? (
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        name="operacao.tipoRenda"
        size="small"
        onChange={onChange}
        className="select-valor-parcela"
        disabled={!editableField('operacao.tipoRenda') || disabledFields}
        suffixIcon={<IconArrowSelect />}
      >
        <Option value={0}>Renda Estimada</Option>
        <Option value={1}>Renda Manual</Option>
      </Select>
    ) : (
      <b className="renda-text">
        {proposta?.operacao?.controleRenda === ControleRenda.APENAS_ESTIMADA &&
          'Renda Estimada'}
        {proposta?.operacao?.controleRenda === ControleRenda.APENAS_MANUAL && (
          <>
            Renda Manual{' '}
            <TooltipInfo text="Deverá ser informada a renda liquida que consta no holerite do cliente." />
          </>
        )}
      </b>
    );
  };

  const SelectValorContratado = () => {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        name="operacao.valorContratado"
        style={{ textAlign: 'right' }}
        onChange={async (value) => {
          setCalculado(true);
          setShowOverlay(true);
          setFieldValue('operacao.floatValorContratado', parseFloat(value));
          await setDataRecalculo(null);

          await simularProposta(
            parseFloat(value),
            true,
            values?.operacao?.vencimento,
            renda,
            null,
          ).catch(() => setShowOverlay(false));

          setShowOverlay(false);
        }}
        suffixIcon={<IconArrowSelect />}
        disabled={(acesso ? !editableField('operacao.valorContratado') : true) || disabledFields}
      >
        {valoresOuParcelas?.length ? (
          valoresOuParcelas
            ?.filter((item) => {
              if (isLuzEmDia) {
                if (values?.operacao?.tipoCalculo === 0) {
                  return item.valor <= proposta?.resultadoCrivo?.valorLuzEmDia;
                } else {
                  return (
                    item.solicitado <= proposta?.resultadoCrivo?.valorLuzEmDia
                  );
                }
              }
              return item;
            })
            ?.map((item) => (
              <Option
                key={item.valor}
                value={item.valor}
                style={{ textAlign: 'right' }}
              >
                <span className="text-right col-12">{item.texto}</span>
              </Option>
            ))
        ) : (
          <Option
            value={values?.operacao?.valorContratado}
            style={{ textAlign: 'right' }}
          >
            <span className="text-right col-12">
              R${' '}
              {parseFloat(
                (values?.operacao?.valorContratado * 100) / 100,
              ).toFixed(2)}
            </span>
          </Option>
        )}
      </Select>
    );
  };

  const SelectPrazoPrestacao = () => {
    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentNode}
        name="operacao.prazo"
        className="text-right"
        disabled={
          (acesso
            ? !editableField('operacao.prazo') || planos?.length <= 1
            : true)
          || disabledFields
        }
        onChange={async () => {
          await setDataRecalculo(null);
        }}
        suffixIcon={planos?.length > 1 && <IconArrowSelect />}
        dropdownAlign={{
          overflow: { adjustY: 0 },
        }}
      >
        {planos?.length ? (
          planos.map((plano) => (
            <Option className="text-right" key={plano.id} value={plano.plano}>
              {values?.operacao?.tipoCalculo === 0
                ? `${plano.plano}x de ${currencyFormat(plano.prestacao)}`
                : `${currencyFormat(plano.solicitado)} em ${plano.plano}x`}
            </Option>
          ))
        ) : (
          <Option className="text-right" value={values?.operacao?.prazo}>
            {values?.operacao?.tipoCalculo === 0
              ? `${values?.operacao?.prazo}x de ${currencyFormat(
                values?.operacao?.prestacao,
              )}`
              : `${currencyFormat(
                !editableField('operacao.prazo') && isLuzEmDia
                  ? proposta?.operacao?.valorContratado
                  : values?.operacao?.valorLiberado,
              )} em ${values?.operacao?.prazo}x`}
          </Option>
        )}
      </Select>
    );
  };

  useEffect(() => {
    setPermiteRecalculo(
      !!editableField('operacao.renda') ||
      !!editableField('operacao.prazo') ||
      !!editableField('operacao.valorContratado'),
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [camposProposta]);

  useEffect(() => {
    if (proposta?.validaContratoRefin?.status === 3) {
      setTimeout(() => {
        setFieldValue('operacao.prazo', null);
        setFieldValue('operacao.valorContratado', '');
      }, 4000);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [proposta?.validaContratoRefin]);

  useEffect(() => {
    if (values.operacao.tipoRenda) {
      setOcultarRendaEstimada(false);
    } else {
      setOcultarRendaEstimada(true);
    }
  }, [values?.operacao.tipoRenda]);

  useEffect(() => {
    const menorValor = Math.min(
      proposta?.resultadoCrivo?.limite,
      proposta?.operacao?.operacao,
    );
    setFieldValue('operacao.operacao', menorValor);
    if (proposta?.tipoCalculo === 0) {
      setFieldValue('operacao.prazo', proposta?.operacao?.prazo);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [proposta?.operacao, proposta?.resultadoCrivo]);

  useEffect(() => {
    objetoLimitesParcela = null;

    if (recalculoTabelaJuros && values?.operacao?.tabelaJurosId) {
      handleChangeTabelaJuros(values?.operacao?.tabelaJurosId);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [recalculoTabelaJuros]);

  useEffect(() => {
    if (proposta?.operacao?.tipoRenda) {
      setRenda(proposta?.operacao?.renda || 0);
    } else {
      setRenda(proposta?.operacao?.rendaEstimada || 0);
    }
    setDataRecalculo(proposta?.operacao?.recalculo);
    setValorSaldoDevedorRefin(proposta?.operacao?.saldoDevedor || 0);
    atualizaValorSolicitadoLiberado();
    /* eslint-disable react-hooks/exhaustive-deps */


  }, [proposta]);

  useEffect(() => {
    if (
      tabelaSelecionada &&
      proposta?.validaCarencia?.status === 1 &&
      (moment(values?.operacao?.vencimento) <
        moment().add(tabelaSelecionada.carenciaMinima, 'd').startOf('day') ||
        moment(values?.operacao?.vencimento) >
        moment().add(tabelaSelecionada.carenciaMaxima, 'd').startOf('day'))
    ) {
      setVencimentoInvalido(true);
      if (values?.operacao?.tipoCalculoVencimento === 3) {
        setFieldValue(
          'operacao.vencimento',
          moment(values?.operacao?.vencimento).format('DD/MM/YYYY'),
        );
        setFieldTouched('operacao.vencimento', true);
      } else {
        setFieldTouched('operacao.vencimento', true);
      }
      if (!calculado) {
        setListaModal((prev) => {
          return [...prev, proposta?.validaCarencia?.mensagem];
        });
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tabelaSelecionada]);

  useEffect(() => {
    setFieldValue('operacao.renda', renda);
    setFieldValue('profissional.renda', renda);
    setFieldValue('profissional.floatRenda', renda);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [renda]);

  const disabledDate = (current) =>
    tabelaSelecionada &&
    !(
      current >=
      moment().add(tabelaSelecionada.carenciaMinima, 'd').startOf('day') &&
      current <
      moment().add(tabelaSelecionada.carenciaMaxima, 'd').startOf('day')
    );

  const simularProposta = async (
    valor = null,
    atualizaPlanos = true,
    vencimento,
    renda,
    recalculo = null,
    tabelaJurosId = null,
  ) => {
    setShowOverlay(true);
    if (
      values?.operacao?.tipoCalculo === 1 &&
      !valoresOuParcelas?.length &&
      (maiorValorLimiteParcela > 0 || values?.operacao?.produtoNome === "CP Auto") &&
      values?.operacao?.floatValorContratado >
      parseFloat(maiorValorLimiteParcela?.toFixed(2))
    ) {
      Modal(
        'Atenção',
        `O valor da parcela selecionada excede o valor limite de ${currencyFormat(
          maiorValorLimiteParcela,
        )}.`,
        'warning',
      );
      limpaCamposValores();
      setShowOverlay(false);
    } else if (
      values?.operacao?.tipoCalculo === 1 &&
      !valoresOuParcelas?.length &&
      values?.operacao?.floatValorContratado > 0 &&
      menorValorLimiteParcela > 0 &&
      values?.operacao?.floatValorContratado <
      parseFloat(menorValorLimiteParcela?.toFixed(2))
    ) {
      setVisibleModalAlerta(true);
      limpaCamposValores();
      setShowOverlay(false);
    } else if (
      values?.operacao?.tipoCalculo === 0 &&
      !valoresOuParcelas?.length &&
      (maiorValorLimite > 0 || values?.operacao?.produtoNome === "CP Auto") &&
      values.operacao.floatValorContratado >
      parseFloat(maiorValorLimite?.toFixed(2))
    ) {
      Modal(
        'Atenção',
        `O valor solicitado excede o valor limite de ${currencyFormat(
          maiorValorLimite,
        )}.`,
        'warning',
      );
      limpaCamposValores();
      setShowOverlay(false);
    } else if (
      values?.operacao?.tipoCalculo === 0 &&
      !valoresOuParcelas?.length &&
      values?.operacao?.floatValorContratado > 0 &&
      menorValorLimite > 0 &&
      values.operacao.floatValorContratado <
      parseFloat(menorValorLimite?.toFixed(2))
    ) {
      setVisibleModalAlerta(true);
      limpaCamposValores();
      setShowOverlay(false);
    } else {

      if (values.operacao.floatValorContratado) {
        values.operacao.valorContratado = values.operacao.floatValorContratado;
      } else {
        if (values?.operacao?.tipoCalculo === 0) {
          values.operacao.valorContratado = proposta?.operacao?.valorContratado;
        } else {
          values.operacao.valorContratado = proposta?.operacao?.prestacao;
        }
      }



      if (values?.operacao?.produtoId) {
        const payloadSimulacao = {
          propostaId: values?.id,
          produtoId: values?.operacao?.produtoId,
          convenioId: values?.operacao?.convenioId,
          tabelaJurosId: tabelaJurosId || values?.operacao?.tabelaJurosId,
          vencimento,
          renda,
          recalculo,
        };

        if (
          values.operacao.valorContratado === '' ||
          values.operacao.valorContratado === 0
        ) {
          values.operacao.valorContratado = null;
        }

        if (values?.operacao?.tipoCalculo === 0) {
          payloadSimulacao.valorContrato = valor
            ? valor
            : Math.round(
              (values.operacao.valorContratado + valorSaldoDevedorRefin) *
              100,
            ) / 100;
        } else {
          payloadSimulacao.valorPrestacao = valor
            ? valor
            : values.operacao.valorContratado;
        }

        if (
          payloadSimulacao.valorContrato !== 'R$ 0,00' &&
          payloadSimulacao.valorPrestacao !== 'R$ 0,00' &&
          payloadSimulacao.valorContrato !== 0 &&
          payloadSimulacao.valorPrestacao !== 0
        ) {
          const response = await calcularPlanos(
            payloadSimulacao,
            values?.operacao?.tipoCalculo,
            valorSaldoDevedorRefin,
            dataRecalculo,
          );

          const temLimitesCrivo = !!(
            menorValorLimite &&
            maiorValorLimite &&
            menorValorLimiteParcela &&
            maiorValorLimiteParcela
          );

          if (atualizaPlanos) {
            setPlanos(
              response?.filter((item) => {
                if (!values?.operacao?.limitarComprometimento) {
                  return item;
                }
                if (values?.operacao?.tipoCalculo) {
                  const limite = objetoLimitesParcela?.find(
                    (el) =>
                      item.plano >= el.rangeInicial &&
                      item.plano <= el.rangeFinal,
                  );
                  if (
                    item.solicitado <=
                    (Math.round(maiorValorLimite * 100) / 100 ||
                      item.solicitado) &&
                    item.valor <= Math.round(limite?.limiteParcela * 100) / 100
                    // && item.solicitado > 0
                  ) {
                    if (temLimitesCrivo) {
                      return (
                        item.solicitado >= menorValorLimite &&
                        item.solicitado <= maiorValorLimite
                      );
                    }
                    return item;
                  }
                } else {
                  const limite = objetoLimitesParcela?.find(
                    (el) =>
                      item.plano >= el.rangeInicial &&
                      item.plano <= el.rangeFinal,
                  );
                  if (
                    Math.round(item.valor * 100) / 100 <=
                    (parseFloat(maiorValorLimite?.toFixed(2)) ||
                      Math.round(item.valor * 100) / 100) &&
                    item.prestacao <=
                    parseFloat(limite?.limiteParcela?.toFixed(2))
                    // && item.prestacao > 0
                  ) {
                    if (temLimitesCrivo) {
                      return (
                        item.prestacao >= menorValorLimiteParcela &&
                        item.prestacao <= maiorValorLimiteParcela
                      );
                    }
                    return item;
                  }
                }
                return false;
              }) || [],
            );
          }
        }
      }

      setShowOverlay(false);
    }
  };

  const aplicarPercentualParcela = () => {
    const adicionalPercentualParcela =
      proposta?.operacao?.adicionalPercentualParcela / 100 + 1 ?? 1;

    objetoLimitesParcela?.forEach((item) => {
      if (
        item.minimoParcelaCrivo === item.limiteParcela &&
        adicionalPercentualParcela
      ) {
        item.limiteParcela *= adicionalPercentualParcela;
      }
    });

    if (objetoLimitesParcela) {
      const maiorValorMaximoParcela = Math?.max(
        ...objetoLimitesParcela?.map((item) => item?.limiteParcela),
      );
      setMaiorValorLimiteParcela(maiorValorMaximoParcela);
    }
  };

  useEffect(() => {
    let existePrestacaoMaiorQueLimite = false;
    let existePrestacaoMenorQueMinimo = false;
    let existePrestacaoMaiorQueSolicitado = false;

    var maiorValorParcela = (proposta?.operacao?.produtoNome === "Energia" || proposta?.operacao?.produtoNome === "Luz em Dia") ? parcelas[0]?.valor : Math?.max(objetoLimitesParcela?.map((item) => item?.limiteParcela))

    var valorAjusteParcela = proposta?.resultadoCrivo?.valorAjusteParcela > maiorValorParcela ? maiorValorParcela : proposta?.resultadoCrivo?.valorAjusteParcela

    planos.forEach(plano => {
      if (plano.prestacao > valorAjusteParcela) {
        existePrestacaoMaiorQueLimite = true;
      }
      if (plano.prestacao < menorValorLimiteParcela) {
        existePrestacaoMenorQueMinimo = true;
      }
      if (plano.solicitado > maiorValorLimite) {
        existePrestacaoMaiorQueSolicitado = true;
      }
    })

    if (proposta?.resultadoCrivo?.valorAjusteParcela) {
      if (existePrestacaoMaiorQueLimite) {
        setPlanos(
          planos.filter(plano => plano.prestacao <= valorAjusteParcela)
        )
      }

      if (values.operacao.prazo > valorAjusteParcela) {
        setFieldValue('operacao.prazo', '')
      }
    }
    if (existePrestacaoMenorQueMinimo) {
      setPlanos(
        planos.filter(plano => plano.prestacao >= menorValorLimiteParcela)
      )
    }
    if (existePrestacaoMaiorQueSolicitado && proposta?.operacao?.produtoNome === "CDC Energia") {
      setPlanos(
        planos.filter(plano => plano.solicitado <= maiorValorLimite)
      )
    }

    // Improvemnt 13240 - alteração provisória
    if (
      planos.length === 0 &&
      values?.operacao?.produtoNome === 'CP Auto' &&
      values?.operacao?.floatValorContratado &&
      values?.operacao?.tipoCalculo === 1 &&
      maiorValorLimite > 0
    ) {
      Modal(
        '',
        `O valor da parcela não atinge o valor de operação mínima (${currencyFormat(
          values?.operacao?.valorMinimoContingencia,
        )}) definido pela regra.`,
        'warning',
      );
      return;
    }

  }, [planos])

  useEffect(() => {
    if (planos?.length === 1 && calculado) {
      setFieldValue('operacao.prazo', planos[0]?.plano);
      if (values?.operacao?.tipoCalculo === 1) {
        setFieldValue('operacao.prestacao', planos[0]?.valor);
      } else {
        setFieldValue('operacao.prestacao', planos[0]?.prestacao);
      }
    } else if (calculado) {
      setFieldValue('operacao.prazo', null);
      setFieldValue('operacao.prestacao', null);
    }
    setFieldTouched('operacao.prazo', false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [planos, values?.operacao.floatValorContratado]);

  useEffect(() => {
    const planoSelecionado = planos?.find(
      (el) => values?.operacao?.prazo === el.plano,
    );
    if (planoSelecionado) {
      if (values?.operacao?.tipoCalculo === 1) {
        setFieldValue('operacao.operacao', planoSelecionado.valorOperacao);
        setFieldValue('operacao.prestacao', planoSelecionado.valor);
        setFieldValue('operacao.valorSolicitado', planoSelecionado.solicitado);
      } else {
        setFieldValue('operacao.prestacao', planoSelecionado.prestacao);
      }
    }
    atualizaValorSolicitadoLiberado();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [values?.operacao.prazo]);

  useEffect(() => {
    setIsLuzEmDia(
      proposta?.operacao?.produtoId === 12 &&
      totalDebitosNaoComprovados > 0 &&
      proposta?.elegivelLuzEmDia === 0,
    );
    setValoresOuParcelas(values?.operacao?.tipoCalculo ? parcelas : valores);
    if (calculado) {
      setFieldValue(
        'operacao.valorContratado',
        tabelaSelecionada?.tipo === 1 ? null : 0,
      );
      setFieldValue(
        'operacao.valorSolicitado',
        tabelaSelecionada?.tipo === 1 ? null : 0,
      );

      setFieldValue('operacao.floatValorContratado', null);
      setFieldValue('operacao.prazo', null);
      setFieldValue('operacao.operacao', 0);
      setFieldTouched('operacao.valorContratado', false);
      setFieldTouched('operacao.prazo', false);
      setPlanos([]);
    }
  }, [
    valores,
    parcelas,
    values?.operacao?.tipoCalculo,
    faturasDescontar,
    totalDebitosNaoComprovados,
  ]);


  const atualizaValorSolicitadoLiberado = () => {
    if (values?.operacao?.tipoCalculo === 0) {
      setFieldValue(
        'operacao.valorLiberado',
        values?.operacao?.valorContratado,
      );
    } else {
      if (values?.operacao?.prazo && planos?.length) {
        let valorSolicitadoEmPrazo = 0;

        planos.forEach((el) => {
          if (el.plano === values?.operacao?.prazo) {
            valorSolicitadoEmPrazo = el.solicitado;
          }
        });
        setFieldValue('operacao.valorLiberado', valorSolicitadoEmPrazo);
        setFieldValue('operacao.valorSolicitado', valorSolicitadoEmPrazo);
      }
    }
  };

  useEffect(() => {
    if (
      values?.operacao?.tipoCalculoVencimento ===
      TipoCalculoVencimento.DEBITO_EM_CONTA
    ) {
      setShowOverlay(true);
      calcularVencimentos(
        values?.operacao?.produtoId,
        values?.id,
        values?.operacao?.convenioId,
        values?.operacao?.tabelaJurosId,
        values?.operacao.diaRecebimento,
      ).then((data) => {
        if (data !== undefined) {
          setFieldValue('operacao.vencimento', data[0]);
          setShowOverlay(false);
          if (prevDiaRecebimentoRef.current !== null) {
            blurRenda(data[0]);
          }
          prevDiaRecebimentoRef.current = values?.operacao.diaRecebimento;
        }
      });
      setFieldTouched('operacao.vencimento', false);
    }
  }, [values?.operacao.diaRecebimento]);

  useEffect(() => {
    async function calculaVencimentos() {

      const tempTabelaJuros = tabelaJuros?.find(
        (el) => el.id === values?.operacao?.tabelaJurosId,
      );


      if (tempTabelaJuros) {
        setCalculoVencimento(null);
        setTabelaSelecionada(tempTabelaJuros);
        if (tempTabelaJuros?.tipo === 1) {
          if (!proposta?.resultadoCrivo?.valorAjusteParcela) {
            await calcularValores(
              tempTabelaJuros,
              proposta?.operacao?.valorContingencia,
            ).then((value) => setValores(value));


            await calcularParcelas(
              tempTabelaJuros,
              values?.operacao?.produtoId,
              values?.operacao?.convenioId,
              values?.operacao?.tabelaJurosId,
              dataRecalculo
            ).then(async (value) => {
              setParcelas(value)
            });
          }
        }
        if (
          (values?.operacao?.tipoCalculoVencimento === 1 ||
            values?.operacao?.tipoCalculoVencimento === 2) &&
          !values?.operacao?.vencimento
        ) {
          setFieldValue(
            'operacao.vencimento',
            moment()
              .add(
                tempTabelaJuros?.carenciaMedia ||
                tempTabelaJuros?.carenciaMinima,
                dayToAdd,
              )
              .startOf('day'),
          );
        }
        // Caso o tipo de calculo de vencimento seja opções fixas (3), carrega a lista de opções de vencimento
        else if (values?.operacao?.tipoCalculoVencimento === 3) {
          await calcularVencimentos(
            values?.operacao?.produtoId,
            values?.id,
            values?.operacao?.convenioId,
            values?.operacao?.tabelaJurosId,
          ).then((value) => setCalculoVencimento(value));
        }

        setFieldTouched('operacao.vencimento', false);
      }
    }
    calculaVencimentos();

  }, [values?.operacao?.tabelaJurosId]);

  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    if ((proposta?.resultadoCrivo?.valorAjusteParcela && (maiorValorLimite && values?.operacao?.tipoCalculo == 0 || values?.operacao?.tipoCalculo == 1))) {

      let maiorValorParcela = (proposta?.operacao?.produtoNome === "Energia" || proposta?.operacao?.produtoNome === "Luz em Dia") ? parcelas[0]?.valor : Math?.max(objetoLimitesParcela?.map((item) => item?.limiteParcela))
      let valorAjusteParcela = proposta?.resultadoCrivo?.valorAjusteParcela > maiorValorParcela ? maiorValorParcela : proposta?.resultadoCrivo?.valorAjusteParcela

      if ((((
        values?.operacao.valorContratado > maiorValorLimite) ||
        values?.operacao?.prestacao > valorAjusteParcela) &&
        values?.operacao?.tipoCalculo === 0) ||
        (values?.operacao.valorContratado > valorAjusteParcela &&
          values?.operacao?.tipoCalculo === 1)) {

        setFieldValue('operacao.valorContratado', '')
        setFieldValue('operacao.floatValorContratado', '')
        setFieldValue('operacao.valorLiberado', '')
        setFieldValue('operacao.prazo', '')
        setFieldValue('operacao.prestacao', '')
      }
    }

  }, [values?.operacao?.valorContratado, maiorValorLimite])

  const limpaCamposValores = () => {
    setFieldValue('operacao.floatValorContratado', '');
    setFieldValue('operacao.valorContratado', '');
    setFieldValue('operacao.valorSolicitado', '');
    setFieldTouched('operacao.valorContratado', false);
    setFieldValue('operacao.prazo', null);
    setFieldTouched('operacao.prazo', false);
    setPlanos([]);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    async function calcularLimites() {
      if (
        !objetoLimitesParcela &&
        values?.operacao?.renda &&
        values?.operacao?.tabelaJurosId &&
        permiteRecalculo
      ) {
        setShowOverlay(true);


        await calcularValoresLimitesPrestacao(
          values?.operacao?.produtoId,
          values?.id,
          values?.operacao?.convenioId,
          values?.operacao?.tabelaJurosId,
          values?.operacao?.vencimento,
          proposta?.operacao?.valorContingencia,
          renda,
          dataRecalculo,
        )
          .then((value) => {
            objetoLimitesParcela = [];
            objetoLimitesProduto = value;
          })
          .catch(() => setShowOverlay(false));


        await calcularLimitesParcelas(
          renda,
          values?.operacao?.produtoId,
          values?.id,
          values?.operacao?.tabelaJurosId,
          values?.operacao?.vencimento,
          objetoLimitesProduto,
          proposta?.refin?.valorPrestacaoRefin,
          dataRecalculo,
          Number(proposta?.operacao?.valorRendaComprometida),
          Number(values?.operacao?.valorDebitoConcorrente),
          abateSomenteLimiteParcela
        )
          .then((response) => {
            const tempTabelaJuros = tabelaJuros?.find(
              (el) => el.id === values?.operacao?.tabelaJurosId,
            );
            objetoLimitesParcela = response.objetoLimitesParcela;

            if (
              objetoLimitesParcela[1]?.limiteParcela === 0 ||
              objetoLimitesParcela[1] === undefined
            ) {
              // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
              // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
              objetoLimitesParcela[0].rangeFinal = Math.min(
                objetoLimitesParcela[0].rangeFinal,
                tempTabelaJuros.planoMaximo,
              );
            }

            if (response.maiorValorLimiteParcela >= 0) {
              if (proposta?.resultadoCrivo?.valorAjusteParcela && proposta?.operacao?.produtoNome === "CDC Energia") {

                const maiorValorMaximoParcela = response.maiorValorLimiteParcela

                if (proposta?.resultadoCrivo?.valorAjusteParcela < maiorValorMaximoParcela) {

                  objetoLimitesParcela.forEach(item => {
                    item.limiteParcela = proposta?.resultadoCrivo?.valorAjusteParcela
                    item.maximoParcelaCrivo = proposta?.resultadoCrivo?.valorAjusteParcela

                    setMaiorValorLimiteParcela(proposta?.resultadoCrivo?.valorAjusteParcela);

                    if (item.minimoParcelaCrivo > proposta?.resultadoCrivo?.valorAjusteParcela) {
                      Modal('', 'O valor da parcela ultrapassa a margem disponível, de acordo com as regras do produto', 'warning');
                    }
                  });
                }
                else {
                  setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
                }
              }
              else {
                setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
              }
            }
            if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
              setMenorValorLimiteParcela(
                objetoLimitesParcela[0].minimoParcelaCrivo,
              );
            }
          })
          .catch(() => setShowOverlay(false));



        await calcularValorLimite(
          values?.operacao?.produtoId,
          values?.id,
          values?.operacao?.convenioId,
          values?.operacao?.tabelaJurosId,
          values?.operacao?.vencimento,
          proposta?.operacao?.valorContingencia,
          renda,
          objetoLimitesParcela,
          objetoLimitesProduto,
          valorSaldoDevedorRefin,
          dataRecalculo,
        )
          .then((value) => {

            setMaiorValorLimite(value)
            // Improvemnt 13240 - alteração provisória
            if (value === 0 && values?.operacao?.produtoNome === 'CP Auto') {
              objetoLimitesParcela[0].limiteParcela = 0;
              setMenorValorLimiteParcela(0)
            }
          })
          .catch(() => setShowOverlay(false));

        if (
          objetoLimitesParcela[0]?.minimoParcelaCrivo &&
          objetoLimitesParcela[0]?.maximoParcelaCrivo
        ) {
          await calcularValorLimite(
            values?.operacao?.produtoId,
            values?.id,
            values?.operacao?.convenioId,
            values?.operacao?.tabelaJurosId,
            values?.operacao?.vencimento,
            proposta?.operacao?.valorContingencia,
            renda,
            objetoLimitesParcela,
            objetoLimitesProduto,
            valorSaldoDevedorRefin,
            dataRecalculo,
            true,
          )
            .then((value) => setMenorValorLimite(value))
            .catch(() => setShowOverlay(false));

          aplicarPercentualParcela();
        }


        await simularProposta(
          null,
          true,
          values?.operacao?.vencimento,
          values?.operacao?.floatRenda,
          dataRecalculo,
        ).catch(() => setShowOverlay(false));

        setShowOverlay(false);
      }

    }
    calcularLimites();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [values?.operacao?.renda, permiteRecalculo]);

  function labelSelectRenda() {
    return (
      <SelectRenda
        onChange={async (value) => {
          let alteraRenda =
            value === RENDA_MANUAL ? renda : proposta?.operacao?.rendaEstimada;

          setRenda(alteraRenda);
          await setFieldValue('operacao.renda', alteraRenda);
          await setFieldValue('operacao.floatRenda', alteraRenda);
          await setFieldValue('profissional.renda', alteraRenda);
          await setFieldValue('profissional.floatRenda', alteraRenda);

          setFieldTouched('operacao.renda', false);

          if (value !== RENDA_MANUAL) {
            setShowOverlay(true);
            await setDataRecalculo(null);

            await calcularLimitesParcelas(
              alteraRenda,
              values?.operacao?.produtoId,
              values?.id,
              values?.operacao?.tabelaJurosId,
              values?.operacao?.vencimento,
              objetoLimitesProduto,
              proposta?.refin?.valorPrestacaoRefin,
              null,
              proposta?.operacao?.valorRendaComprometida,
            )
              .then((response) => {
                const tempTabelaJuros = tabelaJuros?.find(
                  (el) => el.id === values?.operacao?.tabelaJurosId,
                );

                objetoLimitesParcela = response.objetoLimitesParcela;
                if (
                  objetoLimitesParcela[1]?.limiteParcela === 0 ||
                  objetoLimitesParcela[1] === undefined
                ) {
                  // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
                  // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
                  objetoLimitesParcela[0].rangeFinal = Math.min(
                    objetoLimitesParcela[0].rangeFinal,
                    tempTabelaJuros.planoMaximo,
                  );
                }

                if (response.maiorValorLimiteParcela >= 0) {
                  setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
                }
                if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
                  setMenorValorLimiteParcela(
                    objetoLimitesParcela[0].minimoParcelaCrivo,
                  );
                }
              })
              .catch(() => setShowOverlay(false));

            await calcularValorLimite(
              values?.operacao?.produtoId,
              values?.id,
              values?.operacao?.convenioId,
              values?.operacao?.tabelaJurosId,
              values?.operacao?.vencimento,
              proposta?.operacao?.valorContingencia,
              alteraRenda,
              objetoLimitesParcela,
              objetoLimitesProduto,
              valorSaldoDevedorRefin,
              null,
            )
              .then((value) => {
                setMaiorValorLimite(value)
                // Improvemnt 13240 - alteração provisória
                if (value === 0 && values?.operacao?.produtoNome === 'CP Auto') {
                  objetoLimitesParcela[0].limiteParcela = 0;
                  setMenorValorLimiteParcela(0)
                }
              })
              .catch(() => setShowOverlay(false));

            if (
              objetoLimitesParcela[0]?.minimoParcelaCrivo &&
              objetoLimitesParcela[0]?.maximoParcelaCrivo
            ) {
              await calcularValorLimite(
                values?.operacao?.produtoId,
                values?.id,
                values?.operacao?.convenioId,
                values?.operacao?.tabelaJurosId,
                values?.operacao?.vencimento,
                proposta?.operacao?.valorContingencia,
                alteraRenda,
                objetoLimitesParcela,
                objetoLimitesProduto,
                valorSaldoDevedorRefin,
                null,
                true,
              )
                .then((value) => setMenorValorLimite(value))
                .catch(() => setShowOverlay(false));

              aplicarPercentualParcela();
            }

            await simularProposta(
              null,
              false,
              values?.operacao?.vencimento,
              alteraRenda,
              null,
            ).catch(() => setShowOverlay(false));

            limpaCamposValores();
            setShowOverlay(false);
          }
        }}
        disabled={disabledFields}
      />
    );
  }

  async function blurRenda(vencimentoData) {
    if (vencimentoData) {
      setFieldValue('operacao.vencimento', vencimentoData);
    }
    setShowOverlay(true);
    await setDataRecalculo(null);

    await calcularLimitesParcelas(
      renda,
      values?.operacao?.produtoId,
      values?.id,
      values?.operacao?.tabelaJurosId,
      vencimentoData ? vencimentoData : values?.operacao?.vencimento,
      objetoLimitesProduto,
      proposta?.refin?.valorPrestacaoRefin,
      null,
      proposta?.operacao?.valorRendaComprometida,
      values?.operacao?.valorDebitoConcorrente,
      abateSomenteLimiteParcela
    )
      .then((response) => {
        const tempTabelaJuros = tabelaJuros?.find(
          (el) => el.id === values?.operacao?.tabelaJurosId,
        );

        objetoLimitesParcela = response.objetoLimitesParcela;

        if (
          objetoLimitesParcela[1]?.limiteParcela === 0 ||
          objetoLimitesParcela[1] === undefined
        ) {
          // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
          // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
          objetoLimitesParcela[0].rangeFinal = Math.min(
            objetoLimitesParcela[0].rangeFinal,
            tempTabelaJuros.planoMaximo,
          );
        }

        if (response.maiorValorLimiteParcela >= 0) {
          setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
        }
        if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
          setMenorValorLimiteParcela(
            objetoLimitesParcela[0].minimoParcelaCrivo,
          );
        }
      })
      .catch(() => setShowOverlay(false));

    await calcularValorLimite(
      values?.operacao?.produtoId,
      values?.id,
      values?.operacao?.convenioId,
      values?.operacao?.tabelaJurosId,
      vencimentoData ? vencimentoData : values?.operacao?.vencimento,
      proposta?.operacao?.valorContingencia,
      renda,
      objetoLimitesParcela,
      objetoLimitesProduto,
      valorSaldoDevedorRefin,
      null,
    )
      .then((value) => {
        setMaiorValorLimite(value)
        // Improvemnt 13240 - alteração provisória
        if (value === 0 && values?.operacao?.produtoNome === 'CP Auto') {
          objetoLimitesParcela[0].limiteParcela = 0;
          setMenorValorLimiteParcela(0)
        }
      })
      .catch(() => setShowOverlay(false));

    if (
      objetoLimitesParcela[0]?.minimoParcelaCrivo &&
      objetoLimitesParcela[0]?.maximoParcelaCrivo
    ) {
      await calcularValorLimite(
        values?.operacao?.produtoId,
        values?.id,
        values?.operacao?.convenioId,
        values?.operacao?.tabelaJurosId,
        vencimentoData ? vencimentoData : values?.operacao?.vencimento,
        proposta?.operacao?.valorContingencia,
        renda,
        objetoLimitesParcela,
        objetoLimitesProduto,
        valorSaldoDevedorRefin,
        null,
        true,
      )
        .then((value) => setMenorValorLimite(value))
        .catch(() => setShowOverlay(false));

      aplicarPercentualParcela();
    }

    simularProposta(
      null,
      false,
      vencimentoData ? vencimentoData : values?.operacao?.vencimento,
      renda,
      null,
    ).catch(() => setShowOverlay(false));

    limpaCamposValores();
    setShowOverlay(false);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (values?.operacao?.tipoCalculo === 0) {
      setFieldValue(
        'operacao.valorLiberado',
        values?.operacao?.valorContratado,
      );
    } else {
      if (values?.operacao?.prazo && planos?.length) {
        let valorSolicitadoEmPrazo = 0;

        planos.forEach((el) => {
          if (el.plano === values?.operacao?.prazo) {
            valorSolicitadoEmPrazo = el.solicitado;
          }
        });
        setFieldValue('operacao.valorLiberado', valorSolicitadoEmPrazo);
        setFieldValue('operacao.valorSolicitado', valorSolicitadoEmPrazo);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    proposta,
    planos,
    values?.operacao?.valorContratado,
    values?.operacao?.prazo,
  ]);

  async function handleChangeTabelaJuros(value) {
    setShowOverlay(true);
    setCalculado(true);
    await setDataRecalculo(null);
    const tempTabelaJuros = tabelaJuros?.find((el) => el.id === value);

    await calcularValoresLimitesPrestacao(
      values?.operacao?.produtoId,
      proposta.id,
      values?.operacao?.convenioId,
      value,
      values?.operacao?.vencimento,
      values?.operacao?.valorContingencia,
      renda,
    ).then((value) => {
      objetoLimitesParcela = [];
      objetoLimitesProduto = value;
    });

    await calcularLimitesParcelas(
      renda,
      values?.operacao?.produtoId,
      values?.id,
      value,
      values?.operacao?.vencimento,
      objetoLimitesProduto,
      proposta?.refin?.valorPrestacaoRefin,
      null,
      proposta?.operacao?.valorRendaComprometida,
      values?.operacao?.valorDebitoConcorrente,
      abateSomenteLimiteParcela
    )
      .then((response) => {
        objetoLimitesParcela = response.objetoLimitesParcela;

        if (
          objetoLimitesParcela[1]?.limiteParcela === 0 ||
          objetoLimitesParcela[1] === undefined
        ) {
          // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
          // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
          objetoLimitesParcela[0].rangeFinal = Math.min(
            objetoLimitesParcela[0].rangeFinal,
            tempTabelaJuros.planoMaximo,
          );
        }

        if (response.maiorValorLimiteParcela >= 0) {
          setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
        }
        if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
          setMenorValorLimiteParcela(
            objetoLimitesParcela[0].minimoParcelaCrivo,
          );
        }
      })
      .catch(() => setShowOverlay(false));

    await calcularValorLimite(
      values?.operacao?.produtoId,
      values?.id,
      values?.operacao?.convenioId,
      value,
      values?.operacao?.vencimento,
      proposta?.operacao?.valorContingencia,
      renda,
      objetoLimitesParcela,
      objetoLimitesProduto,
      valorSaldoDevedorRefin,
      null,
    )
      .then((value) => {
        setMaiorValorLimite(value)

        // Improvemnt 13240 - alteração provisória
        if (value === 0 && values?.operacao?.produtoNome === 'CP Auto') {
          objetoLimitesParcela[0].limiteParcela = 0;
          setMenorValorLimiteParcela(0)
        }
      })
      .catch(() => setShowOverlay(false));

    if (
      objetoLimitesParcela[0]?.minimoParcelaCrivo &&
      objetoLimitesParcela[0]?.maximoParcelaCrivo
    ) {
      await calcularValorLimite(
        values?.operacao?.produtoId,
        values?.id,
        values?.operacao?.convenioId,
        value,
        values?.operacao?.vencimento,
        proposta?.operacao?.valorContingencia,
        renda,
        objetoLimitesParcela,
        objetoLimitesProduto,
        valorSaldoDevedorRefin,
        null,
        true,
      )
        .then((value) => setMenorValorLimite(value))
        .catch(() => setShowOverlay(false));

      aplicarPercentualParcela();
    }

    limpaCamposValores();

    await simularProposta(
      null,
      false,
      values?.operacao?.vencimento,
      renda,
      null,
      value,
    ).catch(() => setShowOverlay(false));
    setShowOverlay(false);
  }

  return (
    <Fragment>
      <ModalAntd
        visible={visibleModalAlerta}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setVisibleModalAlerta(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          O valor{' '}
          {values?.operacao?.tipoCalculo === 0 ? `solicitado` : `da parcela`} é
          menor que o valor mínimo de{' '}
          {values?.operacao?.tipoCalculo === 0
            ? currencyFormat(menorValorLimite)
            : currencyFormat(menorValorLimiteParcela)}
        </div>
        <div className="btn-area">
          <ButtonAntd
            variant="blue"
            onClick={() => setVisibleModalAlerta(false)}
            className="btn-confirm"
          >
            OK
          </ButtonAntd>
        </div>
      </ModalAntd>
      <div className="form-row pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados de operação</div>
      </div>

      <div className="form-row">
        <div className="col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="operacao.produtoNome"
            label="Produto"
            validate={(value) =>
              validationField(value, 'operacao.produtoNome', 'Nome do Produto')
            }
          >
            <Input
              name="operacao.produtoNome"
              className={isLuzEmDia && 'valor-alterado-luz-dia-input'}
              disabled={
                (acesso ? !editableField('operacao.produtoNome') : true)
                || disabledFields
              }
              value={
                proposta?.operacao?.produtoId !== 12 || isLuzEmDia
                  ? values.operacao.produtoNome
                  : values.operacao.produtoOriginal
              }
              onBlur={(e) => {
                setFieldValue('operacao.produtoNome', e.target.value);
              }}
            />
            {isLuzEmDia && (
              <small className="valor-alterado-luz-dia-explain">
                {`*Produto alterado pelo sistema, produto original: ${values?.operacao?.produtoOriginal}`}
              </small>
            )}
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-2">
          <Form.Item
            name="id"
            label="Nº Proposta"
            validate={(value) => validationField(value, 'id', 'Nº Proposta')}
          >
            <Input
              name="id"
              disabled={
                (acesso ? !editableField('id') : true)
                || disabledFields
              }
              value={proposta?.id}
              onBlur={(e) => {
                setFieldValue('id', e.target.value);
              }}
            />
          </Form.Item>
        </div>

        {acesso &&
          (values?.operacao?.produtoNome === 'Boleto' ||
            values?.operacao?.produtoNome === 'CP Auto' ||
            values?.operacao?.produtoId === REFIN_ID ||
            values?.operacao?.tipoCalculoVencimento ===
            TipoCalculoVencimento.DEBITO_EM_CONTA) && (
            <div className="col-md-3 col-lg-3 col-xl-2">
              <Form.Item
                name="operacao.diaRecebimento"
                label="Dia de Recebimento"
                validate={(value) =>
                  validationField(
                    value,
                    'operacao.diaRecebimento',
                    'Dia de Recebimento',
                  )
                }
                hasFeedback
              >
                <Select
                  name="operacao.diaRecebimento"
                  className="text-right"
                  disabled={
                    !editableField('operacao.diaRecebimento')
                    || disabledFields
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                  suffixIcon={<IconArrowSelect />}
                >
                  {diaRecebimento?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      <span className="text-right col-12">{item.nome}</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
        {acesso &&
          values?.operacao?.tipoCalculoVencimento ===
          TipoCalculoVencimento.DEBITO_EM_CONTA && (
            <div className="col-md-3 col-lg-3 col-xl-3">
              <Form.Item name="operacao.vencimento" label="Data Vencimento">
                <Input
                  className="input-debito-concorrente"
                  name="operacao.vencimento"
                  disabled
                  value={
                    values?.operacao?.vencimento
                      ? moment(values?.operacao?.vencimento).format(
                        'DD/MM/YYYY',
                      )
                      : ''
                  }
                />
              </Form.Item>
            </div>
          )}

        {acesso &&
          (values?.operacao?.tipoCalculoVencimento ===
            TipoCalculoVencimento.CARNE ||
            values?.operacao?.tipoCalculoVencimento ===
            TipoCalculoVencimento.CONSIGNADO_PRIVADO ||
            (values?.operacao?.tipoCalculoVencimento ===
              TipoCalculoVencimento.BOLETO &&
              calculoVencimento)) && (
            <div className="col-md-4 col-lg-4 col-xl-3">
              <Form.Item
                name="operacao.vencimento"
                label="Data de Vencimento"
                validate={(value) => {
                  let errorMsg = '';
                  if (vencimentoInvalido) {
                    errorMsg = 'Data de vencimento fora do período de carência';
                  } else {
                    errorMsg = validationField(
                      value,
                      'operacao.vencimento',
                      'Data de vencimento',
                    );
                  }
                  return errorMsg;
                }}
              >
                {
                  // eslint-disable-next-line
                  values?.operacao?.tipoCalculoVencimento ===
                  TipoCalculoVencimento.CARNE ||
                  // eslint-disable-next-line
                  (values?.operacao?.tipoCalculoVencimento ===
                    TipoCalculoVencimento.CONSIGNADO_PRIVADO && (
                      <DatePicker
                        name="operacao.vencimento"
                        format={dateFormat}
                        style={{ textAlign: 'right' }}
                        disabledDate={disabledDate}
                        defaultPickerValue={
                          tabelaSelecionada &&
                          moment()
                            .add(tabelaSelecionada?.carenciaMinima, 'd')
                            .startOf('day')
                        }
                        disabled={
                          !editableField('operacao.vencimento')
                          || disabledFields
                        }
                        onChange={async (e) => {
                          setShowOverlay(true);
                          await setDataRecalculo(null);

                          await calcularValoresLimitesPrestacao(
                            values?.operacao?.produtoId,
                            values?.id,
                            values?.operacao?.convenioId,
                            values?.operacao?.tabelaJurosId,
                            values?.operacao?.vencimento,
                            proposta?.operacao?.valorContingencia,
                            renda,
                            null,
                          )
                            .then((value) => {
                              objetoLimitesParcela = [];
                              objetoLimitesProduto = value;
                            })
                            .catch(() => setShowOverlay(false));

                          await calcularLimitesParcelas(
                            renda,
                            values?.operacao?.produtoId,
                            values?.id,
                            values?.operacao?.tabelaJurosId,
                            values?.operacao?.vencimento,
                            objetoLimitesProduto,
                            proposta?.refin?.valorPrestacaoRefin,
                            null,
                            proposta?.operacao?.valorRendaComprometida,
                          )
                            .then((response) => {
                              const tempTabelaJuros = tabelaJuros?.find(
                                (el) =>
                                  el.id === values?.operacao?.tabelaJurosId,
                              );

                              objetoLimitesParcela =
                                response.objetoLimitesParcela;

                              if (
                                objetoLimitesParcela[1]?.limiteParcela === 0 ||
                                objetoLimitesParcela[1] === undefined
                              ) {
                                // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
                                // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
                                objetoLimitesParcela[0].rangeFinal = Math.min(
                                  objetoLimitesParcela[0].rangeFinal,
                                  tempTabelaJuros.planoMaximo,
                                );
                              }

                              if (response.maiorValorLimiteParcela >= 0) {
                                setMaiorValorLimiteParcela(
                                  response.maiorValorLimiteParcela,
                                );
                              }
                              if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
                                setMenorValorLimiteParcela(
                                  objetoLimitesParcela[0].minimoParcelaCrivo,
                                );
                              }
                            })
                            .catch(() => setShowOverlay(false));

                          await calcularValorLimite(
                            values?.operacao?.produtoId,
                            values?.id,
                            values?.operacao?.convenioId,
                            values?.operacao?.tabelaJurosId,
                            values?.operacao?.vencimento,
                            proposta?.operacao?.valorContingencia,
                            renda,
                            objetoLimitesParcela,
                            objetoLimitesProduto,
                            valorSaldoDevedorRefin,
                            null,
                          )
                            .then((value) => setMaiorValorLimite(value))
                            .catch(() => setShowOverlay(false));

                          if (
                            objetoLimitesParcela[0]?.minimoParcelaCrivo &&
                            objetoLimitesParcela[0]?.maximoParcelaCrivo
                          ) {
                            await calcularValorLimite(
                              values?.operacao?.produtoId,
                              values?.id,
                              values?.operacao?.convenioId,
                              values?.operacao?.tabelaJurosId,
                              values?.operacao?.vencimento,
                              proposta?.operacao?.valorContingencia,
                              renda,
                              objetoLimitesParcela,
                              objetoLimitesProduto,
                              valorSaldoDevedorRefin,
                              null,
                              true,
                            )
                              .then((value) => setMenorValorLimite(value))
                              .catch(() => setShowOverlay(false));

                            aplicarPercentualParcela();
                          }

                          await simularProposta(
                            null,
                            false,
                            values?.operacao?.vencimento,
                            renda,
                            null,
                          ).catch(() => setShowOverlay(false));

                          setCalculado(true);
                          setVencimentoInvalido(false);
                          limpaCamposValores();
                          setShowOverlay(false);
                        }}
                      />
                    ))
                }
                {values?.operacao?.tipoCalculoVencimento ===
                  TipoCalculoVencimento.BOLETO &&
                  calculoVencimento && (
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      name="operacao.vencimento"
                      className="text-right"
                      suffixIcon={<IconArrowSelect />}
                      disabled={
                        !editableField('operacao.vencimento')
                        || disabledFields
                      }
                      onChange={async (value) => {
                        setShowOverlay(true);
                        await setDataRecalculo(null);

                        await calcularValoresLimitesPrestacao(
                          values?.operacao?.produtoId,
                          values?.id,
                          values?.operacao?.convenioId,
                          values?.operacao?.tabelaJurosId,
                          value,
                          proposta?.operacao?.valorContingencia,
                          renda,
                          null,
                        )
                          .then((value) => {
                            objetoLimitesParcela = [];
                            objetoLimitesProduto = value;
                          })
                          .catch(() => setShowOverlay(false));

                        await calcularLimitesParcelas(
                          renda,
                          values?.operacao?.produtoId,
                          values?.id,
                          values?.operacao?.tabelaJurosId,
                          value,
                          objetoLimitesProduto,
                          proposta?.refin?.valorPrestacaoRefin,
                          null,
                          proposta?.operacao?.valorRendaComprometida,
                        )
                          .then((response) => {
                            const tempTabelaJuros = tabelaJuros?.find(
                              (el) => el.id === values?.operacao?.tabelaJurosId,
                            );

                            objetoLimitesParcela =
                              response.objetoLimitesParcela;

                            if (
                              objetoLimitesParcela[1]?.limiteParcela === 0 ||
                              objetoLimitesParcela[1] === undefined
                            ) {
                              // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
                              // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
                              objetoLimitesParcela[0].rangeFinal = Math.min(
                                objetoLimitesParcela[0].rangeFinal,
                                tempTabelaJuros.planoMaximo,
                              );
                            }

                            if (response.maiorValorLimiteParcela >= 0) {
                              setMaiorValorLimiteParcela(
                                response.maiorValorLimiteParcela,
                              );
                            }
                            if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
                              setMenorValorLimiteParcela(
                                objetoLimitesParcela[0].minimoParcelaCrivo,
                              );
                            }
                          })
                          .catch(() => setShowOverlay(false));

                        await calcularValorLimite(
                          values?.operacao?.produtoId,
                          values?.id,
                          values?.operacao?.convenioId,
                          values?.operacao?.tabelaJurosId,
                          value,
                          proposta?.operacao?.valorContingencia,
                          renda,
                          objetoLimitesParcela,
                          objetoLimitesProduto,
                          valorSaldoDevedorRefin,
                          null,
                        )
                          .then((value) => {
                            setMaiorValorLimite(value)
                            // Improvemnt 13240 - alteração provisória
                            if (value === 0 && values?.operacao?.produtoNome === 'CP Auto') {
                              objetoLimitesParcela[0].limiteParcela = 0;
                              setMenorValorLimiteParcela(0)
                            }
                          })
                          .catch(() => setShowOverlay(false));

                        if (
                          objetoLimitesParcela[0]?.minimoParcelaCrivo &&
                          objetoLimitesParcela[0]?.maximoParcelaCrivo
                        ) {
                          await calcularValorLimite(
                            values?.operacao?.produtoId,
                            values?.id,
                            values?.operacao?.convenioId,
                            values?.operacao?.tabelaJurosId,
                            value,
                            proposta?.operacao?.valorContingencia,
                            renda,
                            objetoLimitesParcela,
                            objetoLimitesProduto,
                            valorSaldoDevedorRefin,
                            null,
                            true,
                          )
                            .then((value) => setMenorValorLimite(value))
                            .catch(() => setShowOverlay(false));

                          aplicarPercentualParcela();
                        }

                        await simularProposta(
                          null,
                          false,
                          value,
                          renda,
                          null,
                        ).catch(() => setShowOverlay(false));
                        setCalculado(true);
                        setVencimentoInvalido(false);
                        limpaCamposValores();
                        setShowOverlay(false);
                      }}
                    >
                      {calculoVencimento?.map((item) => (
                        <Option key={item} value={item}>
                          <span className="text-right col-12">
                            {moment(item).format('DD/MM/YYYY')}
                          </span>
                        </Option>
                      ))}
                    </Select>
                  )}
              </Form.Item>
            </div>
          )}

        {tabelaJuros?.length > 1 && acesso && (
          <div
            className={
              values?.operacao?.produtoNome === 'Boleto' ||
                values?.operacao?.tipoCalculoVencimento ===
                TipoCalculoVencimento.DEBITO_EM_CONTA
                ? `col-md-4 col-lg-4 col-xl-3`
                : `col-md-3 col-lg-3 col-xl-2`
            }
          >
            <Form.Item
              name="operacao.tabelaJurosId"
              label="Tabela de Juros"
              validate={(value) =>
                validationField(
                  value,
                  'operacao.tabelaJurosId',
                  'Tabela de Juros',
                )
              }
              hasFeedback
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                name="operacao.tabelaJurosId"
                disabled={
                  !editableField('operacao.tabelaJurosId')
                  || disabledFields
                }
                suffixIcon={<IconArrowSelect />}
                dropdownAlign={{
                  overflow: { adjustY: 0 },
                }}
                onChange={handleChangeTabelaJuros}
              >
                {tabelaJuros.map((tabela) => (
                  <Option key={tabela.id} value={tabela.id}>
                    {tabela.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        {proposta && (
          <RendaInput
            acesso={
              (acesso
                ? !editableField('operacao.renda')
                  ? true
                  : ocultarRendaEstimada
                : true) || disabledFields
            }
            onBlur={blurRenda}
            setInitialInputValue={setInitialInputValue}
            initialInputValue={initialInputValue}
            labelSelectRenda={labelSelectRenda}
            values={values}
            validationField={validationField}
            renda={renda}
            setRenda={setRenda}
            tabelaJuros={tabelaJuros}
          />
        )}

        <div
          className={
            values?.operacao?.tipoCalculoVencimento ===
              TipoCalculoVencimento.DEBITO_EM_CONTA && tabelaJuros?.length > 1
              ? `col-md-3 col-lg-3 col-xl-2`
              : `col-md-4 col-lg-4 col-xl-3`
          }
        >
          <Form.Item
            name="operacao.valorContratado"
            label={
              <SelectParcela
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={
                  (acesso ? !editableField('operacao.tipoCalculo') : true)
                  || disabledFields
                }
                onChange={() => {
                  setFieldValue('operacao.valorContratado', null);
                  setFieldValue('operacao.floatValorContratado', null);
                  setFieldTouched('operacao.valorContratado', false);
                  setFieldValue('operacao.prazo', null);
                  setFieldTouched('operacao.prazo', false);
                  setDataRecalculo(null);
                  atualizaValorSolicitadoLiberado();
                }}
              />
            }
            validate={(value) =>
              validationField(
                value,
                'operacao.valorContratado',
                values?.operacao?.tipoCalculo === 0
                  ? 'Valor Solicitado'
                  : 'Valor Parcela',
              )
            }
          >
            {tabelaSelecionada?.tipo === 0 && (

              <Field name="operacao.valorContratado">
                {({ field }) => (
                  <InputMoney
                    {...field}
                    className="ant-input"
                    prefix="R$ "
                    decimalScale={2}
                    fixedDecimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    maxLength={12}
                    style={{ textAlign: 'right' }}
                    onBlur={async () => {

                      // Improvemnt 13240 - alteração provisória
                      if (values?.operacao?.tipoCalculo === 0 &&
                        values?.operacao?.produtoNome === 'CP Auto' &&
                        values?.operacao?.valorMinimoContingencia &&
                        values?.operacao?.floatValorContratado < values?.operacao?.valorMinimoContingencia &&
                        values?.operacao?.floatValorContratado &&
                        maiorValorLimite > 0
                      ) {
                        Modal(
                          '',
                          `O valor solicitado deve ser maior ou igual a ${currencyFormat(values?.operacao?.valorMinimoContingencia)}.`,
                          'warning',
                        );
                        return;
                      }

                      setShowOverlay(true);
                      await setDataRecalculo(null);

                      await simularProposta(
                        null,
                        true,
                        values?.operacao?.vencimento,
                        renda,
                        null,
                      ).catch(() => setShowOverlay(false));

                      setShowOverlay(false);
                    }}
                    disabled={
                      (acesso ? !editableField('operacao.valorContratado') : true)
                      || disabledFields
                    }
                    onValueChange={(values) => {
                      setFieldValue(
                        'operacao.floatValorContratado',
                        values.floatValue,
                      );
                      setCalculado(true);
                    }}
                    allowNegative={false}
                  />
                )}
              </Field>
            )}

            {tabelaSelecionada?.tipo === 1 && <SelectValorContratado />}
          </Form.Item>

          {values?.operacao?.tipoCalculo === 1 ? (
            (values?.operacao?.limitarComprometimento ||
              values?.operacao?.produtoId === REFIN_ID) &&
              isNumber(maiorValorLimiteParcela) &&
              objetoLimitesParcela?.length > 0 ? (
              <p className="info-limite-parcela">
                * Limites Parcelas:
                <br />
                {objetoLimitesParcela[0]?.limiteParcela > 0 ? (
                  <>
                    Até {objetoLimitesParcela[0]?.rangeFinal}X{' '}
                    {currencyFormat(objetoLimitesParcela[0]?.limiteParcela)}{' '}
                  </>
                ) : (
                  <>R$ 0,00</>
                )}
                <br />
                {objetoLimitesParcela[1]?.limiteParcela > 0 && (
                  <>
                    De {objetoLimitesParcela[1]?.rangeInicial}X a{' '}
                    {objetoLimitesParcela[1]?.rangeFinal}X{' '}
                    {currencyFormat(objetoLimitesParcela[1]?.limiteParcela)}
                  </>
                )}
                <br />
              </p>
            ) : (
              ''
            )
          ) : (
            (values?.operacao?.limitarComprometimento ||
              values?.operacao?.produtoId === REFIN_ID) &&
            maiorValorLimite !== null && (
              <p className="info-limite-parcela">
                * Limite Valor {currencyFormat(maiorValorLimite)}
              </p>
            )
          )}
        </div>

        <div
          className={
            calculoVencimento
              ? values?.operacao?.produtoId === 1 || 10
                ? `col-md-4 col-lg-4 col-xl-3`
                : `col-md-3 col-lg-3 col-xl-2`
              : values?.operacao?.tipoCalculoVencimento ===
                TipoCalculoVencimento.DEBITO_EM_CONTA &&
                tabelaJuros?.length > 1
                ? `col-md-3 col-lg-3 col-xl-2`
                : `col-md-4 col-lg-4 col-xl-3`
          }
        >
          <Form.Item
            name="operacao.prazo"
            label={`Prazo / ${values?.operacao?.tipoCalculo === 0
              ? `Prestação`
              : proposta?.operacao?.produtoId === REFIN_ID
                ? `Liberado`
                : `Solicitado`
              }`}
            validate={(value) =>
              validationField(
                value,
                'operacao.prazo',
                `Prazo / ${values?.operacao?.tipoCalculo === 0
                  ? `Prestação`
                  : proposta?.operacao?.produtoId === REFIN_ID
                    ? `Liberado`
                    : `Solicitado`
                }`,
              )
            }
            hasFeedback
          >
            <SelectPrazoPrestacao />
          </Form.Item>
        </div>

        {proposta?.operacao?.produtoId === REFIN_ID && (
          <>
            <div className="col-md-4 col-lg-4 col-xl-2">
              <Form.Item
                name="operacao.valorSolicitadoRefin"
                label="Valor Solicitado"
              >
                <Field name="operacao.valorSolicitadoRefin">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      maxLength={12}
                      style={{ textAlign: 'right' }}
                      disabled={true}
                      value={
                        parseFloat(values?.operacao?.valorLiberado) +
                        parseFloat(valorSaldoDevedorRefin)
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>

            <div className="col-md-4 col-lg-4 col-xl-2">
              <Form.Item
                name="operacao.valorSaldoDevedorRefin"
                label="Valor Saldo Devedor"
              >
                <Field name="operacao.valorSaldoDevedorRefin">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      maxLength={12}
                      style={{ textAlign: 'right' }}
                      disabled={true}
                      value={valorSaldoDevedorRefin}
                    />
                  )}
                </Field>
              </Form.Item>
              {proposta?.validaContratoRefin?.status === 3 && (
                <p className="info-limite-parcela">Saldo devedor atualizado</p>
              )}
            </div>
          </>
        )}

        <div
          className={
            ['Boleto', 'Débito em Conta'].includes(
              values?.operacao?.produtoNome,
            )
              ? `col-md-3 col-lg-3 col-xl-3`
              : `col-md-3 col-lg-3 col-xl-2`
          }
        >
          <Form.Item
            name="operacao.valorLiberado"
            label="Valor Liberado"
            validate={(value) =>
              validationField(value, 'operacao.valorLiberado', 'Valor Liberado')
            }
            hasFeedback
          >
            <Field name="operacao.valorLiberado">
              {({ field }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  disabled={
                    !editableField('operacao.valorLiberado')
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>

        {proposta && (
          <RendaInput
            produtoEnergia={true}
            acesso={
              (acesso
                ? !editableField('operacao.renda')
                  ? true
                  : ocultarRendaEstimada
                : true)
              || disabledFields
            }
            onBlur={blurRenda}
            setInitialInputValue={setInitialInputValue}
            initialInputValue={initialInputValue}
            labelSelectRenda={labelSelectRenda}
            values={values}
            validationField={validationField}
            renda={renda}
            setRenda={setRenda}
            tabelaJuros={tabelaJuros}
          />
        )}

        {proposta?.operacao?.tipoCalculoVencimento === 5 && (
          <div className="col-md-3 col-lg-3 col-xl-3">
            <Form.Item
              name="operacao.valorDebitoConcorrente"
              label={
                <>
                  <b className="renda-text">
                    Débitos Concorrentes
                    <TooltipInfo text="Serão considerados como débitos concorrentes todos os débitos automáticos no dia do pagamento." />
                  </b>
                </>
              }
            >
              <InputMoney
                name="operacao.valorDebitoConcorrente"
                className="ant-input input-debito-concorrente"
                prefix="R$ "
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                maxLength={12}
                defaultValue={proposta?.operacao?.valorDebitoConcorrente}
                disabled={
                  (acesso
                    ? !editableField('operacao.valorDebitoConcorrente')
                    : true)
                  || disabledFields
                }
                onFocus={async (e) => {
                  setInitialInputValue(e.target.value);
                }}
                onValueChange={(value) =>
                  setFieldValue(
                    'operacao.valorDebitoConcorrente',
                    value.floatValue,
                  )
                }
                onBlur={async (e) => {
                  if (initialInputValue !== e.target.value) {
                    setShowOverlay(true);
                    await setDataRecalculo(null);

                    await calcularValoresLimitesPrestacao(
                      values?.operacao?.produtoId,
                      values?.id,
                      values?.operacao?.convenioId,
                      values?.operacao?.tabelaJurosId,
                      values?.operacao?.vencimento,
                      proposta?.operacao?.valorContingencia,
                      renda,
                      null,
                    )
                      .then((value) => {
                        objetoLimitesParcela = [];
                        objetoLimitesProduto = value;
                      })
                      .catch(() => setShowOverlay(false));

                    await calcularLimitesParcelas(
                      renda,
                      values?.operacao?.produtoId,
                      values?.id,
                      values?.operacao?.tabelaJurosId,
                      values?.operacao?.vencimento,
                      objetoLimitesProduto,
                      proposta?.refin?.valorPrestacaoRefin,
                      null,
                      proposta?.operacao?.valorRendaComprometida,
                      values?.operacao.valorDebitoConcorrente,
                      abateSomenteLimiteParcela
                    )
                      .then((response) => {
                        const tempTabelaJuros = tabelaJuros?.find(
                          (el) => el.id === values?.operacao?.tabelaJurosId,
                        );

                        objetoLimitesParcela = response.objetoLimitesParcela;

                        if (
                          objetoLimitesParcela[1]?.limiteParcela === 0 ||
                          objetoLimitesParcela[1] === undefined
                        ) {
                          // se só possui um bloco de Range com limite de parcela retornado pelo Crivo
                          // retorna o menor entre o range final do Crivo e o maior plano possivel da tabela de juros selecionada
                          objetoLimitesParcela[0].rangeFinal = Math.min(
                            objetoLimitesParcela[0].rangeFinal,
                            tempTabelaJuros.planoMaximo,
                          );
                        }

                        if (response.maiorValorLimiteParcela >= 0) {
                          setMaiorValorLimiteParcela(
                            response.maiorValorLimiteParcela,
                          );
                        }
                        if (objetoLimitesParcela[0]?.minimoParcelaCrivo) {
                          setMenorValorLimiteParcela(
                            objetoLimitesParcela[0].minimoParcelaCrivo,
                          );
                        }
                      })
                      .catch(() => setShowOverlay(false));

                    await calcularValorLimite(
                      values?.operacao?.produtoId,
                      values?.id,
                      values?.operacao?.convenioId,
                      values?.operacao?.tabelaJurosId,
                      values?.operacao?.vencimento,
                      proposta?.operacao?.valorContingencia,
                      renda,
                      objetoLimitesParcela,
                      objetoLimitesProduto,
                      valorSaldoDevedorRefin,
                      null,
                    )
                      .then((value) => setMaiorValorLimite(value))
                      .catch(() => setShowOverlay(false));

                    if (
                      objetoLimitesParcela[0]?.minimoParcelaCrivo &&
                      objetoLimitesParcela[0]?.maximoParcelaCrivo
                    ) {
                      await calcularValorLimite(
                        values?.operacao?.produtoId,
                        values?.id,
                        values?.operacao?.convenioId,
                        values?.operacao?.tabelaJurosId,
                        values?.operacao?.vencimento,
                        proposta?.operacao?.valorContingencia,
                        renda,
                        objetoLimitesParcela,
                        objetoLimitesProduto,
                        valorSaldoDevedorRefin,
                        null,
                        true,
                      )
                        .then((value) => setMenorValorLimite(value))
                        .catch(() => setShowOverlay(false));

                      aplicarPercentualParcela();
                    }

                    await simularProposta(
                      null,
                      false,
                      values?.operacao?.vencimento,
                      renda,
                      null,
                    ).catch(() => setShowOverlay(false));
                    setCalculado(true);
                    setVencimentoInvalido(false);
                    limpaCamposValores();
                    setShowOverlay(false);
                  }
                  setInitialInputValue(null);
                }}
              />
            </Form.Item>
          </div>
        )}

        {values?.operacao?.descricaoTecnicaConvenioTipo === 'cia_eletrica' &&
          acesso && (
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="dados-fatura-energia">
                <h4>Dados da Fatura de Energia</h4>
                <div className="box">
                  <div className="form-row">
                    {values?.operacao?.convenioNome && (
                      <div className="col-md-2 col-lg-3 col-xl-2">
                        <Form.Item
                          name="operacao.convenioNome"
                          label="CIA Elétrica"
                          validate={(value) =>
                            validationField(
                              value,
                              'operacao.convenioNome',
                              'Convênio',
                            )
                          }
                        >
                          <Input
                            name="operacao.convenioNome"
                            value={values.operacao.convenioNome}
                            disabled={
                              !editableField('operacao.convenioNome')
                              || disabledFields
                            }
                          />
                        </Form.Item>
                      </div>
                    )}

                    {values?.operacao?.dadosAdicionais &&
                      values?.operacao?.dadosAdicionais?.map(
                        (dadoAdicional, i) => {
                          if (
                            dadoAdicional?.valor !== null &&
                            dadoAdicional?.valor !== ''
                          ) {
                            return (
                              <div
                                className="col-md-2 col-lg-3 col-xl-3"
                                key={i}
                              >
                                <Form.Item
                                  name={`operacao.dadosAdicionais.${i}.valor`}
                                  label={dadoAdicional?.nome}
                                  hasFeedback
                                >
                                  {dadoAdicional?.tipo === 1 ? (
                                    <Field
                                      name={`operacao.dadosAdicionais.${i}.valor`}
                                      validate={(value) => {
                                        if (
                                          typeof value == 'string' &&
                                          !RegExp(dadoAdicional?.formato).test(
                                            value,
                                          )
                                        ) {
                                          return dadoAdicional?.mensagem;
                                        }
                                      }}
                                    >
                                      {({ field }) => (
                                        <Input
                                          {...field}
                                          defaultValue={dadoAdicional?.valor}
                                          className="ant-input"
                                          type="number"
                                          disabled={
                                            !editableField(
                                              `operacao.dadosAdicionais.${i}.valor`,
                                            ) || disabledFields
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `operacao.dadosAdicionais.${i}.valor`,
                                              e.target.value,
                                            );
                                          }}
                                          onBlur={(e) => {
                                            if (
                                              !RegExp(
                                                dadoAdicional?.formato,
                                              ).test(e.target.value)
                                            ) {
                                              setFieldValue(
                                                `operacao.dadosAdicionais.${i}.valor`,
                                                '',
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </Field>
                                  ) : dadoAdicional?.tipo !== 4 ? (
                                    <Field
                                      name={`operacao.dadosAdicionais.${i}.valor`}
                                      validate={(value) => {
                                        if (
                                          !RegExp(dadoAdicional?.formato).test(
                                            value,
                                          )
                                        ) {
                                          return dadoAdicional?.mensagem;
                                        }
                                      }}
                                    >
                                      {({ field }) => (
                                        <Input
                                          {...field}
                                          defaultValue={dadoAdicional?.valor}
                                          className="ant-input"
                                          disabled={
                                            !editableField(
                                              `operacao.dadosAdicionais.${i}.valor`,
                                            ) || disabledFields
                                          }
                                        />
                                      )}
                                    </Field>
                                  ) : (
                                    <DatePicker
                                      name={`operacao.dadosAdicionais.${i}.valor`}
                                      format="DD/MM/YYYY"
                                      placeholder="00/00/0000"
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                      dropdownAlign={{
                                        overflow: { adjustY: 0 },
                                      }}
                                      disabled={
                                        !editableField(
                                          `operacao.dadosAdicionais.${i}.valor`,
                                        ) || disabledFields
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {values?.operacao?.descricaoTecnicaConvenioTipo ===
          'consignado_privado' &&
          acesso && (
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="dados-fatura-energia">
                <h4>Dados Consignado Privado</h4>
                <div className="box">
                  <div className="form-row">
                    {values?.operacao?.convenioNome && (
                      <div className="col-md-2 col-lg-12 col-xl-12">
                        <Form.Item
                          name="operacao.convenioNome"
                          label="Conveniada"
                          validate={(value) =>
                            validationField(
                              value,
                              'operacao.convenioNome',
                              'Convênio',
                            )
                          }
                        >
                          <Input
                            name="operacao.convenioNome"
                            value={values.operacao.convenioNome}
                            disabled={
                              !editableField('operacao.convenioNome')
                              || disabledFields
                            }
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        {values?.operacao?.produtoNome === 'CP Auto' && acesso && (
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div className="dados-fatura-energia cp-auto">
              <h4>Dados Garantia</h4>
              <div className="box">
                <div className="form-row">
                  <div>
                    <Form.Item
                      name="operacao.placaVeiculo"
                      label="Placa"
                      validate={(value) =>
                        validationField(
                          value,
                          'operacao.placaVeiculo',
                          'Convênio',
                        )
                      }
                    >
                      <Input
                        name="operacao.placaVeiculo"
                        value={values.operacao.placaVeiculo}
                        disabled={
                          !editableField('operacao.placaVeiculo')
                          || disabledFields
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="operacao.marca"
                      label="Marca"
                      validate={(value) =>
                        validationField(value, 'operacao.marca', 'Convênio')
                      }
                    >
                      <Input
                        name="operacao.marca"
                        value={values.operacao.marca}
                        disabled={
                          !editableField('operacao.marca')
                          || disabledFields
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="operacao.modelo"
                      label="Modelo"
                      validate={(value) =>
                        validationField(value, 'operacao.modelo', 'Convênio')
                      }
                    >
                      <Input
                        name="operacao.modelo"
                        value={values.operacao.modelo}
                        disabled={
                          !editableField('operacao.modelo')
                          || disabledFields
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="operacao.anoModelo"
                      label="Ano Modelo"
                      validate={(value) =>
                        validationField(value, 'operacao.anoModelo', 'Convênio')
                      }
                    >
                      <Input
                        name="operacao.anoModelo"
                        value={values.operacao.anoModelo}
                        disabled={
                          !editableField('operacao.anoModelo')
                          || disabledFields
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export { DadosOperacaoForm };
