import React from 'react';
import IconEdit from '../../../components/EditIcon';

export const columnsImpressao = [
  {
    Header: 'Documentos',
    accessor: 'documentos',
    Cell: ({ value }) => {
      return <div className="td-align-left">{value}</div>;
    },
  },
  {
    Header: 'Etapas',
    accessor: 'etapas',
    Cell: ({ value }) => {
      return <div className="td-align-left">{value}</div>;
    },
  },
  {
    Header: '',
    accessor: 'edit',
    disableSortBy: true,
    Cell: ({ row }) => {
      return <IconEdit className="btn-edit-table-documentos" />;
    },
  },
];
