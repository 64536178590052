import api from './api';

export const listarEventos = async () => {
  try {
    const { data } = await api.get('/Evento');
    return data.data;
  } catch (error) {
    return error;
  }
};
