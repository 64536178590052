import { Modal, Button } from 'antd';
import { ReactComponent as IconExclamationCircle } from '../../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import './styles.scss';

export default function ModalConfirmaInativacao({
  onConfirm = () => {},
  onCancel = () => {},
  show,
  setShow = () => {},
}) {
  return (
    <Modal
      visible={show}
      centered
      footer={null}
      width={452}
      maskClosable={false}
      className="modal-warning modal-alerta-inativacao"
      onCancel={() => {
        onCancel();
        setShow(false);
      }}
      //closeIcon={<IconClose />}
    >
      <div className="icon">
        <IconExclamationCircle width={75} />
      </div>
      <div className="text-modal" style={{ marginTop: 35, marginBottom: 35 }}>
        Existe(m) subunidade(s) associadas à unidade. Se confirmar a inativação
        dela, a(s) subunidade(s) também serão inativadas. Confirma a inativação
        da unidade?
      </div>
      <div className="btn-area">
        <Button
          variant="gray"
          onClick={() => {
            onCancel();
            setShow(false);
          }}
          className="btn-cancel"
        >
          NÃO
        </Button>
        <Button
          variant="blue"
          onClick={() => {
            onConfirm();
            setShow(false);
          }}
          className="btn-confirm"
        >
          SIM
        </Button>
      </div>
    </Modal>
  );
}
