import React from 'react';
import { Link } from 'react-router-dom';


function Navbar() {


  return (
    <nav
      className="navbar navbar-static-top gray-bg"
      role="navigation"
      style={{ marginBottom: 0 }}
    >
      <div className="navbar-header">
        <button
          type="button"
          className="btn btn-blue btn-toggle"
          id="navbar-toggle"
        >
          <i className="fa fa-bars"></i>
        </button>
      </div>

      <ul className="nav navbar-top-links navbar-right">
        <li>
          <Link to="/logout">
            <button
              type="button"
              style={{
                border: 'none',
                marginRight: '10px',
                backgroundColor: 'transparent',
                fontSize: '16px',
              }}
            >
              <i className="fa fa-sign-out" style={{ marginRight: '5px' }}></i>
              Sair
            </button>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
