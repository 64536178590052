import UploadItem from '../UploadItems';

import { Select } from 'antd';
import { ReactComponent as IconArrowDown } from '../../assets/images/icons/icon-arrow-down.svg';
import { useEffect, useState } from 'react';

const { Option } = Select;

const CustomUploadRender = ({
  file,
  arquivos,
  tipoAnexos,
  handleType,
  handlePreview,
  handleRemove,
  values,
  indiceArquivo,
  acesso,
  isLuzEmDia,
  editableField,
  listaTipoAnexo,
  hideDocsPreview,
}) => {
  const [tiposAnexosFiltrados, setTiposAnexosFiltrados] = useState([]);

  const filterTiposPorNomeDocumento = (tipos, key, quantidadeMaxima = 1) => {
    let tiposFiltrado = tipos;
    const idDocumento = tipoAnexos?.
      find(tipo => tipo.nome === key)?.id;

    if (idDocumento) {
      const existeDocumentoEnviado =
        arquivos.find(a => (a.enviado && a.documentoId === idDocumento && !a.id));

      const quantidadeDocumento = arquivos.filter((a) => a.documentoId === idDocumento && !a.id).length;

      if (quantidadeDocumento >= quantidadeMaxima || existeDocumentoEnviado) {
        tiposFiltrado = tipos.filter(tipo => tipo.id !== idDocumento)
      }
    }

    return tiposFiltrado;
  }

  const filterTipoDocumento = (id) => {

    let tipos = tipoAnexos?.filter((arquivo) => {
      if (!arquivo.exibirCorban) return null;

      const isTipoModalidadeMatch =
        arquivo.tipoModalidade === values?.operacao?.tipoModalidade ||
        arquivo.tipoModalidade === 0;
      const isTipoRendaMatch =
        arquivo.tipoRenda === values?.operacao?.tipoRenda ||
        arquivo.tipoRenda == null;
      const isLuzEmDiaMatch =
        (isLuzEmDia && arquivo.luzEmDia) || (!isLuzEmDia && !arquivo.luzEmDia);

      if (isTipoModalidadeMatch && isTipoRendaMatch && isLuzEmDiaMatch) {
        if (!listaTipoAnexo.includes(arquivo.id)) {
          listaTipoAnexo.push(arquivo.id);
        }
        return arquivo;
      }

      return null;
    });

    tipos = filterTiposPorNomeDocumento(tipos, "DOCUMENTO DE IDENTIFICAÇÃO", 2)
    tipos = filterTiposPorNomeDocumento(tipos, "FATURA DE ENERGIA")

    const existeTipoDocumentoSelecionado = tipos.find(
      (arquivo) => arquivo.id === id,
    );

    if (!existeTipoDocumentoSelecionado && id) {
      tipos.push(tipoAnexos?.find((arquivo) => arquivo.id === id));
    }

    setTiposAnexosFiltrados(tipos);
  };

  useEffect(() => {
    filterTipoDocumento(file.documentoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, arquivos]);

  return (
    <>
      <div className="icone-arquivo">
        {UploadItem(
          file,
          handlePreview,
          handleRemove,
          undefined,
          hideDocsPreview
        )}
      </div>

      <p className="data-upload-item">
        {arquivos[indiceArquivo(file.imagemId)]
          ? arquivos[indiceArquivo(file.imagemId)].dataHora
          : 'Carregando...'}
      </p>
      {!file.enviado ?
        <Select
          getPopupContainer={(trigger) => trigger.parentNode}
          id="tipoArquivo"
          name="tipoArquivo"
          value={file.documentoId}
          suffixIcon={<IconArrowDown />}
          placeholder="Tipo de Arquivo"
          dropdownClassName="dropdown-select-upload-proposta-credito"
          disabled={acesso ? !editableField('anexo.nomeDocumento') : true}
          onChange={(e) => {
            handleType(e, file);
            filterTipoDocumento(e);
          }}
          onClick={() => {
            filterTipoDocumento(file.documentoId);
          }}
        >
          {tiposAnexosFiltrados?.map((arquivo) => (
            <Option key={arquivo.id} value={arquivo.id}>
              {arquivo.nome}
            </Option>
          ))}
        </Select>
        :
        <div className="titulo-arquivo">
          <h5>
            {tipoAnexos?.find(
              (tipo) => tipo.id === file.documentoId,
            )?.nome || 'OUTROS'}
          </h5>
        </div>
      }
    </>
  );
};

export default CustomUploadRender;
