import React, { useContext } from 'react';

import ConversationMessages from '../../../components/ConversationMessages';
import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
function CentralDeComunicacao({
  mode,
  placementTooltip,
  fixedTooltip,
  children,
  disabled = false,
}) {
  const { mensagens, sendMensagem, proposta } = useContext(
    PropostaCreditoContext,
  );

  return mensagens ? (
    <ConversationMessages
      mode={mode}
      proposta={proposta}
      listMessages={mensagens}
      sendMessage={sendMensagem}
      children={children}
      placementTooltip={placementTooltip}
      type="chat"
      disabled={
        proposta?.situacaoId < 0 ||
        proposta?.situacaoId === 1 ||
        proposta?.situacaoId === 2 ||
        proposta?.situacaoId === 16 ||
        disabled
          ? true
          : false
      }
      origem={1}
      fixedTooltip={fixedTooltip}
    />
  ) : (
    ''
  );
}

export default CentralDeComunicacao;
