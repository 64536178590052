import React, { createContext, useState, useEffect, useCallback } from 'react';

import {
  getContextoAcompanhamentoCredenciamento,
  filtroListaAcompanhamentoCredenciamento,
} from '../services/correspondenteService';

export const AcompanhamentoCredenciamentoContext = createContext({});

const initialFilters = {
  estagioId: null,
  tipo: null,
  tipoData: null,
  dataInicial: null,
  dataFinal: null,
  gerenteId: null,
  coordenadorId: null,
  supervisorId: null,
  filtroDinamico: null,
};

function AcompanhamentoCredenciamentoProvider({ children }) {
  const [filterShow, setFilterShow] = useState(false);
  const [contextoCredenciamento, setContextoCredenciamento] = useState({});
  const [correspondenteList, setCorrespondenteList] = useState([]);
  const [cards, setCards] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    async function loadContexto() {
      const data = await getContextoAcompanhamentoCredenciamento();

      if (data) {
        setContextoCredenciamento(data);
      }
    }
    loadContexto();
  }, []);

  const fetchCredenciamento = useCallback(async (props) => {
    setLoading(true);
    const page = props?.pageIndex + 1;

    const data = await filtroListaAcompanhamentoCredenciamento({
      pagina: page || 1,
      ordenacao:
        props?.sortBy && props?.sortBy.length > 0 ? props?.sortBy[0].id : null,
      ordenacaoAsc:
        props?.sortBy && props?.sortBy.length > 0
          ? !props?.sortBy[0].desc
          : null,
      quantidadePorPagina: 10,
      estagioId: props?.filter.estagioId,
      tipo: props?.filter.tipo,
      tipoData: props?.filter.tipoData,
      dataInicial: props?.filter.dataInicial,
      dataFinal: props?.filter.dataFinal,
      gerenteId: props?.filter.gerenteId,
      coordenadorId: props?.filter.coordenadorId,
      supervisorId: props?.filter.supervisorId,
      filtroDinamico: props?.filter.filtroDinamico,
    });
    if (data.itens) {
      setCorrespondenteList(data.itens);
      setCards(data.cards);
      setTotal(data.total);
      setPageCount(data.totalPages);
    }

    setLoading(false);
  }, []);

  return (
    <AcompanhamentoCredenciamentoContext.Provider
      value={{
        filterShow,
        setFilterShow,
        contextoCredenciamento,
        fetchCredenciamento,
        correspondenteList,
        total,
        pageCount,
        loading,
        filters,
        setFilters,
        cards,
        setPageCount,
      }}
    >
      {children}
    </AcompanhamentoCredenciamentoContext.Provider>
  );
}

export default AcompanhamentoCredenciamentoProvider;
