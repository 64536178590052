import React from 'react';

import Button from '../../../components/Button';
import Title from '../../../components/Title';

import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';

function Toolbar() {
  return (
    <div className="row">
      <div className="col-lg-9">
        <Title label="Lista de Dados de Correspondentes Cadastrados" />
      </div>

      <div className="col-lg-3">
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            variant="gray"
            style={{ border: '1px solid #CFD2D4' }}
          >
            <FilterIcon style={{ marginRight: '10px' }} />
            <span>Filtrar</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
