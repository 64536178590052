import React, { useState, useContext } from 'react';

import { BorderoContext } from '../../../contexts/BorderoContext';

import Toolbar from './Toolbar';
import BorderoDetails from '../BorderoDetails';

import ModalBordero from '../../../components/ModalBordero';

import Table from '../../../components/BasicTable';

import { columns } from './columns';

function BorderoList() {
  const [Visible, setVisible] = useState(false);

  const {
    fetchBorderos,
    paginacaoBorderos,
    data,
    setBorderoSelecionado,
    filters,
  } = useContext(BorderoContext);

  const { total, loading, pageCount, currentPage } = paginacaoBorderos;

  return (
    <>
      <Table
        onLoadData={fetchBorderos}
        data={data}
        columns={columns(setVisible, setBorderoSelecionado)}
        total={total}
        loading={loading}
        pageCount={pageCount}
        currentPage={currentPage}
        filter={filters}
        header={<Toolbar />}
      />

      {Visible && (
        <ModalBordero
          onClose={() => setVisible(false)}
          content={<BorderoDetails />}
        />
      )}
    </>
  );
}

export default BorderoList;
