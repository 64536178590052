import React from 'react';
import WidgetRelatorioAnalitico from '../../../components/WidgetRelatorioAnalitico';

function Cards({ getCard, loading }) {
  return (
    <div
      className="row pb-4"
      style={{
        gridGap: '25px',
        paddingInline: '15px',
      }}
    >
      <div className="col card-proposta-visao-analitica">
        <WidgetRelatorioAnalitico
          title="Proposta"
          total={getCard('Urgência')?.total}
          direcionadas={getCard('Urgência')?.direcionadas}
          color={getCard('Urgência')?.cor}
          porcentagem={getCard('Urgência')?.porcentagemDirecionadas}
          estagio="Urgência"
          loading={loading}
        />
      </div>
      <div className="col card-proposta-visao-analitica ">
        <WidgetRelatorioAnalitico
          title="Proposta"
          total={getCard('Atenção')?.total}
          direcionadas={getCard('Atenção')?.direcionadas}
          color={getCard('Atenção')?.cor}
          porcentagem={getCard('Atenção')?.porcentagemDirecionadas}
          estagio="Atenção"
          loading={loading}
        />
      </div>
      <div className="col card-proposta-visao-analitica ">
        <WidgetRelatorioAnalitico
          title="Proposta"
          total={getCard('Regular')?.total}
          direcionadas={getCard('Regular')?.direcionadas}
          color={getCard('Regular')?.cor}
          porcentagem={getCard('Regular')?.porcentagemDirecionadas}
          estagio="Regular"
          loading={loading}
        />
      </div>
    </div>
  );
}

export default Cards;
