import moment from 'moment';
import { Checkbox } from 'antd';
import { currencyFormat } from '../../../utils/numberFormatter';

const columnsRefin = (contratoSelecionado, handleContratos) => [
  {
    Header: 'Contrato',
    accessor: 'contrato',
    style: {
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Produto',
    accessor: 'produto',
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Saldo Devedor',
    accessor: 'saldoDevedor',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{`${currencyFormat(value)}`}</div>
    ),
  },
  {
    Header: 'Próximo Vencimento',
    accessor: 'proximoVencimento',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : ''}
      </div>
    ),
  },
  {
    Header: 'Vencimento Última Parcela',
    accessor: 'vencimentoUltimaParcela',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : ''}
      </div>
    ),
  },
  {
    Header: 'Parcelas em Aberto',
    accessor: 'parcelasRestantes',
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Valor Parcela',
    accessor: 'valorParcela',
    Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{`${currencyFormat(value)}`}</div>,
  },
  {
    Header: '',
    accessor: 'check',
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <Checkbox
          // checked={contratos?.find(
          //   (contrato) => contrato.contrato === row.original.contrato,
          // )}
          checked={contratoSelecionado && contratoSelecionado.contrato === row.original.contrato}
          onChange={() => handleContratos(row.original)}
        />
      );
    },
  },
];

export default columnsRefin;
