import React, { useContext } from 'react';
import Layout from '../../layouts/Main';
import { Breadcrumb } from 'antd';
import PageHeading from '../../components/PageHeading';
import useInspinia from '../../hooks/useInspinia';
import Card from '../../components/Card';
import Content from '../../components/Content';
import Title from '../../components/Title';
import './styles.scss';
import HierarquiasProvider from '../../contexts/HierarquiasContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import RightBarHierarquias from './RightBarHierarquias';
import CollapsesHierarquias from './CollapsesHierarquias';

const Hierarquias = () => {
  const { checarPermissao } = useContext(ControleAcessoContext);

  useInspinia();

  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Hierarquia</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      {checarPermissao('tela', 'tela.cadastro.hierarquia', 'Visualizar') && (
        <Content>
          <HierarquiasProvider>
            <div className="card-hierarquias">
              <Card
                title={<Title label="Hierarquias Cadastradas" />}
                rightBar={<RightBarHierarquias />}
              >
                <CollapsesHierarquias />
              </Card>
            </div>
          </HierarquiasProvider>
        </Content>
      )}
    </Layout>
  );
};

export default Hierarquias;
