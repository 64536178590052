import React, { useContext } from 'react';
import { Form, Input, Select } from 'formik-antd';
import { FormikProvider, Field } from 'formik';

import { RelatorioAnaliticoContext } from '../../../../contexts/RelatorioAnaliticoContext';

import Button from '../../../../components/Button';
import Icon from '../../../../components/Icon';
import { ReactComponent as IconArrowSelect } from '../../../../assets/images/icons/arrow-down-select.svg';
import { cpfMask } from '../../../../utils/masks';
import InputMask from 'react-text-mask';

import './styles.scss';

const { Option } = Select;

function FilterAnalitico() {
  const {
    contexto,
    setSearch,
    initialFilters,
    formik,
  } = useContext(
    RelatorioAnaliticoContext,
  );

  const { values, setFieldValue, resetForm } = formik;

  const handleEnvioFormulario = () => {
    setSearch({
      pagina: 1,
      filtroCpf: values.filtroCpf,
      filtroSituacaoId: values.filtroSituacaoId,
      filtroAnalista: values.filtroAnalista,
      filtroSupervisorId: values.filtroSupervisorId,
      produtoGrupoId: values.produtoGrupoId,
      searchButton: true,
    });
  };

  return (
    <div className="filter-container filter-acompanhamento-proposta">
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="filter-wrapper">
            <div className="filter-form">
              <div className="form-row">
                <div className="col">
                  <Form.Item name="cpf" className="filter-item-container">
                    <label className="filters-label">CPF</label>
                    <Field name="cpf">
                      {({ field }) => (
                        <InputMask
                          placeholder="000.000.000-00"
                          name="filtroCpf"
                          className="ant-input"
                          mask={cpfMask}
                          {...field}
                          value={values.filtroCpf}
                          onChange={(val) => {
                            const noMaskCpf = val.target.value.replace(
                              /\D/g,
                              '',
                            );
                            setFieldValue('filtroCpf', noMaskCpf);

                            if (noMaskCpf.length === 11) {
                              setFieldValue('rangeData', undefined);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item
                    name="filtroSituacaoId"
                    label="Status"
                    className="filter-item-container"
                  >
                    <Select
                      name="filtroSituacaoId"
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      allowClear
                      showSearch
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, ''),
                          );
                      }}
                    >
                      {contexto?.etapas?.map((situacao) => (
                        <Option key={situacao.id} value={situacao.id}>
                          {situacao.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <Form.Item name="filtroAnalista" label="Analista">
                    <Input
                      className="filters-label"
                      mode="multiple"
                      allowClear
                      name="filtroAnalista"
                      placeholder="Digite aqui"
                      showSearch
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .includes(
                            input
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, ''),
                          );
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col">
                  <Form.Item
                    name="filtroSupervisorId"
                    className="filter-item-container"
                    style={{ textAlign: 'start' }}
                  >
                    <label className="filters-label">Supervisor</label>
                    <Select
                      name="filtroSupervisorId"
                      allowClear
                      showSearch
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {contexto?.supervisores?.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="filter-actions">
              <Button
                type="button"
                variant="blue"
                block
                async={true}
                onClick={() => {
                  handleEnvioFormulario();
                }}
              >
                <Icon variant="search" />
                <span className="ml-2">Pesquisar</span>
              </Button>

              <Button
                className="clear-button"
                type="button"
                variant="gray"
                block
                async={true}
                onClick={() => {
                  if (values !== initialFilters) {
                    resetForm();
                    setSearch(null);
                    setFieldValue('filtroCpf', '');
                    setFieldValue('filtroSituacaoId', null);
                    setFieldValue('filtroAnalista', '');
                    setFieldValue('filtroSupervisorId', null);
                    setFieldValue('produtoGrupoId', null);
                  }
                }}
              >
                <Icon variant="clean" />
                <span className="ml-2">Limpar</span>
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}

export default FilterAnalitico;
