import React, { useContext } from 'react';

import { Tooltip, Button } from 'antd';

import moment from 'moment';
import { genericPhoneFormatter } from '../../../utils/textFormatter';

import CompListBlocklist from '../../../components/ListBlocklist';

import { BlocklistTelefoneContext } from '../../../contexts/BlocklistTelefoneContext';

import { ReactComponent as IconObservacoes } from '../../../assets/images/icons/infocard.svg';
import { ReactComponent as IconEditar } from '../../../assets/images/icons/icon-editar.svg';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function ListBlocklist() {
  const {
    listagemTelefones,
    totalItensLista,
    pageCountLista,
    loadingLista,
    filter,
    setFilter,
    fetchListagemTelefones,
    setValueForTelefoneBlocklist,
  } = useContext(BlocklistTelefoneContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const handleEdit = (data) => {
    setValueForTelefoneBlocklist(data);
  };

  const columnsBlocklistTelefone = [
    {
      Header: 'Tipo',
      accessor: 'tipo',
      Cell: ({ value }) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
    },
    {
      Header: 'Telefone',
      accessor: 'telefone',
      Cell: ({ value }) => (value ? genericPhoneFormatter(value) : ''),
    },
    {
      Header: 'Inclusão',
      accessor: 'inclusao',
      Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      Header: 'Exclusão',
      accessor: 'exclusao',
      Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      Header: 'Motivo',
      accessor: 'motivo',
    },
  ];

  if (
    checarPermissao('botao', 'botao.blocklistTelefone.tooltip', 'Visualizar')
  ) {
    columnsBlocklistTelefone.push({
      Header: '',
      accessor: 'observacao',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Tooltip
          arrowPointAtCenter
          placement="bottomRight"
          overlayClassName="tooltip-list-blocklist"
          title={
            <p>
              <b>Observação:</b>
              <br />
              {row.original.exclusao
                ? row.original.descricaoExclusao
                : row.original.descricaoInclusao}
            </p>
          }
        >
          <span className="icon-observacoes">
            <IconObservacoes />
          </span>
        </Tooltip>
      ),
    });
  }

  if (
    checarPermissao('botao', 'botao.blocklistTelefone.edicao', 'Visualizar')
  ) {
    columnsBlocklistTelefone.push({
      Header: '',
      accessor: 'editar',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Button
          onClick={() => handleEdit(row.original)}
          className="btn-icon-editar"
        >
          <IconEditar />
        </Button>
      ),
    });
  }

  return (
    <CompListBlocklist
      title="Blocklist Telefone"
      columns={columnsBlocklistTelefone}
      data={listagemTelefones}
      onLoadData={fetchListagemTelefones}
      filter={filter}
      setFilter={setFilter}
      total={totalItensLista}
      pageCount={pageCountLista}
      loading={loadingLista}
      tipoBlocklist="telefone"
    />
  );
}

export default ListBlocklist;
