import api from './api';

export const listarPropostas = async ({
  pagina = 1,
  ordenacao = { id: 'tempoEspera', desc: false },
  filtroDinamico = '',
}) => {
  const { data } = await api.post('/proposta/fila-credito', {
    pagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    filtroDinamico,
  });
  return data;
};

export const redistribuirPropostas = async (propostas) => {
  const { data } = await api.post('/Proposta/Redistribuicao', propostas);
  return data;
};
