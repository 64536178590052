import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import logoQuadrado from '../../assets/images/crefazon-logo-quadrado.svg';
import logoRetangulo from '../../assets/images/crefazon-logo-retangulo.svg';
import './styles.scss';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function Sidebar({ style }) {
  const { checarPermissao, iniciaPermissoes, permissoes } = useContext(
    ControleAcessoContext,
  );

  useEffect(() => {
    iniciaPermissoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (permissoes) {
      var lista = document.querySelectorAll('.nav-third-level');

      if (lista) {
        for (var i = 0; i < lista.length; i++) {
          if (lista[i].querySelectorAll('li').length === 0)
            lista[i].parentElement.remove();
        }
      }

      lista = document.querySelectorAll('.nav-second-level');

      if (lista) {
        for (i = 0; i < lista.length; i++) {
          if (lista[i].querySelectorAll('li').length === 0)
            lista[i].parentElement.remove();
        }
      }
    }
  }, [permissoes]);

  return (
    <nav
      className="navbar-default navbar-static-side"
      role="navigation"
      style={style}
    >
      <div className="sidebar-collapse" id="side-menu">
        <ul className="nav metismenu">
          <li className="nav-header">
            <div className="profile-element">
              <img
                src={logoRetangulo}
                alt="logo-retangulo"
                className="logo-retangulo"
              />
            </div>

            <div className="logo-element">
              <img src={logoQuadrado} alt="logo-quadrado" width="90%" />
            </div>
          </li>

          <li>
            <Link to="/">
              <i className="fa fa-fw fa-lg fa-home"></i>
              <span className="nav-label">Início</span>
            </Link>
          </li>
        </ul>
        <ul className="nav metismenu" style={{ opacity: permissoes ? 1 : 0 }}>
          <li>
            <a href="/">
              <i className="fa fa-fw fa-lg fa-sliders"></i>
              <span className="nav-label">Configurações</span>
              <span className="fa arrow"></span>
            </a>
            <ul className="nav nav-second-level collapse">
              <li>
                <a href="/">
                  Segurança
                  <i className="fa arrow"></i>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.configuracoes.seguranca.auditoria',
                    'Visualizar',
                  ) && (
                    <li>
                      <a href="/#">
                        <i className="text-info">Auditoria</i>
                      </a>
                    </li>
                  )}
                </ul>
              </li>
              {checarPermissao(
                'menu',
                'menu.configuracoes.parametro',
                'Visualizar',
              ) && (
                <li>
                  <Link to="/parametro">Parâmetro</Link>
                </li>
              )}
            </ul>
          </li>

          <li>
            <a href="/">
              <i className="fa fa-fw fa-lg fa-handshake-o"></i>
              <span className="nav-label">Comercial</span>
              <span className="fa arrow"></span>
            </a>

            <ul className="nav nav-second-level collapse">
              <li>
                <a href="/">
                  Comissão
                  <span className="fa arrow"></span>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.comercial.comissao.relatorioComissao',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/relatorio-comissao">
                        Relatório de Comissão
                      </Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.comercial.comissao.notasFiscais',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/notas-fiscais">Notas Fiscais</Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </li>

          <li data-cy="menu-credito">
            <a href="/">
              <i className="fa fa-fw fa-lg fa-money"></i>
              <span className="nav-label">Crédito</span>
              <span className="fa arrow"></span>
            </a>
            <ul className="nav nav-second-level collapse">
              <li>
                <a href="/">
                  Pessoa
                  <span className="fa arrow"></span>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.credito.pessoa.cadastro',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/cadastro-pessoa">Cadastro</Link>
                    </li>
                  )}
                </ul>
              </li>

              <li>
                <a href="/" data-cy="menu-proposta">
                  Proposta
                  <span className="fa arrow"></span>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.simulacao',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/simulacao">Simulação</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.novaProposta',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/pre-analise" data-cy="menu-nova-proposta">
                        Nova Proposta
                      </Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.analise',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/analise-proposta">Análise</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.acompanhamento',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/acompanhamento-proposta">Acompanhamento</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.redistribuicao',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/filaCredito">Redistribuição de Proposta</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.proposta.impressaoContrato',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/impressao-proposta">
                        Impressão de contratos
                      </Link>
                    </li>
                  )}
                </ul>
              </li>

              <li>
                <a href="/">
                  Relatório
                  <span className="fa arrow"></span>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.credito.relatorio.followUp',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/relatorios-proposta">Follow Up</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.relatorio.visaoAnalitica',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/relatorio-analitico">Visão Analítica</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.relatorio.esteiraCredito',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/relatorio-esteira-credito">
                        Esteira de Crédito
                      </Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.credito.relatorio.controlePropostas',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/relatorio-controle-proposta">
                        Controle de Propostas
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </li>

          {checarPermissao(
            'menu',
            'menu.integracoes.geracaoDeToken',
            'Visualizar',
          ) && (
            <li>
              <Link to="/integracoes">
                <i className="fa fa-fw fa-lg fa-exchange"></i>
                <span className="nav-label">Integrações</span>
              </Link>
            </li>
          )}

          <li>
            <a href="/">
              <i className="fa fa-fw fa-lg fa-gear"></i>
              <span className="nav-label">BackOffice</span>
              <span className="fa arrow"></span>
            </a>
            <ul className="nav nav-second-level collapse">
              <li>
                <a href="/">
                  Blocklist
                  <i className="fa arrow"></i>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.backoffice.blocklist.cpf',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/blocklist-cpf">Blocklist CPF</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.backoffice.blocklist.telefone',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/blocklist-telefone">Blocklist Telefone</Link>
                    </li>
                  )}
                  {checarPermissao(
                    'menu',
                    'menu.backoffice.blocklist.unidadeConsumidora',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/blocklist-unidade-consumidora">
                        Blocklist Unidade Consumidora
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <a href="/">
                  Telefonia
                  <i className="fa arrow"></i>
                </a>
                <ul className="nav nav-third-level">
                  {checarPermissao(
                    'menu',
                    'menu.backoffice.telefonia.historico',
                    'Visualizar',
                  ) && (
                    <li>
                      <Link to="/historico-gravacoes">
                        Histórico de Chamadas
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <a href="/">
              <i className="fa fa-fw fa-lg fa-wrench"></i>
              <span className="nav-label">Cadastro</span>
              <span className="fa arrow"></span>
            </a>
            <ul className="nav nav-second-level collapse">
              {checarPermissao(
                'menu',
                'menu.cadastro.credenciamento',
                'Visualizar',
              ) && (
                <li>
                  <Link to="/credenciamento">Credenciamento</Link>
                </li>
              )}
              {checarPermissao(
                'menu',
                'menu.cadastro.usuarios',
                'Visualizar',
              ) && (
                <li>
                  <Link to="/usuarios">Usuários</Link>
                </li>
              )}
              {checarPermissao(
                'menu',
                'menu.cadastro.hierarquia',
                'Visualizar',
              ) && (
                <li>
                  <Link to="/hierarquias">Hierarquias</Link>
                </li>
              )}
              {checarPermissao(
                'menu',
                'menu.cadastro.tabelajuros',
                'Visualizar',
              ) && (
                <li>
                  <Link to="/tabela-juros">Tabela de Juros</Link>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;
