import React, { useContext, useState, useEffect } from 'react';

import CardIcon from '../../../components/CardIcon';
import Tag from '../../../components/Tag';
import Button from '../../../components/Button';
import Tooltip from '../../../components/tooltip';
import Table from '../../../components/FullTable';
import Overlay from '../../../components/Overlay';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import { cpfFormatter } from '../../../utils/textFormatter';

import { ReactComponent as StepUserIcon } from '../../../assets/images/icons/step-current.svg';
import { ReactComponent as MovingClockIcon } from '../../../assets/images/icons/moving-clock.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

import { CaretDownFilled } from '@ant-design/icons';

import { historicoColumns } from './historicoColumns';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function EtapaAtual() {
  const {
    proposta,
    buscarHistoricoAcoesProposta,
    buscarHistoricoProposta,
  } = useContext(AnaliseMesaContext);

  const [timer, setTimer] = useState(0);
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  const { checarPermissao } = useContext(ControleAcessoContext);

  function incrementaTempo() {
    const tempo = proposta?.tempoDecorrido?.split(':');
    if (!!tempo) {
      let hora = 0,
        minuto = 0,
        segundo = 0;

      if (tempo.length === 3) {
        hora = +tempo[0] * 3600;
        minuto = +tempo[1] * 60;
        segundo = +tempo[2];
      } else {
        minuto = +tempo[0] * 60;
        segundo = +tempo[1];
      }

      const tempoTotal = hora + minuto + segundo;
      setTimer(tempoTotal);
    }
  }

  useEffect(() => {
    if (proposta?.tempoDecorrido) {
      incrementaTempo();
      if (proposta?.contadorAtivo) {
        let interval;
        interval = setInterval(() => {
          setTimer((oldTimer) => oldTimer + 1);
        }, 1000);

        return () => clearInterval(interval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposta?.tempoDecorrido]);

  const loadHistoricoAcoes = async (propostaId) => {
    const data = await buscarHistoricoAcoesProposta(propostaId);
    sessionStorage.setItem('historicoAcoesProposta', JSON.stringify(data));

    window.open(`/historico-acoes/${propostaId}`, '_blank');
  };

  const TooltipInfo = ({ text, id }) => (
    <Tooltip
      placement="bottom"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={text}
    >
      <BulletInfo
        className="bullet-info-icon"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          loadHistoricoAcoes(id);
        }}
      />
    </Tooltip>
  );

  const tituloStatus = (
    <>
      Status <TooltipInfo text="Histórico de ações" id={proposta?.id} />
    </>
  );

  return (
    <CardIcon
      title={tituloStatus}
      icon={<StepUserIcon className="mr-1" />}
      rightBar={
        <Tag color={proposta?.situacaoCor} className="tag-status">
          {proposta?.situacaoDescricao}
        </Tag>
      }
    >
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.historico',
        'Visualizar',
      ) && (
        <>
          <div className="historico">
            <Tooltip
              autoAdjustOverflow={false}
              placement="bottomRight"
              color="#445566"
              overlayClassName="tooltip-historico-mesa-credito"
              overlayStyle={{ minWidth: '520px' }}
              trigger="click"
              onClick={async () => {
                if (!historico.length) {
                  const data = await buscarHistoricoProposta(
                    proposta?.id,
                    false,
                    'analiseMesaCredito',
                  );
                  setHistorico(data);
                }
                setLoading(false);
              }}
              title={
                <div className="outsideBorder">
                  <p>
                    Proposta: {proposta?.id}
                    <br />
                    CPF: {cpfFormatter(proposta?.cliente?.cpf)}
                  </p>
                  <div className="tabelaHistorico">
                    <Overlay active={loading}>
                      <Table
                        data={historico}
                        columns={historicoColumns}
                        pagination={false}
                        filter={false}
                      />
                    </Overlay>
                  </div>
                </div>
              }
            >
              <Button type="button" variant="gray" className="btn btn-xs-block">
                Histórico
                <CaretDownFilled />
              </Button>
            </Tooltip>
          </div>
        </>
      )}
      <div className="tempo-proposta">
        <MovingClockIcon className="mr-1" />
        {formatTime()}
      </div>
    </CardIcon>
  );
}

export default EtapaAtual;
