import React from 'react';
import { Tooltip } from 'antd';
import Tag from '../../components/Tag';
import { cpfFormatter } from '../../utils/textFormatter';

import { conformToMask } from 'react-text-mask';
import { phoneMask } from '../../utils/masks';

import { ReactComponent as IconExistente } from '../../assets/images/icons/icon-telefone-existente.svg';

import Table from '../../components/FullTable';

import { columnsContato } from '../../pages/AnaliseMesaCredito/Forms/ColumnsTables/columnsContato';

const TooltipTelefoneExistente = (data = null) => {
  return (
    <Tooltip
      placement="right"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={
        <div className="outsideBorder">
          <p>
            Telefone:{' '}
            {conformToMask(data?.data?.telefone, phoneMask).conformedValue}
            {data?.data?.validaTelefone?.blocklist && (
              <Tag className="ml-3" color="#85635B">
                Telefone no blocklist
              </Tag>
            )}
            <br />
            CPF: {cpfFormatter(data?.data?.cpf)}
            <br />
          </p>
          {data?.data?.validaTelefone?.propostas &&
            data?.data?.validaTelefone.propostas?.length > 0 && (
              <div className="tabelaHistorico">
                <Table
                  data={data?.data?.validaTelefone?.propostas}
                  columns={columnsContato}
                  pagination={false}
                  filter={false}
                />
              </div>
            )}
        </div>
      }
      overlayStyle={{ minWidth: '900px' }}
    >
      <IconExistente width={10} className="icone-campo-alterado" />
    </Tooltip>
  );
};

export default TooltipTelefoneExistente;
