import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import AnaliseList from './AnaliseList';
import AnaliseIndicadores from './AnaliseIndicadores';

import useInspinia from '../../hooks/useInspinia';

import AnalisePropostaProvider from '../../contexts/AnalisePropostaContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import './styles.scss';

function AnaliseProposta() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Análise</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <AnalisePropostaProvider>
          {checarPermissao(
            'tela',
            'tela.credito.proposta.analise',
            'Visualizar',
          ) && (
            <>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 indicadores-analise-proposta">
                  <AnaliseIndicadores />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <AnaliseList />
                </div>
              </div>
            </>
          )}
        </AnalisePropostaProvider>
      </Content>
    </Main>
  );
}

export default AnaliseProposta;
