import moment from 'moment';

const initialValues = {
  produtoId: null,
  convenioId: null,
  tabelaJurosId: null,
  dataVencimento: moment(),
  tipoCalculo: 0,
  valorSolicitado: 0,
  floatValorSolicitado: 0,
  permiteSelecao: false,
};

export default initialValues;
