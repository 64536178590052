import React from 'react';

import './styles.scss';

function CardIcon({ children, title, icon, rightBar }) {
  return (
    <div className="card-icon">
      <div className="card-icon-header">
        <div className="card-icon-title">
          <div className="title">
            {icon}
            {title}
          </div>
          {rightBar}
        </div>
      </div>

      <div className="card-icon-content">{children}</div>
    </div>
  );
}

export default CardIcon;
