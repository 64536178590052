import Overlay from '../../components/Overlay';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { ReactComponent as IconRefresh } from '../../assets/images/icons/refresh.svg';
import { ReactComponent as IconCopy } from '../../assets/images/icons/copy.svg';
import { ReactComponent as IconErrorContained } from '../../assets/images/icons/icon-error-contained.svg';
import { ReactComponent as IconDone } from '../../assets/images/icons/done.svg';
import Card from '../../components/Card';
import Title from '../../components/Title';
import { IntegracoesContext } from '../../contexts/IntegracoesContext';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

const IntegracoesPage = () => {
  const { checarPermissao } = useContext(ControleAcessoContext);

  const {
    token,
    loading,
    isTokenExpired,
    expirationDateString,
    consultarGerarToken,
    errorMessage,
  } = useContext(IntegracoesContext);

  const [isTokenCopied, setIsTokenCopied] = useState(false);

  const isButtonVisible = checarPermissao(
    'botao',
    'botao.integracoes.geracaoDeToken',
    'Visualizar',
  );

  return (
    <Overlay active={loading}>
      <Card title={<Title label="Geração de Token" />}>
        <p>Utilize o token abaixo para acessar ao sistema Promobank</p>

        {!errorMessage && (
          <>
            <textarea className="token" value={token} disabled></textarea>

            {isTokenExpired && (
              <>
                <span className="info-token">Token expirado</span>

                {isButtonVisible && (
                  <Button
                    className="button"
                    onClick={() => {
                      consultarGerarToken(true);
                    }}
                  >
                    <IconRefresh style={{ marginRight: '5px' }} width="15px" />
                    ATUALIZAR TOKEN
                  </Button>
                )}
              </>
            )}

            {!isTokenExpired && (
              <>
                <span className="info-token">
                  Expira em <span>{expirationDateString}</span>
                </span>

                {isButtonVisible && (
                  <Button
                    className={'button' + (isTokenCopied ? ' copied' : '')}
                    onClick={() => {
                      copyToClipboard(token).then(() => {
                        setIsTokenCopied(true);
                      });
                    }}
                  >
                    {!isTokenCopied && (
                      <>
                        <IconCopy style={{ marginRight: '5px' }} width="15px" />
                        COPIAR TOKEN
                      </>
                    )}

                    {isTokenCopied && (
                      <>
                        <IconDone style={{ marginRight: '5px' }} width="15px" />
                        COPIADO
                      </>
                    )}
                  </Button>
                )}
              </>
            )}
          </>
        )}

        {errorMessage && (
          <div className="error">
            <IconErrorContained width={50} height={50} color="gray" />
            <div>{errorMessage.toUpperCase()}</div>
          </div>
        )}
      </Card>
    </Overlay>
  );
};

export default IntegracoesPage;
