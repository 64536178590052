import React, { useContext } from 'react';
import moment from 'moment';
import { Switch } from 'antd';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';
import ModalAlerta from '../../../components/Modal';

import Toolbar from '../Toolbar';

import { ReactComponent as IconDownload } from '../../../assets/images/icons/download.svg';

import { NotasFiscaisContext } from '../../../contexts/NotasFiscaisContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import './styles.scss';
import Overlay from '../../../components/Overlay';
import Button from '../../../components/Button';

function NotasFiscaisList() {
  const {
    pageIndex,
    setPageIndex,
    loading,
    overlay,
    total,
    filters,
    pageCount,
    notasFiscais,
    fetchNotasFiscais,
    downloadNotaFiscal,
    verificaNota,
    disableDownload,
  } = useContext(NotasFiscaisContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const acessoSwitchVerificado = checarPermissao('botao', 'botao.notasFiscais.tabelaNotasFiscais.verificado', 'Editar')

  const handleStatusVerificacao = async (id, status) => {
    await verificaNota(id, status).then((response) => {
      if (response.success) {
        ModalAlerta('', response.data.mensagem, 'success');
      }
    });
  };

  const columns = [
    {
      Header: 'Data/Hora de Inserção',
      accessor: 'createDate',
      Cell: ({ value }) => {
        return moment(value).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      Header: 'Nome do arquivo',
      accessor: 'nomeArquivo',
    },
    {
      Header: 'Matriz',
      accessor: 'correspondente',
    },
    {
      Header: 'CNPJ Matriz',
      accessor: 'correspondenteCNPJ',
    },
    {
      Header: 'Data de referência',
      accessor: 'mesAnoReferencia',
    },
  ];

  if (
    checarPermissao(
      'botao',
      'botao.notasFiscais.tabelaNotasFiscais.verificado',
      'Visualizar',
    )
  ) {
    columns.push({
      Header: 'Verificado',
      accessor: 'statusVerificacao',
      Cell: ({ row, value }) => {
        return (
          <Switch
            size="small"
            defaultChecked={row.original.statusVerificacao}
            className="switch-red-if-off"
            onChange={() => handleStatusVerificacao(row.original.id, !value)}
            disabled={!acessoSwitchVerificado}
          />
        );
      },
    });
  }

  if (
    checarPermissao(
      'botao',
      'botao.notasFiscais.tabelaNotasFiscais.downloadNF',
      'Visualizar',
    )
  ) {
    columns.push({
      accessor: 'download',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <Button
            className="mb-1 link-download"
            type="button"
            onClick={() => downloadNotaFiscal(row.original.id)}
            disabled={disableDownload}
          >
            <IconDownload />
          </Button>
        );
      },
    });
  }

  return (
    <Overlay active={overlay}>
      <Card>
        <div className="notas-fiscais-table">
          <Table
            columns={columns}
            data={notasFiscais}
            pageCount={pageCount}
            loading={loading}
            onLoadData={fetchNotasFiscais}
            total={total}
            filter={filters}
            header={<Toolbar />}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      </Card>
    </Overlay>
  );
}

export default NotasFiscaisList;
