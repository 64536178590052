import React, { useState, useContext, useEffect } from 'react';

import { Upload, Modal } from 'antd';

import Message from '../../../components/Modal';
import Overlay from '../../../components/Overlay';
import UploadItem from '../../../components/UploadItems';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import { getLinkImagem } from '../../../services/imagemService';

import './styles.scss';
import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';
import CustomUploadRender from '../../../components/CustomUploadRender';



function PropostaUploadForm({ formik }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingFile, setLoadingFile] = useState(false);
  const [isLuzEmDia, setIsLuzEmDia] = useState(false);
  const listaTipoAnexo = [];

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const { values } = formik;

  const [blobFile, setBlobFile] = useState(null);
  const [hideDocsPreview, setHideDocsPreview] = useState(false)

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
    setHideDocsPreview(!checarPermissao('botao', 'botao.propostaCredito.visualizarArquivos', 'Visualizar'))
  }, [checarPermissao]);

  const {
    proposta,
    totalDebitosNaoComprovados,
    carregarImagem,
    arquivos,
    setArquivos,
    avisoArquivosPendentes,
    editableField,
    tipoAnexos,
    setAvisoArquivosPendentes,
    reloadUploadForm
  } = useContext(PropostaCreditoContext);

  useEffect(() => {
    alteraAviso();
    setIsLuzEmDia(
      proposta?.operacao?.produtoId === 12 &&
      totalDebitosNaoComprovados > 0 &&
      proposta?.elegivelLuzEmDia === 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.operacao.tipoModalidade,
    values.operacao.tipoRenda,
    totalDebitosNaoComprovados,
  ]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setBlobFile(null);
    setPreviewImage(null);
    setPreviewTitle(null);
  };

  const handleRemove = (file) => {
    let newFileList = [];

    if (file.imagemId) {
      newFileList = arquivos.filter(
        (fileRemove) => fileRemove.imagemId !== file.imagemId,
      );
    }

    const arquivosObrigatoriosPendentes = tipoAnexos?.filter((arquivo) => {
      if (
        (arquivo.tipoModalidade === values?.operacao?.tipoModalidade ||
          arquivo.tipoModalidade === 0) &&
        (arquivo.tipoRenda === values?.operacao?.tipoRenda ||
          arquivo.tipoRenda == null) &&
        arquivo.obrigatorio
      ) {
        if (totalDebitosNaoComprovados > 0 && arquivo.luzEmDia) {
          return (
            arquivo.produtoId === proposta?.operacao?.produtoId &&
            !newFileList.find((item) => item.documentoId === arquivo.id)
          );
        } else if (totalDebitosNaoComprovados === 0 && !arquivo.luzEmDia) {
          return !newFileList.find((item) => item.documentoId === arquivo.id);
        }
      }
      return false;
    });

    setAvisoArquivosPendentes(arquivosObrigatoriosPendentes);
    setArquivos(newFileList);
  };

  const adicionaPreviewImage = async (file, isPreviewVisible) => {
    if (file.link) {
      const anexo = await getLinkImagem(file.link);
      setLoadingFile(false);

      const { conteudo } = anexo;

      if (conteudo) {
        file.type = conteudo.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(conteudo);
        setPreviewVisible(isPreviewVisible);
      }
    } else if (file.url) {
      setLoadingFile(false);
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(file.url);
      setPreviewVisible(isPreviewVisible);
    }
  };

  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);

    await adicionaPreviewImage(file, true);

    if (file.documentoId) {
      setPreviewTitle(
        tipoAnexos?.find((tipo) => tipo.id === file.documentoId).nome,
      );
    } else {
      setPreviewTitle('');
    }
  };

  const indiceArquivo = (imagemId) =>
    arquivos.findIndex((elem) => elem.imagemId === imagemId);

  const handleType = (value, file) => {
    let index;

    if (file.imagemId) {
      index = indiceArquivo(file.imagemId);
    }

    const tempArquivos = arquivos;
    tempArquivos[index].documentoId = value;
    file.documentoId = value;

    alteraAviso();
  };

  const alteraAviso = () => {
    if (tipoAnexos.length > 0) {
      const arquivosObrigatoriosPendentes = tipoAnexos?.filter((arquivo) => {
        if (
          (arquivo.tipoModalidade === values?.operacao?.tipoModalidade ||
            arquivo.tipoModalidade === 0) &&
          (arquivo.tipoRenda === values?.operacao?.tipoRenda ||
            arquivo.tipoRenda == null) &&
          arquivo.obrigatorio
        ) {
          if (totalDebitosNaoComprovados > 0 && arquivo.luzEmDia) {
            return (
              arquivo.produtoId === proposta?.operacao?.produtoId &&
              !arquivos.find((item) => item.documentoId === arquivo.id)
            );
          } else if (totalDebitosNaoComprovados === 0 && !arquivo.luzEmDia) {
            return !arquivos.find((item) => item.documentoId === arquivo.id);
          }
        }
        return false;
      });

      setAvisoArquivosPendentes(arquivosObrigatoriosPendentes);
    }
  };

  const uploadImage = async (options) => {
    if (values?.operacao?.tipoModalidade === 0) {
      Message(
        '',
        'Favor selecionar a modalidade do contrato antes de anexar um documento',
        'warning',
      );
    } else {
      const { file } = options;

      if (file?.status !== 'removed') {
        const reader = new FileReader();
        const temp = { arquivo: '' };
        reader.onloadend = async function () {
          temp.arquivo = reader.result;
          try {
            const fileURL = await getBase64(file);
            const { data } = await carregarImagem(temp);
            const { dataHora, id } = data;
            const newFile = {
              dataHora: dataHora,
              documentoId: null,
              imagemId: id,
              url: fileURL,
            };
            adicionaPreviewImage(newFile, false)
            setArquivos((arquivos) => [newFile, ...arquivos]);
            alteraAviso();
          } catch (err) {
            console.log(`Falha no upload`, err);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const uploadButton = (
    <div className="action-anexar">
      <i className="fa fa-plus"></i>
      <div>Clique para anexar</div>
    </div>
  );

  const beforeUpload = (file) => {
    file.status = '';
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg'
    ) {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
        'warning',
      );
      file.status = 'removed';
    }
    return false;
  };

  const UploadContainer = () => (
    <Upload
        multiple={true}
        listType="picture-card"
        className={
          'proposta-credito-uploads ' +
          (avisoArquivosPendentes?.length > 0 ? 'arquivos-pendentes' : '')
        }
        fileList={arquivos}
        onPreview={handlePreview}
        itemRender={(_, file) => (
            <CustomUploadRender
              key={file.imagemId}
              file={file}
              tipoAnexos={tipoAnexos}
              UploadItem={UploadItem}
              arquivos={arquivos}
              handleType={handleType}
              handlePreview={handlePreview}
              handleRemove={handleRemove}
              values={values}
              indiceArquivo={indiceArquivo}
              acesso={acesso}
              isLuzEmDia={isLuzEmDia}
              listaTipoAnexo={listaTipoAnexo}
              editableField={editableField}
              hideDocsPreview={hideDocsPreview}
            />
          )}
        beforeUpload={beforeUpload}
        onChange={uploadImage}
        onRemove={handleRemove}
        disabled={acesso ? !editableField('anexo.adicionarRemover') : true}
      >
        {uploadButton}
      </Upload>
  )

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobFile(blobURL);
    } else {
      setBlobFile(null);
    }
  }, [previewImage, fileType]);

  return (
    <div>
      {reloadUploadForm && UploadContainer()}

      {!reloadUploadForm && UploadContainer()}

      <Modal
        visible={previewVisible}
        destroyOnClose={true}
        title={previewTitle}
        footer={null}
        className={
          'modal-preview-upload ' +
          fileType.replace('/', '') +
          (previewTitle ? ' com-titulo ' : '')
        }
        onCancel={handleCancel}
      >
        <Overlay active={loadingFile}>
          {!loadingFile && (
            <div className="file-preview-container">
              {fileType === 'application/pdf' && (
                <object
                  data={`${blobFile ?? previewImage}#toolbar=0`}
                  type={fileType}
                  width="100%"
                  height="100%"
                  aria-label="Upload File"
                ></object>
              )}
              {fileType !== 'application/pdf' && (
                <div className="modal-preview-image">
                  <img src={previewImage} alt="Documento"></img>
                </div>
              )}
            </div>
          )}
        </Overlay>
      </Modal>
    </div>
  );
}

export default PropostaUploadForm;
