import React, { useContext } from 'react';

import { Switch } from 'antd';

import Card from '../../../components/Card';
import Table from '../../../components/FullTable';
import IconEdit from '../../../components/EditIcon';

import {
  currencyFormat,
  percentualFormat,
} from '../../../utils/numberFormatter';

import { TarifaContext } from '../../../contexts/TarifaContext';

import './styles.scss';

function TarifaList() {
  const { tarifas, filterStatus, setFilterStatus, setValueForTarifa } =
    useContext(TarifaContext);

  const Action = () => (
    <span className="d-flex align-items-center justify-content-end mr-3">
      Apenas ativos{' '}
      <Switch
        size="small"
        checked={filterStatus}
        onChange={(e) => setFilterStatus(e)}
        style={{ marginLeft: '5px' }}
      />
    </span>
  );

  const handleEdit = (data) => {
    setValueForTarifa(data);
  };

  const columns = [
    {
      Header: 'Cód.',
      accessor: 'id',
      style: {
        width: '10%',
      },
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      style: {
        width: '20%',
      },
    },
    {
      Header: 'Valor Fixo',
      accessor: 'valorFixo',
      Cell: ({ value }) => <div>{currencyFormat(value)}</div>,
      style: {
        width: '20%',
      },
    },
    {
      Header: 'Valor (%)',
      accessor: 'percentual',
      Cell: ({ value }) => <div>{percentualFormat(value, 4)}</div>,
      style: {
        width: '20%',
      },
    },
    {
      Header: 'Evento',
      accessor: 'evento',
      style: {
        width: '20%',
      },
    },
    {
      Header: 'Ativo',
      accessor: 'ativo',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <Switch checked={value} size="small" onChange={() => {}} />;
      },
      style: {
        width: '5%',
      },
    },
    {
      Header: '',
      accessor: 'edit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <IconEdit onClick={() => handleEdit(row.original)} />;
      },
      style: {
        width: '5%',
      },
    },
  ];

  return (
    <Card>
      <div className="tableTarifa">
        <Table
          data={tarifas}
          columns={columns}
          pagination={true}
          filter={true}
          action={<Action />}
          header="Tarifas"
        />
      </div>
    </Card>
  );
}

export default TarifaList;
