import { useHistory } from 'react-router-dom';
import Card from '../../components/Card';
import Title from '../../components/Title';
import Button from '../../components/Button';

import { UsuariosContext } from '../../contexts/UsuariosContext';

import { ReactComponent as FilterIcon } from '../../assets/images/icons/filter.svg';
import { ReactComponent as IconPlusBtn } from '../../assets/images/icons/add.svg';
import { ReactComponent as BackIcon } from '../../assets/images/icons/back.svg';

import UsuariosList from './UsuariosList';
import { useContext, useState } from 'react';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

export function UsuariosPage() {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const history = useHistory();

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };
  const { validaSelectedEmails } = useContext(UsuariosContext);

  const [showFilter, setShowFilter] = useState(true);

  return (
    <>
      {checarPermissao(
        'tela',
        'tela.cadastro.usuarios',
        'Visualizar',
      ) ? (
        <div className="card-listagem-usuarios">
          <Card
            title={<Title label="Usuários Cadastrados" />}
            rightBar={
              <div>
                {checarPermissao(
                  'botao',
                  'botao.cadastro.usuarios.maisUsuario',
                  'Visualizar',
                ) ? (
                  <Button
                    onClick={() => history.push('/cadastro-usuarios')}
                    type="button"
                    variant="blue"
                    className="btn-adicionar-usuario"
                    disabled={
                      !checarPermissao(
                        'botao',
                        'botao.cadastro.usuarios.maisUsuario',
                        'Visualizar',
                      )
                    }
                  >
                    <IconPlusBtn />
                    USUÁRIO
                  </Button>
                ) : (
                  ''
                )}
                {checarPermissao(
                  'botao',
                  'botao.cadastro.usuarios.redefinirSenha',
                  'Visualizar',
                ) ? (
                  <Button
                    type="button"
                    variant="blue"
                    className="btn-redefinir-senha"
                    onClick={() => validaSelectedEmails()}
                    disabled={
                      !checarPermissao(
                        'botao',
                        'botao.cadastro.usuarios.redefinirSenha',
                        'Editar',
                      )
                    }
                  >
                    <BackIcon />
                    REDEFINIR SENHA
                  </Button>
                ) : (
                  <></>
                )}
                <Button
                  onClick={handleShowFilter}
                  type="button"
                  variant="gray"
                  className="btn-filtrar"
                >
                  <FilterIcon />
                  FILTRAR
                </Button>
              </div>
            }
          >
            <UsuariosList showFilter={showFilter} />
          </Card>
        </div>
      ) : (
        ''
      )}
    </>
  );
}
