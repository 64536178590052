import api from './api';

export const listarPessoas = async ({
  pagina = 1,
  filtroNome = '',
  filtroCPF = '',
  filtroTelefone = '',
  filtroUC = '',
  filtroDinamico = '',
  ordenacao = { id: '', desc: false },
}) => {
  const { data } = await api.post('/Pessoa/Cadastro-Pessoa-Lista', {
    pagina,
    filtroNome,
    filtroCPF,
    filtroTelefone,
    filtroUC,
    filtroDinamico,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
  });
  return data;
};

export const editarPessoa = async (pessoa) => {
  try {
    const { data } = await api.put(
      `/Pessoa/Cadastro-Pessoa-Atualiza/${pessoa.pessoa.id}`,
      pessoa,
    );
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const getHistoricoAlteracao = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  filtroDinamico = '',
  ordenacao = { id: 'DataAlteracao', desc: false },
  pessoaId,
}) => {
  const { data } = await api.post('/Pessoa/HistoricoAlteracao', {
    pagina,
    quantidadePorPagina,
    filtroDinamico,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
    pessoaId,
  });
  return data;
};

export const getHistoricoProposta = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  filtroDinamico = '',
  ordenacao = { id: 'id', desc: false },
  pessoaId,
}) => {
  const { data } = await api.post('/Proposta/HistoricoProposta', {
    pagina,
    quantidadePorPagina,
    filtroDinamico,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
    id: pessoaId,
  });
  return data;
};

export const getHistoricoContrato = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  filtroDinamico = '',
  ordenacao = { id: 'plano', desc: false },
  pessoaId,
}) => {
  const { data } = await api.post('/Pessoa/HistoricoContrato', {
    pagina,
    quantidadePorPagina,
    filtroDinamico,
    ordenacao: ordenacao.id,
    ordenacaoAsc: !ordenacao.desc,
    pessoaId,
  });
  return data;
};

export const getHistoricoPrestacao = async (payload) => {
  const { data } = await api.post('/Pessoa/HistoricoPrestacao', payload);
  return data;
};

export const getCadastroPessoaInfo = async (pessoa) => {
  const { data } = await api.post('/Pessoa/Cadastro-Pessoa-Info', pessoa);
  return data;
};

export const getCadastroPessoaContexto = async () => {
  const { data } = await api.get('/Pessoa/Cadastro-Pessoa-Contexto');
  return data;
};

export const getBlocklistCadastroPessoa = async (pessoaId) => {
  const { data } = await api.get(
    `/bloqueado/modal-cadastro-pessoa/${pessoaId}`,
  );
  return data;
};
