import React, { useContext } from 'react';

import { Tooltip, Button } from 'antd';

import moment from 'moment';
import { cpfFormatter } from '../../../utils/textFormatter';

import CompListBlocklist from '../../../components/ListBlocklist';

import { BlocklistCPFContext } from '../../../contexts/BlocklistCPFContext';

import { ReactComponent as IconObservacoes } from '../../../assets/images/icons/infocard.svg';
import { ReactComponent as IconEditar } from '../../../assets/images/icons/icon-editar.svg';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function ListBlocklist() {
  const {
    listagemCPFs,
    filter,
    setFilter,
    setValueForCpfBlocklist,
    fetchListagemCPFs,
    total,
    pageCount,
    loading,
  } = useContext(BlocklistCPFContext);

  const handleEdit = (data) => {
    setValueForCpfBlocklist(data);
  };

  const { checarPermissao } = useContext(ControleAcessoContext);

  const columnsBlocklistCPF = [
    {
      Header: 'CPF',
      accessor: 'cpf',
      Cell: ({ value }) => (value ? cpfFormatter(value) : ''),
    },
    {
      Header: 'Inclusão',
      accessor: 'inclusao',
      Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      Header: 'Exclusão',
      accessor: 'exclusao',
      Cell: ({ value }) => (value ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      Header: 'Motivo',
      accessor: 'motivo',
    },
  ];

  if (checarPermissao('botao', 'botao.blocklistCpf.tooltip', 'Visualizar')) {
    columnsBlocklistCPF.push({
      Header: '',
      accessor: 'observacao',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Tooltip
          arrowPointAtCenter
          placement="bottomRight"
          overlayClassName="tooltip-list-blocklist"
          title={
            <p>
              <b>Última observação:</b>
              <br />
              {row.original.exclusao
                ? row.original.descricaoExclusao
                : row.original.descricaoInclusao}
            </p>
          }
        >
          <span className="icon-observacoes">
            <IconObservacoes />
          </span>
        </Tooltip>
      ),
    });
  }

  if (checarPermissao('botao', 'botao.blocklistCpf.edicao', 'Visualizar')) {
    columnsBlocklistCPF.push({
      Header: '',
      accessor: 'editar',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Button className="btn-icon-editar">
          <IconEditar onClick={() => handleEdit(row.original)} />
        </Button>
      ),
    });
  }

  return (
    <CompListBlocklist
      title="Blocklist CPF"
      columns={columnsBlocklistCPF}
      onLoadData={fetchListagemCPFs}
      data={listagemCPFs}
      tipoBlocklist="cpf"
      filter={filter}
      setFilter={setFilter}
      total={total}
      pageCount={pageCount}
      loading={loading}
    />
  );
}

export default ListBlocklist;
