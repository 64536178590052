import { useState, cloneElement, useEffect, useRef } from 'react';
import { Form } from 'formik-antd';

// Feito para substituir componentes da biblioteca 'formik-antd' (Field, Input), que estão apresentando problemas de performance
// Aceita quaisquer componentes como children, sobrescrevendo os onChange, onBlur, name e value para atualizar o Formik corretamente
const CustomFormItem = ({
  name,
  maskFunction = null,
  formik,
  hasError,
  isTouched,
  label,
  errorMessage,
  validate,
  children,
  startingValue = null,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');

  const { setFieldValue, setFieldError, setFieldTouched } = formik;

  const runOnlyOnce = useRef(true);

  function onChange(e) {
    if (maskFunction) {
      setInputValue(maskFunction(e));
    } else {
      setInputValue(e.target.value);
    }

    const { value } = e.target;
    if (value && hasError) setFieldError(name, null);
  }

  function onBlur(e) {
    setFieldValue(name, inputValue);
    setFieldTouched(name, true);
  }

  useEffect(() => {
    if (runOnlyOnce.current && startingValue) {
      setInputValue(startingValue);
      runOnlyOnce.current = false;
    }
  }, [startingValue]);

  return (
    <Form.Item
      name={name}
      label={label}
      validateStatus={!inputValue && isTouched ? 'error' : ''}
      help={!inputValue && isTouched ? errorMessage : ''}
      validate={validate}
      {...rest}
    >
      {/* eslint-disable-next-line */}
      {typeof children !== undefined &&
        cloneElement(children, {
          onChange,
          onBlur,
          name,
          value: inputValue,
        })}
    </Form.Item>
  );
};

export default CustomFormItem;
