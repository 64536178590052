import React, { useContext } from 'react';

import TablePercent from './TabelaJurosPercentuais';
import TableFixedValues from './TabelaValoresFixos';

import { TabelaJurosContext } from '../../contexts/TabelaJurosContext';

function Tables() {
  const { showTable } = useContext(TabelaJurosContext);

  if (showTable === 0) {
    return <TablePercent />;
  } else {
    return <TableFixedValues />;
  }
}

export default Tables;
