const initialValues = {
  pessoa: {
    cpf: '',
    nome: '',
    nascimento: '',
    rg: '',
    documentoEmissor: '',
    documentoUFId: null,
    documentoEmissao: '',
    pep: false,
    sexo: null,
    estadoCivil: null,
    nacionalidadeId: null,
    naturalidadeUfId: null,
    naturalidadeCidadeId: null,
    grauInstrucao: null,
    nomeMae: '',
    nomeConjuge: '',
    pis: '',
  },

  endereco: {
    cep: '',
    logradouro: '',
    bairro: '',
    numero: '',
    cidadeId: null,
    ufId: null,
  },

  contatos: {
    email: '',
    telefone: '',
  },

  profissao: {
    empresa: '',
    ocupacaoId: null,
    profissaoId: null,
    tempoempregoId: null,
    telefoneRH: '',
    renda: null,
    outrasRendas: null,
    tipoOutrasRendas: '',
  },
};

export default initialValues;
