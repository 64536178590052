import api from './api';

export const getPermissoes = async () => {
  const { data } = await api.get('seguranca');
  return data.data;
};

export const getSistemaIndisponivel = async () => {
  const { data} = await api.get('/seguranca/sistema-indisponivel');
  return data;
}