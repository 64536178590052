import React, { createContext, useEffect, useState, useCallback } from 'react';

import {
  listarFollowUp,
  getFollowUpCard,
} from '../services/relatorioFollowUpService';

export const RelatorioFollowUpContext = createContext({});

function RelatorioFollowUpProvider({ children }) {
  const [followUp, setFollowUp] = useState([]);
  const [tempoRefresh, setTempoRefresh] = useState(5);
  const [tempoRolagem, setTempoRolagem] = useState(10);
  const [showOverlay, setShowOverlay] = useState(false);

  const loadFollowUp = useCallback(async () => {
    setShowOverlay(true);
    await listarFollowUp().then((response) => {
      setFollowUp(response.followUp);
      setTempoRefresh(response.tempoRefresh);
      setTempoRolagem(response.tempoRolagem);
    });
    setShowOverlay(false);
  }, []);

  useEffect(() => {
    loadFollowUp();
  }, [loadFollowUp]);

  return (
    <RelatorioFollowUpContext.Provider
      value={{
        followUp,
        tempoRefresh,
        tempoRolagem,
        showOverlay,
        setShowOverlay,
        loadFollowUp,
        getFollowUpCard,
      }}
    >
      {children}
    </RelatorioFollowUpContext.Provider>
  );
}

export default RelatorioFollowUpProvider;
