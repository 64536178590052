import React from 'react';

import { ReactComponent as Icon } from '../../assets/images/icons/icon-editar.svg';

import './styles.scss';

function EditIcon({ onClick, className }) {
  return (
    <button className={'btn-edit ' + className} onClick={onClick}>
      <Icon className="edit-icon" width="12" height="12" />
    </button>
  );
}

export default EditIcon;
