import React, { useState } from 'react';
import moment from 'moment';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { Field } from 'formik';
import { Form, Select, Input, TimePicker } from 'formik-antd';

import InputPhone from '../../../components/InputPhone';
import DatePicker from '../../../components/DatePicker';
import SelectSearch from '../../../components/SearchSelect';

import { ReactComponent as CaretDownFilled } from '../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';

function HistoricoFormDetails({
  row,
  contexto,
  listaAnalistas,
  editableField,
}) {
  const { Option } = Select;

  const disabledDate = (current) => {
    return current > moment().startOf('day').add(1, 'days');
  };

  const [usuarioId, setUsuarioId] = useState(row.usuarioId);
  const [statusChamada, setStatusChamada] = useState(row.statusChamada);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-row">
            <div className="col-lg-3">
              <Form.Item name="data" label="Data" hasFeedback>
                <DatePicker
                  name="data"
                  format="DD/MM/YYYY"
                  placeholder="00/00/0000"
                  value={moment(row.dataHora)}
                  onChange={(value) => (row.dataHora = value)}
                  disabledDate={disabledDate}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('historicoChamada.data')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item name="inicio" label="Início">
                <TimePicker
                  placeholder="01:00"
                  name="inicio"
                  format={['HH:mm', 'HHmm']}
                  value={moment(row.dataHora)}
                  onChange={(value) => (row.dataHora = value)}
                  locale={locale}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('historicoChamada.inicio')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item name="duracao" label="Duração">
                <TimePicker
                  placeholder="01:00"
                  name="duracao"
                  format={['HH:mm', 'HHmm']}
                  value={moment(row.duracao, 'hh:mm')}
                  onChange={(_, stringDate) => (row.duracao = stringDate)}
                  locale={locale}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('historicoChamada.duracao')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item name="telefone" label="Telefone">
                <InputPhone
                  name="telefone"
                  value={row.telefone}
                  onChange={(e) => (row.telefone = e.target.value)}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('historicoChamada.telefone')
                      : true
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-3">
              <Form.Item name="pa" label="PA">
                <Input
                  name="pa"
                  value={row.ramal}
                  onChange={(e) => (row.ramal = +e.target.value)}
                  type="number"
                  min={0}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('historicoChamada.pa')
                      : true
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item name="analista" label="Analista">
                <Field name="analista">
                  {({ field }) => (
                    <SelectSearch
                      {...field}
                      onChange={(value) => {
                        setUsuarioId(value);
                        row.usuarioId = value;
                      }}
                      value={usuarioId}
                      showSearch
                      disabled={
                        !contexto?.bloqueiaAcessoSimultaneo
                          ? !editableField('historicoChamada.usuarioId')
                          : true
                      }
                    >
                      {listaAnalistas.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </SelectSearch>
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item name="statusChamada" label="Situação">
                <Field name="statusChamada">
                  {({ field }) => (
                    <SelectSearch
                      {...field}
                      placeholder="Selecione"
                      showArrow={true}
                      value={statusChamada}
                      suffixIcon={<CaretDownFilled />}
                      onChange={(value) => {
                        setStatusChamada(value);
                        row.statusChamada = value;
                      }}
                      disabled={
                        !contexto?.bloqueiaAcessoSimultaneo
                          ? !editableField('historicoChamada.situacao')
                          : true
                      }
                    >
                      {contexto?.listaStatusChamada?.map((status) => (
                        <Option value={status.id} key={status.id}>
                          {status.nome}
                        </Option>
                      ))}
                    </SelectSearch>
                  )}
                </Field>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HistoricoFormDetails;
