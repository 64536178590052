import React from 'react';

import './styles.scss';

function SpinnerArrowCircle() {
  return (
    <svg
      id="spinnerCircle"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.53625 5.6325C1.35063 6.22438 1.25 6.85438 1.25 7.5075C1.25 10.9575 4.05062 13.7575 7.5 13.7575C10.95 13.7575 13.75 10.9575 13.75 7.5075C13.75 4.05813 10.95 1.2575 7.5 1.2575C6.17438 1.2575 4.94812 1.675 3.93563 2.38L5 3.7575H0.8875L2.09687 0L3.17062 1.38937C4.39437 0.52125 5.88688 0.0075 7.5 0.0075C11.6394 0.0075 15 3.36812 15 7.5075C15 11.6469 11.6394 15 7.5 15C3.36062 15 0 11.6469 0 7.5075C0 6.86 0.0825 6.23188 0.236875 5.6325H1.53625Z"
        fill="white"
      />
    </svg>
  );
}

export default SpinnerArrowCircle;
