import React, { useState, useEffect, useContext } from 'react';
import { Input } from 'formik-antd';
import { getDDDs } from '../../services/contatoService';
import dddList from '../../utils/ddds';

import { ReactComponent as IconPhone } from '../../assets/images/icons/phone-call.svg';

import './styles.scss';

import { AnaliseMesaContext } from '../../contexts/AnaliseMesaContext';
import { notification } from 'antd';
import { criaChamada } from '../../services/analiseMesaService';

function InputPhone({
  placeholderValue,
  type = null,
  opcaoDiscador = false,
  tipoTelefone,
  handleTelefoneExtraType,
  value,
  disabledButton,
  ...props
}) {
  const ddds = sessionStorage.getItem('ddds');

  const [propsTelefone, setPropsTelefone] = useState(value);
  const [propsTelefoneFormatado, setPropsTelefoneFormatado] = useState();

  const {
    showModalDigitro,
    setShowModalDigitro,
    showModalAgitel,
    setShowModalAgitel,
    setTelefone,
    setTipoTelefone,
    setIsActive,
    setIsPaused,
    setChamadaId,
    proposta,
    setTimer,
    increment,
    status,
    setStatus,
    setChamada,
  } = useContext(AnaliseMesaContext);

  const formataPhone = (telefone) => {
    let unformatted = telefone;
    let formatted = '';
    for (let i = 0; i < unformatted?.length; i++) {
      if (i === 0) {
        formatted += '(';
      }
      if (i === 2) {
        formatted += ') ';
      } else if (!type) {
        if (i === (unformatted[2] === '9' ? 7 : 6)) {
          formatted += '-';
        } else if (i === (unformatted[2] === '9' ? 11 : 10)) {
          break;
        }
      } else if (type === 'fixo') {
        if (i === 6) {
          formatted += '-';
        } else if (i === 10) {
          break;
        }
      } else {
        if (i === 7) {
          formatted += '-';
        } else if (i === 11) {
          break;
        }
      }
      formatted += unformatted[i];
    }
    return formatted;
  };

  const dynamicMaskPhone = (e) => {
    let unformatted = [...e.target.value.replace(/\D/g, '')];

    e.target.value = formataPhone(unformatted);
    setPropsTelefone(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    setPropsTelefoneFormatado(formataPhone(value?.replace(/\D/g, '')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    async function loadDDDs() {
      const data = await getDDDs().catch(() =>
        sessionStorage.setItem('ddds', JSON.stringify(dddList)),
      );

      if (data) {
        await sessionStorage.setItem('ddds', JSON.stringify(data));
      }
    }

    if (!ddds) {
      loadDDDs();
    }
  }, [ddds]);

  const notificacaoTelInvalido = (type) => {
    notification[type]({
      message: 'Telefone inválido',
      description: 'O campo telefone deve ser preenchido',
    });
  };

  const notificacaoChamadaEmAndamento = (type) => {
    notification[type]({
      message: 'Chamada em andamento',
      description:
        'Já existe uma chamada em andamento, finalize a chamada para poder iniciar outra',
    });
  };

  async function iniciaChamadaTelefonica(telefone, propostaId, tipoTelefone) {
    if (showModalDigitro === false && showModalAgitel === false) {
      if (telefone) {
        setTelefone(telefone);
        setTipoTelefone(tipoTelefone);
        const telefonia = localStorage.getItem("telefonia")

        if (telefonia === "Dígitro") {
          // Dígitro
          setShowModalDigitro(true);
          setStatus('em-chamada');
          handleStart();
        } else {
          // Agitel
          setShowModalAgitel(true);
          const chamada = {
            propostaId,
            telefone,
          };
          const response = await criaChamada(chamada);
          if (response?.success) {
            setStatus('em-chamada');
            setChamadaId(response?.data?.id);
            setChamada({
              PropostaId: response?.data?.propostaId,
              historicoChamadaId: response?.data?.id,
              telefoniaId: response?.data?.telefoniaId,
              telefone: response?.data?.telefone,
              ramal: response?.data?.ramal,
              usuarioId: response?.data?.usuarioId,
              dataHora: response?.data?.dataHora,
              confirmacao: response?.data?.confirmacao,
              statusChamada: response?.data?.status,
              linkGravacao: response?.data?.linkGravacao,
              chamadaDataHoraInicio: response?.data?.chamadaDataHoraInicio,
              chamadaDataHoraFim: response?.data?.chamadaDataHoraFim,
              logAlterados: [],
            });
            handleStart();
          } else {
            setStatus('erro-comunicacao');
          }
        }
      } else {
        notificacaoTelInvalido('error');
      }
    } else {
      if (status !== 'erro-comunicacao') {
        notificacaoChamadaEmAndamento('error');
      }
    }
  }

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  return (
    <Input
      {...props}
      onChange={(e) => dynamicMaskPhone(e)}
      placeholder={placeholderValue ? placeholderValue : "Ex.: (00) 0000-0000"}
      className="ant-input ant-input-phone-call"
      value={propsTelefoneFormatado}
      suffix={
        opcaoDiscador && (
          <button
            type="button"
            className="sufix-icon"
            onClick={() =>
              iniciaChamadaTelefonica(
                propsTelefone.replace(/\D/g, ''),
                proposta?.id,
                tipoTelefone,
              )
            }
            disabled={disabledButton}
          >
            <IconPhone />
          </button>
        )
      }
      autoComplete="none"
    />
  );
}

export default InputPhone;
