import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';

import Pagination from '../Pagination';

import './styles.scss';

function Table({
  columns,
  data,
  className,
  header,
  pagination,
  height,
  filter,
  pageSize: controledPageSize = 5,
}) {
  const COLUMNS = useMemo(() => columns, [columns]);
  const DATA = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex },
  } = useTable(
    {
      data: DATA,
      columns: COLUMNS,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const items = pagination ? page : rows;

  useEffect(() => {
    setGlobalFilter(filter);
    setPageSize(Number(controledPageSize));
  }, [filter, controledPageSize, setGlobalFilter, setPageSize]);

  const renderSorter = (isDesc) => {
    if (isDesc) {
      return <i className="fa fa-sort-down" />;
    } else {
      return <i className="fa fa-sort-up" />;
    }
  };

  return (
    <div>
      {header && <div className="table-custom-header">{header}</div>}

      <div className="table-responsive" style={{ height }}>
        <table
          className={`table table-striped ${className}`}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ ...column.style }}
                  >
                    {column.render('Header')}
                    <span className="ml-1">
                      {column.canSort ? (
                        column.isSorted ? (
                          renderSorter(column.isSortedDesc)
                        ) : (
                          <i className="fa fa-sort" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {items.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {pagination && (
        <div className="table-custom-pagination">
          <Pagination
            totalRecords={data.length}
            pageIndex={pageIndex + 1}
            pageCount={pageCount}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageNeighbours={1}
          />
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  pagination: PropTypes.bool,
  height: PropTypes.string,
  filter: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  header: null,
  pagination: false,
  height: '',
  filter: '',
};

export default Table;
