import React, { Fragment, useState, useContext } from 'react';

import Button from '../../components/Button';
import Title from '../../components/Title';

import Filter from './filter';
import { useHistory } from 'react-router-dom';

import { ReactComponent as FilterIcon } from '../../assets/images/icons/filter.svg';
import { ReactComponent as PrintIcon } from '../../assets/images/icons/print.svg';
import { ReactComponent as WriteIcon } from '../../assets/images/icons/write.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/download.svg';
import { ReactComponent as SimulationIcon } from '../../assets/images/icons/simulation.svg';

import './styles.scss';

import { AcompanhamentoPropostaContext } from '../../contexts/AcompanhamentoPropostaContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function Toolbar({ formik }) {
  const [showFilter, setShowFilter] = useState(true);
  const { checarPermissao } = useContext(ControleAcessoContext);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const history = useHistory();

  const { exportarPropostasCsv, exportarPropostasPdf } = useContext(
    AcompanhamentoPropostaContext,
  );

  const imprimirPdf = async () => {
    const obj = await exportarPropostasPdf();
    sessionStorage.setItem(
      'relatorioAcompanhamentoImpressao',
      JSON.stringify(obj),
    );

    window.open('/relatorio-acompanhamento-pdf', '_blank');
  };

  const imprimirDocumentos = () => {
    history.push('/impressao-proposta');
  };

  return (
    <Fragment>
      <div className="row d-flex justify-content-between">
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Title label="Lista de Propostas" />
        </div>

        <div className="toolbar-topo-acompanhamento">
          <div className="col-lg-12 justify-content-end">
            {checarPermissao(
              'botao',
              'botao.acompanhamento.simulacaoProposta',
              'Visualizar',
            ) && (
              <>
                <a href="/simulacao">
                  <Button
                    className="toolbar-button"
                    type="button"
                    variant="white"
                  >
                    <SimulationIcon /> SIMULAÇÃO PROPOSTA
                  </Button>
                </a>
              </>
            )}

            <span className="m-2" />
            {checarPermissao(
              'botao',
              'botao.acompanhamento.cadastrarProposta',
              'Visualizar',
            ) && (
              <>
                <a href="/pre-analise">
                  <Button
                    className="toolbar-button"
                    type="button"
                    variant="white"
                  >
                    <WriteIcon /> CADASTRAR PROPOSTA
                  </Button>
                </a>
              </>
            )}

            <span className="m-2" />
            {checarPermissao(
              'botao',
              'botao.acompanhamento.imprimirContrato',
              'Visualizar',
            ) && (
              <>
                <Button
                  className="toolbar-button"
                  type="button"
                  variant="white"
                  onClick={() => imprimirDocumentos()}
                >
                  <PrintIcon /> IMPRIMIR CONTRATO
                </Button>
              </>
            )}
          </div>
          <div className="col-lg-12 justify-content-end mt-3">
            <Button
              className="toolbar-button px-3"
              type="button"
              variant="white"
              onClick={() => imprimirPdf()}
              async={true}
            >
              <DownloadIcon /> PDF
            </Button>

            <span className="m-2" />

            <Button
              className="toolbar-button px-3"
              type="button"
              variant="white"
              onClick={() => exportarPropostasCsv()}
              async={true}
            >
              <DownloadIcon /> CSV
            </Button>

            <span className="m-2" />
            <Button
              type="button"
              variant="gray"
              className="btn-filtrar"
              onClick={() => handleShowFilter()}
            >
              <FilterIcon className="filter-icon" />
              <span>FILTRAR</span>
            </Button>
          </div>
        </div>
      </div>

      {showFilter && (
        <div className="row">
          <div className="col-lg-12">
            <Filter formik={formik} />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Toolbar;
