import React from 'react';

import './styles.scss';

function Spinner({ color, width, margin }) {
  return (
    <div className="spinner">
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect1"
      ></div>
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect2"
      ></div>
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect3"
      ></div>
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect4"
      ></div>
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect5"
      ></div>
      <div
        style={{ backgroundColor: color, width: width, margin: margin }}
        className="rect6"
      ></div>
    </div>
  );
}

export default Spinner;
