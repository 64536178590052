import React, { createContext, useEffect, useState, useCallback } from 'react';

import { listarEventos } from '../services/eventoService';
import {
  listarTarifas,
  inserirTarifa,
  editarTarifa,
} from '../services/tarifaService';

export const TarifaContext = createContext({});

const initialTarifaState = {
  id: 0,
  nome: '',
  valorFixo: 0,
  percentual: 0,
  percentualBase: null,
  eventoId: null,
  ativo: true,
  edit: '',
};

function TarifaProvider({ children }) {
  const [tarifas, setTarifas] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [tarifa, setTarifa] = useState(initialTarifaState);
  const [filterStatus, setFilterStatus] = useState('');

  const resetTarifaForm = () => {
    setTarifa(initialTarifaState);
  };

  const fetchTarifas = useCallback(async () => {
    const response = await listarTarifas();

    let responseFiltered = [];
    if (!filterStatus) {
      responseFiltered = response;
    } else {
      responseFiltered = response.filter(
        (items) => items.ativo.toString() === filterStatus.toString(),
      );
    }

    if (responseFiltered.length > 0) {
      const results = responseFiltered.map((row) => ({
        id: row.id,
        nome: row.nome,
        valorFixo: row.valorFixo,
        percentual: row.percentual,
        percentualBase: row.percentualBase,
        evento: row.evento.nome,
        eventoId: row.eventoId,
        ativo: row.ativo,
      }));

      setTarifas(results);
    }
  }, [filterStatus]);

  const fetchEventos = async () => {
    const response = await listarEventos();

    setEventos(response);
  };

  const salvarTarifa = async (tarifa) => {
    if (tarifa.id > 0) {
      const response = await editarTarifa(tarifa);

      if (!response.success) {
        return response.messages;
      }
    } else {
      const response = await inserirTarifa(tarifa);

      if (!response.success) {
        return response.messages;
      }
    }

    setTarifa(initialTarifaState);
    await fetchTarifas();
  };

  const cancelar = () => {
    setTarifa(initialTarifaState);
  };

  const setValueForTarifa = async (data) => {
    setTarifa(data);
  };

  useEffect(() => {
    fetchTarifas();
    fetchEventos();
  }, [filterStatus, fetchTarifas]);

  return (
    <TarifaContext.Provider
      value={{
        tarifas,
        eventos,
        salvarTarifa,
        tarifa,
        setTarifa,
        setValueForTarifa,
        initialTarifaState,
        cancelar,
        resetTarifaForm,
        filterStatus,
        setFilterStatus,
      }}
    >
      {children}
    </TarifaContext.Provider>
  );
}

export default TarifaProvider;
