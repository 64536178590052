import moment from 'moment';

export const columnsAlteracoes = [
  {
    Header: 'Data alteração',
    accessor: 'DataAlteracao',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Usuário',
    accessor: 'Login',
  },
  {
    Header: 'Nome do campo alterado',
    accessor: 'campoAlterado',
  },
  {
    Header: 'Valor do parâmetro anterior',
    accessor: 'valorParametroNovo',
  },
  {
    Header: 'Valor do novo parâmetro',
    accessor: 'valorParametroAntigo',
  },
];
