import swal from 'sweetalert';

import './styles.scss';

function ModalComponent(title, message, type, action = () => {}, button, closeOnClickOutside) {
  swal(title, message, type, { button, closeOnClickOutside }).then(() => {
    action();
  });

}

export default ModalComponent;
