import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Formik, Field } from 'formik';
import { Form, Switch, Input, Select } from 'formik-antd';

import InputMoney from '../../../components/InputMoney';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';

import { TarifaContext } from '../../../contexts/TarifaContext';

import validationSchema from './validationSchema';

import './style.scss';

const { Option } = Select;

const Header = ({ text }) => <Title label={`${text} de Tarifas`} />;

function TarifasForm() {
  const { salvarTarifa, tarifa } = useContext(TarifaContext);
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <Overlay active={showOverlay}>
      <Card title={<Header text={tarifa.id > 0 ? 'Edição' : 'Inclusão'} />}>
        <Formik
          initialValues={tarifa}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={async (data, { resetForm }) => {
            setShowOverlay(true);
            data.percentual = data.floatPercentual;
            data.valorFixo = data.floatValorFixo;
            const errorArray = await salvarTarifa(data);
            setShowOverlay(false);

            if (errorArray) {
              Modal(
                'Ocorreu 1 ou mais erros:',
                errorArray.toString().replace(/ *, */g, '\n\n'),
                'error',
              );
            } else {
              resetForm();
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form layout="vertical">
              <FormLayout setFieldValue={setFieldValue} values={values} />
            </Form>
          )}
        </Formik>
      </Card>
    </Overlay>
  );
}

function FormLayout({ setFieldValue, values }) {
  const { eventos, tarifa, resetTarifaForm } = useContext(TarifaContext);

  const handleReset = () => {
    resetTarifaForm();
  };

  useEffect(() => {
    if (tarifa.id > 0) {
      const fields = [
        'id',
        'nome',
        'valorFixo',
        'floatValorFixo',
        'percentual',
        'floatPercentual',
        'eventoId',
        'ativo',
        'percentualBase',
        'edit',
      ];
      fields.forEach((field) => setFieldValue(field, tarifa[field], false));
      setFieldValue('floatPercentual', fields.percentual, false);
      setFieldValue('floatValorFixo', fields.valorFixo, false);
    }
  }, [tarifa, setFieldValue]);
  return (
    <Fragment>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item name="ativo">
            <label className="mr-2">Ativo:</label>
            <Switch name="ativo" size="small" />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-5">
          <div className="form-group">
            <Form.Item name="id" label="Código">
              <Input name="id" disabled />
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-7">
          <div className="form-group">
            <Form.Item name="nome" label="Nome">
              <Input name="nome" placeholder="Nome" />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-5 col-md-5">
          <div className="form-group">
            <Form.Item name="valorFixo" label="Valor Fixo">
              <Field name="valorFixo">
                {({ field }) => (
                  <InputMoney
                    {...field}
                    placeholder="Valor fixo"
                    className="ant-input"
                    prefix="R$ "
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    maxLength={12}
                    onValueChange={(values) => {
                      setFieldValue('floatValorFixo', values.floatValue);
                    }}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-7 col-md-7">
          <div className="form-group">
            <Form.Item name="percentual" label="Valor (%)">
              <Field name="percentual">
                {({ field }) => (
                  <InputMoney
                    {...field}
                    placeholder="Valor percentual"
                    className="ant-input"
                    allowNegative={false}
                    decimalScale={4}
                    fixedDecimalScale={true}
                    maxLength={10}
                    onValueChange={(values) => {
                      setFieldValue('floatPercentual', values.floatValue);
                    }}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-5 col-md-5">
          <div className="form-group">
            <Form.Item name="eventoId" label="Evento vinculado">
              <Select 
                name="eventoId" 
                placeholder="Selecione"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {eventos.length > 0
                  ? eventos.map((evento) => (
                      <Option key={evento.id} value={evento.id}>
                        {evento.nome}
                      </Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="col-lg-7 col-md-7">
          <div className="form-group">
            <Form.Item name="percentualBase" label="Base percentual">
              <Select 
                name="percentualBase" 
                placeholder="Selecione"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value={0}>Valor financiado</Option>
                <Option value={1}>Valor da prestação</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="form-buttons-tarifa">
        <div className="row justify-content-end pr-3">
          <Button
            type="reset"
            variant="gray"
            className="btn-xs-block"
            onClick={() => handleReset()}
            style={{ minWidth: '105px' }}
          >
            CANCELAR
          </Button>

          <div className="p-2"></div>

          <Button
            type="submit"
            variant="green"
            className={tarifa.id > 0 ? 'd-none' : 'btn-xs-block'}
            style={{ minWidth: '110px' }}
          >
            <Icon variant="include" className="mr-2" />
            <span>INCLUIR</span>
          </Button>

          <Button
            type="submit"
            variant="orange"
            className={tarifa.id > 0 ? 'btn-xs-block' : 'd-none'}
            style={{ minWidth: '110px' }}
          >
            <Icon variant="save" className="mr-2" />
            <span>SALVAR</span>
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default TarifasForm;
