import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Modal } from 'antd';
import Card from '../../components/Card';
import Title from '../../components/Title';
import Button from '../../components/Button';
import Option from '../../components/Option';
import TableLogs from '../../components/TableLogs';
import { Select } from 'antd';
import { ReactComponent as IconSaveButton } from '../../assets/images/icons/save.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as SuccessOutlinedIcon } from '../../assets/images/icons/confirm-outline.svg';
import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import './styles.scss';
import TabsComponent from './Tabs';
import { CredenciadoContext } from '../../contexts/CadastroCredenciamentoContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import removeChars from '../../utils/removeChars';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { CaretRightOutlined } from '@ant-design/icons';
import { SubTipoConvenio, TipoCredenciado } from '../../utils/identificadoresFrontBack';

function DadosCredenciado({ userId, action = 'visualizacao' }) {
  const { Panel } = Collapse;
  const history = useHistory();
  const { checarPermissao } = useContext(ControleAcessoContext);
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);

  const {
    formik,
    dadosCredenciamentoContext,
    fetchInfoUsuario,
    loadContextCredenciado,
    infoUsuario,
    criarCredenciado,
    editarCredenciado,
    unidades,
    produtosRegiao,
    dadosBancoContext,
  } = useContext(CredenciadoContext);

  const [documentsErrorModalVisible, setDocumentsErrorModalVisible] =
    useState(false);
  const [regioesErrorModalVisible, setRegioesErrorModalVisible] =
    useState(false);
  const [produtosErrorModalVisible, setProdutosErrorModalVisible] =
    useState(false);
  const [visibleModalCancel, setVisibleModalCancel] = useState(false);
  const [disabledSaved, setDisabledSaved] = useState(false);

  const valuesCloneAdjust = (valuesOriginal) => {
    let valuesClone = JSON.parse(JSON.stringify(valuesOriginal));
    // Aba Dados Gerais
    valuesClone.dadosGerais.cpfCnpj = removeChars(
      ['.', '/', '-'],
      valuesClone.dadosGerais.cpfCnpj,
    );

    valuesClone.dadosGerais.unicard =
      valuesClone?.dadosGerais?.unicard || false;
    valuesClone.dadosGerais.analisePromotora =
      valuesClone?.dadosGerais?.analisePromotora || false;

    // Aba Endereço
    valuesClone.endereco.cep = removeChars(
      ['-', '.'],
      valuesClone.endereco.cep,
    );

    // Aba Contato
    valuesClone.contato = valuesClone.contato.filter(
      (item) => item.nome || item.email || item.telefone || item.tipoContato,
    );
    valuesClone.contato = valuesClone.contato.map((item) => {
      return {
        ...item,
        telefone: removeChars([')', '(', '-', ' '], item.telefone),
      };
    });

    // Aba Regiões
    delete valuesClone.regiao;
    if (
      valuesClone.regioes?.length === 1 &&
      valuesClone?.regioes[0]?.ufId === null
    ) {
      valuesClone.regioes = null;
    }

    // Aba Sócios
    if (valuesClone.socios?.length >= 1 && valuesClone.socios[0].cpf !== null) {
      valuesClone.socios = valuesClone.socios.map((item) => {
        return { ...item, cpf: removeChars(['.', '-'], item.cpf) };
      });
    } else {
      valuesClone.socios = null;
    }

    // Aba Visita

    if (valuesClone.visita.visita) {
      if (valuesClone.visita.anexos) {
        valuesClone.visita.anexos = valuesClone.visita.anexos.map((item) => {
          return { id: item?.id || null, imagemId: item.imagemId || null };
        });
      }
      let time = moment(valuesClone.visita.horaVisita).format('HH:mm:ss');
      let date = moment(valuesClone.visita.dataVisita).format('YYYYMMDD');
      if (moment(valuesClone.visita.horaVisita).isValid()) {
        valuesClone.visita.dataHora = [date, time].join(' ');
      } else {
        valuesClone.visita.dataHora = date;
      }
    } else {
      valuesClone.visita.dataHora = null;
    }
    delete valuesClone.visita.dataVisita;
    delete valuesClone.visita.horaVisita;

    if (
      valuesClone.visita?.anexos?.length === 1 &&
      valuesClone.visita?.anexos[0] === null
    ) {
      valuesClone.visita.anexos = null;
    }

    delete valuesClone.dadosGerais.dataAlteracao;
    delete valuesClone.dadosGerais.dataCadastro;

    // Aba Dados Bancários
    if (
      valuesClone?.dadosBancarios?.banco === null &&
      valuesClone?.dadosBancarios?.agencia === null &&
      valuesClone?.dadosBancarios?.agenciaDigito === null &&
      valuesClone?.dadosBancarios?.contaNumero === null &&
      valuesClone?.dadosBancarios?.conta === null &&
      valuesClone?.dadosBancarios?.tipoConta === null &&
      valuesClone?.dadosBancarios?.tipoOperacao === null &&
      valuesClone?.dadosBancarios?.tempoConta === null &&
      valuesClone?.dadosBancarios?.tipoChavePix === null &&
      valuesClone?.dadosBancarios?.chavePix === null
    ) {
      valuesClone.dadosBancarios = null;
    }

    // Aba Produtos
    if (!valuesClone?.regioes?.length) {
      valuesClone.produtos.produtos = null;
    } else if (
      valuesClone?.produtos?.produtos?.length === 1 &&
      valuesClone?.produtos?.produtos[0].id === null
    ) {
      valuesClone.produtos.produtos = null;
    } else {
      valuesClone.produtos.produtos = valuesClone.produtos.produtos.map(
        (item) => {
          return {
            ...item,
            modalidadeOpcoes: valuesClone.produtos.tipoModalidade,
          };
        },
      );
    }

    // Aba Unidades

    if (unidades.length) {
      const unidadesParsed = formataUnidades(unidades);
      valuesClone.unidades = unidadesParsed;
    } else {
      valuesClone.unidades = null;
    }

    // Aba Documentos
    if (
      valuesClone?.documentos?.length >= 1 &&
      (valuesClone?.documentos[0]?.imagemId !== null ||
        valuesClone?.documentos[0]?.id !== null)
    ) {
      valuesClone.documentos = valuesClone.documentos.map((item) => {
        return {
          imagemId: item?.imagemId || null,
          id: item?.id || null,
          tipoDocumentoCredenciado: item?.tipoDocumentoCredenciado,
        };
      });
    } else {
      valuesClone.documentos = null;
    }

    // Aba Comercial
    if (valuesClone?.inicioVigencia) {
      valuesClone.comercial.hierarquia.dataVigenciaInicial = moment(
        valuesClone.inicioVigencia,
      ).format('YYYYMMDD');
      delete valuesClone?.inicioVigencia;
    }
    if (
      userId &&
      infoUsuario?.comercial?.hierarquia?.id &&
      valuesClone?.fimVigencia
    ) {
      valuesClone.comercial.hierarquia.posicaoAnterior = {
        dataVigenciaFinal: moment(valuesClone.fimVigencia).format('YYYYMMDD'),
        id:
          infoUsuario?.comercial?.hierarquia?.niveis[
            infoUsuario?.comercial?.hierarquia?.niveis?.length - 1
          ]?.posicaoId || null,
      };
      valuesClone.comercial.hierarquia.id = null;
      valuesClone.comercial.hierarquia.dataVigenciaInicial = null;
      valuesClone.comercial.hierarquia.niveis = [];
    }
    delete valuesClone?.fimVigencia;

    if (valuesClone?.comercial?.historico) {
      delete valuesClone.comercial.historico;
    }
    if (
      !valuesClone?.comercial?.hierarquia?.id &&
      !valuesClone?.comercial?.hierarquia?.posicaoAnterior
    ) {
      valuesClone.comercial = null;
    }

    // se o usuário for do tipo convênio produtos e unidades deve ser null
    // quando subTipoCredenciado consignado privado, não deverá definir como nulo.
    if (valuesClone.dadosGerais.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
        valuesClone.dadosGerais.subTipoCredenciado !== SubTipoConvenio.CONSIGNADO_PRIVADO_ID &&
        valuesClone.dadosGerais.subTipoCredenciado !== SubTipoConvenio.CIA_ELETRICA_ID
      ) {
      valuesClone.produtos = null;
    }
    if (!valuesClone?.unidades?.length) {
      delete valuesClone.unidades;
    }
    return valuesClone;
  };

  const ajustaBancoCredenciado = (valueFormik) => {
    const { dadosGerais, dadosConsignado } = valueFormik;

    if (
      dadosGerais.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      dadosGerais.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID
    ) {
      const bancoConcorrente = dadosConsignado.bancoConcorrente.map((codigo) =>
        dadosBancoContext.find((b) => b.codigo === codigo),
      );
      valueFormik.dadosConsignado.bancoConcorrente = bancoConcorrente;
    }

    return valueFormik;
  };

  async function handleEdicao() {
    const errors = await formik
      .validateForm()
      .then((errors) => formik.setTouched({ ...errors }, true));
    formik.setTouched(errors, true);


    if(!(formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      formik?.values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID)){
        delete errors?.dadosConsignado;
      }
    if (validateOtherFields()) {
      if (!Object.keys(errors).length) {
        const errors = await formik
          .validateForm()
          .then((errors) => formik.setTouched({ ...errors }, true));
        formik.setTouched(errors, true);
        if(!(formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
          formik?.values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID)){
            delete errors?.dadosConsignado;
          }

        if (!Object.keys(errors).length) {
          let valuesClone = valuesCloneAdjust(formik.values);
          if (valuesClone?.produtos?.produtos?.length > 0) {
            valuesClone.produtos.produtos = valuesClone.produtos
            .produtos.filter(produto => produto.id !== null && produto.id !== undefined);
          }

          if (!disabledSaved) {
            setDisabledSaved(true);
            valuesClone = ajustaBancoCredenciado(valuesClone);
            editarCredenciado(valuesClone, userId)
              .then((response) => {
                return response.data[0];
              })
              .then((isSuccess) => {
                if (isSuccess) {
                  setSuccessModalVisible(true);
                }
                setDisabledSaved(false);
              })
              .catch(() => setDisabledSaved(false));
          }
        }
      }
    }
  }

  function formataUnidades(subunidades) {
    const subunidadesParsed = subunidades?.map((item) => {
      if (item.id && !item.visited) {
        return {
          unidadeId: item?.id,
          editado: item?.editado || false,
          unidades: item?.subUnidade?.length
            ? formataUnidades(item?.subUnidade)
            : [],
        };
      } else {
        if (
          userId &&
          item?.comercial?.hierarquia?.id &&
          item?.comercial?.hierarquia?.dataVigenciaFinal
        ) {
          item.comercial.hierarquia.posicaoAnterior = {
            dataVigenciaFinal: moment(
              item?.comercial?.hierarquia?.dataVigenciaFinal,
            ).format('YYYYMMDD'),
            id:
              item?.comercial?.hierarquia?.niveis[
                item?.comercial?.hierarquia?.niveis?.length - 1
              ]?.posicaoId || null,
          };

          item.comercial.hierarquia.id = null;
          item.comercial.hierarquia.dataVigenciaInicial = null;
          item.comercial.hierarquia.niveis = [];
        }
        delete item?.comercial?.hierarquia?.dataVigenciaFinal;

        let itemParsed = {
          unidadeId: item?.unidadeId || null,
          editado: item?.editado,
          dadosGerais:
            {
              ...item?.dadosGerais,
              cpfCnpj: removeChars(['-', '/', '.'], item.dadosGerais.cpfCnpj),
            } || null,
          endereco: item?.endereco || null,
          regioes: item?.regioes || null,
          produtos: item?.produtos || null,
          comercial: {
            hierarquia: item?.comercial?.hierarquia,
            historico: item?.comercial?.historico || [],
          },
          unidades: item?.subUnidade?.length
            ? formataUnidades(item?.subUnidade)
            : [],
        };

        return itemParsed;
      }
    });
    return subunidadesParsed;
  }

  function validateOtherFields() {
    if (formik?.errors?.documentos?.length) {
      setDocumentsErrorModalVisible(true);
      return false;
    }

    if (
      formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      formik?.values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID
    )
      return true;

    if (
      formik?.values?.dadosGerais?.tipoCredenciado === 0 &&
      formik?.values?.comercial?.hierarquia?.id &&
      !formik?.values?.comercial?.hierarquia?.dataVigenciaInicial
    ) {
      formik.setErrors({ comercial: 'hierarquia', ...formik?.errors });
      return false;
    }

    if (
      formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      formik?.values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID
    )
      return true;

    if (formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CORRESPONDENTE_ID)
      return true;

    if (
      formik?.values?.produtos?.tipoModalidade == null &&
      formik?.values?.produtos?.produtos?.find((item) => item.id) &&
      formik?.values?.regioes?.length
    ) {
      formik.setErrors({ produtos: 'tipoModalidade', ...formik.errors });
      return false;
    }

    if (formik?.values?.produtos?.produtos?.length) {
      let produtoErrors = [];
      formik?.values?.produtos?.produtos?.forEach((item, i) => {
        let produtoRegiaoPropriedade = produtosRegiao?.find(
          // eslint-disable-next-line
          (produto) => produto.id == item.id,
        )?.propriedade;
        if (produtoRegiaoPropriedade && produtoRegiaoPropriedade?.visualizaPropriedade && !item?.propriedade?.opcoes?.length) {
          produtoErrors.push({ propriedade: { opcoes: 'Campo obrigatório' } });
        } else {
          produtoErrors.push({});
        }
      });
      if (!!produtoErrors.find((item) => Object.keys(item).length)) {
        formik.setErrors({
          produtos: { produtos: [...produtoErrors] },
          ...formik.errors,
        });
        return false;
      }
    }
    return true;
  }

  async function handleCriarUsuario() {
    const errors = await formik
      .validateForm()
      .then((errors) => formik.setTouched({ ...errors }, true));
    formik.setTouched(errors, true);

    if(!(formik?.values?.dadosGerais?.tipoCredenciado === TipoCredenciado.CONVENIO_ID &&
      formik?.values?.dadosGerais?.subTipoCredenciado === SubTipoConvenio.CONSIGNADO_PRIVADO_ID)){
        delete errors?.dadosConsignado;
      }

      if (validateOtherFields()) {
      if (!Object.keys(errors).length) {
        let valuesClone = valuesCloneAdjust(formik.values);

        if (!disabledSaved) {
          setDisabledSaved(true);
          valuesClone = ajustaBancoCredenciado(valuesClone);
          criarCredenciado(valuesClone)
            .then((response) => {
              return response?.data[0];
            })
            .then((isSuccess) => {
              if (isSuccess) {
                setSuccessModalVisible(true);
              }
              setDisabledSaved(false);
            })
            .catch(() => setDisabledSaved(false));
        }

        if (valuesClone?.produtos?.produtos?.length > 0) {
          valuesClone.produtos.produtos = valuesClone.produtos
          .produtos.filter(produto => produto.id !== null && produto.id !== undefined);
        }

      }
    }
  }

  useEffect(() => {
    if (userId) {
      loadContextCredenciado().then(() => {
        fetchInfoUsuario(userId);
      });
    } else {
      loadContextCredenciado();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        visible={documentsErrorModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setDocumentsErrorModalVisible(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          O campo Tipo de Arquivo em Documentos é obrigatório.
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => setDocumentsErrorModalVisible(false)}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        visible={regioesErrorModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setRegioesErrorModalVisible(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          É necessário escolher ao menos uma região.
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => setRegioesErrorModalVisible(false)}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <Modal
        visible={produtosErrorModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setProdutosErrorModalVisible(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          É necessário escolher ao menos um produto.
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => setProdutosErrorModalVisible(false)}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>

      <Modal
        visible={isSuccessModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        closable={false}
        className="modal-warning"
      >
        <div className="icon">
          <SuccessOutlinedIcon width={75} />
        </div>
        <div className="text-modal">
          {userId
            ? 'Registro atualizado com sucesso!'
            : 'Cadastro realizado com sucesso!'}
        </div>
        <div className="btn-area">
          <Link to="/credenciamento">
            <Button
              variant="blue"
              onClick={() => {
                setSuccessModalVisible(false);
                history.push('/credenciamento');
              }}
              className="btn-confirm"
            >
              OK
            </Button>
          </Link>
        </div>
      </Modal>

      <Modal
        visible={visibleModalCancel}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => setVisibleModalCancel(false)}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">Deseja descartar as alterações?</div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => setVisibleModalCancel(false)}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => history.push('/credenciamento')}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>

      <Card
        className="credenciado-page-styles"
        title={<Title label="Dados do Credenciado" />}
        rightBar={
          <div
            className="card-buttons"
            style={{
              // eslint-disable-next-line
              display: action == 'visualizacao' && userId != null && 'none',
            }}
          >
            <Button
              onClick={() => {
                setVisibleModalCancel(true);
              }}
              type="button"
              variant="gray"
              className="btn-cancelar"
            >
              CANCELAR
            </Button>

            {checarPermissao(
              'botao',
              'botao.cadastro.credenciamento.salvar',
              'Visualizar',
            ) && (
              <Button
                type="button"
                variant="green"
                className="btn-salvar"
                onClick={() => {
                  if (userId) {
                    handleEdicao();
                  } else {
                    handleCriarUsuario();
                  }
                }}
                disabled={
                  userId
                    ? !checarPermissao(
                        'botao',
                        'botao.cadastro.credenciamento.salvar',
                        'Editar',
                      )
                    : !checarPermissao(
                        'botao',
                        'botao.cadastro.credenciamento.salvar',
                        'Criar',
                      )
                }
              >
                <IconSaveButton />
                SALVAR
              </Button>
            )}
            <Select
              dropdownClassName="btn-status-dropdown"
              className="btn-status"
              suffixIcon={<IconArrowSelect className="arrow-color" />}
              value={formik?.values?.statusCredenciado}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => {
                formik.setFieldValue('statusCredenciado', value);
              }}
            >
              {dadosCredenciamentoContext?.status?.map((item) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.nome}
                  </Option>
                );
              })}
            </Select>
          </div>
        }
      >
        <div className="body-content">
          <div className="row">
            <div className="col-lg-12 col-tabs dados-credenciado-page">
              <TabsComponent
                action={action}
                userId={userId}
                infoUsuario={infoUsuario}
              />
            </div>
          </div>
        </div>
        {userId && (
          <div className="table-logs-module">
            <Collapse
              expandIconPosition="left"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  rotate={isActive ? 90 : 0}
                  style={{ color: '#ff7817' }}
                />
              )}
              className="mt-4"
            >
              <Panel
                header="Histórico de Alteração dos Dados do Credenciado"
                className="table-logs-panel"
              >
                <TableLogs idUsuario={userId} tipoLog="Credenciado" />
              </Panel>
            </Collapse>
          </div>
        )}
      </Card>
    </>
  );
}
export default DadosCredenciado;
