import React, { useEffect, useContext } from 'react';

import { Form } from 'formik-antd';
import { Formik } from 'formik';
import './styles.scss';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as FileClipIcon } from '../../../assets/images/icons/file-clip.svg';

import DocumentosFormUpload from './DocumentosFormUpload';

import { Tooltip } from 'antd';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';

function DocumentosForm({ formik, typeDocumetsContext, isReadOnly }) {

  const { fileListDocumentos, setFileListDocumentos } =
    useContext(CredenciadoContext);

  useEffect(() => {
    formik.setFieldValue('documentos', fileListDocumentos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileListDocumentos]);

  return (
    <>
      <Formik>
        {({ setFieldValue, values, resetForm, handleSubmit }) => (
          <Form layout="vertical">
            <div className="row tab-documentos-credenciado">
              <div className="col-lg-12 item-dados">
                <Form.Item name="anexos" className="anexos-documentos">
                  <FileClipIcon />
                  <label>Anexo de Documentos</label>
                  <Tooltip
                    placement="topLeft"
                    className="tooltip-info"
                    open
                    overlayClassName="tooltip-anexos-documentos-left"
                    color="#435361"
                    title={
                      'Os arquivos devem estar no formato JPEG, PNG ou PDF'
                    }
                  >
                    <BulletInfo className="bullet-info-icon" />
                  </Tooltip>
                  <div className="form-upload-documentos-credenciamento">
                    <DocumentosFormUpload
                      fileList={fileListDocumentos}
                      setFileList={setFileListDocumentos}
                      typeDocumetsContext={typeDocumetsContext}
                      disabled={isReadOnly}
                      formik={formik}
                    />
                  </div>
                </Form.Item>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DocumentosForm;
