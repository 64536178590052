import React, { useContext } from 'react';
import { Menu, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import Button from '../../../components/Button';
import DropdownButton from '../../../components/DropdownButton';
import ConversationMessages from '../../../components/ConversationMessages';
import Message from '../../../components/Modal';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import { ReactComponent as SaveIcon } from '../../../assets/images/icons/save.svg';
import { ReactComponent as IconChatExterno } from '../../../assets/images/icons/icon-chat-externo.svg';
import { ReactComponent as IconChatInterno } from '../../../assets/images/icons/icon-chat-interno.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/icons/back.svg';
import { ReactComponent as ConfigIcon } from '../../../assets/images/icons/config.svg';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { isBoolean } from 'lodash';
import swal from 'sweetalert';
import { postCodigoBarrasNeoEnergia } from '../../../services/analiseMesaService';

const { Option } = Select;

export default function Buttons() {
  const {
    formik,
    disableDecisao,
    setIsModalBlacklistVisible,
    setIsModalMotivosVisible,
    contexto,
    mensagens,
    sendMensagem,
    proposta,
    anotacoes,
    sendAnotacao,
    setDecisaoSelecionada,
    situacaoId,
    setSituacaoId,
    setSituacaoPendencia,
    arquivos,
    setIsDecisao,
    handleEnvioFormulario,
    setTentativaMudancaSituacaoAprovado,
    debitosConveniada,
    reloadSituacaoAprovado,
    setReloadSituacaoAprovado,
    permiteSalvar,
    etapaAtualProposta,
  } = useContext(AnaliseMesaContext);
  const history = useHistory();
  const { checarPermissao } = useContext(ControleAcessoContext);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const telaAnterior =
    // eslint-disable-next-line
    query.get('origem') == 650
      ? '/relatorio-esteira-credito'
      : '/analise-proposta';

  const voltarParaTelaAnterior = () => {
    localStorage.setItem('paginaAberta', false);
    history.push(telaAnterior);
  };

  const etapaSelecionadaPropostaNome = (contexto?.etapasProposta?.find(
    (etapa) => etapa?.id === situacaoId,
  ))?.nome


  const submitFormik = async () => {
    const LUZ_EM_DIA = 12;
    const existeFaturaComDebitoConsideradoSemCodigoBarra =
      formik.values?.historicoCia?.debitosConveniada?.some(
        value => (value.descontar && !value.codigoBarras)
      )

    if (arquivos.find((item) => item.documentoId === null)) {
      Message('', 'Selecione o tipo de cada documento inserido', 'warning');
    } else {
      if (!Object.keys(formik.errors).length) {
        formik.values.anexo = arquivos;

        if (!permiteSalvar()) return;

        if (
          etapaAtualProposta === "Aguard. Averbação" &&
          etapaSelecionadaPropostaNome === "Fila Pagamento" &&
          proposta.operacao.produtoNome === 'CP Auto'
        ) {
          const response = await swal({
            text: 'Nova tentativa de inclusão automática de Gravame, deseja continuar?',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar']
          })
          if (response) {
            return handleEnvioFormulario();
          }
        } else if (
          !!formik.values?.operacao?.convenioGrupoNeoEnergia &&
          formik.values?.operacao?.produtoId === LUZ_EM_DIA &&
          existeFaturaComDebitoConsideradoSemCodigoBarra &&
          etapaSelecionadaPropostaNome === 'Aprovado'
        ) {
          const clienteUC = formik.values?.operacao?.dadosAdicionais?.find(
            (value) => !!value.flagChaveUc,
          )?.valor;

          const debitosConveniadasSemCodigoBarras =
            formik.values?.historicoCia?.debitosConveniada?.filter(
              (value) => (value.descontar && !value.codigoBarras)
            )

          const debitosConveniadaIdentificadorFatura = (debitosConveniadasSemCodigoBarras || [])
            .reduce((result, d) => {
              if (d.identificadorFatura && !d.codigoBarras) {
                result.push({
                  identificadorFatura: d.identificadorFatura,
                  descontoConvenio: d.descontoConvenio
                });
              }
              return result;
            }, []);

          if (debitosConveniadaIdentificadorFatura.length > 0) {
            const response = await postCodigoBarrasNeoEnergia({
              debitosConveniada: debitosConveniadaIdentificadorFatura,
              propostaId: formik.values?.id,
              convenio: formik?.values?.operacao?.convenioNome,
              clienteUC
            });

            if (response.data) {
              const debitosConveniadaComCodigoBarras = response?.data.debitosConveniada;

              const debitosConveniadaAtualizado = debitosConveniada.map(debito => {
                if (debito.identificadorFatura) {
                  const debitoAtualizado = debitosConveniadaComCodigoBarras.find(
                    d => d.identificadorFatura === debito.identificadorFatura
                  );
                  if (debitoAtualizado) {
                    debito.codigoBarras = debitoAtualizado.codigoBarras
                  }
                }
                return debito;
              });
              formik.setFieldValue("historicoCia.debitosConveniada", debitosConveniadaAtualizado);
              Message("", "Código de barras gerados com sucesso!", "warning", () => {
                handleEnvioFormulario();
              })
            } else {
              Message("", response?.messages?.join("\n"), "warning");
              return;
            }
          } else {
            handleEnvioFormulario();
          }

        } else {
          handleEnvioFormulario();
        }
      } else {
        formik.setTouched(formik.errors, true);
        Message('', 'Verifique os campos inválidos', 'warning');
      }
    }
  };

  const handleChangeSituacao = (situacao) => {
    setDecisaoSelecionada(null);

    const changedDataDadosAdicionais = JSON.parse(
      JSON.stringify(formik.values),
    );

    const situacaoObjeto = contexto?.etapasProposta?.find(
      (etapa) => etapa.id === situacao,
    );
    const abaObjeto = contexto?.abasAcessiveis?.find(
      (aba) => aba.Tag === 'debitosConveniada',
    );
    const existeDebitoSemValidacao = debitosConveniada?.find(
      (debito) =>
        debito.analistaId === null &&
        debito.existeComprovante
    );
    const isDadosAdicionaisSemPreencher =
      changedDataDadosAdicionais.operacao.dadosAdicionais?.find(
        (item) => !item.valor,
      );

    if (
      abaObjeto &&
      situacaoObjeto.nome === 'Aprovado' &&
      (!isBoolean(formik?.values?.historicoCia?.fornecimento) ||
        !isBoolean(formik?.values?.historicoCia?.titularidade) ||
        existeDebitoSemValidacao ||
        isDadosAdicionaisSemPreencher)
    ) {
      setTentativaMudancaSituacaoAprovado(true);
      setReloadSituacaoAprovado(!reloadSituacaoAprovado);
      return;
    }
    setTentativaMudancaSituacaoAprovado(false);

    setSituacaoId(situacao);

    if (situacaoObjeto?.ExibeModalMotivos) {
      setSituacaoPendencia(situacao);
      setIsModalMotivosVisible(true);
    }
  };

  const menuDecisoes = (
    <Menu>
      {contexto?.decisoes?.map((decisao) => (
        <Menu.Item
          key={decisao.id}
          onClick={() => {
            setSituacaoPendencia(null);
            setDecisaoSelecionada(decisao.id);
            setIsModalMotivosVisible(true);
            setIsDecisao(true);
          }}
        >
          {decisao.nome}
        </Menu.Item>
      ))}
    </Menu>
  );


  return (
    <div className="buttons-desk-container">
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.alterarEtapa',
        'Visualizar',
      ) && (
          <>
            <Select
              suffixIcon={<IconArrowSelect className="arrow-color" />}
              value={situacaoId}
              onSelect={handleChangeSituacao}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={
                checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.alterarEtapa',
                  'Editar',
                )
                  ? contexto?.bloqueiaAcessoSimultaneo
                    ? true
                    : disableDecisao
                  : true
              }
            >
              {contexto?.etapasProposta?.map((etapa) => (
                <Option key={etapa.id} value={etapa.id}>
                  {etapa.nome.toUpperCase()}
                </Option>
              ))}
            </Select>
          </>
        )}
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.salvarParecer',
        'Visualizar',
      ) && (
          <>
            <DropdownButton
              variant="green"
              className="btn-xs-block btn-salvar"
              onClick={() => {
                permiteSalvar();
                setTentativaMudancaSituacaoAprovado(false);
                setIsDecisao(false);
                submitFormik();
              }}
              trigger={['click']}
              disabled={
                (contexto?.bloqueiaAcessoSimultaneo ? true : disableDecisao) ||
                !checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.salvarParecer',
                  'Editar',
                )
              }
              icon={<IconArrowSelect className="arrow-color" />}
              overlay={menuDecisoes}
              type="button"
            >
              <SaveIcon style={{ marginRight: 10 }} />
              SALVAR
            </DropdownButton>
          </>
        )}
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.blocklist',
        'Visualizar',
      ) && (
          <>
            <Button
              onClick={() => {
                setIsModalBlacklistVisible(true);
              }}
              type="button"
              variant="brown"
              className="btn-xs-block"
              disabled={
                !checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.blocklist',
                  'Criar',
                )
              }
            >
              <ConfigIcon
                className="config-icon-svg"
                style={{ marginRight: 10 }}
              />
              <span>Blocklist</span>
            </Button>
          </>
        )}
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.externo',
        'Visualizar',
      ) && (
          <>
            <ConversationMessages
              classe="ant-tooltip-analise-credito"
              mode="tooltip"
              listMessages={mensagens}
              placementTooltip="bottomRight"
              type="chat"
              sendMessage={sendMensagem}
              proposta={proposta}
              disabled={
                checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.externo',
                  'Criar',
                )
                  ? contexto?.bloqueiaAcessoSimultaneo
                  : true
              }
              origem={2}
            >
              <Button
                type="button"
                variant="gray"
                className="btn-xs-block btn-externo"
                disabled={
                  !checarPermissao(
                    'botao',
                    'botao.analiseMesaCredito.externo',
                    'Criar',
                  )
                }
              >
                <IconChatExterno style={{ marginRight: 10 }} />
                EXTERNO
              </Button>
            </ConversationMessages>
          </>
        )}
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.interno',
        'Visualizar',
      ) && (
          <>
            <ConversationMessages
              classe="ant-tooltip-analise-credito"
              title="Anotações"
              mode="tooltip"
              listMessages={anotacoes}
              sendMessage={sendAnotacao}
              placementTooltip="bottomLeft"
              proposta={proposta}
              disabled={
                !checarPermissao(
                  'botao',
                  'botao.analiseMesaCredito.interno',
                  'Criar',
                )
              }
              origem={2}
              externo={false}
            >
              <Button
                type="button"
                variant="gray"
                className="btn-xs-block btn-interno"
                disabled={
                  !checarPermissao(
                    'botao',
                    'botao.analiseMesaCredito.interno',
                    'Criar',
                  )
                }
              >
                <IconChatInterno style={{ marginRight: 10 }} />
                INTERNO
              </Button>
            </ConversationMessages>
          </>
        )}
      <Button
        type="button"
        variant="orange"
        className="btn-xs-block"
        onClick={() => voltarParaTelaAnterior()}
      >
        <BackIcon />
      </Button>
    </div>
  );
}
