import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import Table from '../../components/BasicTable';
import Toolbar from './toolbar';
import Button from '../../components/Button';

import AcompanhamentoColumns from './columns';
import { Modal, Input } from 'antd';

import { ReactComponent as IconExclamationCircle } from '../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import { ReactComponent as IconCloseModal } from '../../assets/images/icons/X-close-tooltip.svg';

import { AcompanhamentoPropostaContext } from '../../contexts/AcompanhamentoPropostaContext';
import { FormikProvider } from 'formik';

import { Upload } from 'antd';

import {
  getImagemAnexoReanalise,
} from '../../services/imagemService';



import UploadItem from '../../components/UploadItems';
import Message from '../../components/Modal';
import Overlay from '../../components/Overlay';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import ModalComponent from '../../components/Modal';
import { convertBase64ToBlob } from '../../utils/convertBase64toBlob';

const { Panel } = Collapse;

function PropostasList() {
  const {
    propostas,
    fetchPropostas,
    updateList,
    total,
    loading,
    setLoading,
    pageCount,
    filters,
    putModalidade,
    tempoRefresh,
    reanaliseProposta,
    formik,
    carregarImagem,
    pageIndex,
    setPageIndex,
    getLinkAnexoHistoricoReanalise,
    search
  } = useContext(AcompanhamentoPropostaContext);

  const [modalReanaliseVisible, setModalReanaliseVisible] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [clickedModalMotivo, setClickedModalMotivo] = useState(false);
  const [propostaId, setPropostaId] = useState(null);
  const [msgError, setMsgError] = useState(false);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [loadingFile, setLoadingFile] = useState(false);
  const [arquivos, setArquivos] = useState([]);
  const [historicoReanalise, setHistoricoReanalise] = useState([]);
  const [blobPdf, setBlobPdf] = useState(null);

  const handleReanalisarProposta = async () => {
    if (motivo !== '') {
      setModalReanaliseVisible(false);
      if (propostaId) {
        setLoading(true);
        const response = await reanaliseProposta(
          propostaId,
          motivo,
          arquivos.map((item) => {
            return item.imagemId;
          }),
        ).catch(() => {
          setLoading(false);
        });
        if (response?.data) {
          ModalComponent(
            '',
            'Proposta enviada para reanálise com sucesso',
            'success',
          );
        }
        setLoading(false);
      }
    } else {
      setMsgError(true);
    }
  };

  const uploadButton = (
    <div className="action-anexar">
      <i className="fa fa-plus"></i>
      <div>Clique para anexar</div>
    </div>
  );

  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);

    let anexo;

    if (file.link) anexo = await getLinkAnexoHistoricoReanalise(file.link);
    else if (file.id) anexo = await getImagemAnexoReanalise(file.id);
    if (file.link || file.id) {
      setLoadingFile(false);
      const { conteudo } = anexo?.data;

      if (conteudo) {
        file.type = conteudo.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(conteudo);
        setPreviewVisible(true);
      }
    } else if (file.url) {
      setLoadingFile(false);
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(file.url);
      setPreviewVisible(true);
    }
  };

  const customUploadRender = (item, file) => {
    return (
      <>
        <div className="icone-arquivo">
          {UploadItem(file, handlePreview, handleRemove)}
        </div>
      </>
    );
  };

  const customUploadRenderHistorico = (item, file) => {
    return (
      <>
        <div className="icone-arquivo">
          {UploadItem(file, handlePreview, null, handleDownload)}
        </div>
      </>
    );
  };

  const handleRemove = (file) => {
    let newFileList = [];

    if (file.imagemId) {
      newFileList = arquivos.filter(
        (fileRemove) => fileRemove.imagemId !== file.imagemId,
      );
    }
    setArquivos(newFileList);
  };

  const handleDownload = async (file) => {
    let anexo;

    if (file.link) anexo = await getLinkAnexoHistoricoReanalise(file.link);
    else if (file.id) anexo = await getImagemAnexoReanalise(file.id);

    if (file.link || file.id) {
      const { conteudo } = anexo?.data;
      const link = document.createElement('a');
      link.href = conteudo;
      link.setAttribute('download', anexo?.data?.imagemId);
      document.body.appendChild(link);
      link.click();
    }
  };

  const beforeUpload = (file) => {
    file.status = '';
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg'
    ) {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
        'warning',
      );
      file.status = 'removed';
    }
    return false;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = async (options) => {
    const { file } = options;

    if (file?.status !== 'removed') {
      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const fileURL = await getBase64(file);
          const { data } = await carregarImagem(temp);
          const { dataHora, id } = data;
          const newFile = {
            dataHora: dataHora,
            documentoId: null,
            imagemId: id,
            url: fileURL,
          };
          setArquivos((arquivos) => [...arquivos, newFile]);
        } catch (err) {
          console.log(`Falha no upload`, err);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  useEffect(() => {
    if (motivo === '' && clickedModalMotivo) {
      setMsgError(true);
    } else {
      setMsgError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motivo]);

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobPdf(blobURL);
    } else {
      setBlobPdf(null);
    }
  }, [previewImage, fileType]);

  return (
    <>
      <FormikProvider value={formik}>
        <Table
          columns={AcompanhamentoColumns(
            putModalidade,
            setModalReanaliseVisible,
            setPropostaId,
            setHistoricoReanalise,
            setMotivo,
            setClickedModalMotivo,
            setArquivos,
          )}
          data={propostas}
          pageCount={pageCount}
          loading={loading}
          onLoadData={fetchPropostas}
          total={total}
          header={<Toolbar formik={formik} />}
          filter={filters}
          timeRefresh={tempoRefresh}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          search={search}
          onSearch={true}
          updateList={updateList}
        />
      </FormikProvider>
      <Modal
        visible={modalReanaliseVisible}
        onCancel={() => setModalReanaliseVisible(false)}
        centered
        footer={null}
        width={475}
        closeIcon={<IconCloseModal width={13} height={15.6} />}
      >
        <div className="modal-reanalise">
          <div className="icon">
            <IconExclamationCircle width={80} />
          </div>
          <h1>Confirmar reanálise?</h1>
          <label>Motivo</label>
          <Input.TextArea
            placeholder="Informe o motivo da solicitação de reanálise"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            className={`txta-motivo ${msgError && 'error-txta'}`}
            onClick={() => setClickedModalMotivo(true)}
          />
          {msgError && (
            <div className="ant-form-item-explain-error">
              O motivo é obrigatório
            </div>
          )}
          <label className="label-anexo">Anexos</label>
          <div>
            <Upload
              multiple={true}
              listType="picture-card"
              className={'proposta-credito-uploads'}
              fileList={arquivos}
              onPreview={handlePreview}
              itemRender={customUploadRender}
              beforeUpload={beforeUpload}
              onChange={uploadImage}
              onRemove={handleRemove}
            >
              {uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              destroyOnClose={true}
              footer={null}
              className={'modal-preview-upload ' + fileType.replace('/', '')}
              onCancel={handleCancel}
            >
              <Overlay active={loadingFile}>
                {!loadingFile && (
                  <div>
                    {fileType === 'application/pdf' && (
                      <object
                        data={(blobPdf ?? previewImage) + '#toolbar=0'}
                        type={fileType}
                        width="100%"
                        height="100%"
                        aria-label="Upload File"
                      ></object>
                    )}
                    {fileType !== 'application/pdf' && (
                      <img src={previewImage} alt="Documento"></img>
                    )}
                  </div>
                )}
              </Overlay>
            </Modal>
            <div className="collapse-historicos">
              {historicoReanalise?.length > 0 && (
                <label className="label-historico">
                  Histórico de Reanálise
                </label>
              )}
              <Collapse
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{ color: '#ff7817' }}
                  />
                )}
              >
                {historicoReanalise?.length > 0 &&
                  historicoReanalise?.map((item) => {
                    return (
                      <Panel
                        header={`${item.login} - ${moment(item.dataHora).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}`}
                      >
                        <label>Motivo</label>
                        <div className="tabelaListagem">
                          <Input.TextArea
                            placeholder="Informe o motivo da solicitação de reanálise"
                            value={item.motivo}
                            className="txta-motivo"
                            readOnly={true}
                          />
                        </div>
                        <label>Anexos</label>
                        <div>
                          <Upload
                            multiple={true}
                            listType="picture-card"
                            className={'proposta-credito-uploads'}
                            fileList={item.anexos}
                            itemRender={customUploadRenderHistorico}
                          ></Upload>
                        </div>
                      </Panel>
                    );
                  })}
              </Collapse>
            </div>
          </div>
          <div className="btn-confirmar-container">
            <Button
              variant="blue"
              onClick={() => handleReanalisarProposta()}
              className="btn-confirmar-reanalise"
            >
              Confirmar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PropostasList;
