import React from 'react';
import Button from '../Button';

import './styles.scss';

import { ReactComponent as ReloadIcon } from '../../assets/images/icons/reload-dashed.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/icons/info.svg';

function CardProduto({ produto, selecionarProduto, disabled = false }) {
  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  function checkProduct(produto) {
    return (
      produto.nome !== 'Energia' &&
      produto.nome !== 'CDC Energia' &&
      produto?.visualizaLimiteCard
    );
  }

  return (
    <div className={`product-card ${produto.id === 10 && 'cp-refin'}`}>
      {produto?.aprovacaoFacilitada && (
        <div className="topo-aprovacao">
          <div className="aprov-facilitada">
            {produto.aprovacaoFacilitada} <InfoIcon className="info-icon" />{' '}
          </div>
        </div>
      )}
      <div className="title-card">
        <div className="product-name">{produto.nome}</div>
      </div>

      {checkProduct(produto) && (
        <div className="value">{`${numberFormat(
          produto.valorContingencia,
        )} em até ${produto.prazoContingencia}x`}</div>
      )}

      <div className="value-button">
        <Button
          className="btn-orange"
          onClick={selecionarProduto}
          disabled={disabled}
          variant="orange"
        >
          <div>SIMULAR</div> <ReloadIcon className="reload-icon" />
        </Button>
      </div>
    </div>
  );
}

export default CardProduto;
