import React, { useContext } from 'react';

import Button from '../../../components/Button';

import { CorrespondenteContext } from '../../../contexts/CorrespondenteContext';

import { ReactComponent as UserIcon } from '../../../assets/images/icons/client-circle.svg';
import { ReactComponent as SaveIcon } from '../../../assets/images/icons/save.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/icons/clock-share.svg';
import { ReactComponent as UploadIcon } from '../../../assets/images/icons/upload.svg';

function AprovacaoForm() {
  const { setShowUpload } = useContext(CorrespondenteContext);

  return (
    <div className="toolbar-container">
      <div>
        <div className="d-flex align-items-center">
          <UserIcon height="100%" />
          <span style={{ fontSize: '16px', fontWeight: 600, marginLeft: 5 }}>
            Dados do correspondente selecionado
          </span>
        </div>
      </div>

      <div>
        <div className="buttons-container">
          <Button type="button" variant="green" className="btn-xs-block">
            <SaveIcon style={{ marginRight: 10 }} />
            <span>Salvar</span>
          </Button>

          <span className="p-1"></span>

          <Button type="button" variant="blue">
            <span>Em Análise</span>
            <i className="fa fa-angle-down ml-3" />
          </Button>

          <span className="p-1"></span>

          <Button type="button" variant="gray">
            <ClockIcon style={{ marginRight: 10 }} />
            <span>Externo</span>
          </Button>

          <span className="p-1"></span>

          <Button
            type="button"
            variant="gray"
            onClick={() => setShowUpload(true)}
          >
            <UploadIcon style={{ marginRight: 10 }} />
            <span>Anexar</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AprovacaoForm;
