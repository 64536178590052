import React, { createContext, useState, useCallback, useEffect } from 'react';
import {
  inserirTelefone,
  editarTelefone,
  listarTelefones,
  listarMotivos,
} from '../services/blocklistTelefoneService';

export const BlocklistTelefoneContext = createContext({});

const initialTelefoneBlocklistState = {
  id: 0,
  telefone: '',
  tipo: null,
  motivoId: null,
  descricaoInclusao: '',
  descricaoExclusao: '',
  inclusao: '',
  exclusao: '',
  usuarioInclusao: '',
  usuarioExclusao: '',
};

function BlockListTelefoneProvider({ children }) {
  const [listagemTelefones, setListagemTelefones] = useState([]);
  const [totalItensLista, setTotalItensLista] = useState(0);
  const [pageCountLista, setPageCountLista] = useState(0);
  const [loadingLista, setLoadingLista] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [telefoneBlocklist, setTelefoneBlocklist] = useState(
    initialTelefoneBlocklistState,
  );
  const [filter, setFilter] = useState({ filterStatus: true });
  const [phone, setPhone] = useState('');

  const resetTelefoneBlocklistForm = () => {
    setTelefoneBlocklist(initialTelefoneBlocklistState);
  };

  const fetchListagemTelefones = useCallback(async (props) => {
    setLoadingLista(true);

    const page = props?.pageIndex + 1;
    let response = []

    if(props?.filter?.filtroDinamico || props?.filter?.filtroDinamico  === ''){
        response = await listarTelefones({
        filtroTelefone: props?.filter?.filtroDinamico,
        filtroAtivos: props?.filter?.filterStatus,
        ordenacao: props?.sortBy[0]?.id,
        ordenacaoAsc: props?.sortBy[0]?.desc,
        pagina: page || 1,
      });
    }

    if(!props) {
      response = await listarTelefones({});
    }

    if (response?.data?.itens) {
      setListagemTelefones(response?.data?.itens);
    } else {
      setListagemTelefones([]);
    }

    setTotalItensLista(response?.data?.total);
    setPageCountLista(response?.data?.totalPages);

    setLoadingLista(false);
  }, []);

  const fetchMotivos = async () => {
    const response = await listarMotivos();
    setMotivos(response?.data?.motivo);
  };

  const salvarBlocklistTelefone = async (telefoneBlocklist) => {
    let response;

    if (telefoneBlocklist.id > 0) {
      response = await editarTelefone(telefoneBlocklist);
    } else {
      response = await inserirTelefone(telefoneBlocklist);
    }

    setTelefoneBlocklist(initialTelefoneBlocklistState);
    await fetchListagemTelefones();

    return response;
  };

  const setValueForTelefoneBlocklist = async (data) => {
    setTelefoneBlocklist(data);
  };

  useEffect(() => {
    fetchMotivos();
  }, []);

  return (
    <BlocklistTelefoneContext.Provider
      value={{
        listagemTelefones,
        totalItensLista,
        pageCountLista,
        loadingLista,
        filter,
        setListagemTelefones,
        setFilter,
        fetchListagemTelefones,
        salvarBlocklistTelefone,
        telefoneBlocklist,
        motivos,
        resetTelefoneBlocklistForm,
        setValueForTelefoneBlocklist,
        phone, 
        setPhone,
      }}
    >
      {children}
    </BlocklistTelefoneContext.Provider>
  );
}

export default BlockListTelefoneProvider;
