import React, { useContext, useRef, useEffect } from 'react';
import NumberField from 'react-number-format';

import { Form, Select, Input } from 'formik-antd';
import { Switch } from 'antd';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import Option from '../../../components/Option';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import SelectSearch from '../../../components/SearchSelect';

function ConsignadoPrivadoForm({ formik, dadosBancariosContext, isReadOnly }) {
  const {
    dadosCredenciamentoContext,
    populaRegioes,
    userId,
    averbadoraContext,
  } = useContext(CredenciadoContext);
  const { setFieldValue, values, setFieldTouched, setFieldError } = formik;

  const ref = useRef();

  const handleFocus = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  const handleDays = () => {
    const array = [];
    for (let i = 1; i <= 30; i++) array.push(String(i));
    return array;
  };

  const handleCalculoMargem = (e) => {
    let value = parseFloat(e.target.value.split(',').join('.'));

    if (isNaN(value)) {
      value = null;
    }

    setFieldValue(
      'dadosConsignado.percentualCalculoMargem',
      value,
    );

    setFieldTouched('dadosConsignado.percentualCalculoMargem')
  }
  
  const getAverbadoraName = (id) => {
    const averbadora = averbadoraContext.find(item => item.id === Number(id));
    return averbadora ? averbadora.nome : null;
  };

  useEffect(() => {
    if (getAverbadoraName(values?.dadosConsignado?.averbadora) !== "E.Consig") {
      setFieldValue('dadosConsignado.codigoConvenio', '');
      setFieldValue('dadosConsignado.codigoConvenioReserva', '');
      setFieldError('dadosConsignado.codigoConvenio', undefined);
      setFieldError('dadosConsignado.codigoConvenioReserva', undefined);
    }
  }, [values.dadosConsignado.averbadora, setFieldValue, setFieldError]);

  return (
    <>
      <div className="col-tab-dados-gerais tab-consignado-privado">
        <div className="form-row">
          <div className="col-lg-12 col-switchs">
            <Form.Item name="dadosConsignado.restrito">
              <label>
                <Switch
                  size="small"
                  className="switch-red-if-off"
                  checked={values?.dadosConsignado?.restrito}
                  onChange={(value) => {
                    populaRegioes();
                    setFieldValue('dadosConsignado.restrito', value);
                  }}
                  disabled={isReadOnly}
                />
                Conveniada Restrita
              </label>
            </Form.Item>
          </div>
        </div>
        <div className="form-row" style={{ marginBottom: '10px', paddingInline: '0px' }}>
          <div className="col-3">
            <Form.Item
              name="dadosConsignado.diaCorte"
              label="Dia de Corte"
            >
              <SelectSearch
                name="dadosConsignado.diaCorte"
                placeholder="0"
                showSearch
                allowClear
                onChange={(value) =>
                  setFieldValue('dadosConsignado.diaCorte', value)
                }
                value={values?.dadosConsignado?.diaCorte}
                disabled={isReadOnly}
              >
                {handleDays().map((day) => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item
              name="dadosConsignado.diaVencimento"
              label="Dia de Vencimento"
            >
              <SelectSearch
                name="dadosConsignado.diaVencimento"
                placeholder="0"
                showSearch
                allowClear
                onChange={(value) =>
                  setFieldValue('dadosConsignado.diaVencimento', value)
                }
                value={values?.dadosConsignado?.diaVencimento}
                disabled={isReadOnly}
              >
                {handleDays().map((day) => (
                  <Option key={day} value={day}>
                    {day}
                  </Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item name="dadosConsignado.turnOver" label="Turnover">
              <div className="percentage">
                <NumberField
                  name="juros"
                  className="ant-input percentage-numberfield"
                  prefix=""
                  allowNegative={false}
                  decimalScale={2}
                  value={values?.dadosConsignado?.turnOver}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  style={{ textAlign: 'right' }}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value.split(',').join('.'));

                    if (isNaN(value)) {
                      value = null;
                    }

                    setFieldValue('dadosConsignado.turnOver', value);
                  }}
                  placeholder="0.00"
                  disabled={isReadOnly}
                />
                <span
                  style={{
                    color:
                      values?.dadosConsignado?.turnOver ||
                        values?.dadosConsignado?.turnOver === 0
                        ? 'black'
                        : '#aaa',
                  }}
                >
                  %
                </span>
              </div>
            </Form.Item>
          </div>
          <div className="col-3">
            <Form.Item
              name="dadosConsignado.convenioRiscoId"
              label={'Risco'}
            >
              <Select
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                value={values?.dadosConsignado?.convenioRiscoId}
                onChange={(value) => {
                  setFieldValue('dadosConsignado.convenioRiscoId', value);
                }}
                disabled={isReadOnly}
              >
                {dadosCredenciamentoContext?.convenioRisco?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="form-row" style={{ paddingInline: '0px' }} >
          <div className="col-3" >
            <Form.Item
              name="dadosConsignado.quantidadeVidas"
              label={'Quantidade de Vidas'}
            >
              <NumberField
                type="text"
                className="ant-input"
                name="dadosConsignado.quantidadeVidas"
                placeholder="0"
                decimalScale={0}
                allowNegative={false}
                value={values?.dadosConsignado?.quantidadeVidas}
                onChange={(e) => {
                  setFieldValue(
                    'dadosConsignado.quantidadeVidas',
                    e.target.value,
                  );
                }}
                style={{ textAlign: 'right' }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </div>
          <div className="col">
            <Form.Item
              name="dadosConsignado.percentualCalculoMargem"
              label="% Cálculo de Margem"
            >
              <div
                className="percentage"
              >
                <NumberField
                  name="juros"
                  className="ant-input percentage-numberfield"
                  prefix=""
                  allowNegative={false}
                  decimalScale={2}
                  value={values?.dadosConsignado?.percentualCalculoMargem}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  style={{ textAlign: 'right' }}
                  onChange={handleCalculoMargem}
                  onBlur={handleCalculoMargem}
                  placeholder="0.00"
                  disabled={isReadOnly}
                  getInputRef={ref}
                />
                <span
                  style={{
                    color:
                      values?.dadosConsignado?.percentualCalculoMargem ||
                        values?.dadosConsignado?.percentualCalculoMargem === 0
                        ? 'black'
                        : '#aaa',
                  }}
                  onClick={handleFocus}
                >
                  %
                </span>
              </div>
            </Form.Item>
          </div>
          <div
            className="col-6 select-banco-concorrente"
          >
            <Form.Item
              label="Bancos Concorrentes"
              name="dadosConsignado.bancoConcorrente"
            >
              <SelectSearch
                name="dadosConsignado.bancoConcorrente"
                mode="multiple"
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => {
                  setFieldValue('dadosConsignado.bancoConcorrente', value);
                }}
                value={values?.dadosConsignado?.bancoConcorrente}
                disabled={isReadOnly}
              >
                {dadosBancariosContext?.banco?.map((item) => (
                  <Option key={item.codigo} value={item.codigo}>
                    {`${item.codigo} - ${item.nome}`}
                  </Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </div>
        </div>
        <div className='form-row' style={{ marginTop: '10px', paddingInline: '0px' }}>
          <div className='col-3'
          >
            <Form.Item label="Averbadora" name="dadosConsignado.averbadora">
              <SelectSearch
                placeholder="Selecione"
                onChange={(value) => {
                  setFieldValue('dadosConsignado.averbadora', value)
                  setFieldValue('dadosConsignado.codigoConvenio', '')
                  setFieldError('dadosConsignado.codigoConvenioReserva', '')
                }
                }
                value={values?.dadosConsignado?.averbadora}
                allowClear
                disabled={userId || isReadOnly}
              >
                {dadosCredenciamentoContext?.averbadora?.map((item) => (
                  <Option key={item?.id} value={item?.id}>{item?.nome}</Option>
                ))}
              </SelectSearch>
            </Form.Item>
          </div>
          {
            getAverbadoraName(values?.dadosConsignado?.averbadora) === "E.Consig" && (
              <>
                <div
                  className="col-3"
                >
                  <Form.Item
                    label="Código Convênio"
                    name="dadosConsignado.codigoConvenio"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.codigoConvenio"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.codigoConvenio}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.codigoConvenio', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Código Convênio Reserva"
                    name="dadosConsignado.codigoConvenioReserva"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.codigoConvenioReserva"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.codigoConvenioReserva}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.codigoConvenioReserva', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Código do Serviço"
                    name="dadosConsignado.servicoCodigo"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.servicoCodigo"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.servicoCodigo}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.servicoCodigo', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
              </>
            )
          }
        </div>
        <div className="form-row" style={{ marginTop: '10px', paddingInline: '0px' }}>
          {getAverbadoraName(values?.dadosConsignado?.averbadora) === "E.Consig" &&
            (
              <>
                <div className="col-3">
                  <Form.Item
                    label="Código da Verba"
                    name="dadosConsignado.codVerba"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.codVerba"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.codVerba}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.codVerba', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Código do Orgão"
                    name="dadosConsignado.orgaoCodigo"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.orgaoCodigo"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.orgaoCodigo}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.orgaoCodigo', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Código do Estabelecimento"
                    name="dadosConsignado.estabelecimentoCodigo"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.estabelecimentoCodigo"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.estabelecimentoCodigo}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.estabelecimentoCodigo', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
                <div className="col-3">
                  <Form.Item
                    label="Código Motivo Operação"
                    name="dadosConsignado.codigoMotivoOperacao"
                  >
                    <Input
                      type="text"
                      name="dadosConsignado.codigoMotivoOperacao"
                      placeholder="Digite aqui"
                      value={values?.dadosConsignado?.codigoMotivoOperacao}
                      onChange={(e) => {
                        setFieldValue('dadosConsignado.codigoMotivoOperacao', e.target.value);
                      }}
                      disabled={isReadOnly}
                    />
                  </Form.Item>
                </div>
              </>
            )
          }
        </div>

        <div className="form-row" style={{ marginTop: '10px', paddingInline: '0px' }}>
          {getAverbadoraName(values?.dadosConsignado?.averbadora) === "E.Consig" && (
            <div className="col-3">
              <Form.Item
                label="Obs. Motivo Operação"
                name="dadosConsignado.obsMotivoOperacao"
              >
                <Input
                  type="text"
                  name="dadosConsignado.obsMotivoOperacao"
                  placeholder="Digite aqui"
                  value={values?.dadosConsignado?.obsMotivoOperacao}
                  onChange={(e) => {
                    setFieldValue('dadosConsignado.obsMotivoOperacao', e.target.value);
                  }}
                  disabled={isReadOnly}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ConsignadoPrivadoForm;
