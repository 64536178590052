import React, { createContext, useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { getProcessamentoCrivoCompleto } from '../services/propostaService';

export const ProcessamentoCrivoContext = createContext({
  processamentoCrivo: [],
  drivers: [],
});

function ProcessamentoCrivoProvider({ children }) {
  const [processamentoCrivo, setProcessamentoCrivo] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [dataGeracao, setDataGeracao] = useState(null);

  const params = useParams();

  const { propostaId } = params;

  useEffect(() => {
    async function loadProcessamentoCrivo() {
      const data = await getProcessamentoCrivoCompleto(propostaId);
      setProcessamentoCrivo(data?.processamentoCrivoCompleto);
      setDrivers(data?.drivers);
      setDataGeracao(data?.dataGeracao);
    }

    if (propostaId) {
      loadProcessamentoCrivo();
    }
  }, [propostaId]);

  return (
    <ProcessamentoCrivoContext.Provider
      value={{ processamentoCrivo, drivers, dataGeracao }}
    >
      {children}
    </ProcessamentoCrivoContext.Provider>
  );
}

export default ProcessamentoCrivoProvider;
