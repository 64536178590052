import React, { useContext, useState } from 'react';

import { Collapse, Button } from 'antd';

import TooltipTitleArvore from './TooltipTitleArvore';

import { ReactComponent as IconTree } from '../../../assets/images/icons/icon-tree.svg';
import { ReactComponent as IconErrorContained } from '../../../assets/images/icons/icon-error-contained.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/add.svg';
import { ReactComponent as IconCancel } from '../../../assets/images/icons/icon-cancel-filled.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen2.svg';
import { ReactComponent as IconHistory } from '../../../assets/images/icons/icon-history.svg';
import { ReactComponent as IconListCheck } from '../../../assets/images/icons/icon-list-check.svg';
import { CaretRightOutlined } from '@ant-design/icons';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';

const { Panel } = Collapse;

const ArvorePosicoesHierarquia = ({
  hierarquia,
  editCurrentHierarquia,
  openDetailsPosition,
  openHistoryPosition,
}) => {
  const contexto = useContext(HierarquiasContext);
  const { checarPermissao, activeEditHierarquia, editHierarquiaId } = contexto;

  const posicoes = hierarquia?.posicoes;
  const hierarquiaId = hierarquia?.id;
  const niveis = hierarquia?.hierarquiaNivel;

  let showArvorePosicoes = false;

  if (posicoes?.length > 0 && niveis?.length > 0) {
    posicoes?.forEach((item) => {
      if (item.ativo) {
        showArvorePosicoes = true;
      }
    });
  }

  const addPositionHierarquia = () => {
    activeEditHierarquia(hierarquiaId);
    contexto.addPositionHierarquia(null, hierarquiaId);
  };
  return (
    <>
      <div className="hierarchical-tree row">
        <div>
          <div className="col-lg-12 col-header">
            <div>
              <IconTree />
              <div className="sub-title-form">Hierarquias</div>
            </div>
          </div>
          <div className="col-tree">
            {showArvorePosicoes ? (
              <MountedPosicaoCollapse
                posicoes={posicoes}
                qntdNiveis={niveis?.length}
                editCurrentHierarquia={editCurrentHierarquia}
                openDetailsPosition={openDetailsPosition}
                openHistoryPosition={openHistoryPosition}
                hierarquiaId={hierarquiaId}
              />
            ) : (
              <div>
                <div className="tree-empty">
                  <div className="col-lg-12 tree-empty-message">
                    <IconErrorContained />
                    <div>
                      ADICIONE UM NÍVEL PARA INICIAR
                      <br />A CONFIGURAÇÃO DA HIERARQUIA.
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row col-button">
          {checarPermissao(
            'botao',
            'botao.cadastro.hierarquia.editar',
            'Visualizar',
          ) &&
            niveis?.length > 0 && (
              <Button
                type="button"
                className="btn-posicao"
                variant="blue"
                onClick={() => {
                  addPositionHierarquia();
                }}
                disabled={
                  !checarPermissao(
                    'botao',
                    'botao.cadastro.hierarquia.editar',
                    'Visualizar',
                  ) ||
                  (editHierarquiaId && editHierarquiaId !== hierarquiaId)
                }
              >
                <IconPlusBtn />
                POSIÇÃO
              </Button>
            )}
        </div>
      </div>
    </>
  );
};

const MountedPosicaoCollapse = ({
  posicoes,
  level = 0,
  qntdNiveis,
  editCurrentHierarquia,
  hierarquiaId,
  openDetailsPosition,
  openHistoryPosition,
  parentId = null,
}) => {
  const { coresNiveisHierarquia } = useContext(HierarquiasContext);
  const [activeItem, setActive] = useState('');

  return posicoes.map((item, index) => {
    return item.ativo === true ? (
      <Collapse
        activeKey={activeItem}
        onChange={(active) => {
          if (item?.posicoes?.length) {
            setActive(active);
          }
        }}
        key={index}
        accordion
        expandIconPosition="left"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{
              color:
                level + 1 > coresNiveisHierarquia?.niveis?.length
                  ? coresNiveisHierarquia?.default
                  : coresNiveisHierarquia?.niveis[level]?.cor,
              display:
                item?.posicoes?.length > 0 &&
                item?.posicoes?.find((item) => item.ativo === true) // Pelo menos 1 filho ativo
                  ? 'block'
                  : 'none',
            }}
          />
        )}
        className={item?.posicoes?.length === 0 && 'no-children'}
      >
        <Panel
          header={
            <TitlePanelPosicao
              activeItem={activeItem}
              id={item?.id}
              text={item?.nome}
              tooltipText={'Adicionar'}
              level={level}
              item={item}
              qntdNiveis={qntdNiveis}
              editCurrentHierarquia={editCurrentHierarquia}
              openDetailsPosition={openDetailsPosition}
              openHistoryPosition={openHistoryPosition}
              hierarquiaId={hierarquiaId}
              panelKey={`${index}-sublevel-${item?.id}`}
              parentId={parentId}
            />
          }
          key={`${index}-sublevel-${item?.id}`}
          id={`${index}-sublevel-${item?.id}`}
        >
          <div
            className="line-vertical-left-positions"
            style={{
              position: 'absolute',
              width: '0px',
              height: '100%',
              border: 'none',
              borderLeftStyle: 'solid',
              borderLeftWidth: '1px',
              left: '13px',
              borderColor:
                level + 1 > coresNiveisHierarquia?.niveis?.length
                  ? coresNiveisHierarquia?.default
                  : coresNiveisHierarquia?.niveis[level]?.cor,
            }}
          ></div>
          {item?.posicoes?.length > 0 && (
            <MountedPosicaoCollapse
              posicoes={item?.posicoes}
              level={level + 1}
              qntdNiveis={qntdNiveis}
              editCurrentHierarquia={editCurrentHierarquia}
              openDetailsPosition={openDetailsPosition}
              openHistoryPosition={openHistoryPosition}
              hierarquiaId={hierarquiaId}
              parentId={item?.id}
            />
          )}
        </Panel>
      </Collapse>
    ) : (
      ''
    );
  });
};

const TitlePanelPosicao = ({
  id,
  text,
  tooltipText = '',
  level,
  qntdNiveis,
  openDetailsPosition,
  openHistoryPosition,
  editCurrentHierarquia,
  hierarquiaId,
  activeItem,
  panelKey,
  parentId = null,
}) => {
  const contexto = useContext(HierarquiasContext);
  const {
    checarPermissao,
    activeEditHierarquia,
    editingHierarquia,
    formDetalhesPosicao,
  } = contexto;

  const { setFieldValue, setErrors, setTouched, setValues } =
    formDetalhesPosicao;

  const [newNamePosition, setNewNamePosition] = useState(null);
  const [editingName, setEditingName] = useState(false);

  const addPositionHierarquia = (parentPositionId) => {
    activeEditHierarquia(hierarquiaId);
    contexto.addPositionHierarquia(parentPositionId, hierarquiaId);
  };

  const editPositionHierarquia = (name, positionId) => {
    contexto.editPositionHierarquia(name, positionId, hierarquiaId);
  };

  const inativePositionHierarquia = (posicao) => {
    activeEditHierarquia(posicao.hierarquiaId);
    if (text && text !== null) {
      contexto.openModalInativePositionHierarquia(posicao);
    }
  };

  return (
    <div className={`info-tree ${editCurrentHierarquia ? `editing-info` : ''}`}>
      <input
        id={`input-key-posicao-${id}`}
        value={editCurrentHierarquia && editingName ? newNamePosition : text}
        style={{ minWidth: !newNamePosition ? '200px' : '' }}
        size={newNamePosition?.length || text?.length}
        onClick={(e) => {
          e.stopPropagation();
          setEditingName(true);
        }}
        onFocus={(e) => {
          if (editCurrentHierarquia) {
            e?.target?.parentElement?.parentElement?.parentElement?.parentElement?.classList?.add(
              'ant-tree-treenode-selected',
            );
            e?.target
              ?.closest('.ant-collapse-header')
              ?.classList?.add('selected');
          }
        }}
        onBlur={(e) => {
          e?.target
            ?.closest('.ant-collapse-header')
            ?.classList?.remove('selected');
          setEditingName(false);
          editPositionHierarquia(newNamePosition, id);
        }}
        className={`input-tree-posicao ${
          editCurrentHierarquia ? 'editando' : ''
        }`}
        onChange={(e) => setNewNamePosition(e.target.value)}
        placeholder="Digite o nome da posição"
      />
      <div className="col-icons">
        <TooltipTitleArvore text="Histórico">
          <span
            onClick={(e) => {
              e.stopPropagation();
              openHistoryPosition(id, level, e);
            }}
          >
            <IconHistory className="icon-history" />
          </span>
        </TooltipTitleArvore>
        <TooltipTitleArvore text="Ver detalhes">
          <span
            onClick={(e) => {
              e.stopPropagation();
              setErrors({});
              setTouched({});
              setValues({});
              openDetailsPosition(id, level, e);
              setFieldValue('idPosicaoEditada', id);
            }}
          >
            <IconListCheck className="icon-list-check" />
          </span>
        </TooltipTitleArvore>
        {checarPermissao(
          'botao',
          'botao.cadastro.hierarquia.editar',
          'Visualizar',
        ) && (
          <>
            <TooltipTitleArvore text="Editar">
              <span
                onClick={(e) => {
                  if (editCurrentHierarquia || !editingHierarquia) {
                    activeEditHierarquia(hierarquiaId);
                  }
                  e.stopPropagation();
                }}
              >
                <IconEdit className="icon-edit-opcao" />
              </span>
            </TooltipTitleArvore>
            <TooltipTitleArvore text="Inativar">
              <span
                onClick={(e) => {
                  if (editCurrentHierarquia || !editingHierarquia) {
                    activeEditHierarquia(hierarquiaId);
                    inativePositionHierarquia({
                      positionId: id,
                      nivelIndex: level,
                      quantidadeNiveis: qntdNiveis,
                      hierarquiaId,
                      parentId,
                    });
                  }
                  e.stopPropagation();
                }}
              >
                <IconCancel className="icon-cancel-opcao" />
              </span>
            </TooltipTitleArvore>
            {tooltipText !== '' && level + 1 < qntdNiveis && (
              <TooltipTitleArvore text={tooltipText}>
                <span
                  onClick={(e) => {
                    if (editCurrentHierarquia || !editingHierarquia) {
                      activeEditHierarquia(hierarquiaId);
                      addPositionHierarquia(id);
                    }
                    if (activeItem === panelKey) {
                      // Previne a hierarquia de fechar ao apertar o botão de +
                      e.stopPropagation();
                    }
                  }}
                >
                  <IconPlusBtn className="icon-add-opcao" />
                </span>
              </TooltipTitleArvore>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ArvorePosicoesHierarquia;
