import React, { useContext, useEffect, useState } from 'react';

import { Form, Select } from 'formik-antd';
import { Modal, Button } from 'antd';
import { FormikProvider } from 'formik';
import { Option } from 'antd/lib/mentions';
import Table from '../../../components/BasicTable';
import './styles.scss';

import moment from 'moment';

import DatePickerComponent from '../../../components/DatePicker';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import swal from 'sweetalert';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import {
  getHierarquiaCargos,
  validarVigencia,
} from '../../../services/credenciadoService';

import { uniqBy, orderBy } from 'lodash';

function ComercialForm({ formik, isReadOnly, userId, unidadeId }) {
  const [inicioVigencia, setInicioVigencia] = useState(null);
  const [fimVigencia, setFimVigencia] = useState(null);
  const [vigenciaTouched, setVigenciaTouched] = useState({
    inicioVigencia: false,
    fimVigencia: false,
  });
  const [hierarquiasLista, setHierarquiasLista] = useState([]);
  const [showDataVigencia, setShowDataVigencia] = useState(false);
  const [vigenciaInvalida, setVigenciaInvalida] = useState(false);
  const {
    credenciamentoHierarquias,
    setVisibleDatePickerComercial,
    valueHierarquia,
    setValueHierarquia,
    infoUsuario,
    actionGetHierarquia,
  } = useContext(CredenciadoContext);

  const { values, setFieldValue } = formik;

  const [columnsToAdd, setColumnsToAdd] = useState([]);

  const [historicoHierarquias, setHistoricoHierarquias] = useState([]);
  const [toggleFiltro, setToggleFiltro] = useState();

  const columnsTemplate = [
    {
      Header: 'Hierarquia',
      accessor: 'hierarquiaNome',
    },
    {
      Header: 'Início de vigência',
      accessor: 'dataInicio',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
    {
      Header: 'Fim de vigência',
      accessor: 'dataFim',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : ''}
        </div>
      ),
    },
  ];

  const [columns, setColumns] = useState(columnsTemplate);

  useEffect(() => {
    actionGetHierarquia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Descobrir o maior número de niveis para definir quantas colunas terá
    if (infoUsuario?.comercial?.historico?.length) {
      let niveisPorHierarquia = infoUsuario?.comercial?.historico?.map(
        (item) => item.niveis.length,
      );
      let maiorNivel = Math.max(...niveisPorHierarquia);
      let arrayContagemMaiorNivel = Array.from(Array(maiorNivel).keys());
      setColumnsToAdd(
        arrayContagemMaiorNivel.map((item, index) => {
          return {
            Header: `${index + 1}º NÍVEL`,
            accessor: `niveis[${index}]`,
            Cell: ({ value }) => (
              <div style={{ textAlign: 'center' }}>{value || ''}</div>
            ),
          };
        }),
      );
    }
    // Por padrão, histórico deve ser mostrado da data de início de vigência mais recente para mais antiga
    if (infoUsuario?.comercial?.historico) {
      setHistoricoHierarquias(
        orderBy(infoUsuario?.comercial?.historico, 'dataInicio', 'desc'),
      );
    }
  }, [infoUsuario]);

  useEffect(() => {
    // Atualiza as colunas com o predefinido + níveis dinâmicos
    if (columnsToAdd) {
      setColumns([...columnsTemplate, ...columnsToAdd]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsToAdd]);

  useEffect(() => {
    if (userId) {
      setShowDataVigencia(true);
    }
  }, [userId]);

  useEffect(() => {
    if (values?.comercial?.hierarquia?.id) {
      getAllHierarquias().then((response) => setHierarquiasLista(response));
      setShowDataVigencia(false);
      setFieldValue('inicioVigencia', null);
      setInicioVigencia(null);
      setFieldValue('comercial.hierarquia.dataVigenciaInicial', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.comercial?.hierarquia?.id]);

  useEffect(() => {
    if (uniqBy(hierarquiasLista, 'id').length !== hierarquiasLista.length) {
      setHierarquiasLista(uniqBy(hierarquiasLista, 'id'));
    }
  }, [hierarquiasLista]);

  useEffect(() => {
    if (
      values?.inicioVigencia ||
      values?.comercial?.hierarquia?.dataVigenciaInicial
    ) {
      setShowDataVigencia(true);
      setFieldValue(
        'inicioVigencia',
        values?.inicioVigencia
          ? moment(values?.inicioVigencia)
          : moment(values?.comercial?.hierarquia?.dataVigenciaInicial),
      );
      setFieldValue(
        'comercial.hierarquia.dataVigenciaInicial',
        values?.inicioVigencia
          ? moment(values?.inicioVigencia)
          : moment(values?.comercial?.hierarquia?.dataVigenciaInicial),
      );
      setInicioVigencia(
        values?.inicioVigencia
          ? moment(values?.inicioVigencia)
          : moment(values?.comercial?.hierarquia?.dataVigenciaInicial),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectHierarquiaModal(id) {
    if (valueHierarquia && userId) {
      swal(
        '',
        'A mudança da hierarquia no cadastro da matriz vai gerar inconsistência com as informações de hierarquia das unidades associadas. Deseja confirmar a alteração da hierarquia para a matriz?',
        'warning',
        {
          className: 'alter-hierarquia-comercial-modal',
          buttons: ['Não', 'Sim'],
        },
      ).then((response) => {
        if (response) {
          setVisibleDatePickerComercial(false);
          setValueHierarquia(id);
          selectHierarquia(id).then((response) =>
            setHierarquiasLista(response),
          );
        }
      });
    } else {
      setVisibleDatePickerComercial(false);
      setValueHierarquia(id);
      selectHierarquia(id).then((response) => setHierarquiasLista(response));
    }
  }

  async function selectHierarquia(id) {
    const idHierarquiaSelecionada = id;
    const proximaHierarquia = await getHierarquiaCargos(
      idHierarquiaSelecionada,
    );
    setFieldValue('comercial.hierarquia.niveis', [
      { id: proximaHierarquia.proximoHierarquiaNivel.id, posicaoId: null },
    ]);
    return [{ ...proximaHierarquia.proximoHierarquiaNivel }];
  }

  async function updateHierarquias(selectedOptionId = null, nivelId = null) {
    setShowDataVigencia(false);
    const niveis = formik?.values?.comercial?.hierarquia?.niveis;

    const idHierarquiaSelecionada = formik?.values?.comercial?.hierarquia?.id;

    let nivelSelecionadoIndex = hierarquiasLista.findIndex(
      (item) => item.id === nivelId,
    );

    if (nivelSelecionadoIndex === -1) {
      nivelSelecionadoIndex = niveis.length - 1;
    }

    const proximaHierarquia = await getHierarquiaCargos(
      idHierarquiaSelecionada,
      nivelId,
      selectedOptionId,
    );

    const removerHierarquiasSubsequentes = hierarquiasLista.filter(
      (item, index) => {
        return (
          index <= nivelSelecionadoIndex + 1 &&
          proximaHierarquia?.proximoHierarquiaNivel?.id !== item?.id
        );
      },
    );

    let hierarquiasAtualizado = [
      ...removerHierarquiasSubsequentes,
      proximaHierarquia.proximoHierarquiaNivel,
    ];

    if (!proximaHierarquia) {
      hierarquiasAtualizado = [...removerHierarquiasSubsequentes];
    }

    if (proximaHierarquia?.proximoHierarquiaNivel === null) {
      setShowDataVigencia(true);
      setInicioVigencia(
        values?.inicioVigencia ? moment(values?.inicioVigencia) : moment(),
      );
      setFieldValue(
        'inicioVigencia',
        values?.inicioVigencia ? moment(values?.inicioVigencia) : moment(),
      );
      setFieldValue(
        'comercial.hierarquia.dataVigenciaInicial',
        values?.inicioVigencia ? moment(values?.inicioVigencia) : moment(),
      );
    }

    setHierarquiasLista(hierarquiasAtualizado.filter((item) => item !== null));
  }

  async function getAllHierarquias(type) {
    const niveis = formik?.values?.comercial?.hierarquia?.niveis;
    const idHierarquiaSelecionada = formik?.values?.comercial?.hierarquia?.id;
    const primeiraHierarquia = await getHierarquiaCargos(
      idHierarquiaSelecionada,
    );
    if (Object.keys(formik.values.comercial).length && niveis) {
      const outrasHierarquiasPromises = niveis?.map(async (nivel) => {
        if (nivel.id && nivel.posicaoId) {
          const res = await getHierarquiaCargos(
            idHierarquiaSelecionada,
            nivel.id,
            nivel.posicaoId,
          );
          return res.proximoHierarquiaNivel;
        }
        return null;
      });

      const outrasHierarquiasResponse = await Promise.all(
        outrasHierarquiasPromises,
      );

      return [
        primeiraHierarquia.proximoHierarquiaNivel,
        ...outrasHierarquiasResponse.filter((item) => item !== null),
      ];
    } else {
      return [primeiraHierarquia.proximoHierarquiaNivel];
    }
  }

  function validacaoVigencia(tipoVigencia) {
    if (vigenciaInvalida) {
      return { message: '', status: '' };
    }
    if (!moment(inicioVigencia).isBefore(fimVigencia) && fimVigencia) {
      if (tipoVigencia === 'fim') {
        return {
          message: 'Data inválida',
          status: 'warning',
        };
      }
    }
    if (tipoVigencia === 'inicio') {
      if (inicioVigencia === null && vigenciaTouched.inicioVigencia) {
        return { message: 'Campo Obrigatório', status: 'error' };
      }
    }
    return { message: '', status: '' };
  }

  function formatarData(data) {
    return moment(data).format('YYYYMMDD');
  }

  function validarVigenciaConcomitante(type, date) {
    // state não atualiza a tempo, variavel type indica qual campo foi modificado
    if (userId && unidadeId) {
      if (type === 'fim' && moment(date).isValid()) {
        validarVigencia(
          unidadeId,
          values?.comercial?.hierarquia?.id,
          null, // vigencia inicial, alinhado com o back
          formatarData(date),
        ).then((response) => {
          if (!response) {
            setVigenciaInvalida(true);
            setFimVigencia(null);
            setFieldValue('fimVigencia', null);
          }
        });
      } else if (type === 'inicio' && moment(date).isValid()) {
        validarVigencia(
          unidadeId,
          values?.comercial?.hierarquia?.id,
          formatarData(date),
          null, // vigência final, alinhado com o back
        ).then((response) => {
          if (!response) {
            setVigenciaInvalida(true);
            setInicioVigencia(null);
            setFieldValue('inicioVigencia', null);
          }
        });
      }
    }
  }

  function validationHierarquia(value, index) {
    let error;
    if (!value && values.dadosGerais.tipoCredenciado === 0) {
      error = 'Campo Obrigatório';
      formik.setFieldError(
        `comercial.hierarquia.niveis[${index}]`,
        'Campo Obrigatório',
      );
    }
    return error;
  }

  function filtrarListagemHierarquias(props) {
    let field, isDescending;
    if (props?.sortBy[0]?.id || props?.sortBy[0]?.desc) {
      field = props?.sortBy[0]?.id;
      isDescending = props?.sortBy[0]?.desc;
      setHistoricoHierarquias(
        orderBy(historicoHierarquias, field, isDescending ? 'desc' : 'asc'),
      );
    }
  }

  useEffect(() => {
    filtrarListagemHierarquias(toggleFiltro);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFiltro]);

  return (
    <>
      <Modal
        visible={vigenciaInvalida}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {
          setVigenciaInvalida(false);
        }}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          Credenciado não pode pertencer a duas hierarquias ao mesmo tempo.
          Verifique as datas e tente novamente!
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {
              setVigenciaInvalida(false);
            }}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="row comercial-tab">
            <div className="col-lg-4 item-dados">
              <Form.Item name="comercial.hierarquia.id" label="Hierarquia">
                <Select
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={
                    isReadOnly || infoUsuario?.comercial?.hierarquia?.id
                  }
                  suffixIcon={<IconArrowSelect />}
                  onChange={(id) => {
                    setFieldValue('comercial.hierarquia.id', id);
                    selectHierarquiaModal(id);
                    selectHierarquia(id);
                    setShowDataVigencia(false);
                  }}
                  name="comercial.hierarquia.id"
                  placeholder="Selecione"
                  value={values?.comercial?.hierarquia?.id}
                >
                  {credenciamentoHierarquias?.map((item, index) => (
                    <Option
                      key={'credenciamento-hierarquia-' + index}
                      value={item?.id}
                    >
                      {item?.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {hierarquiasLista?.map((nivelHierarquia, index) => (
              <>
                <div
                  className="col-lg-4 item-dados"
                  key={nivelHierarquia?.nome + index}
                >
                  <Form.Item
                    label={nivelHierarquia?.nome}
                    name={`comercial.hierarquia.niveis[${index}].posicaoId`}
                    validate={(value) => validationHierarquia(value, index)}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabled={
                        isReadOnly ||
                        infoUsuario?.comercial?.hierarquia?.niveis?.length
                      }
                      placeholder="Selecione"
                      suffixIcon={<IconArrowSelect />}
                      name={`comercial.hierarquia.niveis[${index}].posicaoId`}
                      onSelect={(selectedId) => {
                        const niveis =
                          formik?.values?.comercial?.hierarquia?.niveis;
                        if (
                          niveis.find(
                            (item) => item?.id === nivelHierarquia?.id,
                          )
                        ) {
                          const itemSelecionadoIndex = niveis.findIndex(
                            (item) => item?.id === nivelHierarquia?.id,
                          );

                          let niveisAtualizados = niveis;
                          niveisAtualizados[itemSelecionadoIndex] = {
                            id: nivelHierarquia.id,
                            posicaoId: selectedId,
                          };

                          if (niveisAtualizados[itemSelecionadoIndex + 1]) {
                            niveisAtualizados[itemSelecionadoIndex + 1] = {
                              ...niveisAtualizados[itemSelecionadoIndex + 1],
                              posicaoId: null,
                            };
                            //removendo itens do níveis após o próximo select vazio
                            let isShowingOnScreen = true;
                            niveisAtualizados = niveisAtualizados.filter(
                              (item) => {
                                if (item.posicaoId === null) {
                                  isShowingOnScreen = false;
                                }
                                return isShowingOnScreen;
                              },
                            );
                          }
                          setFieldValue(
                            'comercial.hierarquia.niveis',
                            niveisAtualizados,
                          );
                        } else {
                          setFieldValue('comercial.hierarquia.niveis', [
                            ...niveis,
                            { id: nivelHierarquia.id, posicaoId: selectedId },
                          ]);
                        }

                        updateHierarquias(selectedId, nivelHierarquia.id);
                      }}
                    >
                      {hierarquiasLista[index]?.detalhes?.map(
                        (item, indexItem) => (
                          <Option key={item?.nome + indexItem} value={item?.id}>
                            {item?.nome}
                          </Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </div>
              </>
            ))}
            {values?.comercial?.hierarquia?.id && showDataVigencia ? (
              <>
                <div className="col-lg-4 item-dados">
                  <Form.Item
                    name="inicioVigencia"
                    label="Início de Vigência"
                    help={validacaoVigencia('inicio').message}
                    validateStatus={validacaoVigencia('inicio').status}
                  >
                    <DatePickerComponent
                      disabled={
                        isReadOnly ||
                        infoUsuario?.comercial?.hierarquia?.dataVigenciaInicial
                      }
                      format="DD/MM/YYYY"
                      name="inicioVigencia"
                      placeholder="00/00/0000"
                      onChange={(value) => {
                        setInicioVigencia(value);
                        validarVigenciaConcomitante('inicio', value);
                        setFieldValue('inicioVigencia', value);
                        setFieldValue(
                          'comercial.hierarquia.dataVigenciaInicial',
                          value,
                        );
                      }}
                      value={inicioVigencia}
                      disabledDate={(date) => date > moment()}
                      onBlur={() => {
                        setVigenciaTouched({
                          ...vigenciaTouched,
                          inicioVigencia: true,
                        });
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-4 item-dados">
                  <Form.Item
                    name="fimVigencia"
                    label="Fim de Vigência"
                    help={validacaoVigencia('fim').message}
                    validateStatus={validacaoVigencia('fim').status}
                  >
                    <DatePickerComponent
                      disabled={isReadOnly}
                      format="DD/MM/YYYY"
                      name="fimVigencia"
                      placeholder="00/00/0000"
                      onChange={(value) => {
                        setFimVigencia(value);
                        validarVigenciaConcomitante('fim', value);
                        setFieldValue('fimVigencia', value);
                      }}
                      value={fimVigencia}
                      disabledDate={(date) => date > moment()}
                      onBlur={() =>
                        setVigenciaTouched({
                          ...vigenciaTouched,
                          fimVigencia: true,
                        })
                      }
                    />
                  </Form.Item>
                </div>
              </>
            ) : (
              ''
            )}
            {userId && historicoHierarquias?.length > 0 ? (
              <div className="col-lg-12 item-dados">
                <div className="comercial-title">
                  Histórico de hierarquias utilizadas
                </div>
                <Table
                  columns={columns}
                  data={historicoHierarquias}
                  onLoadData={setToggleFiltro}
                  className="table table-striped"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Form>
      </FormikProvider>
    </>
  );
}

export default ComercialForm;
