import React, { Fragment, useContext, useState, useEffect } from 'react';

import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';
import DatePicker from '../../../components/DatePicker';

import InputMask from 'react-text-mask';
import Tooltip from '../../../components/tooltip';

import moment from 'moment';

import SelectSearch from '../../../components/SearchSelect';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import { cpfMask } from '../../../utils/masks';

const { Option } = Select;

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

function DadosPessoaisForm({ formik, disabledFields }) {
  const { setFieldTouched, setFieldValue, values } = formik;

  const {
    estados,
    cidadesNacionalidade,
    sexo,
    estadoCivil,
    grauInstrucao,
    nacionalidades,
    nacionalidadeId,
    setNacionalidadeId,
    setNaturalidadeUfId,
    validationField,
    editableField,
  } = useContext(PropostaCreditoContext);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const handleSelecionarNacionalidade = (value, form) => {
    form.setFieldValue('cliente.nacionalidadeId', value);
    form.setFieldValue('cliente.naturalidadeUfId', null);
    form.setFieldValue('cliente.naturalidadeCidadeId', null);
    setNacionalidadeId(value);
    setFieldTouched('cliente.naturalidadeUfId', false);
    setFieldTouched('cliente.naturalidadeCidadeId', false);
  };

  const handleSelecionarUfNaturalidade = (value, form) => {
    form.setFieldValue('cliente.naturalidadeUfId', value);
    form.setFieldValue('cliente.naturalidadeCidadeId', null);
    setNaturalidadeUfId(value);
    setFieldTouched('cliente.naturalidadeUfId');
  };

  const handleNascimento = (e) => {
    setFieldTouched('cliente.nascimento');
  };

  const handleRgEmissao = (e) => {
    setFieldTouched('cliente.rgEmissao');
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };

  const disabledEmissao = (current) => {
    return (
      current &&
      (current > moment().endOf('day') ||
        current < moment(values.cliente.nascimento))
    );
  };

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados Pessoais do Cliente</div>
      </div>

      <div className="form-row">
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.cpf"
            label="CPF"
            validate={(value) => validationField(value, 'cliente.cpf', 'CPF')}
          >
            <Field name="cliente.cpf">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="000.000.000-00"
                  mask={cpfMask}
                  disabled={
                    (acesso ? !editableField('cliente.cpf') : true)
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
          <Form.Item
            name="cliente.nome"
            label="Nome do Cliente"
            validate={(value) => validationField(value, 'cliente.nome', 'Nome')}
            hasFeedback

          >
            <Input
              name="cliente.nome"
              value={formik?.values?.cliente?.nome}
              disabled={
                (acesso ? !editableField('cliente.nome') : true)
                || disabledFields
              }
              onBlur={(e) => setFieldValue('cliente.nome', String(e.target.value).toUpperCase().trim())}
              uppercase={true}
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.nascimento"
            label="Data de Nascimento"
            validate={(value) =>
              validationField(value, 'cliente.nascimento', 'Data de Nascimento')
            }
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.nascimento"
              placeholder="00/00/0000"
              disabledDate={disabledDate}
              onBlur={(e) => handleNascimento(e)}
              getPopupContainer={(trigger) => trigger.parentNode}
              disabled={
                (acesso ? !editableField('cliente.nascimento') : true)
                || disabledFields
              }
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
            />
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="cliente.rg"
            label="Número do RG/CNH"
            validate={(value) =>
              validationField(value, 'cliente.rg', 'número do RG')
            }
            hasFeedback
          >
            <Input
              name="cliente.rg"
              disabled={
                (acesso ? !editableField('cliente.rg') : true)
                || disabledFields
              }
              value={formik?.values?.cliente?.rg}
              onBlur={(e) => setFieldValue('cliente.rg', e.target.value)}
              maxLength={14}
            />
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.rgEmissor"
            label="Orgão Emissor"
            validate={(value) =>
              validationField(value, 'cliente.rgEmissor', 'emissor do RG')
            }
            hasFeedback
          >
            <Input
              name="cliente.rgEmissor"
              disabled={
                (acesso ? !editableField('cliente.rgEmissor') : true)
                || disabledFields
              }
              value={formik?.values?.cliente?.rgEmissor}
              onBlur={(e) => setFieldValue('cliente.rgEmissor', e.target.value)}
            />
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.rgUfId"
            label="UF de Emissão"
            validate={(value) =>
              validationField(value, 'cliente.rgUfId', 'UF do RG')
            }
            hasFeedback
          >
            <Field name="cliente.rgUfId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('cliente.rgUfId', value)}
                  disabled={
                    (acesso ? !editableField('cliente.rgUfId') : true)
                    || disabledFields
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                  suffixIcon={<IconArrowSelect />}
                  optionFilterProp="children"
                >
                  {estados.map((uf) => (
                    <Option key={uf.id} value={uf.id}>
                      {uf.uf}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.rgEmissao"
            label={<label>Data de Emissão</label>}
            validate={(value) =>
              validationField(value, 'cliente.rgEmissao', 'Data de Emissão')
            }
            hasFeedback
          >
            <DatePicker
              format="DD/MM/YYYY"
              name="cliente.rgEmissao"
              disabled={
                (acesso ? !editableField('cliente.rgEmissao') : true)
                || disabledFields
              }
              dropdownAlign={{
                overflow: { adjustY: 0 },
              }}
              placeholder="00/00/0000"
              disabledDate={disabledEmissao}
              onBlur={(e) => handleRgEmissao(e)}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.sexo"
            label="Sexo"
            validate={(value) => validationField(value, 'cliente.sexo', 'Sexo')}
            hasFeedback
          >
            <Field name="cliente.sexo">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => setFieldValue('cliente.sexo', value)}
                  disabled={
                    (acesso ? !editableField('cliente.sexo') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  showSearch
                >
                  {sexo.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.estadoCivil"
            label="Estado Civil"
            validate={(value) =>
              validationField(value, 'cliente.estadoCivil', 'Estado Civil')
            }
            hasFeedback
          >
            <Field name="cliente.estadoCivil">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    setFieldValue('cliente.estadoCivil', value)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso ? !editableField('cliente.estadoCivil') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {estadoCivil.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.nacionalidadeId"
            label="Nacionalidade"
            validate={(value) =>
              validationField(value, 'cliente.nacionalidadeId', 'Nacionalidade')
            }
            hasFeedback
          >
            <Field name="cliente.nacionalidadeId">
              {({ field, form }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    handleSelecionarNacionalidade(value, form)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso ? !editableField('cliente.nacionalidadeId') : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {nacionalidades.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.naturalidadeUfId"
            label="UF Naturalidade"
            validate={(value) =>
              nacionalidadeId === 1 &&
              validationField(
                value,
                'cliente.naturalidadeUfId',
                'UF Naturalidade',
              )
            }
            hasFeedback
          >
            <Field name="cliente.naturalidadeUfId">
              {({ field, form }) => (
                <SelectSearch
                  {...field}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  onChange={(value) =>
                    handleSelecionarUfNaturalidade(value, form)
                  }
                  disabled={
                    (acesso
                      ? !editableField('cliente.naturalidadeUfId') ||
                      nacionalidadeId !== 1
                      : true)
                    || disabledFields
                  }
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {estados &&
                    estados.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.uf}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-2">
          <Form.Item
            name="cliente.naturalidadeCidadeId"
            label="Cidade Naturalidade"
            validate={(value) =>
              nacionalidadeId === 1 &&
              validationField(
                value,
                'cliente.naturalidadeCidadeId',
                'Cidade Naturalidade',
              )
            }
            hasFeedback
          >
            <Field name="cliente.naturalidadeCidadeId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    setFieldValue('cliente.naturalidadeCidadeId', value)
                  }
                  disabled={
                    (acesso
                      ? !editableField('cliente.naturalidadeCidadeId') ||
                      nacionalidadeId !== 1
                      : true)
                    || disabledFields
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  optionFilterProp="children"
                  suffixIcon={<IconArrowSelect />}
                  showSearch
                >
                  {cidadesNacionalidade.length > 0 &&
                    cidadesNacionalidade.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name="cliente.grauInstrucaoId"
            label="Grau de Instrução"
            validate={(value) =>
              validationField(
                value,
                'cliente.grauInstrucaoId',
                'Grau de Instrução',
              )
            }
            hasFeedback
          >
            <Field name="cliente.grauInstrucaoId">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  name="cliente.grauInstrucaoId"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  disabled={
                    (acesso ? !editableField('cliente.grauInstrucaoId') : true)
                    || disabledFields
                  }
                  suffixIcon={<IconArrowSelect />}
                  onChange={(value) =>
                    setFieldValue(`cliente.grauInstrucaoId`, value)
                  }
                  optionFilterProp="children"
                  showSearch
                >
                  {grauInstrucao.length > 0 &&
                    grauInstrucao.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-3">
          <Form.Item
            name="cliente.pep"
            validate={(value) => validationField(value, 'cliente.pep', 'PEP')}
            hasFeedback
            label={
              <>
                <label>PEP</label>
                <TooltipInfo text="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares considerando assim parentes, na linha direta, até o primeiro grau, assim como o cônjuge, companheiro, enteado, bem como, outras pessoas de seu relacionamento próximo." />
              </>
            }
          >
            <Field name="cliente.pep">
              {({ field }) => (
                <SelectSearch
                  {...field}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownAlign={{
                    overflow: { adjustY: 0 },
                  }}
                  name="cliente.pep"
                  disabled={
                    (acesso ? !editableField('cliente.pep') : true)
                    || disabledFields
                  }
                  suffixIcon={<IconArrowSelect />}
                  onChange={(value) => setFieldValue(`cliente.pep`, value)}
                  optionFilterProp="children"
                  showSearch
                >
                  <Option value={true} key="pep-1">
                    Sim
                  </Option>
                  <Option value={false} key="pep-0">
                    Não
                  </Option>
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4">
          <Form.Item
            name="cliente.nomeMae"
            label="Nome da Mãe"
            validate={(value) =>
              validationField(value, 'cliente.nomeMae', 'Nome da Mãe')
            }
            hasFeedback
          >
            <Input
              name="cliente.nomeMae"
              placeholder="Nome completo da mãe"
              disabled={
                (acesso ? !editableField('cliente.nomeMae') : true)
                || disabledFields
              }
              value={formik?.values?.cliente?.nomeMae}
              onBlur={(e) => setFieldValue('cliente.nomeMae', e.target.value)}
            />
          </Form.Item>
        </div>

        {estadoCivil &&
          estadoCivil.length > 0 &&
          formik.values.cliente?.estadoCivil !== null &&
          estadoCivil[formik.values.cliente?.estadoCivil]?.exigeConjuge && (
            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4">
              <Form.Item
                name="cliente.nomeConjuge"
                label="Nome do Cônjuge"
                validate={(value) => {
                  let msgError;
                  if (
                    !value &&
                    estadoCivil[formik.values.cliente?.estadoCivil]
                      ?.exigeConjuge
                  ) {
                    msgError = 'Obrigatório informar o Nome do Cônjuge';
                  }
                  return msgError;
                }}
                hasFeedback
              >
                <Input
                  name="cliente.nomeConjuge"
                  disabled={
                    (acesso ? !editableField('cliente.nomeConjuge') : true)
                    || disabledFields
                  }
                  placeholder="Nome completo do cônjuge"
                  value={formik?.values?.cliente?.nomeConjuge}
                  onBlur={(e) =>
                    setFieldValue('cliente.nomeConjuge', e.target.value)
                  }
                />
              </Form.Item>
            </div>
          )}
      </div>
    </Fragment>
  );
}

export { DadosPessoaisForm };
