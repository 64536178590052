import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'formik-antd';
import { useHistory } from 'react-router-dom';

import './styles.scss';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Input } from 'antd';

import { CadastroPessoaContext } from '../../../contexts/CadastroPessoaContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import Card from '../../../components/Card';

import { SearchOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import Table from '../../../components/BasicTable';
import Title from '../../../components/Title';
import Modal from '../../../components/Modal';

import {
  phoneFormatter,
  cellPhoneFormatter,
} from '../../../utils/textFormatter';

import Toolbar from './toolbar';
import ModalBlocklist from '../../../components/ModalBlockList';

import { columnsAlteracoes } from './columnsAlteracoes';
import { columnsContratos } from './columnsContratos';
import { columnsPropostas } from './columnsPropostas';

import DadosPessoaisForm from './Forms/DadosPessoaisForm';
import EnderecoForm from './Forms/EnderecoForm';
import ContatoForm from './Forms/ContatoForm';
import DadosProfissionaisForm from './Forms/DadosProfissionaisForm';
import ReferenciasPessoaisForm from './Forms/ReferenciasPessoaisForm';
import DadosAdicionaisForm from './Forms/DadosAdicionaisForm';

import initialValues from './initialValues';
import { validationSchema } from './validationSchema';

import { columnsTooltipContratos } from './columnsTooltipContratos';

const { Panel } = Collapse;

const valuesReferencia = {
  nome: '',
  telefone: '',
  grau: 0,
};

function EdicaoCadastroPessoa({ idPessoa }) {
  const [hasErrors, setHasErrors] = useState({
    pessoa: null,
    contatos: null,
    referencias: null,
    profissao: null,
    endereco: null,
  });

  const history = useHistory();

  const { checarPermissao } = useContext(ControleAcessoContext);

  const navigateToPessoas = () => {
    history.push('/cadastro-pessoa');
  };

  const {
    contexto,
    fetchPessoa,
    editarPessoa,
    historicosAlteracao,
    historicosContrato,
    historicosProposta,
    historicosPrestacao,
    fetchHistoricoAlteracao,
    fetchHistoricoContrato,
    fetchHistoricoProposta,
    paginacaoHistoricoAlteracao,
    paginacaoHistoricoContrato,
    paginacaoHistoricoPropostas,
    setPaginacaoHistoricoAlteracao,
    setPaginacaoHistoricoContrato,
    setPaginacaoHistoricoPropostas,
    setPayloadHistoricoPrestacao,
    loadingHistoricoPrestacao,
    isModalVisible,
    setIsModalVisible,
    dadosModalBlocklist,
    setDisabled,
  } = useContext(CadastroPessoaContext);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      setDisabled(true);
      const temp = Object.assign({}, data);
      if (!Object.keys(formik.errors).length) {
        if (data.endereco.cep) {
          temp.endereco.cep = data.endereco.cep.replace(/\D/g, '');
        }

        if (data.contatos.telefone) {
          temp.contatos.telefone = data.contatos.telefone.replace(/\D/g, '');
        } else {
          temp.contatos.telefone = '';
        }

        if (data.profissao.telefoneRH) {
          temp.profissao.telefoneRH = data.profissao.telefoneRH.replace(
            /\D/g,
            '',
          );
        } else {
          temp.profissao.telefoneRH = '';
        }

        if (
          data.contatos.contatosExtras &&
          data.contatos.contatosExtras.length > 0
        ) {
          temp.contatos.contatosExtras = data.contatos.contatosExtras.map(
            (contatoExtra) => ({
              ...contatoExtra,
              telefone: contatoExtra.telefone.replace(/\D/g, ''),
              contatoId: contatoExtra.contatoId || null,
              pessoaId: idPessoa,
            }),
          );
        }

        if (data.profissao.floatRenda) {
          temp.profissao.renda = data.profissao.floatRenda;
          delete temp.profissao.floatRenda;
        }

        if (data.profissao.floatOutrasRendas) {
          temp.profissao.outrasRendas = data.profissao.floatOutrasRendas;
          delete temp.profissao.floatOutrasRendas;
        }

        if (!data.profissao.outrasRendas) {
          temp.profissao.outrasRendas = 0;
        }

        if (data.referencias && data.referencias.length > 0) {
          temp.referencias = data.referencias.map((referencia) => ({
            ...referencia,
            telefone: referencia.telefone.replace(/\D/g, ''),
            referenciaId: referencia.referenciaId || null,
            pessoaId: idPessoa,
          }));
        }

        const response = await editarPessoa(temp).catch(() =>
          setDisabled(false),
        );

        if (response.success) {
          Modal(
            '',
            'Pessoa atualizada com sucesso!',
            'success',
            navigateToPessoas,
          );
        } else {
          Modal('', response.errors.join(' \n \n '), 'warning');
        }
      } else {
        Modal('', 'Verifique os campos inválidos', 'warning');
      }
      setDisabled(false);
    },
  });

  const { setValues, values } = formik;

  useEffect(() => {
    async function loadPessoa() {
      const data = await fetchPessoa(idPessoa);
      if (data) {
        if (data.contatos.telefoneCelular) {
          data.contatos.telefoneCelular = cellPhoneFormatter(
            data.contatos.telefoneCelular,
          );
        }

        if (data.contatos.telefoneFixo) {
          data.contatos.telefoneFixo = phoneFormatter(
            data.contatos.telefoneFixo,
          );
        }

        if (data.profissao.telefoneRH) {
          data.profissao.telefoneRH = phoneFormatter(data.profissao.telefoneRH);
        }

        const referencias = data?.referencias ? data?.referencias : [];
        for (let i = referencias.length; i < 2; i++) {
          referencias.push(valuesReferencia);
        }

        if (!data.referencias) {
          data.referencias = referencias;
        }

        setValues(data);
      }
    }

    if (idPessoa) {
      loadPessoa();
    }
  }, [fetchPessoa, idPessoa, setValues]);

  useEffect(() => {
    if (contexto.length !== 0) {
      if (!contexto?.sexo?.find((sexo) => sexo.id === values?.pessoa?.sexo)) {
        values.pessoa.sexo = '';
      }
      if (
        !contexto?.tempoEmprego?.find(
          (tempoEmprego) =>
            tempoEmprego.id === values?.profissao?.tempoempregoId,
        )
      ) {
        values.profissao.tempoempregoId = '';
      }
      if (
        !contexto?.estadoCivil?.find(
          (estadoCivil) => estadoCivil.id === values?.pessoa?.estadoCivil,
        )
      ) {
        values.pessoa.estadoCivil = '';
      }
      if (
        !contexto?.grauInstrucao?.find(
          (grauInstrucao) => grauInstrucao.id === values?.pessoa?.grauInstrucao,
        )
      ) {
        values.pessoa.grauInstrucao = '';
      }
      if (
        !contexto?.ocupacao?.find(
          (ocupacao) => ocupacao.id === values?.profissao?.ocupacaoId,
        )
      ) {
        values.profissao.ocupacaoId = '';
      }
      if (
        !contexto?.UF?.find((UF) => UF.id === values?.pessoa?.documentoUFId)
      ) {
        values.pessoa.documentoUFId = '';
      }
      if (
        !contexto?.UF?.find((UF) => UF.id === values?.pessoa?.naturalidadeUfId)
      ) {
        values.pessoa.naturalidadeUfId = '';
      }
      if (
        !contexto?.pais?.find(
          (pais) => pais.id === values?.pessoa?.nacionalidadeId,
        )
      ) {
        values.pessoa.nacionalidadeId = '';
      }
    }
  }, [contexto, values]);

  return (
    <>
      {checarPermissao(
        'tela',
        'tela.credito.pessoa.cadastro.clienteselecionado',
        'Visualizar',
      ) && (
        <>
          <Card title={<Title label="Cliente Selecionado" />}>
            <div className="form-container">
              <FormikProvider value={formik}>
                <Form layout="vertical">
                  <Toolbar formik={formik} setHasErrors={setHasErrors} />
                  <div className="row form-historico-edit mt-3">
                    <div className="col">
                      <Tabs>
                        <TabList>
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.pessoais',
                            'Visualizar',
                          ) && (
                            <Tab
                              style={
                                hasErrors.pessoa && {
                                  color: '#f00',
                                  fontWeight: 'bold',
                                }
                              }
                            >
                              Dados Pessoais
                            </Tab>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.endereco',
                            'Visualizar',
                          ) && (
                            <Tab
                              style={
                                hasErrors.endereco && {
                                  color: '#f00',
                                  fontWeight: 'bold',
                                }
                              }
                              data-cy="tab-endereco"
                            >
                              Endereço
                            </Tab>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.contato',
                            'Visualizar',
                          ) && (
                            <Tab
                              style={
                                hasErrors.contatos && {
                                  color: '#f00',
                                  fontWeight: 'bold',
                                }
                              }
                              data-cy="tab-contato"
                            >
                              Contato
                            </Tab>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.profissionais',
                            'Visualizar',
                          ) && (
                            <Tab
                              style={
                                hasErrors.profissao && {
                                  color: '#f00',
                                  fontWeight: 'bold',
                                }
                              }
                              data-cy="tab-profissao"
                            >
                              Dados Profissionais
                            </Tab>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.referenciasPessoais',
                            'Visualizar',
                          ) && (
                            <Tab
                              style={
                                hasErrors.referencias && {
                                  color: '#f00',
                                  fontWeight: 'bold',
                                }
                              }
                              data-cy="tab-referencias"
                            >
                              Referências Pessoais
                            </Tab>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.dadosAdicionais',
                            'Visualizar',
                          ) &&
                            formik?.values?.dadosConvenio?.length > 0 && (
                              <Tab
                                style={
                                  formik.errors.dicionais &&
                                  formik.touched.dicionais && {
                                    color: '#f00',
                                    fontWeight: 'bold',
                                  }
                                }
                                data-cy="tab-dados-adicionais"
                              >
                                Dados Adicionais
                              </Tab>
                            )}
                        </TabList>

                        <div className="react-tabs__content-holder">
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.pessoais',
                            'Visualizar',
                          ) && (
                            <TabPanel>
                              <DadosPessoaisForm formik={formik} />
                            </TabPanel>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.endereco',
                            'Visualizar',
                          ) && (
                            <TabPanel>
                              <EnderecoForm formik={formik} />
                            </TabPanel>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.contato',
                            'Visualizar',
                          ) && (
                            <TabPanel>
                              <ContatoForm formik={formik} />
                            </TabPanel>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.profissionais',
                            'Visualizar',
                          ) && (
                            <TabPanel>
                              <DadosProfissionaisForm formik={formik} />
                            </TabPanel>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.referenciasPessoais',
                            'Visualizar',
                          ) && (
                            <TabPanel>
                              <ReferenciasPessoaisForm formik={formik} />
                            </TabPanel>
                          )}
                          {checarPermissao(
                            'aba',
                            'aba.cadastroPessoa.cliente.dadosAdicionais',
                            'Visualizar',
                          ) &&
                            formik?.values?.dadosConvenio?.length > 0 && (
                              <TabPanel>
                                <DadosAdicionaisForm formik={formik} />
                              </TabPanel>
                            )}
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </Form>
              </FormikProvider>
            </div>

            <div className="collapse-historicos">
              <Collapse
                expandIconPosition="left"
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{ color: '#ff7817' }}
                  />
                )}
                className="mt-4"
              >
                {checarPermissao(
                  'tabela',
                  'tabela.cadastroPessoa.cliente.historicoContratosRealizados',
                  'Visualizar',
                ) && (
                  <Panel
                    header="Histórico de contratos realizados"
                    extra={
                      <div className="table-filter">
                        <Input
                          className="filter-input"
                          placeholder="Pesquise na tabela"
                          suffix={<SearchOutlined />}
                          onChange={(e) =>
                            setPaginacaoHistoricoContrato((paginacao) => ({
                              ...paginacao,
                              filtroDinamico: e.target.value,
                            }))
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    }
                    key="1"
                  >
                    <div className="tabelaListagem">
                      <Table
                        columns={columnsContratos}
                        data={historicosContrato}
                        pageCount={paginacaoHistoricoContrato.pageCount}
                        loading={paginacaoHistoricoContrato.loading}
                        total={paginacaoHistoricoContrato.total}
                        onLoadData={fetchHistoricoContrato}
                        pagination={true}
                        filter={paginacaoHistoricoContrato.filtroDinamico}
                        tooltipContent={
                          <Table
                            columns={columnsTooltipContratos}
                            data={historicosPrestacao}
                            pageCount={0}
                            loading={loadingHistoricoPrestacao}
                            total={0}
                            onLoadData={() => {}}
                            pagination={true}
                            filter={false}
                          />
                        }
                        onRowClick={(row) =>
                          setPayloadHistoricoPrestacao({
                            parcela: row.plano,
                            valorTotal: row.valorContrato,
                            valorPrestacao: row.prestacao,
                          })
                        }
                      />
                    </div>
                  </Panel>
                )}
                {checarPermissao(
                  'tabela',
                  'tabela.cadastroPessoa.cliente.historicoPropostas',
                  'Visualizar',
                ) && (
                  <Panel
                    header="Histórico de propostas"
                    extra={
                      <div className="table-filter">
                        <Input
                          className="filter-input"
                          placeholder="Pesquise na tabela"
                          suffix={<SearchOutlined />}
                          onChange={(e) =>
                            setPaginacaoHistoricoPropostas((paginacao) => ({
                              ...paginacao,
                              filtroDinamico: e.target.value,
                            }))
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    }
                    key="2"
                  >
                    <div className="tabelaListagem">
                      <Table
                        columns={columnsPropostas}
                        data={historicosProposta}
                        pageCount={paginacaoHistoricoPropostas.pageCount}
                        loading={paginacaoHistoricoPropostas.loading}
                        total={paginacaoHistoricoPropostas.total}
                        onLoadData={fetchHistoricoProposta}
                        pagination={true}
                        filter={paginacaoHistoricoPropostas.filtroDinamico}
                      />
                    </div>
                  </Panel>
                )}
                {checarPermissao(
                  'tabela',
                  'tabela.cadastroPessoa.cliente.historicoAlteracoesDados',
                  'Visualizar',
                ) && (
                  <Panel
                    header="Histórico de alteração dos dados da pessoa"
                    extra={
                      <div className="table-filter">
                        <Input
                          className="filter-input"
                          placeholder="Pesquise na tabela"
                          suffix={<SearchOutlined />}
                          onChange={(e) =>
                            setPaginacaoHistoricoAlteracao((paginacao) => ({
                              ...paginacao,
                              filtroDinamico: e.target.value,
                            }))
                          }
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    }
                    key="4"
                  >
                    <div className="tabelaListagem">
                      <Table
                        columns={columnsAlteracoes}
                        data={historicosAlteracao}
                        pageCount={paginacaoHistoricoAlteracao.pageCount}
                        loading={paginacaoHistoricoAlteracao.loading}
                        total={paginacaoHistoricoAlteracao.total}
                        onLoadData={fetchHistoricoAlteracao}
                        pagination={true}
                        filter={paginacaoHistoricoAlteracao.filtroDinamico}
                      />
                    </div>
                  </Panel>
                )}
              </Collapse>
            </div>
          </Card>

          <ModalBlocklist
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            dados={dadosModalBlocklist}
            disabled={
              !checarPermissao(
                'botao',
                'botao.cadastroPessoa.cliente.blocklist',
                'Criar',
              )
            }
          />
        </>
      )}
    </>
  );
}

export default EdicaoCadastroPessoa;
