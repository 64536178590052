import React, { useContext } from 'react';

import Table from '../../../components/BasicTable';

import Filter from '../filter';
import { cnpjFormatter, cpfFormatter } from '../../../utils/textFormatter';
import moment from 'moment';
import Button from '../../../components/Button';
import { Checkbox } from 'antd';

import { ReactComponent as IconEditar } from '../../../assets/images/icons/icon-editar.svg';

import { UsuariosContext } from '../../../contexts/UsuariosContext';

import '../styles.scss';
import { FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function UsuariosList({ showFilter }) {
  const { checarPermissao } = useContext(ControleAcessoContext);
  const {
    formik,
    usuarios,
    fetchUsuarios,
    total,
    loading,
    pageCount,
    filters,
    pageIndex,
    setPageIndex,
    selectedUserList,
    setSelectedUserList,
  } = useContext(UsuariosContext);
  const history = useHistory();

  function addSelectedId(id) {
    const index = selectedUserList.indexOf(id);
    if (index !== -1) {
      const newSelectedUserList = [...selectedUserList];
      newSelectedUserList.splice(index, 1);
      setSelectedUserList([...newSelectedUserList]);
    } else {
      setSelectedUserList([...selectedUserList, id]);
    }
  }

  const columns = [
    {
      Header: '',
      accessor: 'editar',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="form-button">
          <Button
            className="btn-icon-editar-list"
            onClick={() => {
              history.push(`/cadastro-usuarios/${row.original.usuarioId}`);
            }}
          >
            <IconEditar />
          </Button>
        </div>
      ),
    },
    {
      Header: 'Redefinir Senha',
      accessor: 'redefinir-senha',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ row }) => (
        <div className="form-button" style={{ textAlign: 'center' }}>
          <Checkbox
            onChange={() => {
              addSelectedId(Number(row.original.usuarioId));
            }}
            checked={
              selectedUserList.indexOf(Number(row.original.usuarioId)) !== -1
            }
          />
        </div>
      ),
    },
    {
      Header: 'Tipo',
      accessor: 'usuarioTipo',
      disableSortBy: false,
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'CPF',
      accessor: 'cpf',
      disableSortBy: false,
      style: {
        minWidth: 140,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {value
            ? value.length > 11
              ? cnpjFormatter(value)
              : cpfFormatter(value)
            : ''}
        </div>
      ),
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Login',
      accessor: 'Login',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Matriz',
      accessor: 'matriz',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Unidade',
      accessor: 'unidade',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Ativo',
      accessor: 'ativo',
      disableSortBy: false,
      style: {
        minWidth: 80,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value ? 'Sim' : 'Não'}</div>,
    },
    {
      Header: 'Bloqueado',
      accessor: 'bloqueado',
      disableSortBy: false,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value ? 'Sim' : 'Não'}</div>,
    },
    {
      Header: 'Responsável',
      accessor: 'responsavel',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div>{value ? value : ''}</div>,
    },
    {
      Header: 'Cadastro',
      accessor: 'cadastro',
      disableSortBy: false,
      style: {
        minWidth: 120,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {moment(value).format('DD/MM/YYYY')}
        </div>
      ),
    },
  ];
  if (
    !checarPermissao('botao', 'botao.cadastro.usuarios.editar', 'Visualizar')
  ) {
    columns.shift();
  }
  if (!checarPermissao('botao', 'botao.cadastro.usuarios.redefinirSenha', 'Editar')) {
    const permissaoEditarIndex = columns
      .map((item) => item?.accessor)
      .indexOf('redefinir-senha');
    columns.splice(permissaoEditarIndex, 1);
  }

  return (
    <FormikProvider value={formik}>
      {showFilter && <Filter formik={formik} />}
      <div className="tabela-listagem-usuarios">
        <Table
          columns={columns}
          data={usuarios}
          filter={filters}
          onLoadData={fetchUsuarios}
          pageCount={pageCount}
          total={total}
          pagination={true}
          loading={loading}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </div>
    </FormikProvider>
  );
}

export default UsuariosList;
