import React, { useContext } from 'react';

import Card from '../../../components/Card';
import Table from '../../../components/BasicTable';

import columns from './columns';
import Toolbar from '../Toolbar';

import { RelatorioComissaoContext } from '../../../contexts/RelatorioComissaoContext';

function NotasFiscais() {
  const {
    pageIndex,
    setPageIndex,
    loadingNfs,
    totalNfs,
    filtersNfs,
    pageCountNfs,
    notasFiscais,
    fetchNfs,
  } = useContext(RelatorioComissaoContext);

  return (
    <Card>
      <div className="lista-comissoes-table">
        <Table
          columns={columns}
          data={notasFiscais}
          pageCount={pageCountNfs}
          loading={loadingNfs}
          onLoadData={fetchNfs}
          total={totalNfs}
          filter={filtersNfs}
          header={<Toolbar />}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </div>
    </Card>
  );
}

export default NotasFiscais;
