import React, { useContext, useState } from 'react';

import moment from 'moment';

import { Formik, Field } from 'formik';
import { Form, Input, DatePicker, Select } from 'formik-antd';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/BasicTable';
import Tag from '../../../components/Tag';
import Button from '../../../components/Button';
import InputPhone from '../../../components/InputPhone';

import { genericPhoneFormatter } from '../../../utils/textFormatter';
import InputMask from 'react-text-mask';
import { integerMask } from '../../../utils/masks';
import { cpfFormatter } from '../../../utils/textFormatter';

import { HistoricoGravacoesContext } from '../../../contexts/HistoricoGravacoesContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import Modal from '../../../components/Modal';
import Tooltip from '../../../components/tooltip';

import { ReactComponent as IconDowload } from '../../../assets/images/icons/download.svg';
import { ReactComponent as IconRelogio } from '../../../assets/images/icons/vector.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../../assets/images/icons/erase.svg';
import { ReactComponent as IconCalendar } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';


import './styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];

function ListagemHistoricoGravacoes() {
  const {
    listagemSituacoes,
    listagemHistoricoGravacoes,
    fetchListagemHistoricoGravacoes,
    total,
    pageCount,
    loading,
    setSearch,
    initialValuesFiltros,
    filters,
    downloadGravacao,
    search
  } = useContext(HistoricoGravacoesContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const [dates, setDates] = useState([]);
  const [value, setValue] = useState([moment().add(-1, 'days'), moment()]);
  const [hackValue, setHackValue] = useState();
  const [disableDownload, setDisableDownload] = useState(false);

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
    return tooLate;
  };

  const onOpenChange = (open) => {
    if (!open) {
      setHackValue(undefined);
    }
  };

  const [showFilter, setShowFilter] = useState(true);

  const columnsHistoricoGravacoes = [
    {
      Header: 'CPF',
      accessor: 'cpf',
      Cell: ({ value }) => cpfFormatter(value),
    },
    {
      Header: 'Cliente',
      accessor: 'nome',
      Cell: ({ value }) => value.toLowerCase(),
    },
    {
      Header: 'Data',
      accessor: 'dataHora',
      Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
    },
    {
      Header: 'Início',
      accessor: 'chamadaDataHoraInicio',
      Cell: ({ value }) =>
        value && (
          <Tag className="tag-horario" color="#ffffff">
            <IconRelogio />
            {value ? moment(value).format('HH:mm') : '--'}
          </Tag>
        ),
    },
    {
      Header: 'Duração',
      accessor: 'duracao',
      Cell: ({ value }) => (
        <Tag className="tag-horario" color="#ffffff">
          <IconRelogio />
          {value}
        </Tag>
      ),
    },
    {
      Header: 'Telefone',
      accessor: 'telefone',
      Cell: ({ value }) => (value ? genericPhoneFormatter(value) : ''),
    },
    {
      Header: 'Proposta',
      accessor: 'propostaId',
    },
    {
      Header: 'PA',
      accessor: 'ramal',
    },
    {
      Header: 'Analista',
      accessor: 'usuarioNome',
    },
    {
      Header: 'Situação',
      accessor: 'situacao',
      Cell: ({ value, row }) =>
        value && (
          <Tooltip title={row.original.motivo}>
            <div className='tooltip-chamada'>
              <div style={{ background: row.original.situacaoColor }} >
                {value}
              </div>
            </div>
          </Tooltip>
        ),
    },
    {
      Header: 'Confirm',
      accessor: 'confirmacao',
      Cell: ({ value }) => (value === null || value === false ? 'Não' : 'Sim'),
    },
  ];

  if (
    checarPermissao(
      'botao',
      'botao.backoffice.telefonia.baixarGravacao',
      'Visualizar',
    )
  ) {
    columnsHistoricoGravacoes.push({
      Header: '',
      accessor: 'linkGravacao',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <Button
            className="mb-1"
            type="button"
            onClick={() => {
              row.original.linkGravacao
                ? handleLink(value)
                : Modal('', 'Nenhuma gravação disponível', 'warning');
            }}
            disabled={disableDownload}
          >
            <IconDowload className="btn-download" />
          </Button>
        );
      },
    });
  }

  const handleLink = async (value) => {
    setDisableDownload(true);
    const data = await downloadGravacao(value).catch(() =>
      setDisableDownload(false),
    );

    if (data) {
      const audioFormatsWav = ['wave/wav', 'audio/wav'];
      const audioFormatsMpeg = ['audio/mpeg', 'audio/mp3'];

      // Baixa o arquivo em formato wav
      if (audioFormatsWav.includes(data.headers['content-type'])) {
        const url = window.URL.createObjectURL(
          new Blob([data.data], { type: 'wave/wav' }),
        );

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `gravacao.wav`);
        document.body.appendChild(link);
        link.click();
      }
      // Baixa o arquivo em formato mp3 
      else if (audioFormatsMpeg.includes(data.headers['content-type'])) {
        const url = window.URL.createObjectURL(
          new Blob([data.data], { type: 'audio/mpeg' }),
        );

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `gravacao.mp3`);
        document.body.appendChild(link);
        link.click();
      } else {
        // transforma a resposta de arrayBuffer para JSON, para que possa ser apresentado corretamente em tela
        const response = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(data.data)),
        );
        Modal('', response?.messages?.join(' \n \n '), 'warning');
      }
    }
    setDisableDownload(false);
  };

  function TooltipInfo({ text }) {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-info"
        overlayClassName="tooltip-proposta-credito"
        color="#435361"
        title={text}
      >
        <BulletInfo className="bullet-info-icon" />
      </Tooltip>
    );
  }

  return (
    <Card
      title={<Title label="Histórico de Gravações" />}
      rightBar={
        <FiltroHistorico
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      }
      className="listagem-historico-gravacoes"
    >
      {showFilter && (
        <div className="box-filtros">
          <Formik
            initialValues={initialValuesFiltros}
            onSubmit={async (data) => {
              if (
                !(
                  (data?.filtroCliente &&
                    /^[0-9]*$/.test(
                      data?.filtroCliente
                        ?.replaceAll('-', '')
                        ?.replaceAll('.', ''),
                    )) ||
                  data?.proposta ||
                  data?.filtroTelefone
                ) &&
                data?.filtroData &&
                data?.filtroData[1]?.diff(data?.filtroData[0], 'days') > 31
              ) {
                Modal('', 'Prazo máximo para consulta: 31 dias', 'warning');
              } else {
                const intPropostaId = parseInt(data?.proposta);
                setSearch({
                  pagina: 1,
                  filtroSituacao: data?.filtroSituacao,
                  filtroTelefone: data?.filtroTelefone?.replace(/\D/g, ''),
                  filtroConfirmacao: data?.filtroConfirmacao,
                  filtroDataHoraInicial: data?.filtroData
                    ? moment(data?.filtroData[0])?.format('YYYY-MM-DD')
                    : null,
                  filtroDataHoraFinal: data?.filtroData
                    ? moment(data?.filtroData[1])?.format('YYYY-MM-DD')
                    : null,
                  filtroPropostaId: intPropostaId,
                  filtroAnalista: data?.filtroAnalista,
                  filtroCliente: data.filtroCliente,
                });
              }
            }}
          >
            {({ resetForm, values, submitForm, setFieldValue }) => (
              <Form layout="vertical">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-inputs">
                    <div className="form-row">
                      <div className="col-sm-6">
                        <Form.Item name="filtroCliente">
                          <label style={{ margin: 0, marginRight: 10 }}>
                            Cliente
                          </label>
                          <TooltipInfo text="Se o CPF for informado não haverá restrição no filtro de data." />
                          <Input
                            name="filtroCliente"
                            placeholder="Pesquise pelo Nome ou CPF do Cliente"
                            suffix={<IconSearch />}
                            onChange={(val) => {
                              if (
                                val.target.value &&
                                /^[0-9]*$/.test(
                                  val.target.value
                                    ?.replaceAll('-', '')
                                    ?.replaceAll('.', ''),
                                )
                              ) {
                                setValue(undefined);
                                setDates(undefined);
                                setFieldValue('filtroData', undefined);
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-4">
                        <Form.Item label="Data" name="filtroData">
                          <RangePicker
                            name="filtroData"
                            allowClear={true}
                            value={hackValue || value}
                            format={dateFormatList}
                            suffixIcon={<IconCalendar />}
                            disabledDate={
                              values?.filtroTelefone ||
                                (values?.filtroCliente &&
                                  /^[0-9]*$/.test(
                                    values?.filtroCliente
                                      ?.replaceAll('-', '')
                                      ?.replaceAll('.', ''),
                                  )) ||
                                (values?.proposta &&
                                  values?.proposta?.replace(/\D/g, '') !== '')
                                ? null
                                : disabledDate
                            }
                            onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => {
                              setValue(val);
                              setFieldValue('filtroData', val);
                            }}
                            onOpenChange={onOpenChange}
                            defaultPickerValue={[
                              moment().add(-1, 'month'),
                              moment(),
                            ]}
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-2">
                        <Form.Item name="proposta">
                          <label style={{ margin: 0, marginRight: 10 }}>
                            Proposta
                          </label>
                          <TooltipInfo text="Se o Nº da proposta for informado não haverá restrição no filtro de data." />
                          <Field name="proposta">
                            {({ field }) => (
                              <InputMask
                                {...field}
                                value={values?.proposta || ''}
                                mask={integerMask}
                                name="proposta"
                                onChange={(val) => {
                                  setFieldValue('proposta', val.target.value);
                                  if (val.target.value.length > 0) {
                                    setValue(undefined);
                                    setDates(undefined);
                                    setFieldValue('filtroData', undefined);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </Form.Item>
                      </div>
                      <div className="col-sm-3">
                        <Form.Item
                          label="Nome do Analista"
                          name="filtroAnalista"
                        >
                          <Input name="filtroAnalista" />
                        </Form.Item>
                      </div>
                      <div className="col-sm-3">
                        <Form.Item name="filtroTelefone">
                          <label style={{ margin: 0, marginRight: 10 }}>
                            Telefone
                          </label>
                          <TooltipInfo text="Se o Nº de telefone for informado não haverá restrição no filtro de data." />
                          <Field name="filtroTelefone">
                            {({ field }) => (
                              <InputPhone {...field} className="ant-input" onChange={(val) => {
                                setFieldValue('filtroTelefone', val.target.value);
                                if (val.target.value.length > 0) {
                                  setValue(undefined);
                                  setDates(undefined);
                                  setFieldValue('filtroData', undefined);
                                }
                              }} />
                            )}
                          </Field>
                        </Form.Item>
                      </div>
                      <div className="col-lg-3">
                        <Form.Item label="Situação" name="filtroSituacao">
                          <Select
                            name="filtroSituacao"
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {listagemSituacoes.length > 0 &&
                              listagemSituacoes.map((situacao) => (
                                <Option key={situacao.id} value={situacao.id}>
                                  {situacao.nome}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-lg-3">
                        <Form.Item label="Confirmação" name="filtroConfirmacao">
                          <Select
                            name="filtroConfirmacao"
                            placeholder="Selecione"
                            suffixIcon={<IconArrowSelect />}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            <Option key={1} value={true}>
                              Sim
                            </Option>
                            <Option key={2} value={false}>
                              Não
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-lg-5"></div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-12 col-botoes">
                    <Button
                      type="submit"
                      className="btn-pesquisar"
                      variant="blue"
                      async={true}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      <IconSearch />
                      Pesquisar
                    </Button>
                    <Button
                      type="button"
                      className="btn-limpar"
                      variant="gray"
                      async={true}
                      onClick={() => {
                        setSearch(initialValuesFiltros);
                        setValue([moment().add(-1, 'days'), moment()]);
                        setDates([moment().add(-1, 'days'), moment()]);
                        resetForm();
                      }}
                    >
                      <IconErase />
                      Limpar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <Table
        columns={columnsHistoricoGravacoes}
        data={listagemHistoricoGravacoes}
        loading={loading}
        onLoadData={fetchListagemHistoricoGravacoes}
        total={total}
        pageCount={pageCount}
        pagination={true}
        filter={filters}
        search={search}
        onSearch={true}
      />
    </Card>
  );
}

function FiltroHistorico({ showFilter, setShowFilter }) {
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Button
      type="button"
      variant="gray"
      className="btn-filtrar"
      onClick={() => handleShowFilter()}
    >
      <FilterIcon />
      FILTRAR
    </Button>
  );
}

export default ListagemHistoricoGravacoes;
