import { object, string, number, array } from 'yup';
import * as Yup from 'yup';
import validaCep from '../../../utils/validaCep';
import validaCNPJ from '../../../utils/validaCnpj';
import validaCPF from '../../../utils/validaCpf';

Yup.addMethod(string, 'validateCNPJ', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCNPJ(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCPF(value) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateCPFCNPJ', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    const valida = validaCPF(value) || validaCNPJ(value);
    return valida || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(string, 'validateCEP', function (errorMessage) {
  return this.test(`test-cep`, errorMessage, function (value) {
    const { path, createError } = this;
    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCep(value) || createError({ path, message: errorMessage });
  });
});

export const validationSchemaUnidades = object().shape({
  dadosGerais: object().shape({
    cpfCnpj: string().validateCPFCNPJ('CPF/CNPJ inválido').nullable(),
    nome: string().required('Campo Obrigatório').nullable(),
  }),
  endereco: object().shape({
    cep: string().nullable(),
    logradouro: string().nullable(),
    bairro: string().nullable(),
    numero: string().nullable(),
    complemento: string().nullable(),
    cidadeId: number().required('Campo Obrigatório').nullable(),
    ufId: number().required('Campo Obrigatório').nullable(),
  }),
  produtos: object()
    .shape({
      tipoModalidade: string().nullable(),
      produtos: array()
        .of(
          Yup.object().shape({
            id: number().nullable(),
            modalidadeOpcoes: number().nullable(),
            propriedade: Yup.object({
              nome: string().nullable(),
              opcoes: array().of(number()).nullable(),
            }).nullable(),
          }),
        )
        .nullable(),
    })
    .nullable(),
  regioes: array()
    .of(
      Yup.object().shape({
        opera: Yup.boolean().nullable(),
        ufId: number().nullable(),
        cidadeId: number().nullable(),
      }),
    )
    .nullable(),
  comercial: object().shape({
    niveis: array().of(
      Yup.object({
        id: number().nullable(),
        posicaoId: number().nullable(),
      }),
    ),
    hierarquia: object().shape({
      id: number().nullable().required('Campo Obrigatório'),
      dataVigenciaInicial: string().nullable().required('Campo Obrigatório'),
      dataVigenciaFinal: string().nullable(),
      selecteds: array()
        .of(
          Yup.object().shape({
            posicaoId: number().nullable(),
          }),
        )
        .nullable(),
    }),
    historico: object().shape({}).nullable(),
  }),
});

export const validationSchemaUnidadesConvenio =  object().shape({
  dadosGerais: object().shape({
    cpfCnpj: string().validateCPFCNPJ('CPF/CNPJ inválido').nullable().required(),
    nome: string().nullable(),
  }),
  endereco: object().shape({
    cep: string().nullable(),
    logradouro: string().nullable(),
    bairro: string().nullable(),
    numero: string().nullable(),
    complemento: string().nullable(),
    cidadeId: number().nullable(),
    ufId: number().nullable(),
  }).nullable(),
  produtos: object()
    .shape({
      tipoModalidade: string().nullable(),
      produtos: array()
        .of(
          Yup.object().shape({
            id: number().nullable(),
            modalidadeOpcoes: number().nullable(),
            propriedade: Yup.object({
              nome: string().nullable(),
              opcoes: array().of(number()).nullable(),
            }).nullable(),
          }),
        )
        .nullable(),
    })
    .nullable(),
  regioes: array()
    .of(
      Yup.object().shape({
        opera: Yup.boolean().nullable(),
        ufId: number().nullable(),
        cidadeId: number().nullable(),
      }),
    )
    .nullable(),
  comercial: object().shape({
    niveis: array().of(
      Yup.object({
        id: number().nullable(),
        posicaoId: number().nullable(),
      }),
    ),
    hierarquia: object().shape({
      id: number().nullable(),
      dataVigenciaInicial: string().nullable(),
      dataVigenciaFinal: string().nullable(),
      selecteds: array()
        .of(
          Yup.object().shape({
            posicaoId: number().nullable(),
          }),
        )
        .nullable(),
    }),
    historico: object().shape({}).nullable(),
  }),
});

