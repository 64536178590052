import React, { createContext, useEffect, useState } from 'react';
import { postConsultaGeraTokenPromobank } from '../services/integracoesService';
import moment from 'moment';
import Modal from '../components/Modal';

export const IntegracoesContext = createContext({});

function IntegracoesProvider({ children }) {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [expirationDateString, setExpirationDateString] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null)

  const usuarioId = Number(localStorage.getItem('userId'));

  const consultarGerarToken = async (gerarToken) => {
    setLoading(true);
    const response = await postConsultaGeraTokenPromobank({
      usuarioId,
      gerarToken,
    });
    setLoading(false);

    if (response?.success) {
      setToken(response?.data?.token || '');

      const expirationDate = new Date(response?.data?.expiracao);
      setExpirationDateString(moment(expirationDate).format('DD/MM/YYYY'));
      const now = new Date();

      if (now > expirationDate) {
        setIsTokenExpired(true);
      } else {
        setIsTokenExpired(false);
      }

      return response;
    } else {
      Modal('', response?.messages[0], 'warning');
      setErrorMessage(response?.messages[0]);
    }
  };

  useEffect(() => {
    consultarGerarToken(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntegracoesContext.Provider
      value={{
        token,
        loading,
        setLoading,
        isTokenExpired,
        setIsTokenExpired,
        expirationDateString,
        setExpirationDateString,
        consultarGerarToken,
        errorMessage,
        setErrorMessage
      }}
    >
      {children}
    </IntegracoesContext.Provider>
  );
}

export default IntegracoesProvider;
