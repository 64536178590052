import React, { useState, useContext, useRef, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';

import { Switch, Button, Input, Select, Modal } from 'antd';

import { ReactComponent as ClipboardIcon } from '../../../assets/images/icons/clipboard.svg';

import CardIcon from '../../../components/CardIcon';
import ModalAviso from '../../../components/Modal';
import swal from 'sweetalert';
import Icon from '../../../components/Icon';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import './style.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import moment from 'moment';

const { TextArea } = Input;

const motivos = [
  { id: '1', value: 1, label: 'Pausa' },
  { id: '2', value: 2, label: 'Horário de Almoço' },
  { id: '3', value: 3, label: 'Reunião' },
  { id: '4', value: 4, label: 'Término do expediente' },
  { id: '99', value: 99, label: 'Outro' },
];

function StatusOperatividade() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [motivo, setMotivo] = useState(null);
  const [complementoMotivo, setComplementoMotivo] = useState('');

  const [refreshTimer, setRefreshTimer] = useState(0);
  const [timedOut, setTimedOut] = useState(false);
  const [extraTimeout, setExtraTimeout] = useState(1000000);
  const [inatividadeTimeout, setInatividadeTimeout] = useState(1000000);
  const [refreshTimeout, setRefreshTimeout] = useState(1000000);

  const idleTimer = useRef(null);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const {
    operatividade,
    saveOperatividade,
    isOnline,
    setOnline,
    loadingProposta,
    contexto,
  } = useContext(AnaliseMesaContext);

  const handleStatus = (value) => {
    if (!value) {
      idleTimer?.current?.pause();
      setMotivo(null);
      setComplementoMotivo('');
      setVisibleModal(true);
    } else {
      setOnline(true);
      saveOperatividade({ status: true });
      idleTimer?.current?.reset();
    }
  };

  const handleMotivo = (value) => {
    setMotivo(value);
  };

  const handleComplementoMotivo = (e) => {
    setComplementoMotivo(e.target.value);
  };

  const handleCancel = () => {
    setVisibleModal(false);
    idleTimer?.current?.reset();
  };

  const submitOperatividade = () => {
    setOnline(false);
    let temp = { status: false, motivo: null, complementoMotivo: null };
    temp.motivo = motivo;
    temp.complementoMotivo = complementoMotivo;
    saveOperatividade(temp);
    setVisibleModal(false);
  };

  const onAction = (e) => {
    localStorage.setItem('idle-timer', moment());
    idleTimer?.current?.reset();
  };

  const onActive = (e) => {
    idleTimer?.current?.reset();
    localStorage.setItem('idle-timer', moment());
  };

  const onIdle = (e) => {
    if (isOnline) {
      setRefreshTimer(refreshTimer + 1);
      let globalTimer = moment().diff(
        moment(localStorage.getItem('idle-timer')),
        'seconds',
      );
      if (globalTimer >= inatividadeTimeout) {
        if (!timedOut) {
          ModalAviso(
            '',
            'Identificado inatividade do usuário. Para que o usuário não seja inativado, favor acionar o botão Confirmar',
            'warning',
            onActive,
            'Confirmar',
          );
        }
        setTimedOut(true);
        if (timedOut && globalTimer >= inatividadeTimeout + extraTimeout) {
          setOnline(false);
          saveOperatividade({ status: false, motivo: 0 });
          swal.close();
        }
      } else if (timedOut) {
        swal.close();
        setTimedOut(false);
      } else if (refreshTimer >= refreshTimeout) {
        setRefreshTimer(0);
      }
      idleTimer?.current?.reset();
    }
  };

  useEffect(() => {
    if (isOnline == null) setOnline(operatividade?.status);
    setInatividadeTimeout(operatividade?.tempoInatividade);
    setExtraTimeout(operatividade?.tempoExcedenteInatividade);
    setRefreshTimeout(operatividade?.tempoAtualizacaoProposta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatividade]);

  useEffect(() => {
    if (isOnline) {
      idleTimer?.current?.reset();
    } else {
      idleTimer?.current?.pause();
    }
  }, [isOnline]);

  return (
    <CardIcon
      title="Status Operatividade"
      icon={<ClipboardIcon className="mr-1" />}
      rightBar={
        checarPermissao(
          'botao',
          'botao.analiseMesaCredito.statusOperatividade',
          'Visualizar',
        ) ? (
          <Switch
            size="small"
            name="status"
            checked={isOnline}
            onChange={handleStatus}
            className="ml-2 switch-red-if-off"
            disabled={
              checarPermissao(
                'botao',
                'botao.analiseMesaCredito.statusOperatividade',
                'Editar',
              )
                ? contexto?.bloqueiaAcessoSimultaneo
                  ? true
                  : loadingProposta
                : true
            }
          />
        ) : (
          <> </>
        )
      }
    >
      <IdleTimer
        ref={idleTimer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={1000}
      />

      <div className="nome-pessoa">{localStorage.getItem('login')}</div>

      <Modal
        title="Status Operatividade"
        visible={visibleModal}
        zIndex={999}
        onCancel={handleCancel}
        footer={null}
      >
        <form id="formModal">
          <div className="container">
            <div className="row">
              <label>Motivo paralização</label>
            </div>
            <div className="row">
              <Select
                value={motivo}
                onChange={handleMotivo}
                options={motivos}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </div>

            {motivo === 99 && (
              <div>
                <div className="row">
                  <label
                    style={{
                      marginRight: '10px',
                      marginTop: '20px',
                    }}
                  >
                    Descrição motivo
                  </label>
                </div>
                <div className="row">
                  <TextArea
                    rows={4}
                    onChange={handleComplementoMotivo}
                    placeholder="Escreva os detalhes"
                  />
                </div>
              </div>
            )}

            <div className="row justify-content-end pt-5">
              <Button
                type="primary"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#DEE2E6',
                  border: '1px solid #CFD2D4',

                  color: '#263659',
                  fontWeight: 400,
                  paddingLeft: '20px',
                  height: '35px',
                }}
                onClick={handleCancel}
              >
                <span>CANCELAR</span>
              </Button>

              <Button
                type="primary"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#1ab394',
                  border: '1px solid #16997F',
                  color: '#ffffff',
                  height: '35px',

                  marginLeft: '10px',
                  fontWeight: 400,
                }}
                onClick={submitOperatividade}
              >
                <Icon variant="include" className="mr-2" />
                <span>SALVAR</span>
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </CardIcon>
  );
}

export default StatusOperatividade;
