import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import { Formik, Field } from 'formik';
import { Form, Input, DatePicker, Select } from 'formik-antd';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

import { ReactComponent as IconSearch } from '../../../assets/images/icons/search.svg';
import { ReactComponent as IconErase } from '../../../assets/images/icons/erase.svg';
import { ReactComponent as IconCalendar } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import { RelatorioComissaoContext } from '../../../contexts/RelatorioComissaoContext';

import './styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];

function Filter() {
  const {
    contexto,
    filtersComissoes,
    filtersNfs,
    tipoRelatorio,
    setFiltersComissoes,
    setFiltersNfs,
    initialFiltersComissoes,
    initialFiltersNfs,
  } = useContext(RelatorioComissaoContext);

  const [value, setValue] = useState();
  const [dates, setDates] = useState([]);
  const [dateValue, setDateValue] = useState([
    moment().add(-1, 'days'),
    moment(),
  ]);
  const [hackValue, setHackValue] = useState();

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
    return tooLate;
  };

  const onOpenChange = (open) => {
    if (!open) {
      setHackValue(undefined);
    }
  };

  const [datesNf, setDatesNf] = useState([]);
  const [dateValueNf, setDateValueNf] = useState([
    moment().add(-1, 'days'),
    moment(),
  ]);
  const [hackValueNf, setHackValueNf] = useState();

  const disabledDateNf = (current) => {
    if (!datesNf || datesNf.length === 0) {
      return false;
    }

    const tooLate = datesNf[0] && current.diff(datesNf[0], 'days') > 31;
    return tooLate;
  };

  const onOpenChangeNf = (open) => {
    if (!open) {
      setHackValueNf(undefined);
    }
  };

  const handleCpfCnpj = (e) => {
    let data = e.target.value.replace(/\D/g, ''); // apenas dígitos
    if (data.length > 11) {
      // CNPJ
      let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
        5,
        3,
      )}/`; // ainda exibe máscara de CPF
      if (data.length > 12)
        // exibe máscara CNPJ
        cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
      else cnpj += data.substr(8);
      data = cnpj;
    } else {
      // CPF
      let cpf = '';
      let parts = Math.ceil(data.length / 3);
      for (let i = 0; i < parts; i++) {
        if (i === 3) {
          cpf += `-${data.substr(i * 3)}`;
          break;
        }
        cpf += `${i !== 0 ? '.' : ''}${data.substr(i * 3, 3)}`;
      }
      data = cpf;
    }
    setValue(data);
  };

  useEffect(() => {
    setDateValue(filtersComissoes?.rangeDataComissao);
    setDateValueNf(filtersNfs?.rangeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoRelatorio]);

  return (
    <div className="box-filtros">
      {tipoRelatorio !== 'nf' ? (
        <Formik
          initialValues={filtersComissoes}
          onSubmit={async (data) => {
            if (
              (!data?.cpf || data?.cpf?.replace(/\D/g, '') === '') &&
              data?.rangeDataComissao &&
              data?.rangeDataComissao[1]?.diff(
                data?.rangeDataComissao[0],
                'days',
              ) > 31
            ) {
              Modal('', 'Prazo máximo para consulta: 31 dias', 'warning');
            } else {
              const cpf =
                data?.cpf !== '' ? data?.cpf?.replace(/\D/g, '') : null;
              setFiltersComissoes({ ...data, cpf, pagina: 1 });
            }
          }}
        >
          {({ resetForm, submitForm, values, setFieldValue }) => (
            <Form layout="vertical">
              <div className="row">
                <div className="col-lg-10 col-md-12 col-inputs">
                  <div className="form-row">
                    <div className="col-sm-4">
                      <Form.Item
                        label="Data de comissão"
                        name="rangeDataComissao"
                      >
                        <RangePicker
                          name="rangeDataComissao"
                          format={dateFormatList}
                          suffixIcon={<IconCalendar />}
                          defaultPickerValue={[
                            moment().add(-1, 'month'),
                            moment(),
                          ]}
                          value={hackValue || dateValue}
                          disabledDate={
                            values?.cpf &&
                            values?.cpf?.replace(/\D/g, '') !== ''
                              ? null
                              : disabledDate
                          }
                          onCalendarChange={(val) => setDates(val)}
                          onChange={(val) => setDateValue(val)}
                          onOpenChange={onOpenChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-3">
                      <Form.Item label="CPF/ CNPJ do cliente" name="cpf">
                        <Field name="cpf">
                          {({ field }) => (
                            <Input
                              {...field}
                              placeholder="Ex.: 000.000.000-00"
                              className="ant-input"
                              value={value}
                              onChange={(e) => {
                                handleCpfCnpj(e);
                              }}
                            />
                          )}
                        </Field>
                      </Form.Item>
                    </div>
                    <div className="col-sm-2">
                      <Form.Item
                        label="Código de operação"
                        name="codigoOperacao"
                      >
                        <Input
                          name="codigoOperacao"
                          placeholder="Digite aqui"
                          type="number"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-3">
                      <Form.Item label="Produto" name="filtroProduto">
                        <Select
                          name="filtroProduto"
                          placeholder="Selecione um produto"
                          suffixIcon={<IconArrowSelect />}
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {contexto?.produto?.map((produto) => (
                            <Option key={produto?.id} value={produto?.id}>
                              {produto?.Nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {tipoRelatorio === 'pago' && (
                      <div className="col-sm-4">
                        <Form.Item
                          label="Data de pagamento"
                          name="rangeDataPagamento"
                        >
                          <RangePicker
                            name="rangeDataPagamento"
                            format={dateFormatList}
                            suffixIcon={<IconCalendar />}
                            defaultPickerValue={[
                              moment().add(-1, 'month'),
                              moment(),
                            ]}
                          />
                        </Form.Item>
                      </div>
                    )}

                    <div className="col-sm-3">
                      <Form.Item
                        label="Matriz"
                        name="matriz"
                        className="field-control"
                      >
                        <Select
                          name="matriz"
                          placeholder="Selecione uma matriz"
                          mode="multiple"
                          suffixIcon={<IconArrowSelect />}
                          allowClear
                          getPopupContainer={(trigger) => trigger.parentNode}
                        >
                          {contexto?.matriz?.map((matriz) => (
                            <Option key={matriz?.id} value={matriz?.id}>
                              {matriz?.nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-12 col-botoes">
                  <Button
                    type="button"
                    className="btn-pesquisar"
                    variant="blue"
                    async={true}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    <IconSearch />
                    Pesquisar
                  </Button>
                  <Button
                    type="button"
                    className="btn-limpar"
                    variant="gray"
                    async={true}
                    onClick={() => {
                      setFiltersComissoes(initialFiltersComissoes);
                      setDateValue([moment().add(-1, 'days'), moment()]);
                      setDates([moment().add(-1, 'days'), moment()]);
                      setFieldValue('rangeDataComissao', [
                        moment().add(-1, 'days'),
                        moment(),
                      ]);
                      setValue();
                      resetForm();
                    }}
                  >
                    <IconErase />
                    Limpar
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <Formik
          initialValues={filtersNfs}
          onSubmit={async (data) => {
            setFiltersNfs({ ...data, pagina: 1 });
          }}
        >
          {({ resetForm, submitForm, setFieldValue, values }) => (
            <Form layout="vertical">
              <div className="row d-flex align-items-center">
                <div className="col-lg-10 col-md-12 col-inputs">
                  <div className="form-row">
                    <div className="col-sm-3">
                      <Form.Item
                        label="Nome do arquivo"
                        name="filtroNomeArquivo"
                      >
                        <Input name="filtroNomeArquivo" />
                      </Form.Item>
                    </div>

                    <div className="col-sm-3">
                      <Form.Item label="Matriz" name="filtroNomeMatriz">
                        <Input name="filtroNomeMatriz" />
                      </Form.Item>
                    </div>

                    <div className="col-sm-4">
                      <Form.Item label="Data de inserção" name="rangeData">
                        <RangePicker
                          name="rangeData"
                          allowClear={true}
                          format={dateFormatList}
                          suffixIcon={<IconCalendar />}
                          defaultPickerValue={[
                            moment().add(-1, 'month'),
                            moment(),
                          ]}
                          value={hackValueNf || dateValueNf}
                          disabledDate={disabledDateNf}
                          onCalendarChange={(val) => setDatesNf(val)}
                          onChange={(val) => setDateValueNf(val)}
                          onOpenChange={onOpenChangeNf}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-12 col-botoes">
                  <Button
                    type="button"
                    className="btn-pesquisar"
                    variant="blue"
                    async={true}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    <IconSearch />
                    Pesquisar
                  </Button>
                  <Button
                    type="button"
                    className="btn-limpar"
                    variant="gray"
                    async={true}
                    onClick={() => {
                      setFiltersNfs(initialFiltersNfs);
                      setDateValueNf([moment().add(-1, 'days'), moment()]);
                      setDatesNf([moment().add(-1, 'days'), moment()]);
                      setFieldValue('rangeData', [
                        moment().add(-1, 'days'),
                        moment(),
                      ]);
                      resetForm();
                    }}
                  >
                    <IconErase />
                    Limpar
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default Filter;
