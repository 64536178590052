import React, { useContext, useRef } from 'react';
import { Collapse } from 'antd';

import { Input } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';

import Table from '../../../components/FullTable';

import { columnsContratosRealizado } from './columnsContratosRealizado';
import { columnsPropostas } from './columnsPropostas';
import { columnsBureaux } from './columnsBureaux';
import { columnsAlteracoes } from './columnsAlteracoes';
import dataContratosRealizado from '../../../data/db-contratos-realizado.json';
import dataBureaux from '../../../data/db-historico-bureaux.json';
import dataAlteracoes from '../../../data/db-historico-alteracoes.json';

import painelHistoricos from '../../../data/PanelHistoricos.json';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

const { Panel } = Collapse;


function SwitchCaseColumns(Columns) {
  switch (Columns) {
    case 'columnsContratosRealizado':
      return columnsContratosRealizado;
    case 'columnsPropostas':
      return columnsPropostas;
    case 'columnsBureaux':
      return columnsBureaux;
    case 'columnsAlteracoes':
      return columnsAlteracoes;
    default:
      return '';
  }
}

function CollapseTabelas() {
  const { checarPermissao } = useContext(ControleAcessoContext);

  const { historicoPropostaFiltrado, filtroHistoricoProposta } = useContext(AnaliseMesaContext)

  const inputRefs = [useRef(null), useRef(null)]

  return (
    <div className="collapse-historicos">
      <Collapse
        expandIconPosition="left"
        expandIcon={({ isActive }) => {
          return (<CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{ color: '#ff7817' }}
          />);
        }}
        className="mt-4 text-include-icon"
        collapsible='header'
      >
        {painelHistoricos.length > 0 &&
          // eslint-disable-next-line
          painelHistoricos.map((item, i) => {
            if (
              // eslint-disable-next-line
              (item.id == 1 &&
                checarPermissao(
                  'tabela',
                  'tabela.analiseMesaCredito.historicoPropostas',
                  'Visualizar',
                )) ||
                // eslint-disable-next-line
              (item.id == 2 &&
                checarPermissao(
                  'tabela',
                  'tabela.analiseMesaCredito.historicoAlteracaoDados',
                  'Visualizar',
                ))
            ) {
              let data;

              switch (item.data) {
                case 'dataContratosRealizado':
                  data = dataContratosRealizado;
                  break;
                case 'dataPropostas':
                  data = historicoPropostaFiltrado;
                  break;
                case 'dataBureaux':
                  data = dataBureaux;
                  break;
                case 'dataAlteracoes':
                  data = dataAlteracoes;
                  break;
                default:
                  data = '';
                  break;
              }

              var columns = SwitchCaseColumns(item.columns);
              return (
                <Panel
                  header={item.nome}
                  key={item.id}
                  className="mesa-credito-tabela"
                  extra={
                    <div className="table-filter">
                      <Input
                        className="filter-input"
                        placeholder="Pesquise na tabela"
                        suffix={<SearchOutlined />}
                        ref={inputRefs[i]}
                        onChange={(event) => {
                          // eslint-disable-next-line
                          if (item.id == 1) {
                            filtroHistoricoProposta(event.target.value)
                          }
                          // Timeout que garante que ao mudar o tamanho do Table, mesmo havendo mudança no tamanho da página,
                          // o input manterá foco o tempo todo
                          setTimeout(() => {
                            inputRefs[i].current.focus();
                          }, 10)
                        }}
                      />
                    </div>
                  }
                  collapsible='header'
                >
                  <div className="tabelaListagem">
                    <Table
                      data={data}
                      columns={columns}
                      pagination={true}
                      filter={false}
                      pageSize={5}
                    />
                  </div>
                </Panel>
              );
            }
          })}
      </Collapse >
    </div>

  );
}

export default CollapseTabelas;
