import api from './api';

export const listarMotivos = async () => {
  try {
    const { data } = await api.get('/bloqueado/cpf');
    return data.data;
  } catch (error) {
    return error;
  }
};

export const listarCPFs = async ({
  filtroCPF = '',
  filtroAtivos = true,
  ordenacao = 'inclusao',
  ordenacaoAsc = false,
  pagina = 1,
}) => {
  try {
    const { data } = await api.post('/bloqueado/cpf/lista', {
      filtroCPF,
      filtroAtivos,
      ordenacao,
      ordenacaoAsc,
      pagina,
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const inserirCPF = async (cpfBlocklist) => {
  const dados = {
    cpf: cpfBlocklist.cpf,
    motivoId: parseInt(cpfBlocklist.motivoId),
    descricao: cpfBlocklist.descricaoInclusao,
  };

  const { data } = await api.post('/bloqueado/cpf', dados);
  return data;
};

export const editarCPF = async (cpfBlocklist) => {
  try {
    const dados = {
      id: cpfBlocklist.id,
      descricao: cpfBlocklist.descricaoExclusao,
    };

    const { data } = await api.put(`bloqueado/cpf/${dados.id}`, dados);

    return data;
  } catch (error) {
    return error;
  }
};
