import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import useInspinia from '../../hooks/useInspinia';

import SimulacaoProvider from '../../contexts/SimulacaoContext';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import SimulacaoForm from './SimulacaoForm';
import SimulacaoList from './SimulacaoList';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function Simulacao() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Simulação</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <SimulacaoProvider>
          {checarPermissao('tela','tela.credito.proposta.simulacao','Visualizar') && <> 
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <SimulacaoForm />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <SimulacaoList />
              </div>
            </div>
          
          </>}
        </SimulacaoProvider>
      </Content>
    </Main>
  );
}

export default Simulacao;
