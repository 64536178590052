import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Digital } from '../../assets/images/icons/digital.svg';
import { ReactComponent as Fisico } from '../../assets/images/icons/fisica.svg';

function Modalidade({ value }) {
  switch (value) {
    case 1:
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Fisico />
          <span className="ml-1">Físico</span>
        </div>
      );

    case 2:
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Digital />
          <span className="ml-1">Digital</span>
        </div>
      );

    default:
      return null;
  }
}

Modalidade.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Modalidade;
