import React, { useEffect } from 'react';
import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';
import { cpfFormatter } from '../../../utils/textFormatter';
import logoQuadrado from '../../../assets/images/crefazon-logo-quadrado.svg';

import './styles.scss';

export default function PdfComissao() {
  const cabecObj = [
    'Cod. Operação',
    'Pagamento',
    'CNPJ / CPF',
    'Cliente',
    'Valor da Operação',
    'Valor Líquido',
    'Prazo',
    'Matriz',
    'Produto',
    'UF',
    'Comissão (%)',
    'Comissão (R$)',
    'Empresa',
    'Data Comissão',
  ];

  const relatorioComissao = JSON.parse(
    sessionStorage.getItem('relatorioComissaoImpressao'),
  );

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <div className="bg-white">
      <table className="tabela">
        <thead className="tHead">
          <tr>
            <td colSpan={cabecObj?.length}>
              <div className="header">
                <div>
                  <div className="title">Relatório Visão de Comissão</div>
                  <div className="subtitle">
                    Data de geração:{' '}
                    {moment(relatorioComissao?.dataGeracao).format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </div>
                </div>
                <img
                  className="logo"
                  src={logoQuadrado}
                  alt="Crefaz - Crédito Consciente"
                  width="56"
                />
              </div>
            </td>
          </tr>
          <tr>
            {cabecObj.map((dado) => (
              <td className="tHeadItem">{dado}</td>
            ))}
          </tr>
        </thead>
        <tbody className="tBody">
          {relatorioComissao?.itens?.map((comissao) => (
            <tr className="trBody">
              <td className="tBodyItem">{comissao?.codigoOperacao}</td>
              <td className="tBodyItem">
                {comissao?.dataPagamento &&
                  moment(comissao?.dataPagamento).format('DD/MM/YYYY HH:mm')}
              </td>
              <td className="tBodyItem">{cpfFormatter(comissao?.cnpjCpf)}</td>
              <td className="tBodyItem">{comissao?.cliente}</td>
              <td className="tBodyItem">
                {currencyFormat(comissao?.valorOperacao)}
              </td>
              <td className="tBodyItem">
                {currencyFormat(comissao?.valorLiquido)}
              </td>
              <td className="tBodyItem">{comissao?.prazo}</td>

              <td className="tBodyItem">{comissao?.matriz}</td>
              <td className="tBodyItem">{comissao?.produto}</td>
              <td className="tBodyItem">{comissao?.uf}</td>
              <td className="tBodyItem">{`${comissao?.percentualComissao}%`}</td>
              <td className="tBodyItem">
                {currencyFormat(comissao?.valorComisssao)}
              </td>
              <td className="tBodyItem">{comissao?.empresa}</td>
              <td className="tBodyItem">
                {comissao?.dataComissao &&
                  moment(comissao?.dataComissao).format('DD/MM/YYYY HH:mm')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
