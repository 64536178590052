import React, { useEffect } from 'react';
import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';
import { cpfFormatter } from '../../../utils/textFormatter';
import logoQuadrado from '../../../assets/images/crefazon-logo-quadrado.svg';

import './styles.scss';

export default function PdfEsteiraCredito() {
  const cabecObj = [
    'Status',
    'Data cadastro',
    'Data alteração',
    'Produto',
    'CPF',
    'Nome do cliente',
    'Tipo de cliente',
    'Modalidade',
    'Matriz',
    'Agente',
    'Valor',
  ];

  const relatorioEsteiraImpressao = JSON.parse(
    sessionStorage.getItem('relatorioEsteiraImpressao'),
  );

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <div className="relatorio-esteira-pdf bg-white">
      <table className="tabela">
        <thead className="tHead">
          <tr>
            <td colSpan={cabecObj?.length}>
              <div className="header">
                <div>
                  <div className="title">
                    Relatório visão esteira de crédito
                  </div>
                  <div className="subtitle">
                    Data de geração:{' '}
                    {moment(
                      relatorioEsteiraImpressao?.data?.dataGeracao,
                    ).format('DD/MM/YYYY HH:mm')}
                  </div>
                </div>
                <img
                  className="logo"
                  src={logoQuadrado}
                  alt="Crefaz - Crédito Consciente"
                  width="56"
                />
              </div>
            </td>
          </tr>
          <tr>
            {cabecObj.map((dado) => (
              <td className="tHeadItem">{dado}</td>
            ))}
          </tr>
        </thead>
        <tbody className="tBody">
          {relatorioEsteiraImpressao?.data?.itens?.map((proposta) => (
            <tr className="trBody">
              <td className="tBodyItem">{proposta?.situacaoDescricao}</td>
              <td className="tBodyItem">
                {moment(proposta?.cadastro).format('DD/MM/YYYY HH:mm')}
              </td>
              <td className="tBodyItem">
                {moment(proposta?.ultimaAlteracao).format('DD/MM/YYYY HH:mm')}
              </td>
              <td className="tBodyItem">{proposta?.produto}</td>
              <td className="tBodyItem">{cpfFormatter(proposta?.cpf)}</td>
              <td className="tBodyItem">{proposta?.cliente}</td>
              <td className="tBodyItem">{proposta?.tipoCliente}</td>
              <td className="tBodyItem">{proposta?.modalidade}</td>
              <td className="tBodyItem">{proposta?.matrizNome}</td>
              <td className="tBodyItem">{proposta?.agente}</td>
              <td className="tBodyItem">{currencyFormat(proposta?.valor)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
