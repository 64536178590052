import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import './styles.scss';

import Card from '../../components/Card';
import Title from '../../components/Title';
import Tooltip from '../../components/tooltip';

import { ReactComponent as IconSendButton } from '../../assets/images/icons/icon-send-button.svg';
import { ReactComponent as IconSaveButton } from '../../assets/images/icons/save.svg';
import { ReactComponent as IconMsgEmpresaCrefaz } from '../../assets/images/icons/icon-mensagem-empresa-crefaz.svg';
import { ReactComponent as IconMsgVendedor } from '../../assets/images/icons/icon-mensagem-vendedor.svg';
import { ReactComponent as IconCloseTooltip} from '../../assets/images/icons/X-close-tooltip.svg';

import { Form, Input, Button } from 'antd';
const { TextArea } = Input;

function MessageItem({
  tipo,
  origem,
  data,
  horario,
  remetente,
  mensagem,
  listaMotivo,
  observacao,
}) {
  function formatHorario(horario) {
    if (horario) {
      if (horario.split('T') !== horario) {
        return horario.split('T')[1];
      }
    } else {
      const horario = new Date();
      return horario;
    }
    return horario;
  }
  if (!Number.isInteger(tipo)) {
    tipo = origem;
  }

  return (
    <div
      className={
        'row item-mensagem ' + (tipo === 1 ? 'msg-vendedor' : 'msg-empresa')
      }
    >
      <div className="info-autor">
        {tipo === 1 ? (
          <>
            <div className="icon-perfil perfil-vendedor">
              <IconMsgVendedor color="#676A6C" />
            </div>
          </>
        ) : (
          <>
            <div className="icon-perfil perfil-crefaz">
              <IconMsgEmpresaCrefaz />
            </div>
          </>
        )}
        <div className="data-hora">
          <p className="horario">
            {moment(formatHorario(horario)).isValid &&
              moment(formatHorario(horario), 'HH:mm:ss').format('HH:mm')}
          </p>
          <p className="data">
            {moment(data).isValid() && moment(data).format('DD/MM[\n]YYYY')}
          </p>
        </div>
      </div>
      <div className=" texto-mensagem">
        {tipo === 1 ? (
          <>
            <div className="remetente-title">{remetente}</div>{' '}
          </>
        ) : (
          <>
            <div className="remetente-title">
              {tipo === 0 ? 'Crefaz (Decisão automática)' : remetente}
            </div>{' '}
          </>
        )}
        <p>
          {mensagem}
          <br />
          {listaMotivo && listaMotivo.length > 1
            ? listaMotivo.map((item, indice) => {
                return (
                  <>
                    {item.nome}
                    {indice < listaMotivo.length - 1 && <hr />}
                  </>
                );
              })
            : listaMotivo && listaMotivo[0].nome}
          <br />
          <span>{observacao && <>Observação: {observacao}</>}</span>
        </p>
      </div>
    </div>
  );
}

function BodyConversationMessages({
  userId,
  title,
  mode,
  proposta,
  cpf,
  listMessages,
  sendMessage,
  scrollToBottom,
  handleChangeTooltip,
  messagesEndRef,
  type,
  disabled = false,
  visible,
  origem,
  readOnly,
}) {
  useEffect(() => {
    if (mode === 'tooltip' && visible) {
      // o componente precisa desse timout para poder exibir o tooltip na tela ANTES de rolar as mensagens para baixo
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    } else if (mode === 'screen') {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, listMessages, scrollToBottom]);

  const [form] = Form.useForm();

  const handleSendMessage = (data) => {
    moment.locale('pt-br');
    if (data.mensagem.trim()) {
      let message = {};
      if (type === 'chat') {
        message = {
          propostaId: proposta.id,
          origem: origem,
          mensagem: data.mensagem,
          ultimaMensagem: Math.max.apply(
            Math,
            listMessages.map((mensagem) => {
              return mensagem.id;
            }),
          ),
        };
      } else {
        message = {
          cpf: cpf || proposta.cliente.cpf,
          mensagem: data.mensagem,
        };
      }

      sendMessage(userId, message.mensagem);
    }

    form.resetFields();
  };

  return (
    <Card
      title={<Title label={title ? title : 'Central de Comunicação'} noLine />}
      className="ibox-conversation-messages"
    >
      {mode === 'tooltip' && (
        <Button onClick={() => handleChangeTooltip()} className="close-tooltip" id="close-button-tooltip">
          <IconCloseTooltip />
        </Button>
      )}

      <div className="mensagens">
        <div className="list-mensagens">
          {listMessages.map((mensagem) => {
            return (
              <MessageItem
                key={mensagem?.id}
                origem={origem}
                tipo={mensagem?.tipo}
                data={mensagem?.data}
                horario={mensagem?.horario}
                remetente={mensagem?.remetente || mensagem?.usuario}
                mensagem={mensagem?.mensagem || mensagem?.anotacao || mensagem}
                listaMotivo={mensagem?.listaMotivo}
                observacao={mensagem?.observacao}
                propostaDecisaoId={mensagem?.propostaDecisaoId}
              />
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        {!disabled && (
          <div className="form-row-mensagem form-textarea">
            <div className="col-12">
              <Form form={form} onFinish={handleSendMessage}>
                <Form.Item name="mensagem">
                  <TextArea
                    disabled={readOnly}
                    placeholder="Digite aqui sua observação"
                    onKeyUp={(e) => e.preventDefault()}
                  />
                </Form.Item>
                {type === 'chat' ? (
                  <Button
                    onClick={() => form.submit()}
                    className="btn-acao btn-salvar"
                    disabled={readOnly}
                  >
                    <IconSendButton />
                    Enviar
                  </Button>
                ) : (
                  <Button
                    onClick={() => form.submit()}
                    className="btn-acao btn-salvar"
                  >
                    <IconSaveButton />
                    Salvar
                  </Button>
                )}
              </Form>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

function ConversationMessages({
  userId,
  title,
  mode,
  proposta,
  propostaId,
  cpf,
  listMessages,
  sendMessage,
  placementTooltip,
  type,
  fixedTooltip,
  children,
  disabled = false,
  origem,
  customClass,
  readOnly,
}) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const handleChangeTooltip = () => {
    setVisibleTooltip(!visibleTooltip);
    scrollToBottom();
  };

  return (
    <>
      {mode === 'screen' ? (
        // Quando o componente for ser exibido direto na tela
        <BodyConversationMessages
          userId={userId}
          title={title}
          mode={mode}
          proposta={proposta}
          cpf={cpf}
          listMessages={listMessages}
          sendMessage={sendMessage}
          scrollToBottom={scrollToBottom}
          handleChangeTooltip={handleChangeTooltip}
          messagesEndRef={messagesEndRef}
          type={type}
          readOnly={readOnly}
          disabled={
            proposta?.situacaoId < 0 ||
            proposta?.situacaoId === 1 ||
            proposta?.situacaoId === 2 ||
            proposta?.situacaoId === 16 ||
            disabled
              ? true
              : false
          }
          origem={origem}
        />
      ) : (
        // Em caso de Tooltip
        <Tooltip
          trigger="click"
          placement={placementTooltip}
          getPopupContainer={(triggerNode) => triggerNode}
          overlayClassName={
            customClass +
            ' card-tooltip-messages ' +
            (fixedTooltip ? 'card-fixed' : '')
          }
          visible={visibleTooltip}
          onVisibleChange={handleChangeTooltip}
          title={
            <BodyConversationMessages
              userId={userId}
              title={title}
              mode={mode}
              proposta={proposta}
              cpf={cpf}
              listMessages={listMessages}
              sendMessage={sendMessage}
              scrollToBottom={scrollToBottom}
              handleChangeTooltip={handleChangeTooltip}
              messagesEndRef={messagesEndRef}
              type={type}
              readOnly={readOnly}
              disabled={
                proposta?.situacaoId < 0 ||
                proposta?.situacaoId === 1 ||
                proposta?.situacaoId === 2 ||
                proposta?.situacaoId === 16 ||
                disabled
                  ? true
                  : false
              }
              visible={visibleTooltip}
              origem={origem}
            />
          }
        >
          {children}
        </Tooltip>
      )}
    </>
  );
}

export default ConversationMessages;
