import axios from 'axios';
import Modal from '../components/Modal';
import { getToken } from './authService';

let baseURL = process.env.REACT_APP_API_INTEGRACAO_URL;

const integrationApi = axios.create({
  baseURL,
});

integrationApi.interceptors.request.use((config) => {
  const token = getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

integrationApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('authToken');
          localStorage.removeItem('login');
          localStorage.removeItem('userId');
          localStorage.removeItem('nome');
          localStorage.removeItem('telefonia');
          localStorage.removeItem('paginaAberta');
          localStorage.removeItem('telaAnterior');

          window.location = '/login';
          break;
        case 422:
          Modal(
            'Campos obrigatórios',
            error.response.data.errors.join(' \n \n '),
            'warning',
          );
          break;
        case 400:
          Modal('', error.response.data.message, 'warning');
          break;
        default:
          Modal(
            '',
            'Ocorreu um erro ao processar os dados, tente novamente mais tarde.',
            'error',
            () => (window.location = '/'),
          );
      }
    }

    return Promise.reject(error);
  },
);

export default integrationApi;
