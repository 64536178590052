import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { Button } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, Input } from 'formik-antd';
import Spinner from '../../components/Spinner';
import brand from '../../assets/images/crefazon-logo.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/crefazon-logo-alt.svg';
import { signIn } from '../../services/authService';
import { getEmailUsuarioRedefinicao } from '../../services/usuariosService';
import './styles.scss';

const validationForm = object({
  login: string().required('Campo obrigatório'),
  senha: string().required('Campo obrigatório'),
});

function Login() {
  const [error, setError] = useState('');
  const [usuarioStatus, setUsuarioStatus] = useState('');
  const [passwordStatus, setPasswordStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingEsqueceuSenha, setLoadingEsqueceuSenha] = useState(false);

  const history = useHistory();
  const formik = useFormik({
    initialValues: { login: '', senha: '' },
    validationSchema: validationForm,
    validateOnBlur: false,
    onSubmit: async (values) => {
      limpaValidacoesCampos()
      setLoading(true);
      const data = await signIn(values);
      if (data?.data?.token) {
        localStorage.setItem('authToken', data.data.token);
        localStorage.setItem('login', data.data.login);
        localStorage.setItem('userId', data.data.userId);
        localStorage.setItem('nome', data.data.nome);
        localStorage.setItem('telefonia', data.data.telefonia);
        history.push('/');
      } else if(data?.data?.sistemaIndisponivel) {
        history.push('/sistema-indisponivel');
      } else {
        setError(data?.messages[0]);
        setUsuarioStatus('error');
        setPasswordStatus('error');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formik.touched.login && formik.errors.login === 'Campo obrigatório') {
      setUsuarioStatus('warning');
    }

    if (formik.touched.senha && formik.errors.senha === 'Campo obrigatório') {
      setPasswordStatus('warning');
    }
  }, [formik]);

  const limpaValidacoesCampos = async() =>{
    setPasswordStatus('');
    setUsuarioStatus('');
    formik.setFieldTouched('senha', false)
    formik.setFieldError('senha', '')
    setError(null);
  }

  const validaUsuario = async () =>{
    if(formik.values.login){
      setLoadingEsqueceuSenha(true)
      const data = await getEmailUsuarioRedefinicao(formik.values.login)
      localStorage.setItem('dadosRedefinicao',JSON.stringify({...data, login: formik.values.login}));
      return data
    }
    else{
      formik.setFieldTouched('login')
      formik.validateField('login')
      setUsuarioStatus('warning');
    }
  }

  const validarRedefinicao = async() =>{
    await limpaValidacoesCampos()
    const data = await validaUsuario()

    if(data && (data?.email || data?.telefone)){
      history.push('/escolha-envio');
      setLoadingEsqueceuSenha(false)
    }else{
      setError(data?.mensagem);
      setUsuarioStatus('error');
      setLoadingEsqueceuSenha(false)
    }
  }

  return (
    <div className="login-container">
      <div className="row h-100">
        <div className="col-lg-6 login-brand">
          <div>
            <div className="brand">
              <img src={brand} alt="logo-crefazon" width="100%" />
            </div>
            <BrandWhite className="brand-white" />
          </div>
        </div>

        <div className="col-lg-6 login-form">
          <div style={{ width: '400px' }}>
            <div className="login-title">
              <h2>Entrar</h2>
              <div className="underline"></div>
            </div>
            <FormikProvider value={formik}>
              <Form layout="vertical" autoComplete="off">
                <Form.Item
                  name="login"
                  label="Usuário"
                  className="login-item"
                  hasFeedback
                  validateStatus={usuarioStatus}
                  style={{marginTop: '15px'}}
                >
                  <Input
                    type="text"
                    name="login"
                    size="large"
                    className="login-input"
                    data-cy="login-input"
                    required
                  />
                </Form.Item>

                <Form.Item
                  name="senha"
                  label="Senha"
                  className="login-item"
                  hasFeedback
                  validateStatus={passwordStatus}
                >
                  <Input
                    type="password"
                    name="senha"
                    size="large"
                    className="login-input"
                    data-cy="login-password"
                    required
                  />
                </Form.Item>

                {error && <div className="login-error redefinicao">{error}</div>}

                <Button
                  htmlType="submit"
                  type="primary"
                  className='btn-login'
                  block
                  style={{
                    backgroundColor: '#ff7817',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    color: '#263659',
                    fontWeight: 600,
                    height: '50px',
                    marginTop: '25px',
                  }}
                  data-cy="login-submit"
                >
                  {!loading ? (
                    'ENTRAR'
                  ) : (
                    <Spinner color="#26417B" width={4.54} margin={2.27} />
                  )}
                </Button>

                <Button
                  type="primary"
                  className='btn-login'
                  block
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    color: '#ff7817',
                    fontWeight: 600,
                    height: '50px',
                    marginTop: '15px',
                  }}
                  onClick={validarRedefinicao}
                >
                  {!loadingEsqueceuSenha ? (
                    'ESQUECEU SUA SENHA?'
                  ) : (
                    <Spinner color="#ff7817" width={4.54} margin={2.27} />
                  )}
                </Button>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
