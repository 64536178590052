import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import BlocklistCPFProvider from '../../contexts/BlocklistCPFContext';

import ListBlocklist from './ListBlocklist';
import FormBlocklist from './FormBlocklist';

import './styles.scss';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function BlocklistCPF() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>BackOffice</Breadcrumb.Item>
          <Breadcrumb.Item>Blocklist</Breadcrumb.Item>
          <Breadcrumb.Item>Blocklist CPF</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <BlocklistCPFProvider>
          {checarPermissao(
            'tela',
            'tela.backoffice.blocklist.cpf',
            'Visualizar',
          ) && (
            <>
              <div className="row blocklist">
                {checarPermissao(
                  'frame',
                  'frame.blocklistCpf',
                  'Visualizar',
                ) && (
                  <div className="col-sm-6 col-list">
                    <ListBlocklist />
                  </div>
                )}
                {checarPermissao(
                  'frame',
                  'frame.blocklistCpf.edicao',
                  'Visualizar',
                ) && (
                  <div className="col-sm-6 col-inclusao-edicao">
                    <FormBlocklist />
                  </div>
                )}
              </div>
            </>
          )}
        </BlocklistCPFProvider>
      </Content>
    </Main>
  );
}

export default BlocklistCPF;
