import React, { createContext, useEffect, useState, useCallback } from 'react';

import {
  getStatusCNPJ,
  getCredenciamento,
  putCredenciamento,
  cadastrarCorrespondente,
} from '../services/correspondenteService';
import {
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

import { buscarParametro } from '../services/parametroService';
import { carregarImagem } from '../services/imagemService';

export const CorrespondenteContext = createContext({});

function CorrespondenteProvider({ children }) {
  const [showUpload, setShowUpload] = useState(false);
  const [tipo, setTipo] = useState(1);
  const [fileIds, setFileIds] = useState([]);
  const [parametroPrazo, setParametroPrazo] = useState(30);

  const fetchCredenciamento = useCallback(async (id) => {
    const response = await getCredenciamento(id);
    return response.data;
  }, []);

  const fetchUfs = async () => {
    const response = await listarUfs();
    return response;
  };

  const fetchCidades = async (ufId) => {
    const response = await listarCidades(ufId);
    return response;
  };

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    return response;
  };

  const fetchParametroPrazo = async () => {
    const parametroPrazo = 'PRAZO_DIAS_VISITA';

    const { valor } = await buscarParametro(parametroPrazo);
    setParametroPrazo(valor);
  };

  const fetchStatusCNPJ = async (cnpj) => {
    const response = await getStatusCNPJ(cnpj);
    return response;
  };

  useEffect(() => {
    fetchParametroPrazo();
  }, []);

  return (
    <CorrespondenteContext.Provider
      value={{
        showUpload,
        setShowUpload,
        fetchUfs,
        fetchCidades,
        fetchEndereco,
        fetchStatusCNPJ,
        cadastrarCorrespondente,
        tipo,
        setTipo,
        carregarImagem,
        fileIds,
        setFileIds,
        parametroPrazo,
        fetchCredenciamento,
        putCredenciamento,
      }}
    >
      {children}
    </CorrespondenteContext.Provider>
  );
}

export default CorrespondenteProvider;
