import api from './api';

export const listarFollowUp = async () => {
  let followup = sessionStorage.getItem('followup');
  if(!followup) {
    try {
      const { data } = await api.get(`/proposta/followup`);
      sessionStorage.setItem('followup', JSON.stringify(data.data));
      return data.data;
    } catch (error) {
      return error;
    }
  }
  followup = JSON.parse(followup);
  return followup;
};

export const getFollowUpCard = async (produtoGrupoId) => {
  try {
    const { data } = await api.get(`/proposta/followup-card/${produtoGrupoId}`);
    return data.data;
  } catch (error) {
    return error;
  }
};
