import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import Timer from '../../components/Timer'
import { ReactComponent as IconCheck } from '../../assets/images/icons/circle-check.svg'
import brand from '../../assets/images/crefazon-logo.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/crefazon-logo-alt.svg';
import './styles.scss';

function ConfirmacaoEmail() {

  const history = useHistory();
  const dadosUsuarioRedefinicao = JSON.parse(localStorage.getItem('dadosRedefinicao'));

  return (
    <div className="login-container">
      <div className="row h-100">
        <div className="col-lg-6 login-brand">
          <div>
            <div className="brand">
              <img src={brand} alt="logo-crefazon" width="100%" />
            </div>
            <BrandWhite className="brand-white" />
          </div>
        </div>

        <div className="col-lg-6 login-form">
          <div style={{ width: '431px' }}>
            <div className="login-title" style={{ textAlign: 'center' }}>
                <IconCheck/>
              <h2>{dadosUsuarioRedefinicao.tipo === "e-mail" ? "E-mail" : "SMS"} enviado</h2>
              <p className='info-login confirmacao' style={{marginTop:'15px'}}
              >
                {dadosUsuarioRedefinicao.tipo === 'e-mail' ? 
                  <>
                    Siga as instruções enviadas para o e-mail <span className='tipo-redefinicao'> {dadosUsuarioRedefinicao.email}</span> para redefinir sua senha.
                  </>
                  :
                  <>
                    Foi enviado um sms com link de redefinição de senha para o número <span className='tipo-redefinicao'>{dadosUsuarioRedefinicao.telefone}</span>
                  </>
                }
              </p>
            </div>

            <div className='footer-itens'>
                <Timer type={dadosUsuarioRedefinicao.tipo}></Timer>
            </div>

            <Button
                type="primary"
                className='btn-login'
                block
                style={{
                backgroundColor: '#ff7817',
                border: '1px solid #ff7817',
                borderRadius: 0,
                color: '#263659',
                fontWeight: 600,
                height: '50px',
                marginTop: '15px',
                }}
                onClick={() => history.push('/login')}
            >
               VOLTAR
            </Button>
             
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmacaoEmail;
