import React from 'react';
import Overlay from '../Overlay';

import Tag from '../Tag';

import './styles.scss';

function WidgetRelatorioAnalitico({
  title,
  total,
  direcionadas,
  color,
  estagio,
  porcentagem,
  loading,
}) {
  return (
    <Overlay active={loading}>
      <div className="widget-relatorio-analitico">
        <div className="widget-header">
          <div className="widget-title">{title}</div>
          {estagio && <Tag color={color} className="widget-container-tag">{estagio}</Tag>}
        </div>
        <div className="widget-content">
          <div className="first-data-group">
            <h2 className="total-value" style={{ color: color }}>
              {total || 0}
            </h2>
            <span>Total</span>
          </div>

          <div className="content-data-group mt-3">
            <div className="group-direcionadas">
              <h2 className="direcionadas">{direcionadas || 0}</h2>
              <p className="porcentagem">{`${porcentagem || 0}%`}</p>
            </div>

            <span className="span-direcionadas">Propostas direcionadas</span>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

export default WidgetRelatorioAnalitico;
