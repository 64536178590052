import React from 'react';
import { Tooltip } from 'antd';
import Tag from '../../components/Tag';

import { ReactComponent as BulletInfo } from '../../assets/images/icons/bullet-info-tooltip.svg';

import Table from '../../components/FullTable';

import { columnsUnidadeConsumidora } from '../../pages/AnaliseMesaCredito/Forms/ColumnsTables/columnsUnidadeConsumidora';

const TooltipUnidadeConsumidora = (data) => {
  return (
    <Tooltip
      placement="right"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito"
      color="#435361"
      title={
        <div className="outsideBorder">
          <p>
            {data?.data?.nome}: {data?.data?.valor}
            {data?.data?.validaDadosAdicionaisExistente?.blocklist && (
              <Tag className="ml-3" color="#85635B">
                Unidade Consumidora na blocklist
              </Tag>
            )}
          </p>
          {data?.data?.validaDadosAdicionaisExistente?.propostas &&
            data?.data?.validaDadosAdicionaisExistente.propostas?.length >
              0 && (
              <div className="tabelaHistorico">
                <Table
                  data={data?.data?.validaDadosAdicionaisExistente?.propostas}
                  columns={columnsUnidadeConsumidora}
                  pagination={false}
                  filter={false}
                />
              </div>
            )}
        </div>
      }
      overlayStyle={{ minWidth: '1100px' }}
    >
      <BulletInfo className="bullet-info-icon" />
    </Tooltip>
  );
};

export default TooltipUnidadeConsumidora;
