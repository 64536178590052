import api from './api';

export const calcularVencimentos = async (dados) => {
  const { data } = await api.post('/proposta/calculo-vencimento', dados);
  return data;
};

export const calcularLimitesParcelas = async (dados) => {
  const { data } = await api.post('/proposta/limite-parcela', dados);
  return data;
};

export const calcularPlanos = async (dados, tipoCalculo) => {
  const caminhoApi =
    tipoCalculo === 0 ? 'simulacao-prestacao' : 'simulacao-solicitado';
  const { data } = await api.post(`/proposta/${caminhoApi}`, dados);
  return data;
};
