import React, { useEffect, useState } from 'react';

import { Form, Select } from 'formik-antd';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconPlusBtn } from '../../../assets/images/icons/icon-plus.svg';
import Button from '../../../components/Button';
import { Radio } from 'antd';

function ProdutosForm({
  formik,
  isReadOnly,
  produtosRegiao,
  tiposModalidade,
  userId,
}) {
  const { values, setFieldValue, getFieldProps, touched, setTouched } = formik;
  const [prevProductIds, setPrevProductIds] = useState([]);

  useEffect(() => {
    if (
      values?.produtos?.produtos?.length &&
      !values?.produtos?.produtos?.find((item) => item.id == null) &&
      produtosRegiao.length
    ) {
      values.produtos.produtos = values?.produtos?.produtos?.filter((item) => {
        return produtosRegiao.find((el) => el.id === item.id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtosRegiao]);

  useEffect(() => {
    if (values.produtos == null) {
      values.produtos = [];
    } else {
      formik.validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addProduto() {
    const newProduct = {
      modalidadeOpcoes: null,
      id: null,
      propriedade: {
        nome: '',
        opcoes: [],
      },
    };
    setFieldValue(
      'produtos.produtos',
      values?.produtos?.produtos
        ? [...values?.produtos?.produtos, newProduct]
        : [newProduct],
    );
  }

  function removeProduto(index) {
    const newList = getFieldProps('produtos.produtos').value;
    const newTouched = { ...touched };

    if (Array.isArray(newList)) {
      newList.splice(index, 1);
    }

    if (newTouched?.produtos?.produtos && Array.isArray(newTouched.produtos.produtos)) {
      newTouched.produtos.produtos.splice(index, 1);
    }

    setFieldValue('produtos.produtos', [...newList]);
    setTouched({ ...newTouched });
  }

  function propriedadeProdutoRegiaoSelecionado(idProduto) {
    let indexProdutoSelecionado = produtosRegiao
      ?.map((produto) => produto.id)
      ?.indexOf(idProduto);
    return Number.isInteger(indexProdutoSelecionado)
      ? produtosRegiao[indexProdutoSelecionado]?.propriedade
      : null;
  }

  //Chama a função para popular os selects de propriedade dos produtos
  useEffect(() => {
    optionSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //value: valor vindo do campo ao selecionar uma opção
  //ind: index do campo no formulário
  //name: nome do campo
  function optionSelected(value = '', ind, name = '') {
    if (
      typeof value === 'string' ||
      (typeof value === 'object' && value.length > 0)
    ) {
      // eslint-disable-next-line
      if (value == '' && userId) {
        //Para popular os selects com os dados vindos da api
        let index = [];
        let hasProperty = values?.produtos?.produtos?.filter((item, indice) => {
          // eslint-disable-next-line
          if (item.propriedade && item.propriedade.nome != '') {
            index.push(indice);
            return Object.keys(item?.propriedade).length;
          } else {
            return false;
          }
        });
        hasProperty.forEach((element) => {
          element.propriedade = { ...element.propriedade };
        });
        let options = [];
        for (let i = 0; i < hasProperty.length; i++) {
          options.push({ idCampo: hasProperty[i].id, opcoes: [] });
          if (hasProperty[i]?.propriedade?.opcoes)
            options[i]?.opcoes.push(...hasProperty[i].propriedade?.opcoes);
        }
        index.forEach((i, aux) => {
          setFieldValue(
            `produtos.produtos[${i}].propriedade.opcoes`,
            options[aux]?.opcoes,
          );
        });
      } else {
        //Para popular os selects com os dados inseridos em tela

        if (!userId) {
          //Popular selects no cadastro
          setFieldValue(`produtos.produtos[${ind}].propriedade`, {
            nome: name,
            opcoes: value,
          });
        } else {
          //Popular selects na edição

          // eslint-disable-next-line
          if (value == '') {
            //Dados vindos da api
            setFieldValue(`produtos.produtos[${ind}].propriedade[${0}]`, value);
          } else {
            //Dados inseridos em tela
            setFieldValue(`produtos.produtos[${ind}].propriedade`, {
              nome: name,
              opcoes: value,
            });
          }
        }
      }
    }
  }

  function returnProducts(values, i) {
    if (
      !values?.produtos?.tipoModalidade ||
      values?.produtos?.tipoModalidade === 0
    ) {
      return produtosRegiao?.map((produto, index) => {
        if (
          values?.produtos?.produtos
            ?.map((selecteds) => selecteds.id)
            ?.indexOf(produto.id) < 0 ||
          produto.id === values?.produtos?.produtos[i]?.id
        ) {
          return (
            <option key={index} value={produto.id}>
              {produto.nome}
            </option>
          );
        } else {
          return null;
        }
      });
    } else {
      return produtosRegiao?.map((produto, index) => {
        if (
          (values?.produtos?.produtos
            ?.map((selecteds) => selecteds.id)
            ?.indexOf(produto.id) < 0 ||
            produto.id === values?.produtos?.produtos[i]?.id) &&
          (produto.modalidadeOpcoes === values.produtos.tipoModalidade ||
            produto.modalidadeOpcoes === 0)
        ) {
          return (
            <option key={index} value={produto.id}>
              {produto.nome}
            </option>
          );
        } else {
          return null;
        }
      });
    }
  }


  useEffect(() => {
    // ao entrar no useEffect é atualizado os IDs dos produtos previamente renderizados
    const currentProductIds = values?.produtos?.produtos.map(product => product.id) || [];
    setPrevProductIds(currentProductIds);
  }, [values?.produtos?.produtos]);

  useEffect(() => {
    formik.validateField("produtos.tipoModalidade");


    values?.produtos?.produtos?.forEach((produto, i) => {
      const prevProductId = prevProductIds[i];
      const currentProductId = produto.id;

      // verifica se o ID do produto mudou
      if (prevProductId !== undefined && prevProductId !== currentProductId) {

        // se houve mudanças no produto, limpe as opções e a mensagem de erro
        formik.setFieldValue(`produtos.produtos[${i}].propriedade.opcoes`, undefined);
        formik.setFieldError(`produtos.produtos[${i}].propriedade.opcoes`, undefined);
      }
    });
  }, [values?.produtos?.produtos, prevProductIds]);

  return (
    <>
      <Form layout="vertical">
        <div className="row">
          <div className="col-lg-12 col-tab-produtos">
            <div className="form-row">
              <div className="col-lg-12 item-dados col-modalidade">
                <Form.Item
                  name="produtos.tipoModalidade"
                  className='form-item-modalidade-produtos-form'
                >
                  <span className="label-modalidade">Modalidade:</span>
                  <Radio.Group
                    name="produtos.tipoModalidade"
                    className='radio-group-modalidade-produtos-form'
                    onChange={(e) =>
                      setFieldValue('produtos.tipoModalidade', e.target.value)
                    }
                    value={values?.produtos?.tipoModalidade}
                    disabled={isReadOnly}
                  >
                    {tiposModalidade?.map((tipo, index) => {
                      return (
                        <Radio key={index} value={tipo.id}>
                          {tipo.nome}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
              </div>
              {produtosRegiao?.length > 0 && (
                <>
                  {values?.produtos?.produtos &&
                    values?.produtos?.produtos?.map((item, i) => (
                      <>
                        <div className="col-lg-12 col-new-info">
                          <div className="col-lg-4 item-dados">
                            <Form.Item
                              label="Produto"
                              name={`produtos.produtos[${i}].id`}
                            >
                              <Select
                                name={`produtos.produtos[${i}].id`}
                                placeholder="Selecione"
                                suffixIcon={<IconArrowSelect />}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    `produtos.produtos[${i}].propriedade.opcoes`,
                                    null,
                                  );
                                  setFieldValue(
                                    `produtos.produtos[${i}].id`,
                                    value,
                                  );

                                }}
                                allowClear
                                disabled={isReadOnly}
                                className="produtoSelector"
                              >
                                {returnProducts(values, i)}
                              </Select>
                            </Form.Item>
                          </div>
                          {values?.produtos?.produtos[i]?.id &&
                            produtosRegiao?.filter(
                              (produto) =>
                                produto.id ===
                                values?.produtos?.produtos[i]?.id,
                            )[0]?.propriedade != null &&
                            propriedadeProdutoRegiaoSelecionado(
                              values?.produtos?.produtos[i]?.id,
                            )?.nome && propriedadeProdutoRegiaoSelecionado(
                              values?.produtos?.produtos[i]?.id,
                            )?.visualizaPropriedade && (
                              <div
                                className={`col-new-info col-lg-${values?.produtos?.produtos.length > 1
                                  ? '7'
                                  : '8'
                                  }`}
                              >
                                <div className="item-dados col-lg-12 produtosSelect">
                                  <Form.Item
                                    label={
                                      propriedadeProdutoRegiaoSelecionado(
                                        values?.produtos?.produtos[i]?.id,
                                      )?.nome
                                    }
                                    name={`produtos.produtos[${i}].propriedade.opcoes`}
                                    validate={(value) => {
                                      let msgError = '';
                                      const selectedProductId = values?.produtos?.produtos[i]?.id;
                                      const selectedProduct = produtosRegiao.find(produto => produto.id === selectedProductId);

                                      if (selectedProduct && (selectedProduct.nome === "Energia" || selectedProduct.nome === "Financiamento Crefaz" || selectedProduct.nome === "CDC")) {
                                        if (!value || value === null || value.length === 0) {
                                          msgError = 'Campo obrigatório';
                                        }
                                      }
                                      return msgError;
                                    }}
                                  >
                                    <Select
                                      name={`produtos.produtos[${i}].propriedade.opcoes`}
                                      placeholder="Selecione"
                                      suffixIcon={<IconArrowSelect />}
                                      allowClear
                                      getPopupContainer={(trigger) =>
                                        trigger.parentNode
                                      }
                                      onChange={(value) =>
                                        optionSelected(
                                          value,
                                          i,
                                          propriedadeProdutoRegiaoSelecionado(
                                            values?.produtos?.produtos[i]?.id,
                                          )?.nome,
                                        )
                                      }
                                      mode="multiple"
                                      disabled={isReadOnly}
                                    >
                                      {propriedadeProdutoRegiaoSelecionado(
                                        values?.produtos?.produtos[i]?.id,
                                      )?.opcoes?.map((opcao, index) => (
                                        <option key={index} value={opcao.id}>
                                          {opcao.nome}
                                        </option>
                                      ))}
                                    </Select>
                                    {propriedadeProdutoRegiaoSelecionado(
                                      values?.produtos?.produtos[i]?.id,
                                    )?.mensagemDetalhe && (
                                        <span className="message-detalhe-produto">
                                          {
                                            "*" + propriedadeProdutoRegiaoSelecionado(
                                              values?.produtos?.produtos[i]?.id,
                                            )?.mensagemDetalhe
                                          }
                                        </span>
                                      )}
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                          {values?.produtos?.produtos?.length > 1 &&
                            !isReadOnly ? (
                            <div className=" col-lg-1 col-button-remove">
                              <Button
                                className="btn-trash"
                                onClick={() => removeProduto(i)}
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </>
                    ))}
                  {values?.produtos && !isReadOnly && (
                    <div className="col-lg-12 col-button">
                      <Button
                        type="button"
                        className="btn-produtos"
                        variant="blue"
                        async={true}
                        onClick={addProduto}
                      >
                        <IconPlusBtn />
                        Produtos
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ProdutosForm;
