import moment from 'moment';

export const columnsBureaux = [
  {
    Header: 'Data consulta',
    accessor: 'dataConsulta',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Nome do bureau',
    accessor: 'nomeBureau',
  },
  {
    Header: 'Resultado da consulta',
    accessor: 'resultadoConsulta',
  },
  {
    Header: 'Score do cliente',
    accessor: 'scoreCliente',
  },
];
