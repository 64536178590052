import React, { useContext } from 'react';
import swal from 'sweetalert';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/CustomTable';

import JurosPercentuaisForm from './form';
import JurosPercentuaisFormEdit from './formEdit';

import { ReactComponent as IconTrash } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/edit-pen.svg';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

import { percentualFormat } from '../../../utils/numberFormatter';

function TabelaJurosPercentuais() {
  const {
    tabelaJurosValores,
    isEditJuros,
    setTabelaJurosValores,
    setTableFormData,
    setIsEditJuros,
  } = useContext(TabelaJurosContext);

  const removeItem = (pItem) => {
    swal({
      title: '',
      text: 'Tem certeza que deseja excluir o item?',
      icon: 'warning',
      buttons: ['Cancelar', 'Excluir'],
    }).then((willDelete) => {
      if (willDelete) {
        setTabelaJurosValores(
          tabelaJurosValores.filter((item) => item !== pItem),
        );
        swal('Item excluído com sucesso', { icon: 'success' });
      }
    });
  };

  const columns = [
    {
      Header: 'Plano',
      accessor: 'plano',
      disableSortBy: true,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
    },
    {
      Header: 'Taxa',
      accessor: 'juros',
      disableSortBy: true,
      style: {
        minWidth: 100,
        textAlign: 'center',
      },
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>{percentualFormat(value, 4)}</div>
      ),
    },
    {
      Header: '',
      id: 'actions',
      disableSortBy: true,
      style: {
        minWidth: 60,
        width: 60,
        textAlign: 'center',
      },
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                removeItem(row.original);
              }}
            >
              <IconTrash width="15" />
            </a>

            <span className="m-1"></span>

            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setTableFormData({
                  ...row?.original,
                  oldPlano: row?.original?.plano,
                  jurosFloat: row?.original?.juros,
                  edit: true,
                });
                setIsEditJuros(true);
              }}
            >
              <IconEdit width="15" />
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <Card title={<Title label="Tabela de Juros Percentuais" />} className="flex">
      <div>
        {isEditJuros ? <JurosPercentuaisFormEdit /> : <JurosPercentuaisForm />}
      </div>

      <Table columns={columns} data={tabelaJurosValores} pagination />
    </Card>
  );
}

export default TabelaJurosPercentuais;
