import React, { useContext, useEffect, useCallback } from 'react';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/BasicTable';
import { columns } from './columns';
import { AnalisePropostaContext } from '../../../contexts/AnalisePropostaContext';

function AnaliseList() {
  const {
    propostas,
    total,
    loading,
    pageCount,
    currentPage,
    isOnline,
    fetchPropostas,
    propostasAnalise,
    setPropostasAnalise,
    pageIndex,
    setPageIndex,
  } = useContext(AnalisePropostaContext);

  const abrirPropostaAutomaticamente = useCallback(() => {
    let isOpen = JSON.parse(localStorage.getItem('paginaAberta'));
    if (
      propostas?.length > 0 &&
      currentPage?.current === 1 &&
      isOnline &&
      !isOpen
    ) {
      setTimeout(() => {
        if (!propostasAnalise?.find((p) => p === propostas[0]?.id)) {
          localStorage.setItem('telaAnterior', '/analise-proposta');
          window.open(
            `/analise-mesa-credito/${propostas[0]?.id}?origem=172`,
            '_blank',
          );
          setPropostasAnalise((p) => [...p, propostas[0]?.id]);
        }
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, propostas]);

  useEffect(() => {
    abrirPropostaAutomaticamente();
  }, [abrirPropostaAutomaticamente]);
  
  return (
    <div className="Container">
      <Card title={<Title label="Listagem de propostas" />}>
        <div className="row text-center">
          <div className="col">
            <div className="tabelaAnaliseList">
              <Table
                data={propostas}
                onLoadData={fetchPropostas}
                columns={columns}
                total={total}
                pageCount={pageCount}
                loading={loading}
                currentPage={currentPage.current}
                className="table table-striped"
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AnaliseList;
