import React, { useState, useEffect, useContext } from 'react';
import { Form, Field, Input } from 'formik-antd';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import InputMask from 'react-text-mask';
import { cepMaskAlt } from '../../../utils/masks';
import Option from '../../../components/Option';
import SimpleInput from '../../../components/SimpleInput';

import {
  buscarEndereco,
} from '../../../services/enderecoService';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import SelectSearch from '../../../components/SearchSelect';

function EnderecoForm({ formik, isReadOnly }) {
  const { 
    validationField,
    cidades,
    estados, 
    fetchCidades 
  } = useContext(CredenciadoContext);

  const { setFieldValue, values, getFieldMeta, getFieldHelpers } = formik;
  const [statusErrorCEP, setStatusErrorCEP] = useState('');
  const [messageErrorCEP, setMessageErrorCEP] = useState('');

  const fetchEndereco = async (cep) => {
    const response = await buscarEndereco(cep);
    if (response?.success) {
      return response.data;
    }
  };

  const handleConsultarCEP = async (value) => {
    setMessageErrorCEP(null);
    setStatusErrorCEP(null);
    value = value.replace(/\D/g, '');
    if (value && value.length < 8) {
      setMessageErrorCEP('CEP inválido');
      setStatusErrorCEP('warning');
    } else if (value && value.length === 8) {
      const data = await fetchEndereco(value);
      if (data?.status) {
        setMessageErrorCEP(null);
        setStatusErrorCEP(null);
        await fetchCidades(data.ufId);
        setFieldValue('endereco.ufId', data.ufId);
        setFieldValue('endereco.cidadeId', data.cidadeId);
        setFieldValue('endereco.logradouro', data.logradouro);
        setFieldValue('endereco.bairro', data.bairro);
        setFieldValue('endereco.cep', data.cep);
      } else {
        setStatusErrorCEP('error');
        setMessageErrorCEP('CEP não encontrado');
      }
    }
  };

  useEffect(() => {
    if (values?.endereco?.ufId) {
      fetchCidades(values?.endereco?.ufId);
    }
    if (values?.endereco?.cep === '') {
      genericTouched('endereco.ufId');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [values?.endereco?.ufId]);

  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);
    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }

  return (
    <>
      <div className="col-tab-endereco">
        <div className="form-row">
          <div className="col-lg-3 item-dados">
            <Form.Item
              label="CEP"
              name="endereco.cep"
              help={
                messageErrorCEP
                  ? messageErrorCEP
                  : genericValidate('endereco.cep', 'message')
              }
              validateStatus={
                statusErrorCEP
                  ? statusErrorCEP
                  : genericValidate('endereco.cep', 'status')
              }
            >
              <InputMask
                disabled={isReadOnly}
                mask={cepMaskAlt}
                className="ant-input"
                placeholder="00.000-000"
                value={values.endereco.cep}
                onChange={(e) => {
                  handleConsultarCEP(e.target.value);
                  setFieldValue('endereco.cep', e.target.value);
                  setFieldValue('endereco.ufId', undefined);
                  setFieldValue('endereco.cidadeId', undefined);
                }}
                onBlur={(e) => {
                  genericTouched('endereco.cep');
                }}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6 item-dados">
            <Form.Item
              label="Logradouro"
              name="endereco.logradouro"
              validate={(value) =>
                validationField(value, 'endereco.logradouro', 'Logradouro')
              }
            >
              <Field name="endereco.logradouro">
                {({ field }) => (
                  <SimpleInput
                    {...field}
                    type="text"
                    name="endereco.logradouro"
                    placeholder="Rua, Avenida..."
                    disabled={isReadOnly}
                    value={values?.endereco?.logradouro}
                    onBlur={(e) =>
                      setFieldValue('endereco.logradouro', e.target.value)
                    }
                  />
                )}
              </Field>
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item label="Número" name="endereco.numero">
              <Input
                type="text"
                name="endereco.numero"
                placeholder="Digite aqui"
                value={values?.endereco?.numero}
                onChange={(e) =>
                  setFieldValue('endereco.numero', e.target.value)
                }
                disabled={isReadOnly}
              />
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item label="UF" name="endereco.ufId">
              <SelectSearch
                name="endereco.ufId"
                placeholder="Selecione"
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                suffixIcon={<IconArrowSelect />}
                allowClear
                onChange={(value) => {
                  setFieldValue('endereco.ufId', value);
                  setFieldValue('endereco.cidadeId', null);
                }}
                disabled={values?.endereco?.cep || isReadOnly}
                value={values?.endereco?.ufId}
              >
                {estados &&
                  estados.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.uf}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item label="Cidade" name="endereco.cidadeId">
              <Field name="endereco.cidadeId">
                {({ field }) => (
                  <SelectSearch
                    {...field}
                    name="endereco.cidadeId"
                    placeholder="Selecione"
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    suffixIcon={<IconArrowSelect />}
                    allowClear
                    onChange={(value) =>
                      setFieldValue('endereco.cidadeId', value)
                    }
                    value={values?.endereco?.cidadeId || 'Selecione'}
                    disabled={
                      !values?.endereco?.ufId ||
                      values?.endereco?.cep ||
                      isReadOnly
                    }
                  >
                    {cidades.length > 0 &&
                      cidades.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                  </SelectSearch>
                )}
              </Field>
            </Form.Item>
          </div>
          <div className="col-lg-6 item-dados">
            <Form.Item label="Bairro" name="endereco.bairro">
              <Field name="endereco.bairro">
                {({ field }) => (
                  <SimpleInput
                    {...field}
                    type="text"
                    name="endereco.bairro"
                    placeholder="Digite aqui"
                    disabled={isReadOnly}
                    value={values?.endereco?.bairro}
                    onBlur={(e) =>
                      setFieldValue('endereco.bairro', e.target.value)
                    }
                  />
                )}
              </Field>
            </Form.Item>
          </div>
          <div className="col-lg-12 item-dados">
            <Form.Item label="Complemento" name="endereco.complemento">
              <Field name="endereco.complemento">
                {({ field }) => (
                  <SimpleInput
                    {...field}
                    type="text"
                    name="endereco.complemento"
                    placeholder="Casa, Ap, Bloco"
                    value={values?.endereco?.complemento}
                    onBlur={(e) =>
                      setFieldValue('endereco.complemento', e.target.value)
                    }
                    disabled={isReadOnly}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnderecoForm;
