import React, { useContext, Fragment, useState, useEffect } from 'react';

import { Form, Input, Select, Field } from 'formik-antd';
import InputPhone from '../../../../components/InputPhone';
import SelectSearch from '../../../../components/SearchSelect';

import { Button } from 'antd';

import { ReactComponent as IconTrash } from '../../../../assets/images/icons/trash.svg';

import {
  valuesReferencia,
  CadastroPessoaContext,
} from '../../../../contexts/CadastroPessoaContext';
import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';

const { Option } = Select;

function ReferenciasPessoaisForm({ formik }) {
  const { values, setFieldValue } = formik;
  const { contexto, referencia, setReferencia } = useContext(
    CadastroPessoaContext,
  );
  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao(
        'aba',
        'aba.cadastroPessoa.cliente.profissionais',
        'Editar',
      ),
    );
  }, [checarPermissao]);

  const handleAddReferencia = () => {
    setReferencia((state) => [...state, { ...valuesReferencia }]);
  };

  const handleRemoveReferencia = (index) => {
    setReferencia((newArray) => newArray.filter((_, idx) => idx !== index));
    values.referencias.splice(index, 1);
  };

  return (
    <>
      <div className="form-row input-dinamicos">
        {referencia &&
          referencia.map((item, i) => (
            <Fragment key={i}>
              <div className="col-lg-9">
                <div className="form-row">
                  <div className="col-lg-5">
                    <Form.Item
                      name={`referencias.${i}.nome`}
                      label="Nome"
                      hasFeedback
                    >
                      <Input
                        name={`referencias.${i}.nome`}
                        data-cy={`referencias-${i}-nome`}
                        disabled={acesso}
                      />
                    </Form.Item>
                  </div>

                  <div className="col-lg-3">
                    <Form.Item
                      name={`referencias.${i}.telefone`}
                      label="Telefone"
                      hasFeedback
                    >
                      <Field name={`referencias.${i}.telefone`}>
                        {({ field }) => (
                          <InputPhone
                            {...field}
                            showMask={true}
                            data-cy={`referencias-${i}-telefone`}
                            disabled={acesso}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4">
                    <Form.Item
                      name={`referencias.${i}.grau`}
                      label="Tipo de Referência"
                      hasFeedback
                    >
                      <SelectSearch
                        name={`referencias.${i}.grau`}
                        data-cy={`referencias-${i}-grau`}
                        disabled={acesso}
                        onChange={(value) =>
                          setFieldValue(`referencias.${i}.grau`, value)
                        }
                        value={values?.referencias[i]?.grau}
                      >
                        {contexto?.tipoReferencia?.length > 0 &&
                          contexto?.tipoReferencia?.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.nome}
                            </Option>
                          ))}
                      </SelectSearch>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Button
                  onClick={() => handleRemoveReferencia(i)}
                  className="btnRemoveInputs"
                  disabled={referencia.length <= 2}
                >
                  <IconTrash />
                </Button>
              </div>
            </Fragment>
          ))}
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={handleAddReferencia}
            data-cy="adiciona-referencia"
            disabled={acesso}
          >
            <i className="fa fa-plus mr-2" /> Referência
          </Button>
        </div>
      </div>
    </>
  );
}

export default ReferenciasPessoaisForm;
