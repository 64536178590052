import swal from 'sweetalert';

import './styles.scss';

function ModalConfirmation(title, message, type, action = () => {}) {
  swal(title, message, type, {
    buttons: ['Cancelar', 'Confirmar'],
  }).then((cancelar) => {
    if (cancelar) {
      action();
    }
  });
}

export default ModalConfirmation;
