export const currencyFormat = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(value);
};

export const percentualFormat = (value, fractional = 2) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    maximumFractionDigits: fractional,
    minimumFractionDigits: fractional,
  }).format(value / 100);
};
