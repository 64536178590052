import React, { createContext, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { genericPhoneFormatter } from '../utils/textFormatter';

import {
  listarPessoas,
  getCadastroPessoaContexto,
  getCadastroPessoaInfo,
  getHistoricoAlteracao,
  editarPessoa,
  getHistoricoProposta,
  getHistoricoContrato,
  getHistoricoPrestacao,
  getBlocklistCadastroPessoa,
} from '../services/cadastroPessoaService';

import {
  listarCidades,
  listarUfs,
  buscarEndereco,
} from '../services/enderecoService';

import { getAnotacoes, postAnotacao } from '../services/anotacaoService';
import { useFormik } from 'formik';

export const valuesReferencia = {
  nome: '',
  telefone: '',
  grau: 0,
};

export const CadastroPessoaContext = createContext({
  paginacaoPessoas: {
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  },
  paginacaoHistoricoAlteracao: {
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  },
  paginacaoHistoricoPropostas: {
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  },
  paginacaoHistoricoContrato: {
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  },
  contexto: [],
  pessoa: {},
  historicosAlteracao: [],
  historicosProposta: [],
  historicosContrato: [],
  anotacoes: [],
  telefonesExtra: [],
  referencia: [],
  historicosPrestacao: [],
  loadingHistoricoPrestacao: false,
  pageIndex: null,
  fetchPessoas: async (f) => f,
  fetchPessoa: async (f) => f,
  editarPessoa: async (f) => f,
  fetchHistoricoAlteracao: async (f) => f,
  fetchHistoricoProposta: async (f) => f,
  fetchHistoricoContrato: async (f) => f,
  listarCidades: async (f) => f,
  listarUfs: async (f) => f,
  buscarEndereco: async (f) => f,
  setPaginacaoPessoas: (f) => f,
  setPaginacaoHistoricoAlteracao: (f) => f,
  setPaginacaoHistoricoPropostas: (f) => f,
  setPaginacaoHistoricoContrato: (f) => f,
  cadastrarAnotacao: (f) => f,
  setTelefonesExtra: (f) => f,
  setReferencia: (f) => f,
  setPayloadHistoricoPrestacao: (f) => f,
  setPageIndex: (f) => f,
});

const initialValuesFiltros = {
  filtroNome: '',
  filtroCPF: '',
  filtroTelefone: '',
  filtroUC: '',
};

function CadastroPessoaProvider({ children }) {
  const [data, setData] = useState([]);
  const [pessoa, setPessoa] = useState({});
  const [paginacaoPessoas, setPaginacaoPessoas] = useState({
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  });
  const [paginacaoHistoricoAlteracao, setPaginacaoHistoricoAlteracao] =
    useState({
      total: 0,
      pageCount: 0,
      filtroDinamico: '',
      currentPage: 0,
      loading: false,
    });
  const [paginacaoHistoricoPropostas, setPaginacaoHistoricoPropostas] =
    useState({
      total: 0,
      pageCount: 0,
      filtroDinamico: '',
      currentPage: 0,
      loading: false,
    });
  const [paginacaoHistoricoContrato, setPaginacaoHistoricoContrato] = useState({
    total: 0,
    pageCount: 0,
    filtroDinamico: '',
    currentPage: 0,
    loading: false,
  });

  const [payloadHistoricoPrestacao, setPayloadHistoricoPrestacao] =
    useState(null);

  const [contexto, setContexto] = useState([]);
  const [anotacoes, setAnotacoes] = useState([]);
  const [historicosAlteracao, setHistoricosAlteracao] = useState([]);
  const [historicosProposta, setHistoricosProposta] = useState([]);
  const [historicosContrato, setHistoricosContrato] = useState([]);
  const [historicosPrestacao, setHistoricosPrestacao] = useState([]);
  const [telefonesExtra, setTelefonesExtra] = useState([]);
  const [referencia, setReferencia] = useState([]);
  const [loadingHistoricoPrestacao, setLoadingHistoricoPrestacao] =
    useState(false);
  const [dadosModalBlocklist, setDadosModalBlocklist] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idPessoaModalBlocklist, setIdPessoaModalBlocklist] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const [filters, setFilters] = useState(initialValuesFiltros);

  const params = useParams();
  const { idPessoa } = params;

  const formik = useFormik({
    initialValues: initialValuesFiltros,
  });

  const fetchPessoas = useCallback(async (props) => {
    setPaginacaoPessoas((paginacaoPessoas) => ({
      ...paginacaoPessoas,
      loading: true,
      currentPage: props?.pageIndex,
    }));

    const page = props?.pageIndex + 1;
    if (JSON.stringify(initialValuesFiltros) !== JSON.stringify(props.filter)) {
      const response = await listarPessoas({
        pagina: page || 1,
        filtroNome: props?.filter?.filtroNome,
        filtroCPF: props?.filter?.filtroCPF,
        filtroTelefone: props?.filter?.filtroTelefone,
        filtroUC: props?.filter?.filtroUC,
        ordenacao: props?.sortBy?.[0],
      });
      if (response.data?.itens) {
        setData(
          response.data?.itens.filter((pessoa) => !pessoa.pessoaJuridica),
        );

        setPaginacaoPessoas((paginacaoPessoas) => ({
          ...paginacaoPessoas,
          pageCount: response.data?.totalPages,
          total: response.data?.total,
        }));
      }
    } else {
      setData([]);
    }
    setPaginacaoPessoas((paginacaoPessoas) => ({
      ...paginacaoPessoas,
      loading: false,
    }));
  }, []);

  const fetchAnotacoes = useCallback(async (cpf) => {
    const response = await getAnotacoes(cpf);

    if (response.data) {
      setAnotacoes(response.data);
    }
  }, []);

  const fetchContexto = useCallback(async () => {
    const response = await getCadastroPessoaContexto();

    if (response.data) {
      setContexto(response.data);
    }
  }, []);

  const fetchPessoa = useCallback(
    async (id, pessoaJuridica = false) => {
      const response = await getCadastroPessoaInfo({
        id,
        pessoaJuridica,
      });

      if (response?.data) {
        response.data.contatos.telefone = genericPhoneFormatter(
          response.data.contatos.telefone,
        );
        response.data.profissao.telefoneRH = genericPhoneFormatter(
          response.data.profissao.telefoneRH,
        );
        if (response?.data?.contatos?.contatosExtras) {
          let telefonesExtrasFormatados;
          telefonesExtrasFormatados =
            response?.data?.contatos?.contatosExtras.map((contatoExtra) => ({
              ...contatoExtra,
              telefone: genericPhoneFormatter(contatoExtra.telefone),
            }));

          response.data.contatos.contatosExtras = telefonesExtrasFormatados;
          setTelefonesExtra(telefonesExtrasFormatados);
        }

        if (response?.data?.referencias) {
          let referenciasFormatadas;
          referenciasFormatadas = response?.data?.referencias.map(
            (referencia) => ({
              ...referencia,
              telefone: genericPhoneFormatter(referencia.telefone),
            }),
          );

          response.data.referencias = referenciasFormatadas;
          setReferencia(referenciasFormatadas);
        }

        if (response?.data?.pessoa?.cpf) {
          fetchAnotacoes(response.data.pessoa.cpf);
        }

        const referencias = response?.data?.referencias
          ? response?.data?.referencias
          : [];
        for (let i = referencias.length; i < 2; i++) {
          referencias.push(valuesReferencia);
        }
        setReferencia(referencias);

        setPessoa(response?.data);
        return response?.data;
      }
    },
    [fetchAnotacoes],
  );

  const fetchHistoricoAlteracao = useCallback(
    async (props) => {
      setPaginacaoHistoricoAlteracao((paginacao) => ({
        ...paginacao,
        loading: true,
        currentPage: props?.pageIndex,
      }));

      const page = props?.pageIndex + 1;

      const response = await getHistoricoAlteracao({
        pagina: page || 1,
        quantidadePorPagina: 5,
        filtroDinamico: props?.filter,
        ordenacao: props?.sortBy?.[0],
        pessoaId: idPessoa,
      });

      if (response?.data?.itens) {
        setHistoricosAlteracao(response?.data?.itens);

        setPaginacaoHistoricoAlteracao((paginacao) => ({
          ...paginacao,
          pageCount: response?.data?.totalPages,
          total: response?.data?.total,
        }));
      } else {
        setHistoricosAlteracao([]);
      }
      setPaginacaoHistoricoAlteracao((paginacao) => ({
        ...paginacao,
        loading: false,
      }));
    },
    [idPessoa],
  );

  const fetchHistoricoProposta = useCallback(
    async (props) => {
      setPaginacaoHistoricoPropostas((paginacao) => ({
        ...paginacao,
        loading: true,
        currentPage: props?.pageIndex,
      }));

      const page = props?.pageIndex + 1;

      const response = await getHistoricoProposta({
        pagina: page || 1,
        quantidadePorPagina: 5,
        filtroDinamico: props?.filter,
        ordenacao: props?.sortBy?.[0],
        pessoaId: idPessoa,
      });

      if (response?.data?.itens) {
        setHistoricosProposta(response?.data?.itens);

        setPaginacaoHistoricoPropostas((paginacao) => ({
          ...paginacao,
          pageCount: response?.data?.totalPages,
          total: response?.data?.total,
        }));
      } else {
        setHistoricosProposta([]);
      }
      setPaginacaoHistoricoPropostas((paginacao) => ({
        ...paginacao,
        loading: false,
      }));
    },
    [idPessoa],
  );

  const fetchHistoricoContrato = useCallback(
    async (props) => {
      setPaginacaoHistoricoContrato((paginacao) => ({
        ...paginacao,
        loading: true,
        currentPage: props?.pageIndex,
      }));

      const page = props?.pageIndex + 1;

      const response = await getHistoricoContrato({
        pagina: page || 1,
        quantidadePorPagina: 5,
        filtroDinamico: props?.filter,
        ordenacao: props?.sortBy?.[0],
        pessoaId: idPessoa,
      });

      if (response?.data?.itens) {
        setHistoricosContrato(response?.data?.itens);

        setPaginacaoHistoricoContrato((paginacao) => ({
          ...paginacao,
          pageCount: response?.data?.totalPages,
          total: response?.data?.total,
        }));
      } else {
        setHistoricosContrato([]);
      }
      setPaginacaoHistoricoContrato((paginacao) => ({
        ...paginacao,
        loading: false,
      }));
    },
    [idPessoa],
  );

  const cadastrarAnotacao = useCallback(async (anotacao) => {
    const data = await postAnotacao(anotacao);

    if (data) {
      setAnotacoes((anotacoes) => [...anotacoes, data]);
    }
  }, []);

  const fetchHistoricoPrestacao = useCallback(async () => {
    setLoadingHistoricoPrestacao(true);
    const { parcela, valorTotal, valorPrestacao } = payloadHistoricoPrestacao;
    const { data } = await getHistoricoPrestacao({
      parcela,
      valorTotal,
      valorPrestacao,
    });

    if (data) {
      setHistoricosPrestacao(data);
    }
    setLoadingHistoricoPrestacao(false);
  }, [payloadHistoricoPrestacao]);

  const modalBlocklist = useCallback(async (idPessoaModalBlocklist) => {
    if (idPessoaModalBlocklist > 0) {
      const data = await getBlocklistCadastroPessoa(idPessoaModalBlocklist);

      if (data) {
        setDadosModalBlocklist(data?.data);
        setIsModalVisible(true);
      }
    }
  }, []);

  useEffect(() => {
    fetchContexto();
  }, [fetchContexto]);

  useEffect(() => {
    if (isModalVisible === true) {
      modalBlocklist(idPessoaModalBlocklist);
    }
  }, [isModalVisible, idPessoaModalBlocklist, modalBlocklist]);

  useEffect(() => {
    if (payloadHistoricoPrestacao) {
      fetchHistoricoPrestacao();
    }
  }, [payloadHistoricoPrestacao, fetchHistoricoPrestacao]);

  return (
    <CadastroPessoaContext.Provider
      value={{
        formik,
        data,
        paginacaoPessoas,
        paginacaoHistoricoAlteracao,
        paginacaoHistoricoPropostas,
        paginacaoHistoricoContrato,
        filters,
        setFilters,
        initialValuesFiltros,
        contexto,
        pessoa,
        historicosAlteracao,
        historicosProposta,
        historicosContrato,
        anotacoes,
        telefonesExtra,
        referencia,
        historicosPrestacao,
        loadingHistoricoPrestacao,
        dadosModalBlocklist,
        isModalVisible,
        setIsModalVisible,
        setIdPessoaModalBlocklist,
        fetchPessoas,
        fetchPessoa,
        editarPessoa,
        fetchHistoricoAlteracao,
        fetchHistoricoProposta,
        fetchHistoricoContrato,
        listarCidades,
        listarUfs,
        buscarEndereco,
        setPaginacaoPessoas,
        setPaginacaoHistoricoAlteracao,
        setPaginacaoHistoricoPropostas,
        setPaginacaoHistoricoContrato,
        cadastrarAnotacao,
        setTelefonesExtra,
        setReferencia,
        setPayloadHistoricoPrestacao,
        disabled,
        setDisabled,
      }}
    >
      {children}
    </CadastroPessoaContext.Provider>
  );
}

export default CadastroPessoaProvider;
