import React, { Fragment, useEffect, useMemo, useRef, forwardRef } from 'react';
import { useTable, useRowSelect } from 'react-table';

const IndeterminateRadioButton = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Fragment>
        <input type="radio" ref={resolvedRef} {...rest} />
      </Fragment>
    );
  },
);

function Table({ columns, data, setSelectedRow }) {
  const COLUMNS = useMemo(() => columns, [columns]);
  const DATA = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      columns: COLUMNS,
      data: DATA,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: '',
          Cell: ({ row }) => (
            <div>
              <IndeterminateRadioButton
                {...row.getToggleRowSelectedProps()}
                onClick={() => {
                  toggleAllRowsSelected(false);
                  toggleRowSelected(row.id, true);
                  setSelectedRow(row.original);
                }}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  return (
    <div className="table-responsive">
      <table className="table table-striped" {...getTableProps()}>
        <thead style={{ border: '1px solid transparent' }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} style={{ border: '1px solid #e7eaec' }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
