export class ControleRenda {
  static TODAS = 0;
  static APENAS_ESTIMADA = 1;
  static APENAS_MANUAL = 2;
}

export class TipoCalculoVencimento {
  static CARNE = 1;
  static ENERGIA = 2;
  static BOLETO = 3;
  static CONSIGNADO_PRIVADO = 4;
  static DEBITO_EM_CONTA = 5;
}

export class TipoCredenciado {
  static CORRESPONDENTE_ID = 0;
  static CONVENIO_ID = 1;
}

export class SubTipoConvenio {
  static CIA_ELETRICA_ID = 1;
  static CONSIGNADO_PRIVADO_ID = 3;
}
