import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import { signOut } from '../../services/authService';

export default function Logout() {
  const history = useHistory();
  const { setPermissoes } = useContext(ControleAcessoContext);
  const logout = () => {
    localStorage.removeItem('permissoes');
    setPermissoes(null);
    signOut();
    history.push('/login');
  };
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
