import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import CardIcon from '../../../components/CardIcon';
import Overlay from '../../../components/Overlay';
import { Upload, Input, Modal } from 'antd';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import { cpfFormatter } from '../../../utils/textFormatter';

import { ReactComponent as StepUserIcon } from '../../../assets/images/icons/step-user.svg';

import './styles.scss';

import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { getLinkImagem } from '../../../services/imagemService';

import Tooltip from '../../../components/tooltip';
import UploadItem from '../../../components/UploadItems';
import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';

const { Panel } = Collapse;

export default function InformacaoesCliente() {
  const { proposta, contexto, getHistoricoReanalise } =
    useContext(AnaliseMesaContext);
  const [historico, setHistorico] = useState([]);
  const [loading, setLoading] = useState(true);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loadingFile, setLoadingFile] = useState(false);

  const [blobFile, setBlobFile] = useState(null);

  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);

    if (file.link) {
      const anexo = await getLinkImagem(file.link);
      setLoadingFile(false);
      const { conteudo } = anexo;

      if (conteudo) {
        file.type = conteudo.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(conteudo);
        setPreviewVisible(true);
      }
    } else if (file.url) {
      setLoadingFile(false);
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(file.url);
      setPreviewVisible(true);
    }
  };

  const customUploadRender = (item, file) => {
    return (
      <>
        <div className="icone-arquivo">
          {UploadItem(file, handlePreview, null, handleDownload)}
        </div>
      </>
    );
  };

  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewImage(null);
    setPreviewTitle(null);
    setBlobFile(null);
  };

  const handleDownload = async (file) => {
    if (file.link) {
      const anexo = await getLinkImagem(file.link);
      const { conteudo } = anexo;
      const link = document.createElement('a');
      link.href = conteudo;
      link.setAttribute('download', anexo?.nome);
      document.body.appendChild(link);
      link.click();
    }
  };

  async function handleHistorico(id) {
    const data = await getHistoricoReanalise(id);
    if (data?.data) {
      setHistorico(data.data);
    }
  }

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000; // 1MB
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobFile(blobURL);
    } else {
      setBlobFile(null);
    }
  }, [previewImage, fileType]);

  return (
    <CardIcon
      title="Informações do Cliente"
      icon={<StepUserIcon className="mr-1" />}
      rightBar={
        proposta?.historicoReanalise && (
          <div>
            <Tooltip
              autoAdjustOverflow={false}
              placement="bottomRight"
              arrowPointAtCenter="true"
              color="#445566"
              overlayClassName="tooltip-historico-mesa-credito z-index-0"
              overlayStyle={{ minWidth: '520px' }}
              trigger="click"
              onClick={() => {
                handleHistorico(proposta.id);
                setLoading(false);
              }}
              title={
                <div className="outsideBorder tooltip-historico-reanalise">
                  <div className="tabelaHistorico">
                    <Overlay active={loading}>
                      <div className="tooltip-body">
                        <h1>Marcada como reanálise</h1>
                        <div>
                          <div className="nome-data">
                            {historico?.length > 0 &&
                              `${historico[0]?.login} - ${moment(
                                historico[0]?.dataHora,
                              ).format('DD/MM/YYYY HH:mm:ss')}`}
                          </div>
                          <label className="label-motivo-historico">
                            Motivo
                          </label>
                          <Input.TextArea
                            placeholder="Informe o motivo da solicitação de reanálise"
                            value={historico[0]?.motivo}
                            className="txta-motivo"
                            readOnly={true}
                          />
                          <label>Anexos</label>
                          <div>
                            <Upload
                              multiple={true}
                              listType="picture-card"
                              className={'proposta-credito-uploads'}
                              fileList={historico[0]?.anexos}
                              onPreview={handlePreview}
                              itemRender={customUploadRender}
                            ></Upload>
                          </div>
                        </div>
                        {historico?.length > 0 &&
                          historico?.map(
                            (item, index) =>
                              index !== 0 && (
                                <div>
                                  <label className="label-motivo-historico">
                                    Histórico de Reanálise
                                  </label>
                                  <div>
                                    <Collapse
                                      expandIconPosition="left"
                                      expandIcon={({ isActive }) => (
                                        <CaretRightOutlined
                                          rotate={isActive ? 90 : 0}
                                          style={{ color: '#ff7817' }}
                                        />
                                      )}
                                      className="mt-4"
                                    >
                                      <Panel
                                        header={`${item.login} - ${moment(
                                          item.dataHora,
                                        ).format('DD/MM/YYYY HH:mm:ss')}`}
                                      >
                                        <label>Motivo</label>
                                        <div className="tabelaListagem">
                                          <Input.TextArea
                                            placeholder="Informe o motivo da solicitação de reanálise"
                                            value={item.motivo}
                                            className="txta-motivo"
                                            readOnly={true}
                                          />
                                        </div>
                                        <label>Anexos</label>
                                        <div>
                                          <Upload
                                            multiple={true}
                                            listType="picture-card"
                                            className={
                                              'proposta-credito-uploads'
                                            }
                                            fileList={item.anexos}
                                            onPreview={handlePreview}
                                            itemRender={customUploadRender}
                                          ></Upload>
                                        </div>
                                      </Panel>
                                    </Collapse>
                                  </div>
                                </div>
                              ),
                          )}
                      </div>
                    </Overlay>
                  </div>
                </div>
              }
            >
              <span className="tag-reanalise">Reanálise</span>
            </Tooltip>
            <Modal
              visible={previewVisible}
              destroyOnClose={true}
              title={previewTitle}
              footer={null}
              className={'modal-preview-upload ' + fileType.replace('/', '')}
              onCancel={handleCancel}
            >
              <Overlay active={loadingFile}>
                {!loadingFile && (
                  <div>
                    {fileType === 'application/pdf' && (
                      <object
                        data={(blobFile ?? previewImage) + '#toolbar=0'}
                        type={fileType}
                        width="100%"
                        height="100%"
                        aria-label="Upload File"
                      ></object>
                    )}
                    {fileType !== 'application/pdf' && (
                      <img src={previewImage} alt="Documento"></img>
                    )}
                  </div>
                )}
              </Overlay>
            </Modal>
          </div>
        )
      }
    >
      <div className="row informacoes">
        <div className="col-6 ">
          <p className="nome-cliente">
            <b>Nome:</b> {proposta?.cliente.nome}
          </p>
        </div>
        <div className="col-6 ">
          <p>
            <b>Tipo renda:</b>{' '}
            {proposta?.profissional?.tipoRenda === 0 ? 'Estimada' : 'Manual'}
          </p>
        </div>
        <div className="col-6">
          <p>
            <b>CPF:</b> {cpfFormatter(proposta?.cliente.cpf)}
          </p>
        </div>
        <div className="col-6">
          <p style={proposta?.cliente?.corTipoCliente && { color: proposta?.cliente.corTipoCliente }}>
            <b>Tipo cliente:</b> {proposta?.cliente.tipoCliente}
          </p>
        </div>
        <div className="col-6">
          <p>
            <b>Produto:</b> {proposta?.operacao.produtoNome}
          </p>
        </div>
        <div className="col-6">
          <p>
            <b>Modalidade:</b>{' '}
            {
              contexto?.opcoesModalidade?.filter(
                (modalidade) =>
                  modalidade.id === proposta?.operacao.tipoModalidade,
              )[0]?.nome
            }
          </p>
        </div>
        <div className="col-6">
          <p>
            <b>Canal:</b> {proposta?.cliente?.canal}
          </p>
        </div>
      </div>
    </CardIcon>
  );
}
