import React, { useContext, useEffect, useState } from 'react';
import { AnaliseMesaContext } from '../../contexts/AnaliseMesaContext';
import { ReactComponent as IconAtendeu } from '../../assets/images/icons/icon-discador-atendeu.svg';
import { ReactComponent as IconCancelarChamada } from '../../assets/images/icons/icon-discador-cancelar-chamada.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/icon-discador-check.svg';
import { ReactComponent as IconError } from '../../assets/images/icons/cancel.svg';
import { genericPhoneFormatter } from '../../utils/textFormatter';
import Spinner from '../Spinner';
import Modal from "../Modal"

import './styles.scss';

import {
  atualizaChamada,
} from '../../services/analiseMesaService';
import { notification } from 'antd';
import webSocketApi from '../../services/webSocketApi';

function ModalDiscadorDigitro() {
  const {
    setChamada,
    setShowModalDigitro,
    showModalDigitro,
    telefone,
    tipoTelefone,
    chamadaId,
    propostaId,
    setIsActive,
    setChamadaId,
    setIsPaused,
    setTimer,
    increment,
    status,
    setStatus,
    timer,
  } = useContext(AnaliseMesaContext);

  const [mensagem, setMensagem] = useState();
  const [socket, setSocket] = useState();
  const [error, setError] = useState(false);

  const notificacaoAtendidaFechada = (type) => {
    notification[type]({
      message: 'Chamada Fechada',
      description: 'A chamada foi atendida porém fechada',
    });
  };

  const notificacaoConfirmada = (type) => {
    notification[type]({
      message: 'Chamada Confirmada',
      description: 'A chamada foi confirmada',
    });
  };

  const notificacaoCancelada = (type) => {
    notification[type]({
      message: 'Chamada Cancelada',
      description: 'A chamada foi cancelada',
    });
  };

  function atendeuChamada() {
    setStatus('atendeu');
    if (socket) {
      socket.send(JSON.stringify({
        cti: "DESCONECTA_CHAMADA",
        situacao: "Atendida",
      }))
    }
  }

  async function chamadaConfirmada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 0,
      confirmacao: true,
    };
    setShowModalDigitro(false);
    await atualizaChamada(chamada, chamadaId).then((response) => {
      if (response.status === 400) {
        setChamada({
          historicoChamadaId: chamadaId,
          status: 1,
          confirmacao: false,
        });
      } else {
        notificacaoConfirmada('success');
        setChamada({
          historicoChamadaId: chamadaId,
          status: 0,
          confirmacao: true,
        });
      }
    });
    handleReset();
    setStatus('conexao');
  }

  async function chamadaCancelada(chamadaId) {
    if (socket) {
      socket.send(JSON.stringify({
        cti: "DESCONECTA_CHAMADA",
        situacao: "Cancelada",
      }))
    }
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 1,
      confirmacao: false,
    };
    setShowModalDigitro(false);
    await atualizaChamada(chamada, chamadaId).then(() => {
      notificacaoCancelada('error');
      setChamada({
        historicoChamadaId: chamadaId,
        status: 1,
        confirmacao: false,
      });
    });

    handleReset();
  }

  async function chamadaAtendidaFechada(chamadaId) {
    setStatus('em-chamada');

    const chamada = {
      id: chamadaId,
      status: 0,
      confirmacao: false,
    };
    setShowModalDigitro(false);
    await atualizaChamada(chamada, chamadaId).then(() => {
      notificacaoAtendidaFechada('warning');
      setChamada({
        historicoChamadaId: chamadaId,
        status: 0,
        confirmacao: false,
      });
    });
    setStatus('conexao');
    setMensagem();
    handleReset();
  }

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(true);
    setTimer(0);
    setMensagem()
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  useEffect(() => {
    if (showModalDigitro) {
      const webSocket = webSocketApi({ propostaId, telefone });
      webSocket.onopen = () => {
        console.log("WebSocket connected");
      };

      webSocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        setMensagem(response.modalDiscador);
        if (response.modalAlerta && response.modalDiscador) {
          Modal('', response.modalAlerta, 'warning', () => atendeuChamada())
        } else if (response.modalAlerta) {
          Modal('', response.modalAlerta, 'warning', () => {
            setChamada({
              historicoChamadaId: chamadaId,
              status: 1,
              confirmacao: false,
            });
          })
          setShowModalDigitro(false);
          handleReset()
          setMensagem();
        }

        if (response.chamadaId) {
          setChamadaId(response.chamadaId)
        }

        setError(response.error)
      };

      webSocket.onclose = () => {
      };

      setSocket(webSocket)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalDigitro])

  return (
    <>
      {showModalDigitro && (
        <>
          {status !== '' && (
            <div
              className={
                'modal-discador ' + (error && 'bg-erro')
              }
            >
              {(!mensagem && status !== "atendeu") && (
                <div className="conexao">
                  <Spinner />
                </div>
              )}
              {(!error &&
                (
                  (mensagem && status === 'em-chamada') ||
                  status === 'atendeu'
                )
              ) && (
                  <div className="em-chamada">
                    <h4 className="tipo-telefone">{tipoTelefone}</h4>
                    <h5 className="numero-telefone">
                      {telefone && genericPhoneFormatter(telefone)}
                    </h5>
                    <p className="tempo-chamada">{formatTime()}</p>

                    {(mensagem && status === 'em-chamada') && (
                      <>
                        <h5 className="mensagem">
                          {mensagem && mensagem}
                        </h5>
                        <div className="btns-acoes">
                          <button
                            className="btn-atendeu"
                            onClick={() => atendeuChamada()}
                          >
                            <IconAtendeu />
                            <span>Atendeu</span>
                          </button>
                          <button
                            className="btn-cancelar"
                            onClick={() => chamadaCancelada(chamadaId)}
                          >
                            <IconCancelarChamada />
                            <span>Cancelar</span>
                          </button>
                        </div>
                      </>
                    )}
                    {status === 'atendeu' && (
                      <div className="atendeu">
                        <h4 className="titulo">
                          <IconAtendeu />
                          Atendeu
                        </h4>
                        <button
                          className="btn-confirmacao"
                          onClick={() => chamadaConfirmada(chamadaId)}
                        >
                          <IconCheck /> Confirmação
                        </button>
                        <button
                          className="btn-fechar"
                          onClick={() => chamadaAtendidaFechada(chamadaId)}
                        >
                          Fechar
                        </button>
                      </div>
                    )}
                  </div>
                )}
              {error && (
                <div className="erro-comunicacao">
                  <IconError />
                  <h4>{mensagem}</h4>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ModalDiscadorDigitro;
