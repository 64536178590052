import api from './api';

export const getContextoControleProposta = async () => {
  const { data } = await api.get('/proposta/controle-proposta-contexto');
  return data;
};

export const enviarControlePropostasCSV = async ({
  tipoData,
  dataFinal,
  dataInicial,
  propostaId,
  cpf,
  situacaoId,
  agente,
  matrizId,
  tipoErro,
  produtoId,
  tipoCliente,
  emails,
}) => {
  const { data } = await api.post('/Proposta/controle-proposta', {
    tipoData,
    dataInicial,
    dataFinal,
    propostaId,
    cpf,
    situacaoId,
    agente,
    matrizId,
    tipoErro,
    produtoId,
    tipoCliente,
    emails,
  });
  return data;
};
