import React, { useContext } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import PreAnaliseForm from './PreAnaliseForm';
import PreAnaliseProvider from '../../contexts/PreAnaliseContext';

import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function PreAnalise() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Nova Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Pré-Analise</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <div className="row">
          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 pre-analise-form">
            <PreAnaliseProvider>
              {checarPermissao('tela','tela.credito.proposta.novaProposta','Visualizar') && <> 
                <PreAnaliseForm />
              </>}
            </PreAnaliseProvider>
          </div>
        </div>
      </Content>
    </Main>
  );
}

export default PreAnalise;
