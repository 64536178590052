import React, { useState, useEffect, useContext } from 'react';

import { Table } from 'antd';

import { ReactComponent as IconExpandedTable } from '../../../assets/images/icons/icon-expanded-table.svg';

import { columnsHistorico } from './ColumnsTables/columnsHistorico';

import HistoricoFormDetails from './HistoricoFormDetails';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import Button from '../../../components/CustomButton';
import Modal from '../../../components/Modal';

import './styles.scss';

function HistoricoForm({
  values,
  contexto,
  listaAnalista,
  proposta,
  historicoChamada,
  setHistoricoChamada,
}) {
  const { editableField } = useContext(AnaliseMesaContext);

  const [analistas, setAnalistas] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleConfirmacao = async (record) => {
    const chamadas = [...historicoChamada];
    const indexAtual = chamadas.findIndex((el) => record.id === el.id);
    const chamadaAtual = chamadas[indexAtual];
    const indexConfirmada = chamadas.findIndex((el) => el.confirmacao);

    if (
      indexConfirmada >= 0 &&
      indexAtual !== indexConfirmada &&
      !chamadaAtual.confirmacao
    ) {
      Modal(
        '',
        'Apenas uma ligação da Proposta pode ser a Ligação de Confirmação.',
        'warning',
      );
    } else if (chamadaAtual.statusChamada !== 0 && !chamadaAtual.confirmacao) {
      Modal(
        '',
        'Apenas Ligações Atendidas podem ser marcadas como Ligação de Confirmação.',
        'warning',
      );
    } else {
      record.confirmacao = !record.confirmacao;
    }

    chamadas.splice(indexAtual, 1, { ...chamadaAtual, ...record });
    setHistoricoChamada(chamadas || []);
  };

  useEffect(() => {
    async function loadAnalistas() {
      const data = await listaAnalista({ pesquisaAnalista: '%' });

      setAnalistas(data?.listaAnalista || []);
    }

    loadAnalistas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row historico-form tabela-chamada">
      <div className="table-historico">
        <Table
          columns={columnsHistorico(
            contexto,
            handleConfirmacao,
            analistas,
            proposta,
            editableField,
            historicoChamada
          )}
          dataSource={historicoChamada}
          expandedRowKeys={expandedRowKeys}
          expandable={{
            expandedRowRender: (row) => (
              <HistoricoFormDetails
                row={row}
                contexto={contexto}
                listaAnalistas={analistas}
                editableField={editableField}
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <Button
                className={'btn-expanded-row ' + (expanded && 'expanded-open')}
              >
                <IconExpandedTable
                  onClick={() => {
                    let index = expandedRowKeys.findIndex(
                      (item) => item === record.id,
                    );
                    if (index >= 0) {
                      expandedRowKeys.splice(index, 1);
                    } else {
                      setExpandedRowKeys([...expandedRowKeys, record.id]);
                    }
                    onExpand(record, expanded);
                  }}
                />
              </Button>
            ),
          }}
          expandIconColumnIndex={8}
          rowKey={(record) => record.id}
          expandRowByClick={false}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default HistoricoForm;
