import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../layouts/Main';
import { Breadcrumb } from 'antd';
import PageHeading from '../../components/PageHeading';
import useInspinia from '../../hooks/useInspinia';
import Content from '../../components/Content';

import CadastroUsuariosPage from './CadastroUsuariosPage';
import CadastroUsuariosProvider from '../../contexts/CadastroUsuariosContext';

function CadastroUsuarios() {
  useInspinia();
  const params = useParams();
  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Usuários</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <CadastroUsuariosProvider>
          <CadastroUsuariosPage
            userId={params.userId ? Number.parseInt(params.userId) : null}
          />
        </CadastroUsuariosProvider>
      </Content>
    </Layout>
  );
}

export default CadastroUsuarios;
