import React, { useContext } from 'react';
import moment from 'moment';

import { Form, Field, Select, Input, Checkbox } from 'formik-antd';
import { Tooltip } from 'antd';
import InputMask from 'react-text-mask';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import TooltipAlterados from '../../../components/TooltipAlterados';
import DatePicker from '../../../components/DatePicker';
import SelectBancos from '../../../components/SelectBancos';
import InputMoney from '../../../components/InputMoney';
import Modal from '../../../components/Modal';
import SelectSearch from '../../../components/SearchSelect';
import SimpleInput from '../../../components/SimpleInput';
import TooltipUnidadeConsumidora from '../../../components/TooltipUnidadeConsumidora';

import { cpfMask } from '../../../utils/masks';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar.svg';

import './styles.scss';

const { Option } = Select;

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

function ResumoContato({ formik }) {
  const {
    editableField,
    validationField,
    estados,
    cidades,
    contexto,
    setEnderecoUfId,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
    calcularPrestacao,
    planos,
    highlightDadoAdicional,
  } = useContext(AnaliseMesaContext);

  const { setFieldTouched, setFieldValue, values } = formik;

  const validaDadosAdicionais = () => {
    if (values?.operacao?.dadosAdicionais) {
      values?.operacao?.dadosAdicionais?.forEach((dado, i) => {
        values.operacao.dadosAdicionais[i].tooltip =
          highlightDadoAdicional(dado);
      });
    }
  };

  const handleSelecionarEstado = (value) => {
    setEnderecoUfId(value);
    setFieldValue('resumo.endereco.ufId', value);
    setFieldValue('resumo.endereco.cidadeId', '');
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const handleSimularProposta = async (
    tabelaJurosId,
    tabelaJurosValoresId,
    valorContrato,
    vencimento,
  ) => {
    if (tabelaJurosId && tabelaJurosValoresId && valorContrato && vencimento) {
      const payload = {
        produtoId: values?.resumo.operacao.produtoId,
        convenioId: values?.resumo.operacao.convenioId,
        tabelaJurosId,
        valorContrato,
        vencimento,
        tabelaJurosValoresId,
      };

      const response = await calcularPrestacao(payload);

      if (response.data) {
        setFieldValue('resumo.operacao.operacao', response.data.valorOperacao);
        setFieldValue('resumo.operacao.prestacao', response.data.prestacao);
        setFieldValue('resumo.operacao.taxa', response.data.juros);
      }

      if (response.errors && response.errors.length > 0) {
        Modal(
          'Simulação de proposta',
          response.errors.join(' \n \n '),
          'error',
        );
      }
    }
  };

  const filteredTipos =
    contexto?.tipoConta &&
    contexto?.tipoConta?.filter((tipoConta) => {
      const bancoCodigo =
        values?.bancario && contexto?.bancos
          ? parseInt(values?.bancario?.bancoId)
          : -1;
      return (
        !tipoConta?.bancos ||
        bancoCodigo === null ||
        bancoCodigo < 0 ||
        tipoConta?.bancos.includes(bancoCodigo)
      );
    });

  const handleChangePlano = (prazo) => {
    setFieldValue('resumo.operacao.prazo', prazo);

    const planoSelecionado = planos.filter((plano) => plano.plano === prazo)[0]
      .id;

    setFieldValue('resumo.operacao.tabelaJurosValoresId', planoSelecionado);
    handleSimularProposta(
      values?.resumo.operacao.tabelaJurosId,
      planoSelecionado,
      values?.resumo.operacao.floatValorContratado,
      values?.resumo.operacao.vencimento,
    );
  };

  return (
    <>
      <div className="resumo-contato">
        <div className="form-row pb-3">
          <div className="col-lg-12 titleResumo">Dados Clientes</div>
        </div>

        <div className="form-row">
          <div className="col-lg-5">
            <Form.Item
              name="resumo.cliente.nome"
              validate={(value) =>
                validationField(value, 'resumo.cliente.nome', 'Nome', 'nome')
              }
              label={
                <>
                  <label>Nome do Cliente</label>
                  {campoChecar('resumo.cliente.nome') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.cliente.nome')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.cliente.nome')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.cliente.nome') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.cliente.nome') && `campo-alterado`
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.cliente.nome"
                disabled={!editableField('resumo.cliente.nome')}
                value={values?.resumo?.cliente?.nome}
                onBlur={(e) =>
                  setFieldValue(`resumo.cliente.nome`, e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-lg-3">
            <Form.Item
              name="resumo.cliente.cpf"
              label={
                <>
                  <label>CPF</label>
                  {campoChecar('resumo.cliente.cpf') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(campoChecar('resumo.cliente.cpf')?.campoId)
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.cliente.cpf')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.cliente.cpf') && <TooltipAlterados />}
                </>
              }
              validate={(value) =>
                validationField(value, 'resumo.cliente.cpf', 'CPF')
              }
              className={
                highlightField('resumo.cliente.cpf') && `campo-alterado`
              }
              hasFeedback
            >
              <Field name="resumo.cliente.cpf">
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask={cpfMask}
                    className="ant-input"
                    disabled={!editableField('resumo.cliente.cpf')}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              name="resumo.cliente.rg"
              label={
                <>
                  <label>Número do RG</label>
                  {campoChecar('resumo.cliente.rg') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(campoChecar('resumo.cliente.rg')?.campoId)
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.cliente.rg')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.cliente.rg') && <TooltipAlterados />}
                </>
              }
              validate={(value) =>
                validationField(value, 'resumo.cliente.rg', 'número do RG')
              }
              className={
                highlightField('resumo.cliente.rg') && `campo-alterado`
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.cliente.rg"
                disabled={!editableField('resumo.cliente.rg')}
                value={values?.resumo?.cliente?.rg}
                onBlur={(e) =>
                  setFieldValue(`resumo.cliente.rg`, e.target.value)
                }
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div className="col-lg-3">
            <Form.Item
              name="resumo.cliente.nascimento"
              label={
                <>
                  <label>Data de Nascimento</label>
                  {campoChecar('resumo.cliente.nascimento') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.cliente.nascimento')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.cliente.nascimento')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.cliente.nascimento') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(
                  value,
                  'resumo.cliente.nascimento',
                  'Data de Nascimento',
                )
              }
              className={
                highlightField('resumo.cliente.nascimento') && `campo-alterado`
              }
              hasFeedback
            >
              <DatePicker
                format="DD/MM/YYYY"
                name="resumo.cliente.nascimento"
                placeholder="00/00/0000"
                disabledDate={disabledDate}
                onBlur={() => setFieldTouched('resumo.cliente.nascimento')}
                disabled={!editableField('resumo.cliente.nascimento')}
              />
            </Form.Item>
          </div>
          <div className="col-lg-9">
            <Form.Item
              name="resumo.cliente.nomeMae"
              label={
                <>
                  <label>Nome da Mãe</label>
                  {campoChecar('resumo.cliente.nomeMae') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.cliente.nomeMae')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.cliente.nomeMae')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.cliente.nomeMae') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.cliente.nomeMae') && `campo-alterado`
              }
              validate={(value) =>
                validationField(value, 'resumo.cliente.nomeMae', 'Nome da Mãe')
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.cliente.nomeMae"
                disabled={!editableField('resumo.cliente.nomeMae')}
                value={values?.resumo?.cliente?.nomeMae}
                onBlur={(e) =>
                  setFieldValue(`resumo.cliente.nomeMae`, e.target.value)
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-5">
            <Form.Item
              name="resumo.contatos.contato.email"
              label={
                <>
                  <label>E-mail</label>
                  {campoChecar('resumo.contatos.contato.email') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.contatos.contato.email')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.contatos.contato.email')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.contatos.contato.email') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.contatos.contato.email') &&
                `campo-alterado`
              }
              // validateStatus={statusEmail}
              validate={(value) =>
                validationField(
                  value,
                  'resumo.contatos.contato.email',
                  'E-mail',
                  'email',
                )
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.contatos.contato.email"
                disabled={!editableField('resumo.contatos.contato.email')}
                placeholder="mail@example.com"
                value={values?.resumo?.contatos?.contato?.email}
                onBlur={(e) =>
                  setFieldValue(`resumo.contatos.contato.email`, e.target.value)
                }
              />
            </Form.Item>
          </div>
          {values?.operacao?.produtoNome !== 'CP Auto' ? (
            <div className="col-lg-7">
              <Form.Item
                name="resumo.profissional.ocupacaoId"
                label={
                  <>
                    <label>Classificação Profissional</label>
                    {campoChecar('resumo.profissional.ocupacaoId') && (
                      <Checkbox
                        className="check-campo"
                        checked={
                          !!getChecado(
                            campoChecar('resumo.profissional.ocupacaoId')
                              ?.campoId,
                          )
                        }
                        onChange={() =>
                          handleChangeChecados(
                            campoChecar('resumo.profissional.ocupacaoId')
                              ?.campoId,
                          )
                        }
                      />
                    )}
                    {highlightField('resumo.profissional.ocupacaoId') && (
                      <TooltipAlterados />
                    )}
                  </>
                }
                className={
                  highlightField('resumo.profissional.ocupacaoId') &&
                  `campo-alterado`
                }
                validate={(value) =>
                  validationField(
                    value,
                    'resumo.profissional.ocupacaoId',
                    'Classificação Profissional',
                  )
                }
                hasFeedback
              >
                <SelectSearch
                  name="resumo.profissional.ocupacaoId"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!editableField('resumo.profissional.ocupacaoId')}
                  suffixIcon={<IconArrowSelect />}
                  onChange={(value) =>
                    formik.setFieldValue(
                      `resumo.profissional.ocupacaoId`,
                      value,
                    )
                  }
                  value={values?.resumo?.profissional?.ocupacaoId}
                  fillDefault={editableField(`resumo.profissional.ocupacaoId`)}
                >
                  {contexto?.ocupacoes &&
                    contexto?.ocupacoes.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                </SelectSearch>
              </Form.Item>
            </div>
          ) : (
            <>
              <div className="col-lg-3">
                <Form.Item
                  name="resumo.profissional.ocupacaoId"
                  label={
                    <>
                      <label>Classificação Profissional</label>
                      {campoChecar('resumo.profissional.ocupacaoId') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.profissional.ocupacaoId')
                                ?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.profissional.ocupacaoId')
                                ?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.profissional.ocupacaoId') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.profissional.ocupacaoId') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.profissional.ocupacaoId',
                      'Classificação Profissional',
                    )
                  }
                  hasFeedback
                >
                  <SelectSearch
                    name="resumo.profissional.ocupacaoId"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    disabled={!editableField('resumo.profissional.ocupacaoId')}
                    suffixIcon={<IconArrowSelect />}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `resumo.profissional.ocupacaoId`,
                        value,
                      )
                    }
                    value={values?.resumo?.profissional?.ocupacaoId}
                    fillDefault={editableField(
                      `resumo.profissional.ocupacaoId`,
                    )}
                  >
                    {contexto?.ocupacoes &&
                      contexto?.ocupacoes.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                  </SelectSearch>
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item name="operacao.renda" label="Renda">
                  <Field name="operacao.renda">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        disabled={!editableField('resumo.operacao.operacao')}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
            </>
          )}
        </div>

        <div className="form-row pb-3">
          <div className="col-lg-12 titleResumo">Dados Bancários</div>
        </div>

        <div className="form-row">
          <div className="col-lg-8">
            <Form.Item
              name="resumo.bancario.bancoId"
              label={
                <>
                  <label>Banco</label>
                  {campoChecar('resumo.bancario.bancoId') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.bancario.bancoId')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.bancario.bancoId')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.bancario.bancoId') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(value, 'resumo.bancario.bancoId', 'Banco')
              }
              className={
                highlightField('resumo.bancario.bancoId') && `campo-alterado`
              }
              hasFeedback
            >
              <Field name="resumo.bancario.bancoId">
                {({ field }) => (
                  <SelectBancos
                    {...field}
                    optionsBancos={{
                      bancosPrincipais:
                        contexto?.bancos &&
                        contexto?.bancos?.listaBancosPrincipais,
                      bancos:
                        contexto?.bancos &&
                        contexto?.bancos?.listaBancosSecundarios,
                    }}
                    disabled={!editableField('resumo.bancario.bancoId')}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
              name="resumo.bancario.tipoConta"
              label={
                <>
                  <label>Tipo de Conta</label>
                  {campoChecar('resumo.bancario.tipoConta') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.bancario.tipoConta')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.bancario.tipoConta')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.bancario.tipoConta') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(
                  value,
                  'resumo.bancario.tipoConta',
                  'Tipo de Conta',
                )
              }
              className={
                highlightField('resumo.bancario.tipoConta') && `campo-alterado`
              }
              hasFeedback
            >
              <SelectSearch
                name="resumo.bancario.tipoConta"
                placeholder="Poupança"
                optionFilterProp="children"
                showSearch
                suffixIcon={<IconArrowSelect />}
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={!editableField('resumo.bancario.tipoConta')}
                onChange={(value) =>
                  formik.setFieldValue(`resumo.bancario.tipoConta`, value)
                }
                value={values?.resumo?.bancario?.tipoConta}
                fillDefault={editableField(`resumo.bancario.tipoConta`)}
              >
                {filteredTipos &&
                  filteredTipos.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
        </div>

        <div className="form-row pb-3">
          <div className="col-lg-12 titleResumo">Valor Operação</div>
        </div>

        <div className="form-row">
          <div className="col-lg-8">
            <div className="form-row" style={{ marginLeft: -15 }}>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.produtoNome"
                  label={
                    <>
                      <label>Produto</label>
                      {campoChecar('resumo.operacao.produtoNome') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.produtoNome')
                                ?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.produtoNome')
                                ?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.produtoNome') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.produtoNome') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.operacao.produtoNome',
                      'Nome do Produto',
                    )
                  }
                  hasFeedback
                >
                  <Field name="resumo.operacao.produtoNome">
                    {({ field }) => (
                      <SimpleInput
                        {...field}
                        disabled={!editableField('resumo.operacao.produtoNome')}
                        value={values?.resumo?.operacao?.produtoNome}
                        onBlur={(e) =>
                          setFieldValue(
                            `resumo.operacao.produtoNome`,
                            e.target.value,
                          )
                        }
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.operacao"
                  label={
                    <>
                      <label>Valor Operação</label>
                      {campoChecar('resumo.operacao.operacao') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.operacao')?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.operacao')?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.operacao') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.operacao') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.operacao.operacao',
                      'Valor da Operação',
                    )
                  }
                >
                  <Field name="resumo.operacao.operacao">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        disabled={!editableField('resumo.operacao.operacao')}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.valorContratado"
                  label={
                    <>
                      <label>Valor Liberado</label>
                      {campoChecar('resumo.operacao.valorContratado') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.valorContratado')
                                ?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.valorContratado')
                                ?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.valorContratado') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.valorContratado') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.operacao.valorContratado',
                      'Valor Contratado',
                    )
                  }
                >
                  <Field name="resumo.operacao.valorContratado">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        disabled={
                          !editableField('resumo.operacao.valorContratado')
                        }
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Form.Item
              name="resumo.operacao.prazo"
              label={
                <>
                  <label>Prazo</label>
                  {campoChecar('resumo.operacao.prazo') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.operacao.prazo')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.operacao.prazo')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.operacao.prazo') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.operacao.prazo') && `campo-alterado`
              }
              validate={(value) =>
                validationField(value, 'resumo.operacao.prazo', 'Prazo')
              }
              hasFeedback
            >
              <SelectSearch
                className="text-right"
                name="resumo.operacao.prazo"
                onChange={handleChangePlano}
                disabled={!editableField('resumo.operacao.prazo')}
                suffixIcon={<IconArrowSelect />}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={values?.resumo?.operacao?.prazo}
                fillDefault={editableField(`resumo.operacao.prazo`)}
              >
                {planos &&
                  planos.map((plano) => (
                    <Option
                      className="text-right"
                      key={plano.id}
                      value={plano.plano}
                    >
                      {plano.plano}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-8">
            <div className="form-row" style={{ marginLeft: -15 }}>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.prestacao"
                  label={
                    <>
                      <label>Prestação</label>
                      {campoChecar('resumo.operacao.prestacao') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.prestacao')?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.prestacao')?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.prestacao') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.prestacao') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.operacao.prestacao',
                      'Prestação',
                    )
                  }
                >
                  <Field name="resumo.operacao.prestacao">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        disabled={!editableField('resumo.operacao.prestacao')}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.vencimento"
                  label={
                    <>
                      <label>1º Vencimento</label>
                      {campoChecar('resumo.operacao.vencimento') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.vencimento')
                                ?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.vencimento')
                                ?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.vencimento') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.vencimento') &&
                    `campo-alterado`
                  }
                  validate={(value) =>
                    validationField(
                      value,
                      'resumo.operacao.vencimento',
                      '1º Vencimento',
                    )
                  }
                  hasFeedback
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    name="resumo.operacao.vencimento"
                    placeholder="00/00/0000"
                    disabledDate={disabledDate}
                    onBlur={() => setFieldTouched('resumo.operacao.vencimento')}
                    disabled={!editableField('resumo.operacao.vencimento')}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.cetMensal"
                  label={
                    <>
                      <label>CET Mensal (%)</label>
                      {campoChecar('resumo.operacao.cetMensal') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.cetMensal')?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.cetMensal')?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.cetMensal') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.cetMensal') &&
                    `campo-alterado`
                  }
                >
                  <Field name="resumo.operacao.cetMensal">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix=""
                        decimalScale={2}
                        fixedDecimalScale={true}
                        style={{ textAlign: 'right' }}
                        disabled={!editableField('resumo.operacao.cetMensal')}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Form.Item
              name="resumo.operacao.cetAnual"
              label={
                <>
                  <label>CET Anual (%)</label>
                  {campoChecar('resumo.operacao.cetAnual') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.operacao.cetAnual')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.operacao.cetAnual')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.operacao.cetAnual') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.operacao.cetAnual') && `campo-alterado`
              }
            >
              <Field name="resumo.operacao.cetAnual">
                {({ field }) => (
                  <InputMoney
                    {...field}
                    className="ant-input"
                    prefix=""
                    decimalScale={2}
                    fixedDecimalScale={true}
                    style={{ textAlign: 'right' }}
                    disabled={!editableField('resumo.operacao.cetAnual')}
                  />
                )}
              </Field>
            </Form.Item>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-8">
            <div className="form-row" style={{ marginLeft: -15 }}>
              <div className="col-lg-4">
                <Form.Item
                  name="resumo.operacao.cetAnual"
                  label={
                    <>
                      <label>IOF</label>
                      {campoChecar('resumo.operacao.iof') && (
                        <Checkbox
                          className="check-campo"
                          checked={
                            !!getChecado(
                              campoChecar('resumo.operacao.iof')?.campoId,
                            )
                          }
                          onChange={() =>
                            handleChangeChecados(
                              campoChecar('resumo.operacao.iof')?.campoId,
                            )
                          }
                        />
                      )}
                      {highlightField('resumo.operacao.iof') && (
                        <TooltipAlterados />
                      )}
                    </>
                  }
                  className={
                    highlightField('resumo.operacao.iof') && `campo-alterado`
                  }
                >
                  <Field name="resumo.operacao.iof">
                    {({ field }) => (
                      <InputMoney
                        {...field}
                        className="ant-input"
                        prefix="R$ "
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        maxLength={12}
                        disabled={!editableField('resumo.operacao.iof')}
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>
              {values?.resumo?.operacao?.saldoDevedor && (
                <div className="col-lg-4">
                  <Form.Item
                    name="resumo.operacao.saldoDevedor"
                    label={
                      <>
                        <label>Saldo Devedor</label>
                        {campoChecar('resumo.operacao.saldoDevedor') && (
                          <Checkbox
                            className="check-campo"
                            checked={
                              !!getChecado(
                                campoChecar('resumo.operacao.saldoDevedor')
                                  ?.campoId,
                              )
                            }
                            onChange={() =>
                              handleChangeChecados(
                                campoChecar('resumo.operacao.saldoDevedor')
                                  ?.campoId,
                              )
                            }
                          />
                        )}
                        {highlightField('resumo.operacao.saldoDevedor') && (
                          <TooltipAlterados />
                        )}
                      </>
                    }
                    className={
                      highlightField('resumo.operacao.saldoDevedor') &&
                      `campo-alterado`
                    }
                  >
                    <Field name="resumo.operacao.saldoDevedor">
                      {({ field }) => (
                        <InputMoney
                          {...field}
                          className="ant-input"
                          prefix="R$ "
                          allowNegative={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          maxLength={12}
                          disabled={
                            !editableField('resumo.operacao.saldoDevedor')
                          }
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
        </div>

        {values?.operacao?.convenioNome !== null &&
          values?.operacao?.dadosAdicionais?.length > 0 && (
            <div className="dados-adicionais-convenio">
              <div className="form-row item-convenio">
                <div className="col-lg-3">
                  <Form.Item
                    name="resumo.operacao.convenioNome"
                    label="Companhia Elétrica"
                  >
                    <Field name="resumo.operacao.convenioNome">
                      {({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled={
                            contexto?.bloqueiaAcessoSimultaneo ||
                            !editableField('resumo.operacao.convenioNome')
                          }
                          readOnly
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>

                {values?.operacao?.dadosAdicionais?.map((dadoAdicional, i) => {
                  validaDadosAdicionais();
                  return (
                    <div className="col-lg-3" key={i}>
                      <Form.Item
                        name={`operacao.dadosAdicionais.${i}.valor`}
                        label={
                          <>
                            <label>{dadoAdicional?.nome}</label>
                            {values?.operacao?.dadosAdicionais[i]?.tooltip && (
                              <TooltipUnidadeConsumidora
                                data={values?.operacao?.dadosAdicionais[i]}
                              />
                            )}
                          </>
                        }
                        hasFeedback
                        className={
                          values?.operacao?.dadosAdicionais[i]?.tooltip &&
                          `campo-existente`
                        }
                      >
                        {dadoAdicional?.tipo !== 4 ? (
                          <Field
                            name={`operacao.dadosAdicionais.${i}.valor`}
                            validate={(value) => {
                              if (!RegExp(dadoAdicional?.formato).test(value)) {
                                return dadoAdicional?.mensagem;
                              }
                            }}
                          >
                            {({ field }) => (
                              <Input
                                {...field}
                                defaultValue={dadoAdicional?.valor}
                                value={
                                  values?.operacao?.dadosAdicionais[i]?.valor
                                }
                                onBlur={(e) =>
                                  setFieldValue(
                                    `operacao.dadosAdicionais.${i}.valor`,
                                    e.target.value,
                                  )
                                }
                                disabled={
                                  contexto?.bloqueiaAcessoSimultaneo ||
                                  !editableField(
                                    'resumo.operacao.dadosAdicionais',
                                  )
                                }
                              />
                            )}
                          </Field>
                        ) : (
                          <DatePicker
                            format="DD/MM/YYYY"
                            placeholder="00/00/0000"
                            prefix={<CalendarIcon className="mr-2" />}
                            name={`operacao.dadosAdicionais.${i}.valor`}
                            disabled={
                              contexto?.bloqueiaAcessoSimultaneo ||
                              !editableField('resumo.operacao.dadosAdicionais')
                            }
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

        <div className="form-row pb-3">
          <div className="col-lg-12 titleResumo">Endereço</div>
        </div>

        <div className="form-row">
          <div className="col-lg-5">
            <Form.Item
              name="resumo.endereco.logradouro"
              validate={(value) =>
                validationField(
                  value,
                  'resumo.endereco.logradouro',
                  'Logradouro',
                )
              }
              className={
                highlightField('resumo.endereco.logradouro') && `campo-alterado`
              }
              label={
                <>
                  <label>Logradouro</label>
                  <TooltipInfo text="Rua / Avenida /..." />
                  {campoChecar('resumo.endereco.logradouro') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.logradouro')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.logradouro')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.logradouro') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.endereco.logradouro"
                disabled={!editableField('resumo.endereco.logradouro')}
                value={values?.resumo?.endereco?.logradouro}
                onBlur={(e) =>
                  setFieldValue(`resumo.endereco.logradouro`, e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-lg-3">
            <Form.Item
              name="resumo.endereco.numero"
              label={
                <>
                  <label>Número</label>
                  {campoChecar('resumo.endereco.numero') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.numero')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.numero')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.numero') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(value, 'resumo.endereco.numero', 'Número')
              }
              className={
                highlightField('resumo.endereco.numero') && `campo-alterado`
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.endereco.numero"
                type="number"
                disabled={!editableField('resumo.endereco.numero')}
                placeholder="Ex.: 603"
                value={values?.resumo?.endereco?.numero}
                onBlur={(e) =>
                  setFieldValue(`resumo.endereco.numero`, e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
              name="resumo.endereco.complemento"
              label={
                <>
                  <label>Complemento</label>
                  {campoChecar('resumo.endereco.complemento') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.complemento')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.complemento')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.complemento') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              validate={(value) =>
                validationField(
                  value,
                  'resumo.endereco.complemento',
                  'Complemento',
                )
              }
              className={
                highlightField('resumo.endereco.complemento') &&
                `campo-alterado`
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.endereco.complemento"
                disabled={!editableField('resumo.endereco.complemento')}
                placeholder="Ex.: Casa, Apto, Bloco"
                value={values?.resumo?.endereco?.complemento}
                onBlur={(e) =>
                  setFieldValue(`resumo.endereco.complemento`, e.target.value)
                }
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-5">
            <Form.Item
              name="resumo.endereco.bairro"
              label={
                <>
                  <label>Bairro</label>
                  {campoChecar('resumo.endereco.bairro') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.bairro')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.bairro')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.bairro') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.endereco.bairro') && `campo-alterado`
              }
              validate={(value) =>
                validationField(value, 'resumo.endereco.bairro', 'Bairro')
              }
              hasFeedback
            >
              <SimpleInput
                name="resumo.endereco.bairro"
                disabled={!editableField('resumo.endereco.bairro')}
                placeholder="Ex.: Santa Lucia"
                value={values?.resumo?.endereco?.bairro}
                onBlur={(e) =>
                  setFieldValue(`resumo.endereco.bairro`, e.target.value)
                }
              />
            </Form.Item>
          </div>
          <div className="col-lg-3">
            <Form.Item
              name="resumo.endereco.cidadeId"
              label={
                <>
                  <label>Cidade</label>
                  {campoChecar('resumo.endereco.cidadeId') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.cidadeId')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.cidadeId')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.cidadeId') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.endereco.cidadeId') && `campo-alterado`
              }
            >
              <Field name="resumo.endereco.cidadeId">
                {({ field, form }) => (
                  <SelectSearch
                    {...field}
                    onChange={(value) =>
                      form.setFieldValue('resumo.endereco.cidadeId', value)
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    optionFilterProp="children"
                    autoComplete="cidadeId"
                    suffixIcon={<IconArrowSelect />}
                    disabled={!editableField('resumo.endereco.cidadeId')}
                    value={values?.resumo?.endereco?.cidadeId}
                    fillDefault={editableField(`resumo.endereco.cidadeId`)}
                  >
                    {cidades.length > 0 &&
                      cidades.map((cidade) => (
                        <Option key={cidade.id} value={cidade.id}>
                          {cidade.nome}
                        </Option>
                      ))}
                  </SelectSearch>
                )}
              </Field>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
              name="resumo.endereco.ufId"
              label={
                <>
                  <label>UF</label>
                  {campoChecar('resumo.endereco.ufId') && (
                    <Checkbox
                      className="check-campo"
                      checked={
                        !!getChecado(
                          campoChecar('resumo.endereco.ufId')?.campoId,
                        )
                      }
                      onChange={() =>
                        handleChangeChecados(
                          campoChecar('resumo.endereco.ufId')?.campoId,
                        )
                      }
                    />
                  )}
                  {highlightField('resumo.endereco.ufId') && (
                    <TooltipAlterados />
                  )}
                </>
              }
              className={
                highlightField('resumo.endereco.ufId') && `campo-alterado`
              }
            >
              <SelectSearch
                name="resumo.endereco.ufId"
                onChange={(value) => handleSelecionarEstado(value)}
                showSearch
                getPopupContainer={(trigger) => trigger.parentNode}
                disabled={!editableField('resumo.endereco.ufId')}
                optionFilterProp="children"
                autoComplete="ufId"
                suffixIcon={<IconArrowSelect />}
                value={values?.resumo?.endereco?.ufId}
                fillDefault={editableField(`resumo.endereco.ufId`)}
              >
                {estados.length > 0 &&
                  estados.map((estado) => (
                    <Option key={estado.id} value={estado.id}>
                      {estado.uf}
                    </Option>
                  ))}
              </SelectSearch>
            </Form.Item>
          </div>
        </div>

        {values?.operacao?.produtoNome === 'CP Auto' && (
          <>
            <div className="form-row pb-3">
              <div className="col-lg-12 titleResumo">Dados do Veículo</div>
            </div>

            <div className="form-row">
              <div className="col-lg-3">
                <Form.Item name="dadosBemGarantia.placa" label="Placa">
                  <SimpleInput
                    name="dadosBemGarantia.placa"
                    value={values?.dadosBemGarantia?.placa}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item name="dadosBemGarantia.marca" label="Marca">
                  <SimpleInput
                    name="dadosBemGarantia.marca"
                    value={values?.dadosBemGarantia?.marca}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  name="dadosBemGarantia.modeloVersao"
                  label="Modelo/Versão"
                >
                  <SimpleInput
                    name="dadosBemGarantia.modeloVersao"
                    value={values?.dadosBemGarantia?.modeloVersao}
                    disabled
                  />
                </Form.Item>
              </div>
            </div>

            <div className="form-row">
              <div className="col-lg-3">
                <Form.Item name="dadosBemGarantia.anoModelo" label="Ano Modelo">
                  <SimpleInput
                    name="dadosBemGarantia.anoModelo"
                    value={values?.dadosBemGarantia?.anoModelo}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item
                  name="dadosBemGarantia.anoFabricacao"
                  label="Ano Fabricação"
                >
                  <SimpleInput
                    name="dadosBemGarantia.anoFabricacao"
                    value={values?.dadosBemGarantia?.anoFabricacao}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item
                  name="dadosBemGarantia.ufLicenciamento"
                  label="UF Licenciamento"
                >
                  <SelectSearch
                    name="dadosBemGarantia.ufLicenciamento"
                    value={values?.dadosBemGarantia?.ufLicenciamento}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="col-lg-3">
                <Form.Item
                  name="dadosBemGarantia.ufRegistro"
                  label="UF de Registro"
                >
                  <SelectSearch
                    name="dadosBemGarantia.ufRegistro"
                    value={values?.dadosBemGarantia?.ufRegistro}
                    disabled
                  />
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ResumoContato;
