import React, { useContext, useState, useEffect } from 'react';

import moment from 'moment';
import { Field } from 'formik';
import { Form, Select } from 'formik-antd';

import '../SimulacaoForm/styles.scss';

import { SimulacaoContext } from '../../../contexts/SimulacaoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import {
  calcularParcela,
  calculaVencimento,
} from '../../../services/simulacaoService';

import Button from '../../../components/Button';
import DatePicker from '../../../components/DatePicker';
import InputMoney from '../../../components/InputMoney';

import { currencyFormat } from '../../../utils/numberFormatter';

const { Option } = Select;

const monthToAdd = 'month';
const dayToAdd = 'day';
let calculoVencimento;

const SelectParcela = ({ onChange }) => (
  <Select
    name="tipoCalculo"
    size="small"
    onChange={onChange}
    className="filled"
    style={{
      fontWeight: 'bold',
      width: '200px',
      backgroundColor: '#cccccc',
      marginBottom: '-2px',
    }}
  >
    <Option key={0} value={0}>
      Valor solicitado
    </Option>
    <Option key={1} value={1}>
      Valor da parcela
    </Option>
  </Select>
);

function FormLayout({ setFieldValue, values, setFieldTouched, submitForm }) {
  const { checarPermissao } = useContext(ControleAcessoContext);

  const {
    produtoId,
    convenioId,
    tabelaJurosId,
    dataVencimento,
    tipoCalculo,
    permiteSelecao,
  } = values;

  const [disabled, setDisabled] = useState(true);
  const {
    produtos,
    setSimulacao,
    setTipoSimulacao,
    solicitarSimulacao,
    setParcelaMaxima,
  } = useContext(SimulacaoContext);

  const [convenios, setConvenios] = useState([]);
  const [tabelasJuros, setTabelasJuros] = useState([]);
  const [tabelaSelecionada, setTabelaSelecionada] = useState(null);
  const [valores, setValores] = useState([]);
  const [parcelas, setParcelas] = useState([]);
  const [oldForm, setOldForm] = useState(values);

  useEffect(() => {
    if (!produtoId) {
      calculoVencimento = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEnvioFormulario = () => {
    if (oldForm && JSON.stringify(oldForm) !== JSON.stringify(values)) {
      submitForm();
    }
    setOldForm(values);
  };

  async function calcularVencimentos() {
    if (produtoId) {
      const response = await calculaVencimento({
        produtoId,
        propostaId: null,
        convenioId,
        rota: null,
        leitura: null,
        vencimento: null,
        tabelaJurosId,
      });

      let vencimentosPossiveis;

      if (response?.data?.length > 0) {
        vencimentosPossiveis = response?.data?.map((vencimento) => {
          return vencimento?.vencimento;
        });
        if (vencimentosPossiveis?.length === 1) {
          setFieldValue('dataVencimento', vencimentosPossiveis[0]);
        } else {
          setFieldValue('dataVencimento', '');
        }

        calculoVencimento =
          // eslint-disable-next-line
          vencimentosPossiveis[0] == undefined ? [] : vencimentosPossiveis;
      } else {
        calculoVencimento = [];
      }
    }
  }

  useEffect(() => {
    if (produtoId) {
      const tempProduto = produtos?.find((el) => el.id === produtoId);
      setConvenios(tempProduto?.convenio || []);
      setTabelasJuros(tempProduto?.tabelaJuros || []);

      setFieldValue(
        'permiteSelecao',
        tempProduto?.convenioTipo?.permiteSelecao,
      );
      setFieldValue('convenioId', null);
      setFieldTouched('convenioId', false);

      if (tempProduto?.tabelaJuros?.length === 1) {
        setFieldValue('tabelaJurosId', tempProduto?.tabelaJuros[0].id);
        setTabelaSelecionada(tempProduto?.tabelaJuros[0]);
      } else {
        setFieldValue('tabelaJurosId', null);
        setTabelaSelecionada(null);
      }
      setFieldTouched('tabelaJurosId', false);

      setFieldValue('dataVencimento', '');
      setFieldTouched('dataVencimento', false);
      setFieldValue('valorSolicitado', '');
      setFieldTouched('valorSolicitado', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtoId, produtos]);

  useEffect(() => {
    const isEnergia = !produtos.find((item) => item.id === produtoId)
      ?.tabelaJuros?.length; // Energia não possui tabela de juros, utiliza as tabelas de juros do convênio.
    if (convenioId && isEnergia) {
      const tempConvenio = convenios?.find((el) => el.id === convenioId);
      if (tempConvenio) {
        setFieldValue('tabelaJurosId', null);
        setTabelasJuros(tempConvenio?.tabelaJuros || []);
      }

      if (tempConvenio?.tabelaJuros?.length === 1) {
        setFieldValue('tabelaJurosId', tempConvenio?.tabelaJuros[0]?.id);
        setTabelaSelecionada(tempConvenio?.tabelaJuros[0]);
      }
      setFieldTouched('tabelaJurosId', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convenioId, setFieldValue, setFieldTouched, convenios]);

  useEffect(() => {
    async function getCalculoVencimento() {
      const tempTabelaJuros = tabelasJuros?.find(
        (el) => el.id === tabelaJurosId,
      );
      if (produtoId) {
        await calcularVencimentos();
      }

      if (tempTabelaJuros) {
        setTabelaSelecionada(tempTabelaJuros);
        carregarValores(tempTabelaJuros);
        if (calculoVencimento?.length === 0) {
          if (
            tempTabelaJuros?.carenciaMaxima ||
            tempTabelaJuros?.carenciaMedia
          ) {
            setFieldValue(
              'dataVencimento',
              moment().add(
                tempTabelaJuros?.carenciaMaxima ||
                  tempTabelaJuros?.carenciaMedia,
                dayToAdd,
              ),
            );
          } else {
            setFieldValue('dataVencimento', moment().add(1, monthToAdd));
          }
        }
      }

      setSimulacao([]);
    }

    getCalculoVencimento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelaJurosId]);

  useEffect(() => {
    if (tabelaSelecionada) {
      if (tabelaSelecionada?.tipo === 1) {
        calculaListaPrestacoes(valores);
        setFieldValue('valorSolicitado', null);
        setFieldValue('floatValorSolicitado', null);
      }
    }
    setSimulacao([]);

    if (tabelaSelecionada?.id) {
      const payload = {
        produtoId,
        convenioId,
        tabelaJurosId: tabelaSelecionada.id || null,
        vencimento: moment(dataVencimento).isValid()
          ? moment(dataVencimento).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        valorContrato: produtos?.find((item) => item.id === produtoId)
          .valorMaximo,
      };
      solicitarSimulacao(0, payload).then((response) => {
        setParcelaMaxima(
          response?.reduce((prev, current) => {
            return prev.prestacao > current.prestacao ? prev : current;
          }, {})?.prestacao,
        );
        setSimulacao([]);
      });
    }

    // eslint-disable-next-line
  }, [dataVencimento]);

  useEffect(() => {
    setFieldValue('valoresOuParcelas', tipoCalculo ? parcelas : valores);
    if (tabelaSelecionada && tabelaSelecionada?.tipo === 1) {
      setFieldValue('valorSolicitado', null);
      setFieldValue('floatValorSolicitado', null);
      setFieldTouched('valorSolicitado', false);
    }
    setSimulacao([]);
    setTipoSimulacao(tipoCalculo);
    // eslint-disable-next-line
  }, [valores, parcelas, tipoCalculo]);

  useEffect(() => {
    if (produtoId && (tabelaSelecionada?.id || tabelaJurosId)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [produtoId, tabelaJurosId, tabelaSelecionada]);

  const carregarValores = (tabela) => {
    if (tabela?.tipo === 1) {
      const uniqueValores = [];
      tabela?.tabelaJurosValores?.forEach((el) => {
        if (!uniqueValores?.find((unique) => unique?.valor === el?.valor)) {
          uniqueValores.push({
            valor: el?.valor,
            texto: currencyFormat(el?.valor),
          });
        }
      });
      setValores(uniqueValores);
      setFieldValue('valorSolicitado', null);
      setFieldValue('floatValorSolicitado', null);
    } else {
      setValores([]);
      setParcelas([]);
    }
  };

  const calculaListaPrestacoes = async (valores) => {
    const payload = {
      produtoId,
      convenioId,
      tabelaJurosId: tabelaSelecionada?.id,
      valorContrato: 0,
      vencimento: moment(dataVencimento).isValid()
        ? moment(dataVencimento)?.format('YYYY-MM-DD')
        : null,
    };
    const uniqueParcelas = [];

    let count = 0;
    valores?.length > 0 &&
      valores?.forEach(async (el) => {
        payload.valorContrato = el?.valor;
        await calcularParcela(payload).then((parcelas) => {
          count++;
          if (parcelas?.length > 1) {
            parcelas?.forEach((el) => {
              if (
                uniqueParcelas?.indexOf({
                  valor: el?.prestacao,
                  texto: currencyFormat(el?.prestacao),
                }) < 0
              ) {
                uniqueParcelas?.push({
                  valor: el?.prestacao,
                  texto: currencyFormat(el?.prestacao),
                });
              }
            });
          }
          if (count === valores?.length) setParcelas(uniqueParcelas);
        });
      });

    if (tabelaSelecionada?.id) {
      const payload = {
        produtoId,
        convenioId,
        tabelaJurosId: tabelaSelecionada.id || null,
        vencimento: moment(dataVencimento).isValid()
          ? moment(dataVencimento).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        valorContrato: produtos?.find((item) => item.id === produtoId)
          .valorMaximo,
      };
      solicitarSimulacao(0, payload).then((response) => {
        setParcelaMaxima(
          response?.reduce((prev, current) => {
            return prev.prestacao > current.prestacao ? prev : current;
          }, {})?.prestacao,
        );
        setSimulacao([]);
      });
    }
  };

  const disabledDate = (current) => {
    return (
      tabelaSelecionada &&
      !(
        current >=
          moment().add(tabelaSelecionada?.carenciaMinima, 'd').endOf('day') &&
        current <=
          moment().add(tabelaSelecionada?.carenciaMaxima, 'd').endOf('day')
      )
    );
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item name="produtoId" label="Produto">
            <Select
              name="produtoId"
              placeholder="Selecione um Produto"
              data-cy="produto"
            >
              {produtos &&
                produtos?.map((produto) => (
                  <Option key={produto.id} value={produto.id}>
                    {produto.nome}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      {produtoId && permiteSelecao && (
        <div className="form-row">
          <div className="col-lg-12">
            <Form.Item name="convenioId" label="Empresa Conveniada">
              <Select
                name="convenioId"
                placeholder="Empresa Conveniada"
                data-cy="empresa-conveniada"
              >
                {convenios &&
                  convenios?.map((convenio) => (
                    <Option key={convenio.id} value={convenio.id}>
                      {convenio.nome}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      )}
      {produtoId && tabelasJuros?.length > 1 && (
        <div className="form-row">
          <div className="col-lg-12">
            <Form.Item name="tabelaJurosId" label="Tabela de Juros">
              <Select
                name="tabelaJurosId"
                placeholder="Tabela de Juros"
                data-cy="tabela-juros"
              >
                {tabelasJuros &&
                  tabelasJuros?.map((tabela) => (
                    <Option key={tabela.id} value={tabela.id}>
                      {tabela.nome}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </div>
      )}
      {tabelaJurosId && (
        <div className="form-row">
          {calculoVencimento &&
            calculoVencimento?.length !== 1 &&
            !produtos.find((item) => item.id === produtoId)?.convenios
              ?.length && (
              <div className="col-lg-6 col-md-6">
                <Form.Item name="dataVencimento" label="Data 1º vencimento">
                  {calculoVencimento?.length === 0 ? (
                    <DatePicker
                      name="dataVencimento"
                      disabled={disabled}
                      format="DD/MM/YYYY"
                      disabledDate={disabledDate}
                    />
                  ) : (
                    <Select
                      name="dataVencimento"
                      className="text-right"
                    >
                      {calculoVencimento?.length > 0 &&
                        calculoVencimento?.map((item) => (
                          <Option value={item}>
                            {moment(item)?.format('DD/MM/YYYY')}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}

          <div className={'col-lg-6'}>
            <Form.Item
              name="valorSolicitado"
              label={
                <SelectParcela
                  onChange={() => {
                    calculaListaPrestacoes(valores);
                  }}
                />
              }
            >
              {(!tabelaSelecionada || tabelaSelecionada?.tipo === 0) && (
                <Field name="valorSolicitado">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      maxLength={12}
                      onValueChange={(values) => {
                        setFieldValue(
                          'floatValorSolicitado',
                          values?.floatValue,
                        );
                      }}
                      disabled={disabled}
                      data-cy="valor-solicitado-input"
                    />
                  )}
                </Field>
              )}

              {values?.valoresOuParcelas &&
                tabelaSelecionada &&
                tabelaSelecionada?.tipo === 1 && (
                  <Select
                    name="valorSolicitado"
                    placeholder="Valor"
                    style={{ textAlign: 'right' }}
                    onChange={(value) => {
                      setFieldValue('floatValorSolicitado', parseFloat(value));
                    }}
                    disabled={disabled}
                    data-cy="valor-solicitado-select"
                  >
                    {values?.valoresOuParcelas.map((item) => (
                      <Option
                        key={item.valor}
                        value={item.valor}
                        style={{ textAlign: 'right' }}
                      >
                        {item.texto}
                      </Option>
                    ))}
                  </Select>
                )}
            </Form.Item>
          </div>
        </div>
      )}

      {checarPermissao('botao', 'botao.simulacao.simular', 'Visualizar') &&
        produtoId && (
          <div className="d-flex justify-content-end">
            <Button
              type="button"
              onClick={() => {
                handleEnvioFormulario();
              }}
              variant="orange"
              data-cy="submit"
              async={true}
            >
              SIMULAR
            </Button>
          </div>
        )}
    </>
  );
}

export default FormLayout;
