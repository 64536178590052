import React, { useContext, useState, useEffect } from 'react';

import { Form, Field, Select, Checkbox } from 'formik-antd';
import { Tooltip } from 'antd';
import InputMask from 'react-text-mask';
import Modal from '../../../components/Modal';
import Input from '../../../components/SimpleInput';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import TooltipAlterados from '../../../components/TooltipAlterados';
import SelectSearch from '../../../components/SearchSelect';

import { cepMask } from '../../../utils/masks';
import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';

const { Option } = Select;

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

function EnderecoForm() {
  const {
    editableField,
    validationField,
    estados,
    cidades,
    setEnderecoUfId,
    fetchEndereco,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
    formik,
    setCepInvalido,
    cepInvalido,
  } = useContext(AnaliseMesaContext);

  const [tempCEP, setTempCEP] = useState('');

  useEffect(() => {
    if (tempCEP === '') {
      setTempCEP(formik.values.endereco.cep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.endereco?.cep]);

  const handleConsultarCEP = async (value, form) => {
    form.setFieldTouched('endereco.cep');
    if (value) {
      value = value.replace(/\D/g, '');

      const data = await fetchEndereco(value);
      if (data?.status) {
        if (data.ufId > 0 && data.ufId === form.values.endereco.ufId) {
          form.setFieldValue('endereco.ufId', data.ufId);
          form.setFieldValue('endereco.cidadeId', data.cidadeId);
          form.setFieldValue('endereco.logradouro', data.logradouro);
          form.setFieldValue('endereco.bairro', data.bairro);
          setTempCEP(form?.values?.endereco?.cep);
          setCepInvalido(false);
        } else {
          form.setFieldValue('endereco.cep', tempCEP);
          setCepInvalido(false);
          Modal(
            '',
            'Alteração inválida, o endereço somente pode ser alterado desde que não ocorra alteração na UF do cliente. Se necessário correção da UF, a proposta deve ser cancelada e lançada novamente',
            'warning',
          );
        }
      } else {
        formik.setFieldError('endereco.cep', 'CEP inválido ou não encontrado');
        setCepInvalido(true);
      }
    } else {
      form.setFieldValue('endereco.cep', tempCEP);
      setCepInvalido(false);
    }
  };

  useEffect(() => {
    if (
      cepInvalido &&
      !formik?.errors?.endereco?.cep &&
      formik?.values?.endereco?.cep !== ''
    ) {
      formik.setFieldError('endereco.cep', 'CEP inválido ou não encontrado');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cepInvalido]);

  const handleSelecionarEstado = (value, form) => {
    setEnderecoUfId(value);
    form.setFieldValue('endereco.ufId', value);
    form.setFieldValue('endereco.cidadeId', '');
  };

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="endereco.cep"
            label={
              <>
                <label>CEP</label>
                {campoChecar('endereco.cep') && (
                  <Checkbox
                    className="check-campo"
                    checked={!!getChecado(campoChecar('endereco.cep')?.campoId)}
                    onChange={() =>
                      handleChangeChecados(campoChecar('endereco.cep')?.campoId)
                    }
                  />
                )}
                {highlightField('endereco.cep') && <TooltipAlterados />}
              </>
            }
            className={highlightField('endereco.cep') && `campo-alterado`}
            validate={(value) => validationField(value, 'endereco.cep', 'CEP')}
            hasFeedback
          >
            <Field name="endereco.cep">
              {({ field, form }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="00000-000"
                  autoComplete="cep"
                  mask={cepMask}
                  showMask="true"
                  disabled={!editableField('endereco.cep')}
                  onBlur={(e) => handleConsultarCEP(e.target.value, form)}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="endereco.logradouro"
            validate={(value) =>
              validationField(value, 'endereco.logradouro', 'Logradouro')
            }
            className={
              highlightField('endereco.logradouro') && `campo-alterado`
            }
            label={
              <>
                <label>Logradouro</label>
                <TooltipInfo text="Rua / Avenida /..." />
                {campoChecar('endereco.logradouro') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.logradouro')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.logradouro')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.logradouro') && <TooltipAlterados />}
              </>
            }
            hasFeedback
          >
            <Input
              name="endereco.logradouro"
              disabled={!editableField('endereco.logradouro')}
              value={formik?.values?.endereco?.logradouro}
              onBlur={(e) =>
                formik?.setFieldValue('endereco.logradouro', String(e.target.value).trim())
              }
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="endereco.numero"
            label={
              <>
                <label>Número</label>
                {campoChecar('endereco.numero') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.numero')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.numero')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.numero') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'endereco.numero', 'Número')
            }
            className={highlightField('endereco.numero') && `campo-alterado`}
            hasFeedback
          >
            <Input
              name="endereco.numero"
              type="number"
              disabled={!editableField('endereco.numero')}
              placeholder="Ex.: 603"
              value={formik?.values?.endereco?.numero}
              onBlur={(e) =>
                formik?.setFieldValue('endereco.numero', e.target.value)
              }
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="endereco.ufId"
            label={
              <>
                <label>UF</label>
                {campoChecar('endereco.ufId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.ufId')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.ufId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.ufId') && <TooltipAlterados />}
              </>
            }
            className={highlightField('endereco.ufId') && `campo-alterado`}
          >
            <Field name="endereco.ufId">
              {({ field, form }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) => handleSelecionarEstado(value, form)}
                  showSearch
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!editableField('endereco.ufId')}
                  optionFilterProp="children"
                  autoComplete="ufId"
                  suffixIcon={<IconArrowSelect />}
                  value={form?.values?.endereco?.ufId}
                  fillDefault={editableField(`endereco.ufId`)}
                >
                  {estados.length > 0 &&
                    estados.map((estado) => (
                      <Option key={estado.id} value={estado.id}>
                        {estado.uf}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="endereco.cidadeId"
            label={
              <>
                <label>Cidade</label>
                {campoChecar('endereco.cidadeId') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.cidadeId')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.cidadeId')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.cidadeId') && <TooltipAlterados />}
              </>
            }
            className={highlightField('endereco.cidadeId') && `campo-alterado`}
          >
            <Field name="endereco.cidadeId">
              {({ field, form }) => (
                <SelectSearch
                  {...field}
                  onChange={(value) =>
                    form.setFieldValue('endereco.cidadeId', value)
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                  showSearch
                  disabled={!editableField('endereco.cidadeId')}
                  optionFilterProp="children"
                  autoComplete="cidadeId"
                  suffixIcon={<IconArrowSelect />}
                  value={form?.values?.endereco?.cidadeId}
                  fillDefault={editableField(`endereco.cidadeId`)}
                >
                  {cidades.length > 0 &&
                    cidades.map((cidade) => (
                      <Option key={cidade.id} value={cidade.id}>
                        {cidade.nome}
                      </Option>
                    ))}
                </SelectSearch>
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-5">
          <Form.Item
            name="endereco.bairro"
            label={
              <>
                <label>Bairro</label>
                {campoChecar('endereco.bairro') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.bairro')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.bairro')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.bairro') && <TooltipAlterados />}
              </>
            }
            className={highlightField('endereco.bairro') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'endereco.bairro', 'Bairro')
            }
            hasFeedback
          >
            <Input
              name="endereco.bairro"
              disabled={!editableField('endereco.bairro')}
              placeholder="Ex.: Santa Lucia"
              value={formik?.values?.endereco?.bairro}
              onBlur={(e) =>
                formik?.setFieldValue('endereco.bairro', String(e.target.value).trim())
              }
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item
            name="endereco.complemento"
            label={
              <>
                <label>Complemento</label>
                {campoChecar('endereco.complemento') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('endereco.complemento')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('endereco.complemento')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('endereco.complemento') && <TooltipAlterados />}
              </>
            }
            validate={(value) =>
              validationField(value, 'endereco.complemento', 'Complemento')
            }
            className={
              highlightField('endereco.complemento') && `campo-alterado`
            }
            hasFeedback
          >
            <Input
              name="endereco.complemento"
              disabled={!editableField('endereco.complemento')}
              placeholder="Ex.: Casa, Apto, Bloco"
              value={formik?.values?.endereco?.complemento}
              onBlur={(e) =>
                formik?.setFieldValue('endereco.complemento', String(e.target.value).trim())
              }
              formatWhitespace={true}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default EnderecoForm;
