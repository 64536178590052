import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Select } from 'antd';

import useInspinia from '../../hooks/useInspinia';

import { Breadcrumb } from 'antd';

import OfertasProdutosProvider from '../../contexts/OfertasProdutosContext';
import { OfertasProdutosContext } from '../../contexts/OfertasProdutosContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

import { currencyFormat } from '../../utils/numberFormatter';

import CalculadoraProvider from '../../contexts/CalculadoraContext';

import InputMoney from '../../components/InputMoney';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import Card from '../../components/Card';
import Title from '../../components/Title';
import Field from '../../components/Field';

import OfertasProdutosList from './OfertasProdutosList';
import OfertasProdutosForm from './OfertasProdutosForm';

import './styles.scss';

import { ReactComponent as BackIcon } from '../../assets/images/icons/back.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ControleRenda } from '../../utils/identificadoresFrontBack';

import { Tooltip } from 'antd';
import { ReactComponent as BulletInfo } from '../../assets/images/icons/bullet-info-tooltip2.svg';

const { Option } = Select;
const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info selecao-oferta"
    overlayClassName="tooltip-selecao-oferta"
    color="#435361"
    title={text}
  >
    <BulletInfo className="bullet-info-icon" />
  </Tooltip>
);

function OfertasProdutosPage() {
  const RENDA_ESTIMADA = 0;
  const RENDA_MANUAL = 1
  const {
    proposta,
    renda,
    setRenda,
    touchedRenda,
    setTouchedRenda,
    tipoRenda,
    setTipoRenda,
    produto,
    setProduto,
    propostaId,
    setRendaFinal,
  } = useContext(OfertasProdutosContext);

  const [alterTipoRenda, setAlterTipoRenda] = useState(false);

  const history = useHistory();

  useEffect(() => {
    return () => setTouchedRenda(false);
  }, [setTouchedRenda]);

  useEffect(() => {
    if (tipoRenda === RENDA_ESTIMADA) {
      setAlterTipoRenda(true);
      setRenda(proposta.valorRendaPresumida);
      setRendaFinal(proposta.valorRendaPresumida);
    } else {
      setAlterTipoRenda(false);
      setRenda(0);
      setTouchedRenda(false);
    }
    if (produto?.controleRenda !== ControleRenda.APENAS_ESTIMADA) setProduto(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoRenda]);

  useEffect(() => {
    switch (produto?.controleRenda) {
      case ControleRenda.APENAS_ESTIMADA:
        setTipoRenda(RENDA_ESTIMADA);
        setProduto(produto);
        break;
      case ControleRenda.APENAS_MANUAL:
        setTipoRenda(RENDA_MANUAL);
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produto]);

  useEffect(() => {
    if (!proposta?.valorRendaPresumida) {
      setTipoRenda(RENDA_ESTIMADA);
    }
  }, [proposta?.valorRendaPresumida, setTipoRenda]);

  return (
    <div className="row oferta-produto">
      <div className="col-lg-12">
        <Card
          title={
            <div className="title-oferta">
              <Title label="Ofertas de Produtos" />
              <button
                type="button"
                className="btn btn-orange"
                onClick={() => history.push(`/pre-analise/${propostaId}`)}
              >
                <BackIcon />
              </button>
            </div>
          }
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="wrapper-grid dados-cliente">
                <div className="info">
                  <b className="title-cliente">Cliente</b>

                  <div className="response">{proposta.nome}</div>
                </div>
                <div className="info">
                  <b className="title-cliente">CPF</b>

                  <div className="response">
                    {Object.entries(proposta).length > 0 &&
                      proposta.cpf.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4',
                      )}
                  </div>
                </div>
                <div className="info renda">
                  
               <div className="form-row">
                {(produto === null || produto?.controleRenda === ControleRenda.TODAS) ? (
                    <Select
                      id="tipoRenda"
                      name="tipoRenda"
                      value={tipoRenda}
                      onChange={(value) => setTipoRenda(value)}
                      size="small"
                      className="filled"
                      suffixIcon={<IconArrowSelect />}
                      getPopupContainer={(trigger) => trigger.parentNode}
                    >
                      <Option value={RENDA_ESTIMADA}>Renda Estimada</Option>
                      <Option value={RENDA_MANUAL}>Renda Manual</Option>
                    </Select>
                ): (
                  <b className='renda-text'>
                    {produto?.controleRenda === ControleRenda.APENAS_ESTIMADA && "Renda Estimada"} 
                    {produto?.controleRenda === ControleRenda.APENAS_MANUAL && <>Renda Manual <TooltipInfo text="Deverá ser informada a renda liquida que consta no holerite do cliente." /></>}
                  </b>
                )}
                </div>
                


                  <div className="text-right response">
                    {tipoRenda === RENDA_ESTIMADA ? (
                      currencyFormat(proposta?.valorRendaPresumida || 0)
                    ) : (
                      <div
                        className={
                          !renda && touchedRenda
                            ? 'ant-form-item-has-error'
                            : ''
                        }
                      >
                        <Field
                          message={
                            !renda && touchedRenda ? 'Renda obrigatória' : ''
                          }
                        >
                          <InputMoney
                            className="ant-input"
                            prefix="R$ "
                            allowNegative={false}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            maxLength={12}
                            value={renda}
                            onBlur={() => setRendaFinal(renda)}
                            onValueChange={(values) => {
                              alterTipoRenda && setTouchedRenda(true);
                              renda === 0 && setProduto(null);
                              setRenda(values.floatValue);
                            }}
                          />
                        </Field>
                      </div>
                    )}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="row main-area">
            <div className="col-lg-4 mt-4">
              <OfertasProdutosList />
            </div>
            {renda > 0 && (
              <div className="col-lg-8 mt-4 pl-0">
                <OfertasProdutosForm />
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}

function OfertasProdutos() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Nova Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Ofertas de Produtos</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        {checarPermissao (
          'tela',
          'tela.credito.ofertaProdutos',
          'Visualizar',
        ) && (

        <CalculadoraProvider>
          <OfertasProdutosProvider>
            <OfertasProdutosPage />
          </OfertasProdutosProvider>
        </CalculadoraProvider>
        )}
      </Content>
    </Main>
  );
}

export default OfertasProdutos;
