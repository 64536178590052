import React, { createContext, useEffect, useState } from 'react';

import {
  listarGruposParametro,
  inserirGrupoParametro,
  buscarGrupoParametro,
  atualizarGrupoParametro,
} from '../services/parametroGrupoService';
import {
  listarParametros,
  buscarParametro,
  inserirParametro,
  atualizarParametro,
  buscarParametroAtualFuturo,
  deleteParametroHistorico,
} from '../services/parametroService';

export const ParametroContext = createContext({});

const initialParametroState = {
  id: 0,
  parametroGrupoId: null,
  tipo: null,
  codigo: '',
  nome: '',
  notaTecnica: '',
  ordem: 0,
  valor: '',
  validadeInicial: new Date(),
};

const initialGrupoParametroState = {
  id: 0,
  nome: '',
  ordem: 0,
};

function ParametroProvider({ children }) {
  const [gruposParametro, setGruposParametro] = useState([]);
  const [parametros, setParametros] = useState([]);
  const [parametro, setParametro] = useState(initialParametroState);
  const [parametroAtualFuturo, setParametroAtualFuturo] = useState({});
  const [grupoParametro, setGrupoParametro] = useState(
    initialGrupoParametroState,
  );

  const resetParametroForm = () => {
    setParametro(initialParametroState);
  };

  const fetchGruposParametro = async () => {
    const response = await listarGruposParametro();
    if (response) {
      setGruposParametro(response);
    }
  };

  const fetchParametros = async () => {
    const response = await listarParametros();
    if (response) {
      setParametros(response);
    }
  };

  const salvarParametro = async (values) => {
    if (values.id > 0) {
      const response = await atualizarParametro(values);
      if (!response.success) {
        return response.messages;
      } else {
        setParametro(initialParametroState);
        await fetchParametros();
        return response;
      }
    } else {
      values.ordem = parametros.length + 1;
      const response = await inserirParametro(values);
      if (!response.success) {
        return response.messages;
      } else {
        setParametro(initialParametroState);
        await fetchParametros();
        return response;
      }
    }
  };

  const editarParametro = async (id) => {
    const response = await buscarParametro(id);

    const parametroHistorico = await buscarParametroAtualFuturo(response.id);
    setParametro(response);
    setParametroAtualFuturo(parametroHistorico);
  };

  const removerParametroHistorico = async (id) => {
    return await deleteParametroHistorico(id);
  };

  const salvarGrupoParametro = async (values) => {
    let response = null;

    if (values.id > 0) {
      response = await atualizarGrupoParametro(values);
      fetchGruposParametro();
    } else {
      values.ordem = gruposParametro.length + 1;
      response = await inserirGrupoParametro(values);
      fetchGruposParametro();
    }

    return response;
  };

  const editarGrupoParametro = async (id) => {
    const response = await buscarGrupoParametro(id);
    setGrupoParametro(response);
  };

  useEffect(() => {
    fetchParametros();
    fetchGruposParametro();
  }, []);

  return (
    <ParametroContext.Provider
      value={{
        gruposParametro,
        parametros,
        parametro,
        parametroAtualFuturo,
        grupoParametro,
        salvarParametro,
        editarParametro,
        setParametro,
        salvarGrupoParametro,
        editarGrupoParametro,
        resetParametroForm,
        removerParametroHistorico,
        setParametroAtualFuturo,
      }}
    >
      {children}
    </ParametroContext.Provider>
  );
}

export default ParametroProvider;
