import React from 'react';

function Wrapper({ children }) {
  return (
    <div id="page-wrapper" className="gray-bg">
      {children}
    </div>
  );
}

export default Wrapper;
