/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';

import { Field } from 'formik';
import { Modal } from 'antd';
import { Form, Select } from 'formik-antd';

import ModalNotification from '../../../components/Modal';

import locale from 'antd/es/date-picker/locale/pt_BR';

import { OfertasProdutosContext } from '../../../contexts/OfertasProdutosContext';
import { CalculadoraContext } from '../../../contexts/CalculadoraContext';

import columns from './columnsRefin';

import Table from '../../../components/BasicTable';
import InputMoney from '../../../components/InputMoney';
import DatePicker from '../../../components/DatePicker';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Icon from '../../../components/Icon';

import { currencyFormat } from '../../../utils/numberFormatter';

import './styles.scss';

const { Option } = Select;

let objetoLimitesParcela;
let objetoLimitesProduto;

const dateFormat = 'DD/MM/YYYY';

function Refin({
  formik,
  setSubmitButton,
  planoSelecionado,
  setLimiteParcelas,
}) {
  const { values, resetForm, setFieldValue, setFieldTouched } = formik;

  const { convenioId, dataVencimento, tipoCalculo } = values;

  const {
    produto,
    setSimulacaoProposta,
    renda,
    proposta,
    propostaId,
    maiorValorLimiteParcela,
    setMaiorValorLimiteParcela,
    setLoading,
    maiorValorLimite,
    setMaiorValorLimite,
    rendaFinal,
    contratoSelecionado,
    setContratoSelecionado,
    saldoDevedor,
    setSaldoDevedor,
    setPlanoSelecionado,
    diaRecebimento,
  } = useContext(OfertasProdutosContext);

  const {
    calcularValores,
    calcularVencimentos,
    calcularLimitesParcelas,
    calcularValorLimite,
    calcularValoresLimitesPrestacao,
  } = useContext(CalculadoraContext);

  const [tabelaJurosId, setTabelaJurosId] = useState(null);
  const [tabelasJuros, setTabelasJuros] = useState([]);
  const [valores, setValores] = useState([]);
  const [parcelas, setParcelas] = useState([]);

  const [calculoVencimento, setCalculoVencimento] = useState([]);
  const [modalContratoVisible, setModalContratoVisible] = useState(false);


  const [disabledValorSolicitado, setDisabledValorSolicitado] = useState(true);

  const handleClickValorSolicitado = () => {
    if (disabledValorSolicitado) {
      setFieldValue('dataVencimento', null);
      setFieldTouched('dataVencimento', true);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf('day');
  };
  const handleContratos = (contrato) => {
    if (contratoSelecionado?.length === 0) {
      setContratoSelecionado(contrato);
    } else {
      if (contratoSelecionado.contrato === contrato.contrato) {
        setContratoSelecionado([]);
      } else {
        ModalNotification(
          '',
          `Só é possível selecionar um contrato`,
          'warning',
        );
      }
    }
  };

  useEffect(async () => {
    if (modalContratoVisible === false) {
      await setSaldoDevedor(contratoSelecionado?.saldoDevedor || 0);
      setMaiorValorLimite(null);

      if (dataVencimento) {
        await calcularLimitesParcelas(
          renda,
          produto?.id,
          propostaId,
          tabelaJurosId,
          dataVencimento,
          objetoLimitesProduto,
          contratoSelecionado?.valorParcela || 0,
          null,
          produto?.valorRendaComprometida,
        ).then((response) => {
          objetoLimitesParcela = response.objetoLimitesParcela;
          setLimiteParcelas(response.objetoLimitesParcela);
          setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
        });

        await calcularValorLimite(
          produto?.id,
          propostaId,
          convenioId,
          tabelaJurosId,
          dataVencimento,
          produto?.valorContingencia,
          renda,
          objetoLimitesParcela,
          objetoLimitesProduto,
          contratoSelecionado?.saldoDevedor || 0,
        ).then((value) => {
          setMaiorValorLimite(value);
          setDisabledValorSolicitado(false);
        });
      }
    }
  }, [modalContratoVisible]);

  useEffect(() => {
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
    setSubmitButton(false);

    setDisabledValorSolicitado(true);

    setTabelasJuros(produto?.tabelaJuros || []);

    setFieldValue('convenioId', null);
    setSaldoDevedor(0);
    objetoLimitesParcela = null;
    objetoLimitesProduto = null;
    setMaiorValorLimite(null);
    setMaiorValorLimiteParcela(null);
    setFieldValue('produtoId', produto?.id);
  }, [produto]);

  useEffect(() => {
    const tempTabelaJuros = tabelasJuros.find((el) => el.id === tabelaJurosId);
    if (tempTabelaJuros) {
      if (tempTabelaJuros.tipo === 1) {
        calcularValores(tempTabelaJuros, produto?.valorContingencia).then(
          (value) => setValores(value),
        );
      } else {
        setValores([]);
        setParcelas([]);
      }
    }
    setFieldValue('tabelaJurosId', tabelaJurosId);
    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});
  }, [tabelaJurosId]);

  useEffect(() => {
    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});

    setFieldValue('valorSacar', '');
    setFieldValue('floatValorSacar', '');

    setFieldValue('valorParcela', '');
    setFieldValue('floatValorParcela', '');
  }, [valores, parcelas, tipoCalculo]);

  useEffect(() => {
    resetForm();
    setFieldValue('produtoId', produto?.id);
    setFieldValue('tabelaJurosId', tabelaJurosId);
  }, [produto?.id, proposta?.respostaCrivo?.consultaId]);

  useEffect(() => {
    if (tipoCalculo === 1) {
      setFieldValue('valorSacar', '');
      setFieldValue('valorSacar', planoSelecionado.sacar);
    }
  }, [planoSelecionado, parcelas]);

  useEffect(() => {
    if (tabelaJurosId !== null) {
      calcularVencimentos(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
      ).then((value) => setCalculoVencimento(value));
    }
  }, [convenioId, propostaId, produto.id, tabelaJurosId]);

  useEffect(() => {
    if (produto?.tabelaJuros?.length === 1) {
      setTabelaJurosId(produto?.tabelaJuros[0].id);
    }
  }, [produto?.tabelaJuros]);

  useEffect(async () => {
    setSubmitButton(false);
    setSimulacaoProposta(null);
    setPlanoSelecionado({});

    if (dataVencimento && produto?.convenioTipo?.tabelaJurosTipo !== 1) {
      setLoading(true);
      await calcularValoresLimitesPrestacao(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
        dataVencimento,
        produto?.valorContingencia,
        renda,
      )
        .then((value) => {
          objetoLimitesParcela = [];
          objetoLimitesProduto = value;
        })
        .catch(() => setLoading(false));

      await calcularLimitesParcelas(
        renda,
        produto?.id,
        propostaId,
        tabelaJurosId,
        dataVencimento,
        objetoLimitesProduto,
        contratoSelecionado?.valorParcela,
        null,
        produto?.valorRendaComprometida,
      )
        .then((response) => {
          objetoLimitesParcela = response.objetoLimitesParcela;
          setLimiteParcelas(response.objetoLimitesParcela);
          setMaiorValorLimiteParcela(response.maiorValorLimiteParcela);
        })
        .catch(() => setLoading(false));

      await calcularValorLimite(
        produto?.id,
        propostaId,
        convenioId,
        tabelaJurosId,
        dataVencimento,
        produto?.valorContingencia,
        renda,
        objetoLimitesParcela,
        objetoLimitesProduto,
        saldoDevedor,
      )
        .then((value) => {
          setMaiorValorLimite(value);
          setDisabledValorSolicitado(false);
        })
        .catch(() => setLoading(false));
    } else {
      setDisabledValorSolicitado(true);
    }

    setLoading(false);
    setFieldValue('valorSolicitado', '');
    setFieldValue('floatValorSolicitado', '');
    setFieldTouched('valorSolicitado', false);
  }, [dataVencimento, rendaFinal]);

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-row">
            <div className="col-lg-12">
              <Button
                variant="blue"
                className="btn-selecionar-contratos"
                onClick={() => setModalContratoVisible(true)}
              >
                Selecionar contratos
              </Button>
            </div>
          </div>

          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="dataVencimento" label="Data de Vencimento">
                {produto?.tipoCalculoVencimento === 1 && (
                  <DatePicker
                    locale={locale}
                    name="dataVencimento"
                    format={dateFormat}
                    disabledDate={disabledDate}
                  />
                )}
                {produto?.tipoCalculoVencimento === 3 && (
                  <Select
                    name="dataVencimento"
                    className="text-right"
                    onClick={() => {
                      !tabelaJurosId &&
                        ModalNotification(
                          'Atenção',
                          `É necessário selecionar uma tabela de juros antes de selecionar a Data de Vencimento.`,
                          'warning',
                        );
                    }}
                  >
                    {calculoVencimento?.map((item) => (
                      <Option value={item}>
                        {moment(item).format('DD/MM/YYYY')}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>

          {produto?.tipoCalculoVencimento !== 2 && (
            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="diaRecebimento" label="Dia Recebimento">
                  <Select
                    name="diaRecebimento"
                    className="text-right"
                    showSearch
                  >
                    {diaRecebimento?.map((item) => (
                      <Option key={item.id} value={item.id}>
                        <span className="text-right col-12">{item.nome}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}

          {tabelasJuros.length > 1 && (
            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="tabelaJurosId" label="Tabela">
                  <Select
                    id="tabelaJurosId"
                    name="tabelaJurosId"
                    placeholder="Tabela de Juros"
                    value={tabelaJurosId}
                    onChange={(value) => {
                      setTabelaJurosId(value);
                      setFieldValue('dataVencimento', null);
                    }}
                  >
                    {tabelasJuros.map((tabela) => (
                      <Option key={tabela.id} value={tabela.id}>
                        {tabela.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}

          <div className="form-row">
            <div className="col-lg-12">
              <Form.Item name="saldoDevedor" label="Saldo Devedor Total">
                <Field name="saldoDevedor">
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      maxLength={12}
                      value={saldoDevedor}
                      disabled={true}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-row">
            <div className="col-lg-12 mb-2 mt-2">
              <span className="contratos-title">
                Contratos em Refinanciamento{' '}
                {contratoSelecionado?.length !== 0 ? 1 : 0}
              </span>
            </div>
          </div>
          <div className="form-row mt-2">
            <div className="col-lg-12 col-valor-solicitado">
              <Form.Item
                name={tipoCalculo !== 1 ? 'valorSacar' : 'valorParcela'}
                label={<SelectParcela disabled={disabledValorSolicitado} />}
                className={
                  disabledValorSolicitado && 'disabled-valor-solicitado'
                }
                onClick={handleClickValorSolicitado}
              >
                <Field name={tipoCalculo !== 1 ? 'valorSacar' : 'valorParcela'}>
                  {({ field }) => (
                    <InputMoney
                      {...field}
                      className="ant-input"
                      prefix="R$ "
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      maxLength={12}
                      disabled={disabledValorSolicitado}
                      onValueChange={(values) => {
                        setFieldValue(
                          tipoCalculo !== 1
                            ? 'floatValorSacar'
                            : 'floatValorParcela',
                          values.floatValue,
                        );
                        setSubmitButton(false);
                        setSimulacaoProposta(null);
                        setPlanoSelecionado({});
                      }}
                    />
                  )}
                </Field>
              </Form.Item>
              {tipoCalculo === 1
                ? maiorValorLimiteParcela &&
                  objetoLimitesParcela?.length > 0 && (
                    <p className="info-limite-parcela">
                      * Limites Parcelas:&nbsp;
                      <>
                        <br />
                        Até {objetoLimitesParcela[0]?.rangeFinal}X{' '}
                        {currencyFormat(objetoLimitesParcela[0]?.limiteParcela)}
                        <br />
                        {objetoLimitesParcela?.length > 1 && (
                          <>
                            De {objetoLimitesParcela[1]?.rangeInicial}X a{' '}
                            {objetoLimitesParcela[1]?.rangeFinal}X{' '}
                            {currencyFormat(
                              objetoLimitesParcela[1]?.limiteParcela,
                            )}
                            <br />
                          </>
                        )}
                      </>
                    </p>
                  )
                : maiorValorLimite !== null && (
                    <p className="info-limite-parcela">
                      * Limite Liberado {currencyFormat(maiorValorLimite)}
                    </p>
                  )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={modalContratoVisible}
        centered
        footer={null}
        width={1050}
        onCancel={() => setModalContratoVisible(false)}
      >
        <div className="modal-refin">
          <Title label="Contratos Refinanciáveis" />
          <Table
            columns={columns(contratoSelecionado, handleContratos)}
            data={produto?.refin?.contratos || []}
            pageCount={1}
            onLoadData={() => {}}
            total={produto?.refin?.contratos?.length}
          />
          <div className="d-flex justify-content-end">
            <Button
              variant="blue"
              onClick={() => setModalContratoVisible(false)}
            >
              <Icon variant="add" className="mr-2" />
              SELECIONAR
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Refin;

const SelectParcela = ({ onChange, disabled }) => (
  <Select
    name="tipoCalculo"
    size="small"
    onChange={onChange}
    disabled={disabled}
    className="filled"
    style={{ fontWeight: 'bold', width: '150px', backgroundColor: '#cccccc' }}
    onClick={(e) => {
      e.preventDefault();
    }}
  >
    <Option value={0}>Valor liberado</Option>
    <Option value={1}>Valor da parcela</Option>
  </Select>
);
