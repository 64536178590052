import React from 'react';

function PageHeading({ style, children }) {
  return (
    <div className="row wrapper border-bottom white-bg page-heading" style={style}>
      {children}
    </div>
  );
}

export default PageHeading;
