import React, { useContext } from 'react';

import './styles.scss';
import { AnalisePropostaContext } from '../../../contexts/AnalisePropostaContext';
import CardEstatisticas from '../../../components/CardEstatisticas';
import { ReactComponent as VectorIcon } from '../../../assets/images/icons/vector.svg';
import { ReactComponent as GraphIcon } from '../../../assets/images/icons/graph.svg';
import { ReactComponent as DeclineIcon } from '../../../assets/images/icons/decline.svg';
import { ReactComponent as DayIcon } from '../../../assets/images/icons/day.svg';
import {ReactComponent as Negative } from '../../../assets/images/icons/negative-rate.svg'

import StatusOperatividade from '../StatusOperatividade';

function AnaliseIndicadores() {
  const { estatisticas } = useContext(AnalisePropostaContext);

  return (
    <div className="Container">
      <div className="d-flex justify-content-between flex-wrap list-indicadores">
        <div className="indicador indicador-operatividade">
          <StatusOperatividade ultimaParcial="10:32:34" />
        </div>
        <div className="indicador indicador-tempo-medio">
          <CardEstatisticas
            titulo="Tempo médio"
            corFonte="#667788"
            textoConteudo={estatisticas.tempoMedio}
          >
            <VectorIcon />
          </CardEstatisticas>
        </div>
        <div className="indicador indicador-outros">
          <CardEstatisticas
            titulo="Tx. aprovação"
            porcentagem={estatisticas.taxaAprovacao + '%'}
            corFonte="#1AB394"
            textoConteudo={estatisticas.qtdAprovacao}
          >
            <GraphIcon />
          </CardEstatisticas>
        </div>
        <div className="indicador indicador-outros">
          <CardEstatisticas
            titulo="Tx. pendência"
            porcentagem={estatisticas.taxaPendencia + '%'}
            corFonte="#FA8B3C"
            textoConteudo={estatisticas.qtdPendencia}
          >
            <DeclineIcon />
          </CardEstatisticas>
        </div>
        <div className="indicador indicador-outros">
          <CardEstatisticas
            titulo="Qt. propostas"
            corFonte="#263659"
            textoConteudo={estatisticas.qtdPropostas}
          >
            <DayIcon />
          </CardEstatisticas>
        </div>
        <div className="indicador indicador-outros">
          <CardEstatisticas
          titulo="Tx. negativa"
          porcentagem={estatisticas.taxaNegativa + '%'}
          corFonte="#ED5565"
          textoConteudo={estatisticas.qtdNegativa}
          >
                <Negative />
          </CardEstatisticas>
        </div>
      </div>
    </div>
  );
}

export default AnaliseIndicadores;
