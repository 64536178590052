import moment from 'moment';
import { currencyFormat } from '../../../utils/numberFormatter';

export const columnsTooltipContratos = [
  {
    Header: 'Prestação',
    accessor: 'prestacao',
  },
  {
    Header: 'Vencimento',
    accessor: 'vencimento',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Pagamento',
    accessor: 'pagamento',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: 'Valor',
    accessor: 'valorPago',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Dias Atraso/Adiantamento',
    accessor: 'atraso',
  },
];
