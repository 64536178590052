import React, { useEffect, useState } from 'react';

import { Button, Form, Input } from 'antd';

import brand from '../../assets/images/crefazon-logo.svg';
import { ReactComponent as BrandWhite } from '../../assets/images/crefazon-logo-alt.svg';
import { ReactComponent as IconEye } from '../../assets/images/icons/icon-eye-redefinir-senha.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/icon-checking-circle-filled.svg';

import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { validaLink, redefinirSenha } from '../../services/usuariosService';
import swal from 'sweetalert';
import { signIn } from '../../services/authService';
import Spinner from '../../components/Spinner';

function RedefinirSenha() {
  const [name, setName] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [typeSenhaInput, setTypeSenhaInput] = useState('password');
  const [typeConfirmSenhaInput, setTypeConfirmSenhaInput] =
    useState('password');
  const [classDica1, setClassDica1] = useState('');
  const [classDica2, setClassDica2] = useState('');
  const history = useHistory();
  const [loading, setLoading] = useState(false);


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get('id');
  const token = query.get('token');

  useEffect(() => {
    callValidaLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function callValidaLink() {
    validaLink(id, token).then((response) => {
      if (response.status) {
        setName(response.login);
      } else {
        swal(
          '',
          'Este link foi inativado. Solicite um novo link de redefinição de senha para a equipe de cadastro.',
          'warning',
          {
            className: 'discard-modal-hierarquia',
            closeOnClickOutside: false,
          },
        ).then((response) => {
          if (response) {
            history.push('/login');
          }
        });
      }
    });
  }

  // eslint-disable-next-line
  const [statusConfirmSenha, setStatusConfirmSenha] = useState('');
  function validateSenhas(value, type) {
    let campo1 = '';
    let campo2 = '';
    if (type === 'senha') {
      setSenha(value);
      campo1 = value;
      campo2 = confirmSenha;
    } else {
      campo1 = senha;
      campo2 = value;
      setConfirmSenha(value);
    }
    if (campo1.length === 6) {
      setClassDica1('success');
    } else {
      setClassDica1('error');
    }
    if (campo1 === campo2) {
      setClassDica2('success');
    } else {
      setClassDica2('error');
    }
    if (!campo1 && !campo2) {
      setClassDica1('');
      setClassDica2('');
    }
  }
  function submitForm() {
    if (classDica1 === 'success' && classDica2 === 'success') {
      setLoading(true);

      const body = {
        id,
        token,
        senha,
        confirmacaoSenha: confirmSenha,
      };

      redefinirSenha(body).then(async () => {
        const data = await signIn({
          login: name, 
          senha: senha 
        });
        if (data?.data?.token) {
          localStorage.setItem('authToken', data.data.token);
          localStorage.setItem('login', data.data.login);
          localStorage.setItem('userId', data.data.userId);
          localStorage.setItem('nome', data.data.nome);
          localStorage.setItem('telefonia', data.data.telefonia);
          setLoading(false);
          history.push('/');
        } 
        else{
          setLoading(false);
        }
      }).catch(() => setLoading(false))
    }
  }
  return (
    <div className="redefinir-senha-container">
      <div className="row h-100">
        <div className="col-lg-6 redefinir-senha-brand">
          <div>
            <div className="brand">
              <img src={brand} alt="logo-crefazon" width="100%" />
            </div>
            <BrandWhite className="brand-white" />
          </div>
        </div>
        <div className="col-lg-6 redefinir-senha-form">
          <div style={{ width: '360px' }}>
            <div className="redefinir-senha-title">
              <h2>Redefinição de Senha</h2>
              <div className="underline"></div>
            </div>
            <div>
              <Form layout="vertical" autoComplete="off" onFinish={submitForm}>
                <Form.Item label="Usuário" className="redefinir-senha-item">
                  <Input
                    type="text"
                    size="large"
                    className="redefinir-senha-user"
                    value={name}
                    disabled
                    placeholder="Selecione"
                  />
                </Form.Item>
                <Form.Item
                  name="novaSenha"
                  label="Nova senha"
                  className="redefinir-senha-item"
                >
                  <Input
                    type={typeSenhaInput}
                    name="novaSenha"
                    size="large"
                    className="redefinir-senha-input"
                    onChange={(e) => {
                      validateSenhas(e.target.value, 'senha');
                    }}
                  />
                  <div
                    className="eye-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setTypeSenhaInput(
                        // eslint-disable-next-line
                        typeSenhaInput == 'text' ? 'password' : 'text',
                      );
                    }}
                  >
                    <IconEye />
                  </div>
                </Form.Item>
                <Form.Item
                  name="confirmacaoSenha"
                  label="Confirmação de senha"
                  className="redefinir-senha-item"
                  validateStatus={statusConfirmSenha}
                  help={statusConfirmSenha}
                >
                  <Input
                    type={typeConfirmSenhaInput}
                    name="confirmacaoSenha"
                    size="large"
                    className="redefinir-senha-input"
                    onChange={(e) => {
                      validateSenhas(e.target.value, 'confirmSenha');
                    }}
                  />
                  <div
                    onClick={() => {
                      setTypeConfirmSenhaInput(
                        // eslint-disable-next-line
                        typeConfirmSenhaInput == 'text' ? 'password' : 'text',
                      );
                    }}
                    className="eye-icon"
                    style={{ cursor: 'pointer' }}
                  >
                    <IconEye />
                  </div>
                </Form.Item>

                <div className="dicas">
                  <div className={`dica dica1 ${classDica1}`}>
                    <IconCheck />
                    <span>A senha deve conter 6 digitos.</span>
                  </div>
                  <div className={`dica ${classDica2}`}>
                    <IconCheck />
                    <span>
                      A nova senha e a confirmação de senha devem ser iguais.
                    </span>
                  </div>
                </div>

                <Button
                  htmlType="submit"
                  type="primary"
                  className="redefinir-senha-btn"
                  block
                  style={{
                    backgroundColor: '#ff7817',
                    color: '#263659',
                    border: '1px solid #ff7817',
                    borderRadius: 0,
                    height: 'fit-content',
                  }}
                >
                  
                  {!loading ? (
                    'REDEFINIR'
                  ) : (
                    <Spinner color="#26417B" width={4.54} margin={2.27} />
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RedefinirSenha;
