import React, { useState, useContext, useEffect } from 'react';

import { Formik, Field } from 'formik';
import { Form, Input, Select } from 'formik-antd';

import moment from 'moment';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Overlay from '../../../components/Overlay';
import Modal from '../../../components/Modal';

import { BlocklistUnidadeConsumidoraContext } from '../../../contexts/BlocklistUCContext';

import { ReactComponent as IconSalvar } from '../../../assets/images/icons/icon-salvar.svg';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const { Option } = Select;
const { TextArea } = Input;

function FormBlocklist() {
  const { salvarBlocklistUnidadeConsumidora, unidadeConsumidoraBlocklist, setUc, setFilter } =
    useContext(BlocklistUnidadeConsumidoraContext);
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <Overlay active={showOverlay}>
      <Card
        title={
          <Title
            label={`${
              unidadeConsumidoraBlocklist.id > 0 ? 'Edição' : 'Inclusão'
            } de Blocklist Unidade Consumidora`}
          />
        }
      >
        <Formik
          initialValues={unidadeConsumidoraBlocklist}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={async (data, { resetForm }) => {
            setShowOverlay(true);
            const response = await salvarBlocklistUnidadeConsumidora(
              data,
            ).catch(() => {
              setShowOverlay(false);
              resetForm();
            });

            if (response.success) {
              setUc("")
              setFilter((filter) => ({
                ...filter,
                pagina: 1,
                filtroDinamico: '',
              }));
              Modal(
                'Sucesso',
                `Unidade Consumidora ${
                  data.id > 0 ? `excluída da` : `inserida na`
                } Blocklist`,
                'success',
              );
            }

            setShowOverlay(false);
            resetForm();
          }}
        >
          {(formik) => (
            <Form layout="vertical">
              <FormLayout formik={formik} />
            </Form>
          )}
        </Formik>
      </Card>
    </Overlay>
  );
}

function FormLayout({ formik }) {
  const { setFieldValue, touched, values, setErrors, setTouched } = formik;

  const { checarPermissao } = useContext(ControleAcessoContext);

  const [acessoEdicao, setAcessoEdicao] = useState(false);
  const [acessoInclusao, setAcessoInclusao] = useState(false);

  useEffect(() => {
    setAcessoEdicao(
      !!checarPermissao('frame', 'frame.blocklistUnidadeConsumidora.edicao', 'Editar'),
    );
    setAcessoInclusao(
      !checarPermissao('botao', 'botao.blocklistUnidadeConsumidora.inclusao', 'Criar'),
    );
  }, [checarPermissao]);

  const {
    unidadeConsumidoraBlocklist,
    motivos,
    ciaEletrica,
    resetUnidadeConsumidoraBlocklistForm,
  } = useContext(BlocklistUnidadeConsumidoraContext);

  const [statusErrorCIAEletrica, setStatusErrorCIAEletrica] = useState('');
  const [statusErrorMotivo, setStatusErrorMotivo] = useState('');
  const [statusErrorObs, setStatusErrorObs] = useState('');

  const ciaEletricaSelecionada = ciaEletrica.find((cia) =>
    unidadeConsumidoraBlocklist.id ? cia.nome : cia.id === values.ciaEletrica,
  );

  const handleReset = () => {
    resetUnidadeConsumidoraBlocklistForm();
    setFieldValue('unidadeConsumidora', '');
    setFieldValue('ciaEletrica', '');
    setFieldValue('motivoId', '');
    setFieldValue('descricaoInclusao', '');
    setStatusErrorCIAEletrica('');
    setStatusErrorMotivo('');
    setStatusErrorObs('');
    setErrors({});
    setTouched({});
  };

  useEffect(() => {
    if (!unidadeConsumidoraBlocklist.id) {
      setFieldValue('unidadeConsumidora', '');
    }
  }, [values.ciaEletrica, setFieldValue, unidadeConsumidoraBlocklist]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="ciaEletrica"
            validate={(value) => {
              let msgError = null;

              if (value === '' || value === null) {
                msgError = 'Campo obrigatório';
                setStatusErrorCIAEletrica('warning');
              } else {
                setStatusErrorCIAEletrica('');
              }
              unidadeConsumidoraBlocklist.id > 0 && (msgError = '');

              return msgError;
            }}
            validateStatus={touched.ciaEletrica && statusErrorCIAEletrica}
            hasFeedback
            label="Cia Elétrica"
          >
            {unidadeConsumidoraBlocklist.id === 0 ? (
              <Select
                name="ciaEletrica"
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                disabled={acessoInclusao}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {ciaEletrica.length > 0 &&
                  ciaEletrica.map((ciaEletrica) => (
                    <Option key={ciaEletrica.id} value={ciaEletrica.id}>
                      {ciaEletrica.nome}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input name="ciaEletrica" disabled />
            )}
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="unidadeConsumidora"
            hasFeedback
            label={ciaEletricaSelecionada?.nomeChave || 'Unidade Consumidora'}
          >
            <Field
              name="unidadeConsumidora"
              validate={(value) => {
                if (!RegExp(ciaEletricaSelecionada?.formato).test(value)) {
                  return ciaEletricaSelecionada?.mensagem;
                }
              }}
            >
              {({ field }) =>
                ciaEletricaSelecionada ? (
                  <Input
                    {...field}
                    className="ant-input"
                    defaultValue={ciaEletricaSelecionada?.valor}
                    disabled={
                      unidadeConsumidoraBlocklist?.id > 0
                        ? true
                        : acessoInclusao
                    }
                  />
                ) : (
                  <Input name="unidadeConsumidora" disabled />
                )
              }
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            name="motivoId"
            validate={(value) => {
              let msgError = null;

              if (value === '' || value === null) {
                msgError = 'Campo obrigatório';
                setStatusErrorMotivo('warning');
              } else {
                setStatusErrorMotivo('');
              }
              unidadeConsumidoraBlocklist.id > 0 && (msgError = '');

              return msgError;
            }}
            validateStatus={touched.motivoId && statusErrorMotivo}
            hasFeedback
            label="Motivo"
          >
            {unidadeConsumidoraBlocklist.id === 0 ? (
              <Select
                name="motivoId"
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                disabled={acessoInclusao}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {motivos.length > 0 &&
                  motivos.map((motivo) => (
                    <Option key={motivo.id} value={motivo.id}>
                      {motivo.nome}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Input name="motivo" disabled />
            )}
          </Form.Item>
        </div>
        <div className="col-lg-12">
          <Form.Item
            name="descricaoInclusao"
            label={`Observação ${
              unidadeConsumidoraBlocklist.id > 0 ? 'da Inclusão' : ''
            }`}
            validate={(value) => {
              let msgError = null;

              if (!value && unidadeConsumidoraBlocklist.id === 0) {
                msgError = 'Campo obrigatório';
                setStatusErrorObs('warning');
              } else {
                setStatusErrorObs('');
                msgError = '';
              }
              return msgError;
            }}
            validateStatus={
              unidadeConsumidoraBlocklist.id === 0 &&
              touched.descricaoInclusao &&
              statusErrorObs
            }
            hasFeedback
          >
            <Field name="descricaoInclusao">
              {({ field }) => (
                <TextArea
                  name="descricaoInclusao"
                  placeholder={
                    unidadeConsumidoraBlocklist.id > 0
                      ? ''
                      : 'Informe uma observação'
                  }
                  disabled={
                    unidadeConsumidoraBlocklist.id > 0 ? true : acessoInclusao
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="dataInclusao"
            label={`Data ${
              unidadeConsumidoraBlocklist.id > 0 ? 'da Inclusão' : ''
            }`}
          >
            <Input
              name="dataInclusao"
              value={
                unidadeConsumidoraBlocklist.inclusao === ''
                  ? moment().format('DD/MM/YYYY')
                  : moment(unidadeConsumidoraBlocklist.inclusao).format(
                      'DD/MM/YYYY',
                    )
              }
              disabled
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="usuarioInclusao"
            label={`Responsável ${
              unidadeConsumidoraBlocklist.id > 0 ? 'da Inclusão' : ''
            }`}
          >
            <Input
              name="usuarioInclusao"
              value={
                unidadeConsumidoraBlocklist.id > 0
                  ? unidadeConsumidoraBlocklist.usuarioInclusao
                  : localStorage.getItem('login')
              }
              disabled
            />
          </Form.Item>
        </div>
        {unidadeConsumidoraBlocklist.id > 0 && (
          <>
            <div className="col-lg-12">
              <Form.Item
                name="descricaoExclusao"
                label={`Observação ${
                  unidadeConsumidoraBlocklist.id > 0 ? 'da Exclusão' : ''
                }`}
                validate={(value) => {
                  let msgError = null;
                  if (!value) {
                    msgError = 'Campo obrigatório';
                    setStatusErrorObs('warning');
                  } else {
                    setStatusErrorObs('');
                  }
                  return msgError;
                }}
                validateStatus={touched.descricaoExclusao && statusErrorObs}
                hasFeedback
              >
                <Field name="descricaoExclusao">
                  {({ field }) => (
                    <TextArea
                      name="descricaoExclusao"
                      placeholder={
                        unidadeConsumidoraBlocklist.id > 0 &&
                        unidadeConsumidoraBlocklist.exclusao !== null
                          ? ''
                          : 'Informe uma observação'
                      }
                      disabled={
                        acessoEdicao
                          ? unidadeConsumidoraBlocklist.id > 0 &&
                            unidadeConsumidoraBlocklist.exclusao !== null
                          : true
                      }
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="exclusao"
                label={`Data ${
                  unidadeConsumidoraBlocklist.id > 0 ? 'da Exclusão' : ''
                }`}
              >
                <Input
                  name="exclusao"
                  value={
                    unidadeConsumidoraBlocklist.exclusao === null
                      ? moment().format('DD/MM/YYYY')
                      : moment(unidadeConsumidoraBlocklist.exclusao).format(
                          'DD/MM/YYYY',
                        )
                  }
                  disabled
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                name="usuarioExclusao"
                label={`Responsável ${
                  unidadeConsumidoraBlocklist.id > 0 ? 'da Exclusão' : ''
                }`}
              >
                <Input
                  name="usuarioExclusao"
                  value={
                    unidadeConsumidoraBlocklist.id > 0
                      ? unidadeConsumidoraBlocklist.usuarioInclusao
                      : localStorage.getItem('login')
                  }
                  disabled
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
      <div className="d-flex justify-content-end botoes-acao">
        <Button
          className="btn-cancelar"
          type="button"
          variant="gray"
          onClick={handleReset}
        >
          <span>Cancelar</span>
        </Button>
        {!acessoInclusao && unidadeConsumidoraBlocklist.id === 0 && (
          <Button className="btn-salvar" type="submit" variant="green">
            <IconSalvar />
            <span>Salvar</span>
          </Button>
        )}
        {acessoEdicao &&
          unidadeConsumidoraBlocklist.id > 0 &&
          unidadeConsumidoraBlocklist.exclusao === null && (
            <Button className="btn-salvar" type="submit" variant="green">
              <IconSalvar />
              <span>Excluir</span>
            </Button>
          )}
      </div>
    </>
  );
}

export default FormBlocklist;
