import moment from 'moment';

import { Switch, Tooltip } from 'antd';
import Icon from '../../../../components/Icon';
import { genericPhoneFormatter } from '../../../../utils/textFormatter';

import "./styles.scss";

export const columnsHistorico = (
  contexto,
  handleConfirmacao,
  listaAnalistas,
  proposta,
  editableField,
  historicoChamada
) => [
    {
      title: 'Data',
      dataIndex: 'dataHora',
      key: 'data',
      render: (value) => (value ? moment(value).format('DD/MM/YYYY') : '--'),
    },
    {
      title: 'Início',
      dataIndex: 'chamadaDataHoraInicio',
      key: 'inicio',
      render: (value) => {
        return (
          <div
            className="Container"
            style={{
              background: '#f7f7f7',
              width: 'auto',
              minWidth: '60px',
              display: 'inline-block',
              fontSize: '10px',
              textAlign: 'left',
              borderRadius: 2.5,
              border: '1px solid #667788',
              color: '#646B70',
            }}
          >
            <Icon variant="clock" className="icon-table-text" />
            {value ? moment(value).format('HH:mm') : '--'}
          </div>
        );
      },
    },
    {
      title: 'Duração',
      dataIndex: 'duracao',
      key: 'duracao',
      render: (value) => {
        return (
          <div
            className="Container"
            style={{
              background: '#f7f7f7',
              width: 'auto',
              minWidth: '60px',
              display: 'inline-block',
              fontSize: '10px',
              textAlign: 'left',
              borderRadius: 2.5,
              border: '1px solid #667788',
              color: '#646B70',
            }}
          >
            <Icon variant="clock" className="icon-table-text" />
            {value}
          </div>
        );
      },
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      key: 'telefone',
      textAlign: 'center',
      render: (value) => value && genericPhoneFormatter(value),
    },
    {
      title: 'PA',
      dataIndex: 'ramal',
      key: 'ramal',
    },
    {
      title: 'Analista',
      dataIndex: 'usuarioNome',
      key: 'usuarioNome',
      minWidth: 120,
      width: 120,
      render: (value) =>
        value
    },
    {
      title: 'Situação',
      dataIndex: 'id',
      key: 'id',
      render: (value) => {
        const historico = historicoChamada?.find((historico) => historico.id === value)

        return <Tooltip title={historico?.motivo}>
          <div className='tooltip-chamada'>
            <div style={{ background: historico?.situacaoColor }} >
              {historico?.situacao}
            </div>
          </div>
        </Tooltip>
      },
    },
    {
      title: 'Confirmação',
      dataIndex: 'confirmacao',
      key: 'confirmacao',
      render: (value, record) => {
        return (
          <Switch
            checked={!!value}
            size="small"
            name="confirmacao"
            className="switch-red-if-off"
            onChange={() => handleConfirmacao(record)}
            disabled={
              !contexto?.bloqueiaAcessoSimultaneo
                ? !editableField('historicoChamada.confirmacao')
                : true
            }
          />
        );
      },
    },
  ];
