import './styles.scss';

const ModalBordero = ({ id = 'modal', onClose = () => {}, content }) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  return (
    <div id={id} className="modal" onClick={handleOutsideClick}>
      <div className="container">
        <button className="close" onClick={onClose} />
        <div className="modal-body">{content}</div>
      </div>
    </div>
  );
};

export default ModalBordero;
