import React, { createContext, useEffect, useState } from 'react';
import { getContextoControleProposta, enviarControlePropostasCSV } from '../services/relatorioControlePropostaService';
import { useFormik } from 'formik';
import moment from 'moment';
import { validationForm } from '../pages/RelatorioControleProposta/ControleProposta/validationForm';

const initialFilters = {
  tipoData: 1,
  dataFinal: moment(),
  dataInicial: moment().add(-1, 'days'),
  rangeData: [moment().add(-1, 'days'), moment()],
  proposta: null,
  cpf: '',
  situacao: null,
  agente: null,
  matrizes: null,
  tipoErroIntegracao: undefined,
  produtos: null,
  tipoCliente: null,
};

const initialContext = {
  tipoData: [],
  tipoCliente: [],
  tipoErro: [],
  situacao: [],
  matrizes: [],
  produtos: [],
  proposta: [],
};

export const RelatorioControlePropostaContext = createContext({
  filters: initialFilters,
  contexto: initialContext,
  fetchContextoControleProposta: async (f) => f,
});

function RelatorioControlePropostaProvider({ children }) {
  const [contexto, setContexto] = useState([{}]);
  const [filters, setFilters] = useState(initialFilters);

  const formik = useFormik({
    initialValues: initialFilters,
    onSubmit: () => {},
    validationSchema: validationForm,
    validateOnChange: true,
  });

  const fetchContextoControleProposta = async () => {
    const response = await getContextoControleProposta();
    if (response.data) {
      setContexto(response.data);
    }
  };


  useEffect(() => {
    fetchContextoControleProposta();
  }, []);

  const enviarCsvRelatorioControlePropostas = async (payload) => {
    const response = await enviarControlePropostasCSV(payload)
    return response;
  }

  return (
    <RelatorioControlePropostaContext.Provider
      value={{
        formik,
        filters,
        contexto,
        initialFilters,
        setFilters,
        fetchContextoControleProposta,
        enviarCsvRelatorioControlePropostas,
      }}
    >
      {children}
    </RelatorioControlePropostaContext.Provider>
  );
}

export default RelatorioControlePropostaProvider;
