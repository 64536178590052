import React, { createContext, useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { crivo } from '../services/crivo';

import Modal from '../components/Modal';

import {
  atualizarSelecaoOferta,
  getVariaveisCrivo,
  getOfertasProdutos,
  consultarValoresEnergia
} from '../services/propostaService';

export const OfertasProdutosContext = createContext({});

function OfertasProdutosProvider({ children }) {
  const params = useParams();
  const propostaId = params?.propostaId;

  const [planoSelecionado, setPlanoSelecionado] = useState({});
  const [proposta, setProposta] = useState({});
  const [simulacaoProposta, setSimulacaoProposta] = useState(null);
  const [maiorValorLimiteParcela, setMaiorValorLimiteParcela] = useState(null);
  const [maiorValorLimite, setMaiorValorLimite] = useState(null);
  const [menorQtndParcelas, setMenorQtndParcelas] = useState(null);
  const [maiorQtndParcelas, setMaiorQtndParcelas] = useState(null);
  const [renda, setRenda] = useState(0);
  const [rendaFinal, setRendaFinal] = useState(0);
  const [touchedRenda, setTouchedRenda] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [tipoRenda, setTipoRenda] = useState(0);
  const [produto, setProduto] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [contratoSelecionado, setContratoSelecionado] = useState([]);
  const [saldoDevedor, setSaldoDevedor] = useState(0);
  const [rangeParcelas, setRangeParcelas] = useState([]);
  const [diaRecebimento, setDiaRecebimento] = useState([]);
  const [menorValorLimite, setMenorValorLimite] = useState();
  const [menorValorLimiteParcela, setMenorValorLimiteParcela] = useState();
  const [valorAjusteParcela, setValorAjusteParcela] = useState();

  const history = useHistory();

  const carregarProdutos = useCallback(async (propostaId, atualizaRenda = true) => {
    setLoadingList(true);
    const data = await getOfertasProdutos(propostaId);

    if (data?.bloqueio) {
      Modal('', data?.bloqueio, 'warning', redirecionaAcompanhamentoProposta);
    } else if (data?.produtos?.length === 1 && data?.produtos[0].mensagem) {
      Modal(
        '',
        data?.produtos[0].mensagem,
        'warning',
        redirecionaAcompanhamentoProposta,
      );
    } else {
      setProdutos(data?.produtos);
      setProposta(data?.proposta);
      if(atualizaRenda) {
        setRenda(data?.proposta?.valorRendaPresumida);
      }
    }
    setLoadingList(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirecionaAcompanhamentoProposta = () => {
    history.push('/acompanhamento-proposta');
  };

  const avancarProposta = async (proposta) => {
    const data = await atualizarSelecaoOferta(proposta);
    if (data?.data?.bloqueio) {
      Modal('', data?.data?.bloqueio, 'warning', redirecionaAcompanhamentoProposta)
      return;
    }
    return data;
  };

  const simulacaoCrivo = async (proposta) => {
    const data = await getVariaveisCrivo(proposta);
    return data;
  };

  const consultarValoresCrivo = async (propostaId, payload) => {
    const data = await consultarValoresEnergia(propostaId,payload);

    if (data?.bloqueio) {
      Modal('', data?.bloqueio, 'warning', redirecionaAcompanhamentoProposta)
    }
    return data;
  };

  useEffect(() => {
    if (propostaId) {
      carregarProdutos(propostaId);
    }
  }, [carregarProdutos, propostaId]);

  return (
    <OfertasProdutosContext.Provider
      value={{
        proposta,
        produto,
        setProduto,
        simulacaoProposta,
        setSimulacaoProposta,
        avancarProposta,
        renda,
        setRenda,
        touchedRenda,
        setTouchedRenda,
        simulacaoCrivo,
        crivo,
        produtos,
        loading,
        loadingList,
        setLoading,
        tipoRenda,
        setTipoRenda,
        propostaId,
        maiorValorLimiteParcela,
        setMaiorValorLimiteParcela,
        maiorValorLimite,
        setMaiorValorLimite,
        menorQtndParcelas,
        setMenorQtndParcelas,
        maiorQtndParcelas,
        setMaiorQtndParcelas,
        rendaFinal,
        setRendaFinal,
        contratoSelecionado,
        setContratoSelecionado,
        saldoDevedor,
        setSaldoDevedor,
        rangeParcelas,
        setRangeParcelas,
        menorValorLimiteParcela,
        setMenorValorLimiteParcela,
        planoSelecionado,
        setPlanoSelecionado,
        diaRecebimento,
        setDiaRecebimento,
        menorValorLimite,
        setMenorValorLimite,
        consultarValoresCrivo,
        valorAjusteParcela,
        setValorAjusteParcela,
        carregarProdutos,
        redirecionaAcompanhamentoProposta        
      }}
    >
      {children}
    </OfertasProdutosContext.Provider>
  );
}

export default OfertasProdutosProvider;
