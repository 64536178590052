import React from 'react';
import Title from '../../../components/Title';

import '../styles.scss';

import CardToolbarDetails from './CardsToolbarDetails';
import TableDetails from './TableDetails';

function BorderoDetails() {
  return (
    <div className="row">
      <Title label="Dados Detalhados do Borderô" />

      <div className="row details">
        <div className="col-lg-12">
          <CardToolbarDetails />
        </div>
      </div>

      <div className="row details">
        <div className="col-lg-12">
          <Title label="Tabela de Propostas" />
          <TableDetails />
        </div>
      </div>
    </div>
  );
}

export default BorderoDetails;
