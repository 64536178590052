import React, { useContext, useState } from 'react';
import moment from 'moment';

import { Form, Input, DatePicker, Select } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Tooltip from '../../components/tooltip';

import { AcompanhamentoPropostaContext } from '../../contexts/AcompanhamentoPropostaContext';

import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { ReactComponent as EraseIcon } from '../../assets/images/icons/erase.svg';
import { ReactComponent as IconCalendar } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as BulletInfo } from '../../assets/images/icons/bullet-info-tooltip.svg';

import './styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];

const initialFilters = {
  filtroDinamico: null,
  filtroEtapa: null,
  filtroProduto: null,
  filtroTiposData: null,
  filtroDataFinal: moment(),
  filtroDataInicial: moment().add(-1, 'day'),
  filtroData: [moment().add(-1, 'day'), moment()],
};

function Filter({ formik }) {
  const { values, setFieldValue } = formik;

  const { contexto, tiposData, setSearch } = useContext(
    AcompanhamentoPropostaContext,
  );

  const [dates, setDates] = useState([]);
  const [value, setValue] = useState([moment().add(-1, 'days'), moment()]);
  const [hackValue, setHackValue] = useState();

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 15;
    return tooLate;
  };

  const onOpenChange = (open) => {
    if (!open) {
      setHackValue(undefined);
    }
  };

  function TooltipInfo({ text }) {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-info"
        overlayClassName="tooltip-proposta-credito"
        color="#435361"
        title={text}
      >
        <BulletInfo className="bullet-info-icon" />
      </Tooltip>
    );
  }

  const handleEnvioFormulario = () => {
    if (
      !(
        values?.filtroDinamico !== '' &&
        /^[0-9]*$/.test(
          values?.filtroDinamico?.replaceAll('.', '')?.replaceAll('-', ''),
        )
      ) &&
      values.filtroData &&
      values?.filtroData[1]?.diff(values?.filtroData[0], 'days') > 15
    ) {
      Modal('', 'Prazo máximo para consulta: 15 dias', 'warning');
    } else {
      const dataInicial = values.filtroData
        ? moment(values.filtroData[0]).format('YYYY-MM-DD')
        : null;
      const dataFinal = values.filtroData
        ? moment(values.filtroData[1]).format('YYYY-MM-DD')
        : null;

      setSearch({
        pagina: 1,
        filtroEtapa: values.filtroEtapa,
        filtroProduto: values.filtroProduto,
        filtroTipoData: values.filtroTipoData,
        filtroDataInicial: dataInicial,
        filtroDataFinal: dataFinal,
        filtroDinamico: values.filtroDinamico,
        filtroData: [dataInicial, dataFinal],
      });
    }
  };

  return (
    <div className="filter-container filter-acompanhamento-proposta">
      <Form layout="vertical">
        <div className="row d-flex align-items-center">
          <div className="col-lg-10 col-md-10">
            <div className="form-row">
              <div className="col-lg-3">
                <Form.Item name="filtroEtapa" label="Status">
                  <Select
                    name="filtroEtapa"
                    placeholder="Status"
                    mode="multiple"
                    allowClear
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(
                          input
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                        );
                    }}
                  >
                    {contexto?.situacao?.map((situacao) => (
                      <Option key={situacao.id} value={situacao.id}>
                        {situacao.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="filtroProduto" label="Produto">
                  <Select
                    name="filtroProduto"
                    placeholder="Produto"
                    mode="multiple"
                    allowClear
                    showSearch
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(
                          input
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, ''),
                        );
                    }}
                  >
                    {contexto?.produtos?.map((produto) => (
                      <Option key={produto.id} value={produto.id}>
                        {produto.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="filtroTipoData" label="Data">
                  <Select
                    name="filtroTipoData"
                    placeholder="Tipo de Data"
                    suffixIcon={<IconArrowSelect />}
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {tiposData &&
                      tiposData?.map((tipoData) => (
                        <Option key={tipoData.id} value={tipoData.id}>
                          {tipoData.nome}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>

              <div className="col-lg-3">
                <Form.Item name="filtroData" label="Filtrar por coluna">
                  <RangePicker
                    name="filtroData"
                    value={hackValue || value}
                    disabledDate={
                      values?.filtroDinamico !== '' &&
                      /^[0-9]*$/.test(
                        values?.filtroDinamico
                          ?.replaceAll('.', '')
                          ?.replaceAll('-', ''),
                      )
                        ? null
                        : disabledDate
                    }
                    onCalendarChange={(val) => setDates(val)}
                    onChange={(val) => {
                      setValue(val);
                      if (val) {
                        setFieldValue('filtroDataInicial', val[0]);
                        setFieldValue('filtroDataFinal', val[1]);
                      } else {
                        setFieldValue('filtroDataInicial', null);
                        setFieldValue('filtroDataFinal', null);
                      }
                    }}
                    onOpenChange={onOpenChange}
                    format={dateFormatList}
                    defaultPickerValue={[moment().add(-1, 'month'), moment()]}
                    suffixIcon={<IconCalendar />}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="form-row">
              <div className="col-lg-12">
                <Form.Item name="filtroDinamico" className="field-control">
                  <label style={{ margin: 0, marginRight: 10 }}>
                    Pesquisa dinâmica
                  </label>
                  <TooltipInfo text="Se o CPF ou o Código da proposta forem informados não haverá restrição no filtro de data." />
                  <Input
                    name="filtroDinamico"
                    suffix={<SearchOutlined />}
                    placeholder="Pesquisar por CPF, Código da proposta ou Nome do cliente."
                    onChange={(val) => {
                      if (
                        val.target.value !== '' &&
                        /^[0-9]*$/.test(
                          val.target.value
                            ?.replaceAll('.', '')
                            ?.replaceAll('-', ''),
                        )
                      ) {
                        setValue(undefined);
                        setDates(undefined);
                        setFieldValue('filtroData', undefined);
                        setFieldValue('filtroDataInicial', null);
                        setFieldValue('filtroDataFinal', null);
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-2">
            <div className="d-flex flex-column justify-content-end">
              <Button
                type="button"
                async={true}
                onClick={() => {
                  handleEnvioFormulario();
                }}
                variant="blue"
              >
                <SearchIcon className="search-icon" />
                <span className="d-md-none d-lg-inline ml-2">Pesquisar</span>
              </Button>

              <span className="p-1"></span>

              <Button
                type="button"
                variant="gray"
                async={true}
                onClick={() => {
                  setSearch(initialFilters);
                  setValue([moment().add(-1, 'days'), moment()]);
                  setDates([moment().add(-1, 'days'), moment()]);
                  setFieldValue('filtroData', [
                    moment().add(-1, 'days'),
                    moment(),
                  ]);
                  setFieldValue('filtroTipoData', null);
                  setFieldValue('filtroDinamico', null);
                  setFieldValue('filtroEtapa', null);
                  setFieldValue('filtroProduto', null);
                  setFieldValue('filtroTiposData', null);
                }}
              >
                <EraseIcon className="erase-icon" />
                <span className="d-md-none d-lg-inline ml-2">Limpar</span>
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Filter;
