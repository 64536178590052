import React, { useContext, useEffect, useState } from 'react';

import { Form, Select, Input } from 'formik-antd';
import { Switch, Modal, Button } from 'antd';
import DatePicker from '../../../components/DatePicker';
import './styles.scss';
import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';
import InputMask from 'react-text-mask';
import { cnpjMask } from '../../../utils/masks';
import Option from '../../../components/Option';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';
import removeChars from '../../../utils/removeChars';
import ModalConfirmaInativacao from '../Modals/ModalConfirmaInativacao';
import ModalAlertaInativacao from '../Modals/ModalAlertaInativacao';
import { SubTipoConvenio, TipoCredenciado } from '../../../utils/identificadoresFrontBack';
import { isBoolean } from 'lodash';

function DadosGeraisForm({ formik, dadosGeraisContext, isReadOnly }) {
  const {
    isCnpjValid,
    initialValues,
    userId,
    setFileListDocumentos,
    setUnidades,
    setUnidadesFiltradas,
    setCpfCnpjUnidadesCadastradas,
    handlePesquisar,
    populaRegioes,
    inativaSubunidades,
  } = useContext(CredenciadoContext);

  const [isCnpjModalVisible, setCnpjModalVisible] = useState(false);
  const [infosValidateTypeChange, setInfosValidateTypeChange] = useState({
    field: null,
    value: null,
    previousValue: null,
  });
  const [cnpjValidatorWatch, setCnpjValidatorWatch] = useState(false);
  const [
    visibleModalConfirmarMudancaTipo,
    setVisibleModalConfirmarMudancaTipo,
  ] = useState();
  const [tipoCredenciadoField, setTipoCredenciadoField] = useState(null);
  const [subTipoCredenciadoField, setSubTipoCredenciadoField] = useState(null);
  const {
    setFieldValue,
    values,
    getFieldMeta,
    getFieldHelpers,
    setValues,
    setErrors,
    setTouched,
  } = formik;

  const [showModalConfirmaInativacao, setShowModalConfirmaInativacao] =
    useState(false);
  const [showModalAlertaInativacao, setShowModalAlertaInativacao] =
    useState(false);

  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);

    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }

  function deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  function resetFormFields(field, value) {
    const newValues = deepClone(initialValues);
    if (field === 'type') {
      newValues.dadosGerais.tipoCredenciado = value;
      setSubTipoCredenciadoField(null);
      setValues(newValues);
      setErrors({});
      setTouched({
        dadosGerais: {
          tipoCredenciado: true,
        },
      });
    } else if (field === 'subtype') {
      const type = formik?.values?.dadosGerais?.tipoCredenciado;
      const subtype = value;
      newValues.dadosGerais.tipoCredenciado = type;
      newValues.dadosGerais.subTipoCredenciado = subtype;
      setValues(newValues);
      setErrors({});
      setTouched({
        dadosGerais: {
          tipoCredenciado: true,
          subTipoCredenciado: true,
        },
      });
    }
    setFileListDocumentos([]);
    setUnidades([]);
    setUnidadesFiltradas([]);
    setCpfCnpjUnidadesCadastradas([]);
  }

  function handleTypeSubtypeChange({ field, value }) {
    const previousValue = {
      tipoCredenciado: values?.dadosGerais?.tipoCredenciado,
      subTipoCredenciado: values?.dadosGerais?.subTipoCredenciado,
    };
    if (
      ['type', 'subtype'].includes(field) &&
      (field === 'type'
        ? typeof previousValue?.tipoCredenciado === 'number'
        : typeof previousValue?.subTipoCredenciado === 'number')
    ) {
      setVisibleModalConfirmarMudancaTipo(true);
    } else {
      if (field === 'type') {
        setTipoCredenciadoField(value);
        setFieldValue('dadosGerais.tipoCredenciado', value);
      } else {
        setSubTipoCredenciadoField(value);
        setFieldValue('dadosGerais.subTipoCredenciado', value);
      }
      if ((field === 'subtype' && value === SubTipoConvenio.CONSIGNADO_PRIVADO_ID) && tipoCredenciadoField === TipoCredenciado.CONVENIO_ID) {
        populaRegioes();
      }
    }
    setInfosValidateTypeChange({
      field,
      value,
      previousValue,
    });
  }

  useEffect(() => {
    if (cnpjValidatorWatch) {
      if (
        !formik?.errors?.dadosGerais?.cpfCnpj &&
        !formik?.errors?.dadosGerais?.tipoCredenciado &&
        !formik?.errors?.dadosGerais?.subTipoCredenciado &&
        formik?.values?.dadosGerais?.cpfCnpj &&
        !isNaN(formik?.values?.dadosGerais?.tipoCredenciado) &&
        !isNaN(formik?.values?.dadosGerais?.subTipoCredenciado)
      ) {
        let { cpfCnpj, tipoCredenciado, subTipoCredenciado } =
          formik.values.dadosGerais;
        let cnpjFormatado = removeChars(['/', '.', '-'], cpfCnpj);
        if (!isCnpjValid(cnpjFormatado, tipoCredenciado, subTipoCredenciado)) {
          setCnpjModalVisible(true);
        }
      }
    }
    setCnpjValidatorWatch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjValidatorWatch]);

  useEffect(() => {
    if (tipoCredenciadoField == null)
      setTipoCredenciadoField(values?.dadosGerais?.tipoCredenciado);

    if (subTipoCredenciadoField == null)
      setSubTipoCredenciadoField(values?.dadosGerais?.subTipoCredenciado);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dadosGerais?.tipoCredenciado,
    values?.dadosGerais?.tipoCredenciado,
  ]);

  function validationClassificacao(value) {
    let error;
    if (!value?.length && values?.dadosGerais?.tipoCredenciado === 0) {
      error = 'Campo Obrigatório';
      formik.setFieldError(
        'dadosGerais.classificacaoCredenciado',
        'Campo Obrigatório  ',
      );
    }
    return error;
  }

  return (
    <>
      <ModalConfirmaInativacao
        show={showModalConfirmaInativacao}
        setShow={setShowModalConfirmaInativacao}
        onConfirm={() => {
          setFieldValue('dadosGerais.ativo', false);
        }}
      />
      <ModalAlertaInativacao
        show={showModalAlertaInativacao}
        setShow={setShowModalAlertaInativacao}
        onConfirm={() => {
          setUnidades((unidades) => inativaSubunidades(unidades));
          setFieldValue('dadosGerais.ativo', false);
        }}
      />
      <Modal
        visible={visibleModalConfirmarMudancaTipo}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
        onCancel={() => {
          setVisibleModalConfirmarMudancaTipo(false);
        }}
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          Ao alterar o tipo/subtipo de credenciado, todas as alterações serão
          descartadas. Deseja descartar alterações?
        </div>
        <div className="btn-area">
          <Button
            variant="gray"
            onClick={() => {
              const { previousValue } = infosValidateTypeChange;
              setFieldValue(
                'dadosGerais.tipoCredenciado',
                previousValue.tipoCredenciado,
              );
              setFieldValue(
                'dadosGerais.subTipoCredenciado',
                previousValue.subTipoCredenciado,
              );
              setVisibleModalConfirmarMudancaTipo(false);
            }}
            className="btn-cancel"
          >
            NÃO
          </Button>
          <Button
            variant="blue"
            onClick={() => {
              const { field, value } = infosValidateTypeChange;
              resetFormFields(field, value);
              if (field === 'type') {
                setTipoCredenciadoField(value);
                setFieldValue('dadosGerais.tipoCredenciado', value);
              } else if (field === 'subtype') {
                setSubTipoCredenciadoField(value);
                setFieldValue('dadosGerais.subTipoCredenciado', value);
              }

              if (field === 'subtype' && value === SubTipoConvenio.CONSIGNADO_PRIVADO_ID) {
                populaRegioes();
              }

              setVisibleModalConfirmarMudancaTipo(false);
            }}
            className="btn-confirm"
          >
            SIM
          </Button>
        </div>
      </Modal>
      <Modal
        visible={isCnpjModalVisible}
        centered
        footer={null}
        width={452}
        maskClosable={false}
        className="modal-warning"
      >
        <div className="icon">
          <IconExclamationCircle width={75} />
        </div>
        <div className="text-modal">
          CNPJ já cadastrado para o mesmo tipo e subtipo de credenciamento.
          Verifique e tente novamente.
        </div>
        <div className="btn-area">
          <Button
            variant="blue"
            onClick={() => {
              setCnpjModalVisible(false);
              setFieldValue('dadosGerais.cnpj', '');
            }}
            className="btn-confirm"
          >
            OK
          </Button>
        </div>
      </Modal>

      <div className="col-tab-dados-gerais">
        <div className="form-row">
          <div className="col-lg-12 col-switchs">
            <Form.Item name="dadosGerais.ativo">
              <label>
                <Switch
                  size="small"
                  className="switch-red-if-off"
                  checked={values?.dadosGerais?.ativo}
                  onChange={async () => {
                    const possuiUnidadesAtivas = await handlePesquisar(
                      {
                        filtroAtivo: true,
                        filtroCPFCNPJ: null,
                        filtroNome: null,
                      },
                      'btn',
                    );

                    if (possuiUnidadesAtivas) {
                      setShowModalAlertaInativacao(true);
                    } else {
                      setShowModalConfirmaInativacao(true);
                    }
                  }}
                  disabled={isReadOnly || !values?.dadosGerais?.ativo} // uma vez inativa, não poderá ser ativada novamente de acordo com a ET
                />
                Ativo
              </label>
            </Form.Item>
            {values?.dadosGerais?.tipoCredenciado === 0 && (
              <>
                <Form.Item name="dadosGerais.unicard">
                  <label>
                    <Switch
                      size="small"
                      checked={values?.dadosGerais?.unicard}
                      onChange={(value) =>
                        setFieldValue('dadosGerais.unicard', value)
                      }
                      className="switch-red-if-off"
                      disabled={isReadOnly}
                    />
                    Unicad
                  </label>
                </Form.Item>
                <Form.Item name="dadosGerais.analisePromotora">
                  <label>
                    <Switch
                      size="small"
                      className="switch-red-if-off"
                      checked={values?.dadosGerais?.analisePromotora}
                      onChange={(value) =>
                        setFieldValue('dadosGerais.analisePromotora', value)
                      }
                      disabled={isReadOnly}
                    />
                    Análise Promotora
                  </label>
                </Form.Item>
              </>
            )}
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item label="Tipo" name="dadosGerais.tipoCredenciado">
              <Select
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                value={tipoCredenciadoField}
                onChange={(value) => {
                  handleTypeSubtypeChange({
                    field: 'type',
                    value,
                  });
                  setFieldValue('visita', {
                    visita: false,
                    contato: '',
                    dataHora: null,
                    dataVisita: null,
                    horaVisita: null,
                    anexos: [],
                  });
                }}
                onBlur={() => {
                  setCnpjValidatorWatch(true);
                  genericTouched('dadosGerais.tipoCredenciado');
                }}
                disabled={userId || isReadOnly}
              >
                {dadosGeraisContext?.tipoCredenciado?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item label="SubTipo" name="dadosGerais.subTipoCredenciado">
              <Select
                placeholder="Selecione"
                suffixIcon={<IconArrowSelect />}
                allowClear
                getPopupContainer={(trigger) => trigger.parentNode}
                value={subTipoCredenciadoField}
                onChange={(value) => {
                  handleTypeSubtypeChange({
                    field: 'subtype',
                    value,
                  });
                }}
                onBlur={() => {
                  setCnpjValidatorWatch(true);
                  genericTouched('dadosGerais.subTipoCredenciado');
                }}
                disabled={userId || isReadOnly}
              >
                {dadosGeraisContext?.subTipoCredenciado
                  ?.filter((item) => {
                    return (
                      item.tipoCredenciado ===
                      values?.dadosGerais?.tipoCredenciado
                    );
                  })
                  .map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-6 item-dados input-classificacao-credenciado">
            {values?.dadosGerais?.tipoCredenciado === 0 && (
              <Form.Item
                label="Classificação"
                name="dadosGerais.classificacaoCredenciado"
                validate={validationClassificacao}
              >
                <Select
                  name="dadosGerais.classificacaoCredenciado"
                  mode="multiple"
                  placeholder="Selecione"
                  suffixIcon={<IconArrowSelect />}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value) =>
                    setFieldValue('dadosGerais.classificacaoCredenciado', value)
                  }
                  disabled={isReadOnly}
                >
                  {dadosGeraisContext?.classificacaoCredenciado?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {(dadosGeraisContext?.
              subTipoCredenciado?.
              some((sub) => sub?.tipoCredenciado === values?.dadosGerais?.tipoCredenciado &&
                sub?.id === values?.dadosGerais?.subTipoCredenciado &&
                sub?.analiseOCRFatura)
            ) && (
              <div className="col-lg-6" style={{padding: 0}}>
                <Form.Item
                  label="OCR Fatura de Energia"
                  name="dadosGerais.analiseOCRFatura"
                  validate={(value) => {
                    if (!isBoolean(value)) {
                      return 'Campo Obrigatório';
                    }
                  }}
                >
                  <Select
                    name="dadosGerais.analiseOCRFatura"
                    placeholder="Selecione"
                    suffixIcon={<IconArrowSelect />}
                    allowClear
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) =>
                      setFieldValue('dadosGerais.analiseOCRFatura', value)
                    }
                    disabled={isReadOnly}
                  >
                    <Option value={true}>Sim</Option>
                    <Option value={false}>Não</Option>
                  </Select>
                </Form.Item>
              </div>
            )}
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item
              label="CNPJ"
              name="dadosGerais.cpfCnpj"
              help={genericValidate('dadosGerais.cpfCnpj', 'message')}
              validateStatus={genericValidate('dadosGerais.cpfCnpj', 'status')}
            >
              <InputMask
                disabled={isReadOnly || values?.id}
                name="dadosGerais.cpfCnpj"
                mask={cnpjMask}
                className="ant-input"
                value={values?.dadosGerais?.cpfCnpj || null}
                onChange={(e) => {
                  setFieldValue('dadosGerais.cpfCnpj', e.target.value);
                }}
                onBlur={(e) => {
                  setCnpjValidatorWatch(true);
                  genericTouched('dadosGerais.cpfCnpj');
                }}
                placeholder="00.000.000/0000-00"
              />
            </Form.Item>
          </div>

          <div className="col-lg-9 item-dados">
            <Form.Item label="Nome" name="dadosGerais.nome">
              <Input
                type="text"
                name="dadosGerais.nome"
                placeholder="Fantasia ou Usuário"
                value={values?.dadosGerais?.nome}
                onChange={(e) => {
                  setFieldValue('dadosGerais.nome', e.target.value);
                }}
                disabled={isReadOnly}
              />
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item name="dadosGerais.dataCadastro" label="Cadastro">
              <DatePicker
                format="DD/MM/YYYY"
                name="dadosGerais.dataCadastro"
                placeholder="00/00/0000"
                disabled
              />
            </Form.Item>
          </div>
          <div className="col-lg-3 item-dados">
            <Form.Item name="dadosGerais.dataAlteracao" label="Alteração">
              <DatePicker
                format="DD/MM/YYYY"
                name="dadosGerais.dataAlteracao"
                placeholder="00/00/0000"
                disabled
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
}

export default DadosGeraisForm;
