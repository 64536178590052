import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';

function SelectSearch({
  children,
  onChange,
  value,
  mode = null,
  placeholder = 'Selecione',
  fillDefault = {},
  ...rest
}) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (value !== null) setSelected(value);
    else if (fillDefault?.obrigatorio === false) setSelected('Não informado');
  }, [value, fillDefault]);

  return (
    <Select
      {...rest}
      suffixIcon={<IconArrowSelect />}
      placeholder={placeholder}
      mode={mode}
      getPopupContainer={(trigger) => trigger.parentNode}
      onInputKeyDown={(e) => {
        if (
          e.which === 9 &&
          document?.getElementById(e.target.getAttribute('aria-owns'))
        ) {
          let value = children.find((item) => {
            const elems = document
              ?.getElementById(e.target.getAttribute('aria-owns'))
              ?.parentElement?.getElementsByClassName(
                'ant-select-item-option-active',
              );
            return (
              elems?.length === 1 &&
              item?.props?.children === elems[0]?.innerText
            );
          });
          if (mode === 'multiple') {
            let tempList;
            if (selected?.includes(value?.props?.value)) {
              tempList = selected.filter(
                (item) => item !== value?.props?.value,
              );
            } else {
              tempList = [...selected, value?.props?.value];
            }
            setSelected(tempList);
            onChange(tempList);
          } else {
            setSelected(value?.props?.value);
            onChange(value?.props?.value);
          }
        }
      }}
      onChange={(value) => {
        setSelected(value);
        onChange(value);
      }}
      defaultActiveFirstOption={true}
      value={selected}
      filterOption={(input, option) => {
        return option?.children
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
          .includes(
            input
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          );
      }}
    >
      {children}
    </Select>
  );
}

export default SelectSearch;
