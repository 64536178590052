import React, {useContext} from 'react';
import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import {Breadcrumb} from 'antd';
import { Link } from 'react-router-dom'
import ControleProposta from './ControleProposta';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';
import RelatorioControlePropostaProvider from '../../contexts/RelatorioControlePropostaContext';


function RelatorioControleProposta() {
useInspinia();
const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb className='acessa-tela-controle-proposta'>
        <Breadcrumb.Item><Link to="/">Início</Link></Breadcrumb.Item>
        <div className='exibe-credito'>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
        </div>
          <div className='nao-exibe-credito'>
            <Breadcrumb.Item >...</Breadcrumb.Item>
          </div>
          <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
          <Breadcrumb.Item>Controle de Propostas</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
       <RelatorioControlePropostaProvider>
          {checarPermissao(
            'menu',
            'menu.credito.relatorio.controlePropostas',
            'Visualizar',
          ) && ( 
            <ControleProposta />
          )}


       </RelatorioControlePropostaProvider>
      </Content>
    </Main>
  )
}

export default RelatorioControleProposta;