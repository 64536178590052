import moment from 'moment';

import Tag from '../../../components/Tag';
import Tooltip from '../../../components/tooltip';

import { currencyFormat } from '../../../utils/numberFormatter';
import { cpfFormatter } from '../../../utils/textFormatter';

export const columnsDetails = [
  {
    Header: 'Proposta',
    accessor: 'id',
    style: {
      width: 100,
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{cpfFormatter(value)}</div>
    ),
  },
  {
    Header: 'Nome',
    accessor: 'nome',
    style: {
      width: 150,
      minWidth: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Valor',
    accessor: 'valor',
    style: {
      width: 150,
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'right' }}>{currencyFormat(value)}</div>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    style: {
      textAlign: 'center',
      width: 150,
      minWidth: 150,
    },
    Cell: (e) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Tooltip
            placement="bottomRight"
            arrowPointAtCenter
            color="#435361"
            title={
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '13px',
                  lineHeight: '15px',
                }}
              >
                {e.row.original.status}
              </div>
            }
          >
            <Tag color={e.row.original.statusCor} width={75}>
              {e.row.original.status}
            </Tag>
          </Tooltip>
        </div>
      );
    },
  },
  {
    Header: 'Formalização',
    accessor: 'formalizacao',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : '--'}
      </div>
    ),
  },
  {
    Header: 'Cancelamento',
    accessor: 'cancelamento',
    style: {
      width: 140,
      minWidth: 140,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {value ? moment(value).format('DD/MM/YYYY') : '--'}
      </div>
    ),
  },
];
