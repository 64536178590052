import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import logoCrefazOn from "../../assets/images/crefazon-logo.svg";
import { getSistemaIndisponivel } from '../../services/controleAcessoService';
function SistemaIndisponivel() {

  const history = useHistory();
  const [exibeSistemaIndisponivel, setExibeSistemaIndisponivel] = useState(true);
  const [mensagemSistemaIndisponivel, setMensagemSistemaIndisponivel] = useState('');

  function changeLoginPage() {
    if (!exibeSistemaIndisponivel) {
      history.push('/');
    }
  }

  const validaSistemaIndisponivel = async () => {
    const data = await getSistemaIndisponivel();
    setExibeSistemaIndisponivel(data.data.sistemaIndisponivel);
    setMensagemSistemaIndisponivel(data.data.mensagemSistema)
  }

  useEffect(() => {
    validaSistemaIndisponivel();
    changeLoginPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibeSistemaIndisponivel])

  return (
    <div className="wrapper-sistema-indisponivel">
      <div className=" container">
        <div className="row">
          {mensagemSistemaIndisponivel && <div className="card">
            <img src={logoCrefazOn} alt="logo-crefazon" className="logo-crefazon" />
            <span className="msg">Sistema Indisponível</span>
            <span className="text">
              {mensagemSistemaIndisponivel}
            </span>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default SistemaIndisponivel