import React from 'react';
import './styles.scss';

function Card({ title, children, rightBar, className, centerBar, pagination }) {
  return (
    <div className={'ibox ' + className}>
      {title && (
        <div className="ibox-title">
          {title}
          {centerBar}
          {
            pagination ?
              <div className='pagination-follow-up'>
                {pagination}
                {rightBar}
              </div> : rightBar
          }
        </div>
      )}

      <div className="ibox-content">{children}</div>
    </div>
  );
}

export default Card;