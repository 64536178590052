import React, { createContext, useState, useCallback, useEffect } from 'react';
import {
  inserirUnidadeConsumidora,
  editarUnidadeConsumidora,
  listarUnidadeConsumidora,
  listarMotivosCompanhiaEletrica,
} from '../services/blocklistUCService';

export const BlocklistUnidadeConsumidoraContext = createContext({});

const initialUnidadeConsumidoraBlocklistState = {
  id: 0,
  unidadeConsumidora: '',
  ciaEletrica: null,
  motivoId: null,
  descricaoInclusao: '',
  descricaoExclusao: '',
  inclusao: '',
  exclusao: '',
  usuarioInclusao: '',
  usuarioExclusao: '',
};

function BlockListUnidadeConsumidoraProvider({ children }) {
  const [listagemUnidadeConsumidora, setListagemUnidadeConsumidora] = useState(
    [],
  );

  const [totalItensLista, setTotalItensLista] = useState(0);
  const [pageCountLista, setPageCountLista] = useState(0);
  const [loadingLista, setLoadingLista] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [ciaEletrica, setCIAEletrica] = useState([]);
  const [unidadeConsumidoraBlocklist, setUnidadeConsumidoraBlocklist] =
    useState(initialUnidadeConsumidoraBlocklistState);

  const [filter, setFilter] = useState({ filterStatus: true });
  const [uc, setUc] = useState('');

  const resetUnidadeConsumidoraBlocklistForm = () => {
    setUnidadeConsumidoraBlocklist(initialUnidadeConsumidoraBlocklistState);
  };

  const fetchListagemUnidadeConsumidora = useCallback(async (props) => {
    setLoadingLista(true);

    const page = props?.pageIndex + 1;
    let response = []

    if(props?.filter?.filtroDinamico || props?.filter?.filtroDinamico  === ''){

      response = await listarUnidadeConsumidora({
        filtroUnidadeConsumidora: props?.filter?.filtroDinamico || null,
        filtroAtivos: props?.filter?.filterStatus,
        ordenacao: props?.sortBy?.[0]?.id,
        ordenacaoAsc: props?.sortBy?.[0]?.desc,
        pagina: page || 1,
      });
    }

    if(!props) {
      response = await listarUnidadeConsumidora({})
    }

    if (response?.data?.itens) {
      setListagemUnidadeConsumidora(response?.data?.itens);
    } else {
      setListagemUnidadeConsumidora([]);
    }

    setTotalItensLista(response?.data?.total);
    setPageCountLista(response?.data?.totalPages);

    setLoadingLista(false);
  }, []);

  const fetchMotivosCompanhiaEletrica = useCallback(async () => {
    const response = await listarMotivosCompanhiaEletrica();
    setMotivos(response?.data?.motivo);
    setCIAEletrica(response?.data?.ciaeletrica);
  }, []);

  const salvarBlocklistUnidadeConsumidora = async (
    unidadeConsumidoraBlocklist,
  ) => {
    let response;

    if (unidadeConsumidoraBlocklist.id > 0) {
      response = await editarUnidadeConsumidora(unidadeConsumidoraBlocklist);
    } else {
      response = await inserirUnidadeConsumidora(unidadeConsumidoraBlocklist);
    }

    if (response) {
      await fetchListagemUnidadeConsumidora();
    }

    setUnidadeConsumidoraBlocklist(initialUnidadeConsumidoraBlocklistState);

    return response;
  };

  const setValueForUnidadeConsumidoraBlocklist = async (data) => {
    setUnidadeConsumidoraBlocklist(data);
  };

  useEffect(() => {
    fetchMotivosCompanhiaEletrica();
  }, [fetchMotivosCompanhiaEletrica]);

  return (
    <BlocklistUnidadeConsumidoraContext.Provider
      value={{
        listagemUnidadeConsumidora,
        totalItensLista,
        pageCountLista,
        loadingLista,
        filter,
        setListagemUnidadeConsumidora,
        setFilter,
        fetchListagemUnidadeConsumidora,
        salvarBlocklistUnidadeConsumidora,
        unidadeConsumidoraBlocklist,
        motivos,
        ciaEletrica,
        resetUnidadeConsumidoraBlocklistForm,
        setValueForUnidadeConsumidoraBlocklist,
        uc, 
        setUc,
      }}
    >
      {children}
    </BlocklistUnidadeConsumidoraContext.Provider>
  );
}

export default BlockListUnidadeConsumidoraProvider;
