import React, { useContext } from 'react';
import Slider from 'react-slick';

import { ReactComponent as DashboardIcon } from '../../../assets/images/icons/dashboard.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';

import CardChartInfo from '../../../components/CardChartInfo';
import CardIcon from '../../../components/CardIcon';
import Button from '../../../components/Button';

import './styles.scss';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

const ArrowLeft = (props) => (
  <LeftOutlined
    className="slick-arrow"
    style={{
      position: 'absolute',
      top: '35%',
      left: '-12px',
      fontSize: '14px',
      color: 'rgba(103, 106, 108, 0.5)',
    }}
    onClick={props.onClick}
  />
);
const ArrowRight = (props) => (
  <RightOutlined
    className="slick-arrow"
    style={{
      position: 'absolute',
      top: '35%',
      right: '-12px',
      fontSize: '14px',
      color: 'rgba(103, 106, 108, 0.5)',
    }}
    onClick={props.onClick}
  />
);

function CrivoComponent() {
  const settingsCharts = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <ArrowLeft />,
    prevArrow: <ArrowRight />,
  };

  const { processamentoCrivo, proposta } = useContext(AnaliseMesaContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  return (
    <CardIcon
      title="Processamento do Crivo"
      icon={<DashboardIcon className="mr-2" />}
    >
      <div className="itens-crivo">
        <ul>
          {processamentoCrivo?.processamentoCrivo?.map((item, i) => (
            <li key={i}>
              <div
                className="ellipse"
                style={{ background: item.processamentoCor }}
              ></div>
              {item.processamentoCriterio} | {item.processamentoRetorno}
            </li>
          ))}
        </ul>
      </div>
      {proposta?.id &&
        checarPermissao(
          'botao',
          'botao.analiseMesaCredito.verMais',
          'Visualizar',
        ) && (
          <div className="btn-ver-mais d-flex justify-content-end mb-4">
            <a
              href={`/processamento-crivo/${proposta?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="orange-outline">Ver Mais</Button>
            </a>
          </div>
        )}
      <div className="charts-info">
        <Slider {...settingsCharts}>
          {processamentoCrivo?.processamentoCards?.map((card, i) => (
            <div className="col-chart" key={i}>
              <CardChartInfo
                title={card.cardTitulo}
                subtitle={card?.cardRestricao}
                value={card.cardScore}
                date={card.cardDataHora}
              />
            </div>
          ))}
        </Slider>
      </div>
    </CardIcon>
  );
}

export default CrivoComponent;
