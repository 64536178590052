import React, { useContext } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import RelatorioComissaoProvider from '../../contexts/RelatorioComissaoContext';
import { RelatorioComissaoContext } from '../../contexts/RelatorioComissaoContext';

import ComissaoList from './ComissaoList';
import NotasFiscais from './NotasFiscais';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function RelatorioComissao() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext)
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Comissão</Breadcrumb.Item>
          <Breadcrumb.Item>Relatório de Comissão</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <RelatorioComissaoProvider>
          {checarPermissao('tela','tela.comercial.comissao.relatorioComissao','Visualizar') && <> 
            <Lists />
          
          </>}
        </RelatorioComissaoProvider>
      </Content>
    </Main>
  );
}

function Lists() {
  const { tipoRelatorio } = useContext(RelatorioComissaoContext);

  return tipoRelatorio === 'nf' ? <NotasFiscais /> : <ComissaoList />;
}

export default RelatorioComissao;
