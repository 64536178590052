import api from './api';

export const listarProdutosSimulacao = async (simulacao) => {
  try {
    let data;
    await api
      .get('/proposta/simulacao', simulacao)
      .then((value) => (data = value.data));
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const calcularParcela = async (condicoes) => {
  try {
    let data;
    await api
      .post('/Proposta/simulacao-prestacao', condicoes)
      .then((value) => (data = value.data));
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const calculaLimiteParcela = async (dados) => {
  const { data } = await api.post('/proposta/limite-parcela', dados);
  return data;
};

export const calculaVencimento = async (dados) => {
  const { data } = await api.post('/proposta/calculo-vencimento', dados);
  return data;
};

export const simularProposta = async (tipo, payload) => {
  try {
    const path = tipo === 0 ? 'simulacao-prestacao' : 'simulacao-solicitado';

    const { data } = await api.post(`/proposta/${path}`, payload);

    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
