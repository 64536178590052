import React from 'react';
import PropTypes from 'prop-types';
import NumberTextBox from 'react-number-format';

function InputInteger({ name, className, ...props }) {
  return (
    <NumberTextBox
      name={name}
      className={className}
      allowNegative={false}
      decimalSeparator=""
      {...props}
    />
  );
}

InputInteger.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InputInteger.defaultProps = {
  className: '',
};

export default InputInteger;
