import React, { useContext } from 'react';

import { Form, Field, Checkbox } from 'formik-antd';
import InputMask from 'react-text-mask';

import { phoneMask, cpfMask } from '../../../utils/masks';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import TooltipAlterados from '../../../components/TooltipAlterados';
import Input from '../../../components/SimpleInput';

import './styles.scss';

function UnidadeForm() {
  const {
    formik,
    editableField,
    validationField,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
  } = useContext(AnaliseMesaContext);

  const { setFieldTouched } = formik;


  const handleCPF = () => {
    setFieldTouched('unidade.cpfVendedor');
  };


  return (
    <>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="unidade.nomeMatriz"
            label={
              <>
                <label>Nome da Matriz</label>
                {campoChecar('unidade.nomeMatriz') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('unidade.nomeMatriz')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.nomeMatriz')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.nomeMatriz') && <TooltipAlterados />}
              </>
            }
            className={highlightField('unidade.nomeMatriz') && `campo-alterado`}
            validate={(value) =>
              validationField(value, 'unidade.nomeMatriz', 'Nome da matriz')
            }
            hasFeedback
          >
            <Input
              name="unidade.nomeMatriz"
              placeholder="Nome matriz"
              disabled={!editableField('unidade.nomeMatriz')}
              value={formik?.values?.unidade?.nomeMatriz}
              onBlur={(e) =>
                formik?.setFieldValue(`unidade.nomeMatriz`, e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="unidade.loginAgente"
            label={
              <>
                <label>Login do Agente</label>
                {campoChecar('unidade.loginAgente') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('unidade.loginAgente')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.loginAgente')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.loginAgente') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('unidade.loginAgente') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'unidade.loginAgente', 'Login do Agente')
            }
            hasFeedback
          >
            <Input
              name="unidade.loginAgente"
              disabled={!editableField('unidade.loginAgente')}
              value={formik?.values?.unidade?.loginAgente}
              onBlur={(e) =>
                formik?.setFieldValue(`unidade.loginAgente`, e.target.value)
              }
            />
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="unidade.nomeSupervisor"
            label={
              <>
                <label>Supervisor Comercial Crefaz</label>
                {campoChecar('unidade.nomeSupervisor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('unidade.nomeSupervisor')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.nomeSupervisor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.nomeSupervisor') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('unidade.nomeSupervisor') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'unidade.nomeSupervisor',
                'Supervisor Comercial',
              )
            }
            hasFeedback
          >
            <Input
              name="unidade.nomeSupervisor"
              disabled={!editableField('unidade.nomeSupervisor')}
              value={formik?.values?.unidade?.nomeSupervisor}
              onBlur={(e) =>
                formik?.setFieldValue(`unidade.nomeSupervisor`, e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item
            name="unidade.celularSupervisor"
            label={
              <>
                <label>Celular do Supervisor</label>
                {campoChecar('unidade.celularSupervisor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('unidade.celularSupervisor')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.celularSupervisor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.celularSupervisor') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('unidade.celularSupervisor') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'unidade.celularSupervisor',
                'Celular do Supervisor',
              )
            }
          >
            <Field name="unidade.celularSupervisor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="Ex.: (00) 00000-0000"
                  mask={phoneMask}
                  showMask={true}
                  disabled={!editableField('unidade.celularSupervisor')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="unidade.nomeVendedor"
            label={
              <>
                <label>Nome do Vendedor</label>
                {campoChecar('unidade.nomeVendedor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('unidade.nomeVendedor')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.nomeVendedor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.nomeVendedor') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('unidade.nomeVendedor') && `campo-alterado`
            }
            validate={(value) =>
              validationField(value, 'unidade.nomeVendedor', 'Nome do Vendedor')
            }
            hasFeedback
          >
            <Input
              name="unidade.nomeVendedor"
              disabled={!editableField('unidade.nomeVendedor')}
              value={formik?.values?.unidade?.nomeVendedor}
              onBlur={(e) =>
                formik?.setFieldValue(`unidade.nomeVendedor`, e.target.value)
              }
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="unidade.cpfVendedor"
            label={
              <>
                <label>CPF do Vendedor</label>
                {campoChecar('unidade.cpfVendedor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(campoChecar('unidade.cpfVendedor')?.campoId)
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.cpfVendedor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.cpfVendedor') && <TooltipAlterados />}
              </>
            }
            className={
              highlightField('unidade.cpfVendedor') && `campo-alterado`
            }
            validate={(value) =>
              validationField(
                value,
                'unidade.cpfVendedor',
                'CPF do Vendedor',
                'cpf',
              )
            }
            hasFeedback
          >
            <Field name="unidade.cpfVendedor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="000.000.000-00"
                  onBlur={handleCPF}
                  mask={cpfMask}
                  showMask={true}
                  disabled={!editableField('unidade.cpfVendedor')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="unidade.celularVendedor"
            className={
              highlightField('unidade.celularVendedor') && `campo-alterado`
            }
            label={
              <>
                <label>Celular do Vendedor</label>
                {campoChecar('unidade.celularVendedor') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('unidade.celularVendedor')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('unidade.celularVendedor')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('unidade.celularVendedor') && (
                  <TooltipAlterados />
                )}
              </>
            }
            validate={(value) =>
              validationField(
                value,
                'unidade.celularVendedor',
                'Celular vendedor',
              )
            }
            hasFeedback
          >
            <Field name="unidade.celularVendedor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="Ex.: (00) 0000-0000"
                  mask={phoneMask}
                  showMask={true}
                  disabled={!editableField('unidade.celularVendedor')}
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default UnidadeForm;
