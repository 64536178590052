import React from 'react';

import './styles.scss';

function CardIcon({ titulo, textoConteudo, corFonte, children, porcentagem }) {
  return (
    <div className="card-estatisticas">
      <div className="card-header">
        <span>{titulo}</span>
        <span style={{ color: corFonte, fontSize: '16px' }}>{porcentagem}</span>
      </div>
      <div className="card-body">
        <div className="card-content">
          <div className="img">{children}</div>
          <p className="card-text" style={{ color: corFonte }}>
            {textoConteudo}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CardIcon;
