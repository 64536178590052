import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'antd';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Overlay from '../../../components/Overlay';

import { SimulacaoContext } from '../../../contexts/SimulacaoContext';

import Table from './table';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function SimulacaoList() {
  const { simulacao, showOverlay, tipoSimulacao, setSelectedRow } =
    useContext(SimulacaoContext);
  const history = useHistory();
  const { checarPermissao } = useContext(ControleAcessoContext);
  const navigateToPreAnalise = () => {
    history.push('/pre-analise');
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const columns = [
    {
      accessor: 'plano',
      Header: () => <div style={{ textAlign: 'right' }}>Prazo</div>,
      Cell: ({ value }) => <div style={{ textAlign: 'right' }}>{value}</div>,
    },
    {
      accessor: tipoSimulacao ? 'solicitado' : 'prestacao',
      Header: () => (
        <div style={{ textAlign: 'right' }}>
          Valor {tipoSimulacao ? 'Solicitado' : 'Parcela'}
        </div>
      ),
      Cell: ({ value }) => (
        <div style={{ textAlign: 'right' }}>{numberFormat(value)}</div>
      ),
    },
  ];

  return (
    <Overlay active={showOverlay}>
      {simulacao.length > 0 && (
        <Card title={<Title label="Resultado Simulação" />}>
          <Table
            columns={columns}
            data={simulacao}
            setSelectedRow={setSelectedRow}
          />

          <div className="d-flex justify-content-end mt-3">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-orange"
              data-cy="submit-pre-analise"
              onClick={navigateToPreAnalise}
              style={{
                fontWeight: 400,
                paddingLeft: '20px',
                paddingRight: '20px',
                lineHeight: '1em',
              }}
              disabled={!checarPermissao('tela','tela.credito.proposta.novaProposta','Visualizar')}
            >
              PRÉ-ANÁLISE
            </Button>
          </div>
        </Card>
      )}
    </Overlay>
  );
}

export default SimulacaoList;
