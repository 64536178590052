import React from 'react';

import './styles.scss';

function WidgetRelatorio({ title, total, time, color, date }) {
  return (
    <div className="widget-relatorio" style={{ background: color }}>
      <div className="widget-header">
        <div className="widget-title">{title.toUpperCase()}</div>
        <h2 className="mb-2">{total}</h2>
      </div>
      <div className="widget-content">
        <div className="text-lg-left mt-2">
          <i>Data</i>
          <h3>{date}</h3>
        </div>
        <div className="text-lg-left mt-2">
          <i>Espera</i>
          <h3>{time}</h3>
        </div>
      </div>
    </div>
  );
}

export default WidgetRelatorio;
