import React, { useEffect , useContext, useState} from 'react';

import { Form, Field, Input, Select } from 'formik-antd';

import DatePicker from '../../../../components/DatePicker';

import { ReactComponent as IconArrowSelect } from '../../../../assets/images/icons/arrow-down-select.svg';
import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';

const { Option } = Select;

function DadosAdicionaisForm({ formik }) {
  const { values, setFieldTouched } = formik;
  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao('aba', 'aba.cadastroPessoa.cliente.dadosAdicionais', 'Editar'),
    );
  }, [checarPermissao]);

  useEffect(() => {
    values?.dadosConvenio?.forEach((conveniada, i) => {
      conveniada?.dadosAdicionais?.forEach((_, j) => {
        setFieldTouched(`dadosConvenio.${i}.dadosAdicionais.${j}.valor`, true);
      });
    });
  }, [values.dadosConvenio, setFieldTouched]);

  return (
    <>
      <div className="content-tab-conveniadas">
        {values?.dadosConvenio.map((conveniada, i) => (
          <div className="form-row item-row" key={i}>
            <div className="col-lg-2">
              <Form.Item
                name={`dadosConvenio.${i}.nomeConvenio`}
                label="Conveniada"
                hasFeedback
              >
                <Input
                  readOnly
                  name={`dadosConvenio.${i}.nomeConvenio`}
                  placeholder=""
                  data-cy={`nome-convenio-${i}`}
                  disabled={acesso}
                />
              </Form.Item>
            </div>

            {conveniada?.dadosAdicionais?.length > 0 &&
              conveniada?.dadosAdicionais?.map((dadoAdicional, j) => (
                <div className="col-lg-2" key={j}>
                  <Form.Item
                    name={`dadosConvenio.${i}.dadosAdicionais.${j}.valor`}
                    label={dadoAdicional?.nome}
                    hasFeedback
                  >
                    {dadoAdicional?.tipo === 1 ? (
                      <Field
                        name={`dadosConvenio.${i}.dadosAdicionais.${j}.valor`}
                        validate={(value) => {
                          if (!RegExp(dadoAdicional?.formato).test(value)) {
                            return dadoAdicional?.mensagem;
                          }
                        }}
                      >
                        {({ field }) => (
                          <Input
                            {...field}
                            defaultValue={dadoAdicional?.valor}
                            className="ant-input"
                            type="number"
                            disabled={acesso}
                          />
                        )}
                      </Field>
                    ) : dadoAdicional?.tipo !== 4 ? (
                      <Field
                        name={`dadosConvenio.${i}.dadosAdicionais.${j}.valor`}
                        validate={(value) => {
                          if (!RegExp(dadoAdicional?.formato).test(value)) {
                            return dadoAdicional?.mensagem;
                          }
                        }}
                      >
                        {({ field }) => (
                          <Input
                            {...field}
                            defaultValue={dadoAdicional?.valor}
                            className="ant-input"
                          />
                        )}
                      </Field>
                    ) : (
                      <DatePicker
                        name={`dadosConvenio.${i}.dadosAdicionais.${j}.valor`}
                        format="DD/MM/YYYY"
                        disabled={acesso}
                      />
                    )}
                  </Form.Item>
                </div>
              ))}
            <div className="col-lg-2">
              <Form.Item
                name={`dadosConvenio.${i}.ativo`}
                label="Status"
                hasFeedback
              >
                <Select
                  name={`dadosConvenio.${i}.ativo`}
                  suffixIcon={<IconArrowSelect />}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={acesso}
                >
                  <Option key={0} value={false}>
                    Inativo
                  </Option>
                  <Option key={1} value={true}>
                    Ativo
                  </Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DadosAdicionaisForm;
