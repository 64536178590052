import { Field, Form } from 'formik-antd';
import './styles.scss';
import Input from '../../../components/SimpleInput';
import SelectSearch from '../../../components/SearchSelect';
import { useContext } from 'react';
import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import InputMoney from '../../../components/InputMoney';

export const DadosGarantiaForm = () => {
  const { formik, editableField } = useContext(AnaliseMesaContext);
  const { setFieldValue } = formik;

  return (
    <>
      <div className="form-row">
        <div className="col-lg-12">
          <p className="titulo-bloco">Dados do Veículo</p>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item name="dadosBemGarantia.placa" label="Placa" hasFeedback>
            <Field name="dadosBemGarantia.placa">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item name="dadosBemGarantia.chassi" label="Chassi" hasFeedback>
            <Field name="dadosBemGarantia.chassi">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="dadosBemGarantia.renavam"
            label="Renavam"
            hasFeedback
          >
            <Field name="dadosBemGarantia.renavam">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item name="dadosBemGarantia.marca" label="Marca" hasFeedback>
            <Field name="dadosBemGarantia.marca">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-6">
          <Form.Item
            name="dadosBemGarantia.modeloVersao"
            label="Modelo/Versão"
            hasFeedback
          >
            <Field name="dadosBemGarantia.modeloVersao"
              validate={(value) => {
                let msgError = null;

                if (!value) {
                  msgError = 'O Modelo/Versão é obrigatório';
                }

                return msgError;
              }}>
              {({ field }) => (
                <input
                  {...field}
                  className='ant-input'
                  disabled={!editableField('dadosGarantia.modeloVersao')}
                  onBlur={(event) => {
                    setFieldValue('dadosBemGarantia.modeloVersao', event.target.value);
                  }}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="dadosBemGarantia.anoModelo"
            label="Ano Modelo"
            hasFeedback
          >
            <Field name="dadosBemGarantia.anoModelo">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="dadosBemGarantia.anoFabricacao"
            label="Ano Fabricação"
            hasFeedback
          >
            <Field name="dadosBemGarantia.anoFabricacao">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="dadosBemGarantia.valorGarantia"
            label="Valor da Garantia"
            hasFeedback
          >
            <Field name="dadosBemGarantia.valorGarantia">
              {({ field }) =>
                <InputMoney 
                  {...field}
                  style={{
                    textAlign: "left"
                  }}
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  disabled />
              }
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-5">
          <Form.Item
            name="dadosBemGarantia.ufLicenciamento"
            label="UF Licenciamento"
            hasFeedback
          >
            <Field name="dadosBemGarantia.ufLicenciamento">
              {({ field }) => <SelectSearch {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name="dadosBemGarantia.ufRegistro"
            label="UF de Registro"
            hasFeedback
          >
            <Field name="dadosBemGarantia.ufRegistro">
              {({ field }) => <SelectSearch {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-12">
          <p className="titulo-bloco">Leilão</p>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="dadosBemGarantia.positivoLeilao"
            label="Positivo para Leilão"
            hasFeedback
          >
            <Field name="dadosBemGarantia.positivoLeilao">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-9">
          <Form.Item
            name="dadosBemGarantia.scoreLeilao"
            label="Score Leilao"
            hasFeedback
          >
            <Field name="dadosBemGarantia.scoreLeilao">
              {({ field }) => <Input {...field} disabled />}
            </Field>
          </Form.Item>
        </div>
      </div>
    </>
  );
};
