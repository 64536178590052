import api from './api';

export const getContextoBordero = async () => {
  try {
    const { data } = await api.get('/bordero/contexto');
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const listarBorderos = async ({
  pagina = 1,
  ordenacao = { id: '', desc: false },
  debito = null,
  cnpj = null,
  nomeFantasia = null,
  situacaoBordero = null,
  dataInicial = '',
  dataFinal = '',
}) => {
  const { data } = await api.post('/bordero/gerenciamento-bordero', {
    pagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    debito,
    cnpj,
    nomeFantasia,
    situacaoBordero,
    dataInicial,
    dataFinal,
  });
  return data;
};

export const getCardsDetalhes = async (borderoId) => {
  try {
    const { data } = await api.get(`/bordero/detalhes-cards/${borderoId}`);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const listarTabelaPropostasBordero = async ({
  pagina = 1,
  ordenacao = { id: '', desc: false },
  filtroBorderoId = null,
}) => {
  const { data } = await api.post('/bordero/detalhes-bordero', {
    pagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    filtroBorderoId,
  });
  return data;
};
