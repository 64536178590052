import React, { useContext } from 'react';
import moment from 'moment';

import { Formik } from 'formik';
import { Form, Input, DatePicker, Select } from 'formik-antd';
import { SearchOutlined } from '@ant-design/icons';

import Button from '../../../components/Button';

import { AcompanhamentoCredenciamentoContext } from '../../../contexts/AcompanhamentoCredenciamentoContext';

import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { ReactComponent as EraseIcon } from '../../../assets/images/icons/erase.svg';

import './styles.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormatList = ['DD/MM/YYYY', 'DDMMYYYY'];

const initialValues = {
  filtroEtapa: null,
  filtroTipo: null,
  filtroTipoData: null,
  filtroData: null,
  filtroCoordenador: null,
  filtroGerente: null,
  filtroSupervisor: null,
  filtroDinamico: null,
};

function Filter() {
  const { contextoCredenciamento, setFilters } = useContext(
    AcompanhamentoCredenciamentoContext,
  );

  return (
    <div className="filter-container">
      <Formik
        initialValues={initialValues}
        onSubmit={async (data) => {
          const dataInicial = data.filtroData
            ? moment(data.filtroData[0]).format('YYYY-MM-DD')
            : null;
          const dataFinal = data.filtroData
            ? moment(data.filtroData[1]).format('YYYY-MM-DD')
            : null;

          setFilters({
            pagina: 1,
            estagioId: data.filtroEtapa,
            tipo: data.filtroTipo,
            tipoData: data.filtroTipoData,
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            coordenadorId: data.filtroCoordenador,
            gerenteId: data.filtroGerente,
            supervisorId: data.filtroSupervisor,
            filtroDinamico: data.filtroDinamico,
          });
        }}
      >
        {({ resetForm }) => (
          <Form layout="vertical">
            <div className="row d-flex align-items-center">
              <div className="col-lg-10 col-md-10">
                <div className="form-row" style={{ marginBottom: '10px' }}>
                  <div className="col-lg-3">
                    <Form.Item name="filtroEtapa" className="field-control">
                      <Select
                        name="filtroEtapa"
                        placeholder="Situação"
                        mode="multiple"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.credenciamentoEstagio &&
                          contextoCredenciamento.credenciamentoEstagio.map(
                            (estagio) => (
                              <Option key={estagio.id} value={estagio.id}>
                                {estagio.descricao}
                              </Option>
                            ),
                          )}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-3">
                    <Form.Item name="filtroTipo" className="field-control">
                      <Select 
                        name="filtroTipo" 
                        placeholder="Tipo" 
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.tipoCredenciamento &&
                          contextoCredenciamento.tipoCredenciamento.map(
                            (tipoCredenciamento) => (
                              <Option
                                key={tipoCredenciamento.id}
                                value={tipoCredenciamento.id}
                              >
                                {tipoCredenciamento.nome}
                              </Option>
                            ),
                          )}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-3">
                    <Form.Item name="filtroTipoData" className="field-control">
                      <Select
                        name="filtroTipoData"
                        placeholder="Tipo de Data"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.tipoData &&
                          contextoCredenciamento.tipoData.map((tipoData) => (
                            <Option key={tipoData.id} value={tipoData.id}>
                              {tipoData.nome}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-3">
                    <Form.Item name="filtroData" className="field-control">
                      <RangePicker name="filtroData" format={dateFormatList} />
                    </Form.Item>
                  </div>
                </div>

                <div className="form-row" style={{ marginBottom: '10px' }}>
                  <div className="col-lg-4">
                    <Form.Item
                      name="filtroCoordenador"
                      className="field-control"
                    >
                      <Select
                        name="filtroCoordenador"
                        placeholder="Coordenador"
                        mode="multiple"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.coordenadores &&
                          contextoCredenciamento.coordenadores.map(
                            (coordenador) => (
                              <Option
                                key={coordenador.id}
                                value={coordenador.id}
                              >
                                {coordenador.Nome}
                              </Option>
                            ),
                          )}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4">
                    <Form.Item name="filtroGerente" className="field-control">
                      <Select
                        name="filtroGerente"
                        placeholder="Gerente"
                        mode="multiple"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.gerentes &&
                          contextoCredenciamento.gerentes.map((gerente) => (
                            <Option key={gerente.id} value={gerente.id}>
                              {gerente.Nome}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="col-lg-4">
                    <Form.Item
                      name="filtroSupervisor"
                      className="field-control"
                    >
                      <Select
                        name="filtroSupervisor"
                        placeholder="Supervisor"
                        mode="multiple"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contextoCredenciamento.supervisores &&
                          contextoCredenciamento.supervisores.map(
                            (supervisor) => (
                              <Option key={supervisor.id} value={supervisor.id}>
                                {supervisor.Nome}
                              </Option>
                            ),
                          )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-lg-12">
                    <Form.Item name="filtroDinamico" className="field-control">
                      <Input
                        name="filtroDinamico"
                        suffix={<SearchOutlined />}
                        placeholder="CNPJ ou Nome"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2">
                <div className="d-flex flex-column justify-content-end">
                  <Button type="submit" variant="blue">
                    <SearchIcon className="search-icon" />
                    <span className="d-md-none d-lg-inline ml-2">
                      Pesquisar
                    </span>
                  </Button>

                  <span className="p-1"></span>

                  <Button
                    type="button"
                    variant="gray"
                    onClick={() => {
                      resetForm();
                      setFilters({
                        estagioId: null,
                        tipo: null,
                        tipoData: null,
                        dataInicial: null,
                        dataFinal: null,
                        coordenadorId: null,
                        gerenteId: null,
                        supervisorId: null,
                        filtroDinamico: null,
                      });
                    }}
                  >
                    <EraseIcon className="erase-icon" />
                    <span className="d-md-none d-lg-inline ml-2">Limpar</span>
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Filter;
