import React from 'react';
import { Dropdown } from 'antd';

function DropdownButton({
  type,
  variant,
  className,
  block,
  onClick,
  children,
  ...props
}) {
  return (
    <Dropdown.Button onClick={onClick} {...props}>
      {children}
    </Dropdown.Button>
  );
}

export default DropdownButton;
