import api from './api';

export const listarNotasFiscais = async ({
  pagina = 1,
  quantidadePorPagina = 10,
  ordenacao = { id: '', desc: false },
  filtroMesAnoReferencia = null,
  filtroCNPJMatriz = null,
  filtroMatriz = [],
  filtroStatusVerificacao = null,
  filtroDataInicial = null,
  filtroDataFinal = null,
}) => {
  const { data } = await api.post('/comissao/relatorio/notas', {
    pagina,
    quantidadePorPagina,
    ordenacao: ordenacao.id,
    ordenacaoAsc: ordenacao.desc,
    filtroMesAnoReferencia,
    filtroCNPJMatriz,
    filtroMatriz,
    filtroStatusVerificacao,
    filtroDataInicial,
    filtroDataFinal,
  });
  return data;
};

export const listarMatrizes = async (id) => {
  const { data } = await api.get(`/comissao/comissao-contexto`);
  return data;
};

// export const arquivoNotaFiscal = async (id) => {
//   const { data } = await api.get(`/comissao/download-nota/${id}`);
//   return data;
// };

export const arquivoNotaFiscal = async (id) => {
  try {
    const data = await api.get(`/comissao/download-nota/${id}`);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export const inserirNotas = async (notaFiscal) => {
  const { data } = await api.post('/comissao/inserir-nota', notaFiscal);
  return data;
};

export const verificaNota = async (notaId, status) => {
  const { data } = await api.put(`/comissao/verifica-nota/${notaId}`, {
    id: notaId,
    verificado: status,
  });
  return data;
};
