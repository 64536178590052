const initialValues = {
  id: null,
  dadosGerais: {
    ativo: true,
    tipoUnidade: null,
    cpfCnpj: '',
    nome: '',
    nomeMatriz: '',
    nomeUnidadePai: null,
  },
  endereco: {
    cep: '',
    logradouro: '',
    numero: '',
    ufId: null,
    cidadeId: null,
    bairro: '',
    complemento: null,
  },
  regioes: [],
  produtos: {
    tipoModalidade: null,
    produtos: [],
  },
  comercial: {
    hierarquia: {
      id: null,
      dataVigenciaInicial: '',
      dataVigenciaFinal: '',
      niveis: [],
    },
    historico: [],
    lstProximoNivel: [],
  },
};
export default initialValues;
