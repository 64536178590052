import React, { useContext, useState } from 'react';

import { Radio, Tooltip } from 'antd';

import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import InputMoney from '../../../components/InputMoney';
import ModalUpload from '../../../components/ModalUpload';

import Filter from '../Filter';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';

import { RelatorioComissaoContext } from '../../../contexts/RelatorioComissaoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import './styles.scss';

function Toolbar() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    showFilter,
    setShowFilter,
    tipoRelatorio,
    setTipoRelatorio,
    totalComissao,
    exportarCsv,
    exportarPdf,
    arquivosNotas,
    setArquivosNotas,
    uploadArquivosNotas,
  } = useContext(RelatorioComissaoContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const imprimirPdf = async () => {
    const obj = await exportarPdf();
    sessionStorage.setItem('relatorioComissaoImpressao', JSON.stringify(obj));

    window.open('/relatorio-comissao-pdf', '_blank');
  };

  const acessoAnexarPDF = checarPermissao(
    'botao', 
    'botao.relatorioComissao.anexarPDF',
    'Criar')
  
  const ButtonAnexar = () => (
    <Button
      className="mb-1"
      type="button"
      variant="white"
      disabled={tipoRelatorio === 'pago'}
      style={{ cursor: acessoAnexarPDF ? 'pointer' : 'not-allowed'}}
      onClick={() => acessoAnexarPDF && setIsModalVisible(true)}
    >
      <Icon variant="attachment" />
      <span className="ml-2" style={{ textTransform: 'none' }}>
        Anexar PDF
      </span>
    </Button>
  );

  return (
    <>
      <div className=" mb-3 topo-relatorio-esteira-credito">
        <div className="title-esteira">
          <Title label="Lista de Comissões" />
        </div>
      </div>
      <div className="toolbar d-flex justify-content-between align-items-center">
        <div className="tipo-comissao">
          <Radio.Group
            value={tipoRelatorio}
            onChange={(e) => setTipoRelatorio(e.target.value)}
          >
            {checarPermissao(
              'filtro',
              'filtro.relatorioComissao.paga',
              'Visualizar',
            ) && <Radio value={'pago'}>Comissão paga</Radio>}
            {checarPermissao(
              'filtro',
              'filtro.relatorioComissao.naoPaga',
              'Visualizar',
            ) && <Radio value={'não pago'}>Comissão não paga</Radio>}
            {checarPermissao(
              'filtro',
              'filtro.relatorioComissao.notasFiscais',
              'Visualizar',
            ) && <Radio value={'nf'}>Notas fiscais</Radio>}
          </Radio.Group>
        </div>
        <div className="toolbar-buttons">
          {tipoRelatorio !== 'nf' && (
            <>
              <div className="campo-total-comissao mb-1">
                <div>
                  <label>Total comissão</label>
                  <Tooltip
                    placement="bottom"
                    className="tooltip-info"
                    overlayClassName="tooltip-proposta-credito"
                    color="#435361"
                    title="É a soma da coluna comissão."
                  >
                    <BulletInfo className="bullet-info-icon" />
                  </Tooltip>
                </div>

                <InputMoney
                  className="ant-input"
                  prefix="R$ "
                  allowNegative={false}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  maxLength={12}
                  value={totalComissao}
                  disabled
                />
              </div>

              <span className="p-1"></span>
              {checarPermissao(
                'botao',
                'botao.relatorioComissao.anexarPDF',
                'Visualizar',
              ) ? (
                <>
                  {tipoRelatorio === 'pago' ? (
                    <Tooltip
                      placement="bottom"
                      className="tooltip-info"
                      overlayClassName="tooltip-proposta-credito"
                      color="#435361"
                      title="Botão desabilitado, para anexar mude para comissão não paga."
                    >
                      <div>
                        <ButtonAnexar />
                      </div>
                    </Tooltip>
                  ) : (
                    <ButtonAnexar />
                  )}
                </>
              ) : (
                <> </>
              )}

              <span className="p-1"></span>

              <Button
                className="mb-1"
                type="button"
                variant="white"
                async={true}
                onClick={() => imprimirPdf()}
              >
                <Icon variant="download" />
                <span className="ml-2">PDF</span>
              </Button>

              <span className="p-1"></span>

              <Button
                className="mb-1"
                type="button"
                variant="white"
                async={true}
                onClick={() => exportarCsv()}
              >
                <Icon variant="download" />
                <span className="ml-2">CSV</span>
              </Button>

              <span className="p-1"></span>
            </>
          )}
          <Button
            className="mb-1"
            type="button"
            variant="gray"
            onClick={() => setShowFilter(!showFilter)}
          >
            <Icon variant="filter" />
            <span className="ml-2">Filtrar</span>
          </Button>
        </div>
      </div>
      {showFilter && <Filter />}
      <ModalUpload
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        arquivos={arquivosNotas}
        setArquivos={setArquivosNotas}
        action={uploadArquivosNotas}
      />
    </>
  );
}

export default Toolbar;
