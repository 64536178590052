import React, { useContext, useEffect } from 'react';

import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';

import TabelaPrincipal from './TabelaPrincipal';
import TabelaForm from './TabelaForm';
import Tabelas from './tables';

import useInspinia from '../../hooks/useInspinia';

import TabelaJurosProvider, {
  TabelaJurosContext,
} from '../../contexts/TabelaJurosContext';

import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

import './styles.scss';

function TabelaJuros() {
  const { checarPermissao } = useContext(ControleAcessoContext);
  useInspinia();

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Tabela de Juros</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      {checarPermissao('tela', 'tela.cadastro.tabelajuros', 'Visualizar') ? (
        <Content>
          <TabelaJurosProvider>
            <TabelaJurosPage checarPermissao={checarPermissao} />
          </TabelaJurosProvider>
        </Content>
      ) : (
        <></>
      )}
    </Main>
  );
}

function TabelaJurosPage({ checarPermissao }) {
  const { setAcesso } = useContext(TabelaJurosContext);
  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.cadastro.tabelajuros', 'Visualizar'));
  }, [checarPermissao, setAcesso]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <TabelaPrincipal />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6"> 
          <TabelaForm />
        </div>
        <div className="col-lg-6 tabela-juros-percentuais" style={{display: "flex"}}>
          <Tabelas />
        </div>
      </div>
    </>
  );
}

export default TabelaJuros;
