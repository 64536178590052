import React, { useContext } from 'react';
import { Modal, Button } from 'antd';

import { ReactComponent as IconExclamationCircle } from '../../../assets/images/icons/icon-circle-excalamation-header-luz-dia.svg';

import { HierarquiasContext } from '../../../contexts/HierarquiasContext';

export default function ModalConfirmaInativacao() {
  const { infoModalInativacao, setInfoModalInativacao } =
    useContext(HierarquiasContext);

  const { posicao, visible } = infoModalInativacao;

  function DependenteItem({ item, keyId }) {
    return (
      <div key={`dependente-item-${keyId}`}>
        <div className="dependente-item">
          <div className="position">
            <b>Posição:</b> {item.nome}
          </div>
          <div>
            {item.usuarioNome && (
              <>
                <b>Responsável:</b> {item.usuarioNome}
              </>
            )}
          </div>
          {item.unidades ? (
            <div>
              <b>Unidades:</b> {item.unidades}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="dependente-sub-item">
          {item?.posicoes?.map((item, index) => (
            <DependenteItem
              item={item}
              key={`dependente-sub-item-${index}`}
              keyId={`dependente-sub-item-${index}`}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      centered
      footer={null}
      width={452}
      maskClosable={false}
      closable={false}
      className="modal-warning-hierarquia-dependentes"
    >
      <div className="icon">
        <IconExclamationCircle width={75} />
      </div>
      <div className="text-modal">
        <div>
          Inativar <b>{posicao?.nome ?? 'posição'}</b> e seus dependentes?
        </div>
      </div>
      {posicao ? (
        <div className="bloco">
          <div className="spacing" id="spacing-scrollable">
            {posicao?.posicoes?.map((item, index) => (
              <DependenteItem
                item={item}
                keyId={`dependente-${index}`}
                key={`dependente-${index}`}
              />
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="btn-area">
        <Button
          onClick={() => {
            setInfoModalInativacao({ visible: false });
          }}
          variant="gray"
          className="modal-btn btn-cancel"
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            if (infoModalInativacao?.confirmaInativacao) {
              infoModalInativacao.confirmaInativacao();
            }
            setInfoModalInativacao({ visible: false });
          }}
          variant="red"
          className="modal-btn btn-inativar"
        >
          Inativar
        </Button>
      </div>
    </Modal>
  );
}
