import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';

import useInspinia from '../../hooks/useInspinia';

import ProdutosList from './ListagemDeProdutos/index';
import ProdutosForm from './ParametroForm/index';
import { Breadcrumb } from 'antd';

import ParametroProdutosProvider from '../../contexts/ParametroProdutosContext';

function ParametroDeProduto() {
  useInspinia();

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Parametrização</Breadcrumb.Item>
          <Breadcrumb.Item>Produto</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <ParametroProdutosProvider>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ProdutosList />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ProdutosForm />
            </div>
          </div>
        </ParametroProdutosProvider>
      </Content>
    </Main>
  );
}

export default ParametroDeProduto;
