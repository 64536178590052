import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import './styles.scss';

import Card from '../Card';
import Title from '../Title';
import Tooltip from '../tooltip';

import { ReactComponent as IconSendButton } from '../../assets/images/icons/icon-send-button.svg';
import { ReactComponent as IconSaveButton } from '../../assets/images/icons/save.svg';
import { ReactComponent as IconMsgEmpresaCrefaz } from '../../assets/images/icons/icon-mensagem-empresa-crefaz.svg';
import { ReactComponent as IconMsgVendedor } from '../../assets/images/icons/icon-mensagem-vendedor.svg';
import { ReactComponent as IconCloseTooltip } from '../../assets/images/icons/icon-close-tooltip-messages.svg';
import { ReactComponent as IconDoubleArrow } from '../../assets/images/icons/double-arrow.svg';
import { ReactComponent as IconMsgEmpresaCrefazRotinaAutomatica } from '../../assets/images/icons/icon-chat-interno-rotina-automatica.svg'

import { Form, Input, Button } from 'antd';
const { TextArea } = Input;

function MessageItem({
  origem,
  data,
  horario,
  remetente,
  mensagem,
  listaMotivo,
  observacao,
  externo,
}) {
  return (
    <div className="mensagem-content">
      <div
        className={
          'row item-mensagem ' +
          (origem === 1 ? 'msg-vendedor ' : 'msg-empresa ')
        }
      >
        <div className="col-lg-12 texto-mensagem">
          <div className="topo">
            {origem === 1 ? (
              <>
                <div
                  className={'icon-perfil ' + (!externo ? 'anotacoes ' : '')}
                >
                  <IconMsgVendedor />
                </div>
                <h4>{remetente}</h4>{' '}
              </>
            ) : origem === 3 ? (
              <>
                <div className="novo-fluxo">
                  <h4>{`${remetente} (enviada para Fila Pagamento pela esteira de crédito)`}</h4>{' '}
                  <div
                    className={'icon-perfil ' + (!externo ? 'anotacoes ' : '')}
                  >
                    <IconDoubleArrow />
                  </div>
                </div>
              </>
            ) : (
              <>
                <h4>
                  {origem === 0 ? 'Crefaz (Decisão automática)' : remetente}
                </h4>{' '}
                <div
                  className={'icon-perfil ' + (!externo ? 'anotacoes ' : '')}
                >
                  {
                  // eslint-disable-next-line
                  remetente && remetente == 'rotina.automatica' ? <IconMsgEmpresaCrefazRotinaAutomatica /> : <IconMsgEmpresaCrefaz/>
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={
          'row item-mensagem ' +
          (origem === 1 ? 'msg-vendedor ' : 'msg-empresa ')
        }
      >
        <div className="col-3 info-autor">
          <div className="data-hora">
            <p className="horario">
              {horario ? horario : moment(data).format('HH:mm')}
            </p>
            <p className="data">
              {moment(data).isValid() && data?.length > 5
                ? moment(data, 'DD/MM/YYYY').format('DD/MM/YYYY')
                : data}
            </p>
          </div>
        </div>
        <div className="col-9 texto-mensagem">
          <p>
            {mensagem}
            <br />
            {listaMotivo && listaMotivo.length > 1
              ? listaMotivo.map((item, indice) => {
                  return (
                    <>
                      {item.nome}
                      {indice < listaMotivo.length - 1 && <hr />}
                    </>
                  );
                })
              : listaMotivo && listaMotivo[0].nome}
            <br />
            <span>{observacao && <>Observação: {observacao}</>}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

function BodyConversationMessages({
  title,
  mode,
  proposta,
  cpf,
  listMessages,
  sendMessage,
  scrollToBottom,
  handleChangeTooltip,
  messagesEndRef,
  type,
  disabled = false,
  visible,
  origem,
  externo,
}) {
  useEffect(() => {
    if (mode === 'tooltip' && visible) {
      // o componente precisa desse timout para poder exibir o tooltip na tela ANTES de rolar as mensagens para baixo
      setTimeout(() => {
        scrollToBottom();
      }, 300);
    } else if (mode === 'screen') {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, listMessages, scrollToBottom]);

  const [form] = Form.useForm();

  const handleSendMessage = (data) => {
    moment.locale('pt-br');
    if (data.mensagem.trim()) {
      let message = {};
      if (type === 'chat') {
        message = {
          propostaId: proposta.id,
          origem: origem,
          mensagem: data.mensagem,
          ultimaMensagem: Math.max.apply(
            Math,
            listMessages.map((mensagem) => {
              return mensagem.id;
            }),
          ),
        };
      } else {
        message = {
          cpf: cpf || proposta.cliente.cpf,
          anotacao: data.mensagem,
        };
      }

      sendMessage(message);
    }

    form.resetFields();
  };

  return (
    <Card
      title={<Title label={title ? title : 'Central de Comunicação'} noLine />}
      className="ibox-conversation-messages"
    >
      {mode === 'tooltip' && (
        <Button onClick={() => handleChangeTooltip()} className="close-tooltip">
          <IconCloseTooltip />
        </Button>
      )}

      <div className="mensagens">
        <div className="list-mensagens">
          {listMessages?.map((mensagem) => {
            return (
              <MessageItem
                key={mensagem?.id}
                origem={mensagem?.origem}
                data={mensagem?.data}
                horario={mensagem?.horario}
                remetente={mensagem?.remetente || mensagem?.usuario}
                mensagem={mensagem?.mensagem || mensagem?.anotacao}
                listaMotivo={mensagem?.listaMotivo}
                observacao={mensagem?.observacao}
                propostaDecisaoId={mensagem?.propostaDecisaoId}
                externo={externo}
              />
            );
          })}
          <div ref={messagesEndRef} />
        </div>

        {!disabled && (
          <div className="form-row-mensagem form-textarea">
            <div className="col-12">
              <Form form={form} onFinish={handleSendMessage}>
                <Form.Item name="mensagem">
                  <TextArea placeholder="Escreva aqui sua mensagem" />
                </Form.Item>
                {type === 'chat' ? (
                  <Button
                    onClick={() => form.submit()}
                    className="btn-acao btn-enviar"
                  >
                    <IconSendButton />
                    Enviar
                  </Button>
                ) : (
                  <Button
                    onClick={() => form.submit()}
                    className="btn-acao btn-salvar"
                  >
                    <IconSaveButton />
                    Salvar
                  </Button>
                )}
              </Form>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

function ConversationMessages({
  classe = "",
  title,
  mode,
  proposta,
  cpf,
  listMessages,
  sendMessage,
  placementTooltip,
  type,
  fixedTooltip,
  children,
  disabled = false,
  origem,
  externo = true,
}) {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  };

  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const handleChangeTooltip = () => {
    setVisibleTooltip(!visibleTooltip);
    scrollToBottom();
  };
  
  return (
    <>
      {mode === 'screen' ? (
        // Quando o componente for ser exibido direto na tela
        <BodyConversationMessages
          title={title}
          mode={mode}
          proposta={proposta}
          cpf={cpf}
          listMessages={listMessages}
          sendMessage={sendMessage}
          scrollToBottom={scrollToBottom}
          handleChangeTooltip={handleChangeTooltip}
          messagesEndRef={messagesEndRef}
          type={type}
          disabled={
            proposta?.situacaoId < 0 ||
            proposta?.situacaoId === 1 ||
            proposta?.situacaoId === 2 ||
            proposta?.situacaoId === 16 ||
            disabled
              ? true
              : false
          }
          origem={origem}
          externo={externo}
        />
      ) : (
        // Em caso de Tooltip
        <Tooltip
          trigger="click"
          placement={placementTooltip}
          overlayClassName={
            'card-tooltip-messages ' + (fixedTooltip ? 'card-fixed' : '') + classe
          }
          visible={visibleTooltip}
          onVisibleChange={handleChangeTooltip}
          title={
            <BodyConversationMessages
              title={title}
              mode={mode}
              proposta={proposta}
              cpf={cpf}
              listMessages={listMessages}
              sendMessage={sendMessage}
              scrollToBottom={scrollToBottom}
              handleChangeTooltip={handleChangeTooltip}
              messagesEndRef={messagesEndRef}
              type={type}
              disabled={
                proposta?.situacaoId < 0 ||
                proposta?.situacaoId === 1 ||
                proposta?.situacaoId === 2 ||
                proposta?.situacaoId === 16 ||
                disabled
                  ? true
                  : false
              }
              visible={visibleTooltip}
              origem={origem}
              externo={externo}
            />
          }
        >
          {children}
        </Tooltip>
      )}
    </>
  );
}

export default ConversationMessages;
