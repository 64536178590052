import React from 'react';
import './styles.scss';
import SemiCircleChart from '../SemiCircleChart';

import { ReactComponent as AddressCardIcon } from '../../assets/images/icons/address-card.svg';

const CardChartInfo = ({ title, subtitle, value, date, icon }) => {
  return (
    <div className="card-chart">
      <div className="card-chart-title">{title}</div>
      <div className="card-chart-subtitle">
        {icon && <AddressCardIcon />}
        <b className="ml-1">{subtitle}</b>
      </div>
      <SemiCircleChart value={value} />
      <span className="date">{date}</span>
    </div>
  );
};

export default CardChartInfo;
