import React, { Fragment, useState } from 'react';

import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Title from '../../../components/Title';

import Filter from './filter';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';

import './styles.scss';

function Toolbar() {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-8">
          <Title label="Acompanhamento de Credenciamento" />
        </div>

        <div className="col-lg-4 d-flex justify-content-end toolbar-acompanhamento-credenciamento">
          <Button className="mb-1" type="button" variant="white">
            <Icon variant="download" />
            <span className="ml-2">Excel</span>
          </Button>

          <span className="p-1"></span>

          <Button className="mb-1" type="button" variant="white">
            <Icon variant="download" />
            <span className="ml-2">PDF</span>
          </Button>

          <span className="p-1"></span>

          <Button
            type="button"
            variant="gray"
            onClick={() => handleShowFilter()}
          >
            <FilterIcon className="filter-icon" />
            <span>FILTRAR</span>
          </Button>
        </div>
      </div>

      {showFilter && (
        <div className="row">
          <div className="col-lg-12">
            <Filter />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Toolbar;
