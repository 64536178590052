import * as React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import emailRemoveIcon from '../../assets/images/icons/close-item.svg';
import './styles.scss';

function InputMultiEmail({emails, setEmails, formik, ...props }) {
  return (
      <ReactMultiEmail
        {...props}
        className={"containerMultiEmailControleProposta ant-input " + props?.className}
        emails={emails}
        onChange={(emails) => {
          formik.setFieldValue('emails', emails);
          setEmails(emails);
        }}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span
                data-tag-handle
                onClick={() => removeEmail(index)}
              >
                <img
                  src={emailRemoveIcon}
                  className="remove-multi-email-input-icon"
                  alt="Ícone de remover email digitado"
                />
              </span>
            </div>
          );
        }}
      />
  );
}

export default InputMultiEmail;
