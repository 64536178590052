import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import useInspinia from '../../hooks/useInspinia';

import Main from '../../layouts/Main';
import PageHeading from '../../components/PageHeading';
import Content from '../../components/Content';
import Card from '../../components/Card';
import Title from '../../components/Title';

import CorrespondenteProvider from '../../contexts/CorrespondenteContext';

import CorrespondenteSelecao from './CorrespondenteSelecao';
import CorrespondenteForm from './CorrespondenteForm';

const Header = <Title label="Solicitação de Credenciamento" />;

function Correspondente() {
  useInspinia();

  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Comercial</Breadcrumb.Item>
          <Breadcrumb.Item>Correspondente</Breadcrumb.Item>
          <Breadcrumb.Item>Credenciamento</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <Card title={Header}>
          <CorrespondenteProvider>
            <CorrespondenteSelecao />
            <CorrespondenteForm />
          </CorrespondenteProvider>
        </Card>
      </Content>
    </Main>
  );
}

export default Correspondente;
