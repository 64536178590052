import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-text-mask';
import { Input, Select } from 'antd';

import { cpfMask } from '../../../utils/masks';

import { ImpressaoDocumentosContext } from '../../../contexts/ImpressaoDocumentosContext';

import Card from '../../../components/Card';
import Button from '../../../components/Button';
import Title from '../../../components/Title';
import Overlay from '../../../components/Overlay';
import Tooltip from '../../../components/tooltip';

import { ReactComponent as Printer } from '../../../assets/images/icons/print.svg';
import { ReactComponent as BackIcon } from '../../../assets/images/icons/back.svg';

import './styles.scss';

const { Option } = Select;

function ImpressaoDocumentos() {
  const history = useHistory();

  const {
    loading,
    produtos,
    proposta,
    produtoSelecionado,
    documentos,
    boletos,
    imprimirDocumento,
    imprimirBoleto,
    setProdutoSelecionado,
  } = useContext(ImpressaoDocumentosContext);

  const voltar = () => {
    history.push('/acompanhamento-proposta');
  };

  return (
    <Overlay active={loading}>
      <Card
        title={<Title label="Impressão de documentos" />}
        rightBar={
          <Button
            type="button"
            variant="orange"
            className="btn-xs-block"
            onClick={() => voltar()}
          >
            <BackIcon />
          </Button>
        }
      >
        <div className="impressao-documentos">
          {proposta ? (
            <div className="form-row">
              <div className="col-lg-4">
                <label>Produto</label>
                <Input value={proposta?.produtoNome} disabled={true} />
              </div>
              <div className="col-lg-4">
                <label>N° proposta</label>
                <Input disabled="true" value={proposta?.id} />
              </div>
              <div className="col-lg-4">
                <label>CPF</label>
                <InputMask
                  mask={cpfMask}
                  value={proposta?.cpf}
                  placeholder="Ex.: 000.000.000-00"
                  className="ant-input"
                  disabled
                />
              </div>
            </div>
          ) : (
            <div className="form-row">
              <div className="col-lg-4">
                <label>Produto</label>
                <Select
                  id="produto"
                  name="produto"
                  value={produtoSelecionado}
                  onChange={(value) => setProdutoSelecionado(value)}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {produtos?.map((produto) => (
                    <Option value={produto.id}>{produto.nome}</Option>
                  ))}
                </Select>
              </div>
            </div>
          )}

          <div class="btn-docs-group">
            <div className="row">
              {proposta && (
                <>
                  {documentos.length ? (
                    <>
                      <div className="col-lg-6">
                        <span>Via preenchida</span>
                        {documentos.length > 0 && (
                          <>
                            {documentos.map((documento) => (
                              <div className="row">
                                <div className="col-lg-12">
                                  <Button
                                    type="default"
                                    onClick={() =>
                                      imprimirDocumento({
                                        tipoDocumento: documento.Tipo,
                                        propostaId: proposta?.id,
                                        viaPreenchida: true
                                      })
                                    }
                                    className="btn-doc btn-doc-blue"
                                  >
                                    <Printer />
                                    {documento.Nome}
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {boletos.exibeBoleto && (
                          <div className="row">
                            <div className="col-lg-12">
                              {boletos?.linkBoleto ? (
                                <Button
                                  type="default"
                                  className="btn-doc btn-doc-blue"
                                  onClick={() =>
                                    imprimirBoleto(boletos?.linkBoleto)
                                  }
                                >
                                  <Printer />
                                  DOWNLOAD DOS BOLETOS
                                </Button>
                              ) : (
                                <Tooltip
                                  placement="bottom"
                                  className="tooltip-info"
                                  overlayClassName="tooltip-proposta-credito"
                                  color="#435361"
                                  title="Formalização de contrato em andamento, aguarde para realizar o download dos boletos."
                                >
                                  <span className="btn btn-doc-blue-disabled btn-doc ">
                                    <Printer />
                                    DOWNLOAD DOS BOLETOS
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    (produtoSelecionado || proposta) && (
                      <p className="mt-20">
                        Nenhum documento encontrado para esse produto.
                        <br />
                      </p>
                    )
                  )}
                </>
              )}
              {documentos.length > 0 && (
                <>
                  <div className="col-lg-6">
                    <span>Via em branco</span>
                    {documentos.map((documento) => (
                      <div className="row">
                        <div className="col-lg-12">
                          <Button
                            type="default"
                            onClick={() =>
                              imprimirDocumento({
                                tipoDocumento: documento.Tipo,
                                produtoId:
                                  produtoSelecionado || proposta?.produtoId,
                                propostaId: proposta?.id || "",
                                viaPreenchida: false
                              })
                            }
                            className="btn-doc btn-doc-white"
                          >
                            <Printer />
                            {documento.Nome}
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}

export default ImpressaoDocumentos;
