import React from 'react';

import { Form } from 'formik-antd';

import FormUploads from './ArquivoFormUploads';

import './styles.scss';

function ArquivoForm() {
  return (
    <>
      <div className="form-row">
        <div className="col-lg-12">
          <Form.Item name="anexos" label="">
            <FormUploads />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default ArquivoForm;
