import { object, string, number, mixed, array, lazy } from 'yup';
import '../../utils/phoneSchema';

export const validationSchema = object().shape({
  cliente: object().shape({
    nome: string().required('Obrigatório informar nome').nullable(),
    nascimento: mixed().required('Obrigatório informar data de nascimento'),
    rg: string().required('Obrigatório informar número do RG').nullable(),
    rgUfId: number().required('Obrigatório informar UF do RG').nullable(),
    rgEmissor: string()
      .required('Obrigatório informar emissor do RG')
      .nullable(),
    rgEmissao: mixed().required('Obrigatório informar data do RG'),
    sexo: number().required('Obrigatório informar o sexo').nullable(),
    estadoCivil: number()
      .required('Obrigatório informar estado civil')
      .nullable(),
    nacionalidadeId: number()
      .required('Obrigatório informar nacionalidade')
      .nullable(),
    naturalidadeUfId: mixed().when('nacionalidadeId', (nacionalidadeId) => {
      if (nacionalidadeId === 1) {
        return mixed()
          .required('Obrigatório informar UF da naturalidade')
          .nullable();
      }
    }),
    naturalidadeCidadeId: mixed().when('nacionalidadeId', (nacionalidadeId) => {
      if (nacionalidadeId === 1) {
        return mixed()
          .required('Obrigatório informar cidade da naturalidade')
          .nullable();
      }
    }),
    grauInstrucaoId: number()
      .required('Obrigatório informar grau de instrução')
      .nullable(),
    nomeMae: string().required('Obrigatório informar nome da mãe').nullable(),
    nomeConjuge: string().when('estadoCivil', (estadoCivil) => {
      if (estadoCivil === 1) {
        return string()
          .required('Obrigatório informar nome do cônjuge')
          .nullable();
      } else {
        return string().nullable();
      }
    }),
  }),

  contato: object().shape({
    telefone: string()
      .validateCellphone('Número de telefone inválido, favor tentar novamente.')
      .nullable(),
    telefoneFixo: string()
      .validateLandline('Número de telefone inválido, favor tentar novamente.')
      .nullable(),
    telefoneExtra: lazy(() => {
      return array()
        .of(
          object().shape({
            telefone: string()
              .validatePhone(
                'Número de telefone inválido, favor tentar novamente.',
              )
              .required('O telefone deve ser preenchido')
              .nullable(),
            funcao: mixed()
              .required('O tipo de referência deve ser preenchido')
              .nullable(),
          }),
        )
        .nullable();
    }),
  }),

  endereco: object().shape({
    cep: string().required('Obrigatório informar CEP').nullable(),
    logradouro: string().required('Obrigatório informar logradouro').nullable(),
    bairro: string().required('Obrigatório informar bairro').nullable(),
    cidadeId: number().required('Obrigatório informar cidade').nullable(),
    ufId: number().required('Obrigatório informar estado').nullable(),
    numero: number().required('Obrigatório informar número').nullable(),
  }),

  bancario: object().shape({
    bancoId: number().required('Obrigatório informar banco').nullable(),
    agencia: string().required('Obrigatório informar agência').nullable(),
    digito: string()
      .matches(/[0-9Xx]/, 'Informar apenas números ou X')
      .nullable(),
    numero: string().required('Obrigatório informar número').nullable(),
    conta: string().required('Obrigatório informar conta').nullable(),
    tipoConta: number()
      .required('Obrigatório informar tipo de conta')
      .nullable(),
    tempoConta: number()
      .required('Obrigatório informar tempo de conta')
      .nullable(),
  }),

  profissional: object().shape({
    empresa: string().required('Obrigatório informar empresa').nullable(),
    profissaoId: number().required('Obrigatório informar profissão').nullable(),
    tempoEmpregoAtual: string()
      .required('Obrigatório informar tempo de emprego atual')
      .nullable(),
  }),

  referencia: lazy(() => {
    return array()
      .of(
        object().shape({
          telefone: string()
            .required('O telefone deve ser preenchido')
            .nullable(),
          grau: mixed()
            .required('O tipo de referência deve ser preenchido')
            .nullable(),
        }),
      )
      .nullable();
  }),
});
