import React, { createContext, useState, useEffect, useCallback } from 'react';

import {
  listarProdutosSimulacao,
  simularProposta,
} from '../services/simulacaoService';
import { buscarParametro } from '../services/parametroService';

export const SimulacaoContext = createContext({});

function SimulacaoProvider({ children }) {
  const [produtos, setProdutos] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [parametroObservacao, setParametroObservacao] = useState('');
  const [tipoSimulacao, setTipoSimulacao] = useState(false);
  const [simulacao, setSimulacao] = useState([]);
  const [selectedRows, setSelectedRow] = useState([]);
  const [parcelaMaxima, setParcelaMaxima] = useState();

  const fetchProdutos = useCallback(async () => {
    const data = await listarProdutosSimulacao();

    setProdutos(data);
  }, []);

  const solicitarSimulacao = async (tipo, payload) => {
    const data = await simularProposta(tipo, payload);
    if (data) {
      let valorMaximo = produtos?.find(
        (item) => item.id === payload.produtoId,
      ).valorMaximo;
      if (tipo) {
        setSimulacao(
          data.filter((item) => {
            return item.solicitado <= valorMaximo;
          }),
        );
      } else {
        setSimulacao(data);
      }
    }
    setShowOverlay(false);
    return data;
  };

  const fetchParametroOservacao = async () => {
    const parametroObservacao = 'OBSERVACAO_SIMULADOR_PROPOSTA';

    const { valor } = await buscarParametro(parametroObservacao);
    setParametroObservacao(valor);
  };

  useEffect(() => {
    fetchProdutos();
    fetchParametroOservacao();
  }, [fetchProdutos]);

  return (
    <SimulacaoContext.Provider
      value={{
        solicitarSimulacao,
        produtos,
        simulacao,
        setSimulacao,
        showOverlay,
        setShowOverlay,
        tipoSimulacao,
        setTipoSimulacao,
        parametroObservacao,
        selectedRows,
        setSelectedRow,
        parcelaMaxima,
        setParcelaMaxima,
      }}
    >
      {children}
    </SimulacaoContext.Provider>
  );
}

export default SimulacaoProvider;
