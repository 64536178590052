import { Tooltip } from 'antd';
import Table from '../FullTable';
import { columnsReconsulta } from './columnsReconsulta';
import "./styles.scss"

export const TooltipReconsulta = ({data, children, ...props}) => {
  return (
    <Tooltip
      placement="top"
      className="tooltip-info"
      overlayClassName="tooltip-proposta-credito tooltip-reconsulta"
      color="#435361"
      title={
        <div className="outsideBorder">
          <div className="tabelaHistorico">
            <Table
              data={data}
              columns={columnsReconsulta}
              pagination={false}
              filter={false}
            />
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};
