import React, { useContext } from 'react';
import moment from 'moment';

import { Formik } from 'formik';
import { Form } from 'formik-antd';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Modal from '../../../components/Modal';

import { currencyFormat } from '../../../utils/numberFormatter';
import { SimulacaoContext } from '../../../contexts/SimulacaoContext';

import { validationSchema } from './validationSchema';

import FormLayout from './FormLayout';

import initialValues from './initialValues';

import './styles.scss';

function SimulacaoForm() {
  const {
    solicitarSimulacao,
    setShowOverlay,
    parametroObservacao,
    produtos,
    parcelaMaxima,
  } = useContext(SimulacaoContext);

  return (
    <Card title={<Title label="Simulador de Propostas" />}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        //        validateOnBlur={false}
        //        validateOnChange={true}
        onSubmit={async (data) => {
          const {
            floatValorSolicitado,
            tipoCalculo,
            produtoId,
            convenioId,
            tabelaJurosId,
            dataVencimento,
          } = data;

          const valorMaximo = produtos?.find(
            (item) => item.id === produtoId,
          ).valorMaximo;

          if (!floatValorSolicitado) {
            Modal('', 'O valor solicitado é obrigatório', 'warning');
          } else if (
            (tipoCalculo === 0 && floatValorSolicitado > valorMaximo) ||
            (tipoCalculo === 1 && floatValorSolicitado > parcelaMaxima)
          ) {
            Modal(
              '',
              `Valor da simulação está acima do valor máximo de ${currencyFormat(
                tipoCalculo ? parcelaMaxima : valorMaximo,
              )} permitido para o produto`,
              'warning',
            );
          } else {
            data.valorSolicitado = floatValorSolicitado;

            setShowOverlay(true);

            const payload = {
              produtoId,
              convenioId,
              tabelaJurosId,
              vencimento: moment(dataVencimento).format('YYYY-MM-DD'),
            };

            if (tipoCalculo === 0) {
              payload.valorContrato = floatValorSolicitado;
            } else {
              payload.valorPrestacao = floatValorSolicitado;
            }

            await solicitarSimulacao(tipoCalculo, payload);

            setShowOverlay(false);
          }
        }}
      >
        {({ setFieldValue, values, setFieldTouched, submitForm }) => (
          <Form layout="vertical">
            <FormLayout
              setFieldValue={setFieldValue}
              values={values}
              setFieldTouched={setFieldTouched}
              submitForm={submitForm}
            />
          </Form>
        )}
      </Formik>

      <p className="observacao">{parametroObservacao}</p>
    </Card>
  );
}
export default SimulacaoForm;
