import React from 'react';

import moment from 'moment';
import { cpfFormatter } from '../../../utils/textFormatter';

import Icon from '../../../components/Icon';
import './styles.scss';

export const columns = [
  {
    Header: 'Analista',
    accessor: 'analistaNome',
    style: {
      minWidth: 150,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Nº Proposta',
    accessor: 'id',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    style: {
      minWidth: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>{cpfFormatter(value)}</div>
    ),
  },
  {
    Header: 'Produto',
    accessor: 'produtoNome',
    style: {
      minWidth: 100,
      textAlign: 'center',
    },
    Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
  },
  {
    Header: 'Data',
    accessor: 'dataProposta',
    style: {
      minWidth: 120,
      width: 120,
      textAlign: 'center',
    },
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: (
      <span>
        <Icon variant="clock" className="icon-title" /> Espera
      </span>
    ),
    accessor: 'tempoEspera',
    style: {
      minWidth: 120,
      width: 120,
      textAlign: 'center',
    },

    Cell: (e) => {
      var color = 'white';
      var textColor = '#686B6D';
      var iconName = 'icon-table-text';
      if (e.row.original.tempoEsperaCor) {
        color = e.row.original.tempoEsperaCor;
        textColor = 'white';
        iconName = 'icon-table-white';
      }

      return (
        <div
          className="Container ml-3"
          style={{
            background: color,
            width: 'auto',
            minWidth: '75px',
            display: 'inline-block',
            fontSize: '12px',
            // height: 20,
            textAlign: 'center',
            borderRadius: 2.5,
            border: '1px solid #DEE2E6',
            color: textColor,
            fontWeight: 400,
            padding: '1px 6px',
          }}
        >
          <Icon variant="clock" className={iconName} />
          {e.row.values.tempoEspera}
        </div>
      );
    },
  },
];
