import { object, string, mixed, number } from 'yup';
import * as Yup from 'yup';

import validaCNPJ from '../../utils/validaCnpj';
import validaCep from '../../utils/validaCep';

Yup.addMethod(Yup.string, 'validateCNPJ', function (errorMessage) {
  return this.test(`test-cnpj`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }

    return validaCNPJ(value) || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'validateCEP', function (errorMessage) {
  return this.test(`test-cep`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }

    return validaCep(value) || createError({ path, message: errorMessage });
  });
});

const validationsSchemas = [
  object({
    cnpj: string()
      .required('O CNPJ é obrigatório')
      .validateCNPJ('CNPJ inválido'),
    nomeFantasia: string().required('O Nome Fantasia é obrigatório'),
    funcionarios: number().when('tipo', {
      is: (val) => val === 2,
      then: number()
        .min(1, 'O número de funcionários é obrigatório')
        .typeError('Apenas números'),
    }),
  }),

  object({
    cep: string()
      .validateCEP('CEP inválido, favor preencher novamente.')
      .required('O CEP é obrigatório'),
    logradouro: string().required('O logradouro é obrigatório'),
    complemento: string(),
    bairro: string().required('O bairro é obrigatório'),
    cidadeId: string().required('A cidade é obrigatória'),
    ufId: string().required('O estado é obrigatório'),
    numero: mixed().required('O número é obrigatório'),
  }),

  object().shape(
    {
      contato: string().required('O contato é obrigatório'),
      telefone: string().validateLandline(
        'Número de telefone inválido, favor tentar novamente.',
      ),
      celular: string()
        .validateCellphone(
          'Número de celular inválido, favor tentar novamente.',
        )
        .when('telefoneFixo', {
          is: (telefoneFixo) => !telefoneFixo || telefoneFixo.length === 0,
          then: string()
            .required('Um dos telefones deve ser preenchido')
            .nullable(),
          otherwise: string().nullable(),
        }),
      email: string()
        .required('O E-mail é obrigatório')
        .email('Deve ser informado um E-mail válido'),
    },
    [['telefone', 'celular']],
  ),

  object({
    dataVisita: mixed().when('visita', {
      is: true,
      then: string().required('A data da visita é obrigatória'),
      otherwise: null,
    }),
    horaVisita: mixed().when('visita', {
      is: true,
      then: string().required('A hora da visita é obrigatória'),
      otherwise: null,
    }),
    contatoVisita: mixed().when('visita', {
      is: true,
      then: string().required('O contato da visita é obrigatório'),
      otherwise: null,
    }),
  }),
];

export default validationsSchemas;
