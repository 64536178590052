import React, { useState, useContext } from 'react';

import FilterAnalitico from './FilterAnalitico';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/images/icons/download.svg';

import { RelatorioAnaliticoContext } from '../../../contexts/RelatorioAnaliticoContext';
import ModalComponent from '../../../components/Modal';

function Header() {
  const [showHideFilter, setShowHideFilter] = useState(true);

  const handleShowFilter = () => {
    setShowHideFilter(!showHideFilter);
  };

  const { exportarPropostasCsv, exportarPropostasPdf } = useContext(
    RelatorioAnaliticoContext,
  );

  const imprimirPdf = async () => {
    const obj = await exportarPropostasPdf();
    if (!obj?.itens) {
      ModalComponent(
        '',
        'Não existe nenhuma proposta com os filtros selecionados.',
        'warning',
      );
      return;
    }

    sessionStorage.setItem(
      'relatorioVisaoAnaliticaImpressao',
      JSON.stringify(obj),
    );
    window.open('/visao-analitica-pdf', '_blank');
  };

  return (
    <>
      <div
        className="form-row justify-content-between mb-3 mt-1"
        style={{ alignItems: 'flex-end' }}
      >
        <div className="titulo ml-1">
          <Title label="Painel Analítico" noLine></Title>
        </div>
        <div className="Container mr-1">
          <Button
            className="toolbar-button px-3"
            type="button"
            variant="white"
            onClick={() => imprimirPdf()}
            async={true}
          >
            <DownloadIcon /> PDF
          </Button>

          <span style={{ marginRight: '10px' }} />

          <Button
            className="toolbar-button px-3"
            type="button"
            variant="white"
            onClick={() => exportarPropostasCsv()}
            async={true}
          >
            <DownloadIcon /> CSV
          </Button>

          <span style={{ marginRight: '10px' }} />

          <Button
            type="button"
            variant="gray"
            onClick={() => handleShowFilter()}
          >
            <FilterIcon className="filter-icon" />
            <span>FILTRAR</span>
          </Button>
        </div>
      </div>
      <div className={showHideFilter ? '' : 'd-none'}>
        <FilterAnalitico />
      </div>
    </>
  );
}

export default Header;
