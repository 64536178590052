import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Collapse, Modal, Input, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import Card from '../../components/Card';
import Field from '../../components/Field';
import Title from '../../components/Title';
import ListItem from '../../components/ListItem';
import ListButton from '../../components/ListButton';
import IconEdit from '../../components/EditIcon';

import { ParametroContext } from '../../contexts/ParametroContext';

const { Panel } = Collapse;

const Header = <Title label="Central de Parâmetros" />;

function ParametroList() {
  const [visible, setVisible] = useState(false);

  const {
    gruposParametro,
    parametros,
    editarParametro,
    grupoParametro,
    salvarGrupoParametro,
    editarGrupoParametro,
  } = useContext(ParametroContext);

  const {
    values,
    errors,
    resetForm,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: grupoParametro,
    validationSchema: Yup.object().shape({
      nome: Yup.string().required('O nome é obrigatório'),
    }),
    onSubmit: async (data) => {
      const response = await salvarGrupoParametro(data);
      if (response.success) {
        resetForm();
        setVisible(false);
      } else {
        message.error(response.messages[0]);
      }
    },
  });

  const genExtra = (id) => {
    return (
      <IconEdit onClick={(e) => {
        e.stopPropagation();
        editarGrupoParametro(id);
        showModal();
      }} />
    );
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    resetForm();
    setVisible(false);
  };

  useEffect(() => {
    if (grupoParametro.id > 0) {
      const fields = ['id', 'nome', 'ordem'];
      fields.forEach((field) =>
        setFieldValue(field, grupoParametro[field], false),
      );
    }
  }, [grupoParametro, setFieldValue]);

  return (
    <Card title={Header}>
      <div>
        <Collapse
          expandIconPosition="left"
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              rotate={isActive ? 90 : 0}
              style={{ color: '#ff7817' }}
            />
          )}
        >
          {gruposParametro.map((item) => {
            let id = item.id;
            return (
              <Panel header={item.nome} key={item.id} extra={genExtra(item.id)}>
                {parametros.length > 0 &&
                  parametros.map((param) => {
                    if (id === param.parametroGrupoId) {
                      return (
                        <ListItem
                          key={param.id}
                          label={param.nome}
                          value={param.valor}
                          type={param.tipo}
                          onClick={() => editarParametro(param.codigo)}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                }
                {parametros.filter((item) => id === item.parametroGrupoId).length === 0 &&
                  <div className="text-warning ml-4">Não existe parâmetros cadastrados para esse grupo!</div>
                }
              </Panel>
            );
          })
          }
        </Collapse>

        <ListButton onClick={showModal} />

        <Modal
          title="Grupo de Parâmetros"
          visible={visible}
          zIndex={9999}
          onCancel={handleCancel}
          okButtonProps={{ form: 'formModal', htmlType: 'submit' }}
        >
          <form id="formModal" onSubmit={handleSubmit}>
            <Field label="Nome" message={errors.nome}>
              <input type="hidden" name="id" />
              <input type="hidden" name="ordem" />
              <Input name="nome" value={values.nome} onChange={handleChange} />
            </Field>
          </form>
        </Modal>
      </div>
    </Card>
  );
}

export default ParametroList;
