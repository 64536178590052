import React, { Fragment, useContext, useState, useEffect } from 'react';

import { Button } from 'antd';
import { Field } from 'formik';
import { Form, Select } from 'formik-antd';
import Input from '../../../components/SimpleInput';

import InputPhone from '../../../components/InputPhone';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import validaPhone from '../../../utils/validaPhone';

const { Option } = Select;

function DadosContatoForm({ formik, errorDigitalTelefoneCelular, disabledFields }) {
  const { setFieldTouched, setFieldValue, touched, values, errors } = formik;

  const {
    telefoneExtra,
    setTelefoneExtra,
    tipoTelefone,
    validationField,
    editableField,
    liberaTelefoneExtraPreencher,
    setLiberaTelefoneExtraPreencher,
  } = useContext(PropostaCreditoContext);

  const [statusEmail, setStatusEmail] = useState('');
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusTelefoneExtra, setStatusTelefoneExtra] = useState([]);
  const [statusTelefoneAdicional, setStatusTelefoneAdicional] = useState([]);
  const [telefonesAdicionais, setTelefonesAdicionais] = useState([]);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const valuesTelefonesExtras = {
    telefone: '',
    tipoTelefone: 0,
  };

  const handleAddTelefoneExtra = () => {
    const indice = telefoneExtra.length;

    if (telefoneExtra) {
      setTelefoneExtra((state) => [...state, { ...valuesTelefonesExtras }]);
    } else {
      setTelefoneExtra([valuesTelefonesExtras]);
    }
    setFieldTouched(`contatos.contato.telefoneExtra.${indice}.telefone`, true);
  };

  const handleAddTelefoneAdicional = () => {
    const indice = telefoneExtra.length;
    if (telefonesAdicionais) {
      setTelefonesAdicionais((state) => [
        ...state,
        { ...valuesTelefonesExtras },
      ]);
    } else {
      setTelefonesAdicionais([valuesTelefonesExtras]);
    }
    setFieldTouched(`contatos.telefoneAdicional.${indice}.telefone`, true);
  };

  const [mensagemErrorCelularObrigatorio, setMensagemErrorCelularObrigatorio] =
    useState('');
  const AddCelularObrigatorioModalidadeDigital = () => {
    handleAddTelefoneExtra();
    setMensagemErrorCelularObrigatorio(
      'Telefone celular obrigatório para a modalidade digital.',
    );
  };

  useEffect(() => {
    if (errorDigitalTelefoneCelular === true) {
      AddCelularObrigatorioModalidadeDigital();
      // eslint-disable-next-line
      errorDigitalTelefoneCelular = false;
      setLiberaTelefoneExtraPreencher(true);
    }
  }, [errorDigitalTelefoneCelular]);

  const handleRemoveTelefoneExtra = (index) => {
    setTelefoneExtra((newArray) => newArray.filter((_, idx) => idx !== index));
    values.contatos.contato.telefoneExtra.splice(index, 1);
    const newStateArray = [...statusTelefoneExtra];
    newStateArray.splice(index, 1);
    setStatusTelefoneExtra(newStateArray);
    setFieldTouched(`contatos.contato.telefoneExtra.${index}.telefone`, false);
    setFieldTouched(
      `contatos.contato.telefoneExtra.${index}.tipoTelefone`,
      false,
    );
  };

  const handleRemoveTelefoneAdicional = (index) => {
    setTelefonesAdicionais((newArray) =>
      newArray.filter((_, idx) => idx !== index),
    );
    if (values.contatos.telefoneAdicional) {
      values.contatos.telefoneAdicional.splice(index, 1);
      const newStateArray = [...statusTelefoneAdicional];
      newStateArray.splice(index, 1);
      setStatusTelefoneAdicional(newStateArray);
      setFieldTouched(`contatos.telefoneAdicional.${index}.telefone`, false);
      setFieldTouched(
        `contatos.telefoneAdicional.${index}.tipoTelefone`,
        false,
      );
    }
  };

  const handleTelefoneExtraType = (value, indice) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(
        `contatos.contato.telefoneExtra.${indice}.tipoTelefone`,
        type,
      );
    } else {
      setFieldValue(
        `contatos.contato.telefoneExtra.${indice}.tipoTelefone`,
        '',
      );
    }
  };

  const handleTelefoneAdicionalType = (value, indice) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(`contatos.telefoneAdicional.${indice}.tipoTelefone`, type);
    } else {
      setFieldValue(`contatos.telefoneAdicional.${indice}.tipoTelefone`, '');
    }
  };

  const handleEmail = () => {
    setFieldTouched('contatos.contato.email');
  };

  useEffect(() => {
    const newStateArray = [...statusTelefoneExtra];

    values?.contatos?.contato?.telefoneExtra?.forEach((telefone, i) => {
      setFieldValue(
        `contatos.contato.telefoneExtra.${i}.telefone`,
        telefone.telefone,
      );

      telefoneExtra[i] = {
        telefone: telefone.telefone,
        tipoTelefone: telefone.tipoTelefone,
      };
    });

    if (
      errors?.contatos?.contato?.telefoneExtra &&
      touched?.contatos?.contato?.telefoneExtra
    ) {
      values?.contatos?.contato?.telefoneExtra?.forEach((_, i) => {
        if (
          touched?.contatos?.contato?.telefoneExtra[i]?.telefone &&
          errors?.contatos?.contato?.telefoneExtra[i]?.telefone ===
          'Número de telefone inválido, favor tentar novamente.'
        ) {
          newStateArray[i] = 'warning';
          setStatusTelefoneExtra(newStateArray);
        } else if (
          touched?.contatos?.contato?.telefoneExtra[i]?.telefone &&
          errors?.contatos?.contato?.telefoneExtra[i]?.telefone ===
          'O telefone deve ser preenchido'
        ) {
          newStateArray[i] = 'error';
          setStatusTelefoneExtra(newStateArray);
        } else {
          newStateArray[i] = '';
          setStatusTelefoneExtra(newStateArray);
        }
      });
    } else {
      setStatusTelefoneExtra(['']);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touched?.contatos?.contato?.telefoneExtra,
    errors?.contatos?.contato?.telefoneExtra,
    values?.contatos?.contato?.telefoneExtra,
  ]);

  const handleTelefoneType = (value) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(`contatos.contato.tipoTelefone`, type);
    } else {
      setFieldValue(`contatos.contato.tipoTelefone`, '');
    }
  };

  useEffect(() => {
    if (
      touched?.contatos?.contato &&
      errors?.contatos?.contato &&
      touched?.contatos?.contato.email &&
      errors?.contatos?.contato.email === 'Deve ser informado um e-mail válido'
    ) {
      setStatusEmail('warning');
    } else if (
      touched?.contatos?.contato &&
      errors?.contatos?.contato &&
      touched?.contatos?.contato.email &&
      errors?.contatos?.contato.email === 'Obrigatório informar E-mail'
    ) {
      setStatusEmail('error');
    } else {
      setStatusEmail('');
    }
  }, [errors?.contatos?.contato, touched?.contatos?.contato]);

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Dados de Contato do Cliente</div>
      </div>

      <div className="form-row">
        <div className="col-md-6 col-lg-6 col-xl-6">
          <Form.Item
            name="contatos.contato.email"
            label="E-mail"
            validateStatus={statusEmail}
            validate={(value) =>
              validationField(
                value,
                'contatos.contato.email',
                'E-mail',
                'email',
              )
            }
            hasFeedback
          >
            <Input
              name="contatos.contato.email"
              disabled={
                (acesso ? !editableField('contatos.contato.email') : true)
                || disabledFields
              }
              placeholder="mail@example.com"
              value={formik?.values?.contatos?.contato?.email}
              onBlur={(e) => {
                setFieldValue('contatos.contato.email', e.target.value);
                handleEmail();
              }}
              maxLength={100}
            />
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name={`contatos.contato.telefone`}
            label="Telefone"
            validateStatus={statusTelefone}
            validate={(value) => {
              let msgError = '';

              if (editableField('contatos.contato.telefone') && acesso) {
                if (touched?.contatos?.contato?.telefone) {
                  if (!value.replace(/\D/g, '')) {
                    msgError = 'O telefone deve ser preenchido';
                    setStatusTelefone('error');
                  } else if (value && !validaPhone(value)) {
                    msgError =
                      'Número de telefone inválido, favor tentar novamente.';
                    setStatusTelefone('warning');
                  } else {
                    msgError = '';
                    setStatusTelefone('');
                  }
                }
              }

              return msgError;
            }}
          >
            <Field name={`contatos.contato.telefone`}>
              {({ field }) => (
                <InputPhone
                  {...field}
                  disabled={
                    (acesso ? !editableField('contatos.contato.telefone') : true)
                    || disabledFields
                  }
                  onBlur={(e) => handleTelefoneType(e.target.value)}
                />
              )}
            </Field>
          </Form.Item>
        </div>

        <div className="col-md-3 col-lg-3 col-xl-3">
          <Form.Item
            name={`contatos.contato.tipoTelefone`}
            label="Tipo de telefone"
          >
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              name={`contatos.contato.tipoTelefone`}
              suffixIcon={''}
              disabled={true}
              className={`selectTipoTelefone ${!editableField('contatos.contato.tipoTelefone') &&
                'selectTipoTelefoneDisabled'
                }`}
            >
              {tipoTelefone.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="form-row">
        {telefoneExtra &&
          telefoneExtra.map((_, i) => (
            <Fragment key={i}>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contatos.contato.telefoneExtra.${i}.telefone`}
                  label="Telefone Extra"
                  validate={(value) => {
                    let msgError = '';
                    if (
                      mensagemErrorCelularObrigatorio !== '' &&
                      (!value || value === '')
                    ) {
                      msgError = mensagemErrorCelularObrigatorio;
                    }
                    return msgError;
                  }}
                >
                  <Field name={`contatos.contato.telefoneExtra.${i}.telefone`}>
                    {({ field }) => (
                      <InputPhone
                        {...field}
                        onBlur={(e) =>
                          handleTelefoneExtraType(e.target.value, i)
                        }
                        disabled={
                          (acesso
                            ? !editableField(`contatos.contato.telefone`) &&
                            !liberaTelefoneExtraPreencher
                            : true)
                          || disabledFields
                        }
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contatos.contato.telefoneExtra.${i}.tipoTelefone`}
                  label="Tipo do Telefone"
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    name={`contatos.contato.telefoneExtra.${i}.tipoTelefone`}
                    suffixIcon={''}
                    disabled={true}
                    className="selectTipoTelefone"
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                  >
                    {tipoTelefone.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-1 col-lg-1 col-xl-1">
                <Button
                  htmlType="button"
                  className="btnRemoveInputs"
                  disabled={
                    (acesso ? !editableField(`contatos.contato.telefone`) : true)
                    || disabledFields
                  }
                  onClick={() => handleRemoveTelefoneExtra(i)}
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
              <div className="col-md-5 col-lg-5 col-xl-5"></div>
            </Fragment>
          ))}
        {telefonesAdicionais &&
          telefonesAdicionais.map((_, i) => (
            <Fragment key={i}>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contatos.telefoneAdicional.${i}.telefone`}
                  label="Telefone Extra"
                  validate={(value) => {
                    let msgError = '';
                    if (
                      mensagemErrorCelularObrigatorio !== '' &&
                      (!value || value === '')
                    ) {
                      msgError = mensagemErrorCelularObrigatorio;
                    }
                    return msgError;
                  }}
                >
                  <Field name={`contatos.telefoneAdicional.${i}.telefone`}>
                    {({ field }) => (
                      <InputPhone
                        {...field}
                        onBlur={(e) =>
                          handleTelefoneAdicionalType(e.target.value, i)
                        }
                        disabled={
                          (acesso
                            ? !editableField(`contatos.contato.adicionar`)
                            : true)
                          || disabledFields
                        }
                      />
                    )}
                  </Field>
                </Form.Item>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  name={`contatos.telefoneAdicional.${i}.tipoTelefone`}
                  label="Tipo do Telefone"
                >
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    name={`contatos.telefoneAdicional.${i}.tipoTelefone`}
                    suffixIcon={''}
                    disabled={true}
                    className="selectTipoTelefone"
                    dropdownAlign={{
                      overflow: { adjustY: 0 },
                    }}
                  >
                    {tipoTelefone.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-1 col-lg-1 col-xl-1">
                <Button
                  htmlType="button"
                  className="btnRemoveInputs"
                  disabled={
                    (acesso ? !editableField(`contatos.contato.remover`) : true)
                    || disabledFields
                  }
                  onClick={() => handleRemoveTelefoneAdicional(i)}
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
              <div className="col-md-5 col-lg-5 col-xl-5"></div>
            </Fragment>
          ))}
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={
              editableField('contatos.contato.telefone')
                ? handleAddTelefoneExtra
                : handleAddTelefoneAdicional
            }
            disabled={
              (acesso ? !editableField(`contatos.contato.adicionar`) : true)
              || disabledFields
            }
          >
            <i className="fa fa-plus mr-2" /> Telefone
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosContatoForm };
