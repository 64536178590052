import React, { createContext, useState } from 'react';

export const ParametroProdutosContext = createContext({});

const intialParametroObrigatorioState = {
  id: null,
  listaDocumento: '',
  obrigatoriedade: true,
};

const initialParametroState = {
  ativo: true,
  codigoProduto: '',
  produto: '',
  grupoProduto: null,
  modalidade: null,
  taxaOperacional: true,
  tipoTaxa: null,
  tipoCobranca: null,
  taxas: null,
  diasAtraso: '',
  tipoTarifa: null,
  valorFixo: 0,
  conveniada: null,
  tabelaJuros: null,
  etapasCredito: null,
  regioes: null,
  formaRecebimento: null,
  valorLiberado: '',
  prazoMaximo: '',
  diaPrimeiroVencimento: null,
  mensagemContigencia: '',
  switchContingencia: true,
};

function ParametroProdutosProvider({ children }) {
  const [parametroProduto, setParametroProduto] = useState(
    initialParametroState,
  );
  const [
    parametroDocumentosObrigatorios,
    setParametroDocumentosObrigatorios,
  ] = useState(intialParametroObrigatorioState);
  const [activeInput, setActiveInput] = useState(false);

  return (
    <ParametroProdutosContext.Provider
      value={{
        setParametroProduto,
        parametroProduto,
        initialParametroState,
        parametroDocumentosObrigatorios,
        setParametroDocumentosObrigatorios,
        activeInput,
        setActiveInput,
      }}
    >
      {children}
    </ParametroProdutosContext.Provider>
  );
}

export default ParametroProdutosProvider;
