import React, { useState, useContext, useEffect } from 'react';
import swal from 'sweetalert';

import { RelatorioAnaliticoContext } from '../../../contexts/RelatorioAnaliticoContext';

import Card from '../../../components/Card';
import Title from '../../../components/Title';
import Table from '../../../components/BasicTable';

import { Tabs } from 'antd';
import { ReactComponent as ArrowLeft } from '../../../assets/images/icons/carousel-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/icons/carousel-arrow-right.svg';

import Cards from './Cards';
import Header from './Header';
import './styles.scss';
import { isNumber } from 'lodash';
import RelatorioAnaliticoColumns from './columns';
import { redistribuirPropostas } from '../../../services/FilaService';

function RelatorioAnalitico() {
  const [activeTab, setActiveTab] = useState('0');

  const {
    relatorioAnalitico,
    loading,
    total,
    pageCount,
    contexto,
    getCard,
    loadingCards,
    setSearch,
    fetchRelatorioAnalitico,
    filters,
    search,
    formik,
  } = useContext(RelatorioAnaliticoContext);

  const { TabPane } = Tabs;
  const [updateList, setUpdateList] = useState(false);
  const [firstRun, setFirstRun] = useState(true);

  const panes = contexto?.grupoProdutos?.map(({ id, nome }, index) => (
    <TabPane tab={nome} key={index} tabBarExtraContent produtoGrupoId={id} />
  ));

  const produtoGrupoId = panes?.[activeTab]?.props.produtoGrupoId || 0;

  useEffect(() => {
    if (isNumber(produtoGrupoId) && !firstRun) {
      formik.setFieldValue('produtoGrupoId', produtoGrupoId);
      setSearch((filtros) => {
        if (filtros) {
          filtros.searchButton = false;
        }
        return {
          ...filtros,
          produtoGrupoId,
        };
      });
    }
    setFirstRun(false);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, produtoGrupoId, setSearch]);

  const OperationsSlot = {
    left: (
      <ArrowLeft
        className="arrow-left"
        onClick={() => {
          let dec;
          dec = +activeTab - 1;

          if (dec >= 0) {
            setActiveTab(String(dec));
          }
        }}
      />
    ),
    right: (
      <ArrowRight
        className="arrow-right"
        onClick={() => {
          let sum;
          sum = +activeTab + 1;

          if (sum < panes.length) {
            setActiveTab(String(sum));
          }
        }}
      />
    ),
  };

  const redistribuirProposta = (propostaId) => {
    let objPropostas = {
      propostas: [propostaId],
    };
    swal({
      text: 'Deseja redistribuir a proposta?',
      icon: 'warning',
      buttons: ['Cancelar', true],
    }).then(async (isPropostaRedistribuida) => {
      if (isPropostaRedistribuida) {
        await redistribuirPropostas(objPropostas);

        setUpdateList(!updateList);
        swal({
          text: 'Tudo certo! Proposta redistribuída!',
          icon: 'success',
        });
      } else {
        return false;
      }
    });
  };

  return (
    <div className="analitico">
      <Tabs
        tabBarExtraContent={OperationsSlot}
        centered
        activeKey={activeTab}
        onTabClick={setActiveTab}
      >
        {panes}
      </Tabs>
      <div className="row ml-3 mr-3 animated fadeInRight">
        <div className="col-lg-12">
          <Card title={<Title label="Relatórios Analíticos" />}>
            <Cards getCard={getCard} loading={loadingCards} />

            <div className="row">
              <div className="col-lg-12 text-center analiticoList">
                <Table
                  data={relatorioAnalitico}
                  onLoadData={fetchRelatorioAnalitico}
                  columns={RelatorioAnaliticoColumns({
                    redistribuirProposta,
                  })}
                  filter={filters}
                  pagination={true}
                  loading={loading}
                  total={total}
                  pageCount={pageCount}
                  header={<Header />}
                  updateList={updateList}
                  onSearch={true}
                  search={search}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default RelatorioAnalitico;
