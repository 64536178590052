import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';

import FilaProvider from '../../contexts/FilaContext';

import FilaList from './FilaList';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function FilaCredito() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Redistribuição de Proposta</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <FilaProvider>
          {checarPermissao(
            'tela',
            'tela.credito.proposta.redistribuicao',
            'Visualizar',
          ) && (
            <>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <FilaList />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  {/* <ParametroForm /> */}
                </div>
              </div>
            </>
          )}
        </FilaProvider>
      </Content>
    </Main>
  );
}

export default FilaCredito;
