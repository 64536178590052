import React, { useContext, useEffect, useState } from 'react';

import { Input, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Title from '../../../components/Title';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

function Toolbar() {
  const { active, search, setActive, searchFirstPage, goToFirstPage } =
    useContext(TabelaJurosContext);

  const [inputValue, setInputValue] = useState('');

  async function searchTables() {
    goToFirstPage();
    searchFirstPage();
  }

  useEffect(() => {
    search.current = inputValue;
  }, [inputValue, search]);

  return (
    <div className="row d-flex align-items-center">
      <div className="col-lg-5">
        <Title label="Tabelas Cadastradas" />
      </div>

      <div className="col-lg-7">
        <div className="row d-flex align-items-center">
          <div className="col-lg-4">
            <span className="d-flex align-items-center justify-content-end">
              Apenas ativos
              <Switch
                size="small"
                checked={active}
                onChange={(value) => setActive(value)}
                style={{ marginLeft: '5px' }}
              />
            </span>
          </div>

          <div className="col-lg-8">
            <Input
              value={inputValue}
              placeholder="Pesquise por código, nome ou produto"
              suffix={<SearchOutlined onClick={() => searchTables()} />}
              onKeyDown={(e) => e.key === 'Enter' && searchTables()}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export { Toolbar };
