import React, { useState, useEffect } from 'react';

import './styles.scss';

import 'antd/dist/antd.css';
import { Select } from 'formik-antd';

import { ReactComponent as IconArrowSelect } from '../../assets/images/icons/arrow-down-select.svg';
const { Option } = Select;

function SelectBancos({ onChange, value, fillDefault = {}, ...rest }) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (!value && fillDefault?.obrigatorio === false)
      setSelected('Não informado');
    else setSelected(value);
  }, [value, fillDefault]);

  return (
    <Select
      {...rest}
      onInputKeyDown={(e) => {
        if (
          e.which === 9 &&
          document?.getElementById(e.target.getAttribute('aria-owns'))
        ) {
          let listaBancos = rest?.optionsBancos?.bancosPrincipais;
          listaBancos = [...listaBancos, ...rest?.optionsBancos?.bancos];

          let value = listaBancos.find((item) => {
            const numBanco = document
              ?.getElementById(e.target.getAttribute('aria-owns'))
              ?.parentElement?.getElementsByClassName(
                'ant-select-item-option-active',
              )[0]
              ?.getElementsByClassName('numero')[0]?.innerText;

            return numBanco && item?.codigo === numBanco;
          });

          setSelected(value?.codigo);
          onChange(value?.codigo);
        }
      }}
      className="select-banco"
      showSearch
      optionFilterProp="name"
      suffixIcon={<IconArrowSelect />}
      dropdownMatchSelectWidth={false}
      dropdownClassName="select-bancos"
      showArrow
      onChange={(value) => {
        setSelected(value);
        onChange(value);
      }}
      value={selected}
    >
      {rest?.optionsBancos?.bancosPrincipais?.map(({ nome, codigo }) => (
        <Option
          key={parseInt(codigo)}
          value={codigo}
          name={`${nome} ${codigo}`}
          className="options"
        >
          <span className="numero">{codigo}</span>
          <span className="nome">{nome}</span>
        </Option>
      ))}
      <div value="" className="linha" />
      {rest?.optionsBancos?.bancos?.map(({ nome, codigo }) => (
        <Option
          key={parseInt(codigo)}
          value={codigo}
          name={`${nome} ${codigo}`}
          className="options"
        >
          <span className="numero">{codigo}</span>
          <span className="nome">{nome}</span>
        </Option>
      ))}
    </Select>
  );
}

export default SelectBancos;
