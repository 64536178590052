import React, { useContext } from 'react';
import { HierarquiasContext } from '../../contexts/HierarquiasContext';
import { Button, Input, Switch } from 'antd';
import Tooltip from '../../components/tooltip';
import { ReactComponent as IconPlusBtn } from '../../assets/images/icons/add.svg';
import { SearchOutlined } from '@ant-design/icons';

const RightBarHierarquias = () => {
  const {
    checarPermissao,
    createNewHierarquia,
    filterActive,
    setFilterActive,
    atualizaListagemHierarquiasFiltrada,
    setFilterText,
    creatingHierarquia,
    editHierarquiaId,
  } = useContext(HierarquiasContext);

  return (
    <div className="menu-hierarquias">
      <div className="filtro">
        <Switch
          checked={filterActive}
          onChange={() => {
            setFilterActive(!filterActive);
          }}
        />
        <label>Apenas ativos</label>
      </div>
      <Input
        className="filter-input"
        placeholder="Pesquise por hierarquia"
        suffix={
          <SearchOutlined
            onClick={() => atualizaListagemHierarquiasFiltrada()}
          />
        }
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            atualizaListagemHierarquiasFiltrada();
          }
        }}
        onChange={(e) => setFilterText(e.target.value)}
      />
      {checarPermissao(
        'botao',
        'botao.cadastro.hierarquia.maisHierarquia',
        'Visualizar',
      ) && (
        <Tooltip
          placement="topLeft"
          trigger={creatingHierarquia ? 'hover' : ''}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          overlayClassName="tooltip-styled-crefaz"
          title="Finalize a criação da hierarquia para adicionar uma nova"
        >
          <Button
            type="button"
            className="btn-hierarquia"
            variant="blue"
            async={true}
            disabled={
              !checarPermissao(
                'botao',
                'botao.cadastro.hierarquia.maisHierarquia',
                'Visualizar',
              ) ||
              (editHierarquiaId && !creatingHierarquia)
            }
            onClick={() => {
              if (!creatingHierarquia) {
                createNewHierarquia();
              }
            }}
          >
            <IconPlusBtn />
            HIERARQUIA
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default RightBarHierarquias;
