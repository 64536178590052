import api from './api';

export const getHierarquia = async (active = true) => {
  const { data } = await api.get(`/hierarquia?ativo=${active}`);
  return data.data;
};

export const getCredenciadoArvore = async (credenciadoId, search) => {
  const { data } = await api.get(
    `/correspondente/${credenciadoId}/arvore${search ? `?${search}` : ''}`,
  );
  return data.data;
};

export const getCredenciadoConveniadoArvore = async (credenciadoId, search) => {
  const { data } = await api.get(
    `/convenio/${credenciadoId}/arvore${search ? `?${search}` : ''}`,
  );
  return data.data;
};

export const getHierarquiaCargos = async (
  id,
  hierarquiaNivelId,
  hierarquiaNivelDetalheId,
) => {
  const { data } = await api.get(
    `/hierarquia/${id}${
      hierarquiaNivelId && hierarquiaNivelDetalheId
        ? '?hierarquiaNivelId=' +
          hierarquiaNivelId +
          '&hierarquiaNivelDetalheId=' +
          hierarquiaNivelDetalheId
        : ''
    }`,
  );
  return data.data;
};

export const getPermissaoCadastroCpfCnpj = async (cnpj, tipo, subtipo) => {
  if (cnpj && tipo && subtipo) {
    const { data } = await api.get(
      `/credenciado/permitir-cadastro/${cnpj}?tipo=${tipo}&subtipo=${subtipo}`,
    );
    return data.data;
  }
  return { status: false };
};

export const getPermissaoCadastroUnidadeCnpj = async (cnpj, tipo, subtipo) => {
  if (cnpj) {
    const { data } = await api.get(`/unidade/permitir-cadastro/${cnpj}?tipocredenciado=${tipo}&subtipocredenciado=${subtipo}`);
    return data.data;
  }
  return { status: false };
};

export const getContextoCredenciamento = async () => {
  const response = await api.get('/contextos/credenciado');
  return response.data.data;
};

export const postCredenciadoLista = async (payload) => {
  const response = await api.post('/credenciado/lista', payload);
  return response.data;
};

export const getPermitirCadastroCnpj = async (cnpj, tipo, subtipo) => {
  const response = await api.get(
    `/credenciado/permitir-cadastro/${cnpj}?tipo=${tipo}&subtipo=${subtipo}`,
  );
  return response.data.data;
};

export const getInformacoesUsuario = async (userId) => {
  const response = await api.get(`/credenciado/${userId}`);
  return response?.data?.data;
};
export const getUnidadeInfos = async (unidadeId) => {
  const response = await api.get(`/unidade/${unidadeId}`);
  return response.data.data;
};

export const getUnidadeConvenioInfos = async (unidadeId) => {
  const response = await api.get(`/unidadeconvenio/${unidadeId}`);
  return response.data.data;
};

export const postCredenciadoProduto = async (payload) => {
  const response = await api.post('/credenciado/produto-regiao', payload);
  return response.data;
};

export const postCredenciadoUser = async (payload) => {
  const response = await api.post('/credenciado', payload);
  return response.data;
};

export const putCredenciadoUser = async (payload, id) => {
  const response = await api.put(`/credenciado/${id}`, payload);
  return response.data;
};

export const getDadosBanco = async () => {
  let contextoBanco = sessionStorage.getItem("contexto-banco");
  if (!contextoBanco) {
    const response = await api.get('/contextos/banco?completo=true');
    sessionStorage.setItem("contexto-banco", JSON.stringify(response.data));
    return response.data;
  }
  contextoBanco = JSON.parse(contextoBanco);
  return contextoBanco;
};

export const validarVigencia = async (
  unidadeId,
  hierarquiaNivelDetalheId,
  dataInicio,
  dataFim,
) => {
  const response = await api.post(
    `/unidade/${unidadeId}/validar-vigencia-hierarquia`,
    { hierarquiaNivelDetalheId, dataInicio, dataFim },
  );
  return response.data.data.status;
};
