import api from './api';

export const listarTarifas = async () => {
  try {
    const { data } = await api.get('/tarifa/tarifa-evento');
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const inserirTarifa = async (tarifa) => {
  try {
    const dados = {
      id: tarifa.id,
      nome: tarifa.nome,
      percentual: tarifa.percentual,
      valorFixo: tarifa.valorFixo,
      percentualBase: parseInt(tarifa.percentualBase),
      ativo: tarifa.ativo,
      eventoId: parseInt(tarifa.eventoId),
    };

    const { data } = await api.post('/Tarifa', dados);
    console.log(tarifa.percentual);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const editarTarifa = async (tarifa) => {
  try {
    const dados = {
      id: tarifa.id,
      nome: tarifa.nome,
      percentual: tarifa.percentual,
      valorFixo: tarifa.valorFixo,
      percentualBase: parseInt(tarifa.percentualBase),
      ativo: tarifa.ativo,
      eventoId: parseInt(tarifa.eventoId),
    };

    const { data } = await api.put(`/Tarifa/${dados.id}`, dados);

    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
