import React, { useContext, useState } from 'react';
import moment from 'moment';
import { ReactComponent as IconUsers } from '../../assets/images/icons/icon-most-user.svg';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Input } from 'antd';
import PanelHierarquia from './PanelHierarquia/index.js';
import Tooltip from '../../components/tooltip';
import ModalNotification from './ModalNotification';
import ModalTransfDependentes from './PanelHierarquia/ModalTransfDependentes';
import ModalConfirmaInativacao from './PanelHierarquia/ModalConfirmaInativacao';
import { HierarquiasContext } from '../../contexts/HierarquiasContext';

const { Panel } = Collapse;

const CollapsesHierarquias = () => {
  const {
    hierarquias,
    hierarquiasFiltradas,
    carregaPanelHierarquia,
    searchIndexHierarquia,
    editingHierarquia,
    editHierarquiaId,
    infoModalNotification,
    infoModalTransDependencias,
    openPanelsCollapseHierarquia,
    setOpenPanelsCollapseHierarquia,
  } = useContext(HierarquiasContext);

  const collapseHierarquia = (idsHierarquiasOpen) => {
    if (idsHierarquiasOpen?.length > openPanelsCollapseHierarquia?.length) {
      carregaPanelHierarquia(
        idsHierarquiasOpen[idsHierarquiasOpen?.length - 1],
      );
    }
    if (editingHierarquia && idsHierarquiasOpen.indexOf(-1) < 0) {
      idsHierarquiasOpen.push(-1);
    }
    setOpenPanelsCollapseHierarquia(idsHierarquiasOpen);
  };

  return (
    <div className="collapse-hierarquia">
      <Collapse
        onChange={collapseHierarquia}
        activeKey={openPanelsCollapseHierarquia}
        expandIconPosition="left"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{
              color: '#ff7817',
            }}
          />
        )}
        className="mt-4"
      >
        {hierarquiasFiltradas?.length > 0 &&
          hierarquiasFiltradas?.map((hierarquia) => {
            let indexHierarquia = searchIndexHierarquia(hierarquia.id);
            let hierarquiaCompleta = hierarquias[indexHierarquia];
            // Verifica se alguma hierarquia está sendo editada e se é o mesmo ID da "atual"
            let editCurrentHierarquia =
              editingHierarquia &&
              parseInt(editHierarquiaId) === parseInt(hierarquia?.id);

            return (
              <Panel
                key={hierarquia.id}
                className={`panel-hierarquia ${
                  editCurrentHierarquia && 'editing-pos'
                }`}
                header={
                  <HeaderCollapseHierarquia
                    nome={hierarquiaCompleta?.nome}
                    perfis={hierarquiaCompleta?.perfis || []}
                    hierarquiaId={hierarquiaCompleta?.id}
                    editCurrentHierarquia={editCurrentHierarquia}
                  />
                }
                extra={
                  <ExtraHeaderPanelHierarquia
                    dataCadastro={hierarquiaCompleta?.cadastro}
                    dataAlteracao={hierarquiaCompleta?.ultimaAlteracao}
                    ativo={hierarquiaCompleta?.ativo}
                  />
                }
              >
                <PanelHierarquia
                  hierarquia={hierarquiaCompleta}
                  indexHierarquia={indexHierarquia}
                  editCurrentHierarquia={editCurrentHierarquia}
                />
              </Panel>
            );
          })}
      </Collapse>
      <ModalNotification
        visible={infoModalNotification?.visible}
        type={infoModalNotification?.type}
        message={infoModalNotification?.message}
        buttons={infoModalNotification?.buttons}
        actionConfirm={infoModalNotification?.actionConfirm}
        actionCancel={infoModalNotification?.actionCancel}
      />
      <ModalConfirmaInativacao />
      {infoModalTransDependencias?.visible && (
        <ModalTransfDependentes
          visible={infoModalTransDependencias?.visible}
          hierarquiaId={infoModalTransDependencias?.hierarquiaId}
          hierarquiaIndex={infoModalTransDependencias?.hierarquiaIndex}
        />
      )}
    </div>
  );
};

const HeaderCollapseHierarquia = ({
  nome,
  perfis,
  hierarquiaId,
  editCurrentHierarquia = false,
}) => {
  const contexto = useContext(HierarquiasContext);
  const {
    optionsPerfisHierarquia,
    validateNameHierarquia,
    modalNotification,
    cancelModalNotification,
  } = contexto;

  const [newNameHierarquia, setNewNameHierarquia] = useState(null);

  const editNameHierarquia = async () => {
    if (await validateNameHierarquia(newNameHierarquia)) {
      contexto.editNameHierarquia(newNameHierarquia, hierarquiaId);
    } else {
      modalNotification(
        'warning',
        'Já existe uma hierarquia com o nome informado',
        null,
        () => {
          cancelModalNotification().then(() => {
            setTimeout(() => {
              document.getElementById('new-hierarquia-input-name')?.focus();
            }, 400);
          });
        },
      );
    }
  };

  return (
    <div className="hierarquia-collapse">
      {hierarquiaId === -1 ? (
        <Input
          value={newNameHierarquia || nome}
          id="new-hierarquia-input-name"
          className="editando-header input-title-header-collapse"
          placeholder="Digite o nome da hierarquia"
          onChange={(e) => setNewNameHierarquia(e.target.value)}
          onKeyDown={(e) => {
            e.key === 'Enter' && e?.target?.blur();
          }}
          onBlur={editNameHierarquia}
        />
      ) : (
        <div
          className={`hierarquia-name ${editCurrentHierarquia && 'can-edit'}`}
        >
          <span>{nome}</span>
          {!editCurrentHierarquia &&
            perfis?.length > 0 &&
            tooltipIconHeaderHierarquia(
              `Perfis autorizados: ${perfis
                ?.map((perfilId) => {
                  let perfilIndex = optionsPerfisHierarquia
                    .map((perfil) => perfil.id)
                    .indexOf(perfilId);
                  return optionsPerfisHierarquia[perfilIndex]?.nome;
                })
                ?.toString()
                .replaceAll(',', ' - ')}`,
            )}
        </div>
      )}
    </div>
  );
};

const tooltipIconHeaderHierarquia = (content) => {
  return (
    <Tooltip
      placement="topLeft"
      arrowPointAtCenter
      autoAdjustOverflow={false}
      color="#435361"
      overlayClassName="tooltip-hierarquia"
      title={
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '15px',
          }}
        >
          {content}
        </div>
      }
    >
      <IconUsers />
    </Tooltip>
  );
};

const ExtraHeaderPanelHierarquia = ({
  dataCadastro = null,
  dataAlteracao = null,
  ativo = true,
}) => {
  return (
    <div className="info-headers">
      <div>
        <span>Criação:</span>
        {moment(dataCadastro).isValid()
          ? moment(dataCadastro).format('DD/MM/YYYY')
          : 'Não informado'}
      </div>
      <div>
        <span>Alteração:</span>
        {moment(dataAlteracao).isValid()
          ? moment(dataAlteracao).format('DD/MM/YYYY')
          : 'Não informado'}
      </div>
      <div>
        <span>Status:</span> {ativo ? 'Ativo' : 'Inativo'}
      </div>
    </div>
  );
};

export default CollapsesHierarquias;
