import React from 'react';

import './styles.css';

function Title({ label, noLine }) {
  return (
    <div className="title-label">
      <h3>{label}</h3>
      {!noLine && <div className="title-line"></div>}
    </div>
  );
}

export default Title;
