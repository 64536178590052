import { object, number, ref, string } from 'yup';

export const validationForm = object().shape({
  juros: string().max(8, 'Valor maxímo é de 999,9999'),
  prazoInicial: number('')
    .required('')
    .positive('')
    .moreThan(0, '')
    .integer(''),
  prazoFinal: number('')
    .required('')
    .positive('')
    .moreThan(ref('prazoInicial', ''), '')
    .integer(''),
});
