import React, {
  useState,
  createContext,
  useCallback,
  useRef,
  useEffect,
} from 'react';

import {
  listarPropostasPainel,
  gravarOperatividade,
  getCardEstatistica,
} from '../services/propostaService';

export const AnalisePropostaContext = createContext({});

function AnalisePropostaProvider({ children }) {
  const [isIndicadorActive, setIndicadorActive] = useState(true);
  const [isAnaliseIndicadoresActive, setAnaliseIndicadores] = useState(true);
  const [estatisticas, setEstatisticas] = useState({
    tempoMedio: '00:00',
    taxaAprovacao: 0,
    taxaPendencia: 0,
    qtdPropostas: 0,
    taxaNegativa: 0,
  });
  const [operatividade, setOperatividade] = useState({});
  const [propostas, setPropostas] = useState([]);
  const [isFieldActive, setFieldActive] = useState(true);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const currentPage = useRef(1);
  const [isOnline, setOnline] = useState(null);
  const [propostasAnalise, setPropostasAnalise] = useState([]);

  const saveOperatividade = async (value) => {
    await gravarOperatividade(value);
    fetchPropostas();
  };

  const fetchPropostas = useCallback(async (props) => {
    setLoading(true);

    const page = props?.pageIndex + 1 || 1;
    currentPage.current = page;
    const data = await listarPropostasPainel({
      pagina: page,
      ordenacao: props?.sortBy?.[0],
    });

    if (data?.lista) {
      setPropostas(data.lista?.itens);
      setOperatividade(data.operatividade);
      setTotal(data?.lista?.total);
      setPageCount(data?.lista?.totalPages);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const fetchCardsEstatisticas = async () => {
      const data = await getCardEstatistica();
      const tempoMedioFormatado = data?.tempoMedio?.slice(0, 5);
      setEstatisticas({ ...data, tempoMedio: tempoMedioFormatado });
    };
    fetchCardsEstatisticas();
  }, [setEstatisticas]);

  return (
    <AnalisePropostaContext.Provider
      value={{
        isIndicadorActive,
        isAnaliseIndicadoresActive,
        propostas,
        operatividade,
        estatisticas,
        isFieldActive,
        total,
        loading,
        pageCount,
        currentPage,
        isOnline,
        propostasAnalise,
        setIndicadorActive,
        setAnaliseIndicadores,
        setOperatividade,
        setEstatisticas,
        setLoading,
        fetchPropostas,
        saveOperatividade,
        setFieldActive,
        setOnline,
        setPropostasAnalise,
      }}
    >
      {children}
    </AnalisePropostaContext.Provider>
  );
}

export default AnalisePropostaProvider;
