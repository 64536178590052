import React, { useContext, useState, useEffect } from 'react';

import { Tooltip } from 'antd';

import { ReactComponent as BulletInfo } from '../../../assets/images/icons/bullet-info-tooltip.svg';
import { ReactComponent as PrintIcon } from '../../../assets/images/icons/print-icon.svg';
import FormUpload from '../PropostaUploadForm';
import { Form, Radio } from 'formik-antd';
import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import Button from '../../../components/Button';
import { ReactComponent as IconSendButton } from '../../../assets/images/icons/icon-send-button.svg';
import Modal from '../../../components/Modal';

const TooltipInfo = ({ text }) => (
  <Tooltip
    placement="bottom"
    className="tooltip-info"
    overlayClassName="tooltip-proposta-credito"
    color="#435361"
    title={text}
  >
    <BulletInfo width={15} className="bullet-info-icon" />
  </Tooltip>
);

function DadosUploadForm({ formik }) {
  const {
    arquivos,
    setArquivos,
    avisoArquivosPendentes,
    modalidadeContrato,
    setModalidadeContrato,
    editableField,
    isSendButtonClickedFirstTime,
    setIsSendButtonClickedFirstTime,
    setIsSendButtonClicked,
    isSendButtonClicked,
    setIsLoadingDocumentValidation,
    setResponseAcertPix,
    postAcertPixDocOCR,
    proposta,
    tipoAnexos
  } = useContext(PropostaCreditoContext);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const [haveRequiredDocumentsMissing, setHaveRequiredDocumentsMissing] = useState(true);
  const [lenArquivos, setLenArquivos] = useState(arquivos?.length);
  let disableSendButton = false;

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const handleChangeModalidade = (e) => {
    const { value } = e.target;
    setModalidadeContrato(value);
  };

  const updateSendedFiles = () => {
    arquivos.forEach((arq) => {
      if (arq) {
        arq.enviado = true;
      }
    })

    setArquivos([...arquivos]);
  }

  const sendDocumentsButtonValidations = () => {
    if (arquivos.find(a => a.documentoId === null)) {
      Modal(
        "",
        "Existem anexos cujo tipo de documento não foi preenchido",
        "warning"
      );
      return false;
    }

    return true;
  }

  let timeoutAcetPix;

  const getDocumentsByNameForOCR = (name) => {
    const idDocument = tipoAnexos?.find(
      arquivo => arquivo.nome === name
    )?.id;

    const documents = arquivos?.filter(
      (a) => !a.id && a.documentoId === idDocument && !a.enviado
    );

    const handledDocuments = documents.map(doc => {
      const newDocument = {
        imagemId: doc.imagemId
      }

      return newDocument;
    })

    if (handledDocuments && handledDocuments.length > 0) {
      return handledDocuments
    }

    return null;
  }

  const handleSendDocuments = async () => {
    if (!sendDocumentsButtonValidations()) {
      return;
    }

    const documentosIdentificacao = getDocumentsByNameForOCR("DOCUMENTO DE IDENTIFICAÇÃO");
    const faturaEnergia = getDocumentsByNameForOCR("FATURA DE ENERGIA")?.[0];

    setIsSendButtonClicked(true);
    disableSendButton = true;

    const analiseOCR = proposta?.analiseOCR;

    const isSomeOCRValidation = Object.keys(analiseOCR).some(key => analiseOCR[key]);

    const isAguardandoCadastro = proposta?.situacaoDescricao === "Aguard. Cadastro";

    if (isSomeOCRValidation && (documentosIdentificacao?.length > 0 || faturaEnergia)) {
      clearTimeout(timeoutAcetPix);

      const payload = {
        propostaId: proposta.id,
        documentosIdentificacao: analiseOCR?.documentosIdentificacao ? documentosIdentificacao : null,
        faturaEnergia: (analiseOCR?.faturaEnergia && isAguardandoCadastro) ? faturaEnergia : null
      }

      setIsLoadingDocumentValidation(true);
      postAcertPixDocOCR(payload).then(response => {
        const waitWebhook = Object.keys(response).some(
          (key) => response[key]?.aguardarWebhookAcertpix);
        if (waitWebhook) {
          setResponseAcertPix(response);
          timeoutAcetPix = setTimeout(() => {
            setIsLoadingDocumentValidation(false);
          }, proposta?.parametrosOCR?.tempoMaximoWebhookOCR * 1000);
        } else {
          setIsLoadingDocumentValidation(false);
        }
      });
    }

    if (!isSendButtonClickedFirstTime) {
      setIsSendButtonClickedFirstTime(true);
    }
    updateSendedFiles();
  }

  const hasRequiredDocumentValidation = () => {
    const tipoModalidade = formik.values?.operacao?.tipoModalidade;
    if (avisoArquivosPendentes?.length || !tipoModalidade) {
      setHaveRequiredDocumentsMissing(true);
    } else {
      setHaveRequiredDocumentsMissing(false);
    }
  }

  const hasNewFiles = () => {
    if (lenArquivos < arquivos.length && arquivos.find(a => !a.enviado)) {
      setIsSendButtonClicked(false);
      disableSendButton = false
    }
    setLenArquivos(arquivos.length);
  }

  useEffect(() => {
    hasRequiredDocumentValidation();
    hasNewFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avisoArquivosPendentes, arquivos])

  return (
    <>
      {avisoArquivosPendentes?.length > 0 && (
        <div className="pull-right aviso-arquivos">
          <p>
            Os seguintes arquivos são obrigatórios:{' '}
            {avisoArquivosPendentes.map((arquivo) => arquivo.nome).join(', ')}
          </p>
        </div>
      )}
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">
          Importação de Arquivo
          <TooltipInfo text="Os arquivos devem estar nos formatos de PDF, JPEG ou PNG."></TooltipInfo>
        </div>
      </div>
      <div className="form-row mb-3">
        <div className="col-lg-12 d-flex justify-content-between align-items-center">
          <div className="modalidade-contrato">
            <Form.Item
              name="operacao.tipoModalidade"
              validate={(value) => {
                let msgError = null;
                if (!value) {
                  msgError = 'Obrigatório informar a modalidade';
                }
                return msgError;
              }}
            >
              Modalidade do contrato:{' '}
              <Radio.Group
                value={modalidadeContrato}
                onChange={handleChangeModalidade}
                name="operacao.tipoModalidade"
                disabled={
                  acesso ? !editableField('operacao.tipoModalidade') : true
                }
              >
                <Radio value={1}>Físico</Radio>
                <Radio value={2}>Digital</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="atalho-tela-impressao">
            <a href="/impressao-proposta" target="_blank">
              <Button variant="blue" disabled={!acesso}>
                <PrintIcon />
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-12">
          <FormUpload formik={formik} />
        </div>
      </div>
      <div className='enviar-documento-button'>
        <Button
          variant={
            haveRequiredDocumentsMissing ? "gray" : "blue"
          }
          disabled={
            haveRequiredDocumentsMissing ||
            isSendButtonClicked ||
            !arquivos.some(a => !a.id && !a.enviado) ||
            disableSendButton
          }
          onClick={handleSendDocuments}
        >
          <IconSendButton className='btn-enviar' />
          ENVIAR DOCUMENTOS
        </Button>
      </div>
    </>
  );
}

export { DadosUploadForm };
