import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Switch, Upload, Modal, Button } from 'antd';

import moment from 'moment';

import { ReactComponent as IconAnexar } from '../../../assets/images/icons/download.svg';
import { ReactComponent as IconUploadDelete } from '../../../assets/images/icons/icon-uploads-delete.svg';

import HeaderCalculoLuzDia from '../../../components/HeaderCalculoLuzDia';
import Message from '../../../components/Modal';
import ModalAlerta from '../../../components/Modal';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';
import { getLinkImagem } from '../../../services/imagemService';

import { currencyFormat } from '../../../utils/numberFormatter';

import { convertBase64ToBlob } from '../../../utils/convertBase64toBlob';
import Overlay from '../../../components/Overlay';

function ItemFatura({
  fatura,
  setFaturasDescontar,
  formik,
  carregarImagem,
  tipoDebito,
  elegivelLuzEmDia,
  resultadoCrivo,
  setShowOverlay,
  valorDisponivel,
  setCalculado,
  debitosConveniada,
  setTotalDebitosNaoComprovados,
  setDataRecalculo,
}) {
  const { values } = formik;
  const { dataReferencia, dataVencimento, valor } = fatura;

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [descontar, setDescontar] = useState(false);
  const [blobFile, setBlobFile] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleCancel = () => {
    setPreviewVisible(false);
    setPreviewImage(null);
    setPreviewTitle(null);
    setBlobFile(null);
  };

  const handlePreview = async (file) => {
    setLoadingFile(true);
    setPreviewVisible(true);

    if (file?.link) {
      const anexo = await getLinkImagem(file.link);
      setLoadingFile(false);

      const { conteudo } = anexo;

      if (conteudo) {
        file.type = conteudo.match(
          /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
        )[1];

        setFileType(file.type);
        setPreviewImage(conteudo);
        setPreviewVisible(true);
      }
    } else if (file?.url) {
      setLoadingFile(false);
      file.type = file.url.match(
        /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/,
      )[1];

      setFileType(file.type);
      setPreviewImage(file.url);
      setPreviewVisible(true);
    }
  };

  const beforeUpload = (file) => {
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg'
    ) {
      Message(
        'Arquivo inválido',
        'Somente podem ser enviados arquvivos nos formatos PNG, JPEG ou PDF',
        'warning',
      );
    }
    return false;
  };

  const uploadImage = async (options) => {
    const { file } = options;
    if (
      file?.status !== 'removed' &&
      (file?.type === 'application/pdf' ||
        file?.type === 'image/png' ||
        file?.type === 'image/jpeg')
    ) {
      setShowOverlay(true);

      const reader = new FileReader();
      const temp = { arquivo: '' };
      reader.onloadend = async function () {
        temp.arquivo = reader.result;
        try {
          const fileURL = await getBase64(file);
          const { data } = await carregarImagem(temp);

          fatura.comprovanteId = data.id;
          fatura.link = null;
          fatura.url = fileURL;
          fatura.comprovante = btoa(temp.arquivo);
          fatura.existeComprovante = true;
          fatura.name = file.name;
          setShowOverlay(false);
        } catch (err) {}
        setShowOverlay(false);
      };
      reader.readAsDataURL(file);
    } else {
      options.fileList = [];
      setShowOverlay(false);
    }
    setCalculado(true);
    setDataRecalculo(moment());
  };

  const handleRemove = () => {
    fatura.comprovante = null;
    fatura.comprovanteId = null;
    fatura.existeComprovante = false;
    fatura.name = null;
    fatura.link = null;
    setCalculado(true);
    setDataRecalculo(moment());
  };

  const handleDescontarFatura = (value) => {
    let maxCrivo = values.resultadoCrivo.qtMaxDebitosConcessionaria;

    const qtDescontar = debitosConveniada.filter(
      (fatura) => fatura.descontar === true,
    ).length;
    if (qtDescontar < maxCrivo && value) {
      setFaturasDescontar((faturas) => [...faturas, fatura]);
      fatura.descontar = value;
      setDescontar(value);
    } else if (qtDescontar <= maxCrivo && !value) {
      setFaturasDescontar((faturas) =>
        faturas.filter((item) => item.id !== fatura.id),
      );
      fatura.descontar = value;
      setDescontar(value);
    } else {
      ModalAlerta(
        '',
        'Limite de faturas a serem descontadas atingido.',
        'warning',
      );
    }

    const somaDebitos = debitosConveniada
      .filter((fatura) => fatura.descontar === true)
      .reduce((acc, faturaAtual) => acc + faturaAtual.valor, 0);

    const calculoValorLimite =
      valorDisponivel * (resultadoCrivo?.percentualMaxLimite / 100);

    let debitosAtuaisAcimaLimite = somaDebitos > calculoValorLimite;

    if (debitosAtuaisAcimaLimite && value) {
      setFaturasDescontar((faturas) =>
        faturas.filter((item) => item.id !== fatura.id),
      );
      fatura.descontar = !value;
      setDescontar(!value);
      setTotalDebitosNaoComprovados((prev) => prev + fatura.valor);

      ModalAlerta(
        '',
        `Os débitos atuais do cliente ultrapassaram o limite de R$ ${calculoValorLimite} para prosseguimento da proposta.`,
        'warning',
      );
    }
    setCalculado(true);
    setDataRecalculo(moment());
  };

  const calculoDescontarFaturas = useCallback(async () => {
    if (fatura.descontar) {
      setFaturasDescontar((faturas) => [...faturas, fatura]);
      setDescontar(fatura.descontar);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    calculoDescontarFaturas();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    // Caso o PDF tenha + de 1MB, será convertido para Blob, devido a limitação de certos browsers com base64
    const fileSizeInBytes = previewImage ? previewImage?.length * (3 / 4) : 0;
    const MAX_BROWSER_BASE64_SIZE = 1000000;
    if (
      fileType === 'application/pdf' &&
      previewImage &&
      fileSizeInBytes > MAX_BROWSER_BASE64_SIZE
    ) {
      const blobURL = convertBase64ToBlob(previewImage, fileType);
      setBlobFile(blobURL);
    } else {
      setBlobFile(null);
    }
  }, [previewImage, fileType]);

  return (
    <div className="item-fatura">
      <div className="row">
        <div className={'col-sm-7 informacoes'}>
          <div>
            <h5 className="titulo">Referência</h5>
            <p className="info">{dataReferencia}</p>
          </div>
          <div>
            <h5 className="titulo">Vencimento</h5>
            <p className="info">
              {moment(dataVencimento).format('DD/MM/YYYY')}
            </p>
          </div>
          <div>
            <h5 className="titulo">Tipo</h5>
            <p className="info">
              {tipoDebito?.find((tipo) => tipo.id === fatura.tipo).nome}
            </p>
          </div>
          <div>
            <h5 className="titulo">Valor</h5>
            <p className="info">{currencyFormat(valor)}</p>
          </div>
          {elegivelLuzEmDia !== 1 && (
            <div className="col-sm-5 descontar-fatura">
              <h5 className="titulo">Descontar</h5>
              <Switch
                size="small"
                name="status"
                className="switch-red-if-off"
                onChange={handleDescontarFatura}
                checked={descontar}
                disabled={!acesso}
              />
            </div>
          )}
        </div>
        <div className={'col-sm-5 acoes'}>
          <div className="row">
            <div className={'col-sm-12 anexar-comprovante'}>
              {!fatura.descontar || elegivelLuzEmDia === 1 ? (
                <div>
                  <Upload
                    name="file"
                    maxCount="1"
                    listType="text"
                    fileList={
                      fatura?.existeComprovante
                        ? [
                            {
                              name: fatura?.name
                                ? fatura?.name
                                : `Comprovante ${dataReferencia}`,
                              id: fatura?.id ?? fatura?.comprovanteId,
                              existeComprovante: fatura?.existeComprovante,
                              link: fatura?.link,
                              url: fatura?.url,
                            },
                          ]
                        : []
                    }
                    beforeUpload={beforeUpload}
                    onChange={uploadImage}
                    onRemove={handleRemove}
                    onPreview={handlePreview}
                    showUploadList={{
                      showRemoveIcon: true,
                      removeIcon: <IconUploadDelete />,
                    }}
                    disabled={!acesso}
                  >
                    {!fatura?.existeComprovante &&
                      !debitosConveniada?.comprovante && (
                        <Button icon={<IconAnexar />}>
                          Anexar Comprovante
                        </Button>
                      )}
                  </Upload>

                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    className={
                      'modal-preview-upload ' + fileType.replace('/', '')
                    }
                    onCancel={handleCancel}
                  >
                    <Overlay active={loadingFile}>
                      {!loadingFile && (
                        <div>
                          {fileType === 'application/pdf' && (
                            <object
                              data={(blobFile ?? previewImage) + '#toolbar=0'}
                              type={fileType}
                              width="100%"
                              height="100%"
                              aria-label="Upload File"
                            ></object>
                          )}
                          {fileType !== 'application/pdf' && (
                            <img src={previewImage} alt="Documento"></img>
                          )}
                        </div>
                      )}
                    </Overlay>
                  </Modal>
                </div>
              ) : (
                <p className="info-pagamento-descontado">
                  O pagamento desta fatura será descontado no valor liberado ao
                  cliente
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DadosLuzDiaForm({ formik, carregarImagem, getImagem, tipoDebito }) {
  const { values, setFieldValue } = formik;

  const { historicoCia, resultadoCrivo, elegivelLuzEmDia, operacao } = values;

  const debitosConveniada = historicoCia?.debitosConveniada;

  const {
    setTotalDebitosNaoComprovados,
    totalDebitosNaoComprovados,
    setShowOverlay,
    setCalculado,
    setDataRecalculo,
    faturasDescontar,
    setFaturasDescontar,
    limparValoresLuzEmDia,
    setLimparValoresLuzEmDia,
    valores,
  } = useContext(PropostaCreditoContext);

  const [debitosPagar, setDebitosPagar] = useState(0);
  const [debitosReceber, setDebitosReceber] = useState(0);
  const [valorLimite, setValorLimite] = useState(0);
  const [valorDisponivel, setValorDisponivel] = useState(0);
  const [debitosConcessionaria, setDebitosConcessionaria] = useState(0);
  const [debitosAtuaisAcimaLimite, setDebitosAtuaisAcimaLimite] =
    useState(false);

  useEffect(() => {
    if (valorDisponivel && elegivelLuzEmDia === 0) {
      const somaDebitos = debitosConveniada
        ?.filter((fatura) => fatura.descontar === true)
        ?.reduce((acc, faturaAtual) => acc + faturaAtual.valor, 0);

      const calculoValorLimite =
        valorDisponivel * (resultadoCrivo?.percentualMaxLimite / 100);

      let debitosAtuaisAcimaLimite = somaDebitos > calculoValorLimite;

      if (debitosAtuaisAcimaLimite) {
        ModalAlerta(
          '',
          `Os débitos atuais do cliente ultrapassaram o limite de R$ ${calculoValorLimite} para prosseguimento da proposta.`,
          'warning',
        );
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [valorDisponivel]);

  useEffect(() => {
    if (values.consultarValorCrivo) {
      setLimparValoresLuzEmDia(true);
    }
  }, [faturasDescontar]);

  useEffect(() => {
    if (operacao?.valorSolicitado || operacao?.valorContratado) {
      const menorValorLimite = Math.min(
        operacao?.produtoId === 12 &&
          totalDebitosNaoComprovados > 0 &&
          elegivelLuzEmDia === 0
          ? resultadoCrivo?.valorLuzEmDia
          : resultadoCrivo?.limite,
        operacao?.tipoCalculo === 0
          ? values?.operacao?.valorContratado
          : values?.operacao?.valorSolicitado,
      );
      setValorDisponivel(menorValorLimite);
      setLimparValoresLuzEmDia(false);
    } else {
      let maiorValor = 0;

        valores?.forEach((element) => {
          if (element?.valor > maiorValor) {
            maiorValor = element?.valor;
          }
        });

      // if (limparValoresLuzEmDia) {
        // 9962 - Discutir o porque desse código
        // if (valores[0]?.valor < resultadoCrivo?.valorLuzEmDia) {
        //   setValorDisponivel(valores[0].valor);
        // } else {
        //   setValorDisponivel(resultadoCrivo?.valorLuzEmDia);
        // }
        if (
          operacao?.produtoId === 12 &&
          totalDebitosNaoComprovados > 0 &&
          elegivelLuzEmDia === 0
        ) {
          setValorDisponivel(Math.min(maiorValor, resultadoCrivo?.valorLuzEmDia));
        } else if (valores?.length > 0) {
          setValorDisponivel(Math.min(resultadoCrivo?.limite, maiorValor));
        } else {
          setValorDisponivel(resultadoCrivo?.limite);

      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [operacao?.valorSolicitado, operacao?.valorContratado, totalDebitosNaoComprovados]);

  useEffect(() => {
    setShowOverlay(true);

    const somaDebitos = faturasDescontar.reduce(
      (acc, faturaAtual) => acc + faturaAtual.valor,
      0,
    );

    const somaDebitosReceber = resultadoCrivo?.limite - somaDebitos;

    const calculoValorLimite =
      resultadoCrivo?.limite * (resultadoCrivo?.percentualMaxLimite / 100);

    const valorSolicitado = values?.operacao?.valorContratado;

    const valorLiberado = values?.operacao?.valorLiberado;

    setDebitosAtuaisAcimaLimite(somaDebitos > calculoValorLimite);
    setDebitosPagar(somaDebitos);
    setDebitosReceber(somaDebitosReceber);
    setValorLimite(calculoValorLimite);
    if (elegivelLuzEmDia === 0) {
      if (values?.operacao?.tipoCalculo === 0) {
        setFieldValue(
          'operacao.valorLiberado',
          valorDisponivel ? valorDisponivel - somaDebitos : valorSolicitado,
        );
      } else {
        setFieldValue(
          'operacao.valorLiberado',
          valorDisponivel ? valorDisponivel - somaDebitos : valorLiberado,
        );
      }
    }
    setShowOverlay(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [faturasDescontar, valorDisponivel, values?.operacao?.valorLiberado]);

  useEffect(() => {
    const somaDebitos = debitosConveniada?.reduce(
      (acc, faturaAtual) => acc + faturaAtual.valor,
      0,
    );
    setDebitosConcessionaria(somaDebitos);

    const somaDebitosNaoComprovados = debitosConveniada?.reduce(
      (acc, faturaAtual) =>
        acc +
        (!faturaAtual.descontar && faturaAtual.existeComprovante
          ? 0
          : faturaAtual.valor),
      0,
    );
    setTotalDebitosNaoComprovados(somaDebitosNaoComprovados);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [JSON.stringify(debitosConveniada)]);

  return (
    <>
      <div className="form-row pt-4 pb-3">
        <div className="Container  ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">
          {elegivelLuzEmDia === 0
            ? 'Dados Luz em Dia'
            : 'Débitos com a Concessionária'}
        </div>
      </div>

      <div className="form-row dados-luz-dia-form">
        <div className="col-sm-12">
          <HeaderCalculoLuzDia
            valorDisponivel={valorDisponivel}
            debitosPagar={
              elegivelLuzEmDia === 1 ? debitosConcessionaria : debitosPagar
            }
            aReceber={debitosReceber}
            totalDebitos={debitosPagar}
            debitosAtuaisAcimaLimite={debitosAtuaisAcimaLimite}
            debitosEncontrados={elegivelLuzEmDia === 1}
            valorLimite={valorLimite}
          />
          <div className="lista-faturas">
            {debitosConveniada?.map((fatura) => (
              <ItemFatura
                fatura={fatura}
                descontarFatura={elegivelLuzEmDia === 0}
                setFaturasDescontar={setFaturasDescontar}
                setCalculado={setCalculado}
                formik={formik}
                key={fatura.id}
                carregarImagem={carregarImagem}
                getImagem={getImagem}
                tipoDebito={tipoDebito}
                elegivelLuzEmDia={elegivelLuzEmDia}
                resultadoCrivo={resultadoCrivo}
                setShowOverlay={setShowOverlay}
                valorDisponivel={valorDisponivel}
                debitosConveniada={debitosConveniada}
                setTotalDebitosNaoComprovados={setTotalDebitosNaoComprovados}
                setDataRecalculo={setDataRecalculo}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export { DadosLuzDiaForm };
