import React, { useEffect, useState, useContext, Fragment } from 'react';

import { Form, Input, Select, Field } from 'formik-antd';
import InputPhone from '../../../../components/InputPhone';
import validaPhone from '../../../../utils/validaPhone';

import { Button } from 'antd';

import { ReactComponent as IconTrash } from '../../../../assets/images/icons/trash.svg';

import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';
import { CadastroPessoaContext } from '../../../../contexts/CadastroPessoaContext';

const { Option } = Select;

function ContatoForm({ formik }) {
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusTelefoneExtra, setStatusTelefoneExtra] = useState([]);
  const { values, setFieldValue, touched, errors, setFieldTouched } = formik;
  const [acesso, setAcesso] = useState(false);

  const { contexto, telefonesExtra, setTelefonesExtra } = useContext(
    CadastroPessoaContext,
  );

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao('aba', 'aba.cadastroPessoa.cliente.contato', 'Editar'),
    );
  }, [checarPermissao]);

  const valuesTelefonesExtras = {
    telefoneExtra: '',
    tipoTelefoneExtra: 0,
  };

  const handleAddTelefonesExtra = () => {
    if (telefonesExtra) {
      setTelefonesExtra(telefonesExtra.concat(valuesTelefonesExtras));
    } else {
      setTelefonesExtra([valuesTelefonesExtras]);
    }
  };

  const handleRemoveTelefoneExtra = (index) => {
    setTelefonesExtra((newArray) => newArray.filter((_, idx) => idx !== index));
    values.contatos.contatosExtras.splice(index, 1);
    const newStateArray = [...statusTelefoneExtra];
    newStateArray.splice(index, 1);
    setStatusTelefoneExtra(newStateArray);
    setFieldTouched(`contatos.contatosExtras.${index}.telefone`, false);
  };

  const handleTelefoneExtraType = (value, i) => {
    const type = validaPhone(value);
    if (type !== 0) {
      setFieldValue(`contatos.contatosExtras.${i}.tipoTelefone`, type);
    } else {
      setFieldValue(`contatos.contatosExtras.${i}.tipoTelefone`, '');
    }
  };

  const handleTelefoneType = (value) => {
    const type = validaPhone(value);
    if (type !== 0) {
      formik.setFieldValue(`contatos.contato.tipoTelefone`, type);
    } else {
      formik.setFieldValue(`contatos.contato.tipoTelefone`, '');
    }
  };

  useEffect(() => {
    let newStateArray = [...statusTelefone];
    if (errors?.contatos?.telefone) {
      if (touched?.contatos?.telefone) {
        if (
          errors?.contatos?.telefone ===
          'Número de telefone inválido, favor tentar novamente.'
        ) {
          newStateArray = 'error';
        } else if (
          errors?.contatos?.telefone === 'O telefone deve ser preenchido'
        ) {
          newStateArray = 'error';
        } else {
          newStateArray = '';
        }
      }

      setStatusTelefone(newStateArray);
    } else {
      setStatusTelefoneExtra([]);
    }
    handleTelefoneType(values?.contatos?.telefone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touched?.contatos?.telefone,
    errors?.contatos?.telefone,
    values?.contatos?.telefone,
  ]);

  useEffect(() => {
    const newStateArray = [...statusTelefoneExtra];

    if (errors?.contatos?.contatosExtras) {
      values?.contatos?.contatosExtras?.forEach((_, i) => {
        newStateArray[i] = '';
        if (touched?.contatos?.contatosExtras) {
          if (touched?.contatos?.contatosExtras[i]?.telefone) {
            if (
              errors?.contatos?.contatosExtras[i]?.telefone ===
              'Número de telefone inválido, favor tentar novamente.'
            ) {
              newStateArray[i] = 'warning';
            } else if (
              errors?.contatos?.contatosExtras[i]?.telefone ===
              'O telefone deve ser preenchido'
            ) {
              newStateArray[i] = 'error';
            } else {
              newStateArray[i] = '';
            }
          }
        }

        setStatusTelefoneExtra(newStateArray);

        handleTelefoneExtraType(
          values?.contatos?.contatosExtras[i]?.telefone,
          i,
        );
      });
    } else {
      setStatusTelefoneExtra([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touched?.contatos?.contatosExtras,
    errors?.contatos?.contatosExtras,
    values?.contatos?.contatosExtras,
  ]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="contatos.telefone"
            label="Telefone"
            validateStatus={statusTelefone}
            hasFeedback
          >
            <Field name="contatos.telefone">
              {({ field }) => <InputPhone {...field} disabled={acesso} />}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name={`contatos.contato.tipoTelefone`}
            label="Tipo de telefone"
          >
            <Select
              name={`contatos.contato.tipoTelefone`}
              suffixIcon={''}
              disabled={true}
              className="selectTipoTelefone"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {contexto?.tipoTelefoneExtra.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-lg-6">
          <Form.Item name="contatos.email" label="E-mail">
            <Input
              name="contatos.email"
              placeholder="Ex.: seu_e-mail@crefaz.com.br"
              data-cy="email"
              disabled={acesso}
            ></Input>
          </Form.Item>
        </div>
      </div>
      <div className="form-row add-telefones">
        {telefonesExtra &&
          telefonesExtra.map((item, i) => (
            <Fragment key={i}>
              <div className="col-lg-7" key={i}>
                <div className="form-row">
                  <div className="col-lg-6">
                    <Form.Item
                      name={`contatos.contatosExtras.${i}.telefone`}
                      label="Telefone extra"
                      validateStatus={statusTelefoneExtra[i]}
                      hasFeedback
                    >
                      <Field name={`contatos.contatosExtras.${i}.telefone`}>
                        {({ field }) => (
                          <InputPhone
                            {...field}
                            d
                            disabled={acesso}
                            onBlur={(e) =>
                              handleTelefoneExtraType(e.target.value, i)
                            }
                            data-cy={`telefone-extra-${i}`}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </div>
                  <div className="col-lg-6">
                    <Form.Item
                      name={`contatos.contatosExtras.${i}.tipoTelefone`}
                      label="Tipo de telefone"
                    >
                      <Select
                        id="tipoTelefone"
                        name={`contatos.contatosExtras.${i}.tipoTelefone`}
                        className="selectTipoTelefone"
                        suffixIcon=""
                        disabled={true}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        {contexto?.tipoTelefoneExtra?.map(
                          (tipoTelefoneExtra) => (
                            <Option
                              key={tipoTelefoneExtra.id}
                              value={tipoTelefoneExtra.id}
                            >
                              {tipoTelefoneExtra.nome}
                            </Option>
                          ),
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <Button
                  htmlType="button"
                  className="btnRemoveInputs"
                  onClick={() => handleRemoveTelefoneExtra(i)}
                >
                  <IconTrash />
                </Button>
              </div>
            </Fragment>
          ))}
      </div>
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={handleAddTelefonesExtra}
            data-cy="adiciona-telefone-extra"
          >
            <i className="fa fa-plus mr-2" /> Telefone
          </Button>
        </div>
      </div>
    </>
  );
}

export default ContatoForm;
