import React, { useContext } from 'react';

import InputMoney from '../../../components/InputMoney';
import TextFieldInteger from '../../../components/InputInteger';
import Modal from '../../../components/Modal';

import { Button } from 'antd';
import { Field, useFormik, FormikProvider } from 'formik';
import { Form, Input } from 'formik-antd';

import { TabelaJurosContext } from '../../../contexts/TabelaJurosContext';

import { validationForm } from './validationForm';

function ValoresFixosForm() {
  const {
    carenciaMedia,
    tableFormData,
    calcularValorParcela,
    salvarTabelaJurosValores,
    acesso,
    produtoId,
    setShowOverlayTabelaJuros,
  } = useContext(TabelaJurosContext);

  function formatarValorMonetario(str) {
    if (typeof str === 'string') {
      return Number(
        str
          .replace(/[R$.]+/g, '')
          .replace(',', '.')
          .trim(),
      );
    }
    return str;
  }

  const formikProps = useFormik({
    initialValues: tableFormData,
    validationSchema: validationForm,
    validateOnBlur: false,
    onSubmit: async (data, { resetForm, setFieldError }) => {
      data.valor = formatarValorMonetario(data.valor);
      if (data.valor === 0 || !data.valor) {
        setFieldError('valor', '');
        return;
      }
      const hasJuros = Math.ceil(
        parseFloat(data.juros.toString().replace(',', '.')),
      );
      if (!hasJuros) {
        setFieldError('juros', '');
        return;
      }
      if (produtoId) {
        setShowOverlayTabelaJuros(true);
        await calcularValorParcela({
          valor: data.valor,
          carencia: Number(carenciaMedia),
          plano: Number(data.plano),
          juros: data.jurosFloat,
          produtoId,
        })
          .then((response) => {
            const copy = Object.assign(data, { prestacao: response });
            salvarTabelaJurosValores(copy);
            setShowOverlayTabelaJuros(false);
            resetForm();
          })
          .catch(() => {
            setShowOverlayTabelaJuros(false);
            resetForm();
            return;
          });
      } else {
        Modal('', 'É necessário selecionar um produto antes', 'warning');
      }
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formikProps}>
      <Form layout="vertical" autoComplete="off">
        <Input type="hidden" name="id" />
        <Input type="hidden" name="tabelaJurosId" />
        <Input type="hidden" name="oldPlano" />
        <Input type="hidden" name="oldValor" />
        <Input type="hidden" name="edit" />

        <div className="form-row">
          <div className="col-md-3">
            <Form.Item name="plano" label="Plano">
              <Field name="plano">
                {({ field }) => (
                  <TextFieldInteger
                    {...field}
                    className="ant-input"
                    disabled={!acesso}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <Form.Item name="juros" label="Taxa">
              <Field name="juros">
                {({ field }) => (
                  <InputMoney
                    name="juros"
                    className="ant-input"
                    prefix=""
                    allowNegative={false}
                    decimalScale={4}
                    fixedDecimalScale={true}
                    disabled={!acesso}
                    {...field}
                    onValueChange={(values) => {
                      formikProps.setFieldValue(
                        'jurosFloat',
                        values.floatValue,
                      );
                    }}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <Form.Item name="valor" label="Valor">
              <Field name="valor">
                {({ field }) => (
                  <InputMoney
                    className="ant-input"
                    prefix="R$ "
                    allowNegative={false}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={!acesso}
                    {...field}
                  />
                )}
              </Field>
            </Form.Item>
          </div>

          <div className="col-md-3">
            <label>{'\u00A0'}</label>
            <Button
              htmlType="submit"
              type="primary"
              block
              style={{
                backgroundColor: '#263659',
                borderColor: '#263659',
                borderRadius: 0,
              }}
              disabled={!acesso}
            >
              <span className="text-uppercase">Adicionar</span>
            </Button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default ValoresFixosForm;
