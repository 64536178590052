import React, { useContext } from 'react';
import moment from 'moment';

import { ReactComponent as InfocardIcon } from '../../../assets/images/icons/infocard.svg';
import { ReactComponent as PickerIcon } from '../../../assets/images/icons/picker-icon.svg';
import { ReactComponent as MathIcon } from '../../../assets/images/icons/math-icon.svg';

import CardBordero from '../../../components/CardBordero';

import { BorderoContext } from '../../../contexts/BorderoContext';

import { cnpjFormatter } from '../../../utils/textFormatter';
import { currencyFormat } from '../../../utils/numberFormatter';

function CardsToolbarDetails() {
  const { cardsBordero } = useContext(BorderoContext);

  return (
    <div className="row cards-position">
      <div className="col-lg-4">
        <CardBordero
          icon={<InfocardIcon />}
          title="Informações"
          targetText={cardsBordero?.infoBasicas?.situacao}
          targetColor={cardsBordero?.infoBasicas?.situacaoCor}
        >
          <div className="row item">
            <div className="col-6 titulo">
              <p>ID borderô</p>
            </div>
            <div className="col-6 info">
              <p>{cardsBordero?.infoBasicas?.id}</p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>CNPJ</p>
            </div>
            <div className="col-6 info">
              <p>{cnpjFormatter(cardsBordero?.infoBasicas?.cnpj)}</p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Nome fantasia</p>
            </div>
            <div className="col-6 info">
              <p>{cardsBordero?.infoBasicas?.nomeFantasia}</p>
            </div>
          </div>
        </CardBordero>
      </div>
      <div className="col-lg-4">
        <CardBordero icon={<PickerIcon />} title="Datas">
          <div className="row item">
            <div className="col-6 titulo">
              <p>Geração</p>
            </div>
            <div className="col-6 info">
              <p>
                {cardsBordero?.datas?.geracao
                  ? moment(cardsBordero?.datas?.geracao).format('DD/MM/YYYY')
                  : '--'}
              </p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Integração</p>
            </div>
            <div className="col-6 info">
              <p>
                {cardsBordero?.datas?.integracao
                  ? moment(cardsBordero?.datas?.integracao).format('DD/MM/YYYY')
                  : '--'}
              </p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Pagamento</p>
            </div>
            <div className="col-6 info">
              <p>
                {cardsBordero?.datas?.confirmacaoPagamento
                  ? moment(cardsBordero?.datas?.confirmacaoPagamento).format(
                      'DD/MM/YYYY',
                    )
                  : '--'}
              </p>
            </div>
          </div>
        </CardBordero>
      </div>
      <div className="col-lg-4">
        <CardBordero
          icon={<MathIcon />}
          title="Saldo"
          targetText={currencyFormat(cardsBordero?.saldo?.valor)}
          targetColor="#9BAEBF"
        >
          <div className="row item">
            <div className="col-6 titulo">
              <p>Formalizadas</p>
            </div>
            <div className="col-6 info">
              <p>{cardsBordero?.saldo?.formalizadas}</p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Soma Formalizadas</p>
            </div>
            <div className="col-6 info">
              <p> {currencyFormat(cardsBordero?.saldo?.somaFormalizadas)}</p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Canceladas</p>
            </div>
            <div className="col-6 info">
              <p>{cardsBordero?.saldo?.canceladas}</p>
            </div>
          </div>

          <div className="row item">
            <div className="col-6 titulo">
              <p>Soma Canceladas</p>
            </div>
            <div className="col-6 info">
              <p>{currencyFormat(cardsBordero?.saldo?.somaCanceladas)}</p>
            </div>
          </div>
        </CardBordero>
      </div>
    </div>
  );
}

export default CardsToolbarDetails;
