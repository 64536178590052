import { object, string, number, array } from 'yup';
import * as Yup from 'yup';
import validaCPF from '../../utils/validaCpf';
import { bool } from 'yup';

Yup.addMethod(Yup.string, 'validateCPF', function (errorMessage) {
  return this.test(`test-cpf`, errorMessage, function (value) {
    const { path, createError } = this;

    if (value) {
      value = value.replace(/\D/g, '');
    } else {
      return true;
    }
    return validaCPF(value) || createError({ path, message: errorMessage });
  });
});

export const validationSchema = object().shape({
  ativo: bool().nullable(),
  bloqueado: bool().nullable(),
  dadosGerais: object().shape({
    tipoUsuarioId: number().required('Campo Obrigatório'),
    nome: string()
      .matches(/(\w.+\s).+/, 'Nome incompleto')
      .required('Campo Obrigatório')
      .nullable(),
    perfilUsuarioId: array()
      .required('Campo Obrigatório')
      .min(1, 'Campo Obrigatório'),
    cpf: string()
      .validateCPF('CPF inválido')
      .required('Campo Obrigatório')
      .nullable(),
    rg: string().nullable(),
    dataNascimento: string().required('Campo Obrigatório').nullable(),
    pep: bool().nullable(),
    login: string().required('Campo Obrigatório').nullable(),
    departamentoId: number().required('Campo Obrigatório').nullable(),
  }),
  endereco: object().shape({
    cep: string().nullable(),
    logradouro: string().nullable(),
    numero: string().nullable(),
    ufId: number().required('Campo Obrigatório').nullable(),
    cidadeId: number().required('Campo Obrigatório').nullable(),
    bairro: string().nullable(),
    complemento: string().nullable(),
  }),
  contato: object().shape({
    email: string().nullable(),
  }),
  dadosComerciais: object()
    .shape({
      termoMidia: bool().nullable(),
      atuacaoPromotora: bool().nullable(),
      codigoAgente: string().nullable(),
      classificacaoId: string().nullable(),
      matrizId: string().nullable(),
      redeSocial: string().nullable(),
    })
    .nullable(),
  certificacoes: Yup.array()
    .of(
      Yup.object().shape({
        certificacaoId: string().nullable(),
        tipoCertificacaoId: string()
          .nullable()
          .when('certificacaoId', {
            is: (certificacaoIdText) => certificacaoIdText?.length > 0,
            then: string().required('Campo obrigatório').nullable(),
          }),
      }),
    )
    .nullable(),
});
