import { useFormik } from 'formik';
import swal from 'sweetalert';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import {
  loadContexto,
  loadUsuarios,
  postUsuariosSemEmailList,
  postSendEmailRedefinirSenha,
} from '../services/usuariosService';

const initialValues = {
  filtroTipo: [],
  filtroCPF: null,
  filtroNome: null,
  filtroLogin: null,
  filtroMatriz: null,
  filtroUnidade: null,
  filtroAtivo: 1,
  filtroBloqueado: 2,
};

const blankValues = {
  filtroTipo: [],
  filtroCPF: '',
  filtroNome: '',
  filtroLogin: '',
  filtroMatriz: '',
  filtroUnidade: '',
  filtroAtivo: 1,
  filtroBloqueado: 2,
};

export const UsuariosContext = createContext({
  usuarios: [],
  loading: false,
  total: 0,
  pageCount: 0,
  filters: initialValues,
  showFilter: true,
  pageIndex: false,
  fetchUsuarios: async (f) => f,
  setShowFilter: (f) => f,
  setFilters: (f) => f,
});

function UsuariosProvider({ children }) {
  const [usuarios, setUsuarios] = useState([]);
  const [contexto, setContexto] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(blankValues);
  const [pageIndex, setPageIndex] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);

  const fetchContexto = async () => {
    const data = await loadContexto();
    setContexto(data);  
  };
  const verificaUsuariosSemEmail = async (idList) => {
    const data = await postUsuariosSemEmailList(idList);
    return data;
  };
  const sendEmailRedefinirSenha = async (idList) => {
    const data = await postSendEmailRedefinirSenha(idList);
    return data;
  };


  function validaSelectedEmails() {
    verificaUsuariosSemEmail(selectedUserList).then((response) => {
      if (response.length === 0) {
        swal(
          '',
          `Deseja enviar link de redefinição para o(s) ${selectedUserList.length} usuário(s) selecionado(s)?`,
          'warning',
          {
            className: 'redefinicao-senha-modal-usuarios',
            buttons: ['Não', 'Sim'],
          },
        ).then((response) => {
          if (response) {
            sendEmailRedefinirSenha(selectedUserList);
          }
        });
      } else {
        swal(
          '',
          `Não identificamos o(s) e-mail(s) para o envio do link de redefinição de senha dos seguintes usuários:

          ${response
            // eslint-disable-next-line
            .map((item) => (item.tipo == 'interno' ? item.login : item.matriz))
            .join('\n')}

          Deseja prosseguir com o processo de redefinição de senha?`,
          'warning',
          {
            className: 'redefinicao-senha-modal-usuarios-no-email-users',
            buttons: ['Não', 'Sim'],
          },
        ).then((responseSwal) => {
          if (responseSwal) {
            swal(
              '',
              `Deseja enviar link de redefinição para o(s) ${
                selectedUserList.length - response?.length
              } usuário(s) selecionado(s)?`,
              'warning',
              {
                className: 'redefinicao-senha-modal-usuarios',
                buttons: ['Não', 'Sim'],
              },
            ).then((response) => {
              if (response) {
                sendEmailRedefinirSenha(selectedUserList);
              }
            });
          }
        });
      }
    });
  }

  const fetchUsuarios = useCallback(
    async ({ pageIndex, filter, sortBy }) => {
      if (filters !==  blankValues) {
        setSelectedUserList([]);
        setLoading(true);
        const page = pageIndex + 1;

        const filtroAtivo =
          filter.filtroAtivo !== null
            ? filter.filtroAtivo === 1
              ? true
              : false
            : null;

        const filtroBloqueado =
          filter.filtroBloqueado !== null
            ? filter.filtroBloqueado === 1
              ? true
              : false
            : null;

        const response = await loadUsuarios({
          pagina: page || 1,
          filtroTipo: filter.filtroTipo,
          filtroCPF: filter.filtroCPF,
          filtroNome: filter.filtroNome,
          filtroLogin: filter.filtroLogin,
          filtroMatriz: filter.filtroMatriz,
          filtroUnidade: filter.filtroUnidade,
          filtroAtivo: filtroAtivo,
          filtroBloqueado: filtroBloqueado,
          ordenacao: sortBy[0]?.id || 'cadastro',
          ordenacaoAsc: sortBy[0]?.desc,
        });
        if (response.data.itens) {
          setUsuarios(response.data.itens);
        } else {
          setUsuarios([]);
        }

        setTotal(response.data.total);
        setPageCount(response.data.totalPages);
        setLoading(false);
      }
    },
    [filters],
  );

  const formik = useFormik({
    initialValues,
  });

  useEffect(() => {
    fetchContexto();
  }, []);

  return (
    <UsuariosContext.Provider
      value={{
        formik,
        usuarios,
        fetchUsuarios,
        pageCount,
        total,
        loading,
        filters,
        initialValues,
        blankValues,
        contexto,
        setFilters,
        pageIndex,
        setPageIndex,
        selectedUserList,
        setSelectedUserList,
        validaSelectedEmails,
      }}
    >
      {children}
    </UsuariosContext.Provider>
  );
}

export default UsuariosProvider;
