import React, { useContext, useState } from 'react';

import { Form, Input } from 'formik-antd';
import InputMask from 'react-text-mask';
import Select from '../../../components/Select';
import { Option } from 'antd/lib/mentions';
import Button from '../../../components/Button';
import { phoneMask } from '../../../utils/masks';
import './styles.scss';

import { ReactComponent as IconPlusButton } from '../../../assets/images/icons/add.svg';
import { ReactComponent as TrashIcon } from '../../../assets/images/icons/trash2.svg';
import { CredenciadoContext } from '../../../contexts/CadastroCredenciamentoContext';

const { TextArea } = Input;

function ContatoForm({
  formik,
  action = 'visualizacao',
  userId,
  contatoContext,
  isReadOnly,
}) {
  const {
    validaDuasPalavras,
    initialValuesContato,
  } = useContext(CredenciadoContext);

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    getFieldMeta,
    getFieldHelpers,
    getFieldProps,
  } = formik;

  const [contatos, setContatos] = useState(() => formik?.values?.contato);

  function addContact() {
    setFieldValue('contato', [
      ...values?.contato,
      {
        nome: null,
        email: null,
        telefone: null,
        tipo: null,
        observacoes: null,
      },
    ]);
    const newList = [...contatos, ...initialValuesContato];
    setContatos(newList);
  }

  function removeContact(index) {
    const newList = getFieldProps('contato').value;
    const newTouched = { ...touched };
    newList?.splice(index, 1);
    newTouched.contato?.splice(index, 1);
    setContatos([...newList]);
    setTouched({ ...newTouched });
    setFieldValue(`contato`, [...newList]);
  }

  function genericValidate(name, type) {
    // função criada para atualizar o status e message dos componentes que usam máscara
    const { error, touched } = getFieldMeta(name);
    if (type === 'status') {
      if (error && touched) {
        return error === 'Campo Obrigatório' ? 'error' : 'warning';
      } else {
        return null;
      }
    } else {
      if (error && touched) {
        return error;
      } else {
        return null;
      }
    }
  }
  function genericTouched(fullName) {
    // função criada para que os campos com máscara, que não alteram o touched por padrão, consigam alterar o touched.
    getFieldHelpers(fullName).setTouched(true);
  }

  return (
    <>
      <Form layout="vertical">
        {contatos?.length ? (
          contatos?.map((contato, index) => (
            <div className="contact row" key={'contact-item' + index}>
              <div
                className={
                  contatos?.length > 1
                    ? 'contact-form col-lg-11'
                    : 'contact-form col-lg-12'
                }
              >
                <div className="row">
                  <div className="col-lg-6 item-dados">
                    <Form.Item
                      label="Nome"
                      name={`contato[${index}].nome`}
                    >
                      <Input
                        name={`contato[${index}].nome`}
                        onBlur={(e) => validaDuasPalavras(e, 'Nome')}
                        indexValue={index}
                        value={values.contato[index].nome}
                        onChange={(event) =>
                          setFieldValue(
                            `contato[${index}].nome`,
                            event.target.value,
                          )
                        }
                        placeholder="Digite aqui"
                        disabled={isReadOnly}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-6 item-dados">
                    <Form.Item
                      label="E-mail"
                      name={`contato[${index}].email`}
                      validateStatus={genericValidate(
                        `contato[${index}].email`,
                        'status',
                      )}
                    >
                      <Input
                        name={`contato[${index}].email`}
                        indexValue={index}
                        value={values.contato[index].email}
                        onChange={(event) =>
                          setFieldValue(
                            `contato[${index}].email`,
                            event.target.value,
                          )
                        }
                        placeholder="Digite aqui"
                        disabled={isReadOnly}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 double-item-dados">
                    <Form.Item
                      label="Telefone"
                      name={`contato[${index}].telefone`}
                      help={genericValidate(
                        `contato[${index}].telefone`,
                        'message',
                      )}
                      validateStatus={genericValidate(
                        `contato[${index}].telefone`,
                        'status',
                      )}
                    >
                      <InputMask
                        className="ant-input"
                        name={`contato[${index}].telefone`}
                        value={values.contato[index].telefone}
                        onChange={(event) =>
                          setFieldValue(
                            `contato[${index}].telefone`,
                            event.target.value,
                          )
                        }
                        onBlur={() =>
                          genericTouched(`contato[${index}].telefone`)
                        }
                        indexValue={index}
                        mask={phoneMask}
                        placeholder="(00) 00000-0000"
                        disabled={isReadOnly}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Tipo de Contato"
                      name={`contato[${index}].tipoContato`}
                      indexValue={index}
                      validate={() => {
                        return false;
                      }}
                    >
                      <Select
                        name={`contato[${index}].tipoContato`}
                        onChange={(value) =>
                          setFieldValue(`contato[${index}].tipoContato`, value)
                        }
                        indexValue={index}
                        value={values.contato[index].tipoContato}
                        placeholder="Selecione"
                        allowClear
                        disabled={isReadOnly}
                      >
                        {contatoContext?.tipoContato?.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-lg-8 item-dados">
                    <Form.Item
                      label="Observações"
                      name={`contato[${index}].observacao`}
                    >
                      <TextArea
                        name={`contato[${index}].observacao`}
                        value={values.contato[index].observacao}
                        rows={4}
                        onChange={(e) =>
                          setFieldValue(
                            `contato[${index}].observacao`,
                            e.target.value,
                          )
                        }
                        placeholder="Digite aqui seu texto..."
                        disabled={isReadOnly}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {contatos.length > 1 ? (
                <div
                  className="delete-contact col-lg-1 item-dados"
                  style={{
                    display: isReadOnly && 'none',
                  }}
                >
                  <Button
                    onClick={() => removeContact(index)}
                    className="btn btn-trash"
                  >
                    <TrashIcon />
                  </Button>
                </div>
              ) : (
                ''
              )}
              {contatos.length > 1 && index > 0 ? (
                <div className="gray-line"></div>
              ) : (
                ''
              )}
            </div>
          ))
        ) : (
          <></>
        )}

        <div
          className="row"
          style={{
            display: isReadOnly && 'none',
          }}
        >
          <div className="col-lg-4">
            <Button
              onClick={() => addContact()}
              className="btn-acao btn-enviar btn-plus-contato"
            >
              <IconPlusButton />
              Contato
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ContatoForm;
