import { React } from 'react';

import UsuariosProvider from '../../contexts/UsuariosContext';

import Layout from '../../layouts/Main';
import { Breadcrumb } from 'antd';
import PageHeading from '../../components/PageHeading';
import useInspinia from '../../hooks/useInspinia';
import Content from '../../components/Content';

import './styles.scss';
import { UsuariosPage } from './UsuariosPage';

function ListagemUsuarios() {
  useInspinia();

  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Usuários</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <UsuariosProvider>
          <UsuariosPage />
        </UsuariosProvider>
      </Content>
    </Layout>
  );
}

export default ListagemUsuarios;
