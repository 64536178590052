import React from 'react';

import ButtonSituacao from '../../../components/ButtonSituacao';

import Icon from '../../../components/Icon';
import { ReactComponent as Writing } from '../../../assets/images/icons/writing.svg';
import './styles.scss';
import moment from 'moment';
import { cpfFormatter } from '../../../utils/textFormatter';

export const columns = [
  {
    Header: 'Alteração',
    accessor: 'dataProposta',
    style: {
      minWidth: '114px',
      fontSize: '13px',
    },
    Cell: ({ value }) => (
      <div  style={{ textAlign: 'center' }}>
        {moment(value).format('DD/MM/YYYY HH:mm')}
      </div>
    ),
  },
  {
    Header: 'Produto',
    accessor: 'produtoNome',
    style: {
      minWidth: '84px',
      fontSize: '13px',
    },
  },
  {
    Header: 'CPF',
    accessor: 'cpf',
    style: {
      minWidth: '117px',
      fontSize: '13px',
    },
    Cell: ({ value }) => {
      return cpfFormatter(value);
    },
  },
  {
    Header: 'Cliente',
    accessor: 'nome',
    style: {
      minWidth: '200px',
      fontSize: '13px',
    },
  },
  {
    Header: 'Tipo',
    accessor: 'tipoCliente',
    style: {
      minWidth: '110px',
      fontSize: '13px',
    },
  },
  {
    Header: 'Modalidade',
    accessor: 'modalidadeNome',
    style: {
      minWidth: '200px',
      fontSize: '13px',
    },
    Cell: ({ value }) => {
      return (
        <div>
          <Writing className="writing-icon" /> {value}
        </div>
      );
    },
  },

  {
    Header: (
      <span class="proposal-listing-clock-icon">
        <Icon variant="clock" className="icon-title" /> Espera
      </span>
    ),
    accessor: 'tempoEspera',
    style: {
      minWidth: '93px',
      fontSize: '13px',
    },
    Cell: (e) => {
      var color = 'white';
      var textColor = '#686B6D';
      var iconName = 'icon-table-text';

      if (e.row.original.tempoEsperaCor) {
        color = e.row.original.tempoEsperaCor;
        textColor = 'white';
        iconName = 'icon-table-white';
      }

      return (
        <div
          className="Container"
          style={{
            background: color,
            width: 'auto',
            minWidth: '75px',
            display: 'inline-block',
            fontSize: '12px',
            textAlign: 'center',
            borderRadius: 2.5,
            border: '1px solid #DEE2E6',
            color: textColor,
            fontWeight: 400,
            padding: '1px 6px',
            whiteSpace: 'nowrap',
          }}
        >
          <Icon variant="clock" className={iconName} />
          {e.row.values.tempoEspera}
        </div>
      );
    },
  },
  
  {
    Header: (
      <span class="proposal-listing-clock-icon">
        <Icon variant="clock" className="icon-title" /> Distribuição
      </span>
    ),
    accessor: 'tempoDistribuicao',
    style:{
      minWidth: '128px',
      fontSize: '13px',
  },
    Cell: (e) => {
          var color = 'white';
          var textColor = '#686B6D';
          var iconName = 'icon-table-text';
    
          if (e.row.original.tempoDistribuicaoCor) {
            color = e.row.original.tempoDistribuicaoCor;
            textColor = 'white';
            iconName = 'icon-table-white';
          }

      return (
        <div
          className="Container"
          style={{
            background: color,
            width: 'auto',
            minWidth: '75px',
            display: 'inline-block',
            fontSize: '12px',
            textAlign: 'center',
            borderRadius: 2.5,
            border: '1px solid #DEE2E6',
            color: textColor,
            fontWeight: 400,
            padding: '1px 6px',
            whiteSpace: 'nowrap',
          }}
        >
           <Icon variant="clock" className={iconName} />
           {e.row.values.tempoDistribuicao}         
        </div>
      );
    },
  },

  {
    Header: (
      <span class="proposal-listing-clock-icon">
        <Icon variant="clock" className="icon-title" /> Análise
      </span>
    ),
    accessor: 'tempoAnalise',
    style: {
      minWidth: '96px',
      fontSize: '13px',
    },
       Cell: (e) => {
      var color = 'white';
      var textColor = '#686B6D';
      var iconName = 'icon-table-text';
      if (e.row.original.tempoAnaliseCor) {
        color = e.row.original.tempoAnaliseCor;
        textColor = 'white';
        iconName = 'icon-table-white';
      }

      return (
        <div
          className="Container"
          style={{
            background: color,
            width: 'auto',
            minWidth: '75px',
            display: 'inline-block',
            fontSize: '12px',
            textAlign: 'center',
            borderRadius: 2.5,
            border: '1px solid #DEE2E6',
            color: textColor,
            fontWeight: 400,
            padding: '1px 6px',
            whiteSpace: 'nowrap',
          }}
        >
          <Icon variant="clock" className={iconName} />
          {e.row.values.tempoAnalise}
        </div>
      );
    },
  },

  {
    Header: 'Status',
    accessor: 'etapaNome',
    style: {
      minWidth: '150px',
      fontSize: '13px',
    },

    Cell: (e) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <ButtonSituacao
            link={`/analise-mesa-credito/${e.row.original.id}`}
            bgColor={e.row.original.situacaoCor}
          >
            {e.row.original.situacaoDescricao}
          </ButtonSituacao>
        </div>
      );
    },
  },
];
