import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

function Pagination({
  totalRecords = 0,
  pageIndex,
  pageCount,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageNeighbours = 0,
}) {
  totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

  // pageNeighbours can be: 0, 1 or 2
  pageNeighbours =
    typeof pageNeighbours === 'number'
      ? Math.max(0, Math.min(pageNeighbours, 2))
      : 0;

  const fetchPageNumbers = () => {
    const _totalPages = pageCount;
    const _currentPage = pageIndex;
    const _pageNeighbours = pageNeighbours;

    const totalNumbers = pageNeighbours * 2 + 1;
    const totalBlocks = totalNumbers + 2;

    if (_totalPages > totalBlocks) {
      const startPage = Math.max(2, _currentPage - _pageNeighbours);
      const endPage = Math.min(_totalPages - 1, _currentPage + _pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = _totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, _totalPages];
    }

    return range(1, _totalPages);
  };

  const pages = fetchPageNumbers();

  if (!totalRecords || pageCount === 1) return null;

  return (
    <Fragment>
      <nav>
        <ul className="pagination">
          <li className="page-item">
            <a
              href="/"
              disabled={!canPreviousPage}
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                gotoPage(0);
              }}
            >
              <i className="fa fa-angle-double-left" />
            </a>
          </li>

          <li className="page-item">
            <a
              href="/"
              disabled={!canPreviousPage}
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                previousPage();
              }}
            >
              <span>Anterior</span>
            </a>
          </li>

          {pages.map((page, index) => {
            if (page === LEFT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a
                    href="/"
                    title="Anterior"
                    className="page-link"
                    onClick={(e) => {
                      e.preventDefault();
                      previousPage();
                    }}
                  >
                    <span aria-hidden="true">
                      <i className="fa fa-ellipsis-h" />
                    </span>
                    <span className="sr-only">Anterior</span>
                  </a>
                </li>
              );
            }

            if (page === RIGHT_PAGE) {
              return (
                <li key={index} className="page-item">
                  <a
                    href="/"
                    className="page-link"
                    title="Próxima"
                    onClick={(e) => {
                      e.preventDefault();
                      nextPage();
                    }}
                  >
                    <span aria-hidden="true">
                      <i className="fa fa-ellipsis-h" />
                    </span>
                    <span className="sr-only">Próxima</span>
                  </a>
                </li>
              );
            }

            return (
              <li
                key={index}
                className={`page-item${pageIndex === page ? ' active' : ''}`}
              >
                <a
                  href="/"
                  className="page-link"
                  onClick={(e) => {
                    e.preventDefault();
                    gotoPage(page - 1);
                  }}
                >
                  {page}
                </a>
              </li>
            );
          })}

          <li className="page-item">
            <a
              href="/"
              className="page-link"
              disabled={!canNextPage}
              onClick={(e) => {
                e.preventDefault();
                nextPage();
              }}
            >
              <span>Próxima</span>
            </a>
          </li>

          <li className="page-item">
            <a
              href="/"
              className="page-link"
              disabled={!canNextPage}
              onClick={(e) => {
                e.preventDefault();
                gotoPage(pageCount - 1);
              }}
            >
              <i className="fa fa-angle-double-right" />
            </a>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageNeighbours: PropTypes.number,
};

export default Pagination;
