import React from 'react';
import MoneyInput from '@rschpdr/react-money-input';

function InputCurrency({ ...props }) {
  const config = {
    locale: 'pt-BR',
    currencyCode: 'BRL',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  };

  return (
    <MoneyInput
      {...props}
      max={99999999999}
      className="ant-input"
      currencyConfig={config}
      style={{ textAlign: 'right' }}
    />
  );
}

export default InputCurrency;
