import React, { createContext, useCallback, useState, useEffect } from 'react';

import {
  listarNotasFiscais,
  listarMatrizes,
  arquivoNotaFiscal,
  inserirNotas,
  verificaNota,
} from '../services/notasFiscaisService';

const initialFilters = {};

export const NotasFiscaisContext = createContext({
  notasFiscais: [],
  loading: false,
  total: 0,
  pageCount: 0,
  filters: initialFilters,
  showFilter: false,
  matrizes: [],
  downloadNotaFiscal: (f) => f,
  setShowFilter: (f) => f,
  setFilters: (f) => f,
  setMatrizes: (f) => f,
});

function NotasFiscaisProvider({ children }) {
  const [notasFiscais, setNotasFiscais] = useState([]);
  const [matrizes, setMatrizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [showFilter, setShowFilter] = useState(false);
  const [pageIndex, setPageIndex] = useState(false);
  const [arquivosNotas, setArquivosNotas] = useState([]);
  const [disableDownload, setDisableDownload] = useState(false);

  const fetchNotasFiscais = useCallback(
    async ({ pageIndex, filter, sortBy }) => {
      setLoading(true);
      const page = pageIndex + 1;

      const response = await listarNotasFiscais({
        pagina: page || 1,
        quantidadePorPagina: 15,
        ordenacao: sortBy?.[0],
        filtroMesAnoReferencia: filter?.filtroMesAnoReferencia,
        filtroCNPJMatriz: filter?.filtroCNPJMatriz,
        filtroMatriz: filter?.filtroMatriz,
        filtroStatusVerificacao: filter?.filtroStatusVerificacao,
        filtroDataInicial: filter?.filtroDataInicial,
        filtroDataFinal: filter?.filtroDataFinal,
      });

      if (response?.data?.itens) {
        setNotasFiscais(response?.data?.itens);
      } else {
        setNotasFiscais([]);
      }

      setTotal(response?.data?.total);
      setPageCount(response?.data?.totalPages);
      setLoading(false);
    },
    [],
  );

  function base64ToArrayBuffer(base64) {
    var binary_string = atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const downloadNotaFiscal = useCallback(async (id) => {
    setDisableDownload(true);
    const response = await arquivoNotaFiscal(id)
      .then((response) => {
        const file = base64ToArrayBuffer(response.data.data.conteudo);

        const url = window.URL.createObjectURL(
          new Blob([file], {
            type: response.data.data.tipo,
          }),
        );

        let fileName = response.data.data.nome;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setDisableDownload(false);
      });
    setDisableDownload(false);

    return response;
  }, []);

  const fetchMatrizes = async () => {
    setOverlay(true);
    const response = await listarMatrizes();
    setMatrizes(response?.data?.matriz);
    setOverlay(false);
  };

  const inserirNotasFiscais = async () => {
    const notas = arquivosNotas.map((nota) => ({
      imagemId: nota?.id,
      mesAnoReferencia: nota?.dataReferencia || '',
      nomeArquivo: nota?.nome,
    }));

    const { data } = await inserirNotas(notas);

    if (data) {
      setArquivosNotas([]);
    }
  };

  useEffect(() => {
    fetchMatrizes();
  }, []);

  return (
    <NotasFiscaisContext.Provider
      value={{
        notasFiscais,
        loading,
        overlay,
        total,
        pageCount,
        filters,
        showFilter,
        initialFilters,
        pageIndex,
        matrizes,
        arquivosNotas,
        downloadNotaFiscal,
        fetchNotasFiscais,
        setShowFilter,
        setFilters,
        setPageIndex,
        setArquivosNotas,
        inserirNotasFiscais,
        verificaNota,
        disableDownload,
        setDisableDownload,
      }}
    >
      {children}
    </NotasFiscaisContext.Provider>
  );
}

export default NotasFiscaisProvider;
