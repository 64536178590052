import React, { Fragment, useState } from 'react';

import Button from '../../../components/Button';
import Title from '../../../components/Title';

import Filter from './Filter';

import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';

import '../styles.scss';

function Toolbar() {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4">
          <Title label="Gerenciamento de Borderô" />
        </div>

        <div className="col-lg-8 d-flex justify-content-end">
          <Button
            type="button"
            variant="gray"
            onClick={() => handleShowFilter()}
          >
            <FilterIcon className="filter-icon" />
            <span>FILTRAR</span>
          </Button>
        </div>
      </div>

      {showFilter && (
        <div className="row">
          <div className="col-lg-12">
            <Filter />
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Toolbar;
