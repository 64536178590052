import './styles.scss';
import React from 'react';
import PageHeading from '../../components/PageHeading';
import { Breadcrumb } from 'antd';
import Layout from '../../layouts/Main';
import CredenciadoProvider from '../../contexts/CadastroCredenciamentoContext';
import Content from '../../components/Content';
import CredenciamentoPage from './CredenciamentoPage';

export default function Credenciamento() {
  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>Cadastro</Breadcrumb.Item>
          <Breadcrumb.Item>Credenciamento</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <CredenciadoProvider>
        <Content>
          <CredenciamentoPage />
        </Content>
      </CredenciadoProvider>
    </Layout>
  );
}
