import React, { Fragment, useContext, useState, useEffect } from 'react';

import { Field } from 'formik';
import { Form, Input } from 'formik-antd';

import InputMask from 'react-text-mask';

import { phoneMask } from '../../../utils/masks';

import { PropostaCreditoContext } from '../../../contexts/PropostaCreditoContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

function DadosMatrizForm({disabledFields}) {
  const { validationField, editableField } = useContext(PropostaCreditoContext);

  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(checarPermissao('tela', 'tela.propostacredito', 'Editar'));
  }, [checarPermissao]);

  return (
    <Fragment>
      <div className="form-row pt-4 pb-3">
        <div className="Container ContainerColor mt-1"></div>
        <div className="row pl-4 titleMsg">Informações da Matriz</div>
      </div>

      <div className="form-row">
        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="unidade.nomeMatriz"
            label="Nome da matriz"
            validate={(value) =>
              validationField(value, 'unidade.nomeMatriz', 'Nome da matriz')
            }
          >
            <Input
              name="unidade.nomeMatriz"
              placeholder="Nome matriz"
              disabled={
                (acesso ? !editableField('unidade.nomeMatriz') : true)
                || disabledFields
              }
            ></Input>
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="unidade.loginAgente"
            label="Login do agente"
            validate={(value) =>
              validationField(value, 'unidade.loginAgente', 'Login do agente')
            }
          >
            <Input
              name="unidade.loginAgente"
              disabled={
                (acesso ? !editableField('unidade.loginAgente') : true)
                || disabledFields
              }
            />
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="unidade.nomeSupervisor"
            label="Supervisor comercial"
            validate={(value) =>
              validationField(
                value,
                'unidade.nomeSupervisor',
                'Supervisor comercial',
              )
            }
          >
            <Input
              name="unidade.nomeSupervisor"
              disabled={
                (acesso ? !editableField('unidade.nomeSupervisor') : true)
                || disabledFields
              }
            />
          </Form.Item>
        </div>

        <div className="col-md-4 col-lg-4 col-xl-3">
          <Form.Item
            name="unidade.celularSupervisor"
            label="Celular do supervisor"
            validate={(value) =>
              validationField(
                value,
                'unidade.celularSupervisor',
                'Celular',
              )
            }
          >
            <Field name="unidade.celularSupervisor">
              {({ field }) => (
                <InputMask
                  {...field}
                  className="ant-input"
                  placeholder="Ex.: (00) 0000-0000"
                  mask={phoneMask}
                  showMask={true}
                  disabled={
                    (acesso ? !editableField('unidade.celularSupervisor') : true)
                    || disabledFields
                  }
                />
              )}
            </Field>
          </Form.Item>
        </div>
      </div>
    </Fragment>
  );
}

export { DadosMatrizForm };
