import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import Main from '../../layouts/Main';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';

import ListagemHistoricoGravacoes from './Listagem';

import HistoricoGravacoesProvider from '../../contexts/HistoricoGravacoesContext';

import useInspinia from '../../hooks/useInspinia';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function HistoricoGravacoes() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Main>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>BackOffice</Breadcrumb.Item>
          <Breadcrumb.Item>Telefonia</Breadcrumb.Item>
          <Breadcrumb.Item>Histórico de Chamadas</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>

      <Content>
        <HistoricoGravacoesProvider>
          <div className="row">
            {checarPermissao(
              'tela',
              'tela.backoffice.telefonia.historico',
              'Visualizar',
            ) && (
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ListagemHistoricoGravacoes />
              </div>
            )}
          </div>
        </HistoricoGravacoesProvider>
      </Content>
    </Main>
  );
}

export default HistoricoGravacoes;
