import React from 'react';

import Tooltip from '../../../components/tooltip';

const TooltipTitleArvore = ({ text, children }) => {
  return (
    <Tooltip
      placement="topLeft"
      arrowPointAtCenter
      overlayClassName="tooltip-styled-crefaz tree-hierarquia-tooltip-option"
      autoAdjustOverflow={false}
      color="#435361"
      title={
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '19px',
            padding: '2px',
          }}
        >
          {text}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default TooltipTitleArvore;
