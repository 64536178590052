import React, { useContext, useEffect, useState } from 'react';

import { Form, Input, Select, Field } from 'formik-antd';
import InputMoney from 'react-number-format';

import InputPhone from '../../../../components/InputPhone';
import SelectSearch from '../../../../components/SearchSelect';

import { CadastroPessoaContext } from '../../../../contexts/CadastroPessoaContext';
import { ControleAcessoContext } from '../../../../contexts/ControleAcessoContext';

const { Option } = Select;

function DadosProfissionaisForm({ formik }) {
  const { contexto } = useContext(CadastroPessoaContext);
  const { touched, errors, setFieldValue, values } = formik;
  const [statusTelefoneRH, setStatusTelefoneRH] = useState('');
  const [acesso, setAcesso] = useState(false);

  const { checarPermissao } = useContext(ControleAcessoContext);

  useEffect(() => {
    setAcesso(
      !checarPermissao(
        'aba',
        'aba.cadastroPessoa.cliente.profissionais',
        'Editar',
      ),
    );
  }, [checarPermissao]);

  useEffect(() => {
    if (errors?.profissao?.telefoneRH) {
      setStatusTelefoneRH('warning');
    } else {
      setStatusTelefoneRH('');
    }
  }, [errors, touched]);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="profissao.empresa"
            label="Empresa ou Órgão"
            hasFeedback
          >
            <Input
              name="profissao.empresa"
              placeholder="Crefaz"
              data-cy="empresa"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="profissao.ocupacaoId"
            label="Classificação Profissional"
            hasFeedback
          >
            <SelectSearch
              name="profissao.ocupacaoId"
              placeholder="Empresa Privada"
              showSearch
              data-cy="classificacao"
              disabled={acesso}
              onChange={(value) => setFieldValue('profissao.ocupacaoId', value)}
              value={values?.profissao?.ocupacaoId}
            >
              {contexto?.ocupacao?.map((ocupacao) => (
                <Option key={ocupacao.id} value={ocupacao.id}>
                  {ocupacao.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item name="profissao.profissaoId" label="Profissão" hasFeedback>
            <SelectSearch
              name="profissao.profissaoId"
              placeholder="Secretária"
              showSearch
              data-cy="profissao"
              disabled={acesso}
              onChange={(value) =>
                setFieldValue('profissao.profissaoId', value)
              }
              value={values?.profissao?.profissaoId}
            >
              {contexto?.profissao?.map((profissao) => (
                <Option key={profissao.id} value={profissao.id}>
                  {profissao.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="profissao.tempoempregoId"
            label="Tempo de Emprego Atual"
            hasFeedback
          >
            <SelectSearch
              name="profissao.tempoempregoId"
              placeholder="Menos que 1 ano"
              showSearch
              disabled={acesso}
              data-cy="tempo-emprego"
              onChange={(value) =>
                setFieldValue('profissao.tempoempregoId', value)
              }
              value={values?.profissao?.tempoempregoId}
            >
              {contexto?.tempoEmprego?.map((tempoEmprego) => (
                <Option key={tempoEmprego.id} value={tempoEmprego.id}>
                  {tempoEmprego.nome}
                </Option>
              ))}
            </SelectSearch>
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-3">
          <Form.Item
            name="profissao.telefoneRH"
            label="Telefone RH da Empresa"
            validateStatus={statusTelefoneRH}
            hasFeedback
          >
            <Field name="profissao.telefoneRH">
              {({ field }) => (
                <InputPhone
                  {...field}
                  data-cy="telefone-rh"
                  disabled={acesso}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item name="profissao.pis" label="PIS/PASEP" hasFeedback>
            <Input
              name="profissao.pis"
              placeholder="Ex.: xxxx"
              data-cy="pis"
              disabled={acesso}
            />
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item name="profissao.renda" label="Renda" hasFeedback>
            <Field name="profissao.renda">
              {({ field, form }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  data-cy="renda"
                  style={{ textAlign: 'right' }}
                  onValueChange={(values) =>
                    form.setFieldValue(
                      'profissao.floatRenda',
                      values.floatValue,
                    )
                  }
                  disabled={acesso}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-2">
          <Form.Item
            name="profissao.outrasRendas"
            label="Outras Rendas"
            hasFeedback
          >
            <Field name="profissao.outrasRendas">
              {({ field, form }) => (
                <InputMoney
                  {...field}
                  className="ant-input"
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  maxLength={12}
                  style={{ textAlign: 'right' }}
                  data-cy="outras-rendas"
                  onValueChange={(values) =>
                    form.setFieldValue(
                      'profissao.floatOutrasRendas',
                      values.floatValue,
                    )
                  }
                  disabled={acesso}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-3">
          <Form.Item
            name="profissao.tipoOutrasRendas"
            label="Tipo Outras Rendas"
            hasFeedback
          >
            <Input
              name="profissao.tipoOutrasRendas"
              placeholder="Ex.: Venda de perfume"
              data-cy="tipo-outras-rendas"
              disabled={acesso}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default DadosProfissionaisForm;
