import React, { useEffect, useRef, useState } from 'react';

function Input({
  name,
  value,
  className,
  uppercase = false,
  formatWhitespace = false,
  onBlur = () => {},
  ...props
}) {
  const [valor, setValor] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (value !== null && typeof value !== 'undefined') setValor(value);
  }, [value]);

  function validaInput(el) {
    const cursorPosition = inputRef.current.selectionStart;

    if (formatWhitespace) {
      const hasTwoSubsequentWhitespaces = /\s{2,}/.test(el.target.value);
      if (hasTwoSubsequentWhitespaces && el.target.value !== ' ') {
        setTimeout(() => {
          el.target.value = String(el.target.value).replace(/\s{2,}/g, ' ');
        }, 50);
      } else if (el.target.value === ' ') {
        setTimeout(() => {
          el.target.value = '';
        }, 50);
      }
    }
    if (uppercase) {
      setValor(String(el.target.value).toUpperCase());
    } else {
      setValor(el.target.value);
    }
    // delay solicitado pelo cliente
    setTimeout(() => {
      if (/[&'"<>]/gm.test(el.target.value)) {
        const matches = el.target.value.matchAll('[&\'"<>]');
        let tempValor = el.target.value;
        for (const match of matches) {
          tempValor = tempValor.replace(match[0], '');
        }
        if (uppercase) {
          setValor(String(tempValor).toUpperCase());
        } else {
          setValor(tempValor);
        }
      }
    }, 100);

    // para o cursor não ir para o fim do input ao inserir caracteres no meio da string
    setTimeout(() => {
      try {
        inputRef?.current?.focus();
        inputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
      } catch {}
    }, 1);
  }

  useEffect(() => {
    const event = { target: { value: valor } };
    if (valor) {
      validaInput(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      {...props}
      onBlur={(el) => {
        onBlur(el);
        if (formatWhitespace) {
          const str = String(el.target.value).trim();
          el.target.value.trim();
          setValor(str);
        }
      }}
      name={name}
      value={valor}
      className={'ant-input ' + className}
      onChange={validaInput}
      ref={inputRef}
    />
  );
}

export default Input;
