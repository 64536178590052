import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function Etapa({ value }) {
  switch (value) {
    case 1:
      return <span className="badge badge-primary badge-status">Aprovado</span>;

    case 2:
      return (
        <span className="badge badge-warning badge-status">Em análise</span>
      );

    case 3:
      return <span className="badge badge-danger badge-status">Negado</span>;

    case 4:
      return <span className="badge badge-gray badge-status">Pendente</span>;

    default:
      return null;
  }
}

Etapa.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Etapa;
