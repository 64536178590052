import React, { useContext } from 'react';

import { AcompanhamentoCredenciamentoContext } from '../../../contexts/AcompanhamentoCredenciamentoContext';

import WidgetAcompanhamentoCredenciamento from '../../../components/WidgetAcompanhamentoCredenciamento';
import './styles.scss';

function CardsRow() {
  const { cards, total } = useContext(
    AcompanhamentoCredenciamentoContext,
  );
  return (
    <div className="cards-credenciamento">
      {cards?.length > 0 &&
        cards?.map((card, i) => (
          <div key={i}>
            <WidgetAcompanhamentoCredenciamento
              title={card.etapaNome}
              qtdePropostas={card.qtd}
              direcionadas=""
              color={card.etapaCor}
              total={total}
            />
          </div>
        ))}
    </div>
  );
}

export default CardsRow;
