import React from 'react';

import { ReactComponent as Icon } from '../../../src/assets/images/icons/config.svg';

import Tooltip from '../tooltip';
import Button from '../Button';

import './styles.scss';

function IconConfig({ onClick, className, tooltip = '' }) {
  return (
    tooltip !== '' ? (
      <Tooltip
        placement="top"
        arrowPointAtCenter
        color="#435361"
        title="Blocklist"
      >
        <Button className={'btn-config ' + className} onClick={onClick}>
          <Icon className="config-icon" width="8" height="8" />
        </Button>
      </Tooltip>
    ) : (
      <Button className={'btn-config ' + className} onClick={onClick}>
        <Icon className="config-icon" width="8" height="8" />
      </Button>
    )
  );
}

export default IconConfig;
