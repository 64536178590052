import React, { useMemo, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
} from 'react-table';

import Pagination from '../Pagination';
import Overlay from '../Overlay';

import './styles.scss';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

function Table({
  columns,
  data,
  onLoadData,
  pageCount: controlledPageCount,
  className,
  total,
  loading,
  header,
  pagination,
  height,
  filter,
  setSelectedRows,
}) {
  const COLUMNS = useMemo(() => columns, [columns]);
  const DATA = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      data: DATA,
      columns: COLUMNS,
      pageCount: controlledPageCount,
      initialState: { pageIndex: 0 },
      manualSortBy: true,
      manualPagination: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <div style={{ textAlign: 'center', minWidth: 35 }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableSortBy: true,
          style: {
            textAlign: 'center',
            minWidth: 35,
          },
        },
      ]);
    },
  );

  const items = pagination ? page : rows;

  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  useEffect(() => {
    setSelectedRows([...selectedFlatRows]);
  }, [selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    onLoadData({ pageIndex, sortBy });
  }, [onLoadData, pageIndex, sortBy]);

  const renderSorter = (isDesc) => {
    if (isDesc) {
      return <i className="fa fa-sort-down" />;
    } else {
      return <i className="fa fa-sort-up" />;
    }
  };

  return (
    <div>
      {header && <div className="table-custom-header">{header}</div>}

      <Overlay active={loading}>
        <div className="table-responsive" style={{ height }}>
          <table
            className={`table table-striped ${className}`}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ ...column.style }}
                    >
                      {column.render('Header')}
                      <span className="ml-1">
                        {column.canSort ? (
                          column.isSorted ? (
                            renderSorter(column.isSortedDesc)
                          ) : (
                            <i className="fa fa-sort" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {items.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Overlay>

      {pagination && (
        <div className="table-custom-pagination">
          <Pagination
            totalRecords={total}
            pageIndex={pageIndex + 1}
            pageCount={pageCount}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            pageNeighbours={1}
          />
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  header: PropTypes.node,
  pagination: PropTypes.bool,
  pageCount: PropTypes.number,
  total: PropTypes.number,
  onLoadData: PropTypes.func,
  loading: PropTypes.bool,
  height: PropTypes.string,
  filter: PropTypes.string,
  setSelectedRows: PropTypes.func,
};

Table.defaultProps = {
  className: '',
  header: null,
  pagination: false,
  height: '',
  filter: '',
  setSelectedRows: () => {},
};

export default Table;
