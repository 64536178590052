import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import Layout from '../../layouts/Main';
import Card from '../../components/Card';
import Content from '../../components/Content';
import PageHeading from '../../components/PageHeading';

import PropostasList from './PropostasList';

import useInspinia from '../../hooks/useInspinia';

import AcompanhamentoPropostaProvider from '../../contexts/AcompanhamentoPropostaContext';
import { ControleAcessoContext } from '../../contexts/ControleAcessoContext';

function AcompanhamentoProposta() {
  useInspinia();
  const { checarPermissao } = useContext(ControleAcessoContext);
  return (
    <Layout>
      <PageHeading>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Início</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Crédito</Breadcrumb.Item>
          <Breadcrumb.Item>Proposta</Breadcrumb.Item>
          <Breadcrumb.Item>Acompanhamento</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeading>
      <Content>
        <AcompanhamentoPropostaProvider>
          {checarPermissao(
            'tela',
            'tela.credito.proposta.acompanhamento',
            'Visualizar',
          ) && (
            <>
              <Card>
                <PropostasList />
              </Card>
            </>
          )}
        </AcompanhamentoPropostaProvider>
      </Content>
    </Layout>
  );
}

export default AcompanhamentoProposta;
