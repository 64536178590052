import React, { useContext, useState } from 'react';

import Title from '../../../components/Title';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import ModalUpload from '../../../components/ModalUpload';

import Filter from '../Filter';

import './styles.scss';

import { NotasFiscaisContext } from '../../../contexts/NotasFiscaisContext';

import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

// import { RelatorioComissaoContext } from '../../../contexts/RelatorioComissaoContext';

function Toolbar() {
  const {
    showFilter,
    inserirNotasFiscais,
    arquivosNotas,
    setShowFilter,
    setArquivosNotas,
  } = useContext(NotasFiscaisContext);

  const { checarPermissao } = useContext(ControleAcessoContext);
  // const { inserirNotasFiscais } = useContext(RelatorioComissaoContext)

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <div className=" mb-3 topo-relatorio-comissoes-notas-fiscais">
        <div class="row">
          <div className="title-notas-fiscais col-6">
            <Title label="Notas Fiscais" />
          </div>
          {checarPermissao(
            'botao',
            'botao.notasFiscais.anexarNota',
            'Visualizar',
          ) && (
            <div className="botoes col-6 text-right">
              <Button
                className="btn-anexar-notas"
                type="button"
                variant="white"
                onClick={() => setIsModalVisible(true)}
                disabled={
                  !checarPermissao(
                    'botao',
                    'botao.notasFiscais.anexarNota',
                    'Criar',
                  )
                    ? true
                    : false
                }
              >
                <Icon variant="anexo" />
                <span className="ml-2">Anexar Notas</span>
              </Button>

              <span className="p-1"></span>

              <Button
                className="btn-filtrar"
                type="button"
                variant="gray"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Icon variant="filter" />
                <span className="ml-2">Filtrar</span>
              </Button>
            </div>
          )}
        </div>
        {showFilter && <Filter />}
      </div>
      <ModalUpload
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        arquivos={arquivosNotas}
        setArquivos={setArquivosNotas}
        action={inserirNotasFiscais}
        disabled={
          !checarPermissao('botao', 'botao.notasFiscais.anexarNota', 'Criar')
        }
      />
    </>
  );
}

export default Toolbar;
