import api from './api';

export const getAnotacoes = async (cpf) => {
  try {
    const { data } = await api.get(`/anotacao/${cpf}`);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postAnotacao = async (anotacao) => {
  const { data } = await api.post('/anotacao', anotacao);
  return data.data;
};
