import React, { useContext } from 'react';

import Table from '../../../components/CustomTable';
import Toolbar from './toolbar';

import { CorrespondenteContext } from '../../../contexts/CorrespondenteContext';
import { columns } from './columns';

function AprovacaoList() {
  const { correspondentes } = useContext(CorrespondenteContext);

  return (
    <div>
      <Table
        data={correspondentes}
        columns={columns}
        pagination
        header={<Toolbar />}
      />
    </div>
  );
}

export default AprovacaoList;
