import React, { useEffect, useState, useContext, useRef } from "react";
import './styles.scss'
import { ReactComponent as ArrowSelect } from '../../assets/images/icons/arrow-down-select-antd.svg';
import { ReactComponent as IconCheck } from '../../assets/images/icons/icon-checking-circle-filled.svg';
import { ReactComponent as IconCancel } from '../../assets/images/icons/icon-cancel-filled.svg';
import { CaretRightOutlined } from '@ant-design/icons';
import { CadastroUsuariosContext } from '../../contexts/CadastroUsuariosContext';

export function SelectUnidades({
  data,
  formik,
  label,
  collapse,
  disabled,
  onSelect,
  onDeselect,
  single,
  fieldValue,
  initialValue,
}) {

  const {
    setFieldValue,
  } = formik;


  const {
    unidadeSubunidade
  } = useContext(CadastroUsuariosContext);

  const [selectedOptions, setSelectedOptions] = useState(initialValue || []);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [unitsFilter, setUnitsFilter] = useState('');
  const [showValueInput, setShowValueInput] = useState(false);
  const inputRef = useRef(null);
  const optionListRef = useRef(null)
  const [firstRun, setFirstRun] = useState(true);
  const [singleInputSelectedValue, setSingleInputSelectedValue] = useState('');
  const [placeholderTextSingle, setPlaceholderTextSingle] = useState('Selecione');
  const [focusMode, setFocusMode] = useState(false);
  const [defaultPlaceholderValue, setDefaultPlaceholderValue] = useState(false)

  const handleOptionSelected = (option) => {
    let updatedOptions = selectedOptions.slice();

    const toggleChildrenAndParents = (option, selectedOptions, shouldSelect) => {
      const toggleChildren = (option, selectedOptions, shouldSelect) => {
        if (option.subUnidade) {
          option.subUnidade.forEach(subOption => {
            const index = selectedOptions.findIndex(o => o.unidadeId === subOption.unidadeId);
            if (shouldSelect && index === -1) {
              selectedOptions.push(subOption);
            } else if (!shouldSelect && index !== -1) {
              selectedOptions.splice(index, 1);
            }
            toggleChildren(subOption, selectedOptions, shouldSelect);
          });
        }
      };
      toggleChildren(option, selectedOptions, shouldSelect);
    };

    const index = updatedOptions.findIndex(o => o.unidadeId === option.unidadeId);
    if (index !== -1) {
      updatedOptions.splice(index, 1);
      toggleChildrenAndParents(option, updatedOptions, false);
    } else {
      if (single) {
        updatedOptions = [option];
        setSelectedOptions(updatedOptions)
      } else {
        updatedOptions.push(option);
        toggleChildrenAndParents(option, updatedOptions, true);
      }
    }

    // eslint-disable-next-line
    const filtrarOpcoesSelecionadas = updatedOptions.map(updatedOption => {
      let existeSubunidadeDesmarcada = false;
      if (updatedOption.subUnidade && !single) {

        updatedOption.subUnidade.forEach(option => {
          if (!updatedOptions.find(o => o.unidadeId === option.unidadeId)) {
            existeSubunidadeDesmarcada = true;
          }
        })
      }

      if (!existeSubunidadeDesmarcada)
      return updatedOption
    }).filter(option => option !== undefined)

    setSelectedOptions(filtrarOpcoesSelecionadas);
  };


  const toggleOption = (option, optionsList) => {
    const index = optionsList.findIndex((selectedOption) => selectedOption.unidadeId === option.unidadeId);

    if (index === -1) {
      return [...optionsList, option];
    } else {
      return optionsList.filter((selectedOption) => selectedOption.unidadeId !== option.unidadeId);
    }
  };


  const toggleSubOptions = (subOptions, optionsList) => {

    let updatedOptions = [...optionsList];

    subOptions.forEach((subOption) => {
      const index = updatedOptions.findIndex((selectedOption) => selectedOption.unidadeId === subOption.unidadeId);

      if (index === -1) {
        updatedOptions.push(subOption);
        onSelect(subOption);
      } else {
        updatedOptions = updatedOptions.filter((selectedOption) => selectedOption.unidadeId !== subOption.unidadeId);
        onDeselect(subOption);
      }

      if (subOption.subUnidade) {
        // eslint-disable-next-line no-unused-vars
        const updatedSubChildrenOptions = toggleSubOptions(subOption.subUnidade, updatedOptions);
        updatedOptions = toggleOption(subOption, updatedSubChildrenOptions);
      }
    });

    return updatedOptions;
  };


  const renderOptions = (options, level = 0) => {

    function filterOptions(options, filterText) {
      return options.filter(option => {

        if (option.nome.toLowerCase().includes(filterText.toLowerCase())) {
          return true;
        }

        if (option.subUnidade && option.subUnidade.length > 0) {
          return filterOptions(option.subUnidade, filterText).length > 0;
        }
        return false;
      });
    }

    const filteredOptions = filterOptions(options, unitsFilter);

    return filteredOptions?.map((option) => {
      const isSelected = selectedOptions.some(
        (selectedOption) => selectedOption?.unidadeId === option?.unidadeId
      );
      const itemClass = isSelected ? "selected-option-text" : "";

      return (
        <div
          className="wrapper-item"
          key={option.unidadeId}
          onMouseEnter={() => setHoveredOption(option.unidadeId)}
          onMouseLeave={() => setHoveredOption(null)}
        >
          <div className="item-container" onClick={() => handleOptionSelected(option)}>
            <span className={`${itemClass} unit-name`}>{option.nome}</span>
            {isSelected && (
                hoveredOption === option.unidadeId ? <IconCancel className="icon-check" /> : <IconCheck className="icon-check" />
            )}

          </div>
          {option.subUnidade && (
            <div className={`sub-level-${level} sub-level`} style={{ marginLeft: '16px' }}>
              {renderOptions(option.subUnidade, level + 1)}
            </div>
          )}
        </div>
      );
    });
  };



  useEffect(() => {
    const idsItensSelecionados = selectedOptions.map(option => option.unidadeId)
    if (single) {
      setFieldValue(fieldValue, idsItensSelecionados[0] || null);
    } else {
      setFieldValue(fieldValue, idsItensSelecionados);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);


  function ListItemCollapse({ item, nivel, idsFiltrados }) {
    const [aberto, setAberto] = useState(false);

    const toggleAberto = () => {
      setAberto(!aberto);
    };

    return (
      <ul style={{ margin: '0px', padding: '0px' }}>
        <li key={item.unidadeId} className={`element-unit ${item.marcado ? 'unit-selected' : ''} ${!item.subUnidade && nivel === 0 ? 'single-item' : ''}`}>

          {item.subUnidade && (
            <div onClick={toggleAberto} className="unidade-collapse" >
              {aberto ?
                <CaretRightOutlined className="arrow-select-icon" rotate={90} />

                : <CaretRightOutlined className="arrow-select-icon" rotate={0} />}
              <span className={`top-unit ${item.marcado ? 'unit-selected' : ''}`}>{item.nome} {item.marcado && <IconCheck className="icon-check" />} </span>
            </div>
          )}
          {!item.subUnidade &&
            <div className={`subunit-item-name ${item.marcado ? 'unit-selected' : ''}`}>
              <span className="unit-name">
                {item.nome}
              </span>
              {item.marcado && <IconCheck className="icon-check" />}
            </div>}
          {aberto && item.subUnidade && (
            <div className="subunidade-container" >
              {item.subUnidade.map(subItem => (
                <ListItemCollapse
                  key={subItem.unidadeId}
                  item={subItem}
                />
              ))}
            </div>
          )}
        </li>
      </ul>
    );
  }


  function renderizarLista(data, nivel = 0) {

    return (
      <>
        <div>
          {data?.map(item => (
            <ListItemCollapse key={item?.unidadeId} item={item} nivel={0} isFiltrado={data?.includes(item?.unidadeId)} />
          ))}
        </div>

      </>
    );
  }

  function selecionarUnidades(unidades, idsSelecionados) {
    return unidades.reduce((resultado, unidade) => {
      const marcado = idsSelecionados.includes(unidade.unidadeId);
      if (marcado) {
        resultado.push({
          unidadeId: unidade.unidadeId,
          nome: unidade.nome,
          marcado: marcado,
          subUnidade: unidade.subUnidade ? selecionarUnidades(unidade.subUnidade, idsSelecionados) : null,
        });
      } else if (unidade.subUnidade) {
        const subUnidadesMarcadas = selecionarUnidades(unidade.subUnidade, idsSelecionados);
        if (subUnidadesMarcadas.length > 0) {
          resultado.push({
            unidadeId: unidade.unidadeId,
            nome: unidade.nome,
            marcado: false,
            subUnidade: subUnidadesMarcadas,
          });
        }
      }

      return resultado;
    }, []);
  }

  useEffect(() => {
    if (unidadeSubunidade !== '') {
      setShowValueInput(true)
    }
    // eslint-disable-next-line
  }, [selectedOptions])


  useEffect(() => {
    const option = selectedOptions.map(option => option.nome)
    setPlaceholderTextSingle(option && option?.length !== 0 ? option : 'Selecione');

  }, [selectedOptions])


  useEffect(() => {
    const handleClickOutside = (event) => {

      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        optionListRef.current &&
        !optionListRef.current.contains(event.target)
      ) {
        setUnitsFilter('');
        setIsOpen(false);
        setSingleInputSelectedValue('')
      }
      setFocusMode(false)
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, setUnitsFilter, selectedOptions]);


  useEffect(() => {

    if (!firstRun) {
      setSelectedOptions([])
      setUnitsFilter('');
    }
    if (data.length > 0) {
      setFirstRun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


  const handleChange = (e) => {
    setSingleInputSelectedValue(e.target.value);
    setUnitsFilter(e.target.value);
    if (!e.target.value) {
      setPlaceholderTextSingle(
        singleInputSelectedValue !== null &&
          e.target.value !== '' ?
          singleInputSelectedValue :
          'Selecione'
      );
    }
  };

  const handleFocus = () => {
    if (singleInputSelectedValue && singleInputSelectedValue?.length !== 0) {
      setSingleInputSelectedValue('');
      setUnitsFilter('');
      setPlaceholderTextSingle(singleInputSelectedValue ? singleInputSelectedValue : 'Selecione');
    } else if (!(single && singleInputSelectedValue === '' && selectedOptions.length > 0)) {
      setPlaceholderTextSingle('Selecione');
    }
  };

  useEffect(() => {
    if (placeholderTextSingle === 'Selecione') {
      setDefaultPlaceholderValue(true);
    } else {
      setDefaultPlaceholderValue(false)
    }
  }, [placeholderTextSingle, singleInputSelectedValue])


  // Este useEffect é responsável por exibir a lista de unidades quando o usuário começa a filtrar.
  useEffect(() => {
    if (unitsFilter !== '') {
      setIsOpen(true)
    }
  }, [unitsFilter])

  return (
    <div className={'col-lg-12 p-0'} >
      <div className="label-units mb-0">
        <label className="component-label">{label}</label>
      </div>
      <div className="wrapper-units col-lg-6">
        {showValueInput && single ?

          <div
            onClick={!disabled ? () => { setIsOpen(!isOpen);setFocusMode(true); } : undefined}
            className={`select-option ${disabled ? 'disabled-input' : ''} ${focusMode && 'focus-mode'}`}
          >

            <input
              ref={inputRef}
              className={`input-unidades ${disabled && 'disabled-input'} ${selectedOptions.length > 0 && 'has-single-value'} ${defaultPlaceholderValue && 'placeholder-value'}`}
              disabled={disabled}
              placeholder={placeholderTextSingle}
              value={singleInputSelectedValue}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            <div className="icon-arrow-select">
              <ArrowSelect />
            </div>
          </div>
          :
          <div
            onClick={!disabled ? () => { setIsOpen(!isOpen); setFocusMode(true); } : undefined}
            className={`select-option ${disabled ? 'disabled-input' : ''} ${focusMode && 'focus-mode'}`}
          >

            <input
              ref={inputRef}
              type="text"
              placeholder="Selecione"
              className={`input-unidades ${disabled && 'disabled-input'} ${defaultPlaceholderValue && 'placeholder-value'}`}
              value={unitsFilter}
              onChange={(e) => setUnitsFilter(e.target.value)}
              disabled={disabled}
            />
            <div className="icon-arrow-select">
              <ArrowSelect />
            </div>
          </div>
        }
        {isOpen && !disabled && (
          <>
            <div className="units-options"
              ref={optionListRef}
            >
              {renderOptions(data)}
            </div>
          </>
        )}
      </div>
      {!isOpen && collapse &&
        <div className="option-selected-list wrapper-collapse">
          <div>
            {renderizarLista(selecionarUnidades(unidadeSubunidade, selectedOptions.map(option => option.unidadeId)))}
          </div>
        </div>
      }
    </div>
  );
}