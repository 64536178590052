import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Button } from 'antd';
import { Form, Field, Select, Checkbox } from 'formik-antd';

import InputPhone from '../../../components/InputPhone';
import TooltipAlterados from '../../../components/TooltipAlterados';
import TooltipTelefoneExistente from '../../../components/TooltipTelefoneExistente';
import SelectSearch from '../../../components/SearchSelect';
import Modal from '../../../components/Modal';
import Input from '../../../components/SimpleInput';

import { AnaliseMesaContext } from '../../../contexts/AnaliseMesaContext';
import { ControleAcessoContext } from '../../../contexts/ControleAcessoContext';

import { ReactComponent as IconArrowSelect } from '../../../assets/images/icons/arrow-down-select.svg';
import { ReactComponent as Cellphone } from '../../../assets/images/icons/cellphone.svg';

import validaPhone from '../../../utils/validaPhone';

import './styles.scss';

const { Option } = Select;

const valuesReferencia = {
  nome: '',
  telefone: '',
  grau: 0,
};

function ContatoForm() {
  const {
    formik,
    editableField,
    validationField,
    telefoneExtra,
    setTelefoneExtra,
    contexto,
    referencia,
    setReferencia,
    highlightField,
    campoChecar,
    handleChangeChecados,
    getChecado,
    errorDigitalTelefoneCelular,
    setErrorDigitalTelefoneCelular,
    highlightTelefoneExistente,
    highlightTelefoneReferenciaExistente,
    highlightTelefoneExtraExistente,
    reenviarSMS,
    proposta,
    responseAbaContato
  } = useContext(AnaliseMesaContext);

  const { checarPermissao } = useContext(ControleAcessoContext);

  const { values, setFieldTouched, setFieldValue, touched, errors } = formik;

  const [telefoneOriginal, setTelefoneOriginal] = useState();
  const [statusEmail, setStatusEmail] = useState('');
  const [statusTelefone, setStatusTelefone] = useState('');
  const [statusTelefoneExtra, setStatusTelefoneExtra] = useState([]);
  const [desabilitaBotaoLigacao, setDesabilitaBotaoLigacao] = useState(false);

  useEffect(() => {
    if (values?.contatos?.contato) {
      if (!telefoneOriginal)
        setTelefoneOriginal(
          values.contatos.contato.telefone.replace(/\D/g, ''),
        );
      values.contatos.contato.tooltip = highlightTelefoneExistente();
    }
    if (values?.contatos?.contato?.telefoneExtra) {
      values?.contatos?.contato?.telefoneExtra?.forEach((telefone, i) => {
        values.contatos.contato.telefoneExtra[i].tooltip =
          highlightTelefoneExtraExistente(i);

        setFieldTouched(`contatos.contato.telefoneExtra.${i}.telefone`, false);
      });
    }
    if (values?.contatos?.referencia) {
      values?.contatos?.referencia?.forEach((telefone, i) => {
        values.contatos.referencia[i].tooltip =
          highlightTelefoneReferenciaExistente(i);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.contatos?.contato, values?.contatos?.referencia, responseAbaContato]);

  const handleAddReferencia = () => {
    setReferencia((state) => [...state, { ...valuesReferencia }]);
  };

  const handleRemoveReferencia = (index) => {
    setReferencia((newArray) => newArray.filter((_, idx) => idx !== index));
    values?.contatos.referencia.splice(index, 1);
  };

  const valuesTelefonesExtras = {
    telefone: '',
    funcao: 0,
  };

  const [mensagemErrorCelularObrigatorio, setMensagemErrorCelularObrigatorio] =
    useState('');
  const AddCelularObrigatorioModalidadeDigital = () => {
    handleAddTelefoneExtra();
    setMensagemErrorCelularObrigatorio(
      'Telefone celular obrigatório para a modalidade digital.',
    );
    setTimeout(() => {
      setFieldTouched(
        `contatos.contato.telefoneExtra.${telefoneExtra.length}.telefone`,
        true,
      );
    }, 500);
  };

  useEffect(() => {
    if (errorDigitalTelefoneCelular === true) {
      AddCelularObrigatorioModalidadeDigital();
      setErrorDigitalTelefoneCelular(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDigitalTelefoneCelular]);

  const handleAddTelefoneExtra = () => {
    const indice = telefoneExtra.length + 1;

    if (telefoneExtra) {
      setTelefoneExtra(telefoneExtra.concat(valuesTelefonesExtras));
    } else {
      setTelefoneExtra([valuesTelefonesExtras]);
    }

    setFieldTouched(`contatos.contato.telefoneExtra.${indice}.telefone`, true);
  };

  const handleRemoveTelefoneExtra = (index) => {
    setTelefoneExtra((newArray) => newArray.filter((_, idx) => idx !== index));
    values.contatos.contato.telefoneExtra.splice(index, 1);
    const newStateArray = [...statusTelefoneExtra];
    newStateArray.splice(index, 1);
    setStatusTelefoneExtra(newStateArray);
    setFieldTouched(`contatos.contato.telefoneExtra.${index}.telefone`, false);
    setFieldTouched(
      `contatos.contato.telefoneExtra.${index}.tipoTelefone`,
      false,
    );
  };
  const handleTelefoneExtraType = (value, indice) => {
    const type = validaPhone(value);
    if (type !== 0) {
      formik.setFieldValue(
        `contatos.contato.telefoneExtra.${indice}.tipoTelefone`,
        type,
      );
    } else {
      formik.setFieldValue(
        `contatos.contato.telefoneExtra.${indice}.tipoTelefone`,
        '',
      );
    }
  };

  const handleTelefoneType = (value) => {
    const type = validaPhone(value);
    if (type !== 0 && type !== undefined) {
      formik.setFieldValue(`contatos.contato.tipoTelefone`, type);
      setDesabilitaBotaoLigacao(false)
    } else {
      formik.setFieldValue(`contatos.contato.tipoTelefone`, '');
      setDesabilitaBotaoLigacao(true)
    }
    setFieldTouched('contatos.contato.telefone')
  };

  useEffect(() => {
    const newStateArray = [...statusTelefoneExtra];

    if (
      errors?.contatos?.contato?.telefoneExtra &&
      touched?.contatos?.contato?.telefoneExtra
    ) {
      values?.contatos?.contato?.telefoneExtra?.forEach((_, i) => {
        if (
          touched?.contatos.contato?.telefoneExtra[i]?.telefone &&
          errors?.contatos.contato?.telefoneExtra[i]?.telefone ===
            'Número de telefone inválido, favor tentar novamente.'
        ) {
          newStateArray[i] = 'warning';
          setStatusTelefoneExtra(newStateArray);
        } else if (
          touched?.contatos?.contato?.telefoneExtra[i]?.telefone &&
          errors?.contatos?.contato?.telefoneExtra[i]?.telefone ===
            'O telefone deve ser preenchido'
        ) {
          newStateArray[i] = 'error';
          setStatusTelefoneExtra(newStateArray);
        } else {
          newStateArray[i] = '';
          setStatusTelefoneExtra(newStateArray);
        }
      });
    } else {
      setStatusTelefoneExtra(['']);
    }

    setFieldTouched('contatos.contato.telefone')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    touched?.contatos?.contato?.telefoneExtra,
    errors?.contatos?.contato?.telefoneExtra,
    values?.contatos?.contato?.telefoneExtra,
  ]);

  useEffect(() => {
    if (
      touched?.contatos?.contato &&
      errors?.contatos?.contato &&
      touched?.contatos?.contato?.email &&
      errors?.contatos?.contato?.email === 'Deve ser informado um e-mail válido'
    ) {
      setStatusEmail('warning');
    } else if (
      touched?.contatos?.contato &&
      errors?.contatos?.contato &&
      touched?.contatos?.contato?.email &&
      errors?.contatos?.contato?.email === 'Obrigatório informar E-mail'
    ) {
      setStatusEmail('error');
    } else {
      setStatusEmail('');
    }
  }, [errors?.contatos?.contato, touched?.contatos?.contato]);

  const handleReenvioSMS = async () => {
    await reenviarSMS(proposta?.id).then((response) => {
      if (response.success) {
        Modal('', 'SMS reenviado com sucesso', 'success');
      }
    });
  };

  return (
    <>
      {checarPermissao(
        'botao',
        'botao.analiseMesaCredito.reenviarSMS',
        'Visualizar',
      ) &&
        proposta?.permiteReenvioSMS && (
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  className="btnReenvio"
                  type="button"
                  variant="white"
                  onClick={handleReenvioSMS}
                  disabled={
                    telefoneOriginal !==
                    values?.contatos?.contato?.telefone.replace(/\D/g, '')
                  }
                >
                  <Cellphone />
                  Reenviar SMS
                </Button>
              </div>
            </div>
          </div>
        )}
      <div className="form-row">
        <div className="col-lg-8">
          <Form.Item
            name="contatos.contato.email"
            label={
              <>
                <label>E-mail</label>
                {campoChecar('contatos.contato.email') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('contatos.contato.email')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('contatos.contato.email')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('contatos.contato.email') && (
                  <TooltipAlterados />
                )}
              </>
            }
            className={
              highlightField('contatos.contato.email') && `campo-alterado`
            }
            validateStatus={statusEmail}
            validate={(value) =>
              validationField(
                value,
                'contatos.contato.email',
                'E-mail',
                'email',
              )
            }
            hasFeedback
          >
            <Input
              name="contatos.contato.email"
              disabled={!editableField('contatos.contato.email')}
              placeholder="mail@example.com"
              value={values?.contatos?.contato?.email}
              onBlur={(e) =>
                setFieldValue('contatos.contato.email', e.target.value)
              }
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-row">
        <div className="col-lg-4">
          <Form.Item
            name="contatos.contato.telefone"
            label={
              <>
                <label>Telefone</label>
                {campoChecar('contatos.contato.telefone') && (
                  <Checkbox
                    className="check-campo"
                    checked={
                      !!getChecado(
                        campoChecar('contatos.contato.telefone')?.campoId,
                      )
                    }
                    onChange={() =>
                      handleChangeChecados(
                        campoChecar('contatos.contato.telefone')?.campoId,
                      )
                    }
                  />
                )}
                {highlightField('contatos.contato.telefone') && (
                  <TooltipAlterados />
                )}
                {!!values?.contatos?.contato?.tooltip && (
                  <TooltipTelefoneExistente
                    data={values.contatos.contato.tooltip}
                  />
                )}
              </>
            }
            className={
              (highlightField('contatos.contato.telefone') &&
                `campo-alterado`) ||
              (!!values?.contatos?.contato?.tooltip && `campo-existente`)
            }
            validateStatus={statusTelefone}
            validate={(value) => {
              let msgError = '';

              if (value.length > 15) return;

              if (editableField('contatos.contato.telefone')) {
                if (touched?.contatos?.contato?.telefone) {
                  if (!value.replace(/\D/g, '')) {
                    msgError = 'O telefone deve ser preenchido';
                    setStatusTelefone('error');
                  } else if (value && !validaPhone(value)) {
                    msgError =
                      'Número de telefone inválido, favor tentar novamente.';
                    setStatusTelefone('warning');
                  } else {
                    msgError = '';
                    setStatusTelefone('');
                  }
                }
              }

              return msgError;
            }}
          >
            <Field name="contatos.contato.telefone">
              {({ field }) => (
                <InputPhone
                  {...field}
                  opcaoDiscador={
                    contexto?.bloqueiaAcessoSimultaneo
                      ? false
                      : editableField('contatos.contato.telefone')
                  }
                  disabled={!editableField('contatos.contato.telefone')}
                  onChange={(e) => {
                    handleTelefoneType(e.target.value);
                  }}
                  disabledButton={desabilitaBotaoLigacao}
                />
              )}
            </Field>
          </Form.Item>
        </div>
        <div className="col-lg-4">
          <Form.Item
            name={`contatos.contato.tipoTelefone`}
            label="Tipo de telefone"
          >
            <Select
              name={`contatos.contato.tipoTelefone`}
              suffixIcon={''}
              disabled={true}
              className="selectTipoTelefone"
              getPopupContainer={(trigger) => trigger.parentNode}
            >
              {contexto?.tipoTelefone.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="form-row input-dinamicos">
        {telefoneExtra?.map((_, i) => (
          <Fragment key={i}>
            <div className="col-lg-8 campos">
              <div className="form-row">
                <div className="col-lg-6">
                  <Form.Item
                    name={`contatos.contato.telefoneExtra.${i}.telefone`}
                    label={
                      <>
                        <label>Telefone Extra</label>
                        {highlightField(
                          'contatos.contato.telefoneExtra',
                          null,
                          i,
                        ) && <TooltipAlterados />}
                        {!!values?.contatos?.contato?.telefoneExtra[i]
                          ?.tooltip && (
                          <TooltipTelefoneExistente
                            data={
                              values?.contatos?.contato?.telefoneExtra[i]
                                ?.tooltip
                            }
                          />
                        )}
                      </>
                    }
                    className={
                      (highlightField(
                        'contatos.contato.telefoneExtra',
                        null,
                        i,
                      ) &&
                        `campo-alterado`) ||
                      (!!values?.contatos?.contato?.telefoneExtra[i]?.tooltip &&
                        `campo-existente`)
                    }
                    validate={(value) => {
                      let error = '';

                      if (
                        touched?.contatos?.contato?.telefoneExtra?.[i]?.telefone
                      ) {
                        if (!value) {
                          error = 'O telefone deve ser preenchido';
                        } else if (value && !validaPhone(value)) {
                          error =
                            'Número de telefone inválido, favor tentar novamente.';
                        } else {
                          error = '';
                        }
                      }
                      if (
                        mensagemErrorCelularObrigatorio !== '' &&
                        (!value || value === '')
                      ) {
                        error = mensagemErrorCelularObrigatorio;
                      }

                      return error;
                    }}
                  >
                    <Field
                      name={`contatos.contato.telefoneExtra.${i}.telefone`}
                    >
                      {({ field }) => (
                        <InputPhone
                          {...field}
                          opcaoDiscador={
                            contexto?.bloqueiaAcessoSimultaneo
                              ? false
                              : editableField('contatos.contato.telefoneExtra')
                          }
                          onChange={(e) => {
                            formik.validateField(
                              `contatos.contato.telefoneExtra.${i}.telefone`,
                            );
                            handleTelefoneExtraType(e.target.value, i);
                          }}
                          disabled={
                            !editableField('contatos.contato.telefoneExtra')
                          }
                        />
                      )}
                    </Field>
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                    name={`contatos.contato.telefoneExtra.${i}.tipoTelefone`}
                    label="Tipo de telefone"
                  >
                    <Select
                      name={`contatos.contato.telefoneExtra.${i}.tipoTelefone`}
                      suffixIcon={''}
                      disabled={true}
                      className="selectTipoTelefone"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(value) =>
                        formik.setFieldValue(
                          `contatos.contato.telefoneExtra.${i}.tipoTelefone`,
                          value,
                        )
                      }
                      value={
                        values?.contatos?.contato?.telefoneExtra[i]
                          ?.tipoTelefone
                      }
                    >
                      {contexto?.tipoTelefone.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <Button
                htmlType="button"
                className="btnRemoveInputs"
                onClick={() => handleRemoveTelefoneExtra(i)}
                disabled={
                  !contexto?.bloqueiaAcessoSimultaneo
                    ? !editableField('contatos.contato.remover')
                    : true
                }
              >
                <i className="fa fa-minus mr-2" /> Telefone
              </Button>
            </div>
          </Fragment>
        ))}
      </div>

      <div className="form-row">
        <div className="offset-lg-8 col-lg-4 mt-3">
          <Button
            htmlType="button"
            className="btnAddInputs"
            onClick={handleAddTelefoneExtra}
            disabled={
              !contexto?.bloqueiaAcessoSimultaneo
                ? !editableField('contatos.contato.adicionar')
                : true
            }
          >
            <i className="fa fa-plus mr-2" /> Telefone
          </Button>
        </div>
      </div>
      {referencia &&
        referencia?.map((item, i) => (
          <div className="form-row input-dinamicos mt-4" key={i}>
            <div className="col-lg-5">
              <Form.Item
                name={`contatos.referencia.${i}.nome`}
                label="Nome"
                validate={(value) => {
                  let error;
                  if (!value) {
                    error = 'O nome da referência deve ser preenchido';
                  }
                  return error;
                }}
                hasFeedback
              >
                <Input
                  name={`contatos.referencia.${i}.nome`}
                  disabled={!editableField('contatos.referencia.nome')}
                  value={values?.contatos?.referencia[i]?.nome}
                  onBlur={(e) =>
                    setFieldValue(
                      `contatos.referencia.${i}.nome`,
                      e.target.value,
                    )
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item
                name={`contatos.referencia.${i}.telefone`}
                label={
                  <>
                    <label>Telefone</label>
                    {highlightField('contatos.referencia', 'telefone', i) && (
                      <TooltipAlterados />
                    )}
                    {!!values?.contatos?.referencia[i]?.tooltip && (
                      <TooltipTelefoneExistente
                        data={values?.contatos?.referencia[i]?.tooltip}
                      />
                    )}
                  </>
                }
                className={
                  (highlightField('contatos.referencia', 'telefone', i) &&
                    `campo-alterado`) ||
                  (!!values?.contatos?.referencia[i]?.tooltip &&
                    `campo-existente`)
                }
                validate={(value) => {
                  let error;
                  if (!value) {
                    error = 'O telefone deve ser preenchido';
                  } else if (value && !validaPhone(value)) {
                    error = 'Telefone inválido';
                  }
                  return error;
                }}
                hasFeedback
              >
                <Field name={`contatos.referencia.${i}.telefone`}>
                  {({ field }) => (
                    <InputPhone
                      {...field}
                      opcaoDiscador={
                        contexto?.bloqueiaAcessoSimultaneo
                          ? false
                          : editableField('contatos.referencia.telefone')
                      }
                      onChange={() => {
                        formik.validateField(
                          `contatos.referencia.${i}.telefone`,
                        );
                      }}
                      disabled={!editableField('contatos.referencia.telefone')}
                    />
                  )}
                </Field>
              </Form.Item>
            </div>
            <div className="col-lg-3">
              <Form.Item
                name={`contatos.referencia.${i}.grau`}
                label="Tipo de Referência"
                validate={(value) => {
                  let error;
                  if (value === null || value < 0) {
                    error = 'O tipo de referência deve ser preenchido';
                  }
                  return error;
                }}
                hasFeedback
              >
                <SelectSearch
                  name={`contatos.referencia.${i}.grau`}
                  suffixIcon={<IconArrowSelect />}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  disabled={!editableField('contatos.referencia.grau')}
                  onChange={(value) =>
                    formik.setFieldValue(`contatos.referencia.${i}.grau`, value)
                  }
                  value={values?.contatos?.referencia[i]?.grau}
                  fillDefault={editableField(`contatos.referencia.${i}.grau`)}
                >
                  {contexto?.tipoReferencia.length > 0 &&
                    contexto?.tipoReferencia.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.nome}
                      </Option>
                    ))}
                </SelectSearch>
              </Form.Item>
            </div>

            <div className="col-lg-1 d-flex justify-content-end">
              {referencia.length > 2 && (
                <Button
                  htmlType="button"
                  className="btnRemoveInputs btnIcon"
                  onClick={() => handleRemoveReferencia(i)}
                  disabled={
                    !contexto?.bloqueiaAcessoSimultaneo
                      ? !editableField('contatos.referencia.remover')
                      : true
                  }
                >
                  <i className="fa fa-minus" />
                </Button>
              )}
            </div>
          </div>
        ))}
      <div className="form-row">
        <div className="col-lg-12 d-flex justify-content-end mt-4">
          <Button
            htmlType="button"
            className="btnAddInputs btnIcon"
            onClick={handleAddReferencia}
            disabled={
              !contexto?.bloqueiaAcessoSimultaneo
                ? !editableField('contatos.referencia.adicionar')
                : true
            }
          >
            <i className="fa fa-plus" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default ContatoForm;
