import React from 'react';

import integer from '../../assets/images/icons/integer.svg';
import date from '../../assets/images/icons/date.svg';
import money from '../../assets/images/icons/money.svg';
import text from '../../assets/images/icons/text.svg';
import IconEdit from '../EditIcon';

import { truncate } from '../../utils/words';

import './styles.scss';

const typeEnum = { text: 1, integer: 2, decimal: 3, date: 4 };
Object.freeze(typeEnum);

const renderIcon = (value) => {
  if (value === typeEnum.text) {
    return <img src={text} alt="value-icon" />;
  } else if (value === typeEnum.integer) {
    return <img src={integer} alt="value-icon" />;
  } else if (value === typeEnum.date) {
    return <img src={date} alt="value-icon" />;
  } else {
    return <img src={money} alt="value-icon" />;
  }
};

function ListItem({ label, type, value, onClick }) {
  return (
    <div className="row py-1">
      <div className="col-lg-8"> 
        <span className="mr-2">{renderIcon(type)}</span>
        <span>{truncate(label, 25)}</span>
      </div>
      <div className="col-lg-4 text-right"> 
        <span className="list-item-value mr-2">
          {value ? truncate(value, 20) : ''}
        </span>
        <IconEdit onClick={onClick} />
      </div>
    </div>
  );
}

export default ListItem;
